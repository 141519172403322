import React, {Component, Fragment} from 'react';
import Breadcrumb from '../common/breadcrumb';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import {Modal, Select} from 'antd';

import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import Tour from "reactour";
import {Helmet} from "react-helmet";
import {IMG_HOST} from "../../helper/host";
import format from "format-number";
import {Input, Label} from "reactstrap";
import Ticket from "../../assets/icons/ticket.svg";
import Rekomendasi from "../../assets/icons/rekomendasi.svg";
import CurrencyFormat from "react-currency-format";
import CheckSvgBlue from "../../assets/icons/Check Blue.svg";
import i18n from "i18next";

export class ListPaketLangganan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            paket: [],
            shift: [],
            idDel: '',
            loading:true,
            isLoading:true,
            guide: false,
            modalRenew: false,
            month: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    wantPaket(paket_id){
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menganti paket?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loading: true,
                        data: []
                    })
                    let form = {
                        paket_id: paket_id,
                        month: this.state.month,
                    }
                    this.props.request("paket/update_paket", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    loading: false,
                                    data: []
                                })
                                window.location.reload();
                            }else{
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                    showConfirmButton: false,
                                    timer: 1200,
                                })
                                this.setState({
                                    loading: false,
                                    data: []
                                })
                            }
                        });
                }
            })
    }

    loadPaket(){
        let form = {}
        this.props.request("paket/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        paket: response.data.data,
                        loading: false,
                    })
                }
            });
    }

    componentDidMount() {
        this.loadPaket()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.tambah-divisi',
                content: 'Anda dapat membuat Divisi disini',
            },
            {
                selector: '.daftar-divisi',
                content: 'Daftar Divisi anda dapat dilihat disini',
            },
        ];
        const { Option } = Select;
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Daftar Paket - iPresens</title>
                    <meta name="description" content="Daftar Paket Presensi" />
                </Helmet>
                <Breadcrumb parent="Langganan" title="Daftar Paket" parentLink="/subscription" setGuide={() => {this.setState({guide: true}) }}/>
                <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({guide: false})}/>
                <div className="container-fluid">
                    <section id="harga" className="section-lr feature-section mb-5">
                        <div className="container-fluid">
                            <div className="row feature_row">
                                <div className="pricing-content card-deck flex-column flex-md-row m-0">
                                    {this.state.paket.map((item, index) => {
                                        return (
                                            <div className='col-md-3 d-flex align-items-stretch'>
                                                <div className='card border pb-2 card-style-paket'>
                                                    <div className='card-header border-bottom pb-0' style={{ backgroundColor: "#F9F9F9", padding: "14px", minHeight: "22%" }}>
                                                        <div className='row'>
                                                            <div className='col-2' style={{ paddingLeft: "14px", paddingRight: "14px" }}><img src={Ticket} /></div>
                                                            <div className='col' style={{ fontSize: "12px", paddingRight: "14px", paddingLeft: "12px" }}><b style={{ color: "#252B2B" }}>{item.paket_nama}</b> {item.paket_harga === 0 ? <img src={Rekomendasi} style={{ height: "18px" }} /> : ""}<br /><p style={{ fontSize: "12px", marginBottom: "0px", color: "#5D6F80" }}>{item.paket_short_desc}</p></div>
                                                        </div>
                                                    </div>
                                                    <div className='card-body px-3 pt-2'>
                                                        <div className='row px-3 mb-3 d-flex align-items-end justify-content-start'>
                                                            <div className='col-auto px-0' style={{ fontSize: "24px", fontWeight: 700 }}>
                                                                <CurrencyFormat value={i18n.language == "id" ? item.paket_harga : item.paket_harga_usd} displayType={'text'} thousandSeparator={'.'} decimalSeparator={","} prefix={i18n.language == "id" ? 'IDR ' : 'USD '} />
                                                            </div>
                                                            <div className='col-auto px-0'>
                                                                <p>/ {i18n.t('bulan')}</p>
                                                            </div>
                                                        </div>
                                                        <div className='row mb-2'>
                                                            <div className='col'>
                                                                <button style={{ borderRadius: "8px", paddingTop: "12px", paddingBottom: "12px" }} onClick={() => this.setState({ modalRenew: true, paket_id: item.paket_id })} className={item.paket_harga === 0 ? "btn btn-primary btn-block text-capitalize" : "btn btn-block back-btn-pilih-paket text-capitalize"}>{i18n.t("pilihpaket")}</button>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <ul className="list-group">
                                                                    <li className="list-group-item border-0 px-0 mb-2" style={{ fontSize: "12px", color: "#B6B6B6" }}>{i18n.t("apaygandaperoleh")}</li>
                                                                    {item.paket_harga === 0 ?
                                                                        <li className="list-group-item border-0 px-0 py-1" style={{ fontSize: "12px" }}>
                                                                            <div className='row flex-nowrap'>
                                                                                <div className='col-1'><img src={CheckSvgBlue} style={{ height: "14px", width: "14px" }} alt="check icon" /></div>
                                                                                <div className='col'> Gratis! selama {item.paket_bulan} {i18n.t("bulan")}</div>
                                                                            </div>
                                                                        </li>
                                                                        : ""
                                                                    }
                                                                    {item.paket_premium === '1' ?
                                                                        <li className="list-group-item border-0 px-0 py-1" style={{ fontSize: "12px" }}>
                                                                            <div className='row flex-nowrap'>
                                                                                <div className='col-1'><img src={CheckSvgBlue} style={{ height: "14px", width: "14px" }} alt="check icon" /></div>
                                                                                <div className='col'> {i18n.t("aksespremium")}</div>
                                                                            </div>
                                                                        </li>
                                                                        : ""
                                                                    }
                                                                    <li className="list-group-item border-0 px-0 py-1" style={{ fontSize: "12px" }}>
                                                                        <div className='row flex-nowrap'>
                                                                            <div className='col-1'><img src={CheckSvgBlue} style={{ height: "14px", width: "14px" }} alt="check icon" /></div>
                                                                            <div className='col'>{i18n.t("maksimal")} {item.paket_jumlah} {i18n.t("karyawan")}</div>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list-group-item border-0 px-0 py-1" style={{ fontSize: "12px" }}>
                                                                        <div className='row flex-nowrap'>
                                                                            <div className='col-1'><img src={CheckSvgBlue} style={{ height: "14px", width: "14px" }} alt="check icon" /></div>
                                                                            <div className='col w-75'>{i18n.language == "id" ? item.paket_desc : item.paket_desc_english}</div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Modal
                    title="Pilih Durasi Perpanjangan Paket"
                    visible={this.state.modalRenew}
                    onOk={() => {
                        this.setState({
                            modalRenew: false,
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            modalRenew: false,
                        });
                    }}
                    footer={null}
                >
                    <div>
                        <Label>Pilih Durasi Masa Aktif Perpanjangan Paket Perusahaan</Label><br />
                        <Label style={{ fontSize: "9px", marginBottom: "1px" }}>(Pilih Bulan Masa Aktif) </Label>
                        <Input bsSize="sm" type="select" name="month" className="form-select w-50" value={this.state.month} onChange={this.handleChange}>
                            <option value="">Pilih Bulan</option>
                            <option value="1">1 Bulan</option>
                            <option value="2">2 Bulan</option>
                            <option value="3">3 Bulan</option>
                            <option value="4">4 Bulan</option>
                            <option value="5">5 Bulan</option>
                            <option value="6">6 Bulan</option>
                            <option value="7">7 Bulan</option>
                            <option value="8">8 Bulan</option>
                            <option value="9">9 Bulan</option>
                            <option value="10">10 Bulan</option>
                            <option value="11">11 Bulan</option>
                            <option value="12">12 Bulan</option>
                        </Input>
                        {!this.state.loading ?
                            <a type="button" className="btn btn-primary mb-3 mt-3 text-center"
                               style={{ color: 'white', maxWidth: '100%' }}
                               onClick={() => {this.wantPaket(this.state.paket_id)}}>
                                Pilih Bulan
                            </a>
                            :
                            <a type="button" className="btn btn-primary mb-3 mt-3 text-center"
                               style={{ color: 'white', maxWidth: '100%' }}>
                                <i className="fa fa-refresh fa-spin mr-2" />
                                Sedang Mengolah Paket...
                            </a>
                        }
                    </div>
                </Modal>
            </Fragment>
        )
    }
}

export default ListPaketLangganan
