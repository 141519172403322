import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import i18n from "i18next";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import CKEditor from "react-ckeditor-component";
import Swal from "sweetalert2";
import {Spin} from "antd";

export class KebijakanPerusahaan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kebijakan_content: '',
            loading: false,
            kebijakan_id: '',
        }
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }

    onEditorStateChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            kebijakan_content: newContent
        })
    }

    savePolicy() {
        let form = {
            content: this.state.kebijakan_content,
            kebijakan_id: this.state.kebijakan_id
        }
        this.props.request('save_policy', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: this.props.user.perusahaan.perusahaan_type === 'sekolah' ? i18n.t('kebijakansekolahberhasildisimpan') : i18n.t('kebijakanperusahaanberhasildisimpan'),
                        icon: 'success'
                    }, () => this.setState({
                        loading: true,
                    }))
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('datagagalditambahkan'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.loadPolicy()
            })
    }

    loadPolicy() {
        let form = {}
        this.props.request('load_policy', form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        kebijakan_content: response.data ? response.data.kebijakan_content: null ,
                        kebijakan_id: response.data ? response.data.kebijakan_id : null,
                        loading: false
                    })
                }
            })
    }

    componentDidMount() {
        this.loadPolicy()
    }

    render() {
        const isSekolah = this.props.user.perusahaan.perusahaan_type === 'sekolah';

        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{isSekolah ? i18n.t('kebijakansekolah') : i18n.t('kebijakanperusahaan')} - iPresens</title>
                    <meta name="description" content="Kebijakan"/>
                </Helmet>

                <Breadcrumb title={isSekolah ? i18n.t('kebijakansekolah') : i18n.t('kebijakanperusahaan')}/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    {!this.state.loading ? (
                                        <>
                                            <CKEditor
                                                content={this.state.kebijakan_content}
                                                config={{versionCheck: false}}
                                                events={{
                                                    change: this.onEditorStateChange
                                                }}
                                            />
                                            <div className="d-flex align-items-center justify-content-end mt-3">
                                                <button className="btn ipresens-btn-color" onClick={() => this.savePolicy()}>{i18n.t('simpan')}</button>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="py-5">
                                            <Spin size="large" tip={i18n.t('loading...')} style={{
                                                width: '100%',
                                                margin: 'auto',
                                                marginBottom: '10px',
                                                marginTop: '10px'
                                            }}/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(KebijakanPerusahaan);