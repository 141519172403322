import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import {Helmet} from "react-helmet";
import TimeInput from '../../helper/time_input'
import {Empty} from "antd";
import EmptyDataImage from "../../assets/images/data-kosong.png";


export class Edit_absensi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan: false,
            isLoading: true,
            pickKaryawan: false,
            data: [],
            karyawan: [],
            shift: [],
            lokasi: [],
            lokasi_in: '',
            lokasi_out: '',
            sift: '',
            tanggal: '',
            jam_in: '',
            jam_out: '',
            status_in: '',
            status_out: '',
            keterangan_in: '',
            keterangan_out: '',
            istirahat_mulai: '',
            istirahat_selesai: '',
            karyawan_id: '',

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    loadData(){
        let form = {
            id: this.props.match.params.id
        }
        this.props.request("presensi/profil", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        karyawan: response.data.karyawan,
                        shift: response.data.karyawan.divisi_sift,
                        sift: response.data.sif_name,
                        tanggal: response.data.tanggal,
                        jam_in: response.data.jam_masuk_absen,
                        jam_out: response.data.jam_keluar_absen,
                        status_in: response.data.status_in,
                        status_out: response.data.status_out,
                        keterangan_in: response.data.keterangan_in,
                        keterangan_out: response.data.keterangan_out,
                        istirahat_mulai: response.data.istirahat_mulai,
                        istirahat_selesai: response.data.istirahat_selesai,
                        loading         : false
                    })
                }
            });
    }

    simpanAbsensi = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id: this.props.match.params.id,
            sift: this.state.sift,
            tanggal: this.state.tanggal,
            jam_in: this.state.jam_in,
            jam_out: this.state.jam_out,
            status_in: this.state.status_in,
            status_out: this.state.status_out,
            keterangan_in: this.state.keterangan_in,
            keterangan_out: this.state.keterangan_out,
            istirahat_mulai: this.state.istirahat_mulai,
            istirahat_selesai: this.state.istirahat_selesai,
        }

        this.props.request("presensi/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.props.history.push('/presence')
                }else{
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Edit Presensi - Presensi</title>
                    <meta name="description" content="Detail Presensi" />
                </Helmet>
                <Breadcrumb title="Edit Presensi" parent="Presensi" parentLink="/presence"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    {!this.state.loading && this.state.data.length === 0 ?
                                        <Empty image={EmptyDataImage} className="ipresens-image-empty mb-5"
                                            description={<span>Data tidak ditemukan!</span>}
                                        />
                                        :
                                        <form className="needs-validation user-add" noValidate="">
                                            <h4>Detail Presensi</h4>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Tanggal
                                                    Presensi</label>
                                                <input type="date" name="tanggal"
                                                       className="form-control col-xl-8 col-md-7"
                                                       defaultValue={this.state.tanggal} onChange={this.handleChange}/>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Karyawan</label>
                                                <input value={this.state.karyawan.karyawan_nama}
                                                       className="form-control col-xl-8 col-md-7" disabled/>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Shift</label>
                                                <select name="sift"
                                                        className="form-control col-xl-8 col-md-7"
                                                        onChange={this.handleChange}>
                                                    <option value="">Pilih Shift</option>
                                                    {this.state.shift.map((item, index) => {
                                                        return (
                                                            <option value={item.sif.sift_name}
                                                                    selected={item.sif.sift_name === this.state.data.sif_name}>{item.sif.sift_name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <label className="col-xl-3 f-16 col-md-4"><b>Presensi Masuk</b></label>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Jam Presensi
                                                    Masuk</label>
                                                <TimeInput
                                                    initTime={this.state.jam_in.substr(0, 5)}
                                                    className='s-input -time form-control col-xl-2 col-md-4'
                                                    onTimeChange={(event) => {
                                                        const value = event;
                                                        this.setState({
                                                            jam_in: value + ':00'
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Status Presensi
                                                    Masuk</label>
                                                <select name="status_in" className="form-control col-xl-8 col-md-7"
                                                        onChange={this.handleChange}>
                                                    <option value="">Pilih Status</option>
                                                    <option value="Masuk Kerja"
                                                            selected={this.state.status_in === 'Masuk Kerja'}>Masuk
                                                        Kerja (Tepat Waktu)
                                                    </option>
                                                    <option value="Terlambat"
                                                            selected={this.state.status_in === 'Terlambat'}>Terlambat
                                                    </option>
                                                    <option value="Izin"
                                                            selected={this.state.status_in === 'Izin'}>Izin
                                                    </option>
                                                    <option value="Sakit"
                                                            selected={this.state.status_in === 'Sakit'}>Sakit
                                                    </option>
                                                    <option value="Cuti"
                                                            selected={this.state.status_in === 'Cuti'}>Cuti
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4">Keterangan Presensi Masuk</label>
                                                <input type="text" name="keterangan_in"
                                                       className="form-control col-xl-8 col-md-7"
                                                       placeholder="Keterangan Presensi..." onChange={this.handleChange}
                                                       defaultValue={this.state.keterangan_in}/>
                                            </div>

                                            <label className="col-xl-3 f-16 col-md-4"><b>Istirahat</b></label>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Jam Mulai Istirahat</label>
                                                <TimeInput
                                                    initTime={this.state.istirahat_mulai && this.state.istirahat_mulai.substr(0, 5)}
                                                    className='s-input -time form-control col-xl-2 col-md-4'
                                                    onTimeChange={(event) => {
                                                        const value = event;
                                                        this.setState({
                                                            istirahat_mulai: value + ':00'
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Jam Berakhir Istirahat</label>
                                                <TimeInput
                                                    initTime={this.state.istirahat_selesai && this.state.istirahat_selesai.substr(0, 5)}
                                                    className='s-input -time form-control col-xl-2 col-md-4'
                                                    onTimeChange={(event) => {
                                                        const value = event;
                                                        this.setState({
                                                            istirahat_selesai: value + ':00'
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <label className="col-xl-3 f-16 col-md-4"><b>Presensi Pulang</b></label>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Jam Presensi Pulang</label>
                                                <TimeInput
                                                    initTime={this.state.jam_out !== '' &&
                                                    this.state.jam_out !== null ?this.state.jam_out.substr(0, 5) :''}
                                                    className='s-input -time form-control col-xl-2 col-md-4'
                                                    onTimeChange={(event) => {
                                                        const value = event;
                                                        this.setState({
                                                            jam_out: value + ':00'
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Status Presensi
                                                    Pulang</label>
                                                <select name="status_out" className="form-control col-xl-8 col-md-7"
                                                        onChange={this.handleChange}>
                                                    <option value="">Pilih Status</option>
                                                    <option value="Pulang Kerja"
                                                            selected={this.state.status_out === 'Pulang Kerja'}>Pulang
                                                        Kerja
                                                    </option>
                                                    <option value="Sakit"
                                                            selected={this.state.status_out === 'Sakit'}>Sakit
                                                    </option>
                                                    <option value="Izin"
                                                            selected={this.state.status_out === 'Izin'}>Izin
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4">Keterangan Presensi Pulang</label>
                                                <input type="text" name="keterangan_out"
                                                       className="form-control col-xl-8 col-md-7"
                                                       placeholder="Keterangan Presensi..." onChange={this.handleChange}
                                                       defaultValue={this.state.keterangan_out}/>
                                            </div>
                                        </form>
                                    }
                                    <div className="pull-right">
                                        {this.state.prosesSimpan === false ?
                                            <button type="button" className="btn btn-primary"
                                                    onClick={this.simpanAbsensi}>Simpan</button>
                                            :
                                            <button type="button" className="btn btn-primary"><i className="fa fa-refresh fa-spin"></i> Loading</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Edit_absensi
