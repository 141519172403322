import React, { Component } from 'react'
import { Button, Drawer, Form, Select } from 'antd';
import Swal from "sweetalert2";

class EditDivisi extends Component {
    constructor() {
        super();
        this.state = {
            shift: [],
            divisi_payroll_type: ''
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    simpanDivisi = () => {
        let form = {
            id: this.props.data.divisi_id,
            name: this.props.data.divisi_name,
            sift: this.props.data.divisi_sift,
            payroll: this.state.divisi_payroll_type
        }

        this.props.request("divisi/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        divisi_name: ''
                    }, () => this.props.changeState({
                        visable: false,
                        divisi_name: '',
                        divisi_sift: '',
                        data: [],
                        isLoading: true,
                    }, () => {
                        this.props.loadData()
                    }))
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    loadShift() {
        let form = {}
        this.props.request("shift/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        shift: response.data.data
                    })
                }
            });
    }

    componentDidMount() {
        this.loadShift()
    }

    componentWillReceiveProps(newProps) {
        this.setState({ divisi_payroll_type: newProps.data.divisi_payroll_type })
    }

    render() {
        const { Option } = Select;
        return (
            <Drawer
                title="Edit Divisi"
                width={350}
                onClose={() => {
                    this.props.changeState({ visable: false, divisi_name: '', divisi_sift: '' });
                }}
                visible={this.props.data.visable}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({ visable: false, divisi_name: '', divisi_sift: '' });
                        }}
                            style={{ marginRight: 8 }}>
                            Batal
                        </button>
                        <button type="button" className="btn ipresens-btn-color" onClick={this.simpanDivisi}>
                            Simpan
                        </button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Nama Divisi</label>
                        <input name="divisi_name" className="form-control col-xl-12 col-md-12" placeholder="Nama Divisi..."
                            type="text" required="" value={this.props.data.divisi_name}
                            onChange={(event) => {
                                const target = event.target;
                                const value = target.value;
                                const name = target.name;
                                this.props.changeState({
                                    [name]: value
                                });
                            }} />
                    </div>
                    <div className="form-group">
                        <label>Shift Divisi</label>
                        <Select name="divisi_sift"
                            style={{ width: '100%' }}
                            value={this.props.data.divisi_sift}
                            mode={"multiple"}
                            placeholder="Pilih Shift"
                            onChange={(event) => {
                                this.props.changeState({
                                    divisi_sift: event
                                })
                            }}>

                            {this.state.shift.map((item, index) => {
                                return (<Option key={item.sift_id}>{item.sift_name}</Option>)
                            })}
                        </Select>
                    </div>
                    <div className="form-group">
                        <label>Perhitungan Gaji</label>
                        <select
                            name="divisi_payroll_type"
                            className="form-control col-xl-12 col-md-12"
                            value={this.state.divisi_payroll_type}
                            onChange={(event) => this.handleChange(event)}>

                            <option value="">Pilih Perhitungan Gaji</option>
                            <option value="jam">Jam</option>
                            <option value="hari">Hari</option>
                            <option value="bulan">Bulan</option>

                        </select>
                    </div>
                </Form>
            </Drawer>

        )
    }
}

export default EditDivisi
