import React, { Component, Fragment } from 'react'
import { Button, ButtonGroup, Col, FormGroup, Input, Label, Row, } from 'reactstrap'
import { APIKEY } from "../../../../helper/host";
import {  Spin } from "antd";
import { GoogleApiWrapper } from 'google-maps-react';
import Select from 'react-select'
import CurrencyInput from 'react-currency-input';
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {getCurrency} from "../../../../helper/money";

export class Kompensasi_modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isClearable: true,
            term_loading: false,
            pilihTerm: null,
            data_kompensasi: [],
            term: [],
            rows: [{}],
            term_name: '',
            term_id: '',
            finish: false
        }
        this.handleChangeStock = this.handleChangeStock.bind(this);
        this.handleTerm = this.handleTerm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = id => e => {

        const { name, value } = e.target;
        const list = [...this.state.rows];

        list[id] = { ...list[id], [name]: value };

        this.setState({
            rows: list,
        })
    };

    handleAmountRows = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.state.rows];
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        list[id] = { ...list[id], [name]: final };

        this.setState({
            rows: list,
        }, () => this.countTotalRow(id))
    };

    handleQtyRows = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.state.rows];
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        list[id] = { ...list[id], [name]: final };


        this.setState({
            rows: list,
        }, () => this.countTotalRow(id))
    };

    countTotalRow = (id) => {
        var list = [...this.state.rows];

        var data = this.state.rows[id]
        var test = data.p_kompensasi_qty

        var nominal = data.p_kompensasi_amount === undefined ? 0 : data.p_kompensasi_amount.toString().split(',').join('.')
        var qty = data.p_kompensasi_qty === undefined ? 0 : data.p_kompensasi_qty.toString().split(',').join('.')

        var total = eval(nominal) * eval(qty)

        list[id] = { ...list[id], p_kompensasi_total: total };

        this.setState({
            rows: list,
        })
    }

    addClick() {
        this.setState({
            rows: [...this.state.rows, { p_term_id: "", p_kompensasi_amount: "", p_kompensasi_qty: "", p_kompensasi_type: "" }]
        })
    }

    removeClick = (id) => {
        this.state.rows.splice(id, 1);
        this.setState({ rows: this.state.rows });
    };

    handleTerm = (pilihTerm) => {
        this.setState({
            pilihTerm,
            term_id: pilihTerm.value
        });
    }

    handleChangeStock = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.props.kompensasi];
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        list[id] = { ...list[id], [name]: final };


        this.props.updateState({
            kompensasi: list,
        }, () => this.countTotal(id))
    };

    handleChangeStockAmount = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.props.kompensasi];
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        list[id] = { ...list[id], [name]: final };

        this.props.updateState({
            kompensasi: list,
        }, () => this.countTotal(id))
    };

    countTotal = (id) => {
        var list = [...this.state.data_kompensasi];

        var data = this.state.data_kompensasi[id]

        var nominal = data.p_kompensasi_amount.toString().split(',').join('.')
        var qty = data.p_kompensasi_qty.toString().split(',').join('.')

        var total = eval(nominal) * eval(qty)

        list[id] = { ...list[id], p_kompensasi_total: total };

        this.setState({
            data_kompensasi: list,
        })

    }

    handleSubmit(event) {
        this.setState({
            isLoading: true,
            finish: true,
        })

        let formData = {
            rows: this.props.kompensasi,
            data: this.state.rows,
            payroll_id: this.props.payroll_id,
            finish: true,
        }
        this.props.request('payroll/edit-kompensasi', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message)
                    this.setState({
                        isLoading: false,
                        rows: [{}],
                        finishAll: true
                    }, () => {
                        this.props.updateState({
                            selectedIndex: 2
                        })
                        this.props.handleFinish()
                    })
                }
                else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                    this.setState({ isLoading: false, finishAll: false })
                }

                this.setState({ isLoading: false, finishAll: false })
            })
    }

    dataChanged(data, item) {
        let dataval = data.target != null ? data.target.value : null
        let dataval2
        let dataname = data.target != null ? data.target.name : null

        if (dataname == 'pvName') {
            dataval2 = dataval.split(/[\~]/)[0]
            dataval = dataval.split(/[\~]/)[1].substr(1)
        }


        let formData = {
            p_kompensasi_id: item.p_kompensasi_id,
            p_kompensasi_amount: item.p_kompensasi_amount,
            p_kompensasi_qty: item.p_kompensasi_qty,
            p_kompensasi_total: item.p_kompensasi_total,
            p_term_id: dataname == "term" ? dataval : item.payroll_term.p_term_id,
        };

        this.props.request('payroll/edit-select', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message);
                }
                else {
                    if (Array.isArray(response.message)) {

                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                }
                this.loadDataKompensasi()
                this.setState({ isLoading: false })
            })
    }

    loadDataKompensasi() {
        this.setState({
            loading: true,
            isLoading: true
        })
        let form = {
            payroll_id: this.props.payroll_id,
        }
        this.props.request("payroll/get_payroll", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.updateState({
                        kompensasi: response.data
                    })
                    this.setState({
                        data_kompensasi: response.data,
                        term: response.term,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false,
                        isLoading: false
                    })
                }
            });
    }

    wantDelete(kompensasiId) {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus kompensasi payroll?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        isLoading: true,
                        data: []
                    })
                    let form = {
                        id: kompensasiId
                    }
                    this.props.request("payroll/delete-kompensasi", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadDataKompensasi()
                            }
                        });
                }
            })
    }

    componentDidMount() {
        this.loadDataKompensasi()
    }

    render() {
        let no = 0;
        return (
            <Fragment>
                <div>
                    {/*profil Karyawan*/}
                    <div className="card">
                        <div className="card-header">
                            <h4>Daftar Kompensasi</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-xl-12">
                                        <div>
                                            <Row className="mb-2">
                                                <Col className="text-center">
                                                    <Label>Nama Kompensasi</Label>
                                                </Col>
                                                <Col className="text-center">
                                                    <Label>Kompensasi Nominal</Label>
                                                </Col>
                                                <Col className="text-center">
                                                    <Label>Kompensasi Qty</Label>
                                                </Col>
                                                <Col className="text-center">
                                                    <Label>Kompensasi Total</Label>
                                                </Col>
                                                <Col sm="1" className="text-center">
                                                    <Label>Save</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {this.props.kompensasi.map((item, id) => {
                                                        no += 1
                                                        return (
                                                            <Row>
                                                                <Col className="text-center">
                                                                    {item.payroll_term.p_term_prefix === 'general' ?
                                                                        <Select
                                                                            value={{
                                                                                value: item.p_term_id,
                                                                                label: item.payroll_term.p_term_name
                                                                            }}
                                                                            onChange={(val) => {
                                                                                this.dataChanged({ target: { name: "term", value: val.value } }, item)
                                                                            }}
                                                                            options={
                                                                                this.state.term.length != null ? (
                                                                                    this.state.term.map((data, index) =>
                                                                                    (
                                                                                        {
                                                                                            key: index,
                                                                                            value: data.p_term_id,
                                                                                            label: data.p_term_name
                                                                                        }
                                                                                    )
                                                                                    )
                                                                                ) : ("")
                                                                            }
                                                                            defaultValue={{
                                                                                value: item.term_id,
                                                                                label: item.payroll_term.p_term_id
                                                                            }}
                                                                        /> :
                                                                        <Label bsSize="sm" type="select" name="p_term_id" value={item.p_term_id} >
                                                                            <h6>{item.payroll_term.p_term_name}</h6>
                                                                        </Label>
                                                                    }


                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <CurrencyInput
                                                                            name="p_kompensasi_amount"
                                                                            className="form-control"
                                                                            thousandSeparator="."
                                                                            value={item.p_kompensasi_amount}
                                                                            prefix={getCurrency()}
                                                                            id="p_kompensasi_amount"
                                                                            precision={0}
                                                                            onChangeEvent={this.handleChangeStock(id)}
                                                                        />

                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <CurrencyInput
                                                                            name="p_kompensasi_qty"
                                                                            className="form-control"
                                                                            thousandSeparator="."
                                                                            value={item.p_kompensasi_qty}
                                                                            id="p_kompensasi_qty"
                                                                            decimalSeparator=","
                                                                            precision={2}
                                                                            onChangeEvent={this.handleChangeStockAmount(id)}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <CurrencyInput
                                                                            style={{ backgroundColor: '#f0f0f0' }}
                                                                            readOnly
                                                                            className="form-control"
                                                                            thousandSeparator="."
                                                                            value={item.p_kompensasi_total}
                                                                            prefix={getCurrency()}
                                                                            precision={0}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>

                                                            </Row>
                                                        )

                                                    })}

                                                </Col>
                                                <Col sm="1" className="text-center">


                                                        <Button
                                                            onClick={this.handleSubmit.bind(this)}
                                                            disabled={this.state.isLoading}
                                                            color="success"
                                                        >
                                                            {!this.state.isLoading ?
                                                                <i className="fa fa-save" />
                                                                :
                                                                <i className="fa fa-refresh fa-spin" />
                                                            }
                                                        </Button>
                                                </Col>
                                            </Row>
                                            {this.state.rows.map((item, id) => {
                                                return (
                                                    <Row>
                                                        <Col>
                                                            <Input bsSize="sm" type="select" name="p_term_id" value={item.p_term_id}
                                                                onChange={this.handleChange(id)}>
                                                                <option value="" >- Nama kompensasi -</option>
                                                                {
                                                                    this.state.term.map((item, index) => {
                                                                        return (
                                                                            <option
                                                                                key={index}
                                                                                value={item.p_term_id}
                                                                                selected={item.p_term_id == this.state.term.p_term_id}>
                                                                                {item.p_term_name}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </Input>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <CurrencyInput
                                                                    name="p_kompensasi_amount"
                                                                    className="form-control"
                                                                    thousandSeparator="."
                                                                    value={item.p_kompensasi_amount}
                                                                    prefix={getCurrency()}
                                                                    id="p_kompensasi_amount"
                                                                    precision={0}
                                                                    onChangeEvent={this.handleAmountRows(id)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <CurrencyInput
                                                                    name="p_kompensasi_qty"
                                                                    className="form-control"
                                                                    thousandSeparator="."
                                                                    value={item.p_kompensasi_qty}
                                                                    id="p_kompensasi_qty"
                                                                    decimalSeparator=","
                                                                    precision={2}
                                                                    onChangeEvent={this.handleQtyRows(id)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <CurrencyInput
                                                                    style={{ backgroundColor: '#f0f0f0' }}
                                                                    readOnly
                                                                    className="form-control"
                                                                    thousandSeparator="."
                                                                    value={item.p_kompensasi_total}
                                                                    prefix={getCurrency()}
                                                                    precision={0}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="1">
                                                            <ButtonGroup size="sm">
                                                                <div>
                                                                    {this.state.rows.length !== 1 &&
                                                                        <Button
                                                                            onClick={this.removeClick.bind(this, id)}
                                                                            color='danger'
                                                                            style={{ marginBottom: '10px' }}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </Button>}
                                                                    {this.state.rows.length - 1 === id &&
                                                                        <Button
                                                                            onClick={this.addClick.bind(this)}
                                                                            color="info"
                                                                        >
                                                                            <i className="fa fa-plus"></i>
                                                                        </Button>
                                                                    }
                                                                </div>
                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}

                                        </div>
                                    {this.state.loading &&
                                        <Row>
                                            <Col colSpan={9}>
                                                <Spin size="large" tip="Loading..." style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                    marginBottom: '10px',
                                                    marginTop: '10px'
                                                }} />
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: APIKEY
})(Kompensasi_modal)
