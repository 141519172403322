import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import moment from 'moment';
import { APIKEY, IMG_HOST } from "../../helper/host";
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { Spin } from 'antd';
import { Helmet } from "react-helmet";

export class Detail_kunjungan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalmasuk: false,
            modalpulang: false,
            lokasi: [],
            data: [],
            lembur: [],
            karyawan: [],
            divisi: [],
            loading: true,

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    showModalMasuk = () => {
        this.setState({
            modalmasuk: true,
        });
    };

    handleOk = e => {
        this.setState({
            modalmasuk: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modalmasuk: false,
        });
    };

    loadData() {
        let form = {
            id: this.props.match.params.id
        }
        this.setState({
            loading: true,
        })
        this.props.request("outlet/detail_kunjungan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        karyawan: response.data.karyawan,
                        outlet: response.data.outlet,
                        latitude: response.data.latitude_kunjungan,
                        longitude: response.data.longitude_kunjungan,
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        const stylefoto = {
            maxHeight: '300px',
            maxWidth: '150px',
        }
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Detail Kunjungan - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <Breadcrumb title="Detail Kunjungan" parent="Kunjungan" parentLink='/kunjungan' />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="profile-details text-center">
                                        <div className="row">
                                            {this.state.loading ?
                                                <Spin size="large" tip="Sedang memuat data..." style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                    marginBottom: '10px',
                                                    marginTop: '10px'
                                                }} />
                                                :
                                                <>
                                                    <div className="col-md-12">
                                                        <p className="f-w-600 mb-0" style={{ color: 'black' }}><b>Detail Kunjungan</b></p><br />
                                                        {this.state.data.foto_kunjungan !== null || this.state.data.foto_kunjungan !== "" ? (
                                                            <img
                                                                src={IMG_HOST + 'frontapp/kunjungan/' + this.state.data.foto_kunjungan}
                                                                style={{ width: "80%", height: "auto" }}
                                                                className="img-fluid blur-up lazyloaded" />) : (
                                                            <img src={IMG_HOST + 'user-profile.png'}
                                                                style={{ maxWidth: "100%", maxHeight: "250px" }}
                                                                className="img-fluid blur-up lazyloaded" />
                                                        )}
                                                        <br /><b>{this.state.data.jam_kunjungan_absen}</b><br />

                                                        <div style={{
                                                            position: 'relative',
                                                            width: '100%',
                                                            height: '250px',
                                                            marginTop: '10px'
                                                        }}>
                                                            <Map google={this.props.google}
                                                                className={'map'}
                                                                onClick={this.onMapClicked}
                                                                initialCenter={{
                                                                    lat: this.state.data.latitude_kunjungan,
                                                                    lng: this.state.data.longitude_kunjungan
                                                                }}

                                                                zoom={14}>
                                                                <Marker />
                                                            </Map>
                                                        </div>

                                                    </div>

                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className="card">
                                {this.state.loading ?
                                    <div className="card-body">
                                        <Spin size="large" tip="Sedang memuat data..." style={{
                                            width: '100%',
                                            margin: 'auto',
                                            marginBottom: '10px',
                                            marginTop: '10px'
                                        }} />
                                    </div>
                                    :
                                    <div className="card-body">
                                        <label
                                            className="f-20"><b>kunjungan {this.state.data.absen_hari} / {moment(this.state.data.tanggal_kunjungan).format('ll')}</b>
                                        </label>
                                        <tbody>
                                            <tr>
                                                <td width="200">
                                                    <b>Nama Karyawan</b>
                                                </td>
                                                <td width="20">
                                                    :
                                                </td>
                                                <td>
                                                    {this.state.karyawan.karyawan_nama}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Lokasi Kunjungan</b>
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {this.state.outlet.outlet_nama}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Keterangan Kunjungan</b>
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {this.state.data.keterangan_kunjungan !== 'null' && this.state.data.keterangan_kunjungan !== '' && this.state.data.keterangan_kunjungan !== null ? this.state.data.keterangan_kunjungan : 'Keterangan Kosong'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </div>
                                }

                            </div>
                            <div className="col-md-12">
                                <div className="card">
                                    {this.state.loading ?
                                        <div className="card-body">
                                            <Spin size="large" tip="Sedang memuat data..." style={{
                                                width: '100%',
                                                margin: 'auto',
                                                marginBottom: '10px',
                                                marginTop: '10px'
                                            }} />
                                        </div>
                                        :
                                        <div className="card-body">

                                            <table width='100%'>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">
                                                            LAMPIRAN 1
                                                        </th>
                                                        <th className="text-center">
                                                            LAMPIRAN 2
                                                        </th>
                                                        <th className="text-center">
                                                            LAMPIRAN 3
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center">
                                                            {this.state.data.lampiran_kunjungan_1 !== null ? (
                                                                <a className="ipresens-text-link" href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.data.lampiran_kunjungan_1} target={"_blank"}>
                                                                    <img style={stylefoto}
                                                                        src={IMG_HOST + 'frontapp/kunjungan/' + this.state.data.lampiran_kunjungan_1} />
                                                                </a>) : (
                                                                <i>Lampiran Kosong</i>)}
                                                        </td>
                                                        <td className="text-center">
                                                            {this.state.data.lampiran_kunjungan_2 !== null ? (
                                                                <a className="ipresens-text-link" href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.data.lampiran_kunjungan_2} target={"_blank"}>
                                                                    <img style={stylefoto}
                                                                        src={IMG_HOST + 'frontapp/kunjungan/' + this.state.data.lampiran_kunjungan_2} />
                                                                </a>) : (
                                                                <i>Lampiran Kosong</i>)}
                                                        </td>
                                                        <td className="text-center">
                                                            {this.state.data.lampiran_kunjungan_3 !== null ? (
                                                                <a className="ipresens-text-link" href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + this.state.data.lampiran_kunjungan_3} target={"_blank"}>
                                                                    <img style={stylefoto}
                                                                        src={IMG_HOST + 'frontapp/kunjungan/' + this.state.data.lampiran_kunjungan_3} />
                                                                </a>) : (
                                                                <i>Lampiran Kosong</i>)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: APIKEY
})(Detail_kunjungan)
