import React, {Component} from 'react';
import i18n from "i18next";
//css
import "../iPresens-style.css"


export class Breadcrumb extends Component {
    render() {
        return (
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="page-header-left">
                                    <h3 className="ipresens-text-color">
                                        <small className="mb-1">iPresens Admin panel {this.props.header}</small>
                                        {this.props.title}
                                    </h3>
                                </div>
                                {!this.props.hideBreadcumb && (
                                <ol className="breadcrumb mt-2">
                                    <li className="breadcrumb-item active"><a href="/dashboard" style={{color: '#5D6F80'}}>{i18n.t("beranda")}</a></li>
                                    {this.props.parent && (
                                        <li className="breadcrumb-item active"><a href={this.props.parentLink} style={{color: '#5D6F80'}}>{this.props.parent}</a></li>
                                    )}
                                    <li className="breadcrumb-item">{this.props.title}</li>
                                    <li>&nbsp;&nbsp;<i className="fa fa-info-circle" style={{color: '#057CE4'}} onClick={this.props.setGuide}></i></li>
                                </ol>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default Breadcrumb
