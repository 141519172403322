import React, { Component } from 'react'
import {Form} from 'antd';
import Swal from "sweetalert2";
import i18n from "i18next";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class ShiftAdd extends Component {
    constructor() {
        super();
        this.state = {
            sift_name: '',
            sift_late_tolerance: '',
            loading: false
        }
    }
    handleSubmit = () => {
        this.setState({
            loading: true
        })
        let form = {
            sift_name: this.state.sift_name,
            sift_late_tolerance: this.state.sift_late_tolerance,
        }

        this.props.request("shift/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    const filter_data = JSON.parse(localStorage.getItem('Presensi-Company-Data'))
                    if (filter_data && filter_data._sift) {
                        filter_data._sift.push({
                            sift_id: response.data.sift_id,
                            perusahaan_id: response.data.perusahaan_id,
                            sift_name: response.data.sift_name,
                            sift_late_tolerance: response.data.sift_late_tolerance,
                            sift_create_date: response.data.sift_create_date,
                            sift_status: response.data.sift_status
                        });
                        localStorage.setItem('Presensi-Company-Data', JSON.stringify(filter_data));
                    }
                    this.setState({ sift_name: '', sift_late_tolerance: '' })
                    this.props.changeState({
                        sift_name: '',
                        sift_late_tolerance: '',
                        add_visible: false,
                        data: [],
                        isLoading: true,
                    }, () => {
                        this.props.loadData()
                        this.props.loadCheck()
                    })
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false
                })
            });
    }

    handleChange = (event) => {
        const { name, value } = event.target

        this.setState({
            [name]: value,
        })
    }

    render() {
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.add_visible} toggle={() => this.props.changeState({ add_visible: false })} style={{ maxWidth: "1000px" }}>
                <ModalHeader>
                    <div className='text-dark' style={{
                        fontWeight: 'bold', fontSize: "18px"
                    }}>{i18n.t("tambahshift")}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="">
                        <Form layout="vertical" hideRequiredMark>
                            <div className="form-group">
                                <label>{i18n.t("namashift")}</label>
                                <input name="sift_name" className="form-control col-xl-12 col-md-12"
                                       placeholder={i18n.t("namashift")+("...")}
                                       type="text"
                                       required=""
                                       value={this.state.sift_name}
                                       onChange={this.handleChange}/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("toleransitelat")} ({i18n.t("menit")})</label>
                                <input name="sift_late_tolerance"
                                       className="form-control col-xl-12 col-md-12"
                                       placeholder={i18n.t("toleransiplaceholder")}
                                       type="number"
                                       required
                                       value={this.state.sift_late_tolerance}
                                       onChange={this.handleChange}/>
                            </div>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({add_visible: false, desc: ''});
                        }}
                                style={{marginRight: 8}}>
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color"
                                disabled={this.state.loading} onClick={this.handleSubmit}>
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ShiftAdd
