export const INIT_OTHER = 'OTHER/INIT';
export const SET_OTHER = 'OTHER/SET';
export const CLEAR_OTHER = 'OTHER/CLEAR';

export const init_other = (lang) => ({
    type: INIT_OTHER,
    lang: lang,
});

export const set_other = (lang) => ({
    type: SET_OTHER,
    lang: lang
});

export const clear_other = () => ({
    type: CLEAR_OTHER
});

export default function reducer(state = {
    lang: '',
}, action) {
    switch (action.type) {
        case INIT_OTHER:
            return {
                ...state,
                lang: action.lang,
            }

        case SET_OTHER:
            localStorage.setItem('Presensi-Other', true)
            localStorage.setItem('Presensi-Other-Data', JSON.stringify(action.lang))

            return {
                ...state,
                auth: action.lang,
            };
        case CLEAR_OTHER:
            localStorage.clear()

            return {
                ...state,
                lang: ''
            };
    }

    return state;
}
