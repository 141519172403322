import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import moment from "moment";
import { Empty, Modal, Spin } from "antd";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { convertToRupiah, pageNumber } from "../../functions/functionGeneral";
import presensiHeaderModalBG from "../../assets/images/modal_header_bg.png";
import presensiWhiteLogo from "../../assets/images/presensi_white_logo.png";
import Paging from "../paging";
import i18n from "i18next";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake";
import {Input, Label, Modal as ModalRs, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {set_auth} from "../../reducers/Auth";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {hitungBiayaAdmin} from "../../helper/money";
import ArrowRightUp from "../../assets/icons/arrow-right-up.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import PaketPlatinum from "../../assets/icons/paket-platinum.svg";
import PaketEnterprise from "../../assets/icons/paket-enterprise.svg";
import PaketDiamonds from "../../assets/icons/paket-diamonds.svg";
import PaketGold from "../../assets/icons/paket-gold.svg";
import PaketSilver from "../../assets/icons/paket-silver.svg";
import PaketBasic from "../../assets/icons/paket-basic.svg";
import PaketFree from "../../assets/icons/paket-free.svg";
import format from "format-number";

export class Langganan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            data_invoice: [],
            perusahaan: [],
            langganan: [],
            paket: [],
            voucher: [],
            voucher_code: '',
            invoice_id: '',
            perusahaan_id: '',
            idDel: '',
            renew_month: '',
            upgrade_month: '',
            upgrade_paket: null,
            modalItem: null,
            modalItemDownload: null,
            modalDetail: false,
            modalDetailDownload: false,
            pageCount: 0,
            currentPage: 0,
            pagination: 4,
            currentPageHistory: 0,
            dataHistory: [],
            modalHistory: false,
            loadingHistory: false,
        }

        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleChange = this.handleChange.bind(this);
        this.handlePageHistory = this.handlePageHistory.bind(this);
    }

    handleOk = e => {
        this.setState({
            modalDetail: false,
            voucher_code: '',
        });
    };

    handleCancel = e => {
        this.setState({
            modalDetail: false,
            voucher_code: '',
        });
    };

    handleCancelDownload = e => {
        this.setState({
            modalDetailDownload: false,
        });
    };

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadDataInvoice();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    bayarTagihan = (item) => {
        let form = {
            invoice_no: item.invoice_no,
            type: this.state.metode_bayar,
        }
        this.props.request("paket/pay", form, 'POST')
            .then(response => {
                window.location.replace(response)
            });
    }

    klaimVoucher = () => {
        let form = {
            invoice_id: this.state.modalItem.invoice_id,
            voucher_code: this.state.voucher_code,
        }
        this.props.request("paket/rendem_voucher", form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        modalDetail: true,
                        loading: true
                    }, () =>
                    {
                        this.loadDataInvoice()
                        this.loadInvoiceDetail()
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                    })
                }
            })
    }

    loadInvoiceDetail = () => {
        let form = {
            invoice_id: this.state.modalItem.invoice_id
        }
        this.props.request("detail_invoice", form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        modalItem: response.data,
                        loading: false,
                    })
                }

            });
    }

    printInvoice = () => {
        html2canvas(document.getElementById("invoicecontent")).then(canvas => {
            var data = canvas.toDataURL();
            var pdfExportSetting = {
                content: [
                    {
                        image: data,
                        width: 500
                    }
                ]
            };
            pdfMake.createPdf(pdfExportSetting).download("Invoice_iPresens_" + this.state.modalItem.invoice_no + '.pdf');
        });
    };

    wantPaket() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menganti paket?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loading: true,
                        data: []
                    })
                    let form = {
                        paket_id: this.state.upgrade_paket.paket_id,
                        month: this.state.upgrade_month,
                    }
                    this.props.request("paket/update_paket", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    loading: false,
                                    modalUpgrade: false,
                                    upgrade_month: '',
                                    data: []
                                })
                                window.location.reload();
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                    showConfirmButton: false,
                                    timer: 1200,
                                })
                                this.setState({
                                    loading: false,
                                    modalUpgrade: false,
                                    upgrade_month: '',
                                    data: []
                                })
                            }
                        });
                }
            })
    }

    renewPaket() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin memperpanjang paket?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loading: true,
                        data: []
                    })
                    let form = {
                        paket_id: this.state.langganan.paket.paket_id,
                        month: this.state.renew_month,
                    }
                    this.props.request("paket/perpanjang-paket", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                let form = {
                                    page: 1,
                                }
                                this.props.request("get_invoice", form, 'POST')
                                    .then(response => {
                                        if (response.success) {
                                            this.setState({
                                                data_invoice: response.data.data,
                                                loading: false,
                                            })
                                            this.setState({
                                                modalRenew: false,
                                                modalDetail: true,
                                                modalItem: this.state.data_invoice[0]
                                            })
                                        }
                                    });
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                    showConfirmButton: false,
                                    timer: 1200,
                                })
                                this.setState({
                                    loading: false,
                                    data: []
                                })
                            }
                        });
                }
            })
    }

    stopLangganan() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghentikan langganan?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loading: true,
                    })
                    let form = {
                    }
                    this.props.request("stop-langganan", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    loading: false,
                                    data: []
                                })
                                this.props.clear_auth();
                                window.location.reload();
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                    showConfirmButton: false,
                                    timer: 1200,
                                })
                                this.setState({
                                    loading: false,
                                    data: []
                                })
                            }
                        });
                }
            })
    }

    loadPaket() {
        let form = {}
        this.props.request("paket/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        paket: response.data.data,
                        loading: false,
                    })
                }

            });
    }

    loadData() {
        this.setState({ loading: true })
        let form = {}
        this.props.request("dashboard", form, 'POST')
            .then(response => {
                if (response.success) {
                    var langganan = response.expired_date
                    this.setState({
                        data: response,
                        langganan: langganan
                    })
                }
                this.setState({ loading: false })

            });

    }

    loadDataUser() {
        this.setState({
            loading: true
        })
        let form = {}
        this.props.request("get_company", form, 'POST')
            .then(response => {
                if (response) {
                    this.props.set_auth(response.user?.api_token, response.user);
                    this.setState({
                        data_user: response.data,
                        upgrade_paket: response.upgrade_paket,
                        karyawan_status: response.karyawan,
                        jumlah_karyawan: response.jumlah_karyawan,
                        max_karyawan: response.maks_karyawan,
                    })

                }
            })
        this.setState({
            loading: false
        })
    }

    loadDataInvoice() {
        let form = {
            page: this.state.currentPage + 1,
        }
        this.props.request("get_invoice", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_invoice: response.data.data,
                        perusahaan: response.perusahaan,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                } else {
                    this.setState({
                        data: null
                    })
                }
            });
    }

    loadHistory() {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                perusahaan_id: this.props.user.perusahaan.perusahaan_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("paket/history-langganan", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            perPageHistory: response.data.per_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.total,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            perusahaan_id: ''
                        });
                    }
                });
        })
    }

    componentDidMount() {
        this.loadData()
        this.loadPaket()
        this.loadDataUser()
        this.loadDataInvoice()
    }

    render() {
        let no = 0;
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Langganan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Langganan - iPresens</title>
                        <meta name="description" content="Detail Paket" />
                    </Helmet>
                    <Breadcrumb title={i18n.t('langganan')} setGuide={() => { this.setState({ guide: true }) }} />
                    <div className="container-fluid list-paket">
                        <section id="harga" className="section-lr feature-section mb-5">
                            <div className="container-fluid">
                                {this.state.langganan && this.state.langganan.langganan_status === "pending" || this.state.langganan.langganan_status === "expired" ? (
                                    <div className="alert alert-warning" role="alert">
                                        {i18n.t("pesanlanggananberakhir")}
                                        {this.state.data_invoice.length > 0 ? (
                                            <a onClick={() => {
                                                this.setState({
                                                    modalDetail: true,
                                                    modalItem: this.state.data_invoice[0]
                                                })
                                            }}><b> #{this.state.data_invoice[0].invoice_no} </b></a>
                                        ) : " "}
                                        {i18n.t("untukmengakses")}
                                    </div>
                                ) : null}
                                <div className="row">
                                    <div className=" col">
                                        <div className="card mb-2 ">
                                            <div className="card-body">
                                                <div className="media-body">
                                                    <div className="row mb-lg-4 mb-sm-0">
                                                        <div className="col">
                                                            <div className="mb-2">
                                                                {this.state.langganan.paket_nama &&
                                                                    <div className="row" style={{margin: "auto"}}>
                                                                        <span
                                                                            className="bg-blue-dark text-white p-1 rounded">
                                                                            <strong>{this.state.langganan.paket_nama.toUpperCase()}</strong>
                                                                        </span>
                                                                        {this.props.user && this.props.user.perusahaan.langganan && this.props.user.perusahaan.langganan.paket?.paket_premium == "1" &&
                                                                            <span
                                                                                className="bg-red text-gold p-1 ml-1 rounded">
                                                                                <strong>PREMIUM+</strong>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div>
                                                                <span>{i18n.t("berakhirpada")}&nbsp;</span>
                                                                <strong>
                                                                    {moment(this.state.langganan && this.state.langganan.langganan_expired_date).format('ll')}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            {this.state.langganan && this.state.langganan.paket &&
                                                                <span><sup>Rp</sup><strong
                                                                    className="fs-24">{convertToRupiah((this.state.langganan.paket.paket_harga / this.state.langganan.paket.paket_bulan))}</strong><sup
                                                                    className="text-disable">/{i18n.t("bulan")}</sup></span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="mt-lg-4 mt-sm-2">
                                                        <h6>{i18n.t("karyawanmaksimal")}<span
                                                            className="pull-right">{this.state.jumlah_karyawan} / {this.state.max_karyawan} ({this.state.karyawan_status && this.state.karyawan_status.toFixed(2)}%)</span>
                                                        </h6>
                                                        <div className="progress lg-progress-bar">
                                                            <div className="progress-bar bg-primary" role="progressbar"
                                                                 style={{width: this.state.karyawan_status + '%'}}
                                                                 aria-valuenow="25" aria-valuemin="0"
                                                                 aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="row justify-content-between">
                                                    <div className="row ml-2">
                                                        <div className="px-2 mt-1">
                                                            <a
                                                                className="btn btn-langganan-custom-1 px-2 rounded"
                                                                style={{color: "#6F5D80"}}
                                                                type="button"
                                                                onClick={() => {
                                                                    this.stopLangganan()
                                                                }}>
                                                                <u style={{fontSize: "13px"}}>{i18n.t("berhentilangganan")}</u>
                                                            </a>
                                                        </div>
                                                        <div className="px-1 mt-1">
                                                            <a
                                                                className="btn btn-langganan-custom-2 bg-yellow px-2 rounded"
                                                                style={{color: "#38115F"}}
                                                                type="button"
                                                                onClick={() => {
                                                                    this.setState({modalRenew: true})
                                                                }}>
                                                                {i18n.t("perpanjangpaket")}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="row mr-2">
                                                        <div className="col-auto px-0 mt-1">
                                                            <button
                                                                className="btn-langganan-custom bg-white px-2 rounded border-0">
                                                                <a
                                                                    className="bg-white"
                                                                    style={{color: "#8c2bee", fontWeight: "bold"}}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            modalHistory: true,
                                                                            perusahaan_id: this.state.perusahaan_id
                                                                        }, () => {
                                                                            this.loadHistory(this.state.perusahaan_id);
                                                                        })
                                                                    }}>{i18n.t("riwayatlangganan")}
                                                                    <img src={ArrowRightUp} alt=''/>
                                                                </a>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.upgrade_paket &&
                                        <div className="col">
                                            <div className="card bg-blue-dark">
                                                <div className="card-body">
                                                    <div className="media-body">
                                                        <span className="text-white">
                                                            <strong>{i18n.t("upgradepaketmu")} {this.state.upgrade_paket.paket_nama} {i18n.t("dapatkanmax")} {this.state.upgrade_paket.paket_jumlah} {i18n.t("karyawanhanyadengan")} {convertToRupiah(this.state.upgrade_paket.paket_harga / this.state.upgrade_paket.paket_bulan, true)}/{i18n.t("bulan")}</strong>
                                                        </span>
                                                        <p className="text-white mt-2">{this.state.upgrade_paket.paket_nama} {i18n.t("sesuaiuntuk")} {this.state.upgrade_paket.paket_desc}</p>
                                                        <div className="row">
                                                            <div className="col mb-2">
                                                                <a className="btn btn-outline-custom-none bg-white btn-block text-dark"
                                                                   href={"/subscription/package"}>{i18n.t("lihatpaketlain")}</a>
                                                            </div>
                                                            <div className="col">
                                                                <a className="btn btn-outline-custom-none bg-yellow btn-block"
                                                                   onClick={() => {
                                                                       this.setState({modalUpgrade: true})
                                                                   }}>{i18n.t("upgradeke")} {this.state.upgrade_paket.paket_nama}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="card profile-card mt-2">
                                    <div className="card-body">
                                        {!this.state.isLoading && this.state.data_invoice.length === 0 ?
                                            <Empty description={i18n.t("datakosong")}
                                                   image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                            :
                                            <div>
                                                <Table className="table table-striped table-borderless mb-5" id="list-invoice">
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="30" height={50}>#</Th>
                                                            <Th>{i18n.t("haritanggal")}</Th>
                                                            <Th className={"text-center"}>No. Invoice</Th>
                                                            <Th className={"text-center"}>{i18n.t("paket")}</Th>
                                                            <Th className={"text-center"}>{i18n.t("tagihan")}</Th>
                                                            <Th className={"text-center"}>Status</Th>
                                                            <Th></Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {this.state.data_invoice.map((item, index) => {
                                                            no += 1
                                                            return (
                                                                <Tr className="border-bottom-light">
                                                                    <Td height={50}>
                                                                        <small>
                                                                            <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                                        </small>
                                                                    </Td>
                                                                    <Td align="center"><span
                                                                        className="pb-2">{item.invoice_create_date}</span></Td>
                                                                    <Td align="center">#{item.invoice_no}</Td>
                                                                    <Td align="center">{item.paket.paket_nama}</Td>
                                                                    <Td align="center">{convertToRupiah(item.invoice_nominal, true)}</Td>
                                                                    <Td align="center"><span
                                                                        className={item.invoice_status == 'pending' ? "text-warning" : item.invoice_status == 'expired' ? "text-secondary" : "text-success"}>{item.invoice_status == 'pending' ? i18n.t("menunggupembayaran") : item.invoice_status == 'expired' ? i18n.t("kadaluarsa") : i18n.t("lunas")}</span></Td>
                                                                    <Td className={"text-right"}>
                                                                        {item.invoice_status == 'pending' ?
                                                                            <button className="btn btn-primary px-2"
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            modalDetail: true,
                                                                                            modalItem: item
                                                                                        })
                                                                                    }}>{i18n.t("bayar")}</button>
                                                                            : <a onClick={() => {
                                                                                this.setState({
                                                                                    modalDetail: true,
                                                                                    modalItem: item
                                                                                })
                                                                            }} className="text-primary">Detail</a>
                                                                        }
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        })}
                                                        {this.state.isLoading &&
                                                            <Tr>
                                                                <Td colSpan="3">
                                                                    <Spin style={{width: '100%'}} size="small"
                                                                          tip={i18n.t("mohontunggu")}/>
                                                                </Td>
                                                            </Tr>
                                                        }
                                                    </Tbody>
                                                </Table>
                                                <Paging
                                                    className="float-right"
                                                    handlePageClick={this.handlePageClick}
                                                    pageCount={this.state.pageCount}
                                                    currentPage={this.state.currentPage}
                                                />
                                            </div>

                                        }
                                    </div>
                                </div>
                                <Modal
                                    title=""
                                    visible={this.state.modalDetail}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={[
                                        this.state.modalItem && (
                                            <div className="row justify-content-between px-4">
                                                <button onClick={() => {
                                                    this.setState({modalDetailDownload: true, modalItemDownload: true})
                                                }} className={'btn btn-outline-custom bg-light bg-white mr-2'}
                                                        style={{textTransform: "none"}}><i className="fa fa-download"/>
                                                </button>
                                                <div>
                                                    {this.state.modalItem.invoice_status == 'pending' ?
                                                        <div className={"row"}>
                                                            <div className={"col-auto"}>
                                                                <select name="metode_bayar"
                                                                        className="form-control bg-white karyawan-filter-jabatan mr-1"
                                                                        onChange={this.handleChange}>
                                                                    <option value="">Pilih Metode Bayar</option>
                                                                    <option value="credit_card">Kartu Kredit</option>
                                                                    <option value="bank_transfer">Bank Transfer</option>
                                                                    <option value="shopeepay">ShopeePay</option>
                                                                    <option value="indomaret">Indomaret</option>
                                                                    <option value="alfamart">Alfamart</option>
                                                                    <option value="akulaku">Akulaku Paylater</option>
                                                                    <option value="other_qris">QRIS</option>
                                                                </select>
                                                            </div>
                                                            <button onClick={() => {
                                                                this.bayarTagihan(this.state.modalItem)
                                                            }}
                                                                    className={'btn ' + (this.state.metode_bayar ? 'bg-primary' : 'bg-disabled')}
                                                                    style={{textTransform: "none"}}
                                                                    disabled={!this.state.metode_bayar}>Bayar Sekarang
                                                            </button>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    ]}
                                >
                                    <div className="mb-4"></div>
                                    {this.state.modalItem && (
                                        <div className="bg-white shadow pt-0 pr-3 pl-3 pb-3">
                                            <div
                                                className="row bg-ipresens-dark-purple p-2 text-white border-ipresens-main"
                                                style={{
                                                    backgroundImage: `url(${presensiHeaderModalBG})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'left center'
                                                }}>
                                                <div className="col-4 d-flex align-items-center">
                                                    <img src={presensiWhiteLogo} alt="Logo" style={{height: '26px'}}/>
                                                </div>
                                                <div className="col-8 text-right">
                                                    <b>{moment().format("dddd, MMMM Do YYYY")}</b><br/>
                                                    <span>{this.state.perusahaan.perusahaan_nama}</span>
                                                </div>
                                            </div>
                                            <div className="row p-4">
                                                <div style={{
                                                    backgroundColor: this.state.modalItem.invoice_status == 'pending' ? '#FFF6E8' : this.state.modalItem.invoice_status == 'expired' ? '#F5F5F5' : '#E8F2E7',
                                                    color: this.state.modalItem.invoice_status == 'pending' ? '#FF9C00' : this.state.modalItem.invoice_status == 'expired' ? '#5D6F80' : '#6BAA61',
                                                    fontSize: 16,
                                                    textTransform: "uppercase",
                                                    fontWeight: "bold"
                                                }}
                                                     className="w-100 text-center p-2">{this.state.modalItem.invoice_status == 'pending' ? "Menunggu Pembayaran" : this.state.modalItem.invoice_status == 'expired' ? "Kadaluarsa" : "Lunas"}</div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-8">
                                                    <h5 className="text-blue-dark">
                                                        <b>{this.state.perusahaan.perusahaan_nama}</b></h5>
                                                    <span
                                                        className="text-secondary">{this.state.perusahaan.perusahaan_alamat}</span>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <h5 className="text-blue-dark"><b>INVOICE</b></h5>
                                                    <span
                                                        className="text-secondary">#{this.state.modalItem.invoice_no}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h6 className="text-secondary mb-0"><b>Ringkasan Biaya</b></h6>
                                                    <span>{this.state.modalItem.paket.paket_nama} ({this.state.modalItem.invoice_lama_bulan} Bulan)</span>
                                                </div>
                                                <div className="col text-right">
                                                    <h5 className="text-secondary mb-0"><b>Harga</b></h5>
                                                    <span>{convertToRupiah(this.state.modalItem.invoice_nominal, true)}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h6 className="text-secondary mb-0"><b>Potongan Sisa</b></h6>
                                                </div>
                                                <div className="col text-right">
                                                    <span>{convertToRupiah(this.state.modalItem.potongan_sisa, true)}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h6 className="text-secondary mb-0"><b>Biaya Admin</b></h6>
                                                </div>
                                                <div className="col text-right">
                                                    <span>{convertToRupiah(this.state.modalItem.invoice_status == 'pending' ? hitungBiayaAdmin(this.state.metode_bayar, this.state.modalItem.invoice_nominal) : hitungBiayaAdmin(this.state.modalItem.invoice_payment_type, this.state.modalItem.invoice_nominal), true)}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h6 className="text-secondary mb-0"><b>PPN (11%)</b></h6>
                                                </div>
                                                <div className="col text-right">
                                                    <span>{convertToRupiah(this.state.modalItem.invoice_tax, true)}</span>
                                                </div>
                                            </div>
                                            {this.state.modalItem.invoice_voucher != null &&
                                                <div className="row">
                                                    <div className="col">
                                                        <h6 className="text-secondary mb-0"><b>Voucher Promo</b></h6>
                                                    </div>
                                                    <div className="col text-right">
                                                        <span>{convertToRupiah(this.state.modalItem.potongan_voucher, true)}</span>
                                                    </div>
                                                </div>
                                            }
                                            {this.state.modalItem.invoice_status != 'success' && this.state.modalItem.invoice_voucher == null &&
                                                <div className="row mt-4">
                                                    <div className="col mb-2">
                                                        <div className="input-group mb-0">
                                                            <input type="text" className="form-control-custom"
                                                                   placeholder={"Kode Voucher"} name="voucher_code"
                                                                   onChange={this.handleChange}
                                                                   value={this.state.voucher_code}/>
                                                            <button className={'btn btn-outline-custom-2'} type="button"
                                                                    onClick={this.klaimVoucher}>Klaim
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="text-right mt-4 text-dark">
                                                <h5>
                                                    <b>Total {convertToRupiah(this.state.modalItem.total_bayar - this.state.modalItem.potongan_voucher, true)}</b>
                                                </h5>
                                            </div>
                                            <div className="text-center text-secondary bg-light">
                                                <span>Presensi.co.id @2020</span>
                                            </div>
                                        </div>
                                    )}
                                </Modal>
                                <Modal
                                    title=""
                                    visible={this.state.modalDetailDownload}
                                    onCancel={this.handleCancelDownload}
                                    footer={[
                                        this.state.modalItemDownload && (
                                            <div>
                                                <button onClick={() => {
                                                    this.printInvoice()
                                                }} className={'btn btn-outline-custom bg-light bg-white'}
                                                        style={{textTransform: "none"}}><i
                                                    className="fa fa-download"/>&nbsp;Download Sekarang
                                                </button>
                                                &nbsp;
                                            </div>
                                        )
                                    ]}
                                >
                                    <div className="mb-4"></div>
                                    {this.state.modalItemDownload && (
                                        <div className="bg-white pt-0 pr-3 pl-3 pb-3" id="invoicecontent">
                                            <div
                                                className="row bg-ipresens-dark-purple p-2 text-white border-ipresens-main"
                                                style={{
                                                    backgroundImage: `url(${presensiHeaderModalBG})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'left center'
                                                }}>
                                                <div className="col-4 d-flex align-items-center">
                                                    <img src={presensiWhiteLogo} alt="Logo" style={{height: '26px'}}/>
                                                </div>
                                                <div className="col-8 text-right">
                                                    <b>{moment().format("dddd, MMMM Do YYYY")}</b><br/>
                                                    <span>{this.state.perusahaan.perusahaan_nama}</span>
                                                </div>
                                            </div>
                                            <div className="row p-4">
                                                <div style={{
                                                    backgroundColor: this.state.modalItem.invoice_status == 'pending' ? '#FFF6E8' : this.state.modalItem.invoice_status == 'expired' ? '#F5F5F5' : '#E8F2E7',
                                                    color: this.state.modalItem.invoice_status == 'pending' ? '#FF9C00' : this.state.modalItem.invoice_status == 'expired' ? '#5D6F80' : '#6BAA61',
                                                    fontSize: 16,
                                                    textTransform: "uppercase",
                                                    fontWeight: "bold"
                                                }}
                                                     className="w-100 text-center p-2">{this.state.modalItem.invoice_status == 'pending' ? "Menunggu Pembayaran" : this.state.modalItem.invoice_status == 'expired' ? "Kadaluarsa" : "Lunas"}</div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-8">
                                                    <h5 className="text-blue-dark">
                                                        <b>{this.state.perusahaan.perusahaan_nama}</b></h5>
                                                    <span
                                                        className="text-secondary">{this.state.perusahaan.perusahaan_alamat}</span>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <h5 className="text-blue-dark"><b>INVOICE</b></h5>
                                                    <span
                                                        className="text-secondary">#{this.state.modalItem.invoice_no}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h6 className="text-secondary mb-0"><b>Ringkasan Biaya</b></h6>
                                                    <span>{this.state.modalItem.paket.paket_nama} ({this.state.modalItem.invoice_lama_bulan} Bulan)</span>
                                                </div>
                                                <div className="col text-right">
                                                    <h5 className="text-secondary mb-0"><b>Harga</b></h5>
                                                    <span>{convertToRupiah(this.state.modalItem.invoice_nominal, true)}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h6 className="text-secondary mb-0"><b>Potongan Sisa</b></h6>
                                                </div>
                                                <div className="col text-right">
                                                    <span>{convertToRupiah(this.state.modalItem.potongan_sisa, true)}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <h6 className="text-secondary mb-0"><b>PPN (11%)</b></h6>
                                                </div>
                                                <div className="col text-right">
                                                    <span>{convertToRupiah(this.state.modalItem.invoice_tax, true)}</span>
                                                </div>
                                            </div>
                                            {this.state.modalItem.invoice_voucher != null &&
                                                <div className="row">
                                                    <div className="col">
                                                        <h6 className="text-secondary mb-0"><b>Voucher Promo</b></h6>
                                                    </div>
                                                    <div className="col text-right">
                                                        <span>{convertToRupiah(this.state.modalItem.potongan_voucher, true)}</span>
                                                    </div>
                                                </div>
                                            }
                                            <div className="text-right mt-4 text-dark">
                                                <h5><b>Total {
                                                    convertToRupiah(this.state.modalItem.total_bayar - this.state.modalItem.potongan_voucher +
                                                        (this.state.modalItem.invoice_status == 'pending' ? hitungBiayaAdmin(this.state.metode_bayar, this.state.modalItem.invoice_nominal) : hitungBiayaAdmin(this.state.modalItem.invoice_payment_type, this.state.modalItem.invoice_nominal)), true)}</b>
                                                </h5>
                                            </div>
                                            <div className="text-center text-secondary bg-light">
                                                <span>iPresens.com @2024</span>
                                            </div>
                                        </div>
                                    )}
                                </Modal>
                                <Modal
                                    title="Pilih Durasi Perpanjangan Paket"
                                    visible={this.state.modalRenew}
                                    onOk={() => {
                                        this.setState({
                                            modalRenew: false,
                                        });
                                    }}
                                    onCancel={() => {
                                        this.setState({
                                            modalRenew: false,
                                        });
                                    }}
                                    footer={null}
                                >
                                    <div>
                                        <Label>Pilih Durasi Masa Aktif Perpanjangan Paket Perusahaan</Label><br/>
                                        <Label style={{fontSize: "9px", marginBottom: "1px"}}>(Pilih Bulan Masa
                                            Aktif) </Label>
                                        <Input bsSize="sm" type="select" name="renew_month" className="form-select w-50"
                                               value={this.state.renew_month} onChange={this.handleChange}>
                                            <option value="">Pilih Bulan</option>
                                            <option value="1">1 Bulan</option>
                                            <option value="2">2 Bulan</option>
                                            <option value="3">3 Bulan</option>
                                            <option value="4">4 Bulan</option>
                                            <option value="5">5 Bulan</option>
                                            <option value="6">6 Bulan</option>
                                            <option value="7">7 Bulan</option>
                                            <option value="8">8 Bulan</option>
                                            <option value="9">9 Bulan</option>
                                            <option value="10">10 Bulan</option>
                                            <option value="11">11 Bulan</option>
                                            <option value="12">12 Bulan</option>
                                        </Input>
                                        {!this.state.loading ?
                                            <a type="button" className="btn btn-primary mb-3 mt-3 text-center"
                                               style={{color: 'white', maxWidth: '100%'}}
                                               onClick={() => {
                                                   this.renewPaket()
                                               }}>
                                                Perpanjang Paket
                                            </a>
                                            :
                                            <a type="button" className="btn btn-primary mb-3 mt-3 text-center"
                                               style={{color: 'white', maxWidth: '100%'}}>
                                                <i className="fa fa-refresh fa-spin mr-2"/>
                                                Sedang Menmperpanjang Paket...
                                            </a>
                                        }
                                    </div>
                                </Modal>
                                <Modal
                                    title="Pilih Durasi Upgrade Paket"
                                    visible={this.state.modalUpgrade}
                                    onOk={() => {
                                        this.setState({
                                            modalUpgrade: false,
                                        });
                                    }}
                                    onCancel={() => {
                                        this.setState({
                                            modalUpgrade: false,
                                        });
                                    }}
                                    footer={null}
                                >
                                    <div>
                                        <Label>Pilih Durasi Masa Aktif Upgrade Paket Perusahaan</Label><br/>
                                        <Label style={{fontSize: "9px", marginBottom: "1px"}}>(Pilih Bulan Masa
                                            Aktif) </Label>
                                        <Input bsSize="sm" type="select" name="upgrade_month"
                                               className="form-select w-50" value={this.state.upgrade_month}
                                               onChange={this.handleChange}>
                                            <option value="">Pilih Bulan</option>
                                            <option value="1">1 Bulan</option>
                                            <option value="2">2 Bulan</option>
                                            <option value="3">3 Bulan</option>
                                            <option value="4">4 Bulan</option>
                                            <option value="5">5 Bulan</option>
                                            <option value="6">6 Bulan</option>
                                            <option value="7">7 Bulan</option>
                                            <option value="8">8 Bulan</option>
                                            <option value="9">9 Bulan</option>
                                            <option value="10">10 Bulan</option>
                                            <option value="11">11 Bulan</option>
                                            <option value="12">12 Bulan</option>
                                        </Input>
                                        {!this.state.loading ?
                                            <a type="button" className="btn btn-primary mb-3 mt-3 text-center"
                                               style={{color: 'white', maxWidth: '100%'}}
                                               onClick={() => {
                                                   this.wantPaket()
                                               }}>
                                                {i18n.t("upgradepaket")}
                                            </a>
                                            :
                                            <a type="button" className="btn btn-primary mb-3 mt-3 text-center"
                                               style={{color: 'white', maxWidth: '100%'}}>
                                                <i className="fa fa-refresh fa-spin mr-2"/>
                                                {i18n.t("sedangmengupgradepaket")+("...")}
                                            </a>
                                        }
                                    </div>
                                </Modal>
                                <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false, currentPageHistory: 0 })}>
                                    <ModalHeader style={{ backgroundColor: "#F8F3FD" }} toggle={() => this.setState({ modalHistory: false, currentPageHistory: 0 })}>
                                        <div className='modal-header-ipresens'>{i18n.t("riwayatlangganan")}</div>
                                    </ModalHeader>
                                    <ModalBody>
                                        <div className="list-group">
                                            <div>
                                                {this.state.loadingHistory && (
                                                    <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                                        <Spin
                                                            size="large"
                                                            tip="Loading..."
                                                            style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                            }}
                                                        />
                                                    </div>
                                                )}

                                                {this.state.dataHistory.length !== 0 && (
                                                    <Table className="table table-striped mb-5">
                                                        <Thead>
                                                            <Tr>
                                                                <Th style={{border: "none", fontSize: "13px"}} className="modal-header-ipresens-table" width="30">#</Th>
                                                                <Th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="300">{i18n.t("paketlangganan")}</Th>
                                                                <Th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{i18n.t("tanggalmulai")}</Th>
                                                                <Th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table" width="200">{i18n.t("tanggalselesai")}</Th>
                                                                <Th style={{border: "none", fontSize: "13px"}} className="text-left modal-header-ipresens-table">{i18n.t("status")}</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {this.state.dataHistory.map((item, index) => {
                                                                let no = index + 1;
                                                                return (
                                                                    <Tr>
                                                                        <small>
                                                                            <Th style={{border: "none", color: "#38115F", paddingTop: "25px"}} align='left' scope="row">
                                                                                {pageNumber(no, this.state.perPageHistory, this.state.currentPageHistory)}
                                                                            </Th>
                                                                        </small>
                                                                        <Td style={{border: "none"}}>
                                                                            <div className="row ml-0">
                                                                                <div className="align-self-center">
                                                                                    {item.paket_code === 'ENTERPRISE' && <img src={PaketEnterprise} alt=''/>}
                                                                                    {item.paket_code === 'DIAMOND'    && <img src={PaketDiamonds} alt=''/>}
                                                                                    {item.paket_code === 'PLATINUM'   && <img src={PaketPlatinum} alt=''/>}
                                                                                    {item.paket_code === 'SILVER'     && <img src={PaketSilver} alt=''/>}
                                                                                    {item.paket_code === 'BASIC'      && <img src={PaketBasic} alt=''/>}
                                                                                    {item.paket_code === 'GOLD'       && <img src={PaketGold} alt=''/>}
                                                                                    {item.paket_code === 'FREE'       && <img src={PaketFree} alt=''/>}
                                                                                </div>
                                                                                <div className="col">
                                                                                    <h6 className="mb-0" style={{color: "#000000", fontSize: "13px"}}>{item.paket_nama}</h6>
                                                                                    <b className="mb-0" style={{color: "#38115F", fontSize: "12px"}}>
                                                                                        {
                                                                                            item.paket.paket_harga !== 0 ? format({
                                                                                                prefix: 'Rp ',
                                                                                                integerSeparator: "."
                                                                                            })(item.paket.paket_harga) : 'Gratis'
                                                                                        }
                                                                                    </b><span style={{ fontSize: "12px"}}> /{item.paket.paket_bulan > 1 ? item.paket.paket_bulan : ''} {i18n.t("bulan")}</span>
                                                                                </div>
                                                                            </div>
                                                                        </Td>
                                                                        <Td style={{border: "none"}} align='left'>
                                                                            <div className="mt-0">
                                                                                <p style={{color: "#38115F", marginBottom: "0px"}}>{moment(item.langganan_tanggal_mulai).format('LL')}</p>
                                                                            </div>
                                                                        </Td>
                                                                        <Td style={{border: "none"}} align='left'>
                                                                            <div>
                                                                                <p style={{color: "#38115F", marginBottom: "0px"}}>{moment(item.langganan_tanggal_berakhir).format('LL')}</p>
                                                                            </div>
                                                                        </Td>
                                                                        <Td style={{border: "none"}} align='left'>
                                                                            <div style={{
                                                                                color: item.langganan_status == 'pending' ? '#ff5507' : item.langganan_status == 'expired' ? '#5D6F80' : '#6BAA61',
                                                                                fontSize: 13
                                                                            }}>
                                                                                {item.langganan_status == 'pending' ?
                                                                                    i18n.t("menunggupembayaran")
                                                                                    : item.langganan_status == 'expired' ?
                                                                                        i18n.t("kadaluarsa")
                                                                                        : i18n.t("lunas")}
                                                                            </div>
                                                                        </Td>
                                                                    </Tr>
                                                                );
                                                            })}
                                                        </Tbody>
                                                    </Table>
                                                )}
                                            </div>
                                        </div>
                                    </ModalBody>
                                    <ModalFooter style={{ backgroundColor: "#F5F5F5", borderBottomLeftRadius: "8px", borderBottomRightRadius: "8px" }}>
                                        <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                            {this.state.dataHistory.length !== 0 &&
                                                <>
                                                    <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                                    <div className='col'>
                                                        <ReactPaginate
                                                            previousLabel={<img src={ArrowLeft} alt='' />}
                                                            nextLabel={<img src={ArrowRight} alt='' />}
                                                            pageCount={this.state.pageCountHistory}
                                                            forcePage={this.state.currentPageHistory}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={3}
                                                            onPageChange={this.handlePageHistory}
                                                            breakClassName={'page-item'}
                                                            breakLinkClassName={'page-link custom-page-link'}
                                                            containerClassName={'pagination my-2'}
                                                            pageClassName={'page-item'}
                                                            pageLinkClassName={'page-link custom-page-link'}
                                                            previousClassName={'page-item'}
                                                            previousLinkClassName={'page-link custom-prev-next-link'}
                                                            nextClassName={'page-item'}
                                                            nextLinkClassName={'page-link custom-prev-next-link'}
                                                            activeClassName={'active custom-active'}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </ModalFooter>
                                </ModalRs>
                            </div>
                        </section>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})
const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Langganan);
