import React from 'react';
import { withTranslation } from 'react-i18next';

const withLanguageSwitch = WrappedComponent => {
    class WithLanguageSwitch extends React.Component {
        changeLanguage = (language) => {
            localStorage.setItem('lang', language);
            const { i18n } = this.props;
            i18n.changeLanguage(language);
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    changeLanguage={this.changeLanguage}
                />
            );
        }
    }

    return withTranslation()(WithLanguageSwitch);
};

export default withLanguageSwitch;
