import React, {Component, Fragment} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import {Form} from "antd";
import Swal from "sweetalert2";
import Select from "react-select";
import IconPlusWhite from "../../../assets/icons/IconPlusWhite.svg";

export class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            siswa: [],
            kategori: [],
            kelas: [],
            loading: false,
            siswa_loading: false,
            siswa_name: '',
            siswa_id: '',
            kategori_combined:[],
            mapel_combined:[],
            term_loading: false,
            term_id: [],
            mp_id: [],
            rows_length: 1,
            term_selected: [],
            nilai: [],
            siswa_selected: null,
            term: [],
            selectedTerms: [],
            kategori_selected: [],
            mapel_selected: [],
            semester_selected: null,
            kategori_loading: false,
            mapel_loading: false,
            semester_loading: false,
            nl_term_id: 0,
            mapel_id: 0,
            semester_id: '',
            semester: [],
            pickSemester: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangenl = this.handleChangenl.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleChangenl = (rowIndex) => (e) => {
        const { value } = e.target;

        const selectedMapel = this.state.mapel_selected[rowIndex];
        const min = selectedMapel ? parseFloat(selectedMapel.mapel_start) : 0.00;
        const max = selectedMapel ? parseFloat(selectedMapel.mapel_end) : 10.00;

        if (value === "") {
            this.setState((prevState) => ({
                nilai: {
                    ...prevState.nilai,
                    [rowIndex]: "",
                },
            }));
            return;
        }

        const numericValue = parseFloat(value);
        const newValue = Math.max(min, Math.min(max, numericValue || 0));

        this.setState((prevState) => ({
            nilai: {
                ...prevState.nilai,
                [rowIndex]: newValue
            },
        }));
    };

    searchTerm() {
        let form = {}
        this.props.request('nilai/list-nilai-term', form , 'POST')
            .then((response)=> {
                if (response.success){
                    const payterm = response.data.data;
                    const combined = payterm.map(item => `${item.nl_term_name}_${item.nl_term_id}`);
                    this.setState({
                        kategori: payterm,
                        kategori_combined: combined,
                    });
                }
                this.setState({
                    term_loading: false,
                })
            })
    }

    searchMapel() {
        let form = {}
        this.props.request('mapel/list', form , 'POST')
            .then((response)=> {
                if (response.success){
                    const payterm = response.data.data;
                    const combined = payterm.map(item => `${item.mapel_nama}_${item.mapel_id}`);
                    this.setState({
                        mapel: payterm,
                        mapel_combined: combined,
                    });
                }
                this.setState({
                    mapel_loading: false,
                })
            })
    }

    searchKaryawan() {
        let form = {
            karyawan_name : this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        siswa: response.data,
                        siswa_loading: false
                    })
                }
            })
    }

    searchSemester() {
        let form = {}
        this.props.request('semester/load-data', form , 'POST')
            .then((response)=> {
                if (response.success){
                    const payterm = response.data.data;
                    const combined = payterm.map(item => `${item.semester_type}_${item.semester_id}`);
                    this.setState({
                        semester: payterm,
                        semester_combined: combined,
                    });
                }
                this.setState({
                    semester_loading: false,
                })
            })
    }

    componentDidMount() {
        this.searchTerm()
        this.searchMapel()
        this.searchSemester()
    }

    handleSubmit = () => {
        this.setState({
            loading: true,
        })
        let form = {
            siswa_id    : this.state.siswa_id,
            semester_id : this.state.semester_id,
            term_id     : this.state.term_id,
            mp_id       : this.state.mp_id,
            nilai       : this.state.nilai,
        }
        this.props.request("nilai/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.changeState({
                        add_visible: false,
                        data: [],
                        loading: true,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasilditambahkan'),
                        icon: 'success'
                    })
                    this.setState({
                        loading: false,
                        nilai: [],
                        kategori_selected: [],
                        mapel_selected: [],
                        semester_selected: null,
                        siswa_id: '',
                        siswa_selected: null,
                        rows_length: 1,
                        term_id: [],
                        semester_id: '',
                        mp_id: [],
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.setState({
                        loading: false
                    })
                }
            })
    }

    render() {
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.add_visible} toggle={() => this.props.changeState({ add_visible: false }, () => this.setState({
                loading: false, nilai: [], kategori_selected: [], mapel_selected: [], semester_id: '', semester_selected: null, siswa_id: '', siswa_selected: null, rows_length: 1, term: [],
            }))} style={{ maxWidth: "900px" }}>
                <ModalHeader toggle={() => this.props.changeState({add_visible: false})}>
                    <div className='text-dark' style={{ fontWeight: 'bold', fontSize: "18px" }}>
                        {i18n.t("tambahnilaisiswa")}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Form layout="vertical" hideRequiredMark style={{ width: "100%" }}>
                        <div className="row-cols-2 row"
                             style={{paddingLeft: "13px", paddingRight: "13px", marginTop: "8px"}}>
                            <div className="col-md-8">
                                <label htmlFor="">{i18n.t('siswa')}</label>
                                <Select
                                    placeholder={i18n.t('nama')}
                                    backspaceRemoves={true}
                                    deleteRemoves={true}
                                    styles={{paddingLeft: "0px"}}
                                    components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                    value={this.state.siswa_selected}
                                    isLoading={this.state.siswa_loading}
                                    onFocus={() => this.setState({siswa_loading: true}, () => this.searchKaryawan())}
                                    onBlur={() => this.setState({siswa_loading: false})}
                                    onInputChange={(val) => this.setState({siswa_name: val}, () => this.setState({siswa_loading: true}, () => this.searchKaryawan()))}
                                    options={
                                        this.state.siswa != null
                                            ? this.state.siswa.map((item, index) => ({
                                                key: index,
                                                value: item.karyawan_id,
                                                label: item.karyawan_nama,
                                            }))
                                            : ""
                                    }
                                    onChange={(siswa_selected) => {
                                        if (!siswa_selected) {
                                            this.setState({
                                                siswa_selected: "",
                                                karyawan_id: "",
                                            });
                                        } else {
                                            this.setState({
                                                siswa_selected,
                                                siswa_id: siswa_selected.value,
                                                siswa: [],
                                            });
                                        }
                                    }}
                                    isClearable={() => {
                                        this.setState({
                                            isClearable: true,
                                        });
                                    }}
                                />
                            </div>
                            <div className="col-md-4">
                                <label className="">{i18n.t('semester')}</label>
                                <Select
                                    className="font-edit-value"
                                    placeholder={i18n.t("semester")}
                                    backspaceRemoves={true}
                                    deleteRemoves={true}
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null
                                    }}
                                    value={this.state.semester_selected}
                                    isLoading={this.state.semester_loading}
                                    onFocus={() => this.setState({semester_loading: true}, () => this.searchSemester())}
                                    onBlur={() => this.setState({semester_loading: false})}
                                    onInputChange={(val) => this.setState({semester_type: val}, () => this.setState({semester_loading: true}, () => this.searchSemester()))}
                                    options={
                                        this.state.semester != null ? (
                                                this.state.semester.map((item, index) =>
                                                    (
                                                        {
                                                            key: index,
                                                            value: item.semester_id,
                                                            label: item.semester_type
                                                        }
                                                    )
                                                ))
                                            : ("")
                                    }
                                    onChange={
                                        (semester_selected) => {
                                            if (!semester_selected) {
                                                this.setState({
                                                    semester_selected: '',
                                                    semester_id: '',
                                                    pickSemester: false
                                                })
                                            } else {
                                                this.setState({
                                                    semester_selected,
                                                    semester_id: semester_selected.value,
                                                    pickSemester: true,
                                                    semester: []
                                                })
                                            }
                                        }
                                    }
                                    isClearable={() => {
                                        this.setState({
                                            isClearable: true,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <hr/>
                        {Array.from({length: this.state.rows_length}, (item, rowIndex) => (
                            <div className="row-cols-2 row"
                                 style={{paddingLeft: "13px", paddingRight: "13px", marginTop: "8px"}} key={rowIndex}>
                                <div className="col-md-6">
                                    <label className="">{i18n.t('kategorinilai')}</label>
                                    <Select
                                        placeholder={i18n.t('kategorinilai')}
                                        backspaceRemoves={true}
                                        deleteRemoves={true}
                                        styles={{ paddingLeft: "0px" }}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        value={this.state.kategori_selected[rowIndex] || null}
                                        onFocus={() => this.setState({ kategori_loading: true }, () => this.searchTerm())}
                                        onBlur={() => this.setState({ kategori_loading: false })}
                                        onInputChange={(val) => this.setState({ nl_term_name: val }, () => this.setState({ kategori_loading: true }, () => this.searchTerm()))}
                                        options={
                                            this.state.kategori != null
                                                ? this.state.kategori.map((item, index) => ({
                                                    key: index,
                                                    value: item.nl_term_id,
                                                    label: item.nl_term_name,
                                                }))
                                                : ""
                                        }
                                        onChange={(selectedOption) => {
                                            this.setState((prevState) => {
                                                const updatedKategoriSelected = [...prevState.kategori_selected];
                                                updatedKategoriSelected[rowIndex] = selectedOption || null;

                                                const updatedTermIds = [...prevState.term_id];
                                                updatedTermIds[rowIndex] = selectedOption ? selectedOption.value : null;

                                                return {
                                                    kategori_selected: updatedKategoriSelected,
                                                    term_id: updatedTermIds,
                                                };
                                            });
                                        }}
                                        isClearable
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label className="">{i18n.t('mapel')}</label>
                                    <Select
                                        placeholder={i18n.t('mapel')}
                                        backspaceRemoves={true}
                                        deleteRemoves={true}
                                        styles={{ paddingLeft: '0px' }}
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null,
                                        }}
                                        value={this.state.mapel_selected[rowIndex] || null}
                                        onFocus={() =>
                                            this.setState({ mapel_loading: true }, () => this.searchMapel())
                                        }
                                        onBlur={() => this.setState({ mapel_loading: false })}
                                        onInputChange={(val) =>
                                            this.setState({ mapel_nama: val }, () =>
                                                this.setState({ mapel_loading: true }, () => this.searchMapel())
                                            )
                                        }
                                        options={
                                            this.state.mapel != null
                                                ? this.state.mapel.map((item, index) => ({
                                                    key: index,
                                                    value: item.mapel_id,
                                                    label: item.mapel_nama,
                                                    mapel_start: item.mapel_nilai_start,
                                                    mapel_end: item.mapel_nilai_end,
                                                }))
                                                : ''
                                        }
                                        onChange={(selectedOption) => {
                                            this.setState((prevState) => {
                                                const updatedMapelSelected = [...prevState.mapel_selected];
                                                updatedMapelSelected[rowIndex] = selectedOption || null;

                                                const updatedMapelIds = [...prevState.mp_id];
                                                updatedMapelIds[rowIndex] = selectedOption
                                                    ? selectedOption.value
                                                    : null;

                                                const updatedNilai = { ...prevState.nilai };

                                                if (selectedOption) {
                                                    updatedNilai[rowIndex] = '';
                                                } else {
                                                    delete updatedNilai[rowIndex];
                                                }

                                                return {
                                                    mapel_selected: updatedMapelSelected,
                                                    mp_id: updatedMapelIds,
                                                    nilai: updatedNilai,
                                                };
                                            });
                                        }}
                                        isClearable
                                    />
                                </div>
                                <div className="col-auto">
                                    <label htmlFor="">{i18n.t('nilai')}</label>
                                    <div className="d-flex align-items-center"
                                         style={{width: "115px", position: "relative"}}>
                                        <input
                                            type="number"
                                            step="0.01"
                                            className="form-control"
                                            onChange={this.handleChangenl(rowIndex)}
                                            placeholder="00.00"
                                            name={`nilai_${rowIndex}`}
                                            value={this.state.nilai[rowIndex] || ''}
                                            disabled={!this.state.mapel_selected[rowIndex]}
                                            min={
                                                this.state.mapel_selected[rowIndex]
                                                    ? this.state.mapel_selected[rowIndex].mapel_start
                                                    : 0.0
                                            }
                                            max={
                                                this.state.mapel_selected[rowIndex]
                                                    ? this.state.mapel_selected[rowIndex].mapel_end
                                                    : 10.0
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* Tambah Row */}
                        <div className="col" style={{marginTop: "15px", display: "flex", gap: "10px" }}>
                            {this.state.rows_length <= 8 && (
                                <button
                                    className="btn ipresens-btn-color mt-0 text-capitalize pull-left button-w-full"
                                    onClick={() => this.setState({ rows_length: this.state.rows_length + 1 })}
                                >
                                    <img src={IconPlusWhite} alt="" className="mr-2 mb-1" /> {i18n.t('tambah')}
                                </button>
                            )}
                            <button
                                className="btn ipresens-delete-button"
                                onClick={() => {
                                    Swal.fire({
                                        title: i18n.t("pemberitahuan"),
                                        text: i18n.t("andayakinmeresetnilai"),
                                        icon: 'warning',
                                        confirmButtonText: 'Reset',
                                        cancelButtonText: i18n.t("batal"),
                                        showConfirmButton: true,
                                        showCancelButton: true
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            this.setState((prevState) => {
                                                const lastKategori  = prevState.kategori_selected[prevState.rows_length - 1];
                                                const lastMapel     = prevState.mapel_selected[prevState.rows_length - 1];
                                                const lastTermId    = prevState.term_id[prevState.rows_length - 1];
                                                const updatedNilai  = {...prevState.nilai};

                                                const rowIndex = prevState.rows_length - 1;

                                                updatedNilai[rowIndex] = ''[prevState.rows_length - 1];

                                                if (!lastKategori && !lastMapel && !lastTermId && !updatedNilai) {
                                                    return {
                                                        rows_length: prevState.rows_length = 1
                                                    };
                                                } else {
                                                    return {
                                                        rows_length: this.state.rows_length > 1 ? prevState.rows_length - 1 : prevState.rows_length = 1,
                                                        kategori_selected: prevState.kategori_selected.slice(0, -1),
                                                        mapel_selected: prevState.mapel_selected.slice(0, -1),
                                                        term_id: prevState.term_id.slice(0, -1),
                                                        mp_id: prevState.mp_id.slice(0, -1),
                                                        nilai: updatedNilai,
                                                    };
                                                }
                                            });
                                        }
                                    })
                                }}
                            >
                                <i className={"fa fa-refresh"}></i>
                            </button>
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button
                            type="button"
                            className="btn ipresens-btn-cancel"
                            onClick={() => {
                                this.props.changeState({add_visible: false,}, () => this.setState({
                                    loading: false,
                                    nilai: [],
                                    kategori_selected: [],
                                    mapel_selected: [],
                                    siswa_id: '',
                                    siswa_selected: null,
                                    semester_id: '',
                                    semester_selected: null,
                                    rows_length: 1,
                                    term: [],
                                }));
                            }}
                            style={{marginRight: 8}}
                        >
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color" disabled={this.state.loading} onClick={this.handleSubmit}>
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

export default Add;
