import React, {Component} from 'react'
import {Form} from 'antd';
import Swal from "sweetalert2";
import i18n from "i18next";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class EditSift extends Component {

    constructor() {
        super();
        this.state = {
            sift_name: '',
            sift_late_tolerance: '',
            loading: false
        }
    }

    handleEdit = () => {
        let form = {
            id: this.props.data.sift_id,
            name: this.props.data.sift_name,
            tolerance: this.props.data.sift_late_tolerance,
        }
        this.props.request("shift/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    const filter_data = JSON.parse(localStorage.getItem('Presensi-Company-Data'));
                    if (filter_data && filter_data._sift) {
                        const updateShift = filter_data._sift.map(sift =>
                            sift.sift_id === this.props.data.sift_id ? {
                                ...sift,
                                sift_name: this.props.data.sift_name,
                                sift_late_tolerance: this.props.data.sift_late_tolerance,
                            } : sift)
                        const updateFilter = {...filter_data, _sift: updateShift}
                        localStorage.setItem('Presensi-Company-Data', JSON.stringify(updateFilter))
                    }
                    this.setState({
                        sift_name : '',
                        sift_late_tolerance : '',
                    },() =>this.props.changeState({
                        visable: false,
                        sift_name : '',
                        sift_late_tolerance : '',
                        data: [],
                        isLoading: true,
                    },() => {
                        this.props.loadData()
                    }))
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    handleChange = (event) => {
        const { name, value } = event.target

        this.props.changeState({
            [name]: value,
        })
    }

    render() {
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.data.visable} toggle={() => this.props.changeState({ visable: false })} style={{ maxWidth: "1000px" }}>
                <ModalHeader>
                    <div className='text-dark' style={{
                        fontWeight: 'bold', fontSize: "18px"
                    }}>{i18n.t("editshift")}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="">
                        <Form layout="vertical" hideRequiredMark>
                            <div className="form-group">
                                <label>{i18n.t("namashift")}</label>
                                <input name="sift_name"
                                       className="form-control col-xl-12 col-md-12"
                                       placeholder={i18n.t("namashift")+("...")}
                                       type="text"
                                       required=""
                                       value={this.props.data.sift_name}
                                       onChange={this.handleChange}/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t("toleransitelat")} ({i18n.t("menit")})</label>
                                <input name="sift_late_tolerance"
                                       className="form-control col-xl-12 col-md-12"
                                       placeholder={i18n.t("toleransiplaceholder")}
                                       type="number"
                                       required=""
                                       value={this.props.data.sift_late_tolerance}
                                       onChange={this.handleChange}/>
                            </div>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({visable: false, desc: ''});
                        }}
                                style={{marginRight: 8}}>
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color"
                                disabled={this.state.loading} onClick={this.handleEdit}>
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

export default EditSift
