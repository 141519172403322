import React, {Component} from 'react'
import Swal from "sweetalert2";
import i18n from "i18next";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class EditIstillah extends Component {

    constructor() {
        super();
        this.state = {
            p_term_type: ''
        }
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.props.changeState({
            [name]: value
        })
    }

    handleEdit = () => {
        let form = {
            id: this.props.data.p_term_id,
            name: this.props.data.p_term_name,
            type: this.props.data.p_term_type,
        }
        this.props.request("payroll/edit_term", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        p_term_name : ''
                    },() =>this.props.changeState({
                        visable: false,
                        p_term_name : '',
                        data: [],
                        isLoading: true,
                    },() => {
                        this.props.loadData()
                        this.toggle()
                    }))
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('berhasilupdateistilah'),
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('gagalupdateistilah'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    toggle() {
        this.props.setState({ modalEdit: !this.props.modalEdit })
    }

    componentWillReceiveProps(newProps) {
        this.setState({p_term_type: newProps.data.p_term_type})
    }
    render() {
        const style_add = {
            font_header: {
                "fontWeight": 700,
                "fontSize": "18px",
                "lineHeight": "27px",
            }
        }
        return (
            <Modal
                isOpen={this.props.modalEdit}
                toggle={() => this.props.setState({
                    modalEdit: !this.props.modalEdit,
                    p_term_name:'',
                    p_term_type:''
                })}
                style={{ maxWidth: "1000px" }}
            >
                <ModalHeader
                    toggle={() => this.props.setState({
                    modalEdit: !this.props.modalEdit,
                    p_term_name:'',
                    p_term_type:''
                })}
                >
                    <div className="text-dark" style={style_add.font_header}>{i18n.t("editistilahgaji")}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label>{i18n.t("namaistilah")}</label>
                        <input name="p_term_name"
                               className="form-control col-xl-12 col-md-12"
                               placeholder={i18n.t("placeholdernametype")}
                               type="text" required="" value={this.props.data.p_term_name}
                               onChange={(event) => this.handleChange(event)}/>
                    </div>
                    <div className="form-group">
                        <label>{i18n.t("tipeistilah")}</label>
                        <select
                            name="p_term_type"
                            className="form-control col-xl-12 col-md-12"
                            required=""
                            value={this.props.data.p_term_type}
                            onChange={(event) => this.handleChange(event)}>

                            <option value="">{i18n.t("pilihtipeistilah")}</option>
                            <option value="kompensasi">{i18n.t("kompensasigaji")}</option>
                            <option value="potongan">{i18n.t("potongangaji")}</option>
                        </select>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className='btn ipresens-btn-cancel' onClick={() => {
                        this.props.setState({
                            modalEdit: !this.props.modalEdit,
                            p_term_name:'',
                            p_term_type:''
                        })
                    }}>
                        {i18n.t("batal")}
                    </button>{' '}
                    <button type="button" className="btn ipresens-btn-color"
                            disabled={this.state.loading} onClick={this.handleEdit}>
                        {this.state.loading ?
                            <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                            :
                            i18n.t("simpan")
                        }
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default EditIstillah
