import React, {Component, Fragment} from "react";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import i18n from "i18next";
import Breadcrumb from "../common/breadcrumb";
import {Empty, Spin, Tooltip} from "antd";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import {pageNumber} from "../../functions/functionGeneral";
import Paging from "../paging";
import AddTagihanSiswa from "./add";
import IconPlusWhite from "../../assets/icons/IconPlusWhite.svg";
import EditTagihanSiswa from "./edit";
import Swal from "sweetalert2";
import format from "format-number";
import {getCurrency} from "../../helper/money";
import moment from "moment/moment";
import ExportIcon from "../../assets/icons/download-white.svg";
import ImportIcon from "../../assets/icons/import-icon.svg";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {BACKEND_API_HOST} from "../../helper/host";
import MoreIcon from "../../assets/icons/more.svg";
import AddPaymentType from "./payment_type/payment_type";

export class TagihanSiswaList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
            loading: false,
            add_visible: false,
            edit_visible: false,
            modalImport: false,
            importLoading: false,
            add_payment_type: false,
            data: [],
            importFile: null,
            tagihan_id: 0,
            paging: 10,
            currentPage: 0,
            pageCount: 0,
            perPage: 0,
            totalData: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter() {
        this.setState({
            filter: '',
            loading: true,
            data: [],
            currentPage: 0,
        }, () => this.loadData())
    }

    handlePageClick(data) {
        let selected = data.selected;
        this.setState({
            currentPage: selected, loading: true, data: [],
        }, () => this.loadData())
    }

    loadData() {
        this.setState({ loading: true })
        let form  = {
            page: this.state.currentPage + 1,
            paginate: this.state.paging,
            filter: this.state.filter
        }
        this.props.request("tagihan_siswa/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
                this.setState({ loading: false })
            })
    }

    wantDelete(id) {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmenghapustagihansiswa'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        tagihan_id: id || this.state.tagihan_id,
                    }
                    this.props.request("tagihan_siswa/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                this.setState({ data: [] })
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('databerhasildihapus'),
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 900,
                                })
                                this.loadData()
                            }
                        })
                }
            })
    }

    componentDidMount() {
        this.loadData()
    }

    exportTemplate = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'tagihan_siswa/export-template?token=' + AuthKey, '_blank')
    }

    importTagihanSiswa = () => {
        this.setState({ importLoading: true })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'tagihan_siswa/import', { method: "POST", headers: {
                'Authorization': 'bearer ' + AuthKey
            }, body: formData})
            .catch((error) => { console.log(error)})
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('importtagihansiswaberhasil'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload()
                    this.setState({ modalImport: false, loading: true, importFile: null, data: [] }, () => this.loadData())
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({ modalImport: false })
                }
                this.setState({ importLoading: false })
            })
    }

    exportTagihan = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'tagihan_siswa/export?token=' + AuthKey, '_blank')
    }

    render() {
        let no = 0;
        const currency = this.props.user.perusahaan.perusahaan_currency;
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Tagihan Siswa") >= 0 ) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{i18n.t('tagihansiswa')} -
                            iPresens</title>
                        <meta name="description" content="Tagihan Siswa"/>
                    </Helmet>

                    <Breadcrumb title={i18n.t('tagihansiswa')} parent={i18n.t("pembayaran")}/>
                    <AddTagihanSiswa
                        add_visible={this.state.add_visible}
                        currency={currency}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />

                    <EditTagihanSiswa
                        edit_visible={this.state.edit_visible}
                        id={this.state.tagihan_id}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />

                    <AddPaymentType
                        visible={this.state.add_payment_type}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />

                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-5 d-flex align-items-center">
                                        <div className="input-group btn-srch-payroll-list">
                                            <input name="filter"
                                                   className={this.state.filter !== "" ? "form-control" : "form-control bg-image-search"}
                                                   placeholder={i18n.t('cari')}
                                                   type="text"
                                                   style={{paddingLeft: this.state.filter === "" ? "40px" : "10px"}}
                                                   onKeyUp={(event) => {
                                                       if (event.which === 13) {
                                                           event.preventDefault();
                                                           this.setState({
                                                               loading: true,
                                                               data: [],
                                                           }, () => {
                                                               this.loadData()
                                                           })
                                                       }
                                                   }} onChange={this.handleChange}
                                            />
                                        </div>
                                        <button className="btn ipresens-btn-reset" style={{ marginBottom: "20px", marginLeft: "10px"}} onClick={this.resetFilter}><span
                                            className="fa fa-refresh"></span></button>
                                    </div>
                                    <div className="col-md-7 d-flex justify-content-end pb-4" style={{ gap: 5 }}>
                                        <button className="btn ipresens-btn-color" onClick={() => this.setState({
                                            add_visible: true
                                        })}><img src={IconPlusWhite} alt="" className="mr-2 mb-1"/> {i18n.t('tambah')}
                                        </button>
                                        <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'} title={
                                            <div className="p-2">
                                                <a onClick={() => {
                                                    this.setState({ add_payment_type: true})
                                                }} style={{marginRight: "50px"}}
                                                   className={'text-dark'}>{i18n.t('pengaturantypepembayaran')}</a> <br/>
                                                <a onClick={() => {
                                                    this.setState({modalImport: true})
                                                }} style={{marginRight: "50px"}} className={'text-dark'}>{i18n.t('importtagihan')}</a><br/>
                                                <a onClick={this.exportTagihan} style={{ marginRight: "50px" }} className={'text-dark'}>{i18n.t('exporttagihan')}</a>
                                            </div>
                                        }>
                                            <a><i
                                                style={{
                                                    width:"15",
                                                    fontSize: 18,
                                                    color: 'rgb(51 51 51)',
                                                    alignItems:"center",
                                                    height:"38px",
                                                    paddingRight: "30px"
                                                }}
                                                className='btn border border-dark border-1 px-2'
                                            ><img src={MoreIcon} /></i></a>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body pt-0">
                                {!this.state.loading && this.state.data.length === 0 ? (
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                ): (
                                    <Table className="table table-borderless table-striped">
                                        <Thead>
                                            <Tr>
                                                <Th>#</Th>
                                                <Th>{i18n.t('nama')}</Th>
                                                <Th className="text-center">{i18n.t('nominal')}</Th>
                                                <Th className="text-center">{i18n.t('deadlinetagihan')}</Th>
                                                <Th className="text-center">{i18n.t('kelas')}</Th>
                                                <Th className="text-center" style={{ width: "50px" }}>{i18n.t('aksi')}</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading && (
                                                <Tr>
                                                    <Td colSpan={11}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </Td>
                                                </Tr>
                                            )}
                                            {this.state.data?.map((item, index) => {
                                                no += 1
                                                return (
                                                    <Tr key={index}>
                                                        <Td>{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                        <Td>{item.tagihan_nama}</Td>
                                                        <Td className="text-center">{format({ prefix: getCurrency(currency), integerSeparator: "." })(item.tagihan_nominal ? item.tagihan_nominal : '-')}</Td>
                                                        <Td className="text-center">{moment(item.tagihan_deadline).format('ll')}</Td>
                                                        <Td className="text-center">{item.divisi_id ? item.kelas?.divisi_name : i18n.t('semuakelas')}</Td>
                                                        <Td className="text-center" style={{ width: "50px" }}>
                                                            <Tooltip trigger="click" placement="bottomRight" zIndex={9999} color={'#fff'} title={
                                                                <div className="d-flex flex-column p-2">

                                                                    <a className="text-dark" onClick={() => this.setState({
                                                                        tagihan_id: item.tagihan_id, edit_visible: true
                                                                    })}>{i18n.t('edit')}</a>

                                                                    <a className="text-danger" onClick={() => this.setState({
                                                                        tagihan_id: item.tagihan_id
                                                                    }, () => this.wantDelete(item.tagihan_id))}>{i18n.t('hapus')}</a>

                                                                </div>
                                                            }>
                                                                <a><i className="fa fa-ellipsis-h" style={{
                                                                    width: 35,
                                                                    fontSize: 18,
                                                                    padding: 5,
                                                                    color: 'rgb(51 51 51)'
                                                                }}/></a>
                                                            </Tooltip>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                )}
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>

                    <Modal isOpen={this.state.modalImport} toggle={() => this.setState({ modalImport: false })}>
                        <ModalHeader toggle={() => this.setState({importFile: null, modalImport: false })}>
                            <div>{i18n.t('importtagihan')}</div>
                        </ModalHeader>
                        <ModalBody style={{ padding: "20px" }}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t('importtemplate')}</a><br/>
                                    <a onClick={this.exportTemplate} style={{color: "#057CE4"}}>{i18n.t('unduhtemplate')}</a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({
                                                       importFile: event.target.files[0],
                                                   })
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i> {i18n.t('prosesupload')}</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importTagihanSiswa}>{i18n.t('importdata')}</button>
                            ]}
                        </ModalFooter>
                    </Modal>

                </Fragment>
            )
        } else {
            return (
                <Fragment>
                <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TagihanSiswaList);