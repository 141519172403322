import React, {Component, Fragment} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import {Form, Upload} from "antd";
import Swal from "sweetalert2";
import ImgCrop from "antd-img-crop";
import ImportIcon from "../../assets/icons/import-icon-blue-light.svg";
import {API_HOST} from "../../helper/host";

export class addBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            desc: '',
            startDate: '',
            endDate: '',
            fileList: [],
            gambar: '',

        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleSubmit = () => {
        this.setState({
            loading: true,
        })
        let  form = {
            banner_desc: this.state.desc,
            b_start_date: this.state.startDate,
            b_end_date: this.state.endDate,
            banner_img: this.state.gambar,
        }
        this.props.request("banner/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({ level_name: '', level_page_limited: '' })
                    this.props.changeState({
                        add_visible: false,
                        data: [],
                        isLoading: true,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasilditambahkan'),
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('datagagalditambahkan'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ loading: false, fileList: [], gambar: "" })
            })
    }

    render() {
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.add_visible} toggle={() => this.props.changeState({ add_visible: false })} style={{ maxWidth: "800px" }}>
                <ModalHeader>
                    <div className='text-dark' style={{
                        fontWeight: 'bold', fontSize: "18px"
                    }}>{i18n.t("tambahbanner")}</div>
                </ModalHeader>
                <ModalBody>
                        <div className="">
                            <Form layout="vertical" hideRequiredMark>
                                <div className="form-group">
                                    <label>{i18n.t('foto')}</label>
                                    <ImgCrop
                                        width={1920}
                                    height={600}>
                                        <Upload action={API_HOST} listType="picture-card"
                                                style={{ width: '400px !important' }}
                                                fileList={this.state.fileList}
                                                onChange={(file) => {
                                                    this.setState({ fileList: file.fileList })

                                                    if (file.fileList.length > 0) {
                                                        let files = file.file.originFileObj
                                                        let reader = new FileReader()

                                                        reader.readAsDataURL(files)
                                                        reader.onloadend = () => {
                                                            this.setState({
                                                                gambar: {
                                                                    name: file.file.name,
                                                                    file: reader.result
                                                                }
                                                            })
                                                        }
                                                    }
                                                    else {
                                                        this.setState({ gambar: [] })
                                                    }
                                                }}
                                        >
                                            {this.state.fileList.length >= 1 ? null :
                                                <div className="p-2">
                                                    <img alt='' src={ImportIcon} style={{ width: "32px", height: "32px" }} /><br />
                                                    <div style={{
                                                        fontSize: "12px",
                                                        lineHeight: "16px",
                                                        color: "#5D6F80",
                                                        marginTop: "8px"
                                                    }}>{i18n.t('uploadgambar')}</div><br />
                                                    <a className="btn btn-outline-custom tambah-karyawan">{i18n.t('carigambar')}</a>
                                                </div>
                                            }
                                        </Upload>
                                    </ImgCrop>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="desc">{i18n.t('deskripsi')}</label>
                                    <input type="text" name="desc" id="desc" placeholder={i18n.t('ketikandeskripsi')} className="form-control" onChange={this.handleChange}/>
                                </div>
                                <div className="d-flex flex-row" style={{ gap: 20 }}>
                                    <div className="form-group" style={{ width: "100%"}}>
                                        <label htmlFor="startDate">{i18n.t('tanggalmulai')}</label>
                                        <input type="date" name="startDate" id="startDate"
                                               placeholder={i18n.t('ketikandeskripsi')} className="form-control"
                                               onChange={this.handleChange}/>
                                    </div>
                                    <div className="form-group"  style={{ width: "100%"}}>
                                        <label htmlFor="endDate">{i18n.t('tanggalselesai')}</label>
                                        <input type="date" name="endDate" id="endDate"
                                               placeholder={i18n.t('ketikandeskripsi')} className="form-control"
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                            </Form>
                        </div>
                </ModalBody>
                <ModalFooter>
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({add_visible: false, desc: ''});
                        }}
                                style={{marginRight: 8}}>
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color"
                                disabled={this.state.loading} onClick={this.handleSubmit} disabled={this.state.loading}>
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

export default addBanner;