import React, {Component} from 'react'
import logo from '../../../assets/images/dashboard/icon_ipresens.png'
import CheckWhite from '../../../assets/icons/vector-check-white.svg'
import './style.css';
import LanguageSwitcher from "../../common/lang_switcher";
import withLanguageSwitch from "../../../helper/language";

export class HeaderRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: this.props.step
        }
    }

    render() {
        const { changeLanguage, i18n } = this.props;
        return (
            <header id="sticky" className="sticky">
                <div className="container-fluid fixed-top" style={{backgroundColor: "#FFFFFF"}}>
                    <div className="row pt-2">
                        <div className="col p-0">
                            <div className="top-header padding-body my-1 mx-2 px-5">
                                <div className='row'>
                                    <div className='col-md-3 order-1' style={{textAlign: "center"}}>
                                        <div className="logo pl-2">
                                            <a className="nav-link pl-3"
                                            href="/">
                                                <img src={logo} alt="logo" className='logo-header' />
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col-md-6 px-0 lg:px-1 order-3 order-md-2' style={{ textAlign: "center", fontSize: "12px"}}>
                                        {this.props.hide === true ? ""
                                        :
                                            <div className='row d-flex justify-content-center flex-nowrap'>
                                                <div className='col-sm-2'>
                                                    <div
                                                        className='row d-flex align-items-center justify-content-center'>
                                                        <button disabled={this.props.step === 3}
                                                                onClick={() => this.props.setState({stepHeader: 0})}
                                                                className={this.props.step === 0 ? 'btn btn-sm ipresens-btn-color rounded-pill px-3 py-2' : this.props.step > 0 ? 'btn btn-sm ipresens-btn-color rounded-pill px-2 py-2' : 'btn btn-sm rounded-pill px-3 py-2'}
                                                                style={{borderRadius: "50%"}}>{this.props.step > 0 ?
                                                            <img src={CheckWhite}
                                                                 style={{width: "23px"}}/> : 1}</button>
                                                    </div>
                                                    <div className='row'>
                                                        <div
                                                            className={this.props.step === 0 || this.props.step > 0 ? 'col px-0 text-header-color' : 'col px-0'}
                                                            style={{fontSize: "12px"}}>
                                                            {i18n.t('pilihtipe')}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm py-4'>
                                                    <div
                                                        className={this.props.step > 0 ? 'bg-primary' : 'secondary-color'}
                                                        style={{height: "2px", borderRadius: "5px"}}></div>
                                                </div>

                                                <div className='col-sm-2'>
                                                    <div
                                                        className='row d-flex align-items-center justify-content-center'>
                                                        <button disabled={this.props.step === 0}
                                                                onClick={() => this.props.setState({stepHeader: 1})}
                                                                className={this.props.step === 1 ? 'btn btn-sm ipresens-btn-color rounded-pill px-3 py-2' : this.props.step > 1 ? 'btn btn-sm ipresens-btn-color rounded-pill px-2 py-2' : 'btn btn-sm rounded-pill px-3 py-2'}
                                                                style={{borderRadius: "50%"}}>{this.props.step > 1 ?
                                                            <img src={CheckWhite}
                                                                 style={{width: "23px"}}/> : 2}</button>
                                                    </div>
                                                    <div className='row'>
                                                        <div
                                                            className={this.props.step === 1 || this.props.step > 1 ? 'col px-0 text-header-color' : 'col px-0'}
                                                            style={{fontSize: "12px"}}>
                                                            {i18n.t('pilihpaket')}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm py-4'>
                                                    <div
                                                        className={this.props.step > 1 ? 'bg-primary' : 'secondary-color'}
                                                        style={{height: "2px", borderRadius: "5px"}}></div>
                                                </div>

                                                <div className='col-sm-2'>
                                                    <div
                                                        className='row d-flex align-items-center justify-content-center'>
                                                        <button disabled={this.props.step !== 2}
                                                                onClick={() => this.props.setState({
                                                                    stepHeader: 2,
                                                                    paket_id: this.props.paket_selected.paket_id
                                                                })}
                                                                className={this.props.step === 2 ? 'btn btn-sm ipresens-btn-color rounded-pill px-3 py-2' : this.props.step > 2 ? 'btn btn-sm ipresens-btn-color rounded-pill px-2 py-2' : 'btn btn-sm rounded-pill px-3 py-2'}
                                                                style={{borderRadius: "50%"}}>{this.props.step > 2 ?
                                                            <img src={CheckWhite}
                                                                 style={{width: "23px"}}/> : 3}</button>
                                                    </div>
                                                    <div className='row'>
                                                        <div
                                                            className={this.props.step === 2 || this.props.step > 2 ? 'col px-0 text-header-color' : 'col px-0'}
                                                            style={{fontSize: "12px"}}>
                                                            {i18n.t('daftarakun')}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm py-4'>
                                                    <div
                                                        className={this.props.step > 2 ? 'bg-primary' : 'secondary-color'}
                                                        style={{height: "2px", borderRadius: "5px"}}></div>
                                                </div>

                                                <div className='col-sm-2'>
                                                    <div
                                                        className='row d-flex align-items-center justify-content-center'>
                                                        <button disabled={this.props.step !== 3}
                                                                onClick={() => this.props.setState({stepHeader: 3})}
                                                                className={this.props.step === 3 ? 'btn btn-sm ipresens-btn-color rounded-pill px-3 py-2' : 'btn btn-sm rounded-pill px-3 py-2'}
                                                                style={{borderRadius: "50%"}}>4
                                                        </button>
                                                    </div>
                                                    <div className='row'>
                                                        <div
                                                            className={this.props.step === 3 || this.props.step > 3 ? 'col px-0 text-primary' : 'col px-0'}
                                                            style={{fontSize: "12px"}}>
                                                            {i18n.t('selesai')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div
                                        className='col-md-3 justify-content-center align-items-center mt-2 mb-2 lg:mb-0 order-2 order-md-3'
                                        style={{textAlign: "center"}}>
                                        <LanguageSwitcher
                                            currentLanguage={i18n.language}
                                            onChange={() => changeLanguage(i18n.language == "id" ? 'en' : "id")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default withLanguageSwitch(HeaderRegister)
