import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Empty, Spin, Select } from 'antd';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import { toast } from "react-toastify";
import {
    Button, ButtonGroup, Col,
    FormGroup, Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Modal, ModalBody, ModalFooter,
    ModalHeader, Row,
    UncontrolledTooltip
} from "reactstrap";
import CurrencyInput from 'react-currency-input-field';
import KOMPENSASI from "./modalKompensasi"
import POTONGAN from "./modalPotongan"
import "./style.css"
import refreshIcon from '../../assets/icons/refresh.svg'
import {IMG_HOST} from "../../helper/host";
import pencilIcon from "../../assets/icons/edit_pencil.svg"
import EmptyDataImage from "../../assets/images/data-kosong.png";
import format from "format-number";
import Switch from "react-switch";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import i18n from "i18next";
import {getCurrency} from "../../helper/money";

export class List_GajiKaryawan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            jabatan: [],
            data: [],
            kompensasi: [],
            potongan: [],
            data_term: [],
            data_term_potongan: [],
            rows: [{}],
            idDel: '',
            filter_nama: "",
            filter_jabatan: "",
            karyawan_id: "",
            importFile: null,
            modalimport: false,
            modalAction: false,
            visibleKom: false,
            addDetail: false,
            visiblePot: false,
            p_term_name: "",
            p_term_type: "",
            inputType: "select",
            inputTypeKompensasi : "select",
            inputTypePotongan : "select",
            dataAction: [],
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            guide: false,
            modalbedit: false,
            kom: [],
            pot: [],
            divisi:[],
            divisi_id: undefined,
            upah_lembur: '',
            upah_gaji: '',
            divisi_payroll_type: '',
            kompensasi_name: '',
            nominal_kompensasi: '',
            type_kompensasi: '',
            potongan_name: '',
            type_potongan: '',
            nominal_potongan: '',
            rows_kom: [{}],
            rows_pot: [{}],
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleSubmitKom = this.handleSubmitKom.bind(this)
        this.handleSubmitIstilah = this.handleSubmitIstilah.bind(this)
        this.handleSubmitAmount = this.handleSubmitAmount.bind(this)
        this.kompensasiModal = this.kompensasiModal.bind(this)
        this.loadKompensasi = this.loadKompensasi.bind(this)
        this.handleChangeStock = this.handleChangeStock.bind(this)
        this.handleChangeistilah = this.handleChangeistilah.bind(this)
        this.wantDeleteKom = this.wantDeleteKom.bind(this)
        this.addClickKom = this.addClickKom.bind(this)
        this.removeClickKom = this.removeClickKom.bind(this)
        this.handlePotonganType = this.handlePotonganType.bind(this)
        this.handlePotonganAmount = this.handlePotonganAmount.bind(this)
        this.handleSubmitPotongan = this.handleSubmitPotongan.bind(this)
        this.potonganDelete = this.potonganDelete.bind(this)

    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, data: [] }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }
    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }


    resetFilter(event) {
        this.setState({
            filter_nama: '',
            filter_jabatan: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handleOk = e => {
        this.setState({
            modalimport: false,
            modalAction: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false,
            modalAction: false
        });
    };

    handleSubmitAmount(event, id) {
        const target = event.target;
        const name = target.name;
        var value;
        if (target.value.search(",") >= 0) {
            value = parseInt(target.value.split(',').join(''));
        } else {
            value = parseInt(target.value.split('.').join(''));
        }

        let formData = {
            karyawan_id: id,
            form: { [name]: value }
        }
        this.props.request('karyawan/payroll_update', formData, 'POST')
            .then(response => {
                if (response.success) {
                    toast['success'](response.message, { toastId: Math.floor(Math.random() * 2) })
                    this.setState({
                        loading: true,
                        editedValue: '',
                        data: [],
                    })
                    this.loadData()
                }
            });
    }

    // data kompensasi

    kompensasiModal = () => {
        this.setState({
            visibleKom: !this.state.visibleKom
        })
    };

    istillahBatal = () => {
        this.setState({
            inputType: "select",
            inputTypeKompensasi : "select",
            inputTypePotongan : "select",
            rows: [{}],
            rows_kom: [{}],
            rows_pot: [{}]
        })
    };

    handleChangeStock = (type, id) => (e) => {
        const { name, value } = e.target;

        let list = [...this.state[type]];

        if (value === 'text') {
            if (type === 'rows') {
                this.setState({
                    inputType: value,
                });
            } else if (type === 'rows_kom') {
                this.setState({
                    inputTypeKompensasi: value,
                });
            } else if (type === 'rows_pot') {
                this.setState({
                    inputTypePotongan: value,
                });
            }
        }

        list[id] = { ...list[id], [name]: value };

        this.setState({
            [type]: list,
        });
    };

    handleChangeistilah = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        })
    }

    handleSubmitIstilah(id) {
        this.setState({ loading: true })

        let formData = {
            p_term_type: id,
            p_term_name: this.state.p_term_name
        }
        this.props.request("payroll/add-payroll-term", formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message, { toastId: Math.floor(Math.random() * 2) })
                    this.setState({
                        loading: false,
                        rows: [{}],
                        inputType: "select",
                        inputTypeKompensasi : "select",
                        inputTypePotongan : "select",
                        p_term_name: ""
                    })
                    this.loadDataTerm()
                    this.loadDataTermPotongan()
                }
                else {
                    if (Array.isArray(response.message)) {

                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                    this.setState({ loading: false })
                }

                this.setState({ loading: false })
            })
    }

    handleSubmitKom(event, id) {
        this.setState({ loading: true })

        let formData = {
            rows: this.state.rows,
            karyawan_id: id,
        }
        this.props.request('kompensasi/add', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message, { toastId: Math.floor(Math.random() * 2) })
                    this.setState({
                        loading: false,
                        addDetail: true,
                        rows: [{}],
                    })
                    this.loadKompensasi(id)
                }
                else {
                    if (Array.isArray(response.message)) {

                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                    this.setState({ loading: false })
                }

                this.setState({ loading: false })
            })
    }

    addClickKom() {
        this.setState({
            rows    : [...this.state.rows, { k_kompensasi_type: "", p_term_id: "", k_kompensasi_amount: "" }],
            rows_kom : [...this.state.rows_kom, { k_kompensasi_type: "", p_term_id: "", k_kompensasi_amount: "" }],
        })
    }

    removeClickKom = (id) => {
        this.state.rows.splice(id, 1);
        this.state.rows_kom.splice(id, 1);
        this.setState({ rows: this.state.rows, rows_kom: this.state.rows_kom });
    };

    loadKompensasi(id) {
        let formData = {
            karyawan_id: id,

        }

        this.props.request('kompensasi/list_by_karyawan', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    var result = response.data;
                    this.setState({
                        kompensasi: result.data,
                        visibleKom: true
                    })
                }
                this.setState({ isLoading: false })
            })
    }

    loadDataTerm() {
        this.setState({ loading: true })

        this.props.request("kompensasi/list-by-payroll-term", 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_term: response.data,

                    })
                }
                this.setState({ loading: false })
            });
    }

    loadDataTermPotongan() {
        this.setState({ loading: true })

        this.props.request("karyawan_potongan/list-payroll-term", 'POST')
            .then(response => {
                if (response.success) {

                    this.setState({
                        data_term_potongan: response.data,

                    })
                }
                this.setState({ loading: false })
            });
    }

    wantDeleteKom(id, karyawan_id) {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus data kompensasi?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        isLoading: true
                    })
                    let form = {
                        id: id
                    }
                    this.props.request("kompensasi/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true
                                })
                                this.loadKompensasi(karyawan_id)
                            }
                        });
                }
            })
    }

    // data potongan

    potonganModal = () => {
        this.setState({
            visiblePot: !this.state.visiblePot
        })
    };

    loadPotongan(id) {
        this.setState({ isLoading: true })
        let form = {
            karyawan_id: id,
        }
        this.props.request("karyawan_potongan/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        potongan: response.data,
                        visiblePot: true
                    })
                }
                this.setState({ isLoading: false })
            });
    }

    handlePotonganType = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.state.potongan];

        list[id] = { ...list[id], [name]: value };

        this.setState({
            potongan: list,
        })
    };

    handlePotonganAmount = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.state.potongan];
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        list[id] = { ...list[id], [name]: final };
        this.setState({
            potongan: list,
        })
    };

    handleChangePotongan = id => e => {

        const { name, value } = e.target;
        const list = [...this.state.potongan];

        list[id] = { ...list[id], [name]: value };

        this.setState({
            potongan: list,
        })
    };

    handleSubmitPotongan(id) {
        let formData = {
            data: this.state.potongan,
            row: this.state.rows,
            id: id,
        }
        this.props.request('karyawan_potongan/update', formData, 'POST')
            .then(response => {
                if (response.success) {
                    toast['success'](response.message, { toastId: Math.floor(Math.random() * 2) })
                    this.setState({
                        isLoading: true,
                        rows: [{
                            "p_term_id": "", "k_potongan_type": "", "k_potongan_amount": ""
                        }]
                    })
                    this.loadPotongan(id)
                }
            });
    }


    potonganDelete(id, id_pot) {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: id_pot
                    }
                    this.props.request("karyawan_potongan/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                })
                                this.loadPotongan(id)
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                })
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            paginate: 20,
            page: this.state.currentPage + 1,
            filter_nama: this.state.filter_nama,
            filter_jabatan: this.state.filter_jabatan,
        }
        this.props.request("payroll/list_upah", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    cekMasterData() {
        if (this.props.user.perusahaan.divisi === null || this.props.user.perusahaan.jabatan === [] || this.props.user.perusahaan.lokasi === null) {
            Swal.fire({
                title: 'Peringatan!',
                text: 'Anda harus menambahkan Seluruh Master Data terlebih dahulu',
                icon: 'warning',
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.props.history.push('/employee/shift')
                    }
                })
        }
    }

    componentDidMount() {
        this.loadData()
        this.cekMasterData()
    }

    loadbyDivisi() {
        this.setState({
            loadingEdit: true
        })
        let form = {
            divisi_id: this.state.divisi_id,
        }
        this.props.request("payroll/list_by_divisi", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        kom     : response.kompensasi,
                        pot     : response.potongan,
                        divisi  : response.divisi
                    })
                }
                this.setState({loadingEdit : false})
            });
    }

    batchsimpan() {
        let row_kompensasi = this.state.rows_kom.map((kompensasi, index) => ({
            [`p_term_id`]           : kompensasi.kom_p_term_id,
            [`k_kompensasi_amount`] : kompensasi.k_kompensasi_amount,
            [`k_kompensasi_type`]   : kompensasi.k_kompensasi_type
        }))
        let row_potongan = this.state.rows_pot.map((potongan, index) => ({
            [`p_term_id`]           : potongan.pot_p_term_id,
            [`k_potongan_amount`]   : potongan.k_potongan_amount,
            [`k_potongan_type`]     : potongan.k_potongan_type
        }));
        let data_pot = this.state.pot.map(potongan => ({
            k_potongan_id           : potongan.k_potongan_id,
            karyawan_id             : potongan.karyawan_id,
            p_term_id               : potongan.p_term_id,
            k_potongan_amount       : potongan.k_potongan_amount,
            k_potongan_type         : potongan.k_potongan_type,
        }));

        let form = {
            divisi_id   : this.state.divisi_id,
            upah_lembur : this.state.upah_lembur,
            upah_gaji   : this.state.upah_gaji,
            kom         : row_kompensasi,
            data_pot    : data_pot,
            pot         : row_potongan,
        }
        this.props.request("payroll/batch_edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    if (response.success) {
                        Swal.fire({
                            title: 'Berhasil!',
                            text: response.message,
                            icon: 'success'
                        })
                        this.loadbyDivisi()
                        this.loadData()
                    }
                    if (response.failed) {
                        Swal.fire({
                            title: i18n.t('gagal'),
                            text: response.message,
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        })
                    }
                }
            })
    }

    batchDelete(type) {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: `Anda yakin ingin menghapus data ${type}?`,
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loadingEdit: true
                    })
                    let form = {
                        divisi_id   : this.state.divisi_id,
                        p_term_id   : this.state.p_term_id
                    }
                    this.props.request(`payroll/batch_delete/${type}`, form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    loadingEdit: true
                                })
                                this.loadbyDivisi()
                            }
                        });
                }
            })
    }

    render() {
        const stylefoto = {
            height: '50px',
            width: '50px',
            objectFit: 'cover'
        }
        let no = 0;
        const steps = [
            {
                selector: '.daftar-karyawan',
                content: 'Daftar Karyawan anda dapat dilihat disini',
            },
            {
                selector: '.filter-karyawan',
                content: 'Anda dapat mem-Filter daftar Karyawan anda lewat sini',
            },
        ];
        const { Option } = Select;
        const divisi = this.props.divisi
        let currency = this.props.user.perusahaan.perusahaan_currency;
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Daftar Gaji Karyawan") >= 0) {
            return (
                <Fragment>
                    <div className="body">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("daftargajikaryawan")} - Presensi</title>
                        <meta name="description" content="Daftar Gaji Karyawan - Presensi" />
                    </Helmet>
                    <Breadcrumb title={i18n.t("daftargajikaryawan")} parent={i18n.t('payroll')} setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                        <Modal style={{maxWidth: '900px', width: '100%'}} isOpen={this.state.modalbedit} toggle={() => this.setState({modalbedit : false})}>
                            <ModalHeader toggle={() => this.setState({modalbedit : false})}>
                                <div><h3>{i18n.t("editgaji")}</h3></div>
                            </ModalHeader>
                            <ModalBody>
                                <div className="row m-2">
                                    <div className="col-md-12 mb-2">
                                        <span>{i18n.t("pilihdivisi")}</span>
                                        <div className="mt-2">
                                            <Select
                                                name="divisi_id"
                                                style={{width: '100%'}}
                                                value={this.state.divisi_id}
                                                placeholder={i18n.t("pilihdivisi")}
                                                allowClear
                                                filterOption={(input, option) => {
                                                    const optionChildren = option.props.children ? option.props.children.toString() : '';
                                                    const optionValue = option.props.value ? option.props.value.toString() : '';
                                                    return (
                                                        optionChildren.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                        optionValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    );
                                                }}
                                                onChange={(value) => {
                                                    if (value === null) {
                                                        this.setState({
                                                            divisi_id: null,
                                                        }, () => {this.loadbyDivisi(this.state.divisi_id)});
                                                    } else {
                                                        this.setState({
                                                            divisi_id: value,
                                                        }, () => {this.loadbyDivisi(this.state.divisi_id)});
                                                    }
                                                }}
                                            >
                                                <Option key="semua divisi" value={null}>
                                                    {i18n.t("semuadivisi")}
                                                </Option>
                                                {this.props.divisi.map((item) => (
                                                    <Option key={item.divisi_id} value={item.divisi_id}>
                                                        {item.divisi_name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                {this.state.loadingEdit ?
                                    <Spin size="large" tip={i18n.t("sedangmemuatdata") + ' ...'} style={{
                                        width: '100%',
                                        margin: 'auto',
                                        marginBottom: '10px',
                                        marginTop: '10px'
                                    }}/>
                                    :
                                    <div>
                                        <div className="row m-2">
                                            <div className="col-md-6">
                                                <div className="mb-2">{i18n.t("upahlembur")}</div>
                                                <FormGroup>
                                                    <InputGroup>
                                                        <CurrencyInput
                                                            id="upah_lembur"
                                                            name="upah_lembur"
                                                            className="form-control"
                                                            placeholder={i18n.t("upahlembur")}
                                                            value={this.state.upah_lembur}
                                                            decimalSeparator=","
                                                            groupSeparator="."
                                                            prefix={getCurrency(currency)}
                                                            onValueChange={(value) => {
                                                                this.setState({upah_lembur: value ? parseInt(value.replace(/\./g, '')) : 0})
                                                            }}
                                                            onBlur={() => {
                                                                if (this.state.upah_lembur === "") {
                                                                    this.setState({
                                                                        upah_lembur: 0
                                                                    })
                                                                }
                                                            }}
                                                            onFocus={() => {
                                                                if (this.state.upah_lembur === 0 || this.state.upah_lembur === "0") {
                                                                    this.setState({upah_lembur: ""})
                                                                }
                                                            }}
                                                        />
                                                        <InputGroupAddon addonType="append">
                                                            <div
                                                                className="input-group-text mobile-hide"
                                                                style={{
                                                                    height: '38px',
                                                                    backgroundColor: "#eef1f3"
                                                                }}>
                                                                <Label
                                                                    style={{marginTop: '5px'}}>/{i18n.t("jam")}
                                                                </Label>
                                                            </div>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    <div className="mobile-show">
                                                        <InputGroupAddon addonType="prepend">
                                                            <div className="input-group-text"
                                                                 style={{backgroundColor: "#eef1f3"}}>
                                                                <Label>/{i18n.t("jam")}</Label>
                                                            </div>
                                                        </InputGroupAddon>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-2">{i18n.t("upahgaji")}</div>
                                                <FormGroup>
                                                    <InputGroup>
                                                        <CurrencyInput
                                                            id="upah_gaji"
                                                            name="upah_gaji"
                                                            className="form-control"
                                                            placeholder={i18n.t("upahgaji")}
                                                            value={this.state.upah_gaji}
                                                            decimalSeparator=","
                                                            groupSeparator="."
                                                            prefix={getCurrency(currency)}
                                                            onValueChange={(value) => {
                                                                this.setState({upah_gaji: value ? parseInt(value.replace(/\./g, '')) : 0})
                                                            }}
                                                            onBlur={() => {
                                                                if (this.state.upah_gaji === "") {
                                                                    this.setState({
                                                                        upah_gaji: 0
                                                                    })
                                                                }
                                                            }}
                                                            onFocus={() => {
                                                                if (this.state.upah_gaji === 0 || this.state.upah_gaji === "0") {
                                                                    this.setState({upah_gaji: ""})
                                                                }
                                                            }}
                                                        />
                                                        <InputGroupAddon addonType="append">
                                                            <div className="input-group-text mobile-hide"
                                                                 style={{height: '38px', backgroundColor: "#eef1f3"}}>
                                                                {this.state.divisi.map((item) => {
                                                                    return (
                                                                        <Label
                                                                            style={{marginTop: '5px'}}>/{i18n.t(item.divisi_payroll_type)}
                                                                        </Label>
                                                                    )
                                                                })}
                                                            </div>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                    <div className="mobile-show">
                                                        <InputGroupAddon addonType="append">
                                                            <div className="input-group-text"
                                                                 style={{backgroundColor: "#eef1f3"}}>
                                                                {this.state.divisi.map((item) => {
                                                                    return (
                                                                        <Label
                                                                            style={{marginTop: '5px'}}>/{i18n.t(item.divisi_payroll_type)}
                                                                        </Label>
                                                                    )
                                                                })}
                                                            </div>
                                                        </InputGroupAddon>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row m-2">
                                            <div className="col-md-12">
                                                <div className="mb-2">{i18n.t("kompensasi")}</div>
                                                <Row>
                                                    <Col lg="12">
                                                        {this.state.rows_kom.map((kompensasi, id) => {
                                                            return (
                                                                <Row>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            {this.state.inputTypeKompensasi == "select" ?
                                                                                <Input type="select"
                                                                                       name="kom_p_term_id"
                                                                                       value={this.state.rows_kom.p_term_id}
                                                                                       onChange={this.handleChangeStock('rows_kom', id)}

                                                                                >
                                                                                    <option
                                                                                        value="">- {i18n.t("daftaristillah")} -
                                                                                    </option>
                                                                                    {
                                                                                        this.state.data_term.map((item, index) => {
                                                                                            return (
                                                                                                <option
                                                                                                    key={index}
                                                                                                    value={item.p_term_id}
                                                                                                    selected={item.p_term_id === this.state.data_term.p_term_id}>
                                                                                                    {item.p_term_name}
                                                                                                </option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    <option value="text"
                                                                                            className="text-center"
                                                                                            style={{color: "#0080ff"}}>+ {i18n.t("tambahpayrollistilah")}
                                                                                    </option>
                                                                                </Input>
                                                                                :
                                                                                <div className="form-group">
                                                                                    <div className="input-group mb-3">
                                                                                        <input name="p_term_name"
                                                                                               style={{fontSize: "12px"}}
                                                                                               type="text"
                                                                                               onChange={this.handleChangeistilah}
                                                                                               className="form-control border-right-0 "
                                                                                               id="exampleInputEmail1"
                                                                                               aria-describedby="emailHelp"
                                                                                               placeholder={i18n.t("masukkanistilahbaru")}
                                                                                               autoFocus
                                                                                        />
                                                                                        {this.state.p_term_name.length >= 1 ?
                                                                                            <div className="input-group-append border-left-0">
                                                                                                <span className="input-group-text" style={{fontSize: "12px", backgroundColor: "#FFFFFF"}} id="basic-addon2">
                                                                                                    <a style={{fontSize: "12px", color: "#057CE4"}} onClick={(e) => this.handleSubmitIstilah("kompensasi", e)}>
                                                                                                        {i18n.t("tambahistilah")}</a>
                                                                                                </span>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="input-group-append border-left-0">
                                                                                                <span className="input-group-text" style={{fontSize: "12px", backgroundColor: "#FFFFFF"}} id="basic-addon2">
                                                                                                    <a style={{fontSize: "12px", color: "#057CE4"}} onClick={this.istillahBatal}>
                                                                                                        {i18n.t("batal")}
                                                                                                    </a>
                                                                                                </span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <select
                                                                                name="k_kompensasi_type"
                                                                                id="k_kompensasi_type"
                                                                                className="form-control text-center"
                                                                                onChange={this.handleChangeStock('rows_kom', id)}
                                                                                placeholder={i18n.t("tipekompensasi")}
                                                                            >
                                                                                <option value={""}>{i18n.t("tipekompensasi")}</option>
                                                                                <option value="jam">{i18n.t("jam")}</option>
                                                                                <option value="hari">{i18n.t("hari")}</option>
                                                                                <option value="bulan">{i18n.t("bulan")}</option>
                                                                            </select>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <Input
                                                                                className="form-control text-center"
                                                                                name="k_kompensasi_amount"
                                                                                id="k_kompensasi_amount"
                                                                                type="number"
                                                                                onChange={this.handleChangeStock('rows_kom', id)}
                                                                                value={kompensasi.k_kompensasi_amount}
                                                                                placeholder={i18n.t("nominalkompensasi")}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="2">
                                                                        <ButtonGroup size="sm">
                                                                            <div>
                                                                                {this.state.rows_kom.length !== 1 &&
                                                                                    <Button
                                                                                        className="mb-3"
                                                                                        onClick={() => this.removeClickKom(id)}
                                                                                        color='danger'
                                                                                    >
                                                                                        <i className="fa fa-trash"></i>
                                                                                    </Button>}
                                                                                &nbsp;
                                                                                {this.state.rows_kom.length - 1 === id &&
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn ipresens-btn-color"
                                                                                        onClick={this.addClickKom}
                                                                                        color="info"
                                                                                    >
                                                                                        <i className="fa fa-plus"></i>
                                                                                    </button>
                                                                                }
                                                                            </div>
                                                                        </ButtonGroup>
                                                                    </Col>
                                                                </Row>
                                                            );
                                                        })}
                                                    </Col>
                                                </Row>
                                                {this.state.kom.length === 0 ?
                                                    <div className="text-center" colSpan="9">
                                                        {i18n.t("datakosong")}
                                                    </div> :
                                                    <Table striped className="mb-5">
                                                        <Thead>
                                                            <Tr>
                                                                <Th>{i18n.t("namakompensasi")}</Th>
                                                                <Th>{i18n.t("tipekompensasi")}</Th>
                                                                <Th>{i18n.t("nominalkompensasi")}</Th>
                                                                <Th>Status</Th>
                                                                <Th>{i18n.t("aksi")}</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {this.state.loadingEdit &&
                                                                <Tr>
                                                                    <Td colSpan={9}>
                                                                        <Spin size="large" tip="Loading..." style={{
                                                                            width: '100%',
                                                                            margin: 'auto',
                                                                            marginBottom: '10px',
                                                                            marginTop: '10px'
                                                                        }}/>
                                                                    </Td>
                                                                </Tr>
                                                            }
                                                            {Array.from(new Set(this.state.kom.map(item => item.p_term_id))).map((p_term_id, index) => {
                                                                const item = this.state.kom.find(item => item.p_term_id === p_term_id);
                                                                return (
                                                                    <Tr>
                                                                        <Td>
                                                                            {item.payroll_term.p_term_name}
                                                                        </Td>
                                                                        <Td>
                                                                            {i18n.t(item.k_kompensasi_type)}
                                                                        </Td>
                                                                        <Td>
                                                                            {format({
                                                                                prefix: '',
                                                                                integerSeparator: "."
                                                                            })(item.k_kompensasi_amount)}
                                                                        </Td>
                                                                        <Td>
                                                                            <Switch
                                                                                id={`detail-${item.k_kompensasi_id}`}
                                                                                name="k_kompensasi_status"
                                                                                checked={item.k_kompensasi_status === '1' ? true : false}
                                                                                onChange={(val) => {
                                                                                    this.setState({
                                                                                        loading: true,
                                                                                        data: []
                                                                                    })
                                                                                    let formData = {
                                                                                        id: item.k_kompensasi_id,
                                                                                    }
                                                                                    this.props.request('kompensasi/ubah_status', formData, 'POST')
                                                                                        .then((response) => {
                                                                                            if (response.success) {
                                                                                                Swal.fire({
                                                                                                    title: 'Berhasil!',
                                                                                                    text: response.message,
                                                                                                    icon: 'success',
                                                                                                    showConfirmButton: false,
                                                                                                    timer: 1500,
                                                                                                })
                                                                                            } else {
                                                                                                if (Array.isArray(response.message)) {
                                                                                                    response.message.map(item => {
                                                                                                        toast['error'](item)
                                                                                                        return true
                                                                                                    })
                                                                                                } else {
                                                                                                    toast['error'](response.message)
                                                                                                }
                                                                                            }
                                                                                            this.loadbyDivisi(this.state.divisi_id)
                                                                                            this.setState({
                                                                                                loading: false
                                                                                            })
                                                                                        })
                                                                                }}
                                                                                onColor="#1890ff"
                                                                                offColor="#eb4034"
                                                                                handleDiameter={16}
                                                                                uncheckedIcon={
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            alignItems: "center",
                                                                                            height: "100%",
                                                                                            fontSize: 3,
                                                                                            color: "white",
                                                                                            paddingRight: -5
                                                                                        }}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faTimes}
                                                                                                         style={{fontSize: 15}}/>
                                                                                    </div>
                                                                                }
                                                                                checkedIcon={
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            alignItems: "center",
                                                                                            height: "100%",
                                                                                            fontSize: 4,
                                                                                            color: "white",
                                                                                            paddingLeft: 3
                                                                                        }}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faCheck}
                                                                                                         style={{fontSize: 15}}/>
                                                                                    </div>
                                                                                }
                                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                                height={18}
                                                                                width={40}
                                                                                className="mr-1"
                                                                            />
                                                                        </Td>
                                                                        <Td>
                                                                            <a onClick={() => {
                                                                                this.setState({
                                                                                    p_term_id: item.p_term_id,
                                                                                }, () => {
                                                                                    this.batchDelete('Kompensasi')
                                                                                })
                                                                            }}><i className="fa fa-trash" style={{
                                                                                width: 35,
                                                                                fontSize: 20,
                                                                                padding: 11,
                                                                                color: 'rgb(255 37 37)'
                                                                            }}
                                                                            ></i></a>
                                                                        </Td>
                                                                    </Tr>
                                                                )
                                                            })}
                                                        </Tbody>
                                                    </Table>
                                                }
                                            </div>
                                        </div>
                                        <div className="ml-2">
                                            <div className="mb-2 ml-3">
                                                {i18n.t("potongan")}
                                            </div>
                                            <Col lg="12">
                                                {Array.from(new Set(this.state.pot.map(item => item.p_term_id)))
                                                    .map((p_term_id, id) => {
                                                        const item = this.state.pot.find(item => item.p_term_id === p_term_id);
                                                        const index = this.state.pot.findIndex(item => item.p_term_id === p_term_id);
                                                        return (
                                                            <Row key={p_term_id}>
                                                                <Col className="text-center" name="p_term_id"
                                                                     value={item.p_term_id}>
                                                                    {i18n.t(item.payroll_term?.p_term_name)}
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <select
                                                                            name="k_potongan_type"
                                                                            id="k_potongan_type"
                                                                            className="form-control"
                                                                            onChange={(e) => {
                                                                                const updatedPot = this.state.pot.map(potItem => {
                                                                                    if (potItem.p_term_id === p_term_id) {
                                                                                        return {
                                                                                            ...potItem,
                                                                                            k_potongan_type: e.target.value
                                                                                        };
                                                                                    }
                                                                                    return potItem;
                                                                                });
                                                                                this.setState({pot: updatedPot});
                                                                            }}
                                                                            placeholder={i18n.t("tipepotongan")}
                                                                            value={item.k_potongan_type !== null ? item.k_potongan_type : ""}
                                                                        >
                                                                            <option value="">{i18n.t("tipepotongan")}</option>
                                                                            <option value="jam">{i18n.t("jam")}</option>
                                                                            <option value="hari">{i18n.t("hari")}</option>
                                                                            <option value="bulan">{i18n.t("bulan")}</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <CurrencyInput
                                                                            id="k_potongan_amount"
                                                                            name="k_potongan_amount"
                                                                            className="form-control"
                                                                            placeholder={i18n.t("nominalpotongan")}
                                                                            value={item.k_potongan_amount !== null ? item.k_potongan_amount : ""}
                                                                            decimalSeparator=","
                                                                            defaultValue={item.k_potongan_amount}
                                                                            groupSeparator="."
                                                                            prefix={getCurrency(currency)}
                                                                            onValueChange={(value) => {
                                                                                const finalValue = value ? parseInt(value.replace(/\./g, '')) : 0;
                                                                                const updatedPot = this.state.pot.map(potItem => {
                                                                                    if (potItem.p_term_id === p_term_id) {
                                                                                        return {
                                                                                            ...potItem,
                                                                                            k_potongan_amount: finalValue
                                                                                        };
                                                                                    }
                                                                                    return potItem;
                                                                                });
                                                                                this.setState({pot: updatedPot});
                                                                            }}
                                                                            onBlur={() => {
                                                                                if (!item.k_potongan_amount) {
                                                                                    let updatedPot = [...this.state.pot];
                                                                                    updatedPot[index] = {
                                                                                        ...updatedPot[index],
                                                                                        k_potongan_amount: 0
                                                                                    };
                                                                                    this.setState({pot: updatedPot});
                                                                                }
                                                                            }}
                                                                            onFocus={() => {
                                                                                if (item.k_potongan_amount === 0) {
                                                                                    let updatedPot = [...this.state.pot];
                                                                                    updatedPot[index] = {
                                                                                        ...updatedPot[index],
                                                                                        k_potongan_amount: 0
                                                                                    };
                                                                                    this.setState({pot: updatedPot});
                                                                                }
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="2">
                                                                    {item.payroll_term.p_term_prefix === "general" ? (
                                                                        <ButtonGroup size="sm">
                                                                            <Button
                                                                                className="mt-1"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        p_term_id: item.p_term_id,
                                                                                    }, () => {
                                                                                        this.batchDelete('Potongan')
                                                                                    })
                                                                                }}
                                                                                color="danger"
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </Button>
                                                                        </ButtonGroup>
                                                                    ) : ""}
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })
                                                }
                                                <Row>
                                                    <Col lg="12">
                                                        {this.state.rows_pot.map((potongan, id) => {
                                                            return (
                                                                <Row className="row-md-12">
                                                                    <Col>
                                                                        <FormGroup>
                                                                            {this.state.inputTypePotongan == "select" ?
                                                                                <Input type="select"
                                                                                       name="pot_p_term_id"
                                                                                       value={potongan.p_term_id}
                                                                                       onChange={this.handleChangeStock('rows_pot', id)}

                                                                                >
                                                                                    <option
                                                                                        value="">- {i18n.t("daftaristillah")} -
                                                                                    </option>
                                                                                    {
                                                                                        this.state.data_term_potongan.map((item, index) => {
                                                                                            return (
                                                                                                <option
                                                                                                    key={index}
                                                                                                    value={item.p_term_id}
                                                                                                    selected={item.p_term_id === this.state.data_term_potongan.p_term_id}>
                                                                                                    {item.p_term_name}
                                                                                                </option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    <option value="text"
                                                                                            className="text-center"
                                                                                            style={{color: "#0080ff"}}>+ {i18n.t("tambahpayrollistilah")}
                                                                                    </option>
                                                                                </Input>
                                                                                :
                                                                                <div className="form-group">
                                                                                    <div className="input-group mb-3">
                                                                                        <input name="p_term_name"
                                                                                               style={{fontSize: "12px"}}
                                                                                               type="text"
                                                                                               onChange={this.handleChangeistilah}
                                                                                               className="form-control border-right-0 "
                                                                                               id="exampleInputEmail1"
                                                                                               aria-describedby="emailHelp"
                                                                                               placeholder={i18n.t("masukkanistilahbaru")}
                                                                                               autoFocus
                                                                                        />
                                                                                        {this.state.p_term_name.length >= 1 ?
                                                                                            <div
                                                                                                className="input-group-append border-left-0">
                                                                                        <span
                                                                                            className="input-group-text"
                                                                                            style={{
                                                                                                fontSize: "12px",
                                                                                                backgroundColor: "#FFFFFF"
                                                                                            }} id="basic-addon2">
                                                                                            <a style={{
                                                                                                fontSize: "12px",
                                                                                                color: "#057CE4"
                                                                                            }}
                                                                                               onClick={(e) => this.handleSubmitIstilah("potongan", e)}>{i18n.t("tambahistilah")}</a>
                                                                                        </span>
                                                                                            </div>
                                                                                            :
                                                                                            <div
                                                                                                className="input-group-append border-left-0">
                                                                                        <span
                                                                                            className="input-group-text"
                                                                                            style={{
                                                                                                fontSize: "12px",
                                                                                                backgroundColor: "#FFFFFF"
                                                                                            }} id="basic-addon2">
                                                                                            <a style={{
                                                                                                fontSize: "12px",
                                                                                                color: "#057CE4"
                                                                                            }}
                                                                                               onClick={this.istillahBatal}>{i18n.t("batal")}</a>
                                                                                        </span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <select
                                                                                name="k_potongan_type"
                                                                                id="k_potongan_type"
                                                                                className="form-control"
                                                                                onChange={this.handleChangeStock('rows_pot', id)}
                                                                                placeholder={i18n.t("tipepotongan")}
                                                                                value={potongan.k_potongan_type}
                                                                            >
                                                                                <option value={""}>{i18n.t("tipepotongan")}</option>
                                                                                <option value="jam">{i18n.t("jam")}</option>
                                                                                <option value="hari">{i18n.t("hari")}</option>
                                                                                <option value="bulan">{i18n.t("bulan")}</option>
                                                                            </select>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <Input
                                                                                className="form-control "
                                                                                name="k_potongan_amount"
                                                                                id="k_potongan_amount"
                                                                                type="number"
                                                                                onChange={this.handleChangeStock('rows_pot', id)}
                                                                                value={potongan.k_potongan_amount}
                                                                                placeholder={i18n.t("nominalpotongan")}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="2">
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        })}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </div>
                                    </div>
                                }
                            </ModalBody>
                            <ModalFooter>
                                <div className="mr-2">
                                    <button className="btn ipresens-btn-color" onClick={() => {
                                        this.batchsimpan()
                                    }}>{i18n.t("simpan")}</button>
                                </div>
                            </ModalFooter>
                        </Modal>
                        <div className="container-fluid list-karyawan rounded">
                            <div className="card border border-secondary-subtle">
                                <div className="row filter-karyawan"
                                     style={{marginLeft: '5px', marginTop: "20px", marginBottom: '6px'}}>
                                    <div className="col-md-2 pl-0 pr-0 mt-1 column-filter column-filter-input">
                                        <input name="filter_nama"
                                               className={this.state.filter_nama === "" ? "form-control bg-image-input filter-name" : "form-control filter-name"}
                                               placeholder={'     ' + i18n.t("carinamakaryawan") + '...'}
                                               type="text"
                                               value={this.state.filter_nama}
                                               onChange={this.handleChange}
                                               onKeyUp={(event) => {
                                                   event.preventDefault()
                                                   if (event.which == 13) {

                                                       this.setState({
                                                           page: 0,
                                                           paging: this.state.paging,
                                                           loading: true
                                                       }, () => {
                                                           this.loadData()
                                                       });
                                                   }
                                               }}/>
                                    </div>
                                    <div className="col-md-4 pl-0 pr-0 mt-1 column-filter">
                                        <div className="input-group btn-slct-payroll">
                                            <select name="filter_jabatan" value={this.state.filter_jabatan}
                                                    className="form-control col mr-1"
                                                    style={{fontSize: "12px"}} onChange={this.handleFilter}>
                                                <option value="">{i18n.t("pilihjabatan")}</option>
                                                {this.props.jabatan.map((item, index) => {
                                                    return (
                                                        <option value={item.jabatan_id}>{item.jabatan_nama}</option>
                                                    )
                                                })}
                                            </select>
                                            <button className="btn button-styling px-2" onClick={() => {
                                                this.resetFilter()
                                            }}><img src={refreshIcon}/></button>
                                        </div>
                                    </div>
                                    <div className="col-md-5 mt-1 column-filter ml-4">
                                        <button className="btn btn-primary pull-right text-capitalize"
                                            onClick={() => this.setState({modalbedit: true}, () => {
                                                this.loadbyDivisi()
                                                this.loadDataTerm()
                                                this.loadDataTermPotongan()
                                            })}><img src={pencilIcon}/> Batch Edit
                                        </button>
                                    </div>
                                </div>
                                <hr style={{marginTop: '8px', marginBottom: "8px"}}/>
                                <div className="card-body daftar-karyawan pt-0">
                                    {!this.state.loading && this.state.data.length === 0 ?
                                        <Empty description="Data Kosong"
                                               image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                        :
                                        <Table className="table mb-5 table-striped table-borderless table-style">
                                            <Thead>
                                                <Tr>
                                                    <Th width="30">#</Th>
                                                    <Th className="text-center" width="80"><span
                                                        style={{fontSize: 12}}>{i18n.t("foto")}</span></Th>
                                                    <Th className="text-center" width="180"><span
                                                        style={{fontSize: 12}}>{i18n.t("namakaryawan")}</span></Th>
                                                    <Th className="text-center" width="150"><span
                                                        style={{fontSize: 12}}>{i18n.t("jabatan/divisi")}</span></Th>
                                                    <Th className="text-left" width="250"><span
                                                        style={{fontSize: 12}}>{i18n.t("upahlembur")}</span></Th>
                                                    <Th className="text-left" width="250"><span
                                                        style={{fontSize: 12}}>{i18n.t("upahgaji")}</span></Th>
                                                    <Th className="text-center" width="100"><span
                                                        style={{fontSize: 12}}>{i18n.t("kompensasi")}</span></Th>
                                                    <Th className="text-center" width="100"><span
                                                        style={{fontSize: 12}}>{i18n.t("potongan")}</span></Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {this.state.loading &&
                                                    <Tr>
                                                        <Td colSpan={10}>
                                                            <Spin size="large" tip="Loading..." style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                                marginBottom: '10px',
                                                                marginTop: '10px'
                                                            }}/>
                                                        </Td>
                                                    </Tr>
                                                }
                                                {this.state.data.map((item, index) => {
                                                    no += 1
                                                    return (
                                                        <Tr className="table-tr">
                                                            <small>
                                                                <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                            </small>
                                                            <Td style={{border: "none"}} align="center">
                                                                {item.karyawan_foto !== null ? (
                                                                    <img style={stylefoto} alt='' src={IMG_HOST + 'mitra/frontapp/karyawan/' + item.karyawan_foto}/>
                                                                ) : (
                                                                    <img style={stylefoto} alt='' src={IMG_HOST + "user-profile.png"}/>
                                                                )}
                                                            </Td>
                                                            <Td align="center">
                                                                <span style={{fontWeight: 600}}>{item.karyawan_nama}</span></Td>
                                                            <Td align="center">
                                                                <span style={{fontWeight: 600}}>{item.jabatan.jabatan_nama}/{item.divisi.divisi_name}</span></Td>
                                                            {/* upah lembur */}
                                                            <Td className="text-center">
                                                                <div style={{marginTop: '20px'}}>
                                                                    <FormGroup>
                                                                        <InputGroup>
                                                                            <CurrencyInput
                                                                                name="karyawan_lembur"
                                                                                className="form-control text-center"
                                                                                value={item.karyawan_lembur}
                                                                                id="karyawan_lembur"
                                                                                onKeyPress={(event) => {
                                                                                    if (event.which == 13) {
                                                                                        event.preventDefault()
                                                                                        this.handleSubmitAmount(event, item.karyawan_id)
                                                                                    }
                                                                                }}
                                                                                onBlur={(event) => {
                                                                                    this.handleSubmitAmount(event, item.karyawan_id)
                                                                                }}
                                                                                onValueChange={(value, name) => {
                                                                                    let newData = this.state.data
                                                                                    newData[index].karyawan_lembur = value
                                                                                    this.setState({
                                                                                        editedValue: value,
                                                                                        data: newData
                                                                                    })
                                                                                }}
                                                                            />
                                                                            <InputGroupAddon addonType="prepend">
                                                                                <div
                                                                                    className="input-group-text mobile-hide"
                                                                                    style={{
                                                                                        height: '38px',
                                                                                        backgroundColor: "#eef1f3"
                                                                                    }}>
                                                                                    <Label
                                                                                        style={{marginTop: '5px'}}>/{i18n.t("jam")}
                                                                                    </Label>
                                                                                </div>
                                                                            </InputGroupAddon>
                                                                        </InputGroup>
                                                                        <div className="mobile-show">
                                                                            <InputGroupAddon addonType="prepend">
                                                                                <div className="input-group-text"
                                                                                     style={{backgroundColor: "#eef1f3"}}>
                                                                                    <Label>/{i18n.t("jam")}</Label>
                                                                                </div>
                                                                            </InputGroupAddon>
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                            </Td>
                                                            {/* upah gaji */}
                                                            <Td className="text-center">
                                                                <div style={{marginTop: '20px'}}
                                                                     className="input-column">
                                                                    <FormGroup>
                                                                        <InputGroup>
                                                                            <CurrencyInput
                                                                                name="karyawan_payroll_amount"
                                                                                className="form-control text-center"
                                                                                value={item.karyawan_payroll_amount}
                                                                                id="karyawan_payroll_amount"
                                                                                onKeyPress={(event) => {
                                                                                    if (event.which == 13) {
                                                                                        event.preventDefault()
                                                                                        this.handleSubmitAmount(event, item.karyawan_id)
                                                                                    }
                                                                                }}
                                                                                onBlur={(event) => {
                                                                                    this.handleSubmitAmount(event, item.karyawan_id)
                                                                                }}
                                                                                onValueChange={(value, name) => {
                                                                                    let newData = this.state.data
                                                                                    newData[index].karyawan_payroll_amount = value
                                                                                    this.setState({
                                                                                        editedValue: value,
                                                                                        data: newData
                                                                                    })
                                                                                }}
                                                                            />
                                                                            <InputGroupAddon addonType="prepend">
                                                                                <div
                                                                                    className="input-group-text mobile-hide"
                                                                                    style={{
                                                                                        height: '38px',
                                                                                        backgroundColor: "#eef1f3"
                                                                                    }}>
                                                                                    <Label
                                                                                        style={{marginTop: '5px'}}>/{item.divisi.divisi_payroll_type}
                                                                                    </Label>
                                                                                </div>
                                                                            </InputGroupAddon>
                                                                        </InputGroup>
                                                                        <div className="mobile-show">
                                                                            <InputGroupAddon addonType="prepend">
                                                                                <div className="input-group-text"
                                                                                     style={{backgroundColor: "#eef1f3"}}>
                                                                                    <Label>/{item.divisi.divisi_payroll_type}</Label>
                                                                                </div>
                                                                            </InputGroupAddon>
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                            </Td>
                                                            <Td align="center">
                                                                <button id={`detail-${item.karyawan_id}`}
                                                                        className="btn btn-shadow ipresens-btn-color button-style-edit"
                                                                        style={{width: "77px", paddingLeft: "5px"}}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                karyawan_id: item.karyawan_id,
                                                                            }, () => {
                                                                                this.loadKompensasi(item.karyawan_id)
                                                                                this.loadDataTerm()
                                                                            })
                                                                        }}
                                                                ><img src={pencilIcon}/> Edit
                                                                </button>
                                                                &nbsp;
                                                                <UncontrolledTooltip placement="top"
                                                                                     target={`detail-${item.karyawan_id}`}>
                                                                    {i18n.t("listkompensasi")}
                                                                </UncontrolledTooltip>
                                                            </Td>
                                                            <Td align="center">
                                                                <button id={`list-${item.karyawan_id}`}
                                                                        className="btn btn-shadow ipresens-btn-color button-style-edit"
                                                                        style={{width: "77px", paddingLeft: "5px"}}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                karyawan_id: item.karyawan_id
                                                                            }, () => {
                                                                                this.loadPotongan(item.karyawan_id)
                                                                                this.loadDataTermPotongan()
                                                                            })
                                                                        }}
                                                                ><img src={pencilIcon}/> Edit
                                                                </button>
                                                                &nbsp;
                                                                <UncontrolledTooltip placement="top"
                                                                                     target={`list-${item.karyawan_id}`}>
                                                                    {i18n.t("listpotongan")}
                                                                </UncontrolledTooltip>
                                                            </Td>
                                                        </Tr>
                                                    )}
                                                )}
                                            </Tbody>
                                        </Table>
                                    }
                                    <Paging
                                        className="float-right"
                                        handlePageClick={this.handlePageClick}
                                        pageCount={this.state.pageCount}
                                        currentPage={this.state.currentPage}
                                    />
                                </div>
                            </div>
                        </div>
                        <KOMPENSASI
                            kompensasi={this.state.kompensasi}
                            id={this.state.karyawan_id}
                            visibleKom={this.state.visibleKom}
                            dataTerm={this.state.data_term}
                            rows={this.state.rows}
                            addClickKom={this.addClickKom}
                            handleSubmitKom={this.handleSubmitKom}
                            removeClickKom={this.removeClickKom}
                            handleChangeStock={this.handleChangeStock}
                            handleChangeistilah={this.handleChangeistilah}
                            wantDeleteKom={this.wantDeleteKom}
                            kompensasiModal={this.kompensasiModal}
                            loadKompensasi={this.loadKompensasi}
                            request={this.props.request}
                            loading={this.state.loading}
                            inputType={this.state.inputType}
                            p_term_name={this.state.p_term_name}
                            handleSubmitIstilah={this.handleSubmitIstilah}
                            istillahBatal={this.istillahBatal}
                            changeState={(event) => {
                                this.setState(event)
                            }}
                        />
                        <POTONGAN
                            potongan={this.state.potongan}
                            visiblePot={this.state.visiblePot}
                            id={this.state.karyawan_id}
                            rows={this.state.rows}
                            dataTermPotongan={this.state.data_term_potongan}
                            handleChangeStock={this.handleChangeStock}
                            potonganModal={this.potonganModal}
                            handlePotonganType={this.handlePotonganType}
                            handlePotonganAmount={this.handleChangePotongan}
                            handleSubmitPotongan={this.handleSubmitPotongan}
                            handleSubmitIstilah={this.handleSubmitIstilah}
                            istillahBatal={this.istillahBatal}
                            inputType={this.state.inputType}
                            p_term_name={this.state.p_term_name}
                            removeClick={this.potonganDelete}
                            handleChangeistilah={this.handleChangeistilah}
                            changeState={(event) => {
                                this.setState(event)
                            }}
                        />
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    jabatan: state.Filter._jabatan,
    divisi: state.Filter._divisi
})

export default connect(mapStateToProps)(List_GajiKaryawan);
