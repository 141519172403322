import React from 'react';
import GoogleIcon from "../../../assets/icons/google-icon.svg";
import { useGoogleLogin } from '@react-oauth/google';
import i18n from 'i18next';
import Swal from "sweetalert2";

export default function GoogleAuthButtonRemember(props) {
    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            props.action(tokenResponse.access_token);
        },
        onError: errorResponse => {
        },
    });

    const handleClick = async () => {
        const result = await Swal.fire({
            title: i18n.t("peringatan"),
            text: i18n.t("andayakiningatsaya"),
            confirmButtonText: i18n.t("lanjutkan"),
            showCancelButton: true,
            cancelButtonText: i18n.t("batal"),
            icon: 'warning',
        });

        if (result.isConfirmed) {
            googleLogin();
        }
    };

    return (
        <button
            className="btn ipresens-btn-color loginv2-button-login"
            onClick={handleClick}
        >
            <img alt="" src={GoogleIcon} style={{ width: "16px", height: "16px", marginRight: 8 }} />
            <span className='fs-10'>{i18n.t('lanjutdgngoogle')}</span>
        </button>
    );
}
