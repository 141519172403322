import React, {Component} from 'react'
import {APIKEY} from "../../helper/host";
import {Button, ButtonGroup, Col, FormGroup, Input, Modal, ModalBody, ModalHeader, Row} from 'reactstrap'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import CurrencyInput from 'react-currency-input';
import {GoogleApiWrapper} from 'google-maps-react';
import i18n from "i18next";

export class ModalPotongan extends Component {
    constructor(props) {
        super(props);

        this.state      = {
            loading                 : false,

        }

    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.visiblePot} toggle={this.props.potonganModal}
                       style={{maxWidth: '900px', width: '100%'}}>
                    <ModalHeader>
                        {i18n.t("listpotongan")}
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <Col lg="12">
                                {this.props.potongan.map((item, id) =>{
                                    return(
                                        <Row>
                                            <Col className="text-center">
                                                {item.payroll_term.p_term_name}
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <select
                                                        name="k_potongan_type"
                                                        id="k_potongan_type"
                                                        className="form-control"
                                                        onChange={this.props.handlePotonganType(id)}
                                                        placeholder={i18n.t("tipepotongan")}
                                                        value={item.k_potongan_type}
                                                    >
                                                        <option value={""}>{i18n.t("tipepotongan")}</option>
                                                        <option value="jam">{i18n.t("jam")}</option>
                                                        <option value="hari">{i18n.t("hari")}</option>
                                                        <option value="bulan">{i18n.t("bulan")}</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Input
                                                        className="form-control"
                                                        name="k_potongan_amount"
                                                        id="k_potongan_amount"
                                                        type="number"
                                                        onChange={this.props.handlePotonganAmount(id)}
                                                        value={item.k_potongan_amount}
                                                        placeholder={i18n.t("nominalpotongan")}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="2">
                                                {item.payroll_term.p_term_prefix == "general" ?
                                                <ButtonGroup size="sm">

                                                    <Button
                                                        onClick={this.props.removeClick.bind(this,item.karyawan_id, item.k_potongan_id)}
                                                        color='danger'
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Button>


                                                </ButtonGroup>
                                                    : ""}
                                            </Col>
                                        </Row>
                                    )
                                })}
                                <Row>
                                    <Col lg="12">
                                        {this.props.rows.map((data, id) =>{
                                            return(
                                                <Row className="row-md-12">
                                                    <Col>
                                                        <FormGroup>
                                                            {this.props.inputType == "select" ?
                                                                <Input  type="select" name="p_term_id" value={data.p_term_id}
                                                                        onChange={this.props.handleChangeStock('rows', id)}

                                                                >
                                                                    <option value="">- {i18n.t("daftaristilah")} -</option>
                                                                    {
                                                                        this.props.dataTermPotongan.map((item, index) => {
                                                                            return (
                                                                                <option
                                                                                    key={index}
                                                                                    value={item.p_term_id}
                                                                                    selected={item.p_term_id == this.props.dataTermPotongan.p_term_id}>
                                                                                    {item.p_term_name}
                                                                                </option>
                                                                            )
                                                                        })
                                                                    } <option value="text"  className="text-center"
                                                                              style={{color:"#0080ff"}}>+ {i18n.t("tambahpayrollistilah")}</option>
                                                                </Input>
                                                                :
                                                                <div className="form-group">
                                                                    <div className="input-group mb-3">
                                                                        <input name="p_term_name" style={{fontSize: "12px"}}
                                                                               type="text" onChange={this.props.handleChangeistilah}
                                                                               className="form-control border-right-0 "
                                                                               id="exampleInputEmail1"
                                                                               aria-describedby="emailHelp"
                                                                               placeholder={i18n.t("masukkanistilahbaru")}
                                                                               autoFocus
                                                                        />
                                                                        {this.props.p_term_name.length >= 1 ?
                                                                            <div className="input-group-append border-left-0">
                                                                        <span class="input-group-text" style={{
                                                                            fontSize: "12px",
                                                                            backgroundColor: "#FFFFFF"
                                                                        }} id="basic-addon2">
                                                                            <a style={{fontSize: "12px", color: "#057CE4"}}
                                                                               onClick={this.props.handleSubmitIstilah.bind(this,"potongan")}
                                                                            >{i18n.t("tambahistilah")}</a>
                                                                        </span>
                                                                            </div>
                                                                            :
                                                                            <div className="input-group-append border-left-0">
                                                                        <span className="input-group-text" style={{
                                                                            fontSize: "12px",
                                                                            backgroundColor: "#FFFFFF"
                                                                        }} id="basic-addon2">
                                                                            <a style={{
                                                                                fontSize: "12px",
                                                                                color: "#057CE4"
                                                                            }}
                                                                               onClick={this.props.istillahBatal.bind(this)}
                                                                            >{i18n.t("batal")}</a>
                                                                        </span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <select
                                                                name="k_potongan_type"
                                                                id="k_potongan_type"
                                                                className="form-control"
                                                                onChange={this.props.handleChangeStock('rows', id)}
                                                                placeholder={i18n.t("tipepotongan")}
                                                                value={data.k_potongan_type}
                                                            >
                                                                <option value={""}>{i18n.t("tipepotongan")}</option>
                                                                <option value="jam">{i18n.t("jam")}</option>
                                                                <option value="hari">{i18n.t("hari")}</option>
                                                                <option value="bulan">{i18n.t("bulan")}</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Input
                                                                className="form-control "
                                                                name="k_potongan_amount"
                                                                id="k_potongan_amount"
                                                                type="number"
                                                                onChange={this.props.handleChangeStock('rows', id)}
                                                                value={data.k_potongan_amount}
                                                                placeholder={i18n.t("nominalpotongan")}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">

                                                    </Col>
                                                </Row>
                                            )})}
                                    </Col>
                                </Row>
                                <Row form className="text-center col-md-12 mt-3 mb-3" style={{alignItems: "center", justifyContent: "center"}}>
                                    <button
                                        onClick={()=>{this.props.handleSubmitPotongan(this.props.id)}}
                                        color="success"
                                        type="button"
                                        className="btn ipresens-btn-color"
                                    >
                                        {i18n.t("simpan")}
                                    </button>
                                </Row>
                            </Col>
                        </div>
                    </ModalBody>
                </Modal>
            </div>

        )
    }
}

export default  GoogleApiWrapper({
    apiKey: APIKEY
}) (ModalPotongan)
