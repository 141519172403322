import React from "react";
import { useDrop } from "react-dnd";
const statuses = [
    {
        index: 1,
        status: "open",
        color: "#EB5A46",
    },
    {
        index: 2,
        status: "in progress",
        color: "#00C2E0",
    },
    {
        index: 3,
        status: "in review",
        color: "#C377E0",
    },
    {
        index: 4,
        status: "done",
        color: "#3981DE",
    },
];
const DropWrapper = ({ onDrop, children, status }) => {
    const [{ isOver }, drop] = useDrop({
        accept: 'ITEM',
        canDrop: (item, monitor) => {
            const itemIndex = statuses.findIndex((si) => si.status === item.status);
            const statusIndex = statuses.findIndex((si) => si.index === status);
            return [itemIndex + 1, itemIndex - 1, itemIndex].includes(statusIndex);
        },
        drop: (item, monitor) => {
            onDrop(item, monitor, status);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });
    return (
        <div ref={drop} className={"drop-wrapper"}>
            {React.cloneElement(children, { isOver })}
        </div>
    );
};
export default DropWrapper;
