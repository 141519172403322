import React, { useState, useRef, useEffect, useCallback } from 'react';

const ZoomPanCanvas = ({ children, footer }) => {
    const [scale, setScale] = useState(1);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [lastX, setLastX] = useState(0);
    const [lastY, setLastY] = useState(0);

    const canvasRef = useRef(null);

    const onMouseDown = useCallback((e) => {
        e.stopPropagation();
        setIsDragging(true);
        setLastX(e.clientX);
        setLastY(e.clientY);
    }, []);

    const onMouseMove = useCallback((e) => {
        if (isDragging) {
            const dx = e.clientX - lastX;
            const dy = e.clientY - lastY;

            setX((prevX) => prevX + dx);
            setY((prevY) => prevY + dy);
            setLastX(e.clientX);
            setLastY(e.clientY);
        }
    }, [isDragging, lastX, lastY]);

    const onMouseUp = useCallback((e) => {
        e.stopPropagation();
        setIsDragging(false);
    }, []);

    const onWheel = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.shiftKey) {
            const scaleChange = e.deltaY > 0 ? 0.9 : 1.1;
            const newScale = scale * scaleChange;

            const rect = canvasRef.current.getBoundingClientRect();
            const mouseX = e.clientX - rect.left;
            const mouseY = e.clientY - rect.top;

            const newX = mouseX * (1 - scaleChange) + x;
            const newY = mouseY * (1 - scaleChange) + y;

            setScale(Math.max(0.1, Math.min(5, newScale)));
            setX(newX);
            setY(newY);
        }
    }, [scale, x, y]);

    useEffect(() => {
        const canvas = canvasRef.current;

        canvas.addEventListener('mousedown', onMouseDown);
        canvas.addEventListener('mousemove', onMouseMove);
        canvas.addEventListener('mouseup', onMouseUp);
        canvas.addEventListener('mouseleave', onMouseUp);

        return () => {
            canvas.removeEventListener('mousedown', onMouseDown);
            canvas.removeEventListener('mousemove', onMouseMove);
            canvas.removeEventListener('mouseup', onMouseUp);
            canvas.removeEventListener('mouseleave', onMouseUp);
        };
    }, [onMouseDown, onMouseMove, onMouseUp]);

    const handleTouchStart = useCallback((e) => {
        if (e.touches.length === 1) {
            e.stopPropagation();
            setIsDragging(true);
            setLastX(e.touches[0].clientX);
            setLastY(e.touches[0].clientY);
        }
    }, []);

    const handleTouchMove = useCallback((e) => {
        if (isDragging && e.touches.length === 1) {
            e.stopPropagation();
            const dx = e.touches[0].clientX - lastX;
            const dy = e.touches[0].clientY - lastY;

            setX((prevX) => prevX + dx);
            setY((prevY) => prevY + dy);
            setLastX(e.touches[0].clientX);
            setLastY(e.touches[0].clientY);
        }
    }, [isDragging, lastX, lastY]);

    const handleTouchEnd = useCallback((e) => {
        e.stopPropagation();
        setIsDragging(false);
    }, []);

    const handleTouchCancel = useCallback((e) => {
        e.stopPropagation();
        setIsDragging(false);
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;

        canvas.addEventListener('touchstart', handleTouchStart);
        canvas.addEventListener('touchmove', handleTouchMove);
        canvas.addEventListener('touchend', handleTouchEnd);
        canvas.addEventListener('touchcancel', handleTouchCancel);

        return () => {
            canvas.removeEventListener('touchstart', handleTouchStart);
            canvas.removeEventListener('touchmove', handleTouchMove);
            canvas.removeEventListener('touchend', handleTouchEnd);
            canvas.removeEventListener('touchcancel', handleTouchCancel);
        };
    }, [handleTouchStart, handleTouchMove, handleTouchEnd, handleTouchCancel]);

    const containerStyle = {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        userSelect: 'none',
        cursor: isDragging ? 'grabbing' : 'grab',
    };

    const canvasStyle = {
        transform: `scale(${scale}) translate(${x}px, ${y}px)`,
        transformOrigin: 'top left',
        transition: 'transform 0.1s ease',
        width: '1000px',
        height: '1000px',
    };

    return (
        <div
            style={containerStyle}
            ref={canvasRef}
            onWheel={onWheel}
        >
            <div style={canvasStyle}>
                {children}
            </div>
            {footer}
        </div>
    );
};

export default ZoomPanCanvas;

