import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Spin } from 'antd';
import { Card, CardBody, Col } from 'reactstrap';
import { Helmet } from "react-helmet";
import "./elipsis.css";
import { IMG_HOST } from "../../helper/host";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import KASBON from "./kasbon";
import PEMBAYARAN from "./pembayaran";
import i18n from 'i18next';
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import {KasbonBayar} from "./list";

export class KasbonBayarDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,

        }
    }
    loadData() {
        let form = {
            karyawan_id: this.props.match.params.id
        }

        this.props.request("kasbon/detail_karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    var result = response.data
                    this.setState({
                        data: result,
                        loading: false
                    })
                }

            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0
        let currency = this.props.user.perusahaan.perusahaan_currency;
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{i18n.t('detailkasbonhead')}</title>
                    <meta name="description" content="Detail Kasbon" />
                </Helmet>

                <Breadcrumb title={i18n.t('detailkasbonbread')} parent={i18n.t('pengajuan')} parentLink='/submission/casbon' />
                <Col lg="12">
                    <Card className="main-card mb-3">
                        <CardBody>
                            {this.state.loading ?
                                <Spin size="large" tip="Mohon Tunggu..." style={{
                                    width: '100%',
                                    margin: 'auto',
                                    marginBottom: '10px',
                                    marginTop: '10px',
                                }} />
                                :
                                <div>
                                    <Card className="card-shadow-primary profile-responsive card-border">
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner bg-primary">
                                                <div className="menu-header-content btn-pane-right">
                                                    <div >
                                                        {this.state.data.karyawan_foto !== null ? (
                                                            <img
                                                                id="avatar"
                                                                src={IMG_HOST + 'mitra/frontapp/karyawan/' + this.state.data.karyawan_foto}
                                                                className="img-fluid blur-up lazyloaded" />) : (
                                                            <img
                                                                id="avatar"
                                                                src={IMG_HOST + 'user-profile.png'}
                                                                className="img-fluid blur-up lazyloaded" />
                                                        )}
                                                    </div>
                                                    <div>
                                                        <h5 className="header-title" style={{ color: "#ffffff" }}>{this.state.data.karyawan_nama}</h5>
                                                        <h6 style={{ color: "#ffffff", fontSize: 12 }}>{this.state.data.divisi.divisi_name}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    <div>
                                        <Tabs>
                                            <TabList className="nav nav-tabs tab-coupon">
                                                <Tab className="nav-link">{i18n.t('listkasbon')}</Tab>
                                                <Tab className="nav-link">{i18n.t('listpembayarankasbon')}</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <KASBON
                                                    request={this.props.request}
                                                    currency={this.props.user.perusahaan.perusahaan_currency}
                                                    match={this.props.match}
                                                    history={this.props.history} />
                                            </TabPanel>
                                            <TabPanel>
                                                <PEMBAYARAN
                                                    request={this.props.request}
                                                    currency={this.props.user.perusahaan.perusahaan_currency}
                                                    match={this.props.match}
                                                    history={this.props.history} />
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(KasbonBayarDetail);
