export const INIT_FILTER = 'FILTER/INIT';
export const SET_FILTER = 'FILTER/SET';
export const CLEAR_FILTER = 'FILTER/CLEAR';

export const init_filter = (all_data) => ({
    type: INIT_FILTER,
    all_data: all_data
});

export const set_filter = (all_data) => ({
    type: SET_FILTER,
    all_data: all_data
});

export const clear_filter = () => ({
    type: CLEAR_FILTER
});

const initialState = {
    all_data: [],
    _sift: [],
    _jabatan: [],
    _divisi: [],
    _lokasi: []
};

export default function reducer(state = { initialState
}, action) {
    switch (action.type) {
        case INIT_FILTER:
            return {
                ...state,
                all_data: action.all_data,
                _sift: action.all_data._sift,
                _jabatan: action.all_data._jabatan,
                _divisi: action.all_data._divisi,
                _lokasi: action.all_data._lokasi
            }

        case SET_FILTER:
            localStorage.setItem('Presensi-Company-Data', JSON.stringify(action.all_data))
            return {
                ...state,
                all_data: action.all_data,
                _sift: action.all_data._sift,
                _jabatan: action.all_data._jabatan,
                _divisi: action.all_data._divisi,
                _lokasi: action.all_data._lokasi
            };

        case CLEAR_FILTER:
            localStorage.removeItem('Presensi-Company-Data')

            return {
                ...state,
            };
    }

    return state;
}
