import React, {Component} from 'react'
import Swal from "sweetalert2";
import {Select, Modal} from "antd";
import {APIKEY} from "../../helper/host";
import MapPicker from "react-google-map-picker";
import "./kunjungan.css"
import i18n from "i18next";
import {Modal as ModalRs, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

export class Add_outlet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan    : false,
            isLoading       : true,
            nama_outlet     : '',
            email_outlet    : '',
            no_hp_outlet    : '',
            alamat          : '',
            nama_pemilik    : '',
            radius          : '',
            location        : {
                lat         : -7.865242,
                lng         : 110.156070
            },
            karyawan        : [],
            lokasi_karyawan : []
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    lokasiKaryawanHandler = () => {
        if (this.state.lokasi_karyawan === 'all') {
            this.setState({
                lokasi_karyawan: []
            })
        } else {
            this.setState({
                lokasi_karyawan: 'all'
            })
        }
    }

    simpanOutlet = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            nama            : this.state.nama_outlet,
            email           : this.state.email_outlet,
            no              : this.state.no_hp_outlet,
            latitude        : this.state.location.lat,
            longtitude      : this.state.location.lng,
            alamat          : this.state.alamat,
            nama_pemilik    : this.state.nama_pemilik,
            radius          : this.state.radius,
            karyawan        : this.state.lokasi_karyawan,
        }

        this.props.request("outlet/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.changeState({
                        prosesSimpan    : false,
                        nama_outlet     : '',
                        email_outlet    : '',
                        no_hp_outlet    : '',
                        alamat          : '',
                        nama_pemilik    : '',
                        radius          : '',
                        location        : {
                            lat         : -7.865242,
                            lng         : 110.156070
                        },
                        karyawan        : [],
                        lokasi_karyawan : [],
                        add_visible     : false,
                        data            : [],
                        isLoading       : true,
                    }, () => {
                        this.props.loadData()
                    })
                    Swal.fire({
                        title               : i18n.t("berhasil"),
                        text                : i18n.t(response.message),
                        icon                : 'success',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                }else{
                    Swal.fire({
                        title               : i18n.t("gagal"),
                        text                : i18n.t(response.message),
                        icon                : 'warning',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    loadKaryawan(){
        let form = {}
        this.props.request("karyawan/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        karyawan    : response.data,
                        loading     : false
                    })
                }
            });
    }

    componentDidMount() {
        this.loadKaryawan()
    }

    render() {
        const { Option } = Select;
        return (
            <ModalRs size="lg" zIndex={999} isOpen={this.props.add_visible} toggle={() => this.props.changeState({ add_visible: false })} style={{ maxWidth: "1000px" }}>
                <ModalHeader toggle={() => this.props.changeState({ add_visible: false })}>
                    <div className='text-dark' style={{ fontWeight: 'bold', fontSize: "18px" }}>
                        {i18n.t("tambahlokasikunjungan")}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="card-body">
                        <form className="needs-validation user-add" noValidate="">
                            <div>
                                <div className="form-group">
                                    <label style={{fontSize: "12px", color: "#143B5E"}} className="mb-2 font-weight-bold">
                                        {i18n.t("namalokasikunjungan")}
                                    </label>
                                    <div>
                                        <input type="text" name="nama_outlet" className="form-control"
                                               placeholder={i18n.t("namalokasikunjungan") + ("...")}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label style={{fontSize: "12px", color: "#143B5E"}} className="mb-2 font-weight-bold">
                                        {i18n.t("namapemilik")}
                                    </label>
                                    <div>
                                        <input type="text" name="nama_pemilik"
                                               className="form-control"
                                               placeholder={i18n.t("namapemilik") + ("...")}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label style={{fontSize: "12px", color: "#143B5E"}} className="mb-2 font-weight-bold">
                                        {i18n.t("emaillokasikunjungan")}
                                    </label>
                                    <div>
                                        <input type="email" name="email_outlet"
                                               className="form-control"
                                               placeholder={i18n.t("emaillokasikunjungan") + ("...")}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label style={{fontSize: "12px", color: "#143B5E"}} className="mb-2 font-weight-bold">
                                        {i18n.t("nolokasikunjungan")}
                                    </label>
                                    <div>
                                        <input type="number" name="no_hp_outlet"
                                               className="form-control"
                                               placeholder={i18n.t("nolokasikunjungan") + ("...")}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label style={{fontSize: "12px", color: "#143B5E"}} className="mb-2 font-weight-bold">
                                        {i18n.t("alamat")}
                                    </label>
                                    <div>
                                        <textarea name="alamat" className="form-control"
                                                  placeholder={i18n.t("alamat") + ("...")}
                                                  onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <div className="form-group">
                                            <label className="mb-2 font-weight-bold" style={{fontSize: "12px", color: "#143B5E"}}>
                                                {i18n.t("radiuslokasikunjungan")}
                                            </label>
                                            <div className="input-group flex-nowrap w-100">
                                                <input className="form-control" type="text" onChange={this.handleChange} name="radius" placeholder="100"/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">{i18n.t("meter")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-group">
                                            <label className="mb-2 font-weight-bold" style={{fontSize: "12px", color: "#143B5E"}}>
                                                Latitude
                                            </label>
                                            <div className="input-group w-100">
                                                <input name="latitude" className="form-control"
                                                    placeholder="Latitude..." type="text" required
                                                    value={this.state.location.lat}
                                                    onChange={(event) =>
                                                        this.setState({
                                                            location: {
                                                                lat: event.target.value,
                                                                lng: this.state.location.lng,
                                                            },
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-group">
                                            <label className="mb-2 font-weight-bold" style={{fontSize: "12px", color: "#143B5E"}}>
                                                Longitude
                                            </label>
                                            <div className="input-group w-100">
                                                <input name="longitude" className="form-control"
                                                    placeholder="Longitude..." type="text" required
                                                    value={this.state.location.lng}
                                                    onChange={(event) =>
                                                        this.setState({
                                                            location: {
                                                                lat: this.state.location.lat,
                                                                lng: event.target.value,
                                                            },
                                                        })
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-group">
                                            <button className="btn ipresens-btn-color btn-pin-otl w-100"
                                                type="button" style={{color: "#fff", marginTop: "28px", fontSize: "12px"}}
                                                onClick={() => {this.setState({modalLokasi: true});}}
                                            >
                                                {i18n.t("pinlokasikunjungan")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <Modal
                                    title="Pin Lokasi Absen"
                                    visible={this.state.modalLokasi}
                                    onOk={() => {
                                        this.setState({modalLokasi: false})
                                    }}
                                    onCancel={() => {
                                        this.setState({modalLokasi: false})
                                    }}
                                >
                                    <MapPicker
                                        style={{position: 'relative', width: '100%', height: '250px'}}
                                        name='location'
                                        defaultLocation={this.state.location}
                                        zoom={10}
                                        onChangeLocation={(lat, lng) => {
                                            this.setState({
                                                location: {
                                                    lat: lat,
                                                    lng: lng
                                                }
                                            })
                                        }}
                                        apiKey={APIKEY}/>
                                </Modal>
                                <div className="form-group">
                                    <label style={{fontSize: "12px", color: "#143B5E"}} className="mb-2 font-weight-bold">
                                        {i18n.t("karyawan")}
                                    </label>
                                    {this.state.lokasi_karyawan !== 'all' ?
                                        <Select name="select_karyawan"
                                                className={"col-md-12"}
                                                mode={"multiple"}
                                                placeholder={i18n.t("pilihkaryawan")}
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(event) => {
                                                    this.setState({
                                                        lokasi_karyawan: event
                                                    })
                                                }}>
                                            {this.state.karyawan.map((item, index) => {
                                                return (<Option
                                                    key={item.karyawan_id}>{item.karyawan_nama}</Option>)
                                            })}
                                        </Select> : null
                                    }
                                    <label style={{fontSize: "12px"}}>
                                        <input style={{fontSize: "16px", borderRadius: "8px", marginTop: "10px"}}
                                               type="checkbox"
                                               checked={this.state.lokasi_karyawan === 'all'}
                                               onChange={() => this.lokasiKaryawanHandler()}/>
                                        &nbsp;{i18n.t("semuakaryawan")}
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="pull-right">
                        {this.state.prosesSimpan === false ?
                            <button type="button" className="btn ipresens-btn-color"
                                    onClick={this.simpanOutlet}>{i18n.t("simpan")}</button>
                            :
                            <button type="button" className="btn ipresens-btn-color"><i
                                className="fa fa-refresh fa-spin"></i> {i18n.t("loading")}</button>
                        }
                    </div>
                </ModalFooter>
            </ModalRs>
        )
    }
}

export default Add_outlet
