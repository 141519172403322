import React, {Component, Fragment} from 'react';
import queryString from 'query-string';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Helmet} from "react-helmet";
import {Result, Spin} from 'antd';
import logo from "../../assets/images/dashboard/icon_ipresens.png"
import i18n from "../../i18n";

export class VerifyEmailHapusKaryawan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            token: '',
            loading: true,
            success: false,
            invalidEmail: false,
            message: '',
            tick: 5
        }
    }

    confirmDelete = () => {
        this.setState({
            loading: true,
            confirmDelete: true
        })
        const form = {
            token: this.state.token
        }

        this.props.request("confirm-delete-karyawan", form)
            .then(response => {
                if (response.success) {
                    this.setState({
                        loading: false,
                        success: true,
                        message: response.message
                    })
                } else {
                    this.setState({
                        loading: false,
                        success: false,
                        message: response.message
                    })
                }
            });
    }

    componentDidMount() {
        const queries = queryString.parse(this.props.location.search);


        if (!queries.token) {
            this.setState({
                loading: false,
                success: false,
                message: 'Link verifikasi tidak valid!'
            });
        }else{
            this.setState({
                token: queries.token
            })
        }
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Verifikasi Hapus Karyawan - Presensi</title>
                    <meta name="description" content="Verifikasi Email Aplikasi Presensi" />
                </Helmet>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 card-right">
                                    <div className="card tab2-card">
                                        <div className="card-body">
                                            <div style={{ textAlign: 'center' }} className="card-header">
                                                <div className="container">
                                                    <div className="logo-wrapper mb-3">
                                                        <img className="blur-up lazyloaded" src={logo} alt="" />
                                                    </div>
                                                    <div>
                                                        <h4>{i18n.t('verifikasihapuskaryawan')}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!this.state.confirmDelete ?
                                            <>
                                                <p style={{ color: '#000000' }}>{i18n.t('pesanhapuskaryawan')}</p>
                                                <a className="btn ipresens-btn-color w-50 mx-auto mb-5" type="submit" style={{ backgroundColor: 'gray !important', color: "white" }}
                                                   onClick={() => {
                                                       this.confirmDelete()
                                                   }}
                                                >{i18n.t('hapusdatakaryawan')}</a>
                                            </>
                                            :
                                            <div className="card-body">
                                                {this.state.loading ?
                                                    <div>
                                                        <div className="dmt-4 d-flex-column">
                                                            <p style={{ color: '#000000' }}>{i18n.t('menghapusdatakaryawan')}</p>
                                                            <Spin size="large" tip={i18n.t('menghapusdata')} style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                                marginBottom: '10px',
                                                                marginTop: '10px'
                                                            }}/>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <Result
                                                            status={this.state.success ? "info" : "error"}
                                                            title={this.state.success ? i18n.t('berhasil') : i18n.t('gagal')}
                                                            subTitle={i18n.t(this.state.message)}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default VerifyEmailHapusKaryawan;
