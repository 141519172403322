import moment from 'moment'

moment.updateLocale('id', {
    months: [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
        'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ],
    monthsShort: [
        'Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'
    ],
    weekdays: [
        'Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'
    ],
    weekdaysShort: [
        'Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'
    ]
})

export const dateFormat = (date, format) => {
    var result = moment(date).format(format)

    return result
}

export const countDiffHourFromNow = (date) => {
    var date = moment(date)

    var duration = Math.round(moment.duration(moment(new Date()).diff(moment(date))).asHours())

    return duration
}

export const addMonthsToDate = (date, monthsToAdd) => {
    var newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + monthsToAdd);
    return newDate;
}

export const formatDateToYMD = (date) => {
    var year = date.getFullYear();
    var month = String(date.getMonth() + 1).padStart(2, '0');
    var day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
