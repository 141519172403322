import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import {Helmet} from "react-helmet";
import i18n from 'i18next';

export class Add_agenda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan    : false,
            isLoading       : true,
            agenda_title    : '',
            agenda_note     : '',
            agenda_type     : '',
            agenda_date     : '',
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    simpanAgenda = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            title          : this.state.agenda_title,
            note           : this.state.agenda_note,
            date           : this.state.agenda_date,
            type           : this.state.agenda_type,
        }

        this.props.request("agenda/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title               : i18n.t('berhasil'),
                        text                : i18n.t('databerhasilditambahkan'),
                        icon                : 'success',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                    this.props.history.push('/agenda/list-agenda')
                }else{
                    Swal.fire({
                        title               : i18n.t('gagal'),
                        text                : i18n.t('datagagalditambahkan'),
                        icon                : 'warning',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Tambah Agenda Perusahaan - Presensi</title>
                    <meta name="description" content="Tambah Agenda Perusahaan" />
                </Helmet>
                <Breadcrumb title={i18n.t("tambahagenda")} parent="Dashboard" parentLink="/dashboard"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <form className="needs-validation user-add" noValidate="">
                                        <div>
                                            <div className="form-group">
                                                <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">{i18n.t("judulagenda")}</label>
                                                <div>
                                                    <input type="text" name="agenda_title" className="form-control col-xl-8 col-md-7" placeholder={i18n.t("judulagenda")} onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">{i18n.t("catatanagenda")}</label>
                                                <div>
                                                    <input type="text" name="agenda_note" className="form-control col-xl-8 col-md-7" placeholder={i18n.t("catatanagenda")} onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("tanggalagenda")}</label>
                                                <input type="date" name="agenda_date"
                                                       className="form-control col-xl-8 col-md-7"
                                                       defaultValue={this.state.agenda_date}
                                                       onChange={this.handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("tipeagenda")}</label>
                                                <div>
                                                    <select id="agenda_type" name="agenda_type"
                                                            className="form-control col-xl-8 col-md-7"
                                                            value={this.state.agenda_type}
                                                            onChange={this.handleChange}>
                                                        <option value="">{i18n.t("pilihtipe")}</option>
                                                        <option value="liburnasional">{i18n.t("liburnasional")}</option>
                                                        <option value="liburperusahaan">{i18n.t("liburperusahaan")}</option>
                                                        <option value="acara">{i18n.t("acara")}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="pull-right">
                                        {this.state.prosesSimpan === false ?
                                            <button type="button" className="btn btn-primary"
                                                    onClick={this.simpanAgenda}>{i18n.t("simpan")}</button>
                                            :
                                            <button type="button" className="btn btn-primary"><i
                                                className="fa fa-refresh fa-spin"></i> Loading</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Add_agenda
