import React, { Component, Fragment } from 'react';
import { Empty, Spin, Tooltip } from 'antd';
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Modal as ModalRs} from "reactstrap";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { pageNumber } from "../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux"
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import SaveIcon from "../../assets/icons/save.svg";
import i18n from 'i18next';
import {BACKEND_API_HOST, DEFAULT_IMG} from "../../helper/host";

//css ipresens
import "../iPresens-style.css";
import "./style.css";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import moment from "moment";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
import MoreIcon from "../../assets/icons/more.svg";

export class ListGuru extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading      : true,
            prosesSimpan : false,
            guru         : [],
            guruData     : [],
            dataHistory  : [],
            pagination   : 2,
            perPage      : 0,
            currentPage  : 0,
            currentPageHistory: 0,
            modalDetail  : false,
            idDetail     : "",
            idDel        : "",
            namaDetail   : "",
            modalAdd     : false,
            modalEdit    : false,
            modalimport  : false,
            importFile   : null,
            guru_nama    : '',
            guru_alamat  : '',
            guru_no_telp : '',
            loading_imp  : false,
            checked_all  : false,
            checked      : [],
            totalData    : '',
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.importGuru = this.importGuru.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, guruData: [] }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter(event) {
        this.setState({
            filter_judul: '',
            guruData: [],
            loading: true
        }, () => this.loadData())
    }

    handleCancel = e => {
        this.setState({
            modalimport: false
        });
    };

    simpanGuru = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            nama    : this.state.guru_nama,
            alamat  : this.state.guru_alamat,
            no_telp : this.state.guru_no_telp
        }

        this.props.request("guru/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasilditambahkan'),
                        icon: 'success'
                    })
                    this.setState({
                        prosesSimpan: false,
                        modalAdd: false,
                        guru: [],
                        guru_nama: '',
                        guru_alamat: '',
                        guru_no_telp: '',
                    }, () => this.loadData())
                }else{
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.failed),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.setState({
                        prosesSimpan: false
                    })
                }
            });
    }

    simpanUbahGuru = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            guru_id  : this.state.idDetail,
            nama     : this.state.guru_nama,
            alamat   : this.state.guru_alamat,
            no_telp  : this.state.guru_no_telp
        }

        this.props.request("guru/edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title               : i18n.t('berhasil'),
                        text                : i18n.t('berhasilmengubahdata'),
                        icon                : 'success',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                    this.props.history.push('/teacher/teacher-list')
                }else{
                    Swal.fire({
                        title               : i18n.t('gagal'),
                        text                : i18n.t('gagalmengubahdata'),
                        icon                : 'warning',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                }
                this.setState({
                    prosesSimpan: false,
                    modalEdit: false,
                }, () => this.loadData())
            });
    }

    wantDelete() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmenghapusguru'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        data: [],
                        loading: true
                    })
                    this.props.request("guru/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('databerhasildihapus'),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: i18n.t('datagagaldihapus'),
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            filter_judul: this.state.filter_judul,
            paginate    : 10,
            page        : this.state.currentPage + 1,
        }
        this.props.request("guru/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        guruData: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    loadDetail() {
        let form = {
            id : this.state.idDetail
        }
        this.props.request("guru/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        guruDetail: response.data,
                        guru_nama: response.data.guru_nama,
                        guru_alamat: response.data.guru_alamat,
                        guru_no_telp: response.data.guru_no_telp,
                        loading: false,
                        modalDetail: true,
                    })
                }
            });
    }

    loadEditDetail() {
        let form = {
            id : this.state.idDetail
        }
        this.props.request("guru/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        guru: response.data,
                        guru_nama: response.data.guru_nama,
                        guru_alamat: response.data.guru_alamat,
                        guru_no_telp: response.data.guru_no_telp,
                        modalEdit: true
                    })
                }
            });
    }

    exportTemplate = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'guru/export-template?token=' + AuthKey, "_blank")
    }

    exportExcelGuru = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'guru/export?token=' + AuthKey, "_blank")
    }

    importGuru() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'guru/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        },()=>{
            let form = {
                id: id ? id : this.state.guru_id,
                paging : this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("guru/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                    else {
                        this.setState({
                            modalHistory: false,
                            guru_id: ''
                        });
                    }
                });
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0
        moment.locale(i18n.language);
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Guru") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Guru - Presensi</title>
                        <meta name="description" content="Daftar Guru" />
                    </Helmet>

                    <Modal size='lg' zIndex={999} isOpen={this.state.modalDetail} toggle={() => this.setState({
                        modalDetail: false
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalDetail: false
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("detailguru")}</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row mb-3'>
                                <div className='col-md-12' style={{
                                    fontSize: "20px",
                                    fontWeight: 700,
                                    lineHeight: "32px",
                                    color: "#143B5E"
                                }}>{this.state.namaDetail}</div>
                            </div>
                            <div className='row'>
                                <div className='card-body px-3 py-0'>
                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                        <div className='col-md-2 font-detail-label pr-0'>{i18n.t("alamat")}</div>
                                        <div className='col-md-1 px-0' style={{maxWidth: "20px"}}>:</div>
                                        <div className='col-md-8 px-0 font-detail-value'>
                                            {this.state.guruDetail ? this.state.guruDetail.guru_alamat : "-"}
                                        </div>
                                    </div>
                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                        <div className='col-md-2 font-detail-label pr-0'>{i18n.t("notelepon")}</div>
                                        <div className='col-md-1 px-0' style={{maxWidth: "20px"}}>:</div>
                                        <div className='col-md-8 px-0 font-detail-value'>
                                            {this.state.guruDetail ? this.state.guruDetail.guru_no_telp : "-"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal size='lg' zIndex={999} isOpen={this.state.modalAdd} toggle={() => this.setState({
                        modalAdd: false,
                        guru_nama: '',
                        guru_alamat: '',
                        guru_no_telp: '',
                    })} style={{maxWidth: "1000px"}}>
                        <ModalHeader toggle={() => this.setState({
                            modalAdd: false,
                            guru_nama: '',
                            guru_alamat: '',
                            guru_no_telp: '',
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("tambahguru")}</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <form className="needs-validation user-add" noValidate="">
                                        <div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("nama")}</label>
                                                <div>
                                                    <input type="text"
                                                           name="guru_nama"
                                                           required=""
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder={i18n.t("nama")}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("alamat")}</label>
                                                <div>
                                                    <input type="text"
                                                           name="guru_alamat"
                                                           required=""
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder={i18n.t("alamat")}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("notelepon")}</label>
                                                <div>
                                                    <input type="number"
                                                           name="guru_no_telp"
                                                           required=""
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder={i18n.t("notelepon")}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            <button onClick={() => this.setState({
                                modalAdd: false,
                                guru_nama: '',
                                guru_alamat: '',
                                guru_no_telp: ''
                            })} className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' disabled={this.state.prosesSimpan}
                                    onClick={this.simpanGuru}>
                                <img alt='' src={SaveIcon}/>
                                {this.state.prosesSimpan ? "Menyimpan.." : i18n.t("simpan")}
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal size='lg' zIndex={999} isOpen={this.state.modalEdit} toggle={() => this.setState({
                        modalEdit: false,
                    })} style={{maxWidth: "1000px"}}>
                        <ModalHeader toggle={() => this.setState({
                            modalEdit: false,
                        })} className='pengumuman-modal-header'>
                            <div className='pengumuman-modal-header-font'>{i18n.t("editguru")}</div>
                        </ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <form className="needs-validation user-add" noValidate="">
                                        <div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("nama")}</label>
                                                <div>
                                                    <input type="text" name="guru_nama"
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder={i18n.t("nama")}
                                                           defaultValue={this.state.guru_nama}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("alamat")}</label>
                                                <div>
                                                    <input type="text" name="guru_alamat"
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder={i18n.t("alamat")}
                                                           defaultValue={this.state.guru_alamat}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("notelepon")}</label>
                                                <div>
                                                    <input type="number" name="guru_no_telp"
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder={i18n.t("notelepon")}
                                                           defaultValue={this.state.guru_no_telp}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            <button onClick={() => this.setState({
                                modalEdit: false,
                            })} className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' onClick={this.simpanUbahGuru}><img alt='' src={SaveIcon}/> {i18n.t("simpan")}
                            </button>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.modalimport}>
                        <ModalHeader onClick={this.handleCancel} toggle={() => this.setState({importFile: null})}>
                            <div>{i18n.t('importguru')}</div>
                        </ModalHeader>
                        <ModalBody style={{padding: "20px"}}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t('importtemplate')}</a><br/>
                                    <a onClick={this.exportTemplate} style={{color: "#057CE4"}}>{i18n.t('unduhtemplate')}
                                    </a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({
                                                       importFile: event.target.files[0],
                                                   })
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i> {i18n.t('prosesupload')}</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importGuru}>{i18n.t('importdata')}</button>
                            ]}
                        </ModalFooter>
                    </Modal>

                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false })}>
                            <div className='modal-header-reactstrap'>{i18n.t('riwayatperubahan')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>{i18n.t('user')}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left"style={{ marginRight: '20px' }}>
                                                    <h4>{i18n.t('aktivitas')}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return (
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{ width: '48px' , marginBottom:'25px', height:'48px'}}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                                    <span className="col-green font-weight-bold">
                                                                                      {moment(item.history_create_date).format('lll')}
                                                                                    </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === 'created' ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        {i18n.t('dibuat')}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        {i18n.t('diubah')}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.history_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.guru_nama != item.guru_nama && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('nama')}: {i18n.t('dari')}{' '}
                                                                                        <b>
                                                                                            {item.parent_history
                                                                                                ? item.parent_history.guru_nama
                                                                                                : 'N/A'}{' '}
                                                                                        </b>
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.guru_nama ? item.guru_nama : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.guru_alamat != item.guru_alamat && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('alamat')}: {i18n.t('dari')}{' '}
                                                                                        <b>
                                                                                            {item.parent_history
                                                                                                ? item.parent_history.guru_alamat
                                                                                                : 'N/A'}{' '}
                                                                                        </b>
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.guru_alamat ? item.guru_alamat : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.guru_no_telp != item.guru_no_telp && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('notelepon')}: {i18n.t('dari')}{' '}
                                                                                        <b>
                                                                                            {item.parent_history
                                                                                                ? item.parent_history.guru_no_telp
                                                                                                : 'N/A'}{' '}
                                                                                        </b>
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.guru_no_telp ? item.guru_no_telp : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} data</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>

                        </ModalFooter>
                    </ModalRs>

                    <div className="container-fluid pt-4">
                        <div className='row'>
                            <div className='col-md-12' style={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#5D6F80"
                            }}>iPresensi Admin Panel
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-md-12' style={{
                                fontWeight: 700,
                                fontSize: "24px",
                                lineHeight: "36px",
                                color: "#143B5E"
                            }}>{i18n.t("daftarguru")}
                            </div>
                        </div>
                        <div className="card" style={{ border: "1px solid #DDDDDD" }}>
                            <div className="card-header border-0 p-4">
                                <div className='row'>
                                    <div className='col-md-6 pengumuman-col-filter'>
                                        <div className='flex-row flex-nowrap'>
                                            <div className='input-group btn-srch-pengumuman mr-1'>
                                                <span className='input-group-text pengumuman-filter-nama-input-text'>
                                                    <img alt='' src={SearchIcon}/>
                                                </span>
                                                <input className='form-control mr-2 pengumuman-filter-nama-input'
                                                       style={{
                                                           borderTopRightRadius: "8px",
                                                           borderBottomRightRadius: "8px"
                                                       }}
                                                       type='text'
                                                       placeholder={i18n.t("carinamaguru")} onKeyUp={(event) => {
                                                    if (event.which === 13) {
                                                        event.preventDefault()
                                                        this.setState({
                                                            page: 0,
                                                            paging: this.state.paging,
                                                            loading: true,
                                                            guruData: []
                                                        }, () => {
                                                            this.loadData()
                                                        });
                                                    }
                                                }} onChange={this.handleChange} name="filter_judul"
                                                       value={this.state.filter_judul}/>
                                                <button className='btn ipresens-btn-reset-filter'
                                                        onClick={this.resetFilter}>
                                                    <img alt='' src={RefreshIcon}/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 row m-0 d-flex justify-content-end'>
                                        {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                            <div className="px-2">
                                                <button className='btn ipresens-btn-color px-3 text-capitalize pull-right width-filter'
                                                onClick={() => this.setState({
                                                            modalAdd: true
                                                        })}><img alt='' src={PlusIcon}/> {i18n.t("tambahguru")}
                                                </button>
                                            </div>
                                        )}
                                        <div className='col-auto px-0' align="center">
                                            <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'} title={
                                                <div className={'p-2'}>
                                                    <a onClick={() => {
                                                        this.setState({modalimport: true})
                                                    }} style={{marginRight: "50px"}} className={'text-dark'}
                                                    >Import Excel</a><br/>
                                                    <a onClick={this.exportExcelGuru}
                                                       style={{marginRight: "50px"}}
                                                       className={'text-dark'}
                                                    >Export Excel</a>
                                                </div>
                                            }>
                                                <a><i
                                                    style={{
                                                        width: "15",
                                                        fontSize: 18,
                                                        color: 'rgb(51 51 51)',
                                                        alignItems: "center",
                                                        height: "41px",
                                                        paddingRight: "30px"
                                                    }}
                                                    className='btn border border-dark border-1 px-2'
                                                ><img src={MoreIcon}/></i></a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body daftar-pengumuman pt-3">
                                {!this.state.loading && this.state.guruData.length === 0 ?
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    (this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("R") >= 0) && (
                                        <Table className="table table-striped table-borderless mb-5">
                                            <Thead>
                                                <Tr>
                                                    <Th className="guru-th-table">#</Th>
                                                    <Th className="guru-th-table">{i18n.t("nama")}</Th>
                                                    <Th className="guru-th-table">{i18n.t("alamat")}</Th>
                                                    <Th className="guru-th-table">{i18n.t("notelepon")}</Th>
                                                    <Th className="guru-th-table text-center">{i18n.t("aksi")}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {this.state.loading &&
                                                    <Tr>
                                                        <Td colSpan={8}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                                marginBottom: '10px',
                                                                marginTop: '10px'
                                                            }}/>
                                                        </Td>
                                                    </Tr>
                                                }
                                                {this.state.guruData.map((item) => {
                                                    no += 1
                                                    return (
                                                        <Tr>
                                                            <Td className="guru-td-table">
                                                                {pageNumber(no, this.state.perPage, this.state.currentPage)}
                                                            </Td>
                                                            <Td className="guru-td-table">{item.guru_nama}</Td>
                                                            <Td className="guru-td-table">{item.guru_alamat}</Td>
                                                            <Td className="guru-td-table">{item.guru_no_telp}</Td>
                                                            <Td className="guru-td-table" align="center">
                                                                <Tooltip trigger={'click'} placement="bottom" color={'#fff'}
                                                                         zIndex={900}
                                                                         title={
                                                                             <div className={'p-2'}>
                                                                                 <a onClick={() => this.setState({
                                                                                     namaDetail: item.guru_nama,
                                                                                     idDetail: item.guru_id
                                                                                 }, () => this.loadDetail())}
                                                                                    className={'text-dark'}>{i18n.t("detail")}</a><br/>
                                                                                 {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                                     <>
                                                                                         <a onClick={() => this.setState({
                                                                                             idDetail: item.guru_id
                                                                                         }, () => this.loadEditDetail())}
                                                                                            className={'text-dark'}>Edit</a><br/>
                                                                                     </>
                                                                                 )}
                                                                                 <a onClick={() => this.setState({
                                                                                     modalHistory: true,
                                                                                     guru_id: item.guru_id
                                                                                 }, () => this.loadHistory(item.guru_id))}
                                                                                    className={'text-dark'}>{i18n.t("riwayat")}</a><br/>
                                                                                 {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                                     <>
                                                                                         <a onClick={() => {
                                                                                             this.setState({
                                                                                                 idDel: item.guru_id
                                                                                             })
                                                                                             this.wantDelete()
                                                                                         }}
                                                                                            className={'text-danger'}>{i18n.t("hapus")}</a>
                                                                                     </>
                                                                                 )}
                                                                             </div>
                                                                         }>
                                                                    <a><i
                                                                        className="fa fa-ellipsis-v" style={{
                                                                        width: 35,
                                                                        fontSize: 18,
                                                                        padding: 11,
                                                                        color: 'rgb(51 51 51)'
                                                                    }}
                                                                    ></i></a>
                                                                </Tooltip>

                                                            </Td>
                                                        </Tr>
                                                    )
                                                })}
                                            </Tbody>
                                        </Table>

                                    )
                                }
                            </div>
                            <div className='card-footer p-0 border-0'>
                                <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                    {this.state.guruData.length !== 0 &&
                                        <>
                                            <div className='col font-14' style={{
                                                padding: "auto",
                                                margin: "auto",
                                                fontWeight: 500,
                                                lineHeight: "24px"
                                            }}>{i18n.t("menampilkan")} {this.state.guruData.length === 0 || this.state.guruData.length === null ? "0" : this.state.guruData.length} {i18n.t("dari")} {this.state.totalData} data
                                            </div>
                                            <div className='col'>
                                                <ReactPaginate
                                                    previousLabel={<img src={ArrowLeftIcon} alt=''/>}
                                                    nextLabel={<img src={ArrowRightIcon} alt=''/>}
                                                    pageCount={this.state.pageCount}
                                                    forcePage={this.state.currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={this.handlePageClick}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link custom-page-link'}
                                                    containerClassName={'pagination my-2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link custom-page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link lembur-prev-next-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link lembur-prev-next-link'}
                                                    activeClassName={'active lembur-active'}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListGuru);