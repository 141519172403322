import React, { Component, Fragment } from 'react';
import { Empty, Spin, Tooltip } from 'antd';
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Modal as ModalRs} from "reactstrap";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { Helmet } from "react-helmet";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { pageNumber } from "../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import SaveIcon from "../../assets/icons/save.svg";
import "../../components/pengumuman/style.css"
import EmptyDataImage from "../../assets/images/data-kosong.png";
import i18n from "i18next";
import Select from "react-select";
import {BACKEND_API_HOST, DEFAULT_IMG, IMG_HOST} from "../../helper/host";
import moment from "moment";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ReactPaginate from 'react-paginate';
import MoreIcon from "../../assets/icons/more.svg";

export class ListInventaris extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingHistoryPeminjaman: false,
            loadingHistory: false,
            prosesSimpan: false,
            prosesPinjam: false,
            prosesReturn: false,
            importLoading: false,
            filter_nama: "",
            data: [],
            data_karyawan: [],
            dataDetail: [],
            inventaris_karyawan: [],
            inventaris: [],
            historyPeminjaman: [],
            history: [],

            nama: "",
            deskripsi: '',
            namaEdit: '',
            deskripsiEdit: '',
            namaDetail: '',
            deskripsiDetail: '',
            nama_karyawan: '',
            tanggal_peminjaman: '',
            nama_inventaris: '',
            inventaris_name: '',
            tanggalKembali: '',

            modalAdd: false,
            modalEdit: false,
            modalDetail: false,
            modalPeminjaman: false,
            modalReturn: false,
            modalHistoryPeminjaman: false,
            modalHistory: false,
            modalImport: false,
            inventaris_loading: false,
            karyawan_loading: false,

            inventaris_selected: null,
            karyawan_selected: null,
            importFile: null,

            inventaris_id: 0,
            idHistory: 0,
            idHistoryInv: 0,
            idPinjam: 0,
            idKembali: 0,
            karyawan_id: 0,
            idDel: '',
            idDetail: 0,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            guide: false,
            pagination: 2,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.simpanPeminjaman = this.simpanPeminjaman.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.handlePageHistoryInv = this.handlePageHistoryInv.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    wantDelete() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmenghapusinventaris'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        data: [],
                        loading: true
                    })
                    this.props.request("inventaris/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            karyawan_id: this.props.match.params.id,
            karyawan: this.props.id,
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("inventaris/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }


    loadDetail() {
        let form = {
            id: this.state.idDetail
        }
        this.props.request("inventaris/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataDetail: response.data,
                        namaEdit: response.data.inventaris_name,
                        deskripsiEdit: response.data.inventaris_desc,
                        modalEdit: true
                    })
                }
            });
    }

    loadDataKaryawan() {
        let form = {
            karyawan_id: this.props.match.params.id,
            karyawan: this.props.id,
        }
        this.props.request("inventaris/get_karyawan_inventaris", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_karyawan: response.data,
                        loading: false
                    })
                }
            })
    }

    simpanInventaris = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            nama: this.state.nama,
            deskripsi: this.state.deskripsi,
        }
        this.props.request("inventaris/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                    modalAdd: false,
                    nama: '',
                    deskripsi: '',
                }, () => this.loadData())
            });
    }

    editInventaris = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id: this.state.idDetail,
            nama: this.state.namaEdit,
            deskripsi: this.state.deskripsiEdit,
        }
        this.props.request("inventaris/edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                    modalEdit: false,
                    nama: '',
                    deskripsi: '',
                }, () => this.loadData())
            });
    }

    simpanPeminjaman = () => {
        this.setState({
            prosesPinjam: true,
        })

        let form = {
            tanggal_peminjaman: this.state.tanggal_peminjaman,
            karyawan_id: this.state.karyawan_id,
            inventaris_id: this.state.idPinjam,
        }
        this.props.request("inventaris/add_inv_karyawan_v2", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesPinjam: false,
                    modalPeminjaman: false,
                    nama_karyawan: '',
                    nama_inventaris: '', tanggal_peminjaman: '',
                    inventaris_id: '', karyawan_id: '',
                    inventaris_selected: null,
                    karyawan_selected: null,
                }, () => this.loadData())
            });
    }

    selesaiPeminjaman = () => {
        this.setState({
            prosesReturn: true,
            modalReturn: true
        })
        let form = {
            id_inventaris: this.state.idKembali,
            tanggal: this.state.tanggalKembali,
        }
        this.props.request('inventaris/return-inventaris-v2', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.setState({
                    modalReturn: false,
                    prosesReturn: false, tanggalKembali: '', idKembali: 0,
                }, () => this.loadData())
            })
    }

    loadHistoryPeminjaman(id) {
        this.setState({
            historyPeminjaman: [], loadingHistoryPeminjaman: true
        }, () => {
            let form = {
                id: this.state.idHistory,
                paging : this.state.pagination,
                page: this.state.currentPageHistory + 1,
            }
            this.props.request("inventaris/history_peminjaman", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistoryPeminjaman: false
                    })
                    if (response.success) {
                        this.setState({
                            historyPeminjaman: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        })
                    }
                    else {
                        this.setState({
                            modalHistoryPeminjaman: false,
                        });
                    }
                })
        })
    }

    loadHistory(id) {
        this.setState({
            history: [],
            loadingHistory: true,
        }, () => {
            let form = {
                id: id ? id : this.state.idHistory,
                paging: this.state.pagination,
                page : this.state.currentPageHistoryInv + 1

            };

            this.props.request("inventaris/history", form, 'POST')
                .then(response => {
                    if (response.success) {
                        this.setState({
                            history: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                            loadingHistory: false
                        })
                    } else {
                        this.setState({
                            modalHistory: false,
                            idHistory: 0,
                            loadingHistory: false
                        })
                    }
                })

        })
    }

    handlePageHistory(historyPeminjaman) {
        let selected = historyPeminjaman.selected;
        this.setState({ currentPageHistory: selected, historyPeminjaman: [], loadingHistoryPeminjaman: true }, () => {
            this.loadHistoryPeminjaman();
        });
    }

    handlePageHistoryInv(history) {
        let selected = history.selected;
        this.setState({ currentPageHistoryInv: selected, history: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    exportTemplate = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'inventaris/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'inventaris/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    exportExcel = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'inventaris/export?token=' + AuthKey, "_blank")
    }

    componentDidMount() {
        this.loadData()
        this.loadDataKaryawan()
        // this.loadHistory()
        // this.loadHistoryPeminjaman()
    }

    render() {
        let no = 0
        const stylefoto = {
            height: '50px',
            width: '50px',
            objectFit: 'cover'
        }
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Inventaris") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Inventaris - iPresens</title>
                        <meta name="description" content="Daftar Inventaris" />
                    </Helmet>
                    <Modal size='lg' zIndex={999} isOpen={this.state.modalAdd} toggle={() => this.setState({
                        modalAdd: false, nama: '',
                        deskripsi: '',
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalAdd: false, judul: '',
                            shortdesc: '',
                            gambar: '',
                            fileList: [],
                            deskripsi: '',
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("tambahinventaris")}</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("namainventaris")}</label>
                                        <div>
                                            <input name="nama" className="form-control pengumuman-input" placeholder={i18n.t("ketiknamainventaris")}
                                                   type="text" required="" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("deskripsiinventaris")}</label>
                                        <div>
                                            <input name="deskripsi" className="form-control pengumuman-input" placeholder={i18n.t('ketikdeskripsiinventaris')}
                                                   type="text" required="" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            <button onClick={() => this.setState({
                                modalAdd: false, judul: '',
                                shortdesc: '',
                                gambar: '',
                                fileList: [],
                                deskripsi: '',
                            })} className='ipresens-cancel-button' >{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' disabled={this.state.prosesSimpan} onClick={this.simpanInventaris}><img alt='' src={SaveIcon} /> {this.state.prosesSimpan ? "Menyimpan.." : i18n.t("simpan")}</button>
                        </ModalFooter>
                    </Modal>

                    <Modal size='lg' zIndex={999} isOpen={this.state.modalPeminjaman} toggle={() => this.setState({
                        modalPeminjaman: false, nama_karyawan: '', nama_inventaris: '',
                        tanggal_peminjaman: '', idPinjam: 0,
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalPeminjaman: false, nama_karyawan: '', nama_inventaris: '',
                            tanggal_peminjaman: '', idPinjam: 0,
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("peminjamaninventaris")}</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label
                                            className="mb-2 pengumuman-label-input">{i18n.t("namakaryawan")}</label>
                                        <Select
                                            placeholder={i18n.t('namakaryawan')}
                                            backspaceRemoves={true} deleteRemoves={true}
                                            styles={{paddingLeft: "0px", fontSize: '12px'}}
                                            components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                            value={this.state.karyawan_selected}
                                            isLoading={this.state.karyawan_loading}
                                            options={
                                                this.state.data_karyawan != null ? (
                                                        this.state.data_karyawan.map((item, index) => (
                                                            {
                                                                key: index,
                                                                value: item.karyawan_id,
                                                                label: item.karyawan_nama,
                                                            }
                                                        ))
                                                    )
                                                    : ("sdfsdfsd")
                                            }
                                            onChange={
                                                (karyawan_selected) => {
                                                    if (!karyawan_selected) {
                                                        this.setState({
                                                            karyawan_selected: '',
                                                            karyawan_id: 0,
                                                        })
                                                    } else {
                                                        this.setState({
                                                            karyawan_selected,
                                                            karyawan_id: karyawan_selected.value,
                                                            nama_karyawan: karyawan_selected.label
                                                        })
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("namainventaris")}</label>
                                        <Select
                                            placeholder={i18n.t('namainventaris')}
                                            backspaceRemoves={true} deleteRemoves={true}
                                            styles={{paddingLeft: "0px", fontSize: '12px'}}
                                            components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                            value={this.state.inventaris_selected}
                                            options={
                                                this.state.data != null ? (
                                                        this.state.data.map((item, index) => (
                                                            {
                                                                key: index,
                                                                value: item.inventaris_id,
                                                                label: item.inventaris_name,
                                                            }
                                                        ))
                                                    )
                                                    : ("")
                                            }
                                            onChange={
                                                (inventaris_selected) => {
                                                    if (!inventaris_selected) {
                                                        this.setState({
                                                            inventaris_selected: '',
                                                            inventaris_id: 0,
                                                        })
                                                    } else {
                                                        this.setState({
                                                            inventaris_selected,
                                                            idPinjam: inventaris_selected.value,
                                                            nama_inventaris: inventaris_selected.label,
                                                        })
                                                    }
                                                }
                                            }
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label
                                            className="mb-2 pengumuman-label-input">{i18n.t("tanggalpeminjaman")}</label>
                                        <div>
                                            <input name="tanggal_peminjaman" className="form-control"
                                                   placeholder={i18n.t('ketikdeskripsiinventaris')}
                                                   type="date" required="" onChange={this.handleChange} value={this.state.tanggal_peminjaman}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            <button onClick={() => this.setState({
                                modalPeminjaman: false, nama_karyawan: '', nama_inventaris: '',
                                tanggal_peminjaman: '',
                            })} className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' disabled={this.state.prosesPinjam} onClick={this.simpanPeminjaman}><img alt='' src={SaveIcon} /> {this.state.prosesPinjam ? "Menyimpan.." : i18n.t("simpan")}</button>
                        </ModalFooter>
                    </Modal>

                    <Modal size='lg' zIndex={999} isOpen={this.state.modalEdit} toggle={() => this.setState({
                        modalEdit: false, nama: '',
                        deskripsi: '',
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalEdit: false, nama: '',
                            deskripsi: '',
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("editinventaris")}</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("namainventaris")}</label>
                                        <div>
                                            <input name="namaEdit" className="form-control pengumuman-input" placeholder={i18n.t("ketiknamainventaris")}
                                                   type="text" required="" onChange={this.handleChange} value={this.state.namaEdit}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("deskripsiinventaris")}</label>
                                        <div>
                                            <input name="deskripsiEdit" className="form-control pengumuman-input" placeholder={i18n.t('ketikdeskripsiinventaris')}
                                                   type="text" required="" onChange={this.handleChange} value={this.state.deskripsiEdit}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            <button onClick={() => this.setState({
                                modalEdit: false,
                            })} className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' onClick={this.editInventaris}><img alt='' src={SaveIcon} /> {i18n.t("simpan")}</button>
                        </ModalFooter>
                    </Modal>

                    <Modal size='lg' zIndex={999} isOpen={this.state.modalReturn} toggle={() => this.setState({
                        modalReturn: false, tanggalKembali: ''
                    })} style={{ maxWidth: "800px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalReturn: false, tanggalKembali: ''
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("selesaikanpeminjaman")}</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("tanggalpengembalian")}</label>
                                        <div>
                                            <input name="tanggalKembali" className="form-control pengumuman-input"
                                                   type="date" required="" onChange={this.handleChange} value={this.state.tanggalKembali}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            <button onClick={() => this.setState({
                                modalReturn: false, tanggakKembali: ''
                            })} className='ipresens-cancel-button' >{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' disabled={this.state.prosesReturn} onClick={this.selesaiPeminjaman}><img alt='' src={SaveIcon} /> {this.state.prosesReturn ? i18n.t('menyimpan') : i18n.t("simpan")}</button>
                        </ModalFooter>
                    </Modal>

                    <Modal size='lg' zIndex={999} isOpen={this.state.modalHistoryPeminjaman} toggle={() => this.setState({
                        modalRiwayatPeminjaman: false,
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalHistoryPeminjaman: false,
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("riwayatpeminjaman")}</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistoryPeminjaman && (
                                        <div style={{ textAlign: "center", margin: '10px auto'}}>
                                            <Spin size="large" tip={i18n.t('loading')} style={{ width: "100%", margin: "auto"}}/>
                                        </div>
                                    )}
                                    {this.state.historyPeminjaman.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>{i18n.t('karyawan')}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left"style={{ marginRight: '20px' }}>
                                                    <h4>{i18n.t('aktivitas')}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.historyPeminjaman.map((item, index) => {
                                                return (
                                                    <div key={index} style={{ marginBottom: '20px'}}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                    <div style={{marginRight: '16px'}}>
                                                                        {item.karyawan.karyawan_foto !== null ? (
                                                                            <img
                                                                                src={IMG_HOST + 'mitra/frontapp/karyawan/' + item.karyawan.karyawan_foto}
                                                                                alt=""
                                                                                style={stylefoto}
                                                                            />
                                                                        ) : (<img
                                                                            src={IMG_HOST + "user-profile.png"}
                                                                            alt=""
                                                                            style={stylefoto}
                                                                        />)}
                                                                    </div>
                                                                    <div>
                                                                        <h6 className="mb-0 font-13" style={{
                                                                            fontWeight: 500,
                                                                            fontSize: '16px',
                                                                            lineHeight: '24px'
                                                                        }}>{item.karyawan.karyawan_nama}</h6>
                                                                        <p className="m-0 font-12">
                                                                            <span
                                                                                className="col-green font-weight-bold">
                                                                              {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 8 }}>
                                                                            {item.returned_date ? (
                                                                                    <div
                                                                                        className="m-0"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '100%',
                                                                                            color: '#057CE4',
                                                                                            borderColor: '#057CE4',
                                                                                        }}
                                                                                    >
                                                                                        <div style={{
                                                                                            paddingTop: '3px',
                                                                                            paddingBottom: '3px',
                                                                                            display: "flex", alignItems: "center", justifyContent: "center"
                                                                                        }}>
                                                                                            {i18n.t('selesai')}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                                : (
                                                                                    <div
                                                                                        className="m-0"
                                                                                        style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '100%',
                                                                                            color: '#E6462E',
                                                                                            borderColor: '#E6462E',
                                                                                        }}>
                                                                                        <div style={{
                                                                                            paddingTop: '3px',
                                                                                            paddingBottom: '3px',
                                                                                            display: "flex", alignItems: "center", justifyContent: "center"
                                                                                        }}>
                                                                                            {i18n.t('sedangdipinjam')}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{
                                                                paddingTop: '28px',
                                                                paddingBottom: '16px',
                                                                paddingRight: '12px',
                                                                paddingLeft: '12px'
                                                            }}>
                                                                <div style={{
                                                                    display: "flex", alignItems: "center", gap: '3px',
                                                                }}>
                                                                    <h6>{i18n.t('tanggalpeminjaman')} : </h6>
                                                                    <h6>{moment(item.borrow_date).format('ll')}</h6>
                                                                </div>
                                                                {item.returned_date ? (
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        gap: '3px'
                                                                    }}>
                                                                        <h6>{i18n.t('tanggalpengembalian')} : </h6>
                                                                        <h6>{item.returned_date ? moment(item.borrow_date).format('ll') : ''}</h6>
                                                                    </div>
                                                                ) : null}
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                )
                                            })}

                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            {this.state.historyPeminjaman.length !== 0 &&
                                <>
                                    <div className='col font-14' style={{
                                        padding: "auto",
                                        margin: "auto",
                                        fontWeight: 500,
                                        lineHeight: "24px"
                                    }}>{i18n.t('menampilkan')} {this.state.historyPeminjaman.length === 0 || this.state.historyPeminjaman.length === null ? "0" : this.state.historyPeminjaman.length} {i18n.t('dari')} {this.state.totalDataHistory} {i18n.t('data')}
                                    </div>
                                    <div className='col'>
                                        <ReactPaginate
                                            previousLabel={<img src={ArrowLeft} alt=''/>}
                                            nextLabel={<img src={ArrowRight} alt=''/>}
                                            pageCount={this.state.pageCountHistory}
                                            forcePage={this.state.currentPageHistory}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={this.handlePageHistory}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link custom-page-link'}
                                            containerClassName={'pagination my-2'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link custom-page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link custom-prev-next-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link custom-prev-next-link'}
                                            activeClassName={'active custom-active'}
                                        />
                                    </div>
                                </>
                            }
                        </ModalFooter>
                    </Modal>

                    <Modal size='lg' zIndex={999} isOpen={this.state.modalHistory} toggle={() => this.setState({
                        modalHistory: false,
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalHistory: false,
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("riwayatinventaris")}</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: "center", margin: '10px auto'}}>
                                            <Spin size="large" tip={i18n.t('loading')} style={{ width: "100%", margin: "auto"}}/>
                                        </div>
                                    )}
                                    {this.state.history.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>{i18n.t('user')}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left"style={{ marginRight: '20px' }}>
                                                    <h4>{i18n.t('aktivitas')}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.history.map((item, index) => {
                                                return (
                                                    <div key={index} style={{ marginBottom: '20px'}}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                    <div style={{marginRight: '16px'}}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{ width: '48px' , marginBottom:'25px', height:'48px'}}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6 className="mb-0 font-13" style={{
                                                                            fontWeight: 500,
                                                                            fontSize: '16px',
                                                                            lineHeight: '24px'
                                                                        }}>{item.p_user_name} | {item.level_name}</h6>
                                                                        <p className="m-0 font-12">
                                                                            <span
                                                                                className="col-green font-weight-bold">
                                                                              {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 8 }}>
                                                                            {item.history_status === "created" ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        {i18n.t('dibuat')}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="d-flex justify-content-center"
                                                                                        style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                        {i18n.t('diubah')}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{
                                                                paddingTop: '28px',
                                                                paddingBottom: '16px',
                                                                paddingRight: '12px',
                                                                paddingLeft: '12px'
                                                            }}>
                                                                {item.history_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ? item.parent_history.inventaris_name != item.inventaris_name && (
                                                                            <div>
                                                                                <h6
                                                                                    className="mb-3 font-13 text-left"
                                                                                    style={{
                                                                                        fontWeight: 100,
                                                                                        fontSize: '16px',
                                                                                        lineHeight: '24px',
                                                                                    }}
                                                                                >
                                                                                    {i18n.t('namainventaris')} : {i18n.t('dari')}{' '}
                                                                                    {item.parent_history
                                                                                        ? item.parent_history.inventaris_name
                                                                                        : 'N/A'}{' '}
                                                                                    <img
                                                                                        src={ArrowRight}
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: '20x',
                                                                                            marginBottom: '1px',
                                                                                            height: '18px'
                                                                                        }}
                                                                                    /> {i18n.t('jadi')}
                                                                                    : {item.inventaris_name ? item.inventaris_name : ''}
                                                                                </h6>
                                                                            </div>
                                                                        ) : null}
                                                                        {item.parent_history ? item.parent_history.inventaris_desc != item.inventaris_desc && (
                                                                            <div>
                                                                                <h6
                                                                                    className="mb-3 font-13 text-left"
                                                                                    style={{
                                                                                        fontWeight: 100,
                                                                                        fontSize: '16px',
                                                                                        lineHeight: '24px',
                                                                                    }}
                                                                                >
                                                                                    {i18n.t('deskripsiinventaris')} : {i18n.t('dari')}{' '}
                                                                                    {item.parent_history
                                                                                        ? item.parent_history.inventaris_desc
                                                                                        : 'N/A'}{' '}
                                                                                    <img
                                                                                        src={ArrowRight}
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: '20x',
                                                                                            marginBottom: '1px',
                                                                                            height: '18px'
                                                                                        }}
                                                                                    /> {i18n.t('jadi')}
                                                                                    : {item.inventaris_desc ? item.inventaris_desc : ''}
                                                                                </h6>
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                )
                                            })}

                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            {this.state.history.length !== 0 &&
                                <>
                                    <div className='col font-14' style={{
                                        padding: "auto",
                                        margin: "auto",
                                        fontWeight: 500,
                                        lineHeight: "24px"
                                    }}>{i18n.t('menampilkan')} {this.state.history.length === 0 || this.state.history.length === null ? "0" : this.state.history.length} {i18n.t('dari')} {this.state.totalDataHistory} {i18n.t('data')}
                                    </div>
                                    <div className='col'>
                                        <ReactPaginate
                                            previousLabel={<img src={ArrowLeft} alt=''/>}
                                            nextLabel={<img src={ArrowRight} alt=''/>}
                                            pageCount={this.state.pageCountHistory}
                                            forcePage={this.state.currentPageHistoryInv}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={this.handlePageHistoryInv}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link custom-page-link'}
                                            containerClassName={'pagination my-2'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link custom-page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link custom-prev-next-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link custom-prev-next-link'}
                                            activeClassName={'active custom-active'}
                                        />
                                    </div>
                                </>
                            }
                        </ModalFooter>
                    </Modal>

                    <div className="container-fluid pt-4">
                        <div className='row'>
                            <div className='col-md-12' style={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#5D6F80"
                            }}>iPresens Admin Panel
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-md-12' style={{
                                fontWeight: 700,
                                fontSize: "24px",
                                lineHeight: "36px",
                                color: "#143B5E"
                            }}>{i18n.t("daftarinventaris")}</div>
                        </div>
                        <div className="card">
                            <div className="card-header border-0 p-4">
                                <div className='row'>
                                    <div className='col-md-6 pengumuman-col-filter'>
                                        <div className='flex-row flex-nowrap'>
                                            <div className='input-group btn-srch-pengumuman  mr-1'>
                                                <span className='input-group-text pengumuman-filter-nama-input-text'>
                                                    <img alt='' src={SearchIcon}/>
                                                </span>
                                                <input className='form-control mr-2 pengumuman-filter-nama-input'
                                                       type='text' placeholder={i18n.t("carinamainventaris")}
                                                       onKeyUp={(event) => {
                                                           if (event.which === 13) {
                                                               event.preventDefault()
                                                               this.setState({
                                                                   page: 0,
                                                                   paging: this.state.paging,
                                                                   loading: true,
                                                                   data: []
                                                               }, () => {
                                                                   this.loadData()
                                                               });
                                                           }
                                                       }} onChange={this.handleChange} name="filter_nama" value={this.state.filter_nama} />
                                                <button className='btn ipresens-btn-reset-filter' onClick={this.resetFilter}>
                                                    <img alt='' src={RefreshIcon} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6' style={{
                                        display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 6
                                    }}>
                                        <button className='ipresens-btn-add-pengumuman btn-add-pengumuman'
                                                onClick={() => this.setState({
                                                    modalAdd: true
                                                })}><img alt='' src={PlusIcon}/> {i18n.t("tambahinventaris")}</button>
                                        <Tooltip trigger={'click'} placement="bottomLeft" color={'#fff'} zIndex={900}
                                                 title={
                                                     <div className="p-2">
                                                         <a className={'text-dark'} onClick={() => this.setState({
                                                             modalPeminjaman: true,
                                                         })}>{i18n.t('peminjamaninventaris')}</a><br/>
                                                         <a className={'text-dark'} onClick={() => this.setState({
                                                             modalImport: true,
                                                         })}>{i18n.t('importinventaris')}</a><br/>
                                                         <a className={'text-dark'} onClick={this.exportExcel}>{i18n.t('exportinventaris')}</a><br/>
                                                     </div>
                                                 }>
                                            <a><i
                                                style={{
                                                    width: "18",
                                                    fontSize: 18,
                                                    color: 'rgb(51 51 51)',
                                                    alignItems: "center",
                                                    height: "40px",
                                                    paddingRight: "30px"
                                                }}
                                                className='btn border border-dark border-1 px-2'
                                            ><img src={MoreIcon}/></i></a>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body daftar-pengumuman pt-3">
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <Empty description={i18n.t('datakosong')}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    <Table className="table table-striped table-borderless mb-5">
                                        <Thead>
                                            <Tr>
                                                <Th className="pengumuman-th-table">#</Th>
                                                <Th className="pengumuman-th-table">{i18n.t("namainventaris")}</Th>
                                                <Th className="pengumuman-th-table" style={{ minWidth: '120px'}}>{i18n.t("deskripsiinventaris")}</Th>
                                                <Th className="pengumuman-th-table">{i18n.t("peminjamsaatini")}</Th>
                                                <Th className="pengumuman-th-table"></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading &&
                                                <Tr>
                                                    <Td colSpan={5}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </Td>
                                                </Tr>
                                            }
                                            {this.state.data.map((item, index) => {
                                                no += 1
                                                return (
                                                    <Tr>
                                                        <Td className="pengumuman-td-table">
                                                            {pageNumber(no, this.state.perPage, this.state.currentPage)}
                                                        </Td>
                                                        <Td className="pengumuman-td-table" style={{ minWidth: "200px" }}>{item.inventaris_name}</Td>
                                                        <Td className="pengumuman-td-table">{item.inventaris_desc}</Td>
                                                        <Td className="pengumuman-td-table">{item.barang?.returned_date ? i18n.t('tersedia') : item.barang?.karyawan?.karyawan_nama || i18n.t('tersedia')}</Td>
                                                        <Td className="pengumuman-td-table">
                                                            <Tooltip trigger={'click'} placement="bottom" color={'#fff'} zIndex={900}
                                                                     title={
                                                                         <div className={'p-2'}>
                                                                             {item.barang && !item.barang?.returned_date && (
                                                                                 <>
                                                                                     <a onClick={() => {
                                                                                         this.setState({
                                                                                             modalReturn: true,
                                                                                             idKembali: item.barang.inventaris_id
                                                                                         }, () => this.loadData())
                                                                                     }}
                                                                                        className="text-dark"
                                                                                     >
                                                                                         {i18n.t("selesaikanpeminjaman")}
                                                                                     </a>
                                                                                     <br/>
                                                                                 </>
                                                                             )}
                                                                             <a onClick={() => this.setState({
                                                                                 modalHistoryPeminjaman: true,
                                                                                 idHistory: item.inventaris_id
                                                                             }, () => {
                                                                                 this.loadHistoryPeminjaman(item.inventaris_id)
                                                                             })}
                                                                                className={'text-dark'}>{i18n.t('riwayatpeminjaman')}</a><br/>
                                                                             <a onClick={() => this.setState({
                                                                                 modalHistory: true,
                                                                                 idHistory: item.inventaris_id
                                                                             }, () => {
                                                                                 this.loadHistory(item.inventaris_id)
                                                                             })}
                                                                                className={'text-dark'}>{i18n.t('riwayatinventaris')}</a><br/>
                                                                             <a onClick={() => this.setState({
                                                                                 idDetail: item.inventaris_id
                                                                                 // inventaris_id: item._inv_karyawan_id
                                                                             }, () => this.loadDetail())}
                                                                                className={'text-dark'}>{i18n.t('edit')}</a><br/>
                                                                             <a onClick={() => {
                                                                                 this.setState({
                                                                                     idDel: item.inventaris_id
                                                                                 })
                                                                                 this.wantDelete()
                                                                             }}
                                                                                className={'text-danger'}>{i18n.t("hapus")}</a>
                                                                         </div>
                                                                     }>
                                                                <a><i
                                                                    className="fa fa-ellipsis-v" style={{
                                                                    width: 35,
                                                                    fontSize: 18,
                                                                    padding: 11,
                                                                    color: 'rgb(51 51 51)'
                                                                }}
                                                                ></i></a>
                                                            </Tooltip>

                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>

                    <Modal size="lg" zIndex={999} isOpen={this.state.modalImport} toggle={() => this.setState({
                        modalImport: false, importFile: null
                    })} style={{ maxWidth: "500px" }}>
                        <ModalHeader toggle={() => this.setState({ modalImport: false,  importFile: null })} className="pengumuman-modal-header">
                            {i18n.t('importinventaris')}
                        </ModalHeader>
                        <ModalBody>
                            <div className="p-2">
                                <a style={{color: "#5D6F80", cursor: "default", fontSize: "14px"}}>{i18n.t('importalert')}</a><br/>
                                <a onClick={this.exportTemplate} style={{color: "#057CE4", fontSize: "14px"}}>{i18n.t('unduhtemplate')}</a>
                                <div style={{
                                    position: 'relative',
                                    width: '100%',
                                    backgroundColor: '#F9F9F9',
                                    padding: 16,
                                    borderRadius: 6,
                                    marginTop: 8,
                                    border: "1px dashed #DDDDDD"
                                }}>
                                    <input type='file'
                                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                           onChange={(event) => {
                                               this.setState({
                                                   importFile: event.target.files[0],
                                               })
                                           }}/><br/>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {this.state.importLoading ?
                                <button className={'btn btn-primary'}
                                        style={{textTransform: "none", width: "100%"}} disabled={true}><i
                                    className="fa fa-refresh fa-spin"></i>{i18n.t('sedangmenguploaddata')}</button>
                                :
                                <button
                                    className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'}
                                    style={{textTransform: "none", width: "100%"}}
                                    disabled={this.state.importFile === null}
                                    onClick={this.importExcel}>{i18n.t('importdata')}</button>
                            }
                        </ModalFooter>
                    </Modal>

                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListInventaris);
