import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import {Checkbox, Empty, Modal, Spin, Tooltip} from 'antd';
import {
    Button,
    ButtonGroup, Card, CardBody,
    Col, Input,
    InputGroup, InputGroupAddon, FormGroup, Label, Modal as ModalRs,
    Modal as ModalRS,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Paging from "../../components/paging";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import moment from "moment";
import format from "format-number";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import importIcon from "../../assets/icons/import-icon-blue-light.svg";
import './style.css';
import "./css/slipGaji.css";
import html2canvas from "html2canvas";
import pdfMake from 'pdfmake';
import TimeInput from "../../helper/time_input";
import ReactPaginate from 'react-paginate';
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";

// edit Modal
import Edit from "./edit-modal";
import presensiHeaderModalBG from "../../assets/images/modal_header_bg.png";
import presensiWhiteLogo from "../../assets/images/presensi_white_logo.png";
import {BACKEND_API_HOST, DEFAULT_IMG, IMG_HOST} from "../../helper/host";

import Potongan from "./edit-modal/modalPotongan"
import { toast } from "react-toastify";
import ImportIcon from "../../assets/icons/import-icon.svg";
import WarningIcon from "../../assets/icons/warning-icon.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {DateRange} from "react-date-range";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import IconPlusWhite from "../../assets/icons/IconPlusWhite.svg";
import MoreIcon from '../../assets/icons/more.svg';
import Dropdown from "react-bootstrap/Dropdown";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import i18n from "i18next";
import {getCurrency} from "../../helper/money";
import payroll from "../karyawan/profil/payroll";
import ArrowUpIcon from "../../assets/icons/arrow-up.svg";
import ArrowUpIconCustom1 from "../../assets/icons/arrow-up-custom-1.svg";
import ArrowUpIconCustom2 from "../../assets/icons/arrow-up-custom-2.svg";
import ArrowDownIcon from "../../assets/icons/arrow-down.svg";
import ArrowDownIconCustom1 from "../../assets/icons/arrow-down-custom-1.svg";
import ArrowDownIconCustom2 from "../../assets/icons/arrow-down-custom-2.svg";
import PlusWhiteIcon from "../../assets/icons/plus-white.svg";

export class List_payroll extends Component {
    constructor(props) {
        super(props);
        var namabulan = ("01 02 03 04 05 06 07 08 09 10 11 12");
        namabulan = namabulan.split(" ");
        var date = new Date();
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        var bulan = lastDay.getMonth();
        var tahun = lastDay.getFullYear();
        this.state = {
            loading: true,
            datas: [],
            payroll: [],
            idDel: '',
            filter_nama: "",
            sort_gaji: "",
            sort_kompensasi: "",
            sort_potongan: "",
            sort_total: "",
            isClicked: "",
            status: "",
            year: tahun,
            month: namabulan[bulan],
            yearAdd: "",
            monthAdd: "",
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 10,
            type: null,
            guide: false,
            modalAdd: false,
            modalAddCustom: false,
            modalEditCustom: false,
            modalSlip: false,
            modalEdit: false,
            modalHistory: false, //
            visiblePot: false,
            historyPayroll: [], //
            // dataHistory: [],
            payroll_id: 0,

            dataSlip: [],
            kompensasi: [],
            karyawan: [],
            dataKaryawan: {},
            gaji: [],
            penghasilan: [],
            perusahaan: [],
            karyawan_nama: '',
            karyawan_id: '',
            karyawan_name: "",
            karyawan_loading: false,
            karyawan_selected: null,
            saveAll: false,
            checkAll: false,
            checkedFinish: [],
            checked: [],
            allData: [],
            potongan: [],
            rows: [{}],
            data_term_potongan: [],
            data_no_clockout: [],
            data_clockout_edit: [],
            kompensasiDisableUpdata: false,
            potonganDisableUpdata: false,
            exportStartDate: "",
            exportEndDate: "",
            filterExport : "",
            modalExport: false,
            modalClockout: false,
            reactDateRange: false,
            valueDate: "",
            start_date: new Date(),
            end_date: new Date(),
            tanggal_mulai: "",
            tanggal_selesai: "",
            currentPageHistory: 0,
            loadingHistory: false,
            pageCountHistory: 0,
            payrollDone: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.loadSlip = this.loadSlip.bind(this)
        this.checkPotongan = this.checkPotongan.bind(this)
        this.checkKompensasi = this.checkKompensasi.bind(this)
        this.handleChangeStock = this.handleChangeStock.bind(this)
        this.handleSubmitPotongan = this.handleSubmitPotongan.bind(this)
        this.exportPayroll = this.exportPayroll.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this) //
        this.searchKaryawan = this.searchKaryawan.bind(this)
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this)
        this.handleCheckBoxAllChange = this.handleCheckBoxAllChange.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChangeStock = id => e => {

        const { name, value } = e.target;
        const list = [...this.state.rows];

        list[id] = { ...list[id], [name]: value };
        this.setState({
            rows: list,
        })
    };

    potonganModal = () => {
        this.setState({
            visiblePot: !this.state.visiblePot
        })
    };

    checkPotongan() {
        this.state.datas.map((data) => {
            if (data != null && data.potongan) {
                if (data.potongan.length == 0) {
                    this.setState({
                        potonganDisableUpdata: true
                    })
                }
            }
        }
        );
    }

    checkKompensasi() {
        this.state.datas.map((data) => {
            if (data != null) {
                if (data.kompensasi.length == 0) {
                    this.setState({
                        kompensasiDisableUpdata: true
                    })
                }
            }
        }
        );
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({
            currentPage: selected,
            loading: true,
            datas: []
        }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }
    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            sort_gaji: "",
            sort_kompensasi: "",
            sort_potongan: "",
            sort_total: "",
            isClicked: "",
            datas: [],
            allData: [],
            currentPage: 0,
            loading: true,
            checkAll: false,
            checkedFinish: []
        }, () => this.loadData())
    }


    resetFilter(event) {
        var namabulan = ("01 02 03 04 05 06 07 08 09 10 11 12");
        namabulan = namabulan.split(" ");
        var date = new Date();
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        var bulan = lastDay.getMonth();
        var tahun = lastDay.getFullYear();
        this.setState({
            filter_nama: "",
            sort_gaji: "",
            sort_kompensasi: "",
            sort_potongan: "",
            sort_total: "",
            isClicked: "",
            year: tahun,
            month: namabulan[bulan],
            status: "",
            datas: [],
            allData: [],
            currentPage: 0,
            loading: true,
            checkAll: false,
            checkedFinish: []
        }, () => this.loadData())
    }

    handleSort = (value) => {
        this.setState({
            sort_gaji: value,
            sort_kompensasi: value,
            sort_potongan: value,
            sort_total: value
        }, () => this.loadData())
    }

    handleIconClick = (type) => {
        this.setState((prevState) => ({
            isClicked: prevState.isClicked === type ? type : type
        }));
    };

    exportTemplatePayroll = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'karyawan/export_template?token=' + AuthKey, "_blank")
    }

    printDocumentFour = () => {
        var name = this.state.dataSlip.karyawan.karyawan_nama;
        var periode = this.state.dataSlip.payroll_date;
        var id = this.state.dataSlip.karyawan.karyawan_id;
        html2canvas(document.getElementById("content-list")).then(canvas => {
            var data = canvas.toDataURL();
            var pdfExportSetting = {
                content: [
                    {
                        image: data,
                        width: 550
                    }
                ]
            };
            pdfMake.createPdf(pdfExportSetting).download(name + '-' + periode + '-' + id + '.pdf');
        });

        this.setState({
            modalSlip: false
        })
    };

    loadUpdateGaji() {
        let form = {
            type: this.state.type,
            tanggal_mulai: this.state.tanggal_mulai,
            tanggal_selesai: this.state.tanggal_selesai,
            monthAdd: this.state.month,
            yearAdd: this.state.year
        }
        this.props.request("payroll/updateGajiMonth", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        datas: response.data,
                        loading: false,
                        tanggal_mulai: "",
                        tanggal_selesai: "",
                        valueDate: "",
                        monthAdd: "",
                        yearAdd: "",
                        type: "",
                        modalEditCustom: false,
                    }, () => this.loadData() )
                }
            });
    }

    updatePayroll() {
        this.setState({
            loading: true,
            datas: []
        })
        let formData = {
            type: this.state.type,
            tanggal_mulai: this.state.tanggal_mulai,
            tanggal_selesai: this.state.tanggal_selesai,
            monthAdd: this.state.monthAdd,
            yearAdd: this.state.yearAdd,
        }
        this.props.request('payroll/add_payroll_v2', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    this.loadData()
                    this.setState({
                        tanggal_mulai: "",
                        tanggal_selesai: "",
                        monthAdd: "",
                        yearAdd: "",
                        type: "",
                        modalAddCustom: false,
                    })
                } else if (response.checkout) {
                    Swal.fire({
                        title: 'Pemberitahuan!',
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'Atur Otomatis',
                        showCancelButton: true,
                        cancelButtonText: 'Atur Manual',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    })
                        .then((result) => {
                            if (result.isConfirmed) {
                                this.setState({
                                    loading: true,
                                    datas: []
                                })
                                let form = {
                                    month: this.state.month,
                                    year: this.state.year,
                                }
                                this.props.request("presensi/set-clockout", form, 'POST')
                                    .then(response => {
                                        if (response.success) {
                                            Swal.fire({
                                                title: i18n.t("berhasil"),
                                                text: i18n.t(response.message),
                                                icon: 'success',
                                                showConfirmButton: false,
                                                timer: 800,
                                            })
                                            this.setState({
                                                loading: true,
                                                datas: []
                                            })
                                            this.updatePayroll()
                                        }
                                    });
                            }
                            else {
                                this.props.history.push('/presence')
                            }
                        })
                } else {
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 5000,
                    })
                }
            })
    }

    loadSlip() {
        let form = {
            id: this.state.id
        }
        this.props.request("payroll/slip_gaji", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataSlip: response.data,
                        kompensasi: response.data.kompensasi,
                        potongan: response.data.potongan,
                        dataKaryawan: response.data.karyawan,
                        karyawan: response.data.karyawan,
                        gaji: response.gaji,
                        penghasilan: response.penghasilan,
                        perusahaan: response.data.perusahaan,
                        modalSlip: true
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    loadData() {
        let form = {
            paging          : this.state.paging,
            page            : this.state.currentPage + 1,
            filter_nama     : this.state.filter_nama,
            month           : this.state.month,
            year            : this.state.year,
            status          : this.state.status,
            sort_gaji       : this.state.sort_gaji === "g_asc" || this.state.sort_gaji === "g_desc" ? this.state.sort_gaji : "",
            sort_kompensasi : this.state.sort_kompensasi === "k_asc" || this.state.sort_kompensasi === "k_desc" ? this.state.sort_kompensasi : "",
            sort_potongan   : this.state.sort_potongan === "p_asc" || this.state.sort_potongan === "p_desc" ? this.state.sort_potongan : "",
            sort_total      : this.state.sort_total === "t_asc" || this.state.sort_total === "t_desc" ? this.state.sort_total : ""
        }
        this.props.request("payroll/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        datas: response.data.data,
                        totalData: response.data.total,
                        allData: [...(this.state.allData || []), ...response.data.data],
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    }, () => this.checkPotongan(), () => this.checkKompensasi())
                }
            });

    }

    deletePayroll() {
        Swal.fire({
            title: i18n.t("pemberitahuan"),
            text: i18n.t("andayakinhapuspayroll"),
            icon: 'warning',
            confirmButtonText: i18n.t("ya"),
            showCancelButton: true,
            cancelButtonText: i18n.t("batal"),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        datas: [],
                        loading: true,
                    })
                    let form = {
                        id: this.state.idDel,
                    }
                    this.props.request("payroll/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t("berhasil"),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                }
                                )
                            } else {
                                Swal.fire({
                                    title: i18n.t("gagal"),
                                    text: i18n.t(response.message),
                                    icon: 'warning',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                            }
                            this.loadData()
                        });
                }
            })


    }

    //
    loadHistory(id) {
        this.setState({
            historyPayroll: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.payroll_id,
                paging: 2,
                page: this.state.currentPageHistory + 1,
            };
            this.props.request("payroll/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            historyPayroll: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                    else {
                        this.setState({
                            modalHistory: false,
                            payroll_id: ''
                        })
                    }
                })
        })
    }

    //
    handlePageHistory(historyPayroll) {
        let selected = historyPayroll.selected;
        this.setState({ currentPageHistory: selected, historyPayroll: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    handlePotonganType = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.state.potongan];

        list[id] = { ...list[id], [name]: value };

        this.setState({
            potongan: list,
        })
    };

    handlePotonganAmount = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.state.potongan];
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        list[id] = { ...list[id], [name]: final };
        this.setState({
            potongan: list,
        })
    };

    handleSubmitPotongan(id) {
        let formData = {
            data: this.state.potongan,
            id: id,
        }
        this.props.request('karyawan_potongan/update', formData, 'POST')
            .then(response => {
                if (response.success) {
                    toast['success'](i18n.t(response.message), { toastId: Math.floor(Math.random() * 2) })
                    this.setState({
                        modalEdit: true,
                    })

                }
            });
    }

    potonganDelete(id, id_pot) {
        Swal.fire({
            title: i18n.t("pemberitahuan"),
            text: 'Anda yakin ingin menghapus?',
            icon: 'warning',
            confirmButtonText: i18n.t("ya"),
            showCancelButton: true,
            cancelButtonText: i18n.t("batal"),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: id_pot
                    }
                    this.props.request("karyawan_potongan/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t("berhasil"),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                })
                                this.loadPotongan(id)
                            } else {
                                Swal.fire({
                                    title: i18n.t("gagal"),
                                    text: i18n.t(response.message),
                                    icon: 'warning',
                                })
                            }
                        });
                }
            })
    }

    handlePending() {
        let form = {
            month: this.state.month,
            year: this.state.year,
        }
        this.props.request("presensi/get-no-clockout", form, 'POST')
            .then(response => {
                if(response.success) {
                    this.setState({
                        data_no_clockout: response.data,
                        modalClockout: true,
                        loading: false,
                    })
                }
            });
    }

    handleClockoutTime(id_absensi, jam_pulang_absen) {
        const index = this.state.data_clockout_edit.findIndex(item => item.id_absensi === id_absensi);

        let data_clockout_edit =[...this.state.data_clockout_edit];

        if (index !== -1) {
            data_clockout_edit[index].jam_pulang_absen = jam_pulang_absen;
        } else {
            data_clockout_edit.push({id_absensi: id_absensi, jam_pulang_absen: jam_pulang_absen});
        }

        this.setState({ data_clockout_edit: data_clockout_edit });
    }

    setClockoutTime() {
        let form = {
            absen : this.state.data_clockout_edit,
        }
        this.props.request("presensi/bulk-change-clockout", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon:'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    const { datas } = this.state
                    const datapay = datas[0]
                    const type = datapay ? datapay.payroll_generate_type : ""
                    const tangal_mulai = datapay ? datapay.payroll_custom_start : ""
                    const tanggal_selesai= datapay? datapay.payroll_custom_end : ""
                    this.setState({
                        loading: true,
                        modalClockout: false,
                        datas: [],
                        type : type,
                        tanggal_mulai : tangal_mulai,
                        tanggal_selesai : tanggal_selesai
                    })
                    this.loadUpdateGaji()
                }
            });
    }

    handleCancel = e => {
        this.setState({
            modalExport: false
        });
    };

    exportPayroll() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'payroll/export?token=' + AuthKey + '&payrollStartDate=' + this.state.exportStartDate + '&payrollEndDate=' + this.state.exportEndDate + "&filter=" + this.state.filterExport, "_blank")
    }

    searchKaryawan(){
        let form = {
            karyawan_name : this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response)=> {
                if (response.success){
                    this.setState({
                        karyawan: response.data,
                        karyawan_loading: false
                    })
                }
            })
    }

    handleChangeRows(value, name, id) {
        var list = [...this.state.rows];
        if (typeof value === 'string' || typeof value === 'number') {
            parseFloat(value)
            value = /^\d{2,}/.test(value) ? value.replace(/^0+/, '') : value
            var str = value.split('Rp ').join('');
            var final = str.split('.').join('');

            list[id] = { ...list[id], [name]: final };
        } else {
            list[id] = { ...list[id], [name]: 0 };
        }

        this.setState({
            rows: list,
        }, () => this.countTotalRow(id))
    }

    countTotalRow = (id) => {
        var list = [...this.state.rows];

        var data = this.state.rows[id]
        var test = data.payroll_amount

        var jumlah = data.payroll_waktu_kerja === undefined ? 0 : data.payroll_waktu_kerja.toString().split(',').join('.')
        var nominal = data.payroll_amount === undefined ? 0 : data.payroll_amount.toString().split(',').join('.')

        var total = eval(jumlah) * eval(nominal)

        list[id] = { ...list[id], payroll_total: total };

        this.setState({
            rows: list,
        })
    }

    handleChangeType = id => e => {
        const { name, value } = e.target;
        const list = [...this.state.rows];

        list[id] = { ...list[id], [name]: value };

        this.setState({
            rows: list,
        })
    };

    handleAdd(event) {
        let formData = {
            month: this.state.month,
            year: this.state.year,
            karyawan_id: this.state.karyawan_id,
            rows: this.state.rows
        }
        this.props.request('payroll/add_payroll_manual', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success',
                        timer: 1500,
                        showConfirmButton: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                isLoading: false,
                                modalAdd: false,
                                rows: [{}]
                            }, () => this.loadData())
                        } else {
                            this.setState({
                                isLoading: false,
                                modalAdd: false,
                                rows: [{}]
                            }, () => this.loadData())
                        }
                    })
                } else {
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ isLoading: false })
            })
    }

    handleStatusFinish() {
        Swal.fire({
            title: i18n.t("pemberitahuan"),
            text: i18n.t("andayakinselesaikan"),
            icon: 'warning',
            confirmButtonText: i18n.t("selesaikan"),
            cancelButtonText: i18n.t("batal"),
            showConfirmButton: true,
            showCancelButton: true
        })
            .then((result) => {
                if (result.isConfirmed){
                    let form = {
                        payroll_id: this.state.payroll_id
                    }
                    this.setState({
                        loading: false
                    })
                    this.props.request('payroll/ubah_status_finish', form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t("berhasil"),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                                this.setState({ modalSlip: false })
                            } else {
                                Swal.fire({
                                    title: i18n.t("gagal"),
                                    text: i18n.t(response.message),
                                    icon: 'warning',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                            }
                        });
                }
            })
    }

    handleCheckBoxChange(id) {
        this.setState((prevState) => {
            let updatedCheckedFinish = [...prevState.checkedFinish];
            if (updatedCheckedFinish.includes(id)) {
                updatedCheckedFinish = updatedCheckedFinish.filter(item => item !== id);
            } else {
                updatedCheckedFinish.push(id);
            }

            if (updatedCheckedFinish.length !== this.state.totalData) {
                this.setState({
                    checkAll: false
                })
            } else if (updatedCheckedFinish.length === this.state.totalData) {
                this.setState({
                    checkAll: true
                })
            }

            return {
                checkedFinish: updatedCheckedFinish,
            };
        });
    }

    handleCheckBoxAllChange() {
        this.setState(prevState => ({
            checkAll: !prevState.checkAll
        }), () => {
            this.state.checkAll
                ? this.setState({
                    checkedFinish: this.state.allData.map(item => item.payroll_id)
                })
                : this.setState({
                    checkedFinish: []
                })
        })
    }

    batchFinishDone = () => {
        Swal.fire({
            title: i18n.t("pemberitahuan"),
            text: i18n.t("andayakinselesaikanpayrolldone"),
            icon: 'warning',
            confirmButtonText: i18n.t("selesaikan"),
            cancelButtonText: i18n.t("batal"),
            showConfirmButton: true,
            showCancelButton: true
        })
            .then((result) => {
                if (result.isConfirmed){
                    this.setState({
                        data: [],
                        loading: true
                    })
                    let form = {
                        payroll_ids: this.state.checkedFinish
                    }
                    this.props.request("payroll/batch-finish-payroll", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    checkedFinish: [],
                                    checkAll: false
                                })
                            }
                            this.loadData()
                        });
                }
            })
    }

    bulkFinishDone() {
        Swal.fire({
            title: i18n.t("pemberitahuan"),
            text: i18n.t("andayakinselesaikandonefilter"),
            icon: 'warning',
            confirmButtonText: i18n.t("selesaikan"),
            cancelButtonText: i18n.t("batal"),
            showConfirmButton: true,
            showCancelButton: true
        })
            .then((result) => {
                if (result.isConfirmed){
                    this.setState({
                        loading: true,
                    })
                    let form = {
                        month: this.state.month,
                        year: this.state.year,
                    }
                    this.props.request("payroll/bulk-finish-payroll", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t("berhasil"),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    addPayrollAutoDone() {
        this.setState({
            loading: true,
            datas: []
        })
        let formData = {
            type: this.state.type,
            tanggal_mulai: this.state.tanggal_mulai,
            tanggal_selesai: this.state.tanggal_selesai,
            monthAdd: this.state.monthAdd,
            yearAdd: this.state.yearAdd,
            payrollDone: this.state.payrollDone
        }
        this.props.request('payroll/add_payroll_auto_done', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    this.loadData()
                    this.setState({
                        tanggal_mulai: "",
                        tanggal_selesai: "",
                        monthAdd: "",
                        yearAdd: "",
                        type: "",
                        modalAddCustom: false,
                        payrollDone: false,
                    })
                } else if (response.checkout) {
                    Swal.fire({
                        title: i18n.t("pemberitahuan"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'Atur Otomatis',
                        showCancelButton: true,
                        cancelButtonText: 'Atur Manual',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    })
                        .then((result) => {
                            if (result.isConfirmed) {
                                this.setState({
                                    loading: true,
                                    datas: []
                                })
                                let form = {
                                    month: this.state.month,
                                    year: this.state.year,
                                }
                                this.props.request("presensi/set-clockout", form, 'POST')
                                    .then(response => {
                                        if (response.success) {
                                            Swal.fire({
                                                title: i18n.t("berhasil"),
                                                text: i18n.t(response.message),
                                                icon: 'success',
                                                showConfirmButton: false,
                                                timer: 800,
                                            })
                                            this.setState({
                                                loading: true,
                                                datas: []
                                            })
                                            this.updatePayroll()
                                        }
                                    });
                            }
                            else {
                                this.props.history.push('/presence')
                            }
                        })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 5000,
                    })
                }
            })
    }

    handleCheckBoxDone = (event) => {
        if (event.target.checked) {
            Swal.fire({
                title: i18n.t("pemberitahuan"),
                text: i18n.t("konfirmasiautodone"),
                icon: 'warning',
                confirmButtonText: i18n.t("ya"),
                showCancelButton: true,
                cancelButtonText: i18n.t("batal"),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState(() => ({
                        payrollDone: true
                    }));
                } else {
                    this.setState(() => ({
                        payrollDone: false
                    }));
                }
            });
        } else {
            this.setState(() => ({
                payrollDone: false
            }));
        }
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        let borderBottom = "1px solid #EFEFEF"
        const selectionRange = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            key: 'selection',
        }
        const currency = this.props.user.perusahaan.perusahaan_currency;
        moment.locale(i18n.language)
        const style_add = {
            card_header: {
                backgroundColor: "#FAFDFF",
                color: "#41146c",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px"
            },
            payroll: {
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2",
                textAlign: "left",
                margin: "0px",
                marginBottom: "8px"
            }
        }

        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Daftar Payroll") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("laporandaftarpayroll")} - Presensi</title>
                        <meta name="description" content={i18n.t("detaildaftarpayroll")} />
                    </Helmet>
                    <Breadcrumb title={i18n.t("laporandaftarpayroll")}/>
                    <Tour isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid list-karyawan">
                        <div className="card"
                             style={{
                                 border: "1px solid #DDDDDD",
                                 borderBottomLeftRadius: "8px",
                                 borderBottomRightRadius: "8px",
                             }}
                        >
                            <div className="card-header">
                                <div className="row justify-content-between row-btn-payroll mx-0">
                                    <h5>{i18n.t("daftarpayrollkaryawan")}</h5>
                                    {this.state.checkedFinish.length !== 0 ?
                                        <div className="row">
                                            {this.state.checkedFinish.some(
                                                id => this.state.allData.find(item => item.payroll_id === id)?.payroll_status !== 'done'
                                            ) ?
                                                <div className="col-auto" align="center">
                                                    <button
                                                        className="btn ipresens-btn-color"
                                                        onClick={() => {
                                                            Swal.fire({
                                                                title: i18n.t("pemberitahuan"),
                                                                text: i18n.t("pemberitahuanpayrolltidakbisadone"),
                                                                icon: 'warning'
                                                            });
                                                        }}
                                                    >
                                                        Finish
                                                    </button>
                                                </div>
                                                :
                                                <div className='col-auto'>
                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                        <button
                                                            className="btn ipresens-btn-color"
                                                            onClick={() => {
                                                                this.batchFinishDone();
                                                            }}
                                                        >
                                                            Finish
                                                        </button>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                        :
                                        <div className="row">
                                            {/*<div className='col-md-4'>*/}
                                            {/*    <button className="mr-2 karyawan-import-button" style={{float: 'right'}} onClick={() => { this.setState({ modalimport: true }) }}><img src={ImportIcon} alt='' /> Import</button>*/}
                                            {/*</div>*/}
                                            {/*<div className='col-md-5'>*/}
                                            {/*    <button className="btn ipresens-btn-color"*/}
                                            {/*            disabled={this.state.potonganDisableUpdata || this.state.kompensasiDisableUpdata || this.state.loading}*/}
                                            {/*            onClick={() => { this.updatePayroll() }}*/}
                                            {/*    >*/}
                                            {/*        <span className="fa fa-download"></span> Update Daftar*/}
                                            {/*    </button>*/}
                                            {/*</div>*/}
                                            <div className='col-auto px-0 ml-2'>
                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                    <button
                                                        className='btn ipresens-btn-color float-right ml-2'
                                                        style={{
                                                            lineHeight: "20px",
                                                            padding: "6px 18px",
                                                            fontSize: '14px',
                                                        }}
                                                        onClick={() => {
                                                            this.setState({
                                                                modalAdd: true,
                                                                karyawan_id: 0,
                                                                karyawan_selected: null
                                                            })
                                                        }}><img src={PlusWhiteIcon} alt=''/>
                                                        {i18n.t("tambahpayroll")}
                                                    </button>
                                                )}
                                            </div>
                                            {this.state.datas.length === 0 ?
                                                <div className='col-auto px-0 ml-2'>
                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                        <button className="btn ipresens-btn-color"
                                                                disabled={this.state.potonganDisableUpdata || this.state.kompensasiDisableUpdata || this.state.loading}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modalAddCustom: true
                                                                    })
                                                                }}
                                                        >
                                                            <span
                                                                className="fa fa-download"></span> {i18n.t("generatedaftarpayroll")}
                                                        </button>
                                                    )}
                                                </div>
                                                :
                                                <div className='col-auto px-0 ml-2'>
                                                    {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                        <button className="btn ipresens-btn-color"
                                                                disabled={this.state.potonganDisableUpdata || this.state.kompensasiDisableUpdata || this.state.loading}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modalEditCustom: true
                                                                    })
                                                                }}
                                                        >
                                                            <span
                                                                className="fa fa-refresh"></span> {i18n.t("updatedaftarpayroll")}
                                                        </button>
                                                    )}
                                                </div>
                                            }
                                            <div className="col-auto px-0 mr-3 ml-2" align="center">
                                                <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'} title={
                                                    <div className='row px-4 py-2'>
                                                        <a disabled={this.state.potonganDisableUpdata || this.state.kompensasiDisableUpdata || this.state.loading}
                                                           onClick={() => this.setState({modalExport: !this.state.modalExport})}
                                                           style={{marginRight: "100px"}}
                                                           className={'text-dark'}
                                                        >Export Payroll</a>
                                                        <a disabled={this.state.potonganDisableUpdata || this.state.kompensasiDisableUpdata || this.state.loading}
                                                           className={'text-dark'}
                                                           onClick={() => {
                                                               this.bulkFinishDone()
                                                           }}
                                                        >{i18n.t("selesaikandone")}</a>
                                                    </div>
                                                }>
                                                    <a><i
                                                        style={{
                                                            width: "15",
                                                            fontSize: 18,
                                                            color: 'rgb(51 51 51)',
                                                            alignItems: "center",
                                                            height: "38px",
                                                            paddingRight: "30px"
                                                        }}
                                                        className='btn border border-dark border-1 px-2'
                                                    ><img src={MoreIcon}/></i></a>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="row filter-karyawan row-btn-payroll-list" style={{marginLeft: '15px'}}>
                                <div className="col-md-3 px-1 mb-1">
                                    <div className="input-group btn-srch-payroll-list">
                                        <input name="filter_nama"
                                               className={this.state.filter_nama !== "" ? "form-control" : "form-control bg-image-search"}
                                               placeholder={"       "+(i18n.t("carinamakaryawan"))}
                                               type="text"
                                               required=""
                                               value={this.state.filter_nama}
                                               onChange={this.handleChange}
                                               onKeyUp={(event) => {
                                                   event.preventDefault()
                                                   if (event.which == 13) {

                                                       this.setState({
                                                           page: 0,
                                                           paging: this.state.paging,
                                                           loading: true
                                                       }, () => {
                                                           this.loadData()
                                                       });
                                                   }
                                               }}/>
                                    </div>
                                </div>
                                <div className="col-md-2 px-1 mb-1">
                                    <select name="month" className="form-control" onChange={this.handleFilter}
                                            value={this.state.month}>
                                        <option value="01">{i18n.t("januari")}</option>
                                        <option value="02">{i18n.t("februari")}</option>
                                        <option value="03">{i18n.t("maret")}</option>
                                        <option value="04">{i18n.t("april")}</option>
                                        <option value="05">{i18n.t("mei")}</option>
                                        <option value="06">{i18n.t("juni")}</option>
                                        <option value="07">{i18n.t("juli")}</option>
                                        <option value="08">{i18n.t("agustus")}</option>
                                        <option value="09">{i18n.t("september")}</option>
                                        <option value="10">{i18n.t("oktober")}</option>
                                        <option value="11">{i18n.t("november")}</option>
                                        <option value="12">{i18n.t("desember")}</option>
                                    </select>
                                </div>
                                <div className="col-md-2 px-1 mb-1">
                                    <select name="year" className="form-control" onChange={this.handleFilter} value={this.state.year}>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                        <option value="2026">2026</option>
                                        <option value="2027">2027</option>
                                        <option value="2028">2028</option>
                                        <option value="2029">2029</option>
                                        <option value="2030">2030</option>
                                    </select>
                                </div>
                                <div className="col-md-2 px-1 mb-1">
                                    <div className="input-group btn-slct-status-payroll">
                                        <select className="form-control" name="status"
                                                onChange={this.handleFilter}>
                                            <option value="" selected={this.state.status === ''}>{i18n.t("pilihstatus")}</option>
                                            <option value="new">New</option>
                                            <option value="pending">Pending</option>
                                            <option value="done">Done</option>
                                            <option value="finish">Finish</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2 px-1 mb-1">
                                    <button className="btn ipresens-btn-reset" onClick={this.resetFilter}><span
                                        className="fa fa-refresh"></span></button>
                                </div>
                                <div className="col-md-auto px-1 mb-1">

                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    {!this.state.loading && this.state.datas.length === 0 ?
                                        <Empty description={i18n.t('datakosong')}
                                               image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                        :
                                        <table className="table table-striped table-borderless mb-5">
                                            <thead>
                                            <tr align="center">
                                                <th>
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-custom"
                                                        checked={this.state.checkAll}
                                                        onChange={this.handleCheckBoxAllChange}
                                                    ></input>
                                                </th>
                                                <th className="payroll-th-style">#</th>
                                                <th className="payroll-th-style">{i18n.t("nama")}</th>
                                                <th className="payroll-th-style">{i18n.t("periode")}</th>
                                                <th className="payroll-th-style">{i18n.t("tanggal")}</th>
                                                <th className="payroll-th-style">
                                                    <div className='d-flex justify-content-center'>
                                                        <div align="center" className='payroll-th-style'>{i18n.t("gaji")} </div>
                                                        <div className='ml-2 d-flex flex-column' style={{ marginTop: "2px" }}>
                                                            <img
                                                                src={
                                                                    this.state.isClicked === ""
                                                                        ? ArrowUpIcon
                                                                        : this.state.isClicked === "g_asc"
                                                                            ? ArrowUpIconCustom1
                                                                            : this.state.isClicked === "g_desc"
                                                                                ? ArrowUpIconCustom2
                                                                                : ArrowUpIcon
                                                                }
                                                                alt=""
                                                                onChange={this.handleFilter}
                                                                onClick={() => {
                                                                    this.handleIconClick("g_asc");
                                                                    this.handleSort("g_asc");
                                                                }}
                                                                style={{
                                                                    width: '10px',
                                                                    height: '10px',
                                                                    cursor: 'pointer'
                                                                }}
                                                                className="izin-btn"
                                                            />
                                                            <img
                                                                src={
                                                                    this.state.isClicked === ""
                                                                        ? ArrowDownIcon
                                                                        : this.state.isClicked === "g_desc"
                                                                            ? ArrowDownIconCustom1
                                                                            : this.state.isClicked === "g_asc"
                                                                                ? ArrowDownIconCustom2
                                                                                : ArrowDownIcon
                                                                }
                                                                alt=""
                                                                onChange={this.handleFilter}
                                                                onClick={() => {
                                                                    this.handleIconClick("g_desc");
                                                                    this.handleSort("g_desc");
                                                                }}
                                                                style={{
                                                                    width: '10px',
                                                                    height: '10px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                className="izin-btn"
                                                            />
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="payroll-th-style">
                                                    <div className='d-flex justify-content-center'>
                                                        <div align="center" className='payroll-th-style'>{i18n.t("kompensasi")} </div>
                                                        <div className='ml-2 d-flex flex-column' style={{ marginTop: "2px" }}>
                                                            <img
                                                                src={
                                                                    this.state.isClicked === ""
                                                                        ? ArrowUpIcon
                                                                        : this.state.isClicked === "k_asc"
                                                                            ? ArrowUpIconCustom1
                                                                            : this.state.isClicked === "k_desc"
                                                                                ? ArrowUpIconCustom2
                                                                                : ArrowUpIcon
                                                                }
                                                                alt=""
                                                                onChange={this.handleFilter}
                                                                onClick={() => {
                                                                    this.handleIconClick("k_asc");
                                                                    this.handleSort("k_asc");
                                                                }}
                                                                style={{
                                                                    width: '10px',
                                                                    height: '10px',
                                                                    cursor: 'pointer'
                                                                }}
                                                                className="izin-btn"
                                                            />
                                                            <img
                                                                src={
                                                                    this.state.isClicked === ""
                                                                        ? ArrowDownIcon
                                                                        : this.state.isClicked === "k_desc"
                                                                            ? ArrowDownIconCustom1
                                                                            : this.state.isClicked === "k_asc"
                                                                                ? ArrowDownIconCustom2
                                                                                : ArrowDownIcon
                                                                }
                                                                alt=""
                                                                onChange={this.handleFilter}
                                                                onClick={() => {
                                                                    this.handleIconClick("k_desc");
                                                                    this.handleSort("k_desc");
                                                                }}
                                                                style={{
                                                                    width: '10px',
                                                                    height: '10px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                className="izin-btn"
                                                            />
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="payroll-th-style">
                                                    <div className='d-flex justify-content-center'>
                                                        <div align="center" className='payroll-th-style'>{i18n.t("potongan")} </div>
                                                        <div className='ml-2 d-flex flex-column' style={{ marginTop: "2px" }}>
                                                            <img
                                                                src={
                                                                    this.state.isClicked === ""
                                                                        ? ArrowUpIcon
                                                                        : this.state.isClicked === "p_asc"
                                                                            ? ArrowUpIconCustom1
                                                                            : this.state.isClicked === "p_desc"
                                                                                ? ArrowUpIconCustom2
                                                                                : ArrowUpIcon
                                                                }
                                                                alt=""
                                                                onChange={this.handleFilter}
                                                                onClick={() => {
                                                                    this.handleIconClick("p_asc");
                                                                    this.handleSort("p_asc");
                                                                }}
                                                                style={{
                                                                    width: '10px',
                                                                    height: '10px',
                                                                    cursor: 'pointer'
                                                                }}
                                                                className="izin-btn"
                                                            />
                                                            <img
                                                                src={
                                                                    this.state.isClicked === ""
                                                                        ? ArrowDownIcon
                                                                        : this.state.isClicked === "p_desc"
                                                                            ? ArrowDownIconCustom1
                                                                            : this.state.isClicked === "p_asc"
                                                                                ? ArrowDownIconCustom2
                                                                                : ArrowDownIcon
                                                                }
                                                                alt=""
                                                                onChange={this.handleFilter}
                                                                onClick={() => {
                                                                    this.handleIconClick("p_desc");
                                                                    this.handleSort("p_desc");
                                                                }}
                                                                style={{
                                                                    width: '10px',
                                                                    height: '10px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                className="izin-btn"
                                                            />
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="px-1">
                                                    <div className='d-flex justify-content-center'>
                                                        <div align="center" className='payroll-th-style'>Total</div>
                                                        <div className='ml-2 d-flex flex-column' style={{ marginTop: "2px" }}>
                                                            <img
                                                                src={
                                                                    this.state.isClicked === ""
                                                                        ? ArrowUpIcon
                                                                        : this.state.isClicked === "t_asc"
                                                                            ? ArrowUpIconCustom1
                                                                            : this.state.isClicked === "t_desc"
                                                                                ? ArrowUpIconCustom2
                                                                                : ArrowUpIcon
                                                                }
                                                                alt=""
                                                                onChange={this.handleFilter}
                                                                onClick={() => {
                                                                    this.handleIconClick("t_asc");
                                                                    this.handleSort("t_asc");
                                                                }}
                                                                style={{
                                                                    width: '10px',
                                                                    height: '10px',
                                                                    cursor: 'pointer'
                                                                }}
                                                                className="izin-btn"
                                                            />
                                                            <img
                                                                src={
                                                                    this.state.isClicked === ""
                                                                        ? ArrowDownIcon
                                                                        : this.state.isClicked === "t_desc"
                                                                            ? ArrowDownIconCustom1
                                                                            : this.state.isClicked === "t_asc"
                                                                                ? ArrowDownIconCustom2
                                                                                : ArrowDownIcon
                                                                }
                                                                alt=""
                                                                onChange={this.handleFilter}
                                                                onClick={() => {
                                                                    this.handleIconClick("t_desc");
                                                                    this.handleSort("t_desc");
                                                                }}
                                                                style={{
                                                                    width: '10px',
                                                                    height: '10px',
                                                                    cursor: 'pointer',
                                                                }}
                                                                className="izin-btn"
                                                            />
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="payroll-th-style text-center">{i18n.t("status")}</th>
                                                <th className="payroll-th-style text-center">{i18n.t("aksi")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.loading &&
                                                <tr>
                                                    <td colSpan={11}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }}/>
                                                    </td>
                                                </tr>
                                            }
                                            {this.state.datas.map((item, index) => {
                                                no += 1
                                                return (
                                                    <tr
                                                        align="center"
                                                        style={{
                                                            borderBottom: no === this.state.datas.length ? "" : borderBottom
                                                        }}>
                                                        <td>
                                                            <input
                                                                className="checkbox-custom"
                                                                type="checkbox"
                                                                checked={this.state.checkedFinish.includes(item.payroll_id)}
                                                                onChange={() => this.handleCheckBoxChange(item.payroll_id)}
                                                            ></input>
                                                        </td>
                                                        <small>
                                                            <th scope="row" className="payroll-td-no-style">{pageNumber(no, this.state.perPage, this.state.currentPage)}</th>
                                                        </small>
                                                        <td align="center" className="payroll-td-style">
                                                            <a style={{color: "#8c2bee"}}
                                                               href={"/karyawan/profil/detail/" + item.karyawan.karyawan_slug}>
                                                                {item.karyawan.karyawan_nama}
                                                            </a>
                                                        </td>
                                                        <td align="center" className="payroll-td-style">
                                                            {item.payroll_generate_type === 'monthly' ?
                                                                moment(item.payroll_year + '-' + item.payroll_month + '-01').format('MMM') + ' ' + item.payroll_year
                                                                :
                                                                moment(item.payroll_custom_start).format("ll") + ' - ' + moment(item.payroll_custom_end).format("ll")
                                                            }
                                                        </td>
                                                        <td align="center" className="payroll-td-style">
                                                            {item.payroll_date !== null ? moment(item.payroll_date).format('ll') : ('-')}
                                                        </td>
                                                        <td align="center" className="payroll-td-style">
                                                            {item.payroll_gaji !== null ? format({
                                                                prefix: getCurrency(currency),
                                                                integerSeparator: "."
                                                            })(item.payroll_gaji) : ('-')}
                                                        </td>
                                                        <td align="center" className="payroll-td-style">
                                                            {item.payroll_kompensasi ? format({
                                                                prefix: getCurrency(currency),
                                                                integerSeparator: "."
                                                            })(item.payroll_kompensasi) : '-'}
                                                        </td>
                                                        <td align="center" className="payroll-td-style">
                                                            {item.payroll_potongan ? format({
                                                                prefix: getCurrency(currency),
                                                                integerSeparator: "."
                                                            })(item.payroll_potongan) : '-'}
                                                        </td>
                                                        <td align="center" className="payroll-td-style">
                                                            {format({
                                                                prefix: getCurrency(currency),
                                                                integerSeparator: "."
                                                            })(item.payroll_total)}
                                                        </td>
                                                        <td align="center" className="payroll-td-style">
                                                            {item.payroll_status === 'new' &&
                                                                <div className={"badge badge-warning"}>New</div>
                                                            }
                                                            {item.payroll_status === 'pending' &&
                                                                <div className={"badge badge-danger"}>Pending</div>
                                                            }
                                                            {item.payroll_status === 'done' &&
                                                                <div className={"badge badge-info"}>Done</div>
                                                            }
                                                            {item.payroll_status === 'finish' &&
                                                                <div className={"badge badge-success"}>Finish</div>
                                                            }
                                                        </td>
                                                        <td style={{border: "none"}} align="center"
                                                            className="payroll-td-style">
                                                            <Tooltip
                                                                trigger={'click'}
                                                                placement="bottom"
                                                                color={'#fff'}
                                                                title={
                                                                    <div className={'p-2'}>
                                                                        {item.payroll_status === 'pending' ? (
                                                                            <a id={`detail-${item.payroll_id}`}
                                                                               className="text-dark"
                                                                               color="warning"
                                                                               disabled={item.payroll_id == null}
                                                                               style={{
                                                                                   padding: '2px 2px',
                                                                                   textAlign: 'center',
                                                                                   textDecoration: 'none',
                                                                                   display: 'block',
                                                                                   fontSize: '16px',
                                                                                   margin: '4px 2px'
                                                                               }}
                                                                               onClick={() => {
                                                                                   this.handlePending()
                                                                               }}
                                                                            >Detail</a>
                                                                        ) : (
                                                                            <a id={`detail-${item.payroll_id}`}
                                                                               className="text-dark"
                                                                               color="warning"
                                                                               disabled={item.payroll_id == null}
                                                                               style={{
                                                                                   padding: '2px 2px',
                                                                                   textAlign: 'center',
                                                                                   textDecoration: 'none',
                                                                                   display: 'block',
                                                                                   fontSize: '16px',
                                                                                   margin: '4px 2px'
                                                                               }}
                                                                               onClick={() => {
                                                                                   this.setState({
                                                                                       modalEdit: true,
                                                                                       payroll_id: item.payroll_id,
                                                                                       karyawan_nama: item.karyawan.karyawan_nama,
                                                                                       karyawan_id: item.karyawan.karyawan_id
                                                                                   })
                                                                               }}
                                                                            >Detail</a>
                                                                        )}
                                                                        <a className="text-dark"
                                                                           color="danger" //id={`history-${item.payroll_id}`}
                                                                           disabled={item.payroll_id == null}
                                                                           style={{
                                                                               padding: `2px 2px`,
                                                                               textAlign: `center`,
                                                                               textDecoration: `none`,
                                                                               display: 'block',
                                                                               fontSize: '16px',
                                                                               margin: `4px 2px`
                                                                           }} onClick={() => {
                                                                            this.setState({
                                                                                modalHistory: true,
                                                                                payroll_id: item.payroll_id,
                                                                            }, () => {
                                                                                this.loadHistory(item.payroll_id);
                                                                            })
                                                                        }}
                                                                        >{i18n.t('riwayat')}</a>
                                                                        {
                                                                            (item.payroll_status === 'done' || item.payroll_status === 'finish') && (
                                                                                <a
                                                                                    className="text-dark"
                                                                                    color="danger"
                                                                                    disabled={item.payroll_id == null}
                                                                                    style={{
                                                                                        padding: '2px 2px',
                                                                                        textAlign: 'center',
                                                                                        textDecoration: 'none',
                                                                                        display: 'block',
                                                                                        fontSize: '16px',
                                                                                        margin: '4px 2px'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            id: item.payroll_id
                                                                                        }, () => {
                                                                                            this.loadSlip()
                                                                                        })
                                                                                    }}
                                                                                >{i18n.t('slipgaji')}</a>
                                                                            )
                                                                        }
                                                                        {
                                                                            item.payroll_status === 'done' && (
                                                                                <a
                                                                                    className="text-dark"
                                                                                    color="danger"
                                                                                    disabled={item.payroll_id == null}
                                                                                    style={{
                                                                                        padding: '2px 2px',
                                                                                        textAlign: 'center',
                                                                                        textDecoration: 'none',
                                                                                        display: 'block',
                                                                                        fontSize: '16px',
                                                                                        margin: '4px 2px'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.setState({payroll_id: item.payroll_id}, () => {
                                                                                            this.handleStatusFinish()
                                                                                        })
                                                                                    }
                                                                                    }
                                                                                >Finish</a>
                                                                            )
                                                                        }
                                                                        {
                                                                            (item.payroll_status === 'new' || item.payroll_status === 'pending') && (
                                                                                <a id={`delete-${item.payroll_id}`}
                                                                                   className="text-danger"
                                                                                   color="danger"
                                                                                   disabled={item.payroll_id == null}
                                                                                   style={{
                                                                                       padding: '2px 2px',
                                                                                       textAlign: 'center',
                                                                                       textDecoration: 'none',
                                                                                       display: 'block',
                                                                                       fontSize: '16px',
                                                                                       margin: '4px 2px'
                                                                                   }}
                                                                                   onClick={() => {
                                                                                       this.setState({idDel: item.payroll_id}, () => {
                                                                                           this.deletePayroll()
                                                                                       })
                                                                                   }}
                                                                                >{i18n.t('hapus')}</a>
                                                                            )
                                                                        }
                                                                    </div>
                                                                }>
                                                                <a><i
                                                                    className="fa fa-ellipsis-h" style={{
                                                                    width: 35,
                                                                    fontSize: 18,
                                                                    padding: 11,
                                                                    color: 'rgb(51 51 51)'
                                                                }}
                                                                ></i></a>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    }
                                    <Modal
                                        title="Import Payroll"
                                        visible={this.state.modalimport}
                                        onOk={this.handleOk}
                                        onCancel={this.handleCancel}
                                        footer={[
                                            this.state.importLoading ?
                                                <button className={'btn btn-primary'} style={{textTransform: "none", width: "100%"}} disabled={true}>
                                                    <i className="fa fa-refresh fa-spin"></i> Sedang mengupload data
                                                </button>
                                                :
                                                <button
                                                    className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn btn-primary'}
                                                    style={{textTransform: "none", width: "100%"}}
                                                    disabled={this.state.importFile === null}
                                                    onClick={this.importExcel}>Import Data</button>
                                        ]}
                                    >
                                        <div>
                                            <a style={{color: "#5D6F80", cursor: "default"}}>
                                                Anda bisa mengunduh template yang sudah kami sediakan untuk mempermudah pengimportan.
                                            </a>
                                            <br/>
                                            <a onClick={this.exportTemplatePayroll} style={{color: "#057CE4"}}>Unduh template disini</a>
                                            <div style={{
                                                position: 'relative',
                                                width: '100%',
                                                backgroundColor: '#F9F9F9',
                                                padding: 16,
                                                borderRadius: 6,
                                                marginTop: 8,
                                                border: "1px dashed #DDDDDD"
                                            }}>
                                                <input type='file'
                                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                       onChange={(event) => {
                                                           this.setState({
                                                               importFile: event.target.files[0],
                                                           })
                                                       }}/><br/>
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                            <div className='card-footer p-0 border-0'>
                                <div
                                    className='row mx-0'
                                    style={{
                                        backgroundColor: "#F5F5F5",
                                        width: "100%",
                                        borderBottomLeftRadius: "8px",
                                        borderBottomRightRadius: "8px",
                                    }}
                                >
                                    {this.state.datas.length !== 0 &&
                                        <>
                                            <div className='col font-14' style={{
                                                padding: "auto",
                                                margin: "auto",
                                                fontWeight: 500,
                                                lineHeight: "24px"
                                            }}>{i18n.t("menampilkan")} {this.state.datas.length === 0 || this.state.datas.length === null ? "0" : this.state.datas.length} {i18n.t("dari")} {this.state.totalData} data
                                            </div>
                                            <div className='col'>
                                                <ReactPaginate
                                                    previousLabel={<img src={ArrowLeft} alt=''/>}
                                                    nextLabel={<img src={ArrowRight} alt=''/>}
                                                    pageCount={this.state.pageCount}
                                                    forcePage={this.state.currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={this.handlePageClick}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link custom-page-link'}
                                                    containerClassName={'pagination my-2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link custom-page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link izin-prev-next-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link izin-prev-next-link'}
                                                    activeClassName={'active izin-active'}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            {
                                this.state.modalEdit ?
                                    <ModalRS size="lg" isOpen={this.state.modalEdit} style={{ maxWidth: '900px', width: '100%' }}>
                                        <ModalHeader toggle={() => this.setState({ modalEdit: false })}>Edit Payroll {this.state.karyawan_nama}</ModalHeader>
                                        <ModalBody>
                                            <Edit
                                                payroll_id={this.state.payroll_id}
                                                currency={currency}
                                                karyawan_id={this.state.karyawan_id}
                                                request={this.props.request}
                                                saveAll={this.state.saveAll}
                                                history={this.props.history}
                                                match={this.props.match}
                                                // loadPotonganKaryawan={this.loadPotonganKaryawan}
                                                // loadDataTermPotongan={this.loadDataTermPotongan}
                                                updateState={(state) => {
                                                    this.setState(state)
                                                }}
                                                loadData={() => {this.loadData()}}
                                                loadSlip={this.loadSlip}
                                            />
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button onClick={() => this.setState({modalEdit: false})}
                                                    className={'btn btn-danger'}>{i18n.t("tutup")}</Button>
                                        </ModalFooter>
                                    </ModalRS>

                                    : ""
                            }
                            {/*Modal Add Payroll Manual*/}
                            {
                                this.state.modalAdd ?
                                    <ModalRs size='lg' style={{ maxWidth: "1000px" }} isOpen={this.state.modalAdd} toggle={() => this.setState({ modalAdd: false, rows: [{}] })}>
                                        <ModalHeader style={{ backgroundColor: "#FAFDFF", padding: "12px 24px" }} toggle={() => this.setState({ modalAdd: false, rows: [{}] })}><div className='modal-header-reactstrap'>{i18n.t("tambahpayroll")}</div></ModalHeader>
                                        <ModalBody>
                                            <Col lg="12">
                                                <Card className="main-card mb-3">
                                                    <CardBody>
                                                        <div>
                                                            <div>
                                                                <Row>
                                                                    <Col>
                                                                        <div className='row mb-3'>
                                                                            <div className="col-md-2">
                                                                                <label
                                                                                    htmlFor="exampleFormControlInput1"
                                                                                    className="form-label font-edit-label">{i18n.t("bulan")}</label>
                                                                                <select name="month"
                                                                                        className="form-control"
                                                                                        onChange={this.handleChange}
                                                                                        value={this.state.month}
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            paddingTop: "8px",
                                                                                            paddingBottom: "8px"
                                                                                        }}>
                                                                                    <option value="01">{i18n.t("januari")}</option>
                                                                                    <option value="02">{i18n.t("februari")}</option>
                                                                                    <option value="03">{i18n.t("maret")}</option>
                                                                                    <option value="04">{i18n.t("april")}</option>
                                                                                    <option value="05">{i18n.t("mei")}</option>
                                                                                    <option value="06">{i18n.t("juni")}</option>
                                                                                    <option value="07">{i18n.t("juli")}</option>
                                                                                    <option value="08">{i18n.t("agustus")}</option>
                                                                                    <option value="09">{i18n.t("september")}</option>
                                                                                    <option value="10">{i18n.t("oktober")}</option>
                                                                                    <option value="11">{i18n.t("november")}</option>
                                                                                    <option value="12">{i18n.t("desember")}</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="col-md-2">
                                                                                <label
                                                                                    htmlFor="exampleFormControlInput1"
                                                                                    className="form-label font-edit-label">{i18n.t("tahun")}</label>
                                                                                <select name="year"
                                                                                        className="form-control"
                                                                                        onChange={this.handleChange}
                                                                                        value={this.state.year}
                                                                                        style={{
                                                                                            fontSize: "14px",
                                                                                            paddingTop: "8px",
                                                                                            paddingBottom: "8px"
                                                                                        }}>
                                                                                    <option value="2020">2020</option>
                                                                                    <option value="2021">2021</option>
                                                                                    <option value="2022">2022</option>
                                                                                    <option value="2023">2023</option>
                                                                                    <option value="2024">2024</option>
                                                                                    <option value="2025">2025</option>
                                                                                    <option value="2026">2026</option>
                                                                                    <option value="2027">2027</option>
                                                                                    <option value="2028">2028</option>
                                                                                    <option value="2029">2029</option>
                                                                                    <option value="2030">2030</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className='col-md-3'>
                                                                                <label
                                                                                    htmlFor="exampleFormControlInput1"
                                                                                    className="form-label font-edit-label">{i18n.t("karyawan")}</label>
                                                                                <div className='form-control p-0'>
                                                                                    <Select
                                                                                        className="font-edit-value"
                                                                                        placeholder={i18n.t("namakaryawan")}
                                                                                        backspaceRemoves={true}
                                                                                        deleteRemoves={true}
                                                                                        components={{
                                                                                            DropdownIndicator: () => null,
                                                                                            IndicatorSeparator: () => null
                                                                                        }}
                                                                                        value={this.state.karyawan_selected}
                                                                                        isLoading={this.state.karyawan_loading}
                                                                                        onFocus={() => this.setState({karyawan_loading: true}, () => this.searchKaryawan())}
                                                                                        onBlur={() => this.setState({karyawan_loading: false})}
                                                                                        onInputChange={(val) => this.setState({karyawan_name: val}, () => this.setState({karyawan_loading: true}, () => this.searchKaryawan()))}
                                                                                        options={
                                                                                            this.state.karyawan != null ? (
                                                                                                    this.state.karyawan.map((item, index) =>
                                                                                                        (
                                                                                                            {
                                                                                                                key: index,
                                                                                                                value: item.karyawan_id,
                                                                                                                label: item.karyawan_nama
                                                                                                            }
                                                                                                        )
                                                                                                    ))
                                                                                                : ("")
                                                                                        }
                                                                                        onChange={
                                                                                            (karyawan_selected) => {
                                                                                                if (!karyawan_selected) {
                                                                                                    this.setState({
                                                                                                        karyawan_selected: '',
                                                                                                        karyawan_id: 0,
                                                                                                        pickKaryawan: false
                                                                                                    })
                                                                                                } else {
                                                                                                    this.setState({
                                                                                                        karyawan_selected,
                                                                                                        karyawan_id: karyawan_selected.value,
                                                                                                        pickKaryawan: true,
                                                                                                        loading_selected: true,
                                                                                                        karyawan: []
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        isClearable={() => {
                                                                                            this.setState({
                                                                                                isClearable: true,
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="12" md="8" lg="12">
                                                                        <div>
                                                                            <div className="card">
                                                                                <hr/>
                                                                                <div
                                                                                    className="card-header text-center"
                                                                                    style={{
                                                                                        border: "none",
                                                                                        fontSize: "14px",
                                                                                        paddingTop: "14px",
                                                                                        paddingBottom: "8px"
                                                                                    }}>
                                                                                    <h4>{i18n.t("forminputgaji")}</h4>
                                                                                </div>
                                                                                <hr/>
                                                                                <div className="card-body">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-xl-12">
                                                                                            <div>
                                                                                                <Row
                                                                                                    className="mb-2">
                                                                                                    <Col
                                                                                                        className="text-center">
                                                                                                        <Label>{i18n.t("jumlahgaji")}</Label>
                                                                                                    </Col>
                                                                                                    <Col
                                                                                                        className="text-center">
                                                                                                        <Label>{i18n.t("tipegaji")}</Label>
                                                                                                    </Col>
                                                                                                    <Col
                                                                                                        className="text-center">
                                                                                                        <Label>{i18n.t("nominalgaji")}</Label>
                                                                                                    </Col>
                                                                                                    <Col
                                                                                                        className="text-center">
                                                                                                        <Label>{i18n.t("totalgaji")}</Label>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                                {this.state.rows.map((item, id) => {
                                                                                                    return (
                                                                                                        <Row>
                                                                                                            <Col>
                                                                                                                <FormGroup>
                                                                                                                    <CurrencyInput
                                                                                                                        name="payroll_waktu_kerja"
                                                                                                                        className="form-control"
                                                                                                                        defaultValue={0}
                                                                                                                        value={item.payroll_waktu_kerja}
                                                                                                                        groupSeparator='.'
                                                                                                                        decimalSeparator=","
                                                                                                                        prefix={getCurrency(currency)}
                                                                                                                        id="payroll_waktu_kerja"
                                                                                                                        onValueChange={(value, name) => {
                                                                                                                            this.handleChangeRows(value, name, id)
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                            <Col>
                                                                                                                <FormGroup>
                                                                                                                    <select
                                                                                                                        name="payroll_type"
                                                                                                                        id="payroll_type"
                                                                                                                        className="form-control"
                                                                                                                        onChange={this.handleChangeType(id)}
                                                                                                                        placeholder="Type Payroll"
                                                                                                                        value={item.payroll_type}
                                                                                                                    >
                                                                                                                        <option value={""}>{i18n.t("tipegaji")}</option>
                                                                                                                        <option value="jam">{i18n.t("jam")}</option>
                                                                                                                        <option value="hari">{i18n.t("hari")}</option>
                                                                                                                        <option value="bulan">{i18n.t("bulan")}</option>
                                                                                                                    </select>
                                                                                                                    {/*<CurrencyInput*/}
                                                                                                                    {/*    style={{backgroundColor: '#f0f0f0'}}*/}
                                                                                                                    {/*    readOnly*/}
                                                                                                                    {/*    className="form-control"*/}
                                                                                                                    {/*/>*/}
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                            <Col>
                                                                                                                <FormGroup>
                                                                                                                    <CurrencyInput
                                                                                                                        name="payroll_amount"
                                                                                                                        className="form-control"
                                                                                                                        defaultValue={0}
                                                                                                                        value={item.payroll_amount}
                                                                                                                        groupSeparator='.'
                                                                                                                        decimalSeparator=","
                                                                                                                        prefix={getCurrency(currency)}
                                                                                                                        id="payroll_amount"
                                                                                                                        onValueChange={(value, name) => {
                                                                                                                            this.handleChangeRows(value, name, id)
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                            <Col
                                                                                                                className="text-center">
                                                                                                                <FormGroup>
                                                                                                                    <CurrencyInput
                                                                                                                        style={{backgroundColor: '#f0f0f0'}}
                                                                                                                        readOnly
                                                                                                                        className="form-control"
                                                                                                                        groupSeparator='.'
                                                                                                                        decimalSeparator=','
                                                                                                                        defaultValue={0}
                                                                                                                        value={item.payroll_total}
                                                                                                                        prefix={getCurrency(currency)}
                                                                                                                        precision={0}
                                                                                                                    />
                                                                                                                </FormGroup>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    );
                                                                                                })}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn ipresens-btn-cancel text-capitalize"
                                                    style={{lineHeight: "18px"}}
                                                    onClick={() => this.setState({
                                                        modalAdd: false,
                                                        karyawan_id: 0,
                                                        karyawan_selected: null,
                                                        rows: [{}]
                                                    })}>
                                                {i18n.t("batalkan")}
                                            </button>
                                            {' '}
                                            <Button
                                                style={{
                                                    border: "1px solid #DDDDDD",
                                                    fontSize: "14px",
                                                    paddingTop: "6px",
                                                    paddingBottom: "6px"
                                                }}
                                                onClick={this.handleAdd.bind(this)}
                                                color="primary"
                                            >
                                                {i18n.t("tambah")}
                                            </Button>
                                        </ModalFooter>
                                    </ModalRs>

                                    : ""
                            }
                            {/*Modal generate Payroll Custom*/}
                            {
                                this.state.modalAddCustom ?
                                    <ModalRs size='lg' style={{ maxWidth: "800px" }} isOpen={this.state.modalAddCustom}
                                             toggle={() => this.setState({
                                                 modalAddCustom: false,
                                                 payrollDone: false,
                                                 tanggal_mulai: "",
                                                 tanggal_selesai: "",
                                                 monthAdd: "",
                                                 yearAdd: "",
                                                 type: ""
                                             })}>
                                        <ModalHeader style={{ backgroundColor: "#FAFDFF", padding: "12px 24px" }}
                                                     toggle={() => this.setState({
                                                         modalAddCustom: false,
                                                         payrollDone: false,
                                                         tanggal_mulai: "",
                                                         tanggal_selesai: "",
                                                         monthAdd: "",
                                                         yearAdd: "",
                                                         type: ""
                                                     })}>
                                            <div className='modal-header-reactstrap' style={{ color: "#38115F" }}>{i18n.t("generatedaftarpayroll")}</div>
                                        </ModalHeader>
                                        <ModalBody>
                                            <Col lg="12">
                                                <Row className="mb-3">
                                                    <Col className="p-0">
                                                        <div className="col-md-3" style={style_add.card_header}>
                                                            <label htmlFor="exampleFormControlInput1"
                                                                   style={style_add.payroll}>
                                                                {i18n.t('pilihtipe')}
                                                            </label >
                                                            <select className="form-control"
                                                                    name="type"
                                                                    value={this.state.type}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        this.handleChange(e);

                                                                        if (value === "custom") {
                                                                            this.setState({
                                                                                tanggal_mulai: "",
                                                                                tanggal_selesai: "",
                                                                                monthAdd: "",
                                                                                yearAdd: ""
                                                                            });
                                                                        }else if (value === "monthly") {
                                                                            this.setState({
                                                                                tanggal_mulai: "",
                                                                                tanggal_selesai: ""
                                                                            });
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        paddingTop: "8px",
                                                                        paddingBottom: "8px"
                                                                    }}
                                                            >
                                                                <option value="">{i18n.t("pilihtipe")}</option>
                                                                <option value="custom">Custom Range</option>
                                                                <option value="monthly">{i18n.t("bulantahun")}</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr/>
                                                {
                                                    this.state.type === "custom" &&
                                                    <Row>
                                                        <Col>
                                                            <div className='row mb-3'>
                                                                <div className="col-md-3"
                                                                     style={style_add.card_header}>
                                                                    <label htmlFor="exampleFormControlInput1" style={style_add.payroll}>
                                                                        {i18n.t("tanggalmulai")}
                                                                    </label>
                                                                    <input style={{
                                                                        fontSize: "12px",
                                                                        paddingTop: "10px",
                                                                        paddingBottom: "10px"
                                                                    }} type="date" name="tanggal_mulai"
                                                                           className="form-control font-edit-value"
                                                                           defaultValue={this.state.tanggal_mulai}
                                                                           onChange={this.handleChange}/>
                                                                </div>
                                                                <div className="col-md-3" style={style_add.card_header}>
                                                                    <label htmlFor="exampleFormControlInput1" style={style_add.payroll}>
                                                                        {i18n.t("tanggalselesai")}
                                                                    </label>
                                                                    <input style={{
                                                                        fontSize: "12px",
                                                                        paddingTop: "10px",
                                                                        paddingBottom: "10px"
                                                                    }} type="date" name="tanggal_selesai"
                                                                           className="form-control font-edit-value"
                                                                           defaultValue={this.state.tanggal_selesai}
                                                                           onChange={this.handleChange}/>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 d-flex flex-nowrap"
                                                                     style={style_add.card_header}>
                                                                    <input
                                                                        type='checkbox'
                                                                        checked={this.state.payrollDone}
                                                                        name="payrollDone"
                                                                        id="payrollDone"
                                                                        onClick={(event) => {
                                                                            this.handleCheckBoxDone(event)
                                                                        }}
                                                                    />
                                                                    <label className='px-2 mb-0' style={style_add.payroll}>
                                                                        {i18n.t('pilihautodone')}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                                {
                                                    this.state.type === "monthly" &&
                                                    <Row>
                                                        <Col>
                                                            <div className='row mb-3'>
                                                                <div className="col-md-3" style={style_add.card_header}>
                                                                    <label
                                                                        htmlFor="exampleFormControlInput1"
                                                                        style={style_add.payroll}
                                                                    >
                                                                        {i18n.t("bulan")}
                                                                    </label>
                                                                    <select name="monthAdd"
                                                                            className="form-control px-2"
                                                                            onChange={this.handleChange}
                                                                            value={this.state.monthAdd}
                                                                            style={{
                                                                                fontSize: "14px",
                                                                                paddingTop: "8px",
                                                                                paddingBottom: "8px"
                                                                            }}>
                                                                        <option value="">{i18n.t("pilihbulan")}</option>
                                                                        <option value="01">{i18n.t("januari")}</option>
                                                                        <option value="02">{i18n.t("februari")}</option>
                                                                        <option value="03">{i18n.t("maret")}</option>
                                                                        <option value="04">{i18n.t("april")}</option>
                                                                        <option value="05">{i18n.t("mei")}</option>
                                                                        <option value="06">{i18n.t("juni")}</option>
                                                                        <option value="07">{i18n.t("juli")}</option>
                                                                        <option value="08">{i18n.t("agustus")}</option>
                                                                        <option value="09">{i18n.t("september")}</option>
                                                                        <option value="10">{i18n.t("oktober")}</option>
                                                                        <option value="11">{i18n.t("november")}</option>
                                                                        <option value="12">{i18n.t("desember")}</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-3" style={style_add.card_header}>
                                                                    <label
                                                                        htmlFor="exampleFormControlInput1"
                                                                        style={style_add.payroll}
                                                                    >
                                                                        {i18n.t('tahun')}
                                                                    </label>
                                                                    <select name="yearAdd"
                                                                            className="form-control"
                                                                            onChange={this.handleChange}
                                                                            value={this.state.yearAdd}
                                                                            style={{
                                                                                fontSize: "14px",
                                                                                paddingTop: "8px",
                                                                                paddingBottom: "8px"
                                                                            }}>
                                                                        <option value="">{i18n.t('pilihtahun')}</option>
                                                                        <option value="2020">2020</option>
                                                                        <option value="2021">2021</option>
                                                                        <option value="2022">2022</option>
                                                                        <option value="2023">2023</option>
                                                                        <option value="2024">2024</option>
                                                                        <option value="2025">2025</option>
                                                                        <option value="2026">2026</option>
                                                                        <option value="2027">2027</option>
                                                                        <option value="2028">2028</option>
                                                                        <option value="2029">2029</option>
                                                                        <option value="2030">2030</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 d-flex flex-nowrap"
                                                                     style={style_add.card_header}>
                                                                    <input
                                                                        type='checkbox'
                                                                        checked={this.state.payrollDone}
                                                                        name="payrollDone"
                                                                        id="payrollDone"
                                                                        onClick={(event) => {
                                                                            this.handleCheckBoxDone(event)
                                                                        }}
                                                                    />
                                                                    <label className='px-2 mb-0' style={style_add.payroll}>
                                                                        {i18n.t('pilihautodone')}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn ipresens-btn-cancel text-capitalize"
                                                    style={{lineHeight: "18px"}}
                                                    onClick={() => this.setState({
                                                        modalAddCustom: false,
                                                        payrollDone: false,
                                                        tanggal_mulai: "",
                                                        tanggal_selesai: "",
                                                        monthAdd: "",
                                                        yearAdd: "",
                                                        type: ""
                                                    })}>
                                                {i18n.t("batalkan")}
                                            </button>
                                            {' '}
                                            <Button
                                                style={{
                                                    border: "1px solid #DDDDDD",
                                                    fontSize: "14px",
                                                    paddingTop: "6px",
                                                    paddingBottom: "6px"
                                                }}
                                                disabled={this.state.type === null}
                                                onClick={() => {
                                                    if(this.state.payrollDone === true) {
                                                        this.addPayrollAutoDone()
                                                    } else {
                                                        this.updatePayroll()
                                                    }
                                                }}
                                                color="primary"
                                            >
                                                Generate
                                            </Button>
                                        </ModalFooter>
                                    </ModalRs>

                                    : ""
                            }

                            {/*Modal update payroll custom*/}
                            {
                                this.state.modalEditCustom ?
                                    <ModalRs size='lg' style={{ maxWidth: "800px" }} isOpen={this.state.modalEditCustom}
                                             toggle={() => this.setState({
                                                 modalEditCustom: false,
                                                 tanggal_mulai: "",
                                                 tanggal_selesai: "",
                                                 monthAdd: "",
                                                 yearAdd: "",
                                                 type: ""
                                             })}>
                                        <ModalHeader style={{ color: "#38115F", backgroundColor: "#FAFDFF", padding: "12px 24px" }}
                                                     toggle={() => this.setState({
                                                         modalEditCustom: false,
                                                         tanggal_mulai: "",
                                                         tanggal_selesai: "",
                                                         monthAdd: "",
                                                         yearAdd: "",
                                                         type: ""
                                                     })}>
                                            <div className='modal-header-reactstrap' style={{ color: "#38115F" }}>{i18n.t("updatedaftarpayroll")}</div>
                                        </ModalHeader>
                                        <ModalBody>
                                            <Col lg="12">
                                                <Row className="mb-3">
                                                    <Col className="col-3">
                                                        <div style={style_add.card_header}>
                                                            <label htmlFor="exampleFormControlInput1" style={style_add.payroll}>
                                                                {i18n.t('pilihtipe')}
                                                            </label>
                                                            <select className="form-control"
                                                                    name="type"
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        this.handleChange(e);

                                                                        if (value === "custom") {
                                                                            this.setState({
                                                                                tanggal_mulai: "",
                                                                                tanggal_selesai: "",
                                                                                monthAdd: "",
                                                                                yearAdd: ""
                                                                            });
                                                                        }else if (value === "monthly") {
                                                                            this.setState({
                                                                                tanggal_mulai: "",
                                                                                tanggal_selesai: ""
                                                                            });
                                                                        }
                                                                    }}
                                                                    value={this.state.type}
                                                                    style={{
                                                                        fontSize: "14px",
                                                                        paddingTop: "8px",
                                                                        paddingBottom: "8px"
                                                                    }}
                                                            >
                                                                <option value="">{i18n.t("pilihtipe")}</option>
                                                                <option value="custom">Custom Range</option>
                                                                <option value="monthly">{i18n.t("bulantahun")}</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr/>
                                                {
                                                    this.state.type === "custom" &&
                                                    <Row>
                                                        <Col className="col-3">
                                                            <div style={style_add.card_header}>
                                                                <label htmlFor="exampleFormControlInput1" style={style_add.payroll}>
                                                                    {i18n.t("tanggalmulai")}
                                                                </label>
                                                                <input style={{
                                                                    fontSize: "12px",
                                                                    paddingTop: "10px",
                                                                    paddingBottom: "10px"
                                                                }} type="date" name="tanggal_mulai"
                                                                       className="form-control font-edit-value"
                                                                       defaultValue={this.state.tanggal_mulai}
                                                                       onChange={this.handleChange}/>
                                                            </div>
                                                        </Col>
                                                        <Col className="col-3">
                                                            <div style={style_add.card_header}>
                                                                <label htmlFor="exampleFormControlInput1" style={style_add.payroll}>
                                                                    {i18n.t("tanggalselesai")}
                                                                </label>
                                                                <input style={{
                                                                    fontSize: "12px",
                                                                    paddingTop: "10px",
                                                                    paddingBottom: "10px"
                                                                }} type="date" name="tanggal_selesai"
                                                                       className="form-control font-edit-value"
                                                                       defaultValue={this.state.tanggal_selesai}
                                                                       onChange={this.handleChange}/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                                {
                                                    this.state.type === "monthly" &&
                                                    <Row>
                                                        <Col>
                                                            <div className='row'>
                                                                <div className="col-md-3" style={style_add.card_header}>
                                                                    <label htmlFor="exampleFormControlInput1" style={style_add.payroll}>
                                                                        {i18n.t("bulan")}
                                                                    </label>
                                                                    <select name="monthAdd"
                                                                            className="form-control px-2"
                                                                            onChange={this.handleChange}
                                                                            value={this.state.monthAdd}
                                                                            style={{
                                                                                fontSize: "14px",
                                                                                paddingTop: "8px",
                                                                                paddingBottom: "8px"
                                                                            }}>
                                                                        <option value="">{i18n.t("pilihbulan")}</option>
                                                                        <option value="01">{i18n.t("januari")}</option>
                                                                        <option value="02">{i18n.t("februari")}</option>
                                                                        <option value="03">{i18n.t("maret")}</option>
                                                                        <option value="04">{i18n.t("april")}</option>
                                                                        <option value="05">{i18n.t("mei")}</option>
                                                                        <option value="06">{i18n.t("juni")}</option>
                                                                        <option value="07">{i18n.t("juli")}</option>
                                                                        <option value="08">{i18n.t("agustus")}</option>
                                                                        <option value="09">{i18n.t("september")}</option>
                                                                        <option value="10">{i18n.t("oktober")}</option>
                                                                        <option value="11">{i18n.t("november")}</option>
                                                                        <option value="12">{i18n.t("desember")}</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-3" style={style_add.card_header}>
                                                                    <label htmlFor="exampleFormControlInput1" style={style_add.payroll}>
                                                                        {i18n.t("tahun")}
                                                                    </label>
                                                                    <select name="yearAdd"
                                                                            className="form-control"
                                                                            onChange={this.handleChange}
                                                                            value={this.state.yearAdd}
                                                                            style={{
                                                                                fontSize: "14px",
                                                                                paddingTop: "8px",
                                                                                paddingBottom: "8px"
                                                                            }}>
                                                                        <option value="">{i18n.t("pilihtahun")}</option>
                                                                        <option value="2020">2020</option>
                                                                        <option value="2021">2021</option>
                                                                        <option value="2022">2022</option>
                                                                        <option value="2023">2023</option>
                                                                        <option value="2024">2024</option>
                                                                        <option value="2025">2025</option>
                                                                        <option value="2026">2026</option>
                                                                        <option value="2027">2027</option>
                                                                        <option value="2028">2028</option>
                                                                        <option value="2029">2029</option>
                                                                        <option value="2030">2030</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button className="btn ipresens-btn-cancel text-capitalize"
                                                    style={{lineHeight: "18px"}}
                                                    onClick={() => this.setState({
                                                        modalEditCustom: false,
                                                        tanggal_mulai: "",
                                                        tanggal_selesai: "",
                                                        monthAdd: "",
                                                        yearAdd: "",
                                                        type: ""
                                                    })}>
                                                {i18n.t("batalkan")}
                                            </button>
                                            {' '}
                                            <Button
                                                style={{
                                                    border: "1px solid #DDDDDD",
                                                    fontSize: "14px",
                                                    paddingTop: "6px",
                                                    paddingBottom: "6px"
                                                }}
                                                disabled={this.state.type === null}
                                                onClick={() => {
                                                    this.loadUpdateGaji()
                                                    this.updatePayroll()
                                                }}
                                                color="primary"
                                            >
                                                Update
                                            </Button>
                                        </ModalFooter>
                                    </ModalRs>

                                    : ""
                            }
                            {/*modal Slip Gaji Karyawan*/}
                            {
                                this.state.modalSlip ?
                                    <ModalRS size="lg" isOpen={this.state.modalSlip} toggle={() => this.setState({ modalSlip: false })}>
                                        <ModalHeader>{i18n.t("slipgajikaryawan")}</ModalHeader>
                                        <ModalBody id="content-list">
                                            <div className="">
                                                <div>
                                                    <div className="row bg-blue-dark p-3 text-white border-bottom-blue"
                                                         style={{
                                                             backgroundImage: `url(${presensiHeaderModalBG})`,
                                                             backgroundRepeat: 'no-repeat',
                                                             backgroundPosition: 'left center'
                                                         }}>
                                                        <div className="col-4 d-flex align-items-center">
                                                            <img src={presensiWhiteLogo} alt="Logo"
                                                                 style={{height: '26px'}}/>
                                                        </div>
                                                        <div className="col-8 text-right">
                                                            <b>{moment(this.state.dataSlip.payroll_month).format('MMMM')} {this.state.dataSlip.payroll_year}</b><br/>
                                                            <span>{this.state.perusahaan.perusahaan_nama}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-3 border-btm-gray">
                                                    <div className="col">
                                                        <div className="text-muted font-weight-bold mb-2">{i18n.t("dari")}</div>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <img
                                                                    className="align-self-center pull-right rounded-circle blur-up lazyloaded"
                                                                    src={this.state.perusahaan.perusahaan_foto !== null ? IMG_HOST + 'mitra/' + this.state.perusahaan.perusahaan_foto : DEFAULT_IMG}
                                                                    alt="header-user" style={{
                                                                    width: "40px",
                                                                    height: "40px",
                                                                    objectFit: "cover"
                                                                }}/>
                                                            </div>
                                                            <div>
                                                                <div
                                                                    className="text-blue-dark font-weight-bold fs-14">{this.state.perusahaan.perusahaan_nama}</div>
                                                                <div
                                                                    className="text-muted">{this.state.perusahaan.perusahaan_alamat}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="text-muted font-weight-bold mb-2">{i18n.t("untuk")}</div>
                                                        <div>
                                                            <div className="text-blue-dark font-weight-bold fs-14">{this.state.karyawan.karyawan_nama}</div>
                                                            <div className="text-muted">{this.state.karyawan.divisi.divisi_name} - {this.state.karyawan.jabatan.jabatan_nama}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-6">
                                                        <div className="text-muted font-weight-bold mb-2">{i18n.t("pendapatan")}
                                                        </div>
                                                        <div className="pl-3 pr-1">
                                                            <div
                                                                className="row align-items-center justify-content-between pb-1">
                                                                <div>
                                                                    {i18n.t("gajipokok")}
                                                                </div>
                                                                <div className="text-blue-dark">
                                                                    {format({
                                                                        prefix: getCurrency(currency),
                                                                    integerSeparator: "."
                                                                })(this.state.gaji)}
                                                                </div>
                                                            </div>
                                                            {this.state.kompensasi.map((item, index) => {
                                                                return (
                                                                    <div
                                                                        className="row align-items-center justify-content-between pb-1">
                                                                        <div>
                                                                            {item.payroll_term.p_term_name}
                                                                        </div>
                                                                        <div className="text-blue-dark">
                                                                            {format({
                                                                            prefix: getCurrency(currency),
                                                                            integerSeparator: "."
                                                                        })(item.p_kompensasi_total)}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div
                                                                className="row align-items-center justify-content-between pt-2"
                                                                style={{borderTop: "1px dotted #DDDDDD"}}>
                                                                <div>
                                                                    <strong>Total</strong>
                                                                </div>
                                                                <div>
                                                                    <strong>{format({
                                                                        prefix: getCurrency(currency),
                                                                        integerSeparator: "."
                                                                    })(this.state.penghasilan)}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="text-muted font-weight-bold mb-2">{i18n.t("potongan")}</div>
                                                        <div className="pr-3 pl-3">
                                                            {this.state.potongan.map((item, index) => {
                                                                return (
                                                                    <div
                                                                        className="row align-items-center justify-content-between pb-1">
                                                                        <div>
                                                                            {item.payroll_term.p_term_name}
                                                                        </div>
                                                                        <div className="text-blue-dark">
                                                                            {format({
                                                                            prefix: getCurrency(currency),
                                                                            integerSeparator: "."
                                                                        })(item.p_potongan_total)}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div
                                                                className="row align-items-center justify-content-between pt-2"
                                                                style={{borderTop: "1px dotted #DDDDDD"}}>
                                                                <div>
                                                                    <strong>Total</strong>
                                                                </div>
                                                                <div>
                                                                    <strong>{format({
                                                                        prefix: getCurrency(currency),
                                                                        integerSeparator: "."
                                                                    })(this.state.dataSlip.payroll_potongan)}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bg-light mt-2 px-4 py-2">
                                                    <div
                                                        className="row align-items-center justify-content-between fs-14 font-weight-bold text-blue-dark">
                                                        <div>
                                                            <strong>TAKE HOME PAY</strong>
                                                        </div>
                                                        <div>
                                                            <strong>{format({
                                                                prefix: getCurrency(currency),
                                                                integerSeparator: "."
                                                            })(this.state.dataSlip.payroll_total)}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="text-center text-secondary mt-2">
                                                        <span>Presensi.co.id @2020</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button className={'btn btn-success'} onClick={this.printDocumentFour}>Download</Button>
                                            <Button className={'btn btn-danger'} onClick={() => this.setState({ modalSlip: false })}>{i18n.t("batal")}</Button>
                                        </ModalFooter>
                                    </ModalRS>
                                    :
                                    ""
                            }
                            {this.state.modalExport &&
                                <ModalRs size='lg' isOpen={this.state.modalExport}
                                         toggle={() => this.setState({modalExport: !this.state.modalExport})}>
                                    <ModalHeader style={{backgroundColor: "#FAFDFF"}}
                                                 toggle={() => this.setState({modalExport: !this.state.modalExport})}>Export
                                        Excel</ModalHeader>
                                    <ModalBody>
                                        <div className='row'>
                                            <div className='col'>
                                                <div className='d-flex flex-row warning-info px-2 mb-2'>
                                                    <div className='px-0 mr-1'><img src={WarningIcon} alt=""/></div>
                                                    <div className='px-0' style={{fontSize: "14px"}}>
                                                        {i18n.t("filterberdasarkantanggal")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-2' style={{width: "780px"}}>
                                            <div className='col-md-6'>
                                                <InputGroup className="btn-tgl-exp-payroll">
                                                    <InputGroupAddon addonType="prepend">
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon icon={faCalendarAlt}/>
                                                        </div>
                                                    </InputGroupAddon>

                                                    <Input bsSize="sm" className='font-edit-value'
                                                           style={{paddingTop: "8px", paddingBottom: "8px"}}
                                                           onFocus={() => this.setState({reactDateRange: true})}
                                                           placeholder={i18n.t("filtertanggal")} value={this.state.valueDate}/>
                                                </InputGroup>
                                            </div>
                                        </div>
                                        {this.state.reactDateRange === true &&
                                            <div className='shadow d-flex flex-column' style={{maxWidth: "388px"}}>
                                                <div>
                                                    <DateRange
                                                        onBlur={() => this.setState({reactDateRange: false})}
                                                        ranges={[selectionRange]}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                start_date: e.selection.startDate,
                                                                end_date: e.selection.endDate,
                                                                exportStartDate: moment(e.selection.startDate).format("YYYY-MM-DD"),
                                                                exportEndDate: moment(e.selection.endDate).format("YYYY-MM-DD")
                                                            })
                                                        }
                                                        }
                                                    />
                                                </div>
                                                <div>
                                                    <div className='d-flex justify-content-end pr-2'>
                                                        <button className='btn ipresens-btn-cancel text-capitalize mb-2'
                                                                style={{fontSize: "14px", fontWeight: 500}}
                                                                onClick={() => this.setState({reactDateRange: false})}>{i18n.t("batal")}
                                                        </button>
                                                        <button
                                                            className='btn ipresens-btn-color mb-2 ml-2 text-capitalize'
                                                            style={{
                                                                fontSize: "14px",
                                                                fontWeight: 500,
                                                                lineHeight: "24px"
                                                            }} onClick={
                                                            () => this.setState({
                                                                valueDate: this.state.exportStartDate + " - " + this.state.exportEndDate,
                                                                reactDateRange: false
                                                            })
                                                        }>{i18n.t("terapkan")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.valueDate !== "" &&
                                            <>
                                                <div>
                                                    <div className='row'>
                                                        <div className='col-auto d-flex justify-content-start mb-2'>
                                                            <div className='btn-group width-filter'>
                                                                <button
                                                                    className={'btn text-capitalize custom-btn-group filter-masuk btn-filter-exp-payroll' + (this.state.filterExport === "new" ? ' active-custom-btn-group' : '' ? " custom-btn-group" : "")}
                                                                    onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "new" ? '' : 'new',}))}>{i18n.t("baru")}
                                                                </button>
                                                                <button
                                                                    className={'btn text-capitalize custom-btn-group filter-masuk btn-filter-exp-payroll' + (this.state.filterExport === "finish" ? ' active-custom-btn-group' : '' ? " custom-btn-group" : "")}
                                                                    onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "finish" ? '' : 'finish',}))}>{i18n.t("selesai")}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr style={{marginBottom: "40px"}}/>
                                                    <div className='row px-2'>
                                                        <div className='col-md-4 px-1'>
                                                            <button
                                                                className={'btn btn-block btn-primary px-3 py-2 text-capitalize mb-2'}
                                                                style={{fontSize: "14px", borderRadius: "11px"}}
                                                                onClick={this.exportPayroll}>Export Data Payroll
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </ModalBody>
                                    <ModalFooter style={{backgroundColor: "#F5F5F5", borderTop: 0}}>
                                        <button className="btn ipresens-btn-cancel text-capitalize"
                                                onClick={() => this.setState({modalExport: !this.state.modalExport})}>
                                            {i18n.t("batalkan")}
                                        </button>
                                    </ModalFooter>
                                </ModalRs>
                            }
                            {/*modal edit clockout*/}
                            {
                                this.state.modalClockout ?
                                    <ModalRs size="lg" isOpen={this.state.modalClockout}
                                             style={{maxWidth: '900px', width: '100%'}}>
                                        <ModalHeader toggle={() => this.setState({modalClockout: false})}>{i18n.t("ubahabsenpulangkosong")}</ModalHeader>
                                        <ModalBody>
                                            {this.state.data_no_clockout.map((item, index) => {
                                                return (
                                                    <div>
                                                        {index != 0 ? <hr/> : null}
                                                        <div className="row">
                                                            <div className="col-6 mb-2">
                                                                <div className="text-muted font-weight-bold mb-1">{i18n.t("namakaryawan")}
                                                                </div>
                                                                <div
                                                                    className="text-blue-dark font-weight-bold fs-14">{item.karyawan.karyawan_nama}</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div
                                                                    className="text-muted font-weight-bold mb-1">{i18n.t("tanggalabsen")}
                                                                </div>
                                                                <div
                                                                    className="text-blue-dark font-weight-bold fs-14">{moment(item.tanggal).format('ll')}</div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="text-muted font-weight-bold mb-1">{i18n.t("jammasuk")}
                                                                </div>
                                                                <div
                                                                    className="text-blue-dark font-weight-bold fs-14">{item.jam_masuk_absen}</div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="text-muted font-weight-bold mb-1">{i18n.t("jamkeluar")}
                                                                </div>
                                                                <div>
                                                                    <TimeInput
                                                                        placeholder={i18n.t("jamkeluar")+"..."}
                                                                        style={{fontSize: "12px"}}
                                                                        initTime={null}
                                                                        className='s-input -time form-control px-3 py-2'
                                                                        onTimeChange={(event) => {
                                                                            const value = event;
                                                                            this.handleClockoutTime(item.id_absensi, value)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button onClick={() => this.setState({modalClockout: false})}
                                                    className={'btn btn-danger'}>{i18n.t("tutup")}</Button>
                                            <Button onClick={() => {
                                                this.setClockoutTime()
                                            }} disabled={this.state.loading}
                                                    className={'btn btn-success'}>{i18n.t("simpan")}</Button>
                                        </ModalFooter>
                                    </ModalRs>

                                    : ""
                            }

                            <ModalRs size="lg" style={{maxWidth: "1000px"}} isOpen={this.state.modalHistory}
                                     toggle={() => this.setState({modalHistory: false})}>
                                <ModalHeader style={{backgroundColor: "#FAFDFF"}}
                                             toggle={() => this.setState({modalHistory: false})}>
                                    <div className='modal-header-reactstrap'>{i18n.t("riwayatperubahan")}</div>
                                </ModalHeader>
                                <ModalBody>
                                    <div className="list-group">
                                        <div>
                                            {this.state.loadingHistory && (
                                                <div style={{textAlign: 'center', margin: '10px auto'}}>
                                                    <Spin
                                                        size="large"
                                                        tip="Loading..."
                                                        style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            {this.state.historyPayroll.length !== 0 && (
                                                <div>
                                                    <Row>
                                                        <Col xs={0} md={0} className="text-left"
                                                             style={{marginLeft: '20px'}}>
                                                            <h4>User</h4>
                                                        </Col>
                                                        <Col xs={0} md={0} className="text-left"
                                                             style={{marginRight: '20px'}}>
                                                            <h4>{i18n.t("aktivitas")}</h4>
                                                        </Col>
                                                    </Row>

                                                    {this.state.historyPayroll.map((item, index) => {
                                                        return (
                                                            <div key={index} style={{marginBottom: '20px'}}>
                                                                <Row>
                                                                    <Col xs={12} md={6} className="text-left">
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            alignItems: "center",
                                                                            paddingTop: '16px',
                                                                            paddingBottom: '16px',
                                                                            paddingRight: '12px',
                                                                            paddingLeft: '12px'
                                                                        }}>
                                                                            <div style={{marginRight: '16px'}}>
                                                                                <img
                                                                                    src={DEFAULT_IMG}
                                                                                    alt=""
                                                                                    style={{
                                                                                        width: '48px',
                                                                                        marginBottom: '25px',
                                                                                        height: '48px'
                                                                                    }}/>
                                                                            </div>
                                                                            <div>
                                                                                <h6 className="mb-0 font-13" style={{
                                                                                    fontWeight: 500,
                                                                                    fontSize: '16px',
                                                                                    lineHeight: '24px',
                                                                                }}>
                                                                                    {item.p_user_name} | {item.level_name}
                                                                                </h6>
                                                                                <p className="m-0 font-12">
                                                                                    <span
                                                                                        className="col-green font-weight-bold">
                                                                                        {moment(item.created_at).format('lll')}
                                                                                    </span>
                                                                                </p>
                                                                                <div style={{marginTop: 12}}>
                                                                                    {item.history_status === 'created' ? (
                                                                                        <div className="m-0" style={{
                                                                                            border: '1px solid #000',
                                                                                            borderRadius: '25px',
                                                                                            width: '50%',
                                                                                            color: '#057CE4',
                                                                                            borderColor: '#057CE4',
                                                                                        }}>
                                                                                            <div
                                                                                                className="d-flex justify-content-center"
                                                                                                style={{
                                                                                                    paddingTop: '6px',
                                                                                                    paddingBottom: '6px'
                                                                                                }}>
                                                                                                {i18n.t("dibuat")}
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div
                                                                                            className="m-0"
                                                                                            style={{
                                                                                                border: '1px solid #000',
                                                                                                borderRadius: '25px',
                                                                                                width: '50%',
                                                                                                color: '#E6462E',
                                                                                                borderColor: '#E6462E',
                                                                                            }}>
                                                                                            <div
                                                                                                className="d-flex justify-content-center"
                                                                                                style={{
                                                                                                    paddingTop: '6px',
                                                                                                    paddingBottom: '6px'
                                                                                                }}>
                                                                                                {i18n.t("diubah")}
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                        {item.history_status !== 'created' && (
                                                                            <div>
                                                                                {item.parent_history ? item.parent_history.payroll_kompensasi !== item.payroll_kompensasi && (
                                                                                    <div>
                                                                                        <h6 className="mb-3 font-13 text-left"
                                                                                            style={{
                                                                                                fontWeight: 500,
                                                                                                fontSize: '16px',
                                                                                                lineHeight: '24px'
                                                                                            }}>
                                                                                            {i18n.t("kompensasi")} : {i18n.t("dari")} {' '}
                                                                                            {item.parent_history ? item.parent_history.payroll_kompensasi : 'Kosong'}{' '}
                                                                                            <img
                                                                                                src={ArrowRight}
                                                                                                alt=""
                                                                                                style={{
                                                                                                    width: '20x',
                                                                                                    marginBottom: '3px',
                                                                                                    height: '20px'
                                                                                                }}
                                                                                            /> {i18n.t("jadi")} : {item.payroll_kompensasi ? item.payroll_kompensasi : ''}
                                                                                        </h6>
                                                                                    </div>
                                                                                ) : null}
                                                                                {item.parent_history ? item.parent_history.payroll_potongan !== item.payroll_potongan && (
                                                                                    <div>
                                                                                        <h6 className="mb-3 font-13 text-left"
                                                                                            style={{
                                                                                                fontWeight: 500,
                                                                                                fontSize: '16px',
                                                                                                lineHeight: '24px'
                                                                                            }}>
                                                                                            {i18n.t("payrollpotongan")} : {i18n.t("dari")} {' '}
                                                                                            {item.parent_history ? item.parent_history.payroll_potongan : 'Kosong'}{' '}
                                                                                            <img
                                                                                                src={ArrowRight}
                                                                                                alt=""
                                                                                                style={{
                                                                                                    width: '20x',
                                                                                                    marginBottom: '3px',
                                                                                                    height: '20px'
                                                                                                }}
                                                                                            /> {i18n.t("jadi")}: {item.payroll_potongan ? item.payroll_potongan : ''}
                                                                                        </h6>
                                                                                    </div>
                                                                                ) : null}
                                                                                {item.parent_history ? item.parent_history.payroll_total !== item.payroll_total && (
                                                                                    <div>
                                                                                        <h6 className="mb-3 font-13 text-left"
                                                                                            style={{
                                                                                                fontWeight: 500,
                                                                                                fontSize: '16px',
                                                                                                lineHeight: '24px'
                                                                                            }}>
                                                                                            {i18n.t("payrolltotal")} : {i18n.t("dari")} {' '}
                                                                                            {item.parent_history ? item.parent_history.payroll_total : 'Kosong'}{' '}
                                                                                            <img
                                                                                                src={ArrowRight}
                                                                                                alt=""
                                                                                                style={{
                                                                                                    width: '20x',
                                                                                                    marginBottom: '3px',
                                                                                                    height: '20px'
                                                                                                }}
                                                                                            /> {i18n.t("jadi")}: {item.payroll_total ? item.payroll_total : ''}
                                                                                        </h6>
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter style={{backgroundColor: "#F5F5F5"}}>
                                    <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                        {this.state.historyPayroll.length !== 0 &&
                                            <>
                                                <div className='col font-14' style={{
                                                    padding: "auto",
                                                    margin: "auto",
                                                    fontWeight: 500,
                                                    lineHeight: "24px"
                                                }}>{i18n.t("menampilkan")} {this.state.historyPayroll.length === 0 || this.state.historyPayroll.length === null ? "0" : this.state.historyPayroll.length} {i18n.t("dari")} {this.state.totalDataHistory} data
                                                </div>
                                                <div className='col'>
                                                <ReactPaginate
                                                        previousLabel={<img src={ArrowLeft} alt=''/>}
                                                        nextLabel={<img src={ArrowRight} alt=''/>}
                                                        pageCount={this.state.pageCountHistory}
                                                        forcePage={this.state.currentPageHistory}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={this.handlePageHistory}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link custom-page-link'}
                                                        containerClassName={'pagination my-2'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link custom-page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link custom-prev-next-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link custom-prev-next-link'}
                                                        activeClassName={'active custom-active'}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </ModalFooter>
                            </ModalRs>

                        </div>
                    </div>
                    <Potongan
                        potongan={this.state.potongan}
                        visiblePot={this.state.visiblePot}
                        id={this.state.karyawan_id}
                        rows={this.state.rows}
                        dataTermPotongan={this.state.data_term_potongan}
                        handleChangeStock={this.handleChangeStock}
                        potonganModal={this.potonganModal}
                        handlePotonganType={this.handlePotonganType}
                        handlePotonganAmount={this.handlePotonganAmount}
                        handleSubmitPotongan={this.handleSubmitPotongan}
                        removeClick={this.potonganDelete}
                        request={this.props.request}
                    />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

export default connect(mapStateToProps)(List_payroll);
