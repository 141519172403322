import React, { Component } from 'react'
import Swal from "sweetalert2";
import {Drawer, Form} from "antd";
import i18n from "i18next";
import Select from "react-select";

class AddInventaris extends Component {
    constructor() {
        super();
        this.state = {
            inventaris: [],
            inventaris_name: '',
            inventaris_id: '',
            tanggal_peminjaman: '',
            loading: false,
            inventaris_loading: false,
            inventaris_selected: null
        }
        this.handleChange = this.handleChange.bind(this)
        this.searchInventaris = this.searchInventaris.bind(this)
        this.setInventaris = this.setInventaris.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    setInventaris(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            inventaris_id: value,
        })
    }

    searchInventaris(){
        let form = {
            inventaris_name: this.state.inventaris_name
        }
        this.props.request("inventaris/search", form, "POST")
            .then((response)=> {
                if (response.success){
                    this.setState({
                        inventaris: response.data,
                        inventaris_loading: false
                    })
                }
            })
    }

    loadDataInventaris() {
        let form = {}
        this.props.request('inventaris/list', form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        inventaris: response.data.data,
                        loading: false
                    })
                }
            })
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        let form = {
            karyawan: this.props.id,
            inventaris_id: this.state.inventaris_id,
            tanggal_peminjaman: this.state.tanggal_peminjaman,
        }
        this.props.request("inventaris/add_inv_karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        inventaris_name: '',
                        inventaris_id: '',
                    })
                    this.props.changeState({
                        add_visible: false,
                        data: [],
                        isLoading: true,
                        loading: true,
                    }, () => {
                        this.props.loadData()
                    })
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false,
                    tanggal_peminjaman: '',
                    inventaris_id: 0,
                    inventaris_selected: null,
                })
            });
    }

    componentDidMount() {
        this.loadDataInventaris()
    }

    render() {
        return (
            <Drawer
                title={i18n.t('pinjaminventaris')}
                width={350}
                onClose={() => {
                    this.props.changeState({add_visible: false});
                }}
                visible={this.props.add_visible}
                bodyStyle={{paddingBottom: 80}}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button className="btn ipresens-btn-cancel"
                                onClick={() => {
                                    this.props.changeState({add_visible: false});
                                }}
                                style={{marginRight: 8}}>
                            {i18n.t('batal')}
                        </button>
                        <button onClick={this.handleSubmit} disabled={this.state.loading}
                                className="btn ipresens-btn-color">
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> Loading</>
                                :
                                <a>{i18n.t('simpan')}</a>
                            }
                        </button>
                    </div>
                }>
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>{i18n.t('namainventaris')}</label>
                        <Select
                            placeholder={i18n.t('namainventaris')}
                            backspaceRemoves={true} deleteRemoves={true}
                            styles={{paddingLeft: "0px"}}
                            components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                            value={this.state.inventaris_selected}
                            // onInputChange={(val) => this.setState({inventaris_selected: val}, () => this.setState({inventaris_loading: true}))}
                            options={
                                this.state.inventaris != null ? (
                                        this.state.inventaris.map((item, index) => (
                                            {
                                                key: index,
                                                value: item.inventaris_id,
                                                label: item.inventaris_name,
                                            }
                                        ))
                                    )
                                    : ("")
                            }
                            onChange={
                                (inventaris_selected) => {
                                    if (!inventaris_selected) {
                                        this.setState({
                                            inventaris_selected: '',
                                            inventaris_id: 0,
                                        })
                                    } else {
                                        this.setState({
                                            inventaris_selected,
                                            inventaris_id: inventaris_selected.value,
                                        })
                                    }
                                }
                            }
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">{i18n.t('tanggalpeminjamaninventaris')}</label>
                        <input name="tanggal_peminjaman" value={this.state.tanggal_peminjaman}
                               className="form-control" onChange={this.handleChange}
                               type="date" required=""/>
                    </div>
                </Form>
            </Drawer>
        )
    }
}

export default AddInventaris