import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {Empty, Spin, Tooltip, Upload} from 'antd';
import Paging from "../../../components/paging";
import {Button, ButtonGroup, Card, CardBody, Col, Input, InputGroup, InputGroupAddon, Modal, Row} from 'reactstrap';
import i18n from "i18next";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import {API_HOST, DEFAULT_IMG, IMG_HOST, MAIN_HOST} from "../../../helper/host";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import moment from "moment";
import { pageNumber } from "../../../functions/functionGeneral";
import format from "format-number";
import { clear_auth, set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import SearchIcon from "../../../assets/icons/search.svg";
import FilterIcon from "../../../assets/icons/filter.svg";
import RefreshIcon from "../../../assets/icons/refresh.svg";
import ArrowUpIcon from "../../../assets/icons/arrow-up.svg";
import ArrowDownIcon from "../../../assets/icons/arrow-down.svg";
import {Link} from "react-router-dom";
import ElipsisIcon from "../../../assets/icons/ellipsis-vertical.svg";
import ReactPaginate from "react-paginate";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg";
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Modal as ModalRs} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WarningIcon from '../../../assets/icons/warning-icon.svg';
import { faCalendarAlt, } from '@fortawesome/free-solid-svg-icons';
import { DateRange } from 'react-date-range';
import { BACKEND_API_HOST } from "../../../helper/host";
import DownloadIcon from '../../../assets/icons/download-white.svg';
import EmptyDataImage from "../../../assets/images/data-kosong.png";
import ArrowRight from "../../../assets/icons/arrow-right.svg";
import ArrowLeft from "../../../assets/icons/arrow-left.svg";
import PlusIcon from "../../../assets/icons/plus-white.svg";
import Select from "react-select";
import ImportIcon from "../../../assets/icons/import-icon-blue-light.svg";
import ImgCrop from "antd-img-crop";
import {getCurrency} from "../../../helper/money";


export class List_klaim_beban extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            karyawan: [],
            data: [],
            datakaryawan: [],
            datakar: [],
            dataDetail: [],
            keyword: "",
            nominal: "",
            status: "",
            k_beban_id: "",
            beban_id: "",
            note: "",
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            guide: false,
            modalOpen: false,
            openModal: false,
            filter: false,
            exportStartDate: "",
            exportEndDate: "",
            reactDateRange: false,
            valueDate: "",
            modalexport: false,
            modalManagement: false,
            start_date: new Date(),
            end_date: new Date(),
            cutiStartDate: '',
            cutiEndDate: '',
            approvedData: "",
            rejectData : "",
            filterExport : "",
            buttonFilter : "",
            slice_karyawan_nama: true,
            dataHistory: [],
            modalHistory: false,
            loadingHistory: false,
            currentPageHistory: 0,
            karyawan_selected: null,
            karyawan_loading: false,
            id_karyawan: 0,
            // tanggal_pengajuan:(new Date()).toISOString().substr(0, 10),
            tanggal_pengajuan: "",
            fileList: [],
            gambar: '',
            modalDetail: false,
            karyawan_id     : "",
            karyawan_nama   : "",
            karyawan_foto   : "",
            k_beban_nominal  : "",
            k_beban_note  : "",
            k_beban_photo  : "",
            k_beban_date  : "",
            k_beban_desc     : "",
            k_beban_status     : "",
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.reject = this.reject.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.exportKbeban = this.exportKbeban.bind(this)
        this.handleReject = this.handleReject.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.searchKaryawan = this.searchKaryawan.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter(event) {
        this.setState({
            keyword: '',
            nominal: '',
            status: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    sortabledate = (val) => {
        const copyArray = this.state.data

        copyArray.sort((a, b) => {
            return val === 0 ? new Date(a.k_beban_date) - new Date(b.k_beban_date) : new Date(b.k_beban_date) - new Date(a.k_beban_date);
        });

        this.setState({
            data: copyArray
        })
    }

    approve = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('accklaimbeban'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        dataKasbon: [],
                        loading: true
                    })
                    let form = {
                        id: this.state.k_beban_id
                    }
                    this.props.request("beban/success", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('accklaimbebanberhasil'),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                            }
                            this.loadData()
                        });
                }
            })
    }

    reject = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('tolakklaimbeban'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.handleReject();
                }
            })
    }

    handleReject() {
        let form = {
            id: this.state.k_beban_id,
            note: this.state.note
        }
        this.props.request("beban/cancel", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        modalOpen: false,
                        beban_id: "",
                        note: ""
                    }, () => {
                        Swal.fire({
                            title: i18n.t('berhasil'),
                            text: i18n.t('tolakklaimbebanberhasil'),
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1200,
                        })
                    })


                }
                this.loadData()
            });
    }

    loadData() {
        let form = {
            keyword: this.state.keyword,
            nominal: this.state.nominal,
            status: this.state.status,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("beban/list-beban", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;

        this.setState({
            data: [],
            loading: true,
            modalexport: true,
            exportStartDate: start,
            exportEndDate: end,
            filterExport: true,
        }, () => {
            this.loadData()
        });
    }
    exportKbeban() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'beban/export?token=' + AuthKey + '&KbebanStartDate=' + this.state.exportStartDate + '&KbebanEndDate=' + this.state.exportEndDate + "&filterBeban=" + this.state.filterExport, "_blank")
    }

    handleCancel = e => {
        this.setState({
            modalexport: false,
            modalManagement: false,
            modalimport: false,
            valueDate: "",
            exportStartDate: "",
            exportEndDate: "",
            start_date: new Date(),
            end_date: new Date()
        });
    };

    loadDataKaryawan() {
        let form = {}
        this.props.request("beban/list-karyawan", form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        loading: false,
                        karyawan_loading: false,
                    })
                }
                this.setState({
                    loading: false,
                    karyawan_loading: false,
                })
            })
    }


    componentDidMount() {
        this.loadData()
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    searchKaryawan() {
        let form = {
            karyawan_name: this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        karyawan_loading: false
                    })
                }
            })
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.k_beban_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("beban/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            k_beban_id: ''
                        });
                    }
                });
        })
    }

    simpanKlaimbeban = () => {
        if (this.state.karyawan_selected?.value === undefined) {
            Swal.fire({
                title: i18n.t('gagal'),
                text: i18n.t('validasikaryawan'),
                icon: 'warning',
                confirmButtonText: 'OK'
            });
        } else if(!this.state.tanggal_pengajuan) {
            Swal.fire({
                title: i18n.t('gagal'),
                text: i18n.t('validasitglpengajuan'),
                icon: 'warning',
                confirmButtonText: 'OK'
            });
        } else if(!this.state.nominal) {
            Swal.fire({
                title: i18n.t('gagal'),
                text: i18n.t('validasinominal'),
                icon: 'warning',
                confirmButtonText: 'OK'
            });
        } else {
            this.setState({
                loading: true,
            });

            let form = {
                tanggal_pengajuan: this.state.tanggal_pengajuan,
                nominal: this.state.nominal,
                deskripsi: this.state.deskripsi,
                note: this.state.catatan,
                id_kar: this.state.karyawan_id || this.state.karyawan_selected?.value,
                gambar: this.state.gambar,
            };

            this.props.request("beban/add", form, 'POST')
                .then((response) => {
                    if (response.success) {
                        this.setState({
                            modalAdd: false,
                            karyawan_selected: null,
                            tanggal_pengajuan: '',
                            nominal: '',
                            deskripsi: '',
                            note: '',
                            fileList: [],
                            gambar: ''
                        });
                        this.loadData();
                        Swal.fire({
                            title: i18n.t('berhasil'),
                            text: i18n.t('tambahklaimbebanberhasil'),
                            icon: 'success'
                        });
                    } else {
                        Swal.fire({
                            title: i18n.t('gagal'),
                            text: i18n.t('tambahklaimbebangagal'),
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        });
                    }
                }, () => this.loadData());
        }

    };




    compareBy(key) {
        return function (a, b) {
            if (a[key] < (b[key])) return -1;
            if (a[key] > (b[key])) return 1;
            return 0;
        };
    }

    sortablestatus = (val) => {
        const copyArray = this.state.data
        if (val === 0) {
            copyArray.sort(this.compareBy("k_beban_status"));
        } else {
            copyArray.reverse();
        }
        this.setState({
            data: copyArray
        })
    }

    render() {
        const selectionRange = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            key: 'selection',
        }
        const style_add = {
            font_header: {
                "fontWeight": 700,
                "fontSize": "18px",
                "lineHeight": "27px",
                "color": "#143B5E"
            },
            font_label: {
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "24px",
                "color": "#143B5E"
            },
            font_time: {
                "padding": "16px",
                "textAlign": "center",
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "24px",
                "color": "#143B5E"
            },
            input: {
                "padding": "16px",
                "borderRadius": "8px",
                "fontWeight": 500,
                "fontSize": "14px",
                "lineHeight": "18px",
                "color": "#143B5E"
            },
            input_select: {
                "padding": "16px",
                "borderRadius": "8px",
                "fontWeight": 500,
                "fontSize": "14px",
                "lineHeight": "18px",
                "color": "#143B5E",
                "marginTop": "30px"
            },
            select: {
                "padding": "16px",
                "borderRadius": "8px",
                "fontWeight": 500,
                "fontSize": "14px",
                "lineHeight": "18px",
                "color": "#143B5E",
                "width": "100%"
            }
        }
        let no = 0
        const steps = [
            {
                selector: '.daftar-izin',
                content: i18n.t('stepklaimbeban'),
            },
        ];
        const currency = this.props.user.perusahaan.perusahaan_currency;
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Pengajuan Klaim beban") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t('daftarpengajuanklaimbeban')} - Presensi</title>
                        <meta name="description" content={i18n.t('daftarpengajuanklaimbeban')} />
                    </Helmet>
                    <Breadcrumb title={i18n.t('daftarpengajuanklaimbeban')} parent={i18n.t('pengajuan')} setGuide={() => { this.setState({ guide: true }) }} />
                    <ModalRs size='lg' isOpen={this.state.modalexport} toggle={this.handleCancel}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={this.handleCancel}>Export Excel</ModalHeader>
                        <ModalBody>
                            <div className='row'>
                                <div className='col'>
                                    <div className='d-flex flex-row warning-info px-2 mb-2'>
                                        <div className='px-0 mr-1'><img src={WarningIcon} alt="" /></div>
                                        <div className='px-0' style={{ fontSize: "14px" }}>
                                            {i18n.t('filtertanggal')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2' style={{ width:"780px" }}>
                                <div className='col-md-6'>
                                    <InputGroup className={"form-tgl-kbeban"}>
                                        <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                            </div>
                                        </InputGroupAddon>
                                        <Input bsSize="sm" className='font-edit-value' style={{ paddingTop: "8px", paddingBottom: "8px" }} onFocus={() => this.setState({ reactDateRange: true })} placeholder={i18n.t('filterpilihtanggal')} value={this.state.valueDate} />
                                    </InputGroup>
                                </div>
                            </div>
                            {this.state.reactDateRange === true &&
                                <div className='shadow d-flex flex-column' style={{ maxWidth: "388px" }}>
                                    <div>
                                        <DateRange
                                            onBlur={() => this.setState({ reactDateRange: false })}
                                            ranges={[selectionRange]}
                                            onChange={(e) => {
                                                this.setState({
                                                    start_date: e.selection.startDate,
                                                    end_date: e.selection.endDate,
                                                    exportStartDate: moment(e.selection.startDate).format("YYYY-MM-DD"),
                                                    exportEndDate: moment(e.selection.endDate).format("YYYY-MM-DD")
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-end pr-2'>
                                            <button className='btn ipresens-btn-cancel text-capitalize mb-2' style={{ fontSize: "14px", fontWeight: 500 }} onClick={() => this.setState({ reactDateRange: false })}>{i18n.t('batal')}</button>
                                            <button className='btn ipresens-btn-color mb-2 ml-2 text-capitalize' style={{ fontSize: "14px", fontWeight: 500, lineHeight: "24px" }} onClick={
                                                () => this.setState({
                                                    valueDate: this.state.exportStartDate + " - " + this.state.exportEndDate,
                                                    reactDateRange: false
                                                })
                                            }>{i18n.t('terapkan')}</button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.valueDate !== "" &&
                                <>
                                    <div>
                                        <div className='row'>
                                            <div className='col-auto d-flex justify-content-start mb-2'>
                                                <div className='btn-group width-filter'>
                                                    <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filterExport === "pending" ? ' ipresens-active-custom-btn-group' : '' ? " ipresens-custom-btn-group" : "")} onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "pending" ? '' : 'pending',}))}>{i18n.t('menunggu')}</button>
                                                    <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filterExport === "success" ? ' ipresens-active-custom-btn-group' : '' ? " ipresens-custom-btn-group" : "")} onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "success" ? '' : 'success',}))}>{i18n.t('disetujui')}</button>
                                                    <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filterExport === "cancel" ? ' ipresens-active-custom-btn-group' : '' ? " ipresens-custom-btn-group" : "")} onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "cancel" ? '' : 'cancel',}))}>{i18n.t('ditolak')}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{ marginBottom: "40px" }} />
                                        <div className='row px-2'>
                                            <div className='col-md-4 px-1'>
                                                <button className={'btn btn-block ipresens-btn-color px-3 py-2 text-capitalize mb-2'} style={{ fontSize: "14px", borderRadius: "11px" }} onClick={this.exportKbeban}>{i18n.t('exportdataklaimbeban')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                            <button className="btn ipresens-btn-cancel text-capitalize" onClick={this.handleCancel}>
                                {i18n.t('batalkan')}
                            </button>
                        </ModalFooter>
                    </ModalRs>

                    <ModalRs size='lg' zIndex={999} isOpen={this.state.modalAdd} toggle={() => this.setState({
                        modalAdd: false,
                        karyawan_selected: null,
                        tanggal_pengajuan: '',
                        nominal: '', deskripsi: '', note: '', fileList: [], gambar: ''
                    })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({
                            modalAdd: false,
                            karyawan_selected: null,
                            tanggal_pengajuan: '',
                            nominal: '', deskripsi: '', note: '', fileList: [], gambar: ''
                        })}>{i18n.t('tambahpengajuanklaimbeban')}</ModalHeader>
                        <ModalBody>
                            <div className="">
                                <div className='form-group'>
                                    <label htmlFor="nama_karyawan"
                                           className="form-label">{i18n.t('namakaryawan')}</label>
                                    <Select
                                        backspaceRemoves={true}
                                        deleteRemoves={true}
                                        placeholder={i18n.t('ketiknamakaryawan')}
                                        components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                        value={this.state.karyawan_selected}
                                        isLoading={this.state.karyawan_loading}
                                        onFocus={() => this.setState({karyawan_loading: true}, () => this.searchKaryawan())}
                                        onBlur={() => this.setState({karyawan_loading: false})}
                                        onInputChange={(val) => this.setState({karyawan_nama: val}, () => this.setState({karyawan_loading: true}, () => this.searchKaryawan()))}
                                        options={
                                            this.state.karyawan != null ? (
                                                    this.state.karyawan.map((item, index) =>
                                                        (
                                                            {
                                                                key: index,
                                                                value: item.karyawan_id,
                                                                label: item.karyawan_nama,
                                                            }
                                                        )
                                                    ))
                                                : ("")
                                        }
                                        onChange={
                                            (karyawan_selected) => {
                                                if (!karyawan_selected) {
                                                    this.setState({
                                                        karyawan_selected: '',
                                                    })
                                                } else {
                                                    this.setState({
                                                        karyawan_selected,
                                                        id_karyawan: karyawan_selected.value,
                                                    })
                                                }
                                            }
                                        }
                                        isClearable={() => {
                                            this.setState({
                                                isClearable: true,
                                            })
                                        }}
                                    />
                                </div>
                                <div className="" style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr",
                                    gap: "16px"
                                }}>
                                    <div className="form-group">
                                        <label htmlFor="">{i18n.t('tanggalpengajuan')}</label>
                                        <input type="date" className="form-control" name="tanggal_pengajuan"
                                               onChange={this.handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">{i18n.t('nominal')}</label>
                                        <input type="number" className="form-control" name="nominal"
                                               placeholder={i18n.t('masukannominal')}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">{i18n.t('deskripsi')}</label>
                                    <input type="text" placeholder={i18n.t('ketikandeskripsi')} className="form-control"
                                           name="deskripsi" onChange={this.handleChange}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">{i18n.t('catatanopsional')}</label>
                                    <textarea name="catatan" id="catatan" placeholder={i18n.t('masukancatatan')} onChange={this.handleChange} className="form-control"></textarea>
                                </div>
                                <div className="form-group">
                                    <ImgCrop
                                        width={2000}
                                        height={1000}>
                                        <Upload action={API_HOST} listType="picture-card"
                                                style={{ width: '400px !important' }}
                                                fileList={this.state.fileList}
                                                onChange={(file) => {
                                                    this.setState({ fileList: file.fileList })

                                                    if (file.fileList.length > 0) {
                                                        let files = file.file.originFileObj
                                                        let reader = new FileReader()

                                                        reader.readAsDataURL(files)
                                                        reader.onloadend = () => {
                                                            this.setState({
                                                                gambar: {
                                                                    name: file.file.name,
                                                                    file: reader.result
                                                                }
                                                            })
                                                        }
                                                    }
                                                    else {
                                                        this.setState({ gambar: [] })
                                                    }
                                                }}
                                        >
                                            {this.state.fileList.length >= 1 ? null :
                                                <div className="p-2">
                                                    <img alt='' src={ImportIcon} style={{ width: "32px", height: "32px" }} /><br />
                                                    <div style={{
                                                        fontSize: "12px",
                                                        lineHeight: "16px",
                                                        color: "#5D6F80",
                                                        marginTop: "8px"
                                                    }}>{i18n.t('uploadgambar')}</div><br />
                                                    <a className="btn btn-outline-custom tambah-karyawan">{i18n.t('carigambar')}</a>
                                                </div>
                                            }
                                        </Upload>
                                    </ImgCrop>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            borderTop: 0,
                            backgroundColor: "#F5F5F5",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                        }}>
                            <button className='btn ipresens-btn-cancel text-capitalize' onClick={() => {
                                this.setState({
                                    modalAdd: false,
                                    karyawan_selected: null,
                                    catatan: "", nominal: "", deskripsi: "", tangal_pengajuan: "", id_karyawan: 0.
                                })
                            }}>
                                {i18n.t('batal')}
                            </button>{' '}
                            <button
                                className='btn ipresens-btn-color text-capitalize'
                                style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "24px"
                                }}
                                disabled={this.state.loading}
                                onClick={() => {
                                    this.simpanKlaimbeban()
                                }}
                            >
                                {i18n.t('simpan')}
                            </button>
                        </ModalFooter>
                    </ModalRs>

                    <ModalRs size="lg" style={{maxWidth: "1000px"}} isOpen={this.state.modalHistory}
                             toggle={() => this.setState({modalHistory: false})}>
                        <ModalHeader style={{backgroundColor: "#FAFDFF"}}
                                     toggle={() => this.setState({modalHistory: false})}>
                            <div className='modal-header-reactstrap'>{i18n.t('riwayatperubahan')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{textAlign: 'center', margin: '10px auto'}}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}
                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{marginLeft: '20px'}}>
                                                    <h4>{i18n.t('user')}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{marginRight: '20px'}}>
                                                    <h4>{i18n.t('aktivitas')}</h4>
                                                </Col>
                                            </Row>
                                            {this.state.dataHistory.map((item, index) => {
                                                return (
                                                    <div key={index} style={{marginBottom: '20px'}}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    paddingTop: '16px',
                                                                    paddingBottom: '16',
                                                                    paddingRight: '12px',
                                                                    paddingLeft: '12px'
                                                                }}>
                                                                    <div style={{marginRight: '16px'}}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span className="col-green font-weight-bold">
                                                                                {moment(item.created_at).lang(i18n.language).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === "created" ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        {i18n.t('dibuat')}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        {i18n.t('diubah')}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.k_beban_desc != item.k_beban_desc && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('descklaimbebandari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.k_beban_desc
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.k_beban_desc ? item.k_beban_desc : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.k_beban_nominal != item.k_beban_nominal && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('nominalklaimbebandari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.k_beban_nominal
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.k_beban_nominal ? item.k_beban_nominal : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.k_beban_status != item.k_beban_status && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 100,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('statusklaimbebandari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.k_beban_status
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRight}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '1px',
                                                                                                height: '18px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.k_beban_status ? item.k_beban_status : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.k_beban_date != item.k_beban_date && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('tanggalklaimbebandari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.k_beban_date
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.k_beban_date ? item.k_beban_date : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} data</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>

                    <ModalRs size='lg' isOpen={this.state.modalDetail} toggle={() => this.setState({
                        modalDetail: false
                    })} style={{
                        marginRight: "10px",
                        maxWidth: "419px",
                    }}>
                        <ModalHeader toggle={() => this.setState({
                            modalDetail: false
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t('detailklaimbeban')}</div>
                        </ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div>
                                <div className="dropdown-menu-header">
                                    <div className="dropdown-menu-header-inner">
                                        <div className="menu-header-content btn-pane-right">
                                            <div>
                                                {this.state.karyawan_foto !== null ? (
                                                    <img
                                                        id="avatar"
                                                        src={IMG_HOST + 'mitra/frontapp/karyawan/' + this.state.karyawan_foto}
                                                        className="img-fluid blur-up lazyloaded"/>) : (
                                                    <img
                                                        id="avatar"
                                                        src={IMG_HOST + 'user-profile.png'}
                                                        className="img-fluid blur-up lazyloaded"/>
                                                )}
                                            </div>
                                            <div>
                                                <a style={{
                                                    fontWeight: 700,
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    color: "#8c2bee"
                                                }} href={"/karyawan/profil/detail/" + this.state.karyawan_id}
                                                   className='text-capitalize'>{this.state.karyawan_nama}</a>
                                                {/*<h6 style={{ fontSize: 12 }}>{this.state.divisi.divisi_name}</h6>*/}
                                                {/*<h6 style={{ fontSize: 12 }}>{format({prefix: '', integerSeparator: "."})(this.state.karyawan.karyawan_payroll_amount)}</h6>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <label className="form-label"
                                                   style={style_add.font_label}>{i18n.t('descklaimbeban')}</label>
                                            <p style={{color: "black", fontSize: '14px'}}>{this.state.k_beban_desc}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <label className="form-label"
                                                   style={style_add.font_label}>{i18n.t('fotoklaimbeban')}</label>
                                            {this.state.k_beban_foto != null && (
                                                <a href={this.state.k_beban_foto != null ? IMG_HOST + "mitra/beban/" + this.state.k_beban_foto : DEFAULT_IMG}
                                                   target={"_blank"}><i
                                                    className="fa fa-eye" style={{
                                                    width: 35,
                                                    fontSize: 20,
                                                    padding: 11,
                                                    color: '#8c2bee'
                                                }}/>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <label className="form-label"
                                                   style={style_add.font_label}>{i18n.t('nominalklaimbeban')}</label>
                                            <p style={{color: "black"}}>
                                                {format({
                                                    prefix: getCurrency(currency),
                                                    integerSeparator: "."
                                                })(this.state.k_beban_nominal)}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <label className="form-label"
                                                   style={style_add.font_label}>{i18n.t('tanggalpengajuanklaimbeban')}</label>
                                            <p style={{color: "black"}}>
                                                {moment(this.state.k_beban_date).lang(i18n.language).format('ll')}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <label className="form-label"
                                                   style={style_add.font_label}>{i18n.t('keterangan')}</label>
                                            <p style={{color: "black"}}>
                                                {this.state.k_beban_note}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <label className="form-label"
                                                   style={style_add.font_label}>{i18n.t('statusklaimbeban')}</label>
                                            <p className='lembur-td-style'
                                                style={{color: this.state.k_beban_status === "pending" ? "#FF9C00" : this.state.k_beban_status === "cancel" ? "#E03616" : this.state.k_beban_status === "success" ? "#1E7E0E" : ""}}>
                                                {this.state.k_beban_status === "pending" ? i18n.t('menunggu') : this.state.k_beban_status === "cancel" ? i18n.t('ditolak') : this.state.k_beban_status === "success" ? i18n.t('disetujui') : ""}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </ModalRs>

                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody}
                          onBeforeClose={this.enableBody} onRequestClose={() => this.setState({guide: false})}/>
                    <div className="container-fluid">
                        <div className="card" style={{border: "1px solid #DDDDDD"}}>
                            <div className="card-body">
                                <div className='mb-4'>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                            <div className='d-flex flex-row mb-2'>
                                                <div className='flex-fill pr-2'>
                                                    <div className='input-group btn-srch-kbeban'>
                                                    <span className="input-group-text izin-search-karyawan-img" id="basic-addon1">
                                                        <img alt='' src={SearchIcon} />
                                                    </span>
                                                        <input type="text" name='keyword' onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                this.setState({
                                                                    data: [],
                                                                    page: 0,
                                                                    paging: this.state.paging,
                                                                    loading: true
                                                                }, () => {
                                                                    this.loadData()
                                                                });
                                                            }
                                                        }} value={this.state.keyword} onChange={this.handleChange} className="form-control izin-search-karyawan-form" placeholder={i18n.t('carinamakaryawan') + "..."} aria-label="Username" aria-describedby="basic-addon1" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <button className='btn ipresens-btn-filter' onClick={() => this.setState({ filter: !this.state.filter })}><img src={FilterIcon} />   {i18n.t('filter')}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className='mx-1'>
                                                <button
                                                    className='btn ipresens-btn-color px-3 text-capitalize pull-right width-filter tombol-export mb-2'
                                                    style={{fontSize: "14px", paddingTop: "6px", paddingBottom: "6px", marginLeft: "10px"}}
                                                    onClick={() => {
                                                        this.setState({modalAdd: true})
                                                    }}><img src={PlusIcon}/> {i18n.t('tambahpengajuan')}
                                                </button>
                                                <button
                                                    className='btn ipresens-btn-color px-3 text-capitalize pull-right width-filter tombol-export mb-2'
                                                    style={{fontSize: "14px", paddingTop: "6px", paddingBottom: "6px"}}
                                                    onClick={() => {
                                                        this.setState({modalexport: true})
                                                    }}><img src={DownloadIcon}/> {i18n.t('eksporexcel')}</button>

                                            </div>
                                        </div>
                                    </div>
                                    {this.state.filter &&
                                        <div className='row mx-0'>
                                            <div className='col-md-3 px-1 mb-2'>
                                                <label className='form-label' style={{fontSize: '12px'}}>{i18n.t('filternominal')}:</label><br/>
                                                <input name="nominal" className="form-control"
                                                       placeholder={i18n.t('filternominal')}
                                                       type="number" required=""
                                                       value={this.state.nominal}
                                                       onChange={this.handleChange}
                                                       onKeyUp={(event) => {
                                                           event.preventDefault()
                                                           if (event.which == 13) {

                                                               this.setState({
                                                                   page: 0,
                                                                   paging: this.state.paging,
                                                                   loading: true
                                                               }, () => {
                                                                   this.loadData()
                                                               });
                                                           }
                                                       }} />
                                            </div>
                                            <div className='col-auto px-1 d-flex align-items-end justify-content-end mb-2'>
                                                <div className='btn-group width-filter'>
                                                    <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.status === "pending" ? ' ipresens-active-custom-btn-group' : '')} onClick={() => this.setState({ status: "pending", loading: true }, () => this.loadData())}>{i18n.t('menunggu')}</button>
                                                    <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.status === "success" ? ' ipresens-active-custom-btn-group' : '')} onClick={() => this.setState({ status: "success", loading: true }, () => this.loadData())}>{i18n.t('disetujui')}</button>
                                                    <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.status === "cancel" ? ' ipresens-active-custom-btn-group' : '')} onClick={() => this.setState({ status: "cancel", loading: true }, () => this.loadData())}>{i18n.t('ditolak')}</button>
                                                </div>
                                            </div>
                                            <div className='col-auto px-1 d-flex align-items-end justify-content-end pb-2'>
                                                <button className='btn text-capitalize px-2 width-filter ipresens-btn-reset-filter pt-1' onClick={this.resetFilter}><img src={RefreshIcon} alt="icon refresh" /> {i18n.t('resetfilter')}</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className='table table-striped table-borderless'>
                                                <thead>
                                                <tr>
                                                    <th className='lembur-th-style'>#</th>
                                                    <th className='lembur-th-style' style={{ minWidth: "120px" }}>
                                                        <div className='d-flex'>
                                                            <div className='izin-th-style'>{i18n.t('tglpengajuan')} </div>
                                                            <div className='ml-1 d-flex flex-column pt-1'>
                                                                <img alt='' onClick={() => {
                                                                    this.sortabledate(1)
                                                                }} style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowUpIcon} />
                                                                <img alt='' onClick={() => {
                                                                    this.sortabledate(0)
                                                                }} style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowDownIcon} />
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className='lembur-th-style' style={{ maxWidth: "125px" }}>{i18n.t('karyawan')}</th>
                                                    <th className='lembur-th-style'>{i18n.t('foto')}</th>
                                                    <th className='lembur-th-style' style={{ minWidth: "135px" }}>{i18n.t('nominal')}</th>
                                                    <th className='lembur-th-style'>{i18n.t('keterangan')}</th>
                                                    <th className='lembur-th-style'>
                                                        <div className='d-flex'>
                                                            <div className='izin-th-style'>{i18n.t('status')} </div>
                                                            <div className='ml-1 d-flex flex-column pt-1'>
                                                                <img alt='' style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowUpIcon} onClick={() => {
                                                                    this.sortablestatus(1)
                                                                }} />
                                                                <img alt='' style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowDownIcon} onClick={() => {
                                                                    this.sortablestatus(0)
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className='lembur-th-style'></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.loading ?
                                                    <tr>
                                                        <td colSpan={10}>
                                                            <Spin size="large" tip="Loading..." style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                                marginBottom: '10px',
                                                                marginTop: '10px'
                                                            }} />
                                                        </td>
                                                    </tr>
                                                    :
                                                    this.state.data.length === 0 ?
                                                        <tr>
                                                            <td colSpan={9}>
                                                                <Empty description={i18n.t("datakosong")}
                                                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                            </td>
                                                        </tr>
                                                        :
                                                        this.state.data.map((item, index) => {
                                                            no += 1
                                                            return (
                                                                <tr style={{
                                                                    borderBottom: no === this.state.data.length ? "" : "1px solid #EFEFEF"
                                                                }}>
                                                                    <td className='lembur-td-style'>{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                    <td className='lembur-td-style'>
                                                                        {moment(item.k_beban_date).lang(i18n.language).format('ll')}
                                                                    </td>
                                                                    <td className='lembur-td-style' style={{ maxWidth: "115px" }}>
                                                                        <a href={"/karyawan/profil/detail/" + item.karyawan.karyawan_slug} style={{ color: "#8c2bee" }}>
                                                                            {this.state.slice_karyawan_nama ? item.karyawan.karyawan_nama.slice(0, 14) : item.karyawan.karyawan_nama}
                                                                        </a>
                                                                        {item.karyawan.karyawan_nama.length >= 15 && this.state.slice_karyawan_nama === true ?
                                                                            <button className='izin-btn' style={{ color: "#6BA9FF", fontSize: "24px", fontWeight: 400, padding: "0px" }} onClick={() => {
                                                                                this.setState({
                                                                                    slice_karyawan_nama: false
                                                                                })
                                                                            }}>...</button> : ""}
                                                                        {item.karyawan.karyawan_nama.length >= 15 && this.state.slice_karyawan_nama === false ?
                                                                            <button className='izin-btn' style={{ color: "#6BA9FF", fontSize: "14px", fontWeight: 400, padding: "0px" }} onClick={() => {
                                                                                this.setState({
                                                                                    slice_karyawan_nama: true
                                                                                })
                                                                            }}>{i18n.t('lebihsedikit')}</button> : ""}
                                                                    </td>
                                                                    <td className='lembur-td-style'>
                                                                        {item.k_beban_foto != null && (
                                                                            <a href={item.k_beban_foto != null ? IMG_HOST + "mitra/beban/" + item.k_beban_foto : DEFAULT_IMG}
                                                                               target={"_blank"}><i
                                                                                className="fa fa-eye" style={{
                                                                                width: 35,
                                                                                fontSize: 20,
                                                                                padding: 11,
                                                                                color: '#8c2bee'
                                                                            }}/>
                                                                            </a>
                                                                        )}
                                                                    </td>
                                                                    <td className='lembur-td-style'>
                                                                        {item.k_beban_nominal !== null ? format({ prefix: getCurrency(currency), integerSeparator: "." })(item.k_beban_nominal) : ('-')}
                                                                    </td>
                                                                    <td className='lembur-td-style'>
                                                                        {item.k_beban_desc}
                                                                    </td>
                                                                    <td className='lembur-td-style' style={{ color: item.k_beban_status === "pending" ? "#FF9C00" : item.k_beban_status === "cancel" ? "#E03616" : item.k_beban_status === "success" ? "#1E7E0E" : "" }}>
                                                                        {item.k_beban_status === "pending" ? i18n.t('menunggu') : item.k_beban_status === "cancel" ? i18n.t('ditolak') : item.k_beban_status === "success" ? i18n.t('disetujui') : ""}
                                                                    </td>
                                                                    <td className='lembur-td-style'>
                                                                        <Tooltip placement="bottom" trigger={"click"} zIndex={1000} color={'#fff'} title={
                                                                            <div className={'d-flex flex-column'}>
                                                                                <button style={{width: "84px"}}
                                                                                        className='izin-btn izin-tooltip-menu px-2 py-1 mb-1'
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                modalHistory: true,
                                                                                                k_beban_id: item.k_beban_id,
                                                                                            }, () => {
                                                                                                this.loadHistory(item.k_beban_id);
                                                                                            })
                                                                                        }}>{i18n.t('riwayat')}
                                                                                </button>
                                                                                <button
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            k_beban_id: item.k_beban_id,
                                                                                            modalDetail: true,
                                                                                            karyawan_id     : item.karyawan.karyawan_id,
                                                                                            karyawan_nama   : item.karyawan.karyawan_nama,
                                                                                            karyawan_foto   : item.karyawan.karyawan_foto,
                                                                                            k_beban_nominal : item.k_beban_nominal,
                                                                                            k_beban_foto    : item.k_beban_foto,
                                                                                            k_beban_desc    : item.k_beban_desc,
                                                                                            k_beban_note    : item.k_beban_note,
                                                                                            k_beban_status  : item.k_beban_status,
                                                                                            k_beban_date    : item.k_beban_date,
                                                                                        })
                                                                                    }}
                                                                                    style={{width: "84px"}}
                                                                                    className='izin-btn izin-tooltip-menu px-2 py-1'>{i18n.t('detail')}
                                                                                </button>
                                                                                {item.k_beban_status !== "success" &&
                                                                                    <button
                                                                                        style={{width: "84px"}}
                                                                                        className='izin-btn izin-tooltip-menu px-2 py-1 mb-1'
                                                                                        disabled={item.k_beban_status === "success"}
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                k_beban_id: item.k_beban_id
                                                                                            }, () => {
                                                                                                this.approve()
                                                                                            })
                                                                                        }}>{i18n.t('setujui')}</button>
                                                                                }
                                                                                {item.k_beban_status !== "cancel" &&
                                                                                    <button
                                                                                        style={{width: "84px"}}
                                                                                        className='izin-btn izin-tooltip-menu px-2 py-1 mb-1'
                                                                                        disabled={item.k_beban_status === "cancel"}
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                k_beban_id: item.k_beban_id
                                                                                            }, () => {
                                                                                                this.reject()
                                                                                            })
                                                                                        }}>{i18n.t('tolak')}</button>
                                                                                }
                                                                            </div>
                                                                        }>
                                                                            <button className='izin-btn'>
                                                                                <img alt='' src={ElipsisIcon}/>
                                                                            </button>
                                                                        </Tooltip>

                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer p-0 border-0'>
                                <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                    {this.state.data.length !== 0 &&
                                        <>
                                            <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t('menampilkan')} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t('dari')} {this.state.totalData} {i18n.t('data')}</div>
                                            <div className='col'>
                                                <ReactPaginate
                                                    previousLabel={<img src={ArrowLeftIcon} alt='' />}
                                                    nextLabel={<img src={ArrowRightIcon} alt='' />}
                                                    pageCount={this.state.pageCount}
                                                    forcePage={this.state.currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={this.handlePageClick}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link lembur-page-link'}
                                                    containerClassName={'pagination my-2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link lembur-page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link lembur-prev-next-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link lembur-prev-next-link'}
                                                    activeClassName={'active lembur-active'}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_klaim_beban);
