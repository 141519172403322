import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import i18n from "i18next";
import refreshIcon from "../../assets/icons/refresh.svg";
import ImportIcon from "../../assets/icons/import-icon.svg";
import plusIconWhite from "../../assets/icons/IconPlusWhite.svg";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import {ListAgenda} from "../agenda/list";
import {Empty, Spin} from "antd";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import {pageNumber} from "../../functions/functionGeneral";
import Paging from "../paging";
import Swal from "sweetalert2";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import Tour from "reactour";
import {BACKEND_API_HOST, DEFAULT_IMG} from "../../helper/host";
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ReactPaginate from 'react-paginate';
import moment from "moment/moment";

export class IstilahPembayaran extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter_nama: '',
            page: 0,
            paging: 5,
            loading: false,
            modal_import: false,
            add_visible: false,
            filter_type: '',
            currentPage: 0,
            data: [],
            totalData: 0,
            pageCount: 0,
            perPage: 0,
            id_delete: 0,
            modal_add: false,
            pb_term_name: "",
            pb_term_name_edit: "",
            pb_term_name_detail: "",
            data_detail: [],
            importFile: null,
            modalimport: false,
            modal_history: false,
            history: [],
            pageCountHistory: 0,
            currentPageHistory: 0,
            loading_history: false,
            term_id: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.importExcel = this.importExcel.bind(this);
        this.handlePageHistory = this.handlePageHistory.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handlePageHistory(history) {
        let selected = history.selected;
        this.setState({ currentPageHistory: selected, loading_history: true, history: [] }, () => {
            this.loadHistory();
        });
    }

    handleOk = e => {
        this.setState({
            modalimport: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false
        });
    };

    resetFilter() {
        this.setState({
            filter_nama: "",
            data: [],
            loading: true,
        }, () => this.loadData())
    }

    handlePageClick(data) {
        let selected = data.selected;
        this.setState({ currentPage: selected, loading: true, data: [] }, () => {
            this.loadData()
        });
    }

    loadData() {
        this.setState({loading: true})
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request('pembayaran/payment_term_list', form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
                this.setState({
                    loading: false
                })
            })
    }

    wantdelete(pb_term_id) {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('andayakininginmenghapusistilah'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal')
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({ data: [], loading: true })
                    let form = {
                        pb_term_id: pb_term_id ? pb_term_id : this.state.id_delete,
                    }
                    this.props.request("pembayaran/delete-pem-term", form, "POST")
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('databerhasildihapus'),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        })
                }
            })
    }

    handleSubmit = () => {
        this.setState({ loading: true })
        let form = {
            pb_term_name : this.state.pb_term_name,
        }
        this.props.request('pembayaran/add_payment_term', form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        pb_term_name: '',
                        data: [],
                        loading: false,
                        modal_add: false,
                    }, () => this.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasilditambahkan'),
                        icon: "success"
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('datagagalditambahkan'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ loading: false })
            })
    }

    loadDetail () {
        let form = {
            pb_term_id: this.state.pb_term_id,
        }
        this.props.request('pembayaran/payment_term_detail', form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_detail: response.data,
                        pb_term_name: response.data.pb_term_name,
                    })
                }
                this.setState({
                    loading: false,
                })
            })
    }

    handleEdit = () => {
        this.setState({ loading: true })
        let form = {
            pb_term_name : this.state.pb_term_name,
            pb_term_id: this.state.pb_term_id,
        }
        this.props.request('pembayaran/edit_payment_term', form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        pb_term_name: '',
                        data: [],
                        loading: false,
                        modal_edit: false,
                    }, () => this.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildihapus'),
                        icon: "success"
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('datagagaldihapus'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ loading: false })
            })
    }

    exportTemplate = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'pembayaran/export-template-term?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'pembayaran/import-term', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('berhasilimportistilahpembayaran'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    loadHistory(id) {
        this.setState({ loading_history: true, history: []})
        let form = {
            pb_term_id: id || this.state.term_id,
            paging: 2,
            page: this.state.currentPageHistory + 1,
        }
        this.props.request('pembayaran/history_term', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        history: response.data.data,
                        loading_history: false,
                        totalDataHistory: response.data.total,
                        pageCountHistory: response.data.last_page,
                        perPageHistory: response.data.per_page,
                    })
                }
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.pembayaran-siswa',
                content: 'Anda dapat membuat Pembayaran Siswa disini',
            },
            {
                selector: '.daftar-istilah-pembayaran',
                content: 'Daftar Istilah Pembayaran anda dapat dilihat disini',
            },
        ];
        const style = {
            filter_nama: {
                backgroundColor: "#F9F9F9",
                padding: "12px 10px",
                color: "#5D6F80",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px"
            }
        }
        if(!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Pembayaran") >= 0 ){
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{i18n.t('daftaristilahpembayaran')} - Presensi</title>
                        <meta name="description" content="Detail Daftar Payroll"/>
                    </Helmet>

                    <Breadcrumb title={i18n.t("istilahpembayaranlist")}  parent={i18n.t("pembayaran")}  setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid">
                        <div className='row'>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-4 pr-lg-1 pr-md-1">
                                            <div className='input-group btn-srch-kbeban'>
                                        <span className='input-group-text izin-search-karyawan-img'>
                                            <img alt='' src={SearchIcon}/>
                                        </span>
                                                <input type='text' onChange={this.handleChange}
                                                       name='filter_nama' value={this.state.filter_nama}
                                                       className='form-control izin-search-karyawan-form'
                                                       placeholder={i18n.t("carinamaistilah") + ("...")}
                                                       style={style.filter_nama} onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        this.loadData()
                                                    }
                                                }}>
                                                </input>
                                            </div>
                                        </div>
                                        <div className="col-md-3 px-lg-1 px-md-1 d-flex flex-row flex-nowrap mb-3">
                                            <div>
                                                <button className='btn ipresens-btn-reset border px-2'>
                                                    <img alt="" src={RefreshIcon}
                                                         onClick={this.resetFilter}/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="d-flex justify-content-end align-items-center mx-1">
                                                <button
                                                    className="btn ipresens-import-button text-capitalize width-filter mr-2"
                                                    onClick={() => {
                                                        this.setState({modalimport: true})
                                                    }}
                                                >
                                                    <img src={ImportIcon} alt="Import Icon"/> Import
                                                </button>
                                                <button
                                                    className="btn ipresens-btn-color px-3 text-capitalize width-filter tombol-export"
                                                    onClick={() => {
                                                        this.setState({modal_add: true});
                                                    }}
                                                >
                                                    <img src={PlusIcon} alt="Tambah Istilah"/> {i18n.t('tambah')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.loading ?
                                    <div className="card-body">
                                        <Spin size="large" tip={i18n.t('sedangmemuatdata')} style={{
                                            width: '100%',
                                            margin: 'auto',
                                            marginBottom: '10px',
                                            marginTop: '10px'
                                        }}/>
                                    </div>
                                    :
                                    <div className="card-body">
                                        {!this.state.loading && this.state.data.length === 0 ?
                                            <Empty description={i18n.t("datakosong")}
                                                   image={EmptyDataImage} className="ipresens-image-empty "/>
                                            :
                                            <Table striped className="mb-5">
                                                <Thead>
                                                    <Tr style={{ paddingBottom: "10px"}}>
                                                        <Th width="30" style={{ paddingBottom: "10px"}}>#</Th>
                                                        <Th width="100" className="text-left" style={{ paddingBottom: "10px"}}>Nama</Th>
                                                        <Th className="text-center" width="100" style={{ paddingBottom: "10px"}}>Aksi</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody className="">
                                                    {this.state.loading &&
                                                        <Tr>
                                                            <Td colSpan={9}>
                                                                <Spin size="large" tip="Loading..." style={{
                                                                    width: '100%',
                                                                    margin: 'auto',
                                                                    marginBottom: '10px',
                                                                    marginTop: '10px'
                                                                }}/>
                                                            </Td>
                                                        </Tr>
                                                    }
                                                    {this.state.data.map((item, index) => {
                                                        no += 1
                                                        return (
                                                            <Tr className="table-istilah">
                                                                <Td scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                                <Td style={{ minWidth: "150px"}}>
                                                                    {item.pb_term_name}
                                                                </Td>
                                                                <Td align={"center"}>
                                                                    <a onClick={() => {
                                                                        this.setState({modal_history: true, term_id: item.pb_term_id}, () => this.loadHistory(item.pb_term_id))
                                                                    }}>
                                                                        <button className="btn-riwayat">{i18n.t('riwayat')}</button>
                                                                    </a>
                                                                    <a onClick={() => {
                                                                        this.setState({
                                                                            modal_edit: true,
                                                                            pb_term_id: item.pb_term_id,
                                                                            pb_term_name: item.pb_term_name
                                                                        }, () => this.loadDetail())
                                                                    }}>
                                                                        <button className="btn-edit">{i18n.t('edit')}</button>
                                                                    </a>
                                                                    <a onClick={() => {
                                                                        this.setState({
                                                                            id_delete: item.pb_term_id
                                                                        }, () => this.wantdelete(item.pb_term_id));
                                                                    }}>
                                                                        <button className="btn-delete">{i18n.t('hapus')}</button>
                                                                    </a>
                                                                </Td>
                                                            </Tr>
                                                        );
                                                    })}
                                                </Tbody>
                                            </Table>
                                        }
                                        <Paging
                                            className="float-right"
                                            handlePageClick={this.handlePageClick}
                                            pageCount={this.state.pageCount}
                                            currentPage={this.state.currentPage}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <Modal size="lg" style={{ maxWidth: "600px" }} isOpen={this.state.modal_add} toggle={() => this.setState({ modal_add: false})}>
                        <ModalHeader>
                            <div className="">{i18n.t('tambahistilahpembayaran')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="">
                                <label htmlFor="">{i18n.t('namaistilah')}</label>
                                <input type="text" className="form-control" placeholder={i18n.t('nama')} name="pb_term_name" onChange={this.handleChange} value={this.state.pb_term_name}/>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                                    this.setState({ modal_add :false, pb_term_name: "" })
                                }}
                                        style={{marginRight: 8}}>
                                    {i18n.t('batal')}
                                </button>
                                <button type="button" className="btn ipresens-btn-color" onClick={this.handleSubmit}
                                        disabled={this.state.loading}>
                                    {this.state.loading ?
                                        <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t('loading')}</>
                                        :
                                        i18n.t('simpan')
                                    }
                                </button>
                            </div>
                        </ModalFooter>
                    </Modal>

                    <Modal size="lg" style={{ maxWidth: "600px" }} isOpen={this.state.modal_edit} toggle={() => this.setState({ modal_edit: false})}>
                        <ModalHeader>
                            <div className="">{i18n.t('editistilahpembayaran')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="">
                                <label htmlFor="">{i18n.t('namaistilah')}</label>
                                <input type="text" className="form-control" placeholder={i18n.t('nama')}  name="pb_term_name" onChange={this.handleChange} value={this.state.pb_term_name}/>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                                    this.setState({ modal_edit:false, pb_term_name_edit: "", pb_term_name_detail: "" })
                                }}
                                        style={{marginRight: 8}}>
                                    {i18n.t('batal')}
                                </button>
                                <button type="button" className="btn ipresens-btn-color" onClick={this.handleEdit}
                                        disabled={this.state.loading}>
                                    {this.state.loading ?
                                        <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t('loading')}</>
                                        :
                                        i18n.t('simpan')
                                    }
                                </button>
                            </div>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.modalimport}>
                        <ModalHeader onClick={this.handleCancel} toggle={() => this.setState({importFile: null})}>
                            <div>{i18n.t('importistilahpembayaran')}</div>
                        </ModalHeader>
                        <ModalBody style={{ padding: "20px" }}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t('andabisamengunduhtemplate')}</a><br/>
                                    <a onClick={this.exportTemplate} style={{color: "#057CE4"}}>{i18n.t('unduhtemplatedisini')}</a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({
                                                       importFile: event.target.files[0],
                                                   })
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i> {i18n.t('prosesupload')}</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                            ]}
                        </ModalFooter>
                    </Modal>

                    <Modal size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modal_history} toggle={() => this.setState({ modal_history: false})}>
                        <ModalHeader className="pengumuman-modal-header" toggle={() => this.setState({ modal_history: false})}>
                            <div className="pengumuman-modal-header-font">{i18n.t('riwayatpembayarantermsiswa')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div className="">
                                    {this.state.loading_history && (
                                        <div style={{textAlign: "center", margin: '10px auto'}}>
                                            <Spin size="large" tip={i18n.t('loading')}
                                                  style={{width: "100%", margin: "auto"}}/>
                                        </div>
                                    )}
                                    {this.state.history.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{marginLeft: '20px'}}>
                                                    <h4>{i18n.t('user')}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{marginRight: '20px'}}>
                                                    <h4>{i18n.t('aktivitas')}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.history.map((item, index) => {
                                                return (
                                                    <div key={index} style={{marginBottom: "20px"}}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    padding: '16px 12px'
                                                                }}>
                                                                    <div style={{marginRight: '16px'}}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{
                                                                                width: '48px',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6 className="mb-0 font-13" style={{
                                                                            fontWeight: 500,
                                                                            fontSize: '16px',
                                                                            lineHeight: '24px'
                                                                        }}>{item.p_user_name} | {item.level_name}</h6>
                                                                        <p className="m-0 font-12">
                                                                            <span
                                                                                className="col-green font-weight-bold">
                                                                              {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{marginTop: 8}}>
                                                                            {item.history_status === "created" ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="d-flex justify-content-center"
                                                                                        style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                        {i18n.t('dibuat')}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="d-flex justify-content-center"
                                                                                        style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                        {i18n.t('diubah')}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{
                                                                padding: '28px 12px 16px',
                                                            }}>
                                                                {(item.history_status !== 'created' && item.history) && (
                                                                    <div>
                                                                        {item.history?.pb_term_name !== item.pb_term_name && (
                                                                            <div>
                                                                                <h6
                                                                                    className="mb-3 font-13 text-left"
                                                                                    style={{
                                                                                        fontWeight: 100,
                                                                                        fontSize: '16px',
                                                                                        lineHeight: '24px',
                                                                                    }}
                                                                                >
                                                                                    {i18n.t('nama')} : {i18n.t('dari')}{' '}
                                                                                    {item.history?.pb_term_name}
                                                                                    <img
                                                                                        src={ArrowRight}
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: '20x',
                                                                                            marginBottom: '1px',
                                                                                            height: '18px'
                                                                                        }}
                                                                                    /> {i18n.t('jadi')}
                                                                                    : {item?.pb_term_name}
                                                                                </h6>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {this.state.history.length !== 0 &&
                                <>
                                    <div className='col font-14' style={{
                                        padding: "auto",
                                        margin: "auto",
                                        fontWeight: 500,
                                        lineHeight: "24px"
                                    }}>{i18n.t('menampilkan')} {this.state.history.length === 0 || this.state.history.length === null ? "0" : this.state.history.length} {i18n.t('dari')} {this.state.totalDataHistory} {i18n.t('data')}
                                    </div>
                                    <div className='col'>
                                        <ReactPaginate
                                            previousLabel={<img src={ArrowLeft} alt=''/>}
                                            nextLabel={<img src={ArrowRight} alt=''/>}
                                            pageCount={this.state.pageCountHistory}
                                            forcePage={this.state.currentPageHistory}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={this.handlePageHistory}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link custom-page-link'}
                                            containerClassName={'pagination my-2'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link custom-page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link custom-prev-next-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link custom-prev-next-link'}
                                            activeClassName={'active custom-active'}
                                        />
                                    </div>
                                </>
                            }
                        </ModalFooter>
                    </Modal>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(IstilahPembayaran);
