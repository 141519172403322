import React, { Component } from "react";
import CompanyIcon from "../../../assets/images/company.svg";
import SchoolIcon from "../../../assets/images/school.svg";
import i18n from "i18next";
import language from "../../../helper/language";

class PilihTipe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipe_selected: '',
            proses_type: false,
            paket_type: [],
            paket_selected: {},
            selected_new_paket: false,
            data_paket: [],
        };
    }

    submitType = (value) => {
        let form = {
            tipe_instansi: value,
            proses_type: true,
        };
        this.props.request("register_v3", form, 'POST')
        .then(response => {
            // console.info('server: ', response)
            if (response.success) {
                this.props.setState({
                    tipe_selected: this.state.tipe_selected,
                    data_paket: this.state.data_paket,
                    stepHeader: 1
                })
            }
        })

    };

    handleSelection = (value) => {
        this.setState({ tipe_selected: value, paket_type: '' }, () => {
            setTimeout(() => {
                this.props.setState({ tipe_selected: value, data_paket: this.state.data_paket });
                this.submitType(value);
                // this.loadforPaket();
            }, 300);
        });
    };

    renderCard = (type, icon, label) => {
        const isSelected = this.state.tipe_selected === type;
        return (
            <label className="type-card-label">
                <input
                    onClick={() => this.handleSelection(type)}
                    type="radio"
                    checked={isSelected}
                    className="card-input-element"
                />
                <div className={isSelected ? "card p-4 border-2" : "card p-4 border"}>
                    <div className="d-flex flex-column align-items-center">
                        <img src={icon} alt="" style={{ width: "20%", height: "100%" }} />
                        <h1 style={{ fontSize: "18px", fontWeight: 700, color: "#143B5E" }}>
                            {i18n.t(label)}
                        </h1>
                    </div>
                </div>
            </label>
        );
    };

    render() {
        return (
            <main style={{backgroundColor: "#F9F9F9", minHeight: "90vh", marginTop: "110px", height: "100%"}}>
                <div className="container type-container">
                    {this.renderCard("sekolah", SchoolIcon, 'sekolah')}
                    {this.renderCard("perusahaan", CompanyIcon, 'perusahaan')}
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="text-description-type" style={{textAlign: "center"}}>
                        {i18n.language == "id" ?
                            <p>Silakan pilih jenis institusi Anda: Perusahaan atau Sekolah. Ini akan membantu kami
                                menyesuaikan pengalaman pengguna dan konten yang relevan untuk kebutuhan Anda.</p>
                            :
                            <p>Please select your institution type: Company or School. This will help us customize the
                                user experience and relevant content for your needs.</p>
                        }
                    </div>
                </div>
            </main>
        );
    }
}

export default PilihTipe;
