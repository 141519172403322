import React, {Fragment} from 'react'

import {Input, InputGroup, InputGroupAddon} from 'reactstrap';

import {faCalendarAlt,} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import moment from 'moment';

class FormDateRangePicker extends React.Component {
    constructor(props) {
        super(props);

        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");

        this.state = {
            start: start,
            end: end,
            selectStart: '',
            selectEnd: '',
            selectText: '',
        }

        this.applyCallback = this.applyCallback.bind(this);
    }

    applyCallback(startDate, endDate) {
        let selectStart = moment(startDate).format('YYYY-MM-DD')
        let selectEnd   = moment(endDate).format('YYYY-MM-DD')

        this.setState({
            start: startDate,
            end: endDate,
            selectStart: selectStart,
            selectEnd: selectEnd,
            selectText: selectStart+' - '+selectEnd,
        }, () => {
            this.props.onSelect(this.state)
        })
    }

    render() {
        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate()));
        let end = moment(start).add(1, "days");

        let ranges = {
            "Hari Ini": [moment(start), moment(end)],
            "Kemarin": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],

        }
        let local = {
            "format": "DD-MM-YYYY",
            "sundayFirst": false
        }
        let maxDate = moment(start).add(24, "hour")

        return (
            <Fragment>
                <DateTimeRangeContainer
                    ranges={ranges}
                    start={this.state.start}
                    end={this.state.end}
                    local={local}
                    // maxDate={maxDate}
                    applyCallback={ this.applyCallback }
                >
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <div className="input-group-text">
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                            </div>
                        </InputGroupAddon>

                        <Input  bsSize="sm" placeholder="Filter Tanggal" value={ this.state.selectText }/>
                    </InputGroup>
                </DateTimeRangeContainer>
            </Fragment>
        )
    }
}

export default FormDateRangePicker;
