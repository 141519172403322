import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import i18n from "i18next";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import Breadcrumb from "../common/breadcrumb";
import Tour from "reactour";
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import AddSemester from "./add-semester";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {Empty, Spin, Tooltip} from "antd";
import {pageNumber} from "../../functions/functionGeneral";
import Paging from "../paging";
import EditSemester from "./edit-semester";
import moment from "moment/moment";
import Swal from "sweetalert2";

class SemesterList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter_nama: '',
            loading: false,
            data: [],
            add_visible: false,
            edit_visible: false,
            semester_id: 0,
            paging: 10,
            totalData: 0,
            pageCount: 0,
            perPage: 0,
            currentPage: 0,
        }
        this.resetFilter = this.resetFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    resetFilter() {
        this.setState({
            data: [], filter_nama: '', loading: true,
        }, () => this.loadData());
    }

    handlePageClick(data) {
        let selected = data.selected;
        this.setState({
            currentPage: selected, data: [], loading: true
        }, () => this.loadData())
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    loadData() {
        this.setState({ loading: true , data: [] })
        let form = {
            filter: this.state.filter_nama,
            paginate: this.state.paging,
            page: this.state.currentPage + 1,
        }
        this.props.request('semester/load-data', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false,
                    })
                }
            })
    }

    wantDelete = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakininginmenghapussemester'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        semester_id: this.state.semester_id
                    }
                    this.setState({
                        data: [],
                        loading: true
                    })
                    this.props.request("semester/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('semesterberhasildihapus'),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.tambah-semester',
                content: 'Anda dapat membuat Semester disini',
            },
            {
                selector: '.daftar-semester',
                content: 'Daftar Semester anda dapat dilihat disini',
            },
        ];

        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Semester") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{i18n.t('semester')} - Presensi</title>
                        <meta name="description" content="Semester Sekolah"/>
                    </Helmet>

                    <Breadcrumb parent={i18n.t("semestersekolah")} title={i18n.t("daftarsemester")} setGuide={() => {
                        this.setState({ guide: true })
                    }}/>
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody}
                          onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />

                    <AddSemester add_visible={this.state.add_visible}
                                 request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                                 changeState={(state, callback) => {
                                     this.setState(state, () => {
                                         if (typeof callback != 'undefined')
                                             callback()
                                     })
                                 }}
                                 loadData={() => this.loadData()}
                    />

                    <EditSemester edit_visible={this.state.edit_visible}
                                  id={this.state.semester_id}
                                  request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                                  changeState={(state, callback) => {
                                      this.setState(state, () => {
                                          if (typeof callback != 'undefined')
                                              callback()
                                      })
                                  }}
                                  loadData={() => this.loadData()}
                    />



                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header pb-0 px-3 border-0">
                                <div className="row">
                                    <div className="col-md-4 pr-lg-1 pr-md-1">
                                        <div className='input-group btn-srch-kbeban'>
                                        <span className='input-group-text izin-search-karyawan-img'>
                                            <img alt='' src={SearchIcon}/>
                                        </span>
                                            <input type='text' onChange={this.handleChange}
                                                   name='filter_nama' value={this.state.filter_nama}
                                                   className='form-control izin-search-karyawan-form filter_nama'
                                                   placeholder={i18n.t("cari") + ("...")}
                                                   onKeyDown={(e) => {
                                                       if (e.key === "Enter") {
                                                           this.loadData()
                                                       }
                                                   }}>
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-md-3 px-lg-1 px-md-1 d-flex flex-row flex-nowrap mb-3">
                                        {/**/}
                                        <div>
                                            <button className='btn ipresens-btn-reset border px-2'>
                                                <img alt="" src={RefreshIcon}
                                                     onClick={this.resetFilter}/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="d-flex justify-content-end align-items-center mx-1">
                                            <button
                                                className="btn ipresens-btn-color px-3 text-capitalize width-filter tombol-export"
                                                onClick={() => {
                                                    this.setState({add_visible: true});
                                                }}>
                                                <img src={PlusIcon} alt="Tambah Istilah"/> {i18n.t('tambah')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-0">
                                <div className="table-responsive">
                                    {!this.state.loading && this.state.data.length === 0 ? (
                                        <Empty description={i18n.t("datakosong")}
                                               image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    ): (
                                        <Table className="table table-striped table-borderless mb-2">
                                            <Thead>
                                                <Tr>
                                                    <Th>#</Th>
                                                    <Th>{i18n.t('namasemester')}</Th>
                                                    <Th className="text-center">{i18n.t('jangkawaktusemester')}</Th>
                                                    <Th className="text-center">{i18n.t('status')}</Th>
                                                    <Th className="text-right">{i18n.t('aksi')}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {this.state.loading && (
                                                    <Tr>
                                                        <Td colSpan={5}>
                                                            <Spin size="large" tip="Loading..." style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                                marginBottom: '10px',
                                                                marginTop: '10px'
                                                            }} />
                                                        </Td>
                                                    </Tr>
                                                )}
                                                {this.state.data.map((item) => {
                                                    no += 1
                                                    return (
                                                        <Tr>
                                                            <Td>{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                            <Td className="text-capitalize">{i18n.t('semester') +' '+ item.semester_type +' '+ new Date(item.semester_start_date).getFullYear()}</Td>
                                                            <Td className="text-center">{moment(item.semester_start_date).format('LL')} - {item.semester_end_date ? moment(item.semester_end_date).format('LL') : i18n.t('saatini')}</Td>
                                                            <Td className={`${item.semester_status === 'active' ? 'text-success' : 'text-dark' } text-center`}>{item.semester_status === 'active' ? i18n.t('aktif'): i18n.t('selesai')}</Td>
                                                            <Td className="text-right">
                                                                <Tooltip trigger={'click'} placement={'bottomRight'} color={'#fff'} title={
                                                                    <div className="p-2 d-flex flex-column">
                                                                        <a className="text-dark" onClick={() => this.setState({
                                                                            semester_id: item.semester_id, edit_visible: true
                                                                        })}>{i18n.t('edit')}</a>
                                                                        <a className="text-danger" onClick={() => this.setState({
                                                                            semester_id: item.semester_id
                                                                        }, () => this.wantDelete())}>{i18n.t('hapus')}</a>
                                                                    </div>
                                                                }>
                                                                    <a className=""><i className="fa fa-ellipsis-h" style={{
                                                                        width: 35,
                                                                        fontSize: 18,
                                                                        padding: 5,
                                                                        color: 'rgb(51 51 51)'
                                                                    }}/></a>
                                                                </Tooltip>
                                                            </Td>
                                                        </Tr>
                                                    )
                                                })}
                                            </Tbody>
                                        </Table>
                                    )}
                                    <Paging
                                        className="float-right"
                                        handlePageClick={this.handlePageClick}
                                        pageCount={this.state.pageCount}
                                        currentPage={this.state.currentPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SemesterList);