import React, {Component} from 'react'
import {Button, Drawer, Form} from 'antd';
import Swal from "sweetalert2";
import {set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import i18n from 'i18next';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class Add extends Component {
    constructor() {
        super();
        this.state = {
            jabatan_nama: '',
            loading: false
        }
    }
    simpanJabatan = () => {
        let form = {
            name: this.state.jabatan_nama
        }

        this.props.request("jabatan/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    const filter_data = JSON.parse(localStorage.getItem('Presensi-Company-Data'))
                    if (filter_data && filter_data._jabatan) {
                        filter_data._jabatan.push({
                            jabatan_id: response.data.jabatan_id,
                            jabatan_nama: response.data.jabatan_nama,
                            perusahaan_id: response.data.perusahaan_id,
                            created_at: response.data.created_at,
                            updated_at: response.data.updated_at
                        });
                        localStorage.setItem('Presensi-Company-Data', JSON.stringify(filter_data));
                    }
                    this.setState({jabatan_nama: ''})
                    this.props.set_auth(this.props.api_token, response.perusahaan);
                    this.props.changeState({
                        jabatan_nama: '',
                        add_visible: false,
                        data: [],
                        isLoading: true,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success'
                    })
                }else {
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    render() {
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.add_visible} toggle={() => this.props.changeState({ add_visible: false })} style={{ maxWidth: "1000px" }}>
                <ModalHeader toggle={() => this.props.changeState({ add_visible: false })}>
                    <div className='text-dark' style={{
                        fontWeight: 'bold', fontSize: "18px"
                    }}>{i18n.t("tambahjabatan")}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="">
                        <Form layout="vertical" hideRequiredMark>
                            <div className="form-group">
                                <label>{i18n.t("namajabatan")}</label>
                                <input name="jabatan_nama" className="form-control col-xl-12 col-md-12"
                                       placeholder={i18n.t("placeholdernamajabatan")}
                                       type="text" required="" value={this.state.jabatan_nama}
                                       onChange={(event) => {
                                           const target = event.target;
                                           const value = target.value;
                                           const name = target.name;
                                           this.setState({
                                               [name]: value
                                           });
                                       }}/>
                            </div>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({add_visible: false, desc: ''});
                        }}
                                style={{marginRight: 8}}>
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color"
                                disabled={this.state.loading} onClick={this.simpanJabatan}>
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Add)
