import React, {Component} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import i18n from "i18next";
import {OrgDiagram} from 'basicprimitivesreact';
import {PageFitMode, Size, Enabled} from 'basicprimitives';
import "./structure.css"
import ZoomPanCanvas from "./canvas";
import {Spin} from "antd";
import Select from "react-select";
import Swal from "sweetalert2";

class ViewDivisiStr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view_chart: false,
            divisi_id: 0,
            loading: true,
            data: [],
            karyawan: [],
            data_karyawan: [],
            add_chart: false,
            karyawan_selected: null,
            karyawan_loading: false,
            karyawan_name: '',
            karyawan_id: 0,
            parent_id: 0,
            add: false
        }
    }

    addKaryawan(id) {
        console.log('iddd', id)
        let form = {
            karyawan_id: this.state.karyawan_id,
            divisi_id: this.state.divisi_id,
            parent_id: id ? id : this.state.parent_id
        }
        this.props.request("divisi/add-chart-karyawan", form, "POST")
        .then((response) => {
            if (response.success) {
                Swal.fire({
                    title: i18n.t('berhasil'),
                    text: i18n.t('berhasilmenambahkanstruktur'),
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 800,
                })
            }  else {
                Swal.fire({
                    title: i18n.t('gagal'),
                    text: i18n.t('gagalmenambahkanstruktur'),
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 1200,
                })
            }
            this.setState({
                add: false, karyawan_loading: false, karyawan_id: 0, data_karyawan: [], karyawan_selected: null, data: [],
            }, () => this.loadData())
        })
    }

    loadKaryawan() {
        let form = {
            divisi_id: this.state.divisi_id
        }
        this.props.request('divisi/load-karyawan', form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_karyawan: response.data,
                        loading: false
                    })
                }
                this.setState({
                    karyawan_loading: false,
                })
            })
    }

    simpanChart() {
        let form = {
            divisi_id: this.state.divisi_id,
            karyawan_id: this.state.karyawan_id
        }
        this.props.request('divisi/simpan-chart', form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('berhasilmenambahkanstruktur'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('gagalmenambahkanstruktur'),
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                }
                this.setState({
                    add_chart: false, karyawan_loading: false, data: [], karyawan_id: 0, data_karyawan: [], karyawan_selected: null,
                }, () => this.loadData())
            })
    }

    wantDelete(id) {
        Swal.fire({
            title: i18n.t('peringatan'),
            text: i18n.t('andayakininginmnghapusstruktur'),
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
            confirmButtonText: i18n.t('hapus'),
        })
        .then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    loading: true
                }, () => this.confirmDelete(id))
            }
        })
    }

    confirmDelete(id) {
        let form = {
            id: id,
            divisi_id: this.state.divisi_id
        }
        this.props.request("divisi/delete-chart-karyawan", form, 'POST')
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('berhasilmenghapusdata'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.setState({
                        data: [], loading: false
                    }, () => this.loadData())
                }
            })
    }

    loadData(id) {
        this.setState({loading: true})
        let form = {
            divisi_id: this.state.divisi_id,
        }
        this.props.request('divisi/get-structure', form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        loading: false
                    })
                }
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.divisi_id !== prevProps.divisi_id) {
            this.setState({
                divisi_id: this.props.divisi_id,
            }, () => {
                this.loadData(this.props.divisi_id);
            });
        }
    }

    render() {
        const config = {
            pageFitMode: PageFitMode.AutoSize,
            cousinsIntervalMultiplier: 1,
            defaultTemplateName: "lg",
            normalLevelShift: 25,
            normalItemsInterval: 30,
            autoSizeMinimum: new Size(5000, 5000),
            hasSelectorCheckbox: Enabled.False,
            cursorItem: 0,
            templates: [{
                name: "lg",
                itemSize: {width: this.props.isSekolah ? 180 : 250, height: 65},
                onItemRender: ({context: itemConfig}) => {
                    return <>
                        <div className="" style={{cursor: "pointer", borderRadius: '4px', border: '2px solid #8C2BEE' }}>
                            <div className=" " style={{
                                padding: "2px 0 2px 0",
                                backgroundColor: '#8C2BEE',
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: '4px'
                            }}>
                                <div className="text-center px-2"
                                     style={{color: "#fff"}}>{itemConfig.jabatan_nama}</div>
                            </div>
                            <div className="px-2 d-flex justify-content-center h-100">
                                <h1 className="lgTitle text-capitalize"
                                    style={{fontSize: 12, marginTop: '10px'}}>{itemConfig.karyawan_nama}</h1>
                            </div>
                        </div>
                        <div className="buttonParent">
                            <button key="1" className="styleButton" onClick={() => this.setState({
                                divisi_id: this.state.divisi_id,
                                parent_id: itemConfig.id,
                                add: true
                            })}>
                                +
                            </button>
                            <button key="1" className="styleButton" onClick={() => this.setState({
                                divisi_id: this.state.divisi_id,
                            }, () => this.wantDelete(itemConfig.id))}>
                                -
                            </button>
                        </div>
                    </>
                }
            }],
            items: this.state.data,
        };

        const isSekolah = this.props.isSekolah
        return (
            <>
                <Modal size='lg' style={{maxWidth: "1480px", minHeight: '500px'}} isOpen={this.props.view_chart}
                       toggle={() => this.props.changeState({
                           view_chart: false
                       })}>
                <ModalHeader toggle={() => this.props.changeState({
                        view_chart: false
                    })}>
                        <div style={{
                            color: "#143B5E",
                            fontSize: "24px",
                            fontWeight: 700,
                            lineHeight: "36px"
                        }}>{isSekolah ? i18n.t('structurekelas') : i18n.t('stucturedivisi')} {this.props.divisi_name}</div>
                    </ModalHeader>
                    <ModalBody className='p-0' style={{height: '580px'}}>
                        {this.state.loading ? (
                            <div className="d-flex align-items-center justify-content-center" style={{height: "100%"}}>
                                <Spin size="large" tip={i18n.t('loading...')} style={{
                                    width: '100%',
                                    margin: 'auto',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}/>
                            </div>
                        ) : (
                            <>
                                <ZoomPanCanvas footer={
                                    this.state.data.length === 0 && (
                                        <button
                                            className="btn ipresens-btn-color text-capitalize float-right ml-2 izin-btn-w100"
                                            style={{position: "absolute", right: 20, bottom: 20}}
                                            onClick={() => this.setState({
                                                add_chart: true,
                                            })}>Tambah</button>
                                    )
                                }>
                                    <OrgDiagram centerOnCursor={true} config={config}/>
                                </ZoomPanCanvas>
                            </>
                        )}

                    </ModalBody>
                </Modal>

                {/* Modal Tambah Karyawan Divisi First */}

                <Modal size='lg' style={{maxWidth: "300px", marginRight: 20, }} isOpen={this.state.add_chart}
                       toggle={() => this.setState({
                           add_chart: false, karyawan_selected: null, karyawan_id: 0, data_karyawan: [],
                       })}>
                    <ModalHeader toggle={() => this.setState({
                        add_chart: false, karyawan_selected: null, karyawan_id: 0, data_karyawan: [],
                    })}>{isSekolah ? i18n.t('tambahsiswa') : i18n.t('tambahkaryawan')}
                    </ModalHeader>
                    <ModalBody className='p-0' style={{height: '515px' }}>
                        <div className="px-2">
                            <Select
                                className="font-edit-value"
                                placeholder={isSekolah ? i18n.t('namasiswa') :i18n.t('namakaryawan')}
                                backspaceRemoves={true}
                                deleteRemoves={true}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null
                                }}
                                value={this.state.karyawan_selected}
                                isLoading={this.state.karyawan_loading}
                                onFocus={() => this.setState({karyawan_loading: true}, () => this.loadKaryawan())}
                                onBlur={() => this.setState({karyawan_loading: false})}
                                onInputChange={(val) => this.setState({karyawan_name: val}, () => this.setState({karyawan_loading: true}, () => this.loadKaryawan()))}
                                options={
                                    this.state.data_karyawan != null ? (
                                            this.state.data_karyawan.map((item, index) =>
                                                (
                                                    {
                                                        key: index,
                                                        value: item.karyawan_id,
                                                        label: item.karyawan_nama,
                                                    }
                                                )
                                            ))
                                        : ("")
                                }
                                onChange={
                                    (karyawan_selected) => {
                                        if (!karyawan_selected) {
                                            this.setState({
                                                karyawan_selected: '',
                                                karyawan_id: 0,
                                            })
                                        } else {
                                            this.setState({
                                                karyawan_selected,
                                                karyawan_id: karyawan_selected.value,
                                                data_karyawan: []
                                            })
                                        }
                                    }
                                }
                                isClearable={() => {
                                    this.setState({
                                        isClearable: true,
                                    })
                                }}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter style={{
                        backgroundColor: "#F9F9F9",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px"
                    }} className='px-4 py-3'>
                        <button className='btn text-capitalize batalkan-add-jamker' onClick={() => {
                            this.setState({
                                modalAdd: false,
                                id_jamkerja: [],
                                activeTab: 0,
                                id_jabatan: [],
                                divisi_name: "",
                                divisi_payroll_type: "",
                                checked_all: false
                            })
                        }}>{i18n.t("batalkan")}</button>
                        <button className='btn btn-primary text-capitalize' onClick={() => this.simpanChart()}>{i18n.t("simpan")}</button>
                    </ModalFooter>
                </Modal>

            {/* Tambah Chart Karyawan */}
                <Modal size='lg' style={{maxWidth: "400px", marginTop: 100 }} isOpen={this.state.add}
                       toggle={() => this.setState({
                           add: false, karyawan_selected: null, karyawan_id: 0, data_karyawan: [],
                       })}>
                    <ModalHeader toggle={() => this.setState({
                        add: false, karyawan_selected: null, karyawan_id: 0, data_karyawan: [],
                    })}>{isSekolah ? i18n.t('tambahsiswa') : i18n.t('tambahkaryawan')}
                    </ModalHeader>
                    <ModalBody className='p-0' style={{height: '100px' }}>
                        <div className="px-2">
                            <Select
                                className="font-edit-value"
                                placeholder={isSekolah ? i18n.t('namasiswa') : i18n.t('namakaryawan')}
                                backspaceRemoves={true}
                                deleteRemoves={true}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null
                                }}
                                value={this.state.karyawan_selected}
                                isLoading={this.state.karyawan_loading}
                                onFocus={() => this.setState({karyawan_loading: true}, () => this.loadKaryawan())}
                                onBlur={() => this.setState({karyawan_loading: false})}
                                onInputChange={(val) => this.setState({karyawan_name: val}, () => this.setState({karyawan_loading: true}, () => this.loadKaryawan()))}
                                options={
                                    this.state.data_karyawan != null ? (
                                            this.state.data_karyawan.map((item, index) =>
                                                (
                                                    {
                                                        key: index,
                                                        value: item.karyawan_id,
                                                        label: item.karyawan_nama,
                                                    }
                                                )
                                            ))
                                        : ("")
                                }
                                onChange={
                                    (karyawan_selected) => {
                                        if (!karyawan_selected) {
                                            this.setState({
                                                karyawan_selected: '',
                                                karyawan_id: 0,
                                            })
                                        } else {
                                            this.setState({
                                                karyawan_selected,
                                                karyawan_id: karyawan_selected.value,
                                                data_karyawan: []
                                            })
                                        }
                                    }
                                }
                                isClearable={() => {
                                    this.setState({
                                        isClearable: true,
                                    })
                                }}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter style={{
                        backgroundColor: "#F9F9F9",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px"
                    }} className='px-4 py-3'>
                        <button className='btn text-capitalize batalkan-add-jamker' onClick={() => {
                            this.setState({
                                add: false
                            })
                        }}>{i18n.t("batalkan")}</button>
                        <button className='btn btn-primary text-capitalize' onClick={() => this.addKaryawan()}>{i18n.t("simpan")}</button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

export default ViewDivisiStr;