import React, {Component} from 'react'
import {Form} from 'antd';
import Swal from "sweetalert2";
import i18n from "i18next";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class IstillahAdd extends Component {
    constructor() {
        super();
        this.state = {
            p_term_name: '',
            p_term_type: '',
            loading: false
        }
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        let form = {
            p_term_type: this.state.p_term_type,
            p_term_name: this.state.p_term_name
        }

        this.props.request("payroll/add-payroll-term", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({p_term_name: ''})
                    this.props.changeState({
                        p_term_name: '',
                        p_term_type: '',
                        add_visible: false,
                        data: [],
                        isLoading: true,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false
                })
            });
    }

    render() {
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.add_visible} toggle={() => this.props.changeState({ add_visible: false })} style={{ maxWidth: "1000px" }}>
                <ModalHeader toggle={() => this.props.changeState({ add_visible: false })}>
                    <div className='text-dark' style={{
                        fontWeight: 'bold', fontSize: "18px"
                    }}>{i18n.t("tambahistilah")}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="">
                        <Form layout="vertical" hideRequiredMark>
                            <div className="form-group">
                                <label>{i18n.t("namaistilah")}</label>
                                <input name="p_term_name"
                                       className="form-control col-xl-12 col-md-12"
                                       placeholder={i18n.t("placeholdername")}
                                       type="text" required="" value={this.state.p_term_name}
                                       onChange={(event) => this.handleChange(event)}/>
                            </div>
                            <div className="form-group">
                                <label>{i18n.t('tipeistilah')}</label>
                                <select
                                    name="p_term_type"
                                    className="form-control col-xl-12 col-md-12"
                                    value={this.state.p_term_type}
                                    onChange={(event) => this.handleChange(event)}>

                                    <option value="">{i18n.t("pilihtipeistilah")}</option>
                                    <option value="kompensasi">{i18n.t("kompensasigaji")}</option>
                                    <option value="potongan">{i18n.t("potongangaji")}</option>

                                </select>
                            </div>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div style={{ textAlign: 'right'}}>
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({add_visible: false});
                        }} style={{marginRight: 8}}>
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color" disabled={this.state.loading} onClick={this.handleSubmit}>
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

export default IstillahAdd
