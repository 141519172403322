import React, {Component, Fragment} from 'react'
import {Empty, Spin} from "antd";
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {pageNumber} from "../../functions/functionGeneral";
import Paging from "../../components/paging";
import format from "format-number";
import moment from "moment";
import {Else, If, Then} from "react-if-elseif-else-render";
import EmptyDataImage from "../../assets/images/data-kosong.png"
import i18n from 'i18next';
import {getCurrency} from "../../helper/money";

export class Kasbon extends Component {
    constructor(props) {
        super(props);

        this.state      = {
            loading         : false,
            data            : [],
            pageCount       : 0,
            currentPage     : 0,
            totalData       : 0,
            perPage         : 0,
            paging          : 20,
        }

    }

    loadData() {
        this.setState({loading: true})
        let form = {
            paginate        : 10,
            page            : this.state.currentPage + 1,
            karyawan_id         : this.props.match.params.id,
        }
        this.props.request("kasbon/kasbon_by_karywan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data            : response.data.data,
                        pageCount       : response.data.last_page,
                        perPage         : response.data.per_page,
                        loading         : false
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0
        let currency = this.props.currency;
        return (
            <Fragment>
                <div className="card-body daftar-karyawan">
                    {!this.state.loading && this.state.data.length === 0 ?
                        <Empty description="Data Kosong"
                               image={EmptyDataImage} className="ipresens-image-empty"/>
                        :
                        <Table striped className="mb-5">
                            <Thead>
                            <Tr>
                                <Th width="30">#</Th>
                                <Th className="text-center">{i18n.t('pembayarankabonnominal')}</Th>
                                <Th className="text-center">{i18n.t('pembayarankasbontenor')}</Th>
                                <Th className="text-center">{i18n.t('pembayarankasboncicilan')}</Th>
                                <Th className="text-center">{i18n.t('pembayarankasbondeskripsi')}</Th>
                                <Th className="text-center">{i18n.t('pembayarankasbonnote')}</Th>
                                <Th className="text-center">Status</Th>
                                <Th className="text-center">{i18n.t('pembayarankasbontanggal')}</Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                            {this.state.loading &&
                            <Tr>
                                <Td colSpan={10}>
                                    <Spin size="large" tip="Loading..." style={{
                                        width: '100%',
                                        margin: 'auto',
                                        marginBottom: '10px',
                                        marginTop: '10px'
                                    }}/>
                                </Td>
                            </Tr>
                            }
                            {this.state.data.map((item, index) => {
                                no += 1
                                return (
                                    <Tr>
                                        <small>
                                            <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                        </small>
                                        <Td className="text-center">
                                            {format({ prefix: getCurrency(currency), integerSeparator: "." })(item.kasbon_nominal)}
                                        </Td>
                                        <Td className="text-center">
                                            {item.kasbon_tenor}
                                        </Td>
                                        <Td className="text-center">
                                            {format({ prefix: getCurrency(currency), integerSeparator: "." })(item.kasbon_cicilan)}
                                        </Td>
                                        <Td className="text-center">
                                            {item.kasbon_desc}
                                        </Td>
                                        <Td className="text-center">
                                            {item.kasbon_note}
                                        </Td>
                                        <Td className="text-center">
                                            <If condition={item.kasbon_finish == '1'}>
                                                <Then>
                                                    <span className="badge badge-success">{i18n.t('lunas')}</span>
                                                </Then>
                                                <Else>
                                                    <span className="badge badge-danger">{i18n.t('belumlunas')}</span>
                                                </Else>
                                            </If>
                                        </Td>
                                        <Td className="text-center">
                                            {moment(item.kasbon_tanggal).format('ll')}
                                        </Td>
                                    </Tr>
                                )
                            })}
                            </Tbody>
                        </Table>
                    }
                    <Paging
                        className="float-right"
                        handlePageClick={this.handlePageClick}
                        pageCount={this.state.pageCount}
                        currentPage={this.state.currentPage}
                    />

                </div>
            </Fragment>
        )
    }
}

export default (Kasbon)
