import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import i18n from "i18next";
import Breadcrumb from "../common/breadcrumb";
import {Empty, Spin, Tooltip} from "antd";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import RefreshIcon from "../../assets/icons/refresh.svg";
import SearchIcon from "../../assets/icons/search.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import AddFacility from "./add";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {pageNumber} from "../../functions/functionGeneral";
import {DEFAULT_IMG, IMG_HOST} from "../../helper/host";
import EditFacility from "./edit";
import Swal from "sweetalert2";
import Paging from "../paging";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ReactPaginate from 'react-paginate';
import moment from "moment";

export class PerusahaanFacility extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter_nama: '',
            paging: 5,
            page: 0,
            data: [],
            loading: false,
            add_visible: false,
            edit_visible: false,
            perPage: 0,
            currentPage: 0,
            facility_id: 0,
            facility_name: '',
            modal_history: false,
            history: [],
            loading_history: false,
            totalDataHistory: 0,
            pageCountHistory: 0,
            perPageHistory: 0,
            currentPageHistory: 0,
        }
        this.handleChange = this.handleChange.bind(this)
        this.resetFilter = this.resetFilter.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handlePageHistory = this.handlePageHistory.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter() {
        this.setState({
            filter_nama: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    loadData() {
        let form = {
            filter_nama: this.state.filter_nama,
            paging: this.state.paging,
            page: this.state.currentPage + 1,
        }
        this.props.request('fasilitas/load-data', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false,
                    })
                }
            })
    }

    handlePageClick(data) {
        let selected = data.selected;
        this.setState({ currentPage: selected, loading: true, data: [] }, () => {
            this.loadData();
        });
    }

    handlePageHistory(history) {
        let selected = history.selected;
        this.setState({ currentPageHistory: selected, loading_history: true, history: [] }, () => {
            this.loadDataHistory();
        });
    }

    wantDelete() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinhapusfasilitas'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        facility_id: this.state.facility_id
                    }
                    this.setState({
                        data: [],
                        loading: true
                    })
                    this.props.request("fasilitas/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('berhasilmenghapusfasilitas'),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadDataHistory(id) {
        this.setState({ loading_history: true })
        let form = {
            facility_id: this.state.facility_id || id,
            paging: 2,
            page: this.state.currentPageHistory + 1,
        }
        this.props.request('fasilitas/history', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        history: response.data.data,
                        loading_history: false,
                        totalDataHistory: response.data.total,
                        pageCountHistory: response.data.last_page,
                        perPageHistory: response.data.per_page,
                    })
                }
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        const stylefoto = {
            maxWidth: 130,
            borderRadius: "4px",
            objectFit: "cover",
        }
        const isSekolah = this.props.user.perusahaan.perusahaan_type === 'sekolah';

        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{isSekolah ? i18n.t('fasilitassekolah') : i18n.t('fasilitasperusahaan')} - iPresens</title>
                    <meta name="description" content="Kebijakan"/>
                </Helmet>

                <Breadcrumb title={isSekolah ? i18n.t('fasilitassekolah') : i18n.t('fasilitasperusahaan')}/>

                <AddFacility add_visible={this.state.add_visible}
                             request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                             changeState={(state, callback) => {
                                 this.setState(state, () => {
                                     if (typeof callback != 'undefined')
                                         callback()
                                 })
                             }}
                             loadData={() => this.loadData()}
                />

                <EditFacility edit_visible={this.state.edit_visible}
                              id={this.state.facility_id}
                              request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                              changeState={(state, callback) => {
                                  this.setState(state, () => {
                                      if (typeof callback != 'undefined')
                                          callback()
                                  })
                              }}
                              loadData={() => this.loadData()}
                />

                <Modal zIndex="999" size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modal_history} toggle={() => this.setState({ modal_history: false })}>
                    <ModalHeader toggle={() => this.setState({ modal_history: false })} className="pengumuman-modal-header">
                        <div className='pengumuman-modal-header-font'>{isSekolah ? i18n.t("riwayatfasilitassekolah") : i18n.t("riwayatfasilitasperusahaan")}</div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="list-group">
                            <div className="">
                                {this.state.loading_history && (
                                    <div style={{textAlign: "center", margin: '10px auto'}}>
                                        <Spin size="large" tip={i18n.t('loading')}
                                              style={{width: "100%", margin: "auto"}}/>
                                    </div>
                                )}
                                {this.state.history.length !== 0 && (
                                    <div>
                                        <Row>
                                            <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                <h4>{i18n.t('user')}</h4>
                                            </Col>
                                            <Col xs={0} md={0} className="text-left"style={{ marginRight: '20px' }}>
                                                <h4>{i18n.t('aktivitas')}</h4>
                                            </Col>
                                        </Row>

                                        {this.state.history.map((item, index) => {
                                            return (
                                                <div key={index} style={{ marginBottom : "20px" }}>
                                                    <Row>
                                                        <Col xs={12} md={6} className="text-left">
                                                            <div style={{ display: 'flex', alignItems: 'center', padding: '16px 12px' }}>
                                                                <div style={{marginRight: '16px'}}>
                                                                    <img
                                                                        src={DEFAULT_IMG}
                                                                        alt=""
                                                                        style={{ width: '48px' , marginBottom:'25px', height:'48px'}}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <h6 className="mb-0 font-13" style={{
                                                                        fontWeight: 500,
                                                                        fontSize: '16px',
                                                                        lineHeight: '24px'
                                                                    }}>{item.p_user_name} | {item.level_name}</h6>
                                                                    <p className="m-0 font-12">
                                                                            <span
                                                                                className="col-green font-weight-bold">
                                                                              {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                    </p>
                                                                    <div style={{ marginTop: 8 }}>
                                                                        {item.history_status === "created" ? (
                                                                            <div
                                                                                className="m-0"
                                                                                style={{
                                                                                    border: '1px solid #000',
                                                                                    borderRadius: '25px',
                                                                                    width: '50%',
                                                                                    color: '#057CE4',
                                                                                    borderColor: '#057CE4',
                                                                                }}
                                                                            >
                                                                                <div className="d-flex justify-content-center" style={{
                                                                                    paddingTop: '6px',
                                                                                    paddingBottom: '6px'
                                                                                }}>
                                                                                    {i18n.t('dibuat')}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                className="m-0"
                                                                                style={{
                                                                                    border: '1px solid #000',
                                                                                    borderRadius: '25px',
                                                                                    width: '50%',
                                                                                    color: '#E6462E',
                                                                                    borderColor: '#E6462E',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="d-flex justify-content-center"
                                                                                    style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                    {i18n.t('diubah')}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} md={6} className="text-left" style={{
                                                            padding: '28px 12px 16px',
                                                        }}>
                                                            {(item.history_status !== 'created' && item.history) && (
                                                                <div>
                                                                    {item.history?.facility_name !== item.facility_name && (
                                                                        <div>
                                                                            <h6
                                                                                className="mb-3 font-13 text-left"
                                                                                style={{
                                                                                    fontWeight: 100,
                                                                                    fontSize: '16px',
                                                                                    lineHeight: '24px',
                                                                                }}
                                                                            >
                                                                                {i18n.t('namafasilitas')} : {i18n.t('dari')}{' '}
                                                                                {item.history?.facility_name}
                                                                                <img
                                                                                    src={ArrowRight}
                                                                                    alt=""
                                                                                    style={{
                                                                                        width: '20x',
                                                                                        marginBottom: '1px',
                                                                                        height: '18px'
                                                                                    }}
                                                                                /> {i18n.t('jadi')}
                                                                                : {item?.facility_name}
                                                                            </h6>
                                                                        </div>
                                                                    )}
                                                                    {item.history.facility_desc !== item.facility_desc && (
                                                                        <div>
                                                                            <h6
                                                                                className="mb-3 font-13 text-left"
                                                                                style={{
                                                                                    fontWeight: 100,
                                                                                    fontSize: '16px',
                                                                                    lineHeight: '24px',
                                                                                }}
                                                                            >
                                                                                {i18n.t('deskripsifasilitas')} : {i18n.t('dari')}{' '}
                                                                                {item.history?.facility_desc}
                                                                                <img
                                                                                    src={ArrowRight}
                                                                                    alt=""
                                                                                    style={{
                                                                                        width: '20x',
                                                                                        marginBottom: '1px',
                                                                                        height: '18px'
                                                                                    }}
                                                                                /> {i18n.t('jadi')}
                                                                                : {item?.facility_desc}
                                                                            </h6>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.history.length !== 0 &&
                            <>
                                <div className='col font-14' style={{
                                    padding: "auto",
                                    margin: "auto",
                                    fontWeight: 500,
                                    lineHeight: "24px"
                                }}>{i18n.t('menampilkan')} {this.state.history.length === 0 || this.state.history.length === null ? "0" : this.state.history.length} {i18n.t('dari')} {this.state.totalDataHistory} {i18n.t('data')}
                                </div>
                                <div className='col'>
                                    <ReactPaginate
                                        previousLabel={<img src={ArrowLeft} alt=''/>}
                                        nextLabel={<img src={ArrowRight} alt=''/>}
                                        pageCount={this.state.pageCountHistory}
                                        forcePage={this.state.currentPageHistory}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={this.handlePageHistory}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link custom-page-link'}
                                        containerClassName={'pagination my-2'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link custom-page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link custom-prev-next-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link custom-prev-next-link'}
                                        activeClassName={'active custom-active'}
                                    />
                                </div>
                            </>
                        }
                    </ModalFooter>
                </Modal>

                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header border-0 px-4 pb-3">
                            <div className='row'>
                                <div className='col-md-6 pengumuman-col-filter'>
                                    <div className='flex-row flex-nowrap'>
                                        <div className='input-group btn-srch-pengumuman  mr-1'>
                                                <span className='input-group-text pengumuman-filter-nama-input-text'>
                                                    <img alt='' src={SearchIcon}/>
                                                </span>
                                            <input className='form-control mr-2 pengumuman-filter-nama-input'
                                                   type='text' placeholder={i18n.t("carifasilitas")}
                                                   onKeyUp={(event) => {
                                                       if (event.which === 13) {
                                                           event.preventDefault()
                                                           this.setState({
                                                               page: 0,
                                                               paging: this.state.paging,
                                                               loading: true,
                                                               data: []
                                                           }, () => {
                                                               this.loadData()
                                                           });
                                                       }
                                                   }} onChange={this.handleChange} name="filter_nama"
                                                   value={this.state.filter_nama}/>
                                            <button className='btn ipresens-btn-reset-filter'
                                                    onClick={this.resetFilter}>
                                                <img alt='' src={RefreshIcon}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <button className='ipresens-btn-add-pengumuman btn-add-pengumuman'
                                            onClick={() => this.setState({
                                                add_visible: true
                                            })}><img alt='' src={PlusIcon}/> {i18n.t("tambahfasilitas")}</button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body mt-0 pt-0">
                            {!this.state.loading && this.state.data.length === 0 ? (
                                <Empty description={i18n.t('datakosong')}
                                       image={EmptyDataImage}
                                       className="ipresens-image-empty mb-5"/>
                            ): (
                                <Table className="mb-5 table table-borderless table-striped">
                                    <Thead>
                                        <Tr>
                                            <Th className="pengumuman-th-table" style={{ width: '20px'}}>#</Th>
                                            <Th className="pengumuman-th-table" style={{ width: '200px' }}>{i18n.t('fotofasilitas')}</Th>
                                            <Th className="pengumuman-th-table" style={{ minWidth: '180px'}}>{i18n.t('namafasilitas')}</Th>
                                            <Th className="pengumuman-th-table">{i18n.t('deskripsifasilitas')}</Th>
                                            <Th className="pengumuman-th-table text-center" style={{ width: '30px'}}>{i18n.t('aksi')}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {this.state.loading &&
                                            <Tr>
                                                <Td colSpan={5}>
                                                    <Spin size="large" tip="Loading..." style={{
                                                        width: '100%',
                                                        margin: 'auto',
                                                        marginBottom: '10px',
                                                        marginTop: '10px'
                                                    }} />
                                                </Td>
                                            </Tr>
                                        }
                                        {this.state.data.map((item) => {
                                            no += 1
                                            return (
                                                <Tr>
                                                    <Td className="pengumuman-td-table" style={{ width: '20px'}}>{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                    <Td className="pengumuman-td-table" style={{ width: '200px' }}>
                                                        <a href={item.facility_image != null ? IMG_HOST + 'mitra/facility/' + item.facility_image : DEFAULT_IMG}
                                                           target={"_blank"}>
                                                            <img style={stylefoto}
                                                                 src={item.facility_image != null ? IMG_HOST + 'mitra/facility/' + item.facility_image : null}
                                                                 alt="Facility Image"/>
                                                        </a>
                                                    </Td>
                                                    <Td className="pengumuman-td-table" style={{ minWidth: '180px'}}>{item.facility_name}</Td>
                                                    <Td className="pengumuman-td-table">{item.facility_desc}</Td>
                                                    <Td className="pengumuman-td-table text-center" style={{ width: '30px'}}>
                                                        <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                                            <div className={'p-2 d-flex flex-column'}>

                                                                <a className="text-dark"
                                                                   onClick={() => this.setState({
                                                                       facility_id: item.facility_id,
                                                                       modal_history: true,
                                                                   }, () => this.loadDataHistory(item.facility_id))}>{i18n.t('riwayat')}</a>

                                                                <a className="text-dark"
                                                                   onClick={() => this.setState({
                                                                       facility_id: item.facility_id,
                                                                       edit_visible: true,
                                                                   })}>{i18n.t('edit')}</a>

                                                                <a className="text-danger"
                                                                   onClick={() => this.setState({
                                                                       facility_id: item.facility_id,
                                                                   }, () => this.wantDelete())}>{i18n.t('hapus')}</a>

                                                            </div>
                                                        }>
                                                            <a><i className="fa fa-ellipsis-h" style={{
                                                                width: 35,
                                                                fontSize: 18,
                                                                padding: 11,
                                                                color: 'rgb(51 51 51)'
                                                            }}/></a>
                                                        </Tooltip>
                                                    </Td>
                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                            )}
                            <Paging
                                className="float-right"
                                handlePageClick={this.handlePageClick}
                                pageCount={this.state.pageCount}
                                currentPage={this.state.currentPage}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        )

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PerusahaanFacility);