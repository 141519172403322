import React, { Component, Fragment } from 'react';
import { Button, ButtonGroup, } from "reactstrap"
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import "./elipsis.css";
import {Empty, Spin} from "antd";
import { pageNumber } from "../../functions/functionGeneral";
import moment from "moment";
import format from "format-number";
import Paging from "../../components/paging";
import Tour from "reactour";
import "./style.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumb from '../common/breadcrumb';
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import i18n from 'i18next';
import {getCurrency} from "../../helper/money";

export class KasbonBayar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataBayar: [],
            loading: true,
            loadingSave: true,
            visible: false,
            visibleEdit: false,
            pilihBon: null,
            filter_nama: "",
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
        }
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSelectBon = this.handleSelectBon.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
    }

    handleSelectBon = (pilihBon) => {
        this.setState({
            pilihBon,
            isLoading: true,
            kasbon_id: pilihBon.value,
            bon_id: pilihBon.value,
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter(event) {

        this.setState({
            filter_nama: "",
            dataBayar: [],
            loading: true
        }, () => this.loadData())
    }



    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }


    loadData() {
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("kasbon/list-kasbon-bayar", form, "POST")
            .then(response => {
                if (response.success) {
                    var data = response.data
                    this.setState({
                        dataBayar: data.data,
                        pageCount: data.last_page,
                        perPage: data.per_page,
                        loading: false,
                        loadingSave: false,
                    })
                }
            })
    }


    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0
        const currency = this.props.user.perusahaan.perusahaan_currency;
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Pembayaran Kasbon") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t('headpembayarankasbon')}</title>
                        <meta name="description" content={i18n.t('titlepembayarankasbon')} />
                    </Helmet>
                    <Breadcrumb title={i18n.t('titlepembayarankasbon')} parent={i18n.t('titlepembayarankasbon')} setGuide={() => {
                        this.setState({ guide: true })
                    }} />
                    <Tour isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody}
                        onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid list-karyawan">
                        <div className="card">
                            <div className="card-header">
                                <h5>{i18n.t('titlepembayarankasbon')}</h5>
                                <div className="row filter-karyawan" style={{ marginLeft: '5px' }}>
                                    <div className="col-md-3">
                                        <label style={{ fontSize: '10px' }}>{i18n.t('filterpembayarankasbon')}</label><br />
                                        <input name="filter_nama"
                                            className="form-control"
                                            placeholder={i18n.t('placeholderfilterpembayarankasbon')}
                                            type="text"
                                            required=""
                                            value={this.state.filter_nama}
                                            onChange={this.handleChange}
                                            onKeyUp={(event) => {
                                                event.preventDefault()
                                                if (event.which == 13) {

                                                    this.setState({
                                                        page: 0,
                                                        paging: this.state.paging,
                                                        loading: true
                                                    }, () => {
                                                        this.loadData()
                                                    });
                                                }
                                            }} />
                                    </div>
                                    <div className="col-md-2">
                                        <label style={{ fontSize: '10px' }}>{i18n.t('aturfilterpembayarankasbon')}</label><br />
                                        <button className="btn btn-danger" onClick={this.resetFilter}><span className="fa fa-refresh"></span> {i18n.t('aturulangfilterpembayarankasbon')}</button>
                                    </div>
                                </div>


                            </div>

                            <br />
                            <div className="card-body">
                                {!this.state.loading && this.state.dataBayar.length === 0 ?
                                    <Empty description="Data Kosong"
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    <Table responsive className="table table-striped table-borderless">
                                        <Thead>
                                            <Tr>
                                                <Th>#</Th>
                                                <Th className="text-center">{i18n.t('namakaryawan')}</Th>
                                                <Th className="text-center">{i18n.t('totalkasbon')}</Th>
                                                <Th className="text-center">{i18n.t('totalbayar')}</Th>
                                                <Th className="text-center">{i18n.t('sisakasbon')}</Th>
                                                <Th className="text-center">{i18n.t('tanggalterakhir')}</Th>
                                                <Th className="text-center">Detail</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                this.state.dataBayar.map((item, index) => {
                                                    no += 1
                                                    return (
                                                        <Tr key={index}>
                                                            <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                            <Td className="text-center">
                                                                {item.karyawan_nama}
                                                            </Td>
                                                            <Td className="text-center">
                                                                {format({
                                                                    prefix: getCurrency(currency),
                                                                    integerSeparator: "."
                                                                })(item.jumlah_kasbon)}
                                                            </Td>
                                                            <Td className="text-center">
                                                                {format({
                                                                    prefix: getCurrency(currency),
                                                                    integerSeparator: "."
                                                                })(item.jumlah_pembayaran)}
                                                            </Td>
                                                            <Td className="text-center">
                                                                {format({
                                                                    prefix: getCurrency(currency),
                                                                    integerSeparator: "."
                                                                })(item.sisa_kasbon)}
                                                            </Td>
                                                            <Td className="text-center">
                                                                {
                                                                    item.tanggal_pembayaran !== null ? (
                                                                        moment(item.tanggal_pembayaran).format('ll')
                                                                    ) : ('-')
                                                                }

                                                            </Td>
                                                            <Td className="text-center">
                                                                <ButtonGroup size="sm">
                                                                    <div>
                                                                        <Link
                                                                            to={"/pembayaran-kasbon/detail/" + item.karyawan_id}
                                                                            target="_blank">
                                                                            <button className="btn ipresens-btn-color">
                                                                                <i className="fa fa-info"></i>
                                                                            </button>
                                                                        </Link>
                                                                    </div>
                                                                </ButtonGroup>
                                                            </Td>
                                                        </Tr>
                                                    )
                                                })
                                            }
                                            {this.state.loading &&
                                                <Tr>
                                                    <Td colSpan={9}>
                                                        <Spin size="large" tip={i18n.t('loading...')} style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </Td>
                                                </Tr>
                                            }
                                        </Tbody>
                                    </Table>
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(KasbonBayar);
