import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import { set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import RefreshIcon from "../../assets/icons/refresh.svg";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import JamKerjaDivisi from "../divisi_karyawan/jam-kerja-divisi";
import JabatanDivisi from "../divisi_karyawan/jabatan-divisi";
import Switch from "react-switch";
import Tour from "reactour";
import i18n from 'i18next';

export class BPJSSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAdd: false,
            prosesSimpan: false,
            isLoading: true,
            payroll_note: '',
            bpjs_data: [],
            jkk_type: [],
            jkm_type: [],
            selected_bpjs: '',
            selected_jkk: '',
            selected_jkm: '',
            selected_jkk_covered: false,
            selected_jkm_covered: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let arr = this.state.bpjs_data
        let item = arr.find(i => i.bpjs_setting_id.toString() === value.toString());

        if (item) {
            this.setState({
                [name]: item,
                selected_jkk: item.bpjs_setting_jkk,
                selected_jkm: item.bpjs_setting_jkm,
                selected_jkk_covered: item.bpjs_setting_jkk_covered === 1 ? true : false,
                selected_jkm_covered: item.bpjs_setting_jkm_covered === 1 ? true : false,
            }, () => this.loadData())
        }


    }

    addSetting() {
        this.setState({
            disable_add: true
        })
        let form = {
            name: this.state.setting_name,
        }
        this.props.request("bpjs/add", form, "POST")
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('pengaturanbpjsberhasil'),
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                    this.setState({
                        setting_name: "",
                        modalAdd: false,
                    });
                    this.loadData()
                } else {
                    this.setState({
                        disable_add: false
                    })
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('pengaturanbpjsgagal'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    editSetting() {
        this.setState({
            prosesSimpan: true
        })
        let form = {
            id: this.state.selected_bpjs.bpjs_setting_id,
            jkk: this.state.selected_jkk,
            jkm: this.state.selected_jkm,
            jkk_covered: this.state.selected_jkk_covered ? 1 : 0,
            jkm_covered: this.state.selected_jkm_covered ? 1 : 0,
        }
        this.props.request("bpjs/edit", form, "POST")
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('editpengaturanbpjsberhasil'),
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                    this.loadData()
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('editpengaturanbpjsgagal'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false
                })
            })
    }

    deleteSetting() {
        Swal.fire({
            title: i18n.t('yakinhapus'),
            text: i18n.t('pesanhapusbpjs'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18n.t('hapus'),
            cancelButtonText: i18n.t('batal'),
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    prosesSimpan: true
                })
                let form = {
                    id: this.state.selected_bpjs.bpjs_setting_id,
                }
                this.props.request("bpjs/delete", form, "POST")
                    .then((response) => {
                        if (response.success) {
                            Swal.fire({
                                title: i18n.t('berhasil'),
                                text: i18n.t('hapuspengaturanbpjsberhasil'),
                                icon: 'success',
                                confirmButtonText: 'OK'
                            })
                            this.setState({
                                selected_bpjs: '',
                                bpjs_data: [],
                            })
                            this.loadData()
                        } else {
                            Swal.fire({
                                title: i18n.t('gagal'),
                                text: i18n.t('hapuspengaturanbpjsgagal'),
                                icon: 'warning',
                                confirmButtonText: 'OK'
                            })
                        }
                        this.setState({
                            prosesSimpan: false
                        })
                    })
            }
        })
    }

    loadData() {
        this.setState({
            loading: true
        })
        let form = {}
        this.props.request("bpjs/list", form, 'POST')
            .then(response => {
                if (response) {
                    this.setState({
                        bpjs_data: response.data,
                        jkk_type: response.jkk,
                        jkm_type: response.jkm,
                    })
                }
            })
        this.setState({
            loading: false
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        const steps = [
            {
                selector: '.bpjs',
                content: i18n.t("stepbpjs"),
            },
        ];

        const selectMapping = {
            1: i18n.t('jkk1'),
            2: i18n.t('jkk2'),
            3: i18n.t('jkk3'),
            4: i18n.t('jkk4'),
            5: i18n.t('jkk5'),
        };

        const bpjsLangSwitch = (idx) => {
            return selectMapping[idx] || "";
        }


        const style_add = {
            modal_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px",
            },
            title_header: {
                color: "#143B5E",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px"
            },
            label_input: {
                color: "#143B5E",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            },
            input_group_text: {
                backgroundColor: "#EFEFEF",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                padding: "12px"
            },
            input: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            select: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                minHeight: "44px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            card_header: {
                backgroundColor: "#FAFDFF",
                padding: "10px 16px",
                color: "#143B5E",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px"
            },
            card_body: {
                backgroundColor: "#F9F9F9",
                padding: "12px 16px"
            },
            column_card_body: {
                backgroundColor: "#F9F9F9",
                textAlign: "center"
            }
        }
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("BPJS") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("pengaturanbpjs")} - iPresens</title>
                        <meta name="description" content={i18n.t("pengaturanbpjs")} />
                    </Helmet>
                    <Breadcrumb title={`${i18n.t("pengaturanbpjs")} iPresens`} setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <Modal size='lg' style={{ maxWidth: "1000px" }} isOpen={this.state.modalAdd} toggle={() => this.setState({
                        modalAdd: false,
                        setting_name: "",
                    })}>
                        <ModalHeader style={style_add.modal_header} toggle={() => this.setState({
                            modalAdd: false,
                            id_jamkerja: [],
                            activeTab: 0,
                            id_jabatan: [],
                            divisi_name: "",
                            divisi_payroll_type: "",
                        })}>
                            <div style={style_add.title_header}>{i18n.t("tambahpengaturanbpjs")}</div>
                        </ModalHeader>
                        <ModalBody className='p-0' style={{ backgroundColor: "#F9F9F9" }}>
                            <div className='row pt-4 px-4 pb-3 mx-0 mb-2' style={{ backgroundColor: "#FFFFFF" }}>
                                <div className='col-md-8 pl-0'>
                                    <label className='form-label' style={style_add.label_input}>{i18n.t("namapengaturanbpjs")}</label>
                                    <input className='form-control' onChange={(e) => this.handleChange(e)} value={this.state.setting_name} placeholder={i18n.t("ketikpengaturanbpjs")} name="setting_name" style={style_add.input} />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            backgroundColor: "#F9F9F9",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px"
                        }} className='px-4 py-3'>
                            <button className='btn text-capitalize batalkan-add-jamker' onClick={() => {
                                this.setState({
                                    modalAdd: false,
                                    setting_name: "",
                                })
                            }}>{i18n.t("batalkan")}</button>
                            <button className='btn btn-primary text-capitalize' disabled={this.state.disable_add} onClick={() => this.addSetting()}>{i18n.t("simpan")}</button>
                        </ModalFooter>
                    </Modal>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row justify-content-between">
                                            <div className="row ml-1">
                                                <select name="selected_bpjs"
                                                        className="form-control input-select-style select-column"
                                                        onChange={this.handleFilter}>
                                                    <option value=""
                                                            selected={this.state.selected_bpjs === ''}>{i18n.t("pilihsetting")}</option>
                                                    {this.state.bpjs_data.map((item, index) => {
                                                        return (
                                                            <option
                                                                value={item.bpjs_setting_id}>{item.bpjs_setting_name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <div className="ml-1">
                                                    <button className='karyawan-refresh-button' onClick={() => {
                                                        this.setState({
                                                            loading: true,
                                                            selected_bpjs: "",
                                                        }, () => this.loadData())
                                                    }}>
                                                        <img alt='' src={RefreshIcon}/>
                                                    </button>
                                                </div>
                                            </div>
                                            <button type="button" className="btn ipresens-btn-color" onClick={() => {
                                                this.setState({modalAdd: true})
                                            }}>{i18n.t("tambahpengaturanbpjs")}</button>
                                        </div>
                                    </div>
                                    {this.state.selected_bpjs != "" &&
                                        <div className="card-body">
                                            <form className="needs-validation user-add" noValidate="">
                                                <div>
                                                    <label className=""><b>{i18n.t("jkk")}</b></label>
                                                    <div className="row">
                                                        <label className="col-xl-3 col-md-4">{i18n.t("tangunganjkk")}</label>
                                                        <Switch
                                                            id={'selected_jkk_covered'}
                                                            name="selected_jkk_covered"
                                                            checked={this.state.selected_jkk_covered}
                                                            onChange={(val) => {
                                                                this.setState({
                                                                    selected_jkk_covered: val
                                                                })
                                                            }}
                                                            onColor="#8c2bee"
                                                            offColor="#eb4034"
                                                            handleDiameter={18}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            height={24}
                                                            width={48}
                                                            className="mr-1"
                                                        />
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4">{i18n.t("tipejkk")}</label>
                                                        <select name="selected_jkk" value={this.state.selected_jkk} className="form-control col-xl-9 col-md-8 input-select-style select-column" onChange={this.handleChange}>
                                                            <option value="" selected={this.state.filter_type === ''}>{i18n.t("pilihtipejkk")}</option>
                                                            {this.state.jkk_type.map((item, index) => {
                                                                return(
                                                                    <option value={item.bpjs_type_id}>{bpjsLangSwitch(item.bpjs_type_id)}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className=""><b>{i18n.t("jkm")}</b></label>
                                                    <div className="row">
                                                        <label className="col-xl-3 col-md-4">{i18n.t("tangunganjkm")}</label>
                                                        <Switch
                                                            id={'selected_jkm_covered'}
                                                            name="selected_jkm_covered"
                                                            checked={this.state.selected_jkm_covered}
                                                            onChange={(val) => {
                                                                this.setState({
                                                                    selected_jkm_covered: val
                                                                })
                                                            }}
                                                            onColor="#8c2bee"
                                                            offColor="#eb4034"
                                                            handleDiameter={18}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                            height={24}
                                                            width={48}
                                                            className="mr-1"
                                                        />
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4">{i18n.t("tipejkm")}</label>
                                                        <select name="selected_jkm" value={this.state.selected_jkm} className="form-control col-xl-9 col-md-8 input-select-style select-column" onChange={this.handleChange}>
                                                            <option value="" selected={this.state.filter_type === ''}>{i18n.t("pilihtipejkm")}</option>
                                                            {this.state.jkm_type.map((item, index) => {
                                                                return(
                                                                    <option value={item.bpjs_type_id}>{item.bpjs_type_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="pull-right">
                                                {this.state.prosesSimpan === false ?
                                                    <div className="row">
                                                        <button type="button" className="btn btn-danger mr-2"
                                                                onClick={() => {this.deleteSetting()}}>{i18n.t("hapuspengaturanbpjs")}</button>
                                                        <button type="button" className="btn ipresens-btn-color"
                                                                onClick={() => {this.editSetting()}}>{i18n.t("simpanpengaturanbpjs")}</button>
                                                    </div>
                                                    :
                                                    <button type="button" className="btn ipresens-btn-color"><i className="fa fa-refresh fa-spin" /> Loading</button>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    user: state.Auth.user,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(BPJSSetting)
