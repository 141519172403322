export const isPrime = (number) => {
    if (number <= 1) {
        return false; // Numbers less than or equal to 1 are not prime
    }

    // Check for divisors from 2 to the square root of the number
    for (let i = 2; i <= Math.sqrt(number); i++) {
        if (number % i === 0) {
            return false; // Number is divisible by a divisor other than 1 and itself
        }
    }

    return true; // Number is prime
}

export const isOdd = (number) =>  {
    return number % 2 === 1;
}
