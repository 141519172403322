import React, {Component} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import {getCurrency} from "../../helper/money";
import Swal from "sweetalert2";

class AddTagihanSiswa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tagihan_name: '',
            tagihan_nominal: '',
            tagihan_deadline: '',
            loading: false,
            divisi_id: 0,
            divisi: [],
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name   = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleSubmit = () => {
        let form = {
            tagihan_name: this.state.tagihan_name,
            tagihan_nominal: this.state.tagihan_nominal,
            tagihan_deadline: this.state.tagihan_deadline,
            divisi_id: this.state.divisi_id
        }
        this.props.request('tagihan_siswa/add', form , 'POST')
        .then(response => {
            this.props.changeState({
                add_visible: false, loading: false, data: []
            },() =>this.props.loadData())
            if (response.success) {
                Swal.fire({
                    title: i18n.t('berhasil'),
                    text: i18n.t('databerhasilditambahkan'),
                    icon: 'success'
                })
                this.setState({ divisi_id: 0, tagihan_name: '', tagihan_nominal: '', tagihan_deadline: '' })
            } else {
                Swal.fire({
                    title: i18n.t('gagal'),
                    text: i18n.t('datagagalditambahkan'),
                    icon: 'warning',
                    confirmButtonText: 'OK'
                })
            }
        })
    }

    loadKelas() {
        let form = {}
        this.props.request('tagihan_siswa/load-divisi', 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        divisi: response.data,
                    })
                }
            })
    }

    componentDidMount() {
        this.loadKelas()
    }

    render() {
        return (
            <Modal size="lg" zIndex={9999} isOpen={this.props.add_visible} toggle={() => this.props.changeState({
                add_visible: false
            })} style={{ minWidth: "1000px" }}>
                <ModalHeader>
                    <div className="">{i18n.t('tambahtagihan')}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="px-2">
                        <div className="form-group">
                            <label htmlFor="">{i18n.t('nama')}</label>
                            <input type="text" name="tagihan_name" className="form-control" onChange={this.handleChange}
                                   placeholder={i18n.t('namatagihan')}/>
                        </div>
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label htmlFor="">{i18n.t('nominal')}</label>
                                <div className="position-relative d-flex align-items-center">
                                    <input type="number" name="tagihan_nominal" className="form-control"
                                           style={{paddingLeft: "30px"}}
                                           onChange={this.handleChange} placeholder={i18n.t('nominaltagihan')}/>
                                    <span className="position-absolute ml-2"
                                          style={{color: "GrayText"}}>{getCurrency(this.props.currency)}</span>
                                </div>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="">{i18n.t('deadlinetagihan')}</label>
                                <input type="date" name="tagihan_deadline" className="form-control"
                                       onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">{i18n.t('kelas')}</label>
                            <select name="divisi_id" id="kelas" onChange={this.handleChange} className="form-control">
                                <option value="">{i18n.t('semuakelas')}</option>
                                {this.state.divisi?.map((item) => {
                                    return (
                                        <option value={item.divisi_id}>{item.divisi_name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({add_visible: false,}, () => this.setState({
                                loading: false,
                            }));
                        }}
                                style={{marginRight: 8}}>
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color"
                                disabled={this.state.loading} onClick={this.handleSubmit}>
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

export default AddTagihanSiswa