import React, { useState } from 'react';
import ArrowUpIcon from '../../../../assets/icons/arrow-up.svg';
import ArrowDownIcon from '../../../../assets/icons/arrow-down.svg'
import i18n from "i18next";

const CollapsibleScore = ({ title, children, averageScore }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="py-2 px-0 mb-2" >
            <button onClick={toggleCollapse} className="w-100 btn px-3 py-1 d-flex align-content-center justify-content-between bg-white" style={{ cursor: 'pointer', alignItems: "center", border: '1px solid #ccc' }}>
                <div className="lh-1 d-flex align-content-center justify-content-center text-dark text-capitalize">{title}</div>
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <h1 className="text-capitalize" style={{ fontSize: "14px", paddingTop: 6}}>{i18n.t('nilairatarata')} : {averageScore}</h1>
                    <span className="ml-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {isOpen ? (
                    <img src={ArrowUpIcon} alt="" className="" style={{width: "16px", height: "16px"}}/>
                ) : (
                    <img src={ArrowDownIcon} alt="" className="" style={{width: "16px", height: "16px"}}/>
                )}
                </span>
                </div>
            </button>
            {isOpen && (
                <div className="px-1">
                    {children}
                </div>
            )}
        </div>
    );
};

export default CollapsibleScore;