import React, {Component} from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import moment from 'moment';
import {requestData} from '../../helper/request'
import {IMG_HOST} from '../../helper/host'
import android from '../../assets/images/android.png'
import apple from '../../assets/images/apple.png'
import blogDefault from '../../assets/images/dashboard/cat3.png'
import bannerHome from '../../assets/images/home/banner.png'
import "../auth/style.css"
import format from "format-number";
import {Helmet} from "react-helmet";
import FrontHeader from "../common/front_header";

export class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: false,
            data: {},
            blog: [],
            paket: [],
        }
    }

    loadData() {
        let form = {}
        requestData("home", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        blog: response.blog,
                    })
                }
            });
    }

    loadPaket() {
        let form = {}
        this.props.request("paket/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        paket: response.data.data,
                        loading: false,
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
        this.loadPaket()
    }

    toggleMenu = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    }

    render() {
        return (
            <div className="landing-page-multikart" style={{ fontFamily: 'Lato, sans-serif' }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Home - Presensi</title>
                    <meta name="description" content="Home Absensi" />
                </Helmet>
                <FrontHeader />

                {/*Home section*/}
                <section className="flex-container" id="home">
                    <div className="row">
                        <div className="container col-lg-6 col-md-12">
                            <div className="pl-body">
                                <div className="col-md-12 text-left top-30">
                                    <p className="m-0 top-app-name">APLIKASI PRESENSI</p>
                                    <h2 className="m-0 top-main-name">Kelola Absensi Tanpa Ribet</h2>
                                    <h5 className="mb-4 mt-3 ls-0 width-75">Presensi merupakan aplikasi absensi karyawan berbasis aplikasi.
                                        Dapatkan laporan absensi karyawan dengan mudah,
                                        menggunakan presensi.co.id</h5>
                                    <a target="_blank"
                                        href="register"
                                        className="button-outline hoverable-btn">COBA SEKARANG</a>
                                </div>
                            </div>
                        </div>
                        <div className="container col-lg-6 col-md-12">
                            <img className="img-fluid lazyload banner-img pt-4" alt='Banner Img' src={bannerHome} />
                        </div>
                    </div>
                </section>
                <section id="fitur" className="section-lr feature-section">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="feature text-center">
                                    <div>
                                        <h5 className="title-landing">Mengapa Presensi Lebih Baik ?</h5>
                                        <p className="pb-3"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row feature_row">
                            <div className="col-xl-4 col-md-6 col-sm-12">
                                <div className="feature-box card-mengapa">
                                    <img src={require('../../assets/images/home/gps.png')} className="icon-content" />
                                    <div className="header-content">
                                        <h5><strong>Teknologi berbasis GPS</strong></h5>
                                    </div>
                                    <p className="desc-content">
                                        GPS (Global Positioning System) memungkinkan perusahaan Anda untuk
                                        mencatat banyak titik kehadiran setiap karyawan.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 col-sm-12">
                                <div className="feature-box card-mengapa pb-2">
                                    <img src={require('../../assets/images/home/fleksibel.png')} className="icon-content" />
                                    <div className="header-content">
                                        <h5><strong>Fleksibel</strong></h5>
                                    </div>
                                    <p className="desc-content">
                                        Anda punya kebijakan absen yang berbeda-beda untuk setiap divisi? Presensi dapat mengatur berdasarkan grup dengan mudah.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 col-sm-12">
                                <div className="feature-box card-mengapa pb-2">
                                    <img src={require('../../assets/images/home/minimalisir.png')} className="icon-content" />
                                    <div className="header-content">
                                        <h5><strong>Meminimalisir Kecurangan</strong></h5>
                                    </div>
                                    <p className="desc-content">
                                        Semua data absensi karyawan akan tersimpan secara digital sehingga meminimalisir kecurangan dan manipulasi data.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 col-sm-12">
                                <div className="feature-box card-mengapa pb-2">
                                    <img src={require('../../assets/images/home/karyawan-sales.png')} className="icon-content" />
                                    <div className="header-content">
                                        <h5><strong>Fleksibel Untuk Karyawan Sales</strong></h5>
                                    </div>
                                    <p className="desc-content">
                                        Presensi memungkinkan mencatat banyak titik kunjungan kehadiran bagi karyawan sales sesuai titik yang Anda tentukan.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 col-sm-12">
                                <div className="feature-box card-mengapa pb-2">
                                    <img src={require('../../assets/images/home/praktis.png')} className="icon-content" />
                                    <div className="header-content">
                                        <h5><strong>Lebih Praktis</strong></h5>
                                    </div>
                                    <p className="desc-content">
                                        Dengan presensi tidak perlu antri lagi untuk absen, karyawan dapat melakukan absensi melalui Smartphone masing-masing.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 col-sm-12">
                                <div className="feature-box card-mengapa pb-2">
                                    <img src={require('../../assets/images/home/rekap.png')} className="icon-content" />
                                    <div className="header-content">
                                        <h5><strong>Rekap Absensi Yang Baik</strong></h5>
                                    </div>
                                    <p className="desc-content">
                                        Presensi mencatat otomatis riwayat absensi karyawan yang lengkap, karyawan dan HR dapat melihat jumlah Hadir, Terlambat, Dll
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="footer" className="section-b-space gradient-section footer mt-2">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="footer-section">
                                    <div>
                                        <h2 className="fw-900">Jadwalkan demo & konsultasi langsung dengan <br />
                                            Presensi.co.id</h2>
                                        <a target="_blank"
                                            href="register"
                                            className="btn hoverable-btn demo-btn">Jadwalkan demo</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="harga" className="section-lr feature-section mb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="feature row mb-2">
                                    <div className="col-lg-5 col-md-5 col-sm-12" align="left">
                                        <h1 className="title-landing">Berikut ini penawaran kami hanya untuk Anda</h1>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-12"></div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row feature_row" align="left">
                            <div className="pricing-content card-deck flex-column flex-md-row m-0">
                                {this.state.paket.map((item, index) => {
                                    return (
                                        <div className="col col-md-3 price-content">
                                            <div className={item.paket_harga == 0 ? "ribbon-container h-100" : "h-100"} data-ribbon="FREE">
                                                <div className="card-pricing popular mb-3 h-100">
                                                    <div className="col-xl-12 p-0 h-100">
                                                        <div className="card pricing card-deck flex-column flex-md-row m-0  h-100">
                                                            <div className="pl-4 pr-4 pb-4 card-pricing px-0 mb-10">
                                                                <div className="bg-transparent card-header p-0 border-0">
                                                                    <img src={IMG_HOST + 'mitra/admin/paket/' + item.paket_foto} style={{ width: '30%', height: 'auto', margin: '5px' }} />
                                                                    <span className="h5 w-60 mx-auto px-2 rounded" style={{ fontSize: '15px', color: '#002558' }}>{item.paket_nama}</span>
                                                                    <h3 className="text-dark-color m-2"><b>{item.paket_harga !== 0 ? format({ prefix: 'Rp ', integerSeparator: "." })(item.paket_harga) : 'Gratis!'}</b></h3>
                                                                    <span className="h5 text-seconday-dark ml-2 fs-14">Per {item.paket_bulan} Bulan</span>
                                                                </div>
                                                                <div className="card-body p-2">
                                                                    <span className="mb-2"><img src={require('../../assets/images/home/check.png')} style={{ width: '15%', height: 'auto', margin: '2px' }} /> Max {item.paket_jumlah} Karyawan</span><br />
                                                                    <span><span><img src={require('../../assets/images/home/check.png')} style={{ width: '15%', height: 'auto', margin: '2px' }} /></span><span>{item.paket_desc}</span></span>
                                                                    <a href={"/register/" + item.paket_alias} type="button" className="btn btn-outline-dark-color hoverable-btn mt-3 btn-block text-center" style={{ color: '#002558', maxWidth: '100%' }}>Pilih Paket</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                <section id="blog" className="section-lr feature-section mb-5">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="feature row mb-2">
                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                        <h1 className="title-landing">Informasi Bermanfaat</h1>
                                    </div>
                                    <div className="col-lg-1 col-md-1 col-sm-12"></div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row feature_row">
                            {this.state.blog.map((item, index) => {
                                return (
                                    <div className="col-xl-4 col-md-6 ">
                                        <div className="feature-box">
                                            <div className="feature-image">
                                                <a href={"/blog/" + item.blog_alias}>
                                                    {item.blog_image !== null ?
                                                        <img className="img-fluid lazyload" alt={item.blog_image} src={IMG_HOST + 'mitra/admin/blog/' + item.blog_image} />
                                                        :
                                                        <img className="img-fluid lazyload" alt='default Img' src={blogDefault} />
                                                    }
                                                </a>
                                            </div>
                                            <p className="date-text mt-2">
                                                {moment(item.blog_create_date).format('lll')}
                                            </p>
                                            <div>
                                                <a href={"/blog/" + item.blog_alias}>
                                                    <h5 className="blog-title">{item.blog_title}</h5>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}


                        </div>
                    </div>
                </section>

                {/*Kontak*/}
                <section id="kontak" className="main-feature section-b-space grey-bg text-left">
                    <div className="col">
                        <iframe
                            src="https://maps.google.com/maps?q=idota%20cell&t=&z=15&ie=UTF8&iwloc=&output=embed"
                            frameBorder="0" allowFullScreen=""
                            aria-hidden="false" tabIndex="0" style={{ border: 0, width: '100%', height: 450 }}>
                        </iframe>
                    </div>
                    <div className="container mt-5">
                        <h4 className="pb-3">Punya pertanyaan mengenai absensi online?</h4>
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-12">
                                <h5 className="title-landing">Hubungi Kami</h5>
                                <ul className="navbar-nav">
                                    <li>
                                        <a className='ml-2 text-custome-16'
                                            href={'tel' + this.state.data.contact_phone}
                                            alt={'Telepon ' + this.state.data.contact_phone}
                                            title={'Telepon ' + this.state.data.contact_phone}>
                                            {this.state.data.contact_phone}</a>
                                    </li>
                                    <li className="mt-3">
                                        <a className='ml-2 text-custome-16'
                                            href={"mailto:" + this.state.data.contact_email}
                                            alt={"email:" + this.state.data.contact_email}
                                            title={"email:" + this.state.data.contact_email}>
                                            {this.state.data.contact_email}</a>
                                    </li>
                                    <li className="mt-3 mb-3">
                                        <span className='ml-2 text-custome-16'>
                                            {this.state.data.contact_address}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-2 col-12">
                                <h5 className="title-landing">Menu</h5>
                                <ul className="navbar-nav">
                                    <li className="mb-3">
                                        <AnchorLink className="text-custome-16" href='#fitur'>Fitur</AnchorLink>
                                    </li>
                                    <li className="mb-3">
                                        <AnchorLink className="text-custome-16" href='#harga'>Harga</AnchorLink>
                                    </li>
                                    <li className="mb-3">
                                        <a className="text-custome-16" href='/login'>Masuk</a>
                                    </li>
                                    <li className="mb-3">
                                        <a className="text-custome-16" href='/register'>Daftar</a>
                                    </li>
                                    <li className="mb-3">
                                        <a className="text-custome-16" href='/kebijakan-privasi'>Kebijakan & Privasi</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-2 col-12">
                                <h5 className="title-landing">Temukan Kami</h5>
                                <ul className="navbar-nav">
                                    <li className="mb-3">
                                        <a className="text-custome-16" href={this.state.data.contact_instagram_link}>Instagram</a>
                                    </li>
                                    <li className="mb-3">
                                        <a className="text-custome-16" href={this.state.data.contact_facebook_link}>Facebook</a>
                                    </li>
                                    <li className="mb-3">
                                        <a className="text-custome-16" href={this.state.data.contact_youtube_link}>Youtube</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-md-2 col-12">
                                <h5 className="title-landing">Tersedia di</h5>
                                <div className="set-image mb-3">
                                    <a target='_blank' href={this.state.data.app_url_android}><img
                                        style={{ maxWidth: '80%' }} src={android} alt="android" /></a>
                                </div>
                                <div className="set-image">
                                    <a target='_blank' href={this.state.data.app_url_ios}>
                                        <img style={{ maxWidth: '80%' }} src={apple} alt="apple" />
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <div className="footer-shadow text-center">
                    Copyright Presensi.co.id 2020
                </div>

                <div className="tap-top">
                    <div>
                        <i className="fa fa-angle-double-up"></i>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home
