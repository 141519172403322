import React, {Component} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import Swal from "sweetalert2";

class EditSemester extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit_loading: false,
            semester_type: '',
            semester_start: '',
            semester_end: '',
            currentPage: 0,
            semester_id: 0,
            status: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (value === 'active') {
            this.setState({
                semester_end: '',
            })
        }

        if (name === 'semester_start') {
            if (value !== '') {
                this.setState({
                    status: 'active'
                })
            } else {
                this.setState({
                    status: ''
                })
            }
        }

        if (name === 'semester_end') {
            if (value !== '') {
                this.setState({
                    status: 'finish'
                })
            } else {
                this.setState({
                    status: 'active'
                })
            }
        }

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleSubmit = () => {
        this.setState({ edit_loading: false })
        let form = {
            semester_id: this.state.semester_id,
            semester_type: this.state.semester_type,
            semester_start: this.state.semester_start,
            semester_end: this.state.semester_end,
            status: this.state.status,
        }
        this.props.request("semester/edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.changeState({
                        edit_visible: false,
                        loading: true,
                        data: [],
                    }, this.props.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('semesterberhasildiedit'),
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('semestergagaldiedit'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ edit_loading: false })
            })
    }
    
    loadDetail(id) {
        let form = {
            semester_id: id || this.state.semester_id
        }
        this.props.request('semester/detail', form, 'POST')
        .then(response => {
            if (response.success) {
                this.setState({
                    semester_type: response.data.semester_type,
                    semester_start: response.data.semester_start_date,
                    semester_end: response.data.semester_end_date,
                    status: response.data.semester_end_date === null ? 'active' : 'finish'
                })
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.setState({
                semester_id: this.props.id
            }, () => this.loadDetail(this.props.id));
        }
    }

    render() {
        return (
            <Modal size="lg" zIndex="999" style={{ maxWidth: "850px" }} isOpen={this.props.edit_visible} toggle={() => this.props.changeState({ edit_visible: false })}>
                <ModalHeader toggle={() => this.props.changeState({ edit_visible: false })}>
                    <div className="text-dark" style={{  fontWeight: 'bold', fontSize: "18px" }}>{i18n.t('editsemester')}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="">
                        <div className="form-group">
                            <label htmlFor="">{i18n.t('tipesemester')}</label>
                            <select name="semester_type" className="form-control" onChange={this.handleChange}>
                                <option value=""
                                        selected={this.state.semester_type === ''}>{i18n.t('pilihtipe')}</option>
                                <option value="ganjil"
                                        selected={this.state.semester_type === 'ganjil'}>{i18n.t('semesterganjil')}</option>
                                <option value="genap"
                                        selected={this.state.semester_type === 'genap'}>{i18n.t('semestergenap')}</option>
                            </select>
                        </div>
                        <div className="row">
                            <div className="form-group col">
                                <label htmlFor="">{i18n.t('tahunmulai')}</label>
                                <input type="date" className="form-control" name="semester_start"
                                       onChange={this.handleChange} value={this.state.semester_start}/>
                            </div>
                            <div className="form-group col">
                                <label htmlFor="">{i18n.t('tahunselesai')}</label>
                                <input type="date" className="form-control" name="semester_end"
                                       onChange={this.handleChange} value={this.state.semester_end}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">{i18n.t('statussemester')}</label>
                            <select name="status" className="form-control" onChange={this.handleChange}
                                    value={this.state.status}>
                                <option value=""
                                        selected={this.state.semester_type === '' && this.state.semester_start === ''}>{i18n.t('pilihstatus')}</option>
                                <option value="active"
                                        selected={this.state.semester_end === ''}>{i18n.t('aktif')}</option>
                                <option value="finish"
                                        selected={this.state.semester_end !== ''}>{i18n.t('selesai')}</option>
                            </select>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({edit_visible: false}, () => this.setState({
                                edit_loading: false,
                                semester_type: '', semester_start: '', semester_end: '',
                            }));
                        }}
                                style={{marginRight: 8}}>
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color"
                                disabled={this.state.edit_loading} onClick={this.handleSubmit}>
                            {this.state.edit_loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

export default EditSemester;