import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { Helmet } from "react-helmet";
import RefreshIcon from "../../assets/icons/refresh.svg";
import moment from "moment/moment";
import { Bar } from "react-chartjs-2";
import { lineOptions } from '../../constants/chartData'
import Chart from "react-google-charts";
import i18n from 'i18next';

export class Monitor extends Component {
    constructor(props) {
        super(props);
        let currentDate = new Date();
        let currentYear = currentDate. getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        this.state = {
            selectedBulan: currentMonth.toString().padStart(2, '0') // Format dua digit
        };
        this.state = {
            data: [],
            selectedBulanTahun: moment().format('MM'),
            selectedBulan: moment().format('MM'),
            perusahaan: [],
            selectedYear: currentYear,
            selectedTahun: currentYear,
            absensiTerlambat: [],
            absensiTepatWaktu: [], //
            absensi: [],
            karyawan: [],
            loading: true,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleBulanTahunChange = this.handleBulanTahunChange.bind(this);
        this.handleBulan = this.handleBulan.bind(this);
        this.handleTahun = this.handleTahun.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.resetFilterTerlambat = this.resetFilterTerlambat.bind(this);
        this.resetFilterTepatWaktu = this.resetFilterTepatWaktu.bind(this); //
        this.handleYearChange = this.handleYearChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }
    getMonthName(monthValue) {
        const months = [
            "Januari", "Februari", "Maret", "April", "Mei", "Juni",
            "Juli", "Agustus", "September", "Oktober", "November", "Desember"
        ];

        if (monthValue >= 1 && monthValue <= 12) {
            return months[monthValue - 1];
        } else {
            return "Bulan tidak valid";
        }
    }

    generateOptions() {
        const now = new Date();
        const currentMonth = now.getMonth() + 1;
        const monthsToShow = 6;
        const options = [];

        for (let i = 0; i < monthsToShow; i++) {
            const monthValue = currentMonth - i > 0 ? currentMonth - i : currentMonth - i + 12;
            options.push(
                <option key={monthValue} value={monthValue.toString().padStart(2, '0')}>
                    {this.getMonthName(monthValue)}
                </option>
            );
        }
        return options;
    }

    resetFilter(event) {
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        this.setState({
                data: [],
                absensin: [],
                selectedYear: currentYear,
                selectedMonth: currentMonth,
                loading: true,
            }, () => this.loadDataAbsensi()
        )
    }
    resetFilterTerlambat(event) {
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        this.setState({
                data: [],
                absensin: [],
                selectedYear: currentYear,
                selectedMonth: currentMonth,
                loading: true,
            }, () => this.loadData()
        )
    }
    //
    resetFilterTepatWaktu(event) {
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        this.setState({
                data: [],
                absensin: [],
                selectedYear: currentYear,
                selectedMonth: currentMonth,
                loading: true,
            }, () => this.loadDataTepatWaktu()
        )
    }

    handleBulan = (event) => {
        this.setState({ selectedBulanTahun: event.target.value }, () => {
            this.loadData();
        });
    };
    //
    handleBulanTepatWaktu = (event) => {
        this.setState({ selectedBulanTahun: event.target.value }, () => {
            this.loadDataTepatWaktu();
        });
    };

    handleBulanTahunChange = (event) => {
        this.setState({ selectedBulan: event.target.value }, () => {
            this.loadDataAbsensi();
        });
    };

    handleTahun = (event) => {
        this.setState({ selectedYear: event.target.value }, () => {
            this.loadData();
        });
    };
    //
    handleTahunTepatWaktu = (event) => {
        this.setState({ selectedYear: event.target.value }, () => {
            this.loadDataTepatWaktu();
        });
    };

    handleYearChange = (event) => {
        this.setState({ selectedTahun: event.target.value }, () => {
            this.loadDataAbsensi();
        });
    };

    loadData() {
        let form = {
            tahun: this.state.selectedYear,
            bulan: this.state.selectedBulanTahun
        };
        this.props.request('presensi/pemantauan-terlambat', form, 'POST')
            .then(response => {
                var tanggal = [];
                var terlambat = [];
                var nama = [];
                response.data.map((item, index) => {
                    tanggal.push((item.tanggal !== null ? item.tanggal : 0));
                    terlambat.push((item.terlambat !== null ? item.terlambat : 0));
                    nama.push((item.nama !== null ? item.nama : 0));
                });
                if (response.success) {
                    this.setState({
                        absensiTerlambat: {
                            tanggal: tanggal,
                            terlambat: terlambat,
                            namaKaryawan: nama,
                        },
                        loading: false
                    })
                }
            })
    }

    //
    loadDataTepatWaktu() {
        let form = {
            tahun: this.state.selectedYear,
            bulan: this.state.selectedBulanTahun
        };
        this.props.request('presensi/pemantauan-tepat-waktu', form, 'POST')
            .then(response => {
                var tanggal = [];
                var tepatWaktu = [];
                var nama = [];
                response.datas.map((item, index) => {
                    tanggal.push((item.tanggal !== null ? item.tanggal : 0));
                    tepatWaktu.push((item.tepatWaktu !== null ? item.tepatWaktu : 0));
                    nama.push((item.nama !== null ? item.nama : 0));
                });
                if (response.success) {
                    this.setState({
                        absensiTepatWaktu: {
                            tanggal: tanggal,
                            tepatWaktu: tepatWaktu,
                            namaKaryawan: nama,
                        },
                        loading: false
                    })
                }
            })
    }

    loadDataAbsensi() {
        this.setState({
            loading: true
        });
        let form = {
            year: this.state.selectedTahun,
            month: this.state.selectedBulan
        };
        this.props.request("list_absensiv2", form, 'POST')
            .then(response => {
                var tanggal = [];
                var tepatWaktu = [];
                var terlambat = [];
                var izin = [];
                var alpa = [];
                response.map((item, index) => {
                    tanggal.push((item.tanggal !== null ? item.tanggal : 0));
                    tepatWaktu.push((item.masuk !== null ? item.masuk : 0));
                    terlambat.push((item.terlambat !== null ? item.terlambat : 0));
                    izin.push((item.izin !== null ? item.izin : 0))
                    alpa.push((item.alpa !== null ? item.alpa : 0));
                });
                this.setState({
                    absensi: {
                        tanggal: tanggal,
                        tepatWaktu: tepatWaktu,
                        terlambat: terlambat,
                        izin: izin,
                        alpa: alpa,
                    },
                    loading: false
                })
            });
    }

    loadDataKaryawan() {
        this.setState({
            loading: true
        });
        let form = {};
        this.props.request("dashboard", form, 'POST')
            .then(response => {
                if (response.success) {
                    const isKaryawanNotNull = response.data.karyawan !== null;

                    this.setState({
                        data: response,
                        perusahaan: isKaryawanNotNull ? response.data : {
                            ...response.data,
                            karyawan: {
                                karyawan_laki_laki: 0,
                                karyawan_perempuan: 0,
                                total_karyawan: 0,
                                sisa_karyawan: 0,
                            }
                        }
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(error => {
            console.error("Error loading data:", error);
            this.setState({
                loading: false
            });
        });
    }

    // loadCheck() {
    //     let form = {
    //         perusahaan_id: this.props.user.perusahaan_id
    //     }
    //     this.props.request("check-guide-data", form, "POST")
    //         .then((response) => {
    //             if (response.success) {
    //                 this.setState({
    //                     karyawan: response.karyawan
    //                 }, () => {
    //                     if (this.props.user.perusahaan.status === "new" && this.props.user.perusahaan.perusahaan_bidang !== null && this.props.user.perusahaan.perusahaan_alamat !== null && this.props.user.perusahaan.perusahaan_telp !== null) {
    //                         this.setState({
    //                             progresModal: true
    //                         })
    //                     }
    //                 })
    //             }
    //         })
    // }

    componentDidMount() {
        this.loadData()
        this.loadDataAbsensi()
        this.loadDataKaryawan()
        // this.loadCheck()
    }

    render() {
        const dataAbsenTerlambat = {
            labels: this.state.absensiTerlambat.namaKaryawan,
            datasets: [
                {
                    label: i18n.t("terlambat"),
                    legend: 'none',
                    data: this.state.absensiTerlambat.terlambat,
                    borderColor: "#ffba5b",
                    backgroundColor: "#ff9100",
                    borderWidth: 2
                }
            ]
        };
        //
        const dataAbsenTepatWaktu = {
            labels: this.state.absensiTepatWaktu.namaKaryawan,
            datasets: [
                {
                    label: i18n.t("ontime"),
                    legend: 'none',
                    data: this.state.absensiTepatWaktu.tepatWaktu,
                    borderColor: "#75ff43",
                    backgroundColor: "#75ff41",
                    borderWidth: 2
                }
            ]
        }
        const dataAbsen = {
            labels: this.state.absensi.tanggal,
            datasets: [{
                label: i18n.t("ontime"),
                legend: 'none',
                data: this.state.absensi.tepatWaktu,
                borderColor: "#75ff43",
                backgroundColor: "#75ff43",
                borderWidth: 2
            },
                {
                    label: i18n.t("terlambat"),
                    legend: 'none',
                    data: this.state.absensi.terlambat,
                    borderColor: "#ffe643",
                    backgroundColor: "#ffe643",
                    borderWidth: 2
                },
                {
                    label: i18n.t("izin"),
                    legend: 'none',
                    data: this.state.absensi.izin,
                    borderColor: "#007bff",
                    backgroundColor: "#007bff",
                    borderWidth: 2
                },
                {
                    label: i18n.t("alpa"),
                    legend: 'none',
                    data: this.state.absensi.alpa,
                    borderColor: "#ff4343",
                    backgroundColor: "#ff4343",
                    borderWidth: 2
                },
            ]
        };
        const limitOption = {
            title: "",
            pieHole: 0.5,
            slices: [{
                color: "#EFEFEF"
            },
                {
                    color: "#057CE4"
                },
            ],
            tooltip: {
                showColorCode: false
            },
            chartArea: {
                width: '360px',
                height: '100%'
            },
            legend: "none"
        };
        const genderOption = {
            title: "",
            pieHole: 0.5,
            slices: [{
                color: "#057CE4"
            },
                {
                    color: "#FF9C00"
                },
            ],
            tooltip: {
                showColorCode: false
            },
            chartArea: {
                width: '360px',
                height: '100%'
            },
            legend: "none"
        };
        let perbandinganlaki = 0
        let perbandinganperempuan = 0
        if(!this.state.loading && this.state.perusahaan && this.state.perusahaan.karyawan_laki_laki) {
            perbandinganlaki = this.state.perusahaan.karyawan.karyawan_laki_laki / this.state.perusahaan.karyawan.total_karyawan * 100;
            perbandinganperempuan = this.state.perusahaan.karyawan.karyawan_perempuan / this.state.perusahaan.karyawan.total_karyawan * 100;
        }
        const tipe = this.props.user.perusahaan.perusahaan_type === "sekolah";
        const tipePerusahaan = this.props.user.perusahaan.perusahaan_type === "sekolah" ? i18n.t('sekolah') : i18n.t('perusahaan');
        const karyawanOrSiswa = this.props.user.perusahaan.perusahaan_type === "sekolah" ? i18n.t('siswa') : i18n.t('karyawan');

        return(
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{tipe ? i18n.t('monitorsekolah') : i18n.t('monitorperusahaan')} - iPresens</title>
                    <meta name="description" content={tipe ? i18n.t('monitorsekolah') : i18n.t('monitorperusahaan')}/>
                </Helmet>
                <div className="container-fluid pt-4">
                    <div className='row'>
                        <div className='col-md-12' style={{
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#5D6F80"
                        }}>iPresens Admin Panel
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col-md-12' style={{
                            fontWeight: 700,
                            fontSize: "24px",
                            lineHeight: "36px",
                            color: "#6201A7"
                        }}>{tipe ? i18n.t('monitorsekolah') : i18n.t('monitorperusahaan')}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className="col-xl-9 col-md-9">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-3 pt-3">
                                        <div className="col-md-4 d-flex">
                                            <h4>{tipe ? i18n.t("laporanabsensekolah"): i18n.t("laporanabsen")}</h4>
                                        </div>
                                        <div className='col-md-2'>
                                            <label style={{fontSize: '13px', alignContent: 'flex-end'}}>{i18n.t("filtertahun")} :</label>
                                            <select name="year" className="form-control descending"
                                                    value={this.state.selectedTahun}
                                                    onChange={this.handleYearChange}>
                                                <option key={2024} value={2024}>2024</option>
                                                <option key={2023} value={2023}>2023</option>
                                                <option key={2022} value={2022}>2022</option>
                                            </select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{fontSize: '13px', alignContent: 'flex-end'}}>{i18n.t("filterbulan")} :</label>
                                            <select name="month" className="form-control descending"
                                                    value={this.state.selectedBulan}
                                                    onChange={this.handleBulanTahunChange}>
                                                {this.generateOptions()}
                                            </select>
                                        </div>
                                        <div className='col-md-2 pengumuman-col-filter'>
                                            <label style={{fontSize: '13px', alignContent: 'flex-end'}}>{i18n.t("resetfilter")} :</label>
                                            <div className='d-flex flex-row flex-nowrap'>
                                                <button className='pengumuman-button-refresh'
                                                        onClick={this.resetFilter}>
                                                    <img alt='' src={RefreshIcon}/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={
                                        "col mb-2"
                                    }>
                                        <div className="market-chart">
                                            <Bar data={dataAbsen}
                                                 options={lineOptions}
                                                 width={"100%"}
                                                 height={500}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="col">
                                        <div className='row'>
                                            <div className="col-md-6 d-flex">
                                                <h4>{tipe ? i18n.t('laporan5terlambatsiswa') : i18n.t('laporan5terlambat')}</h4>
                                            </div>
                                            <div className='col-md-2'>
                                                <label style={{fontSize: '13px', alignContent: 'flex-end'}}>{i18n.t("filtertahun")} :</label>
                                                <select name="year" className="form-control descending"
                                                        value={this.state.selectedYear}
                                                        onChange={this.handleTahun}>
                                                    <option key={2024} value={2024}>2024</option>
                                                    <option key={2023} value={2023}>2023</option>
                                                    <option key={2022} value={2022}>2022</option>
                                                </select>
                                            </div>
                                            <div className='col-md-2'>
                                                <label style={{fontSize: '13px', alignContent: 'flex-end'}}>{i18n.t("filterbulan")} :</label>
                                                <select name="month" className="form-control descending"
                                                        value={this.state.selectedBulanTahun}
                                                        onChange={this.handleBulan}>
                                                    {this.generateOptions()}
                                                </select>
                                            </div>
                                            <div className='col-md-2 pengumuman-col-filter'>
                                                <label style={{fontSize: '13px', alignContent: 'flex-end'}}>{i18n.t("resetfilter")} :</label>
                                                <div className='d-flex flex-row flex-nowrap'>
                                                    <button className='pengumuman-button-refresh'
                                                            onClick={this.resetFilterTerlambat}>
                                                        <img alt='' src={RefreshIcon}/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="market-chart">
                                                <Bar data={dataAbsenTerlambat}
                                                     options={lineOptions}
                                                     width={"100%"}
                                                     height={500}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="col">
                                        <div className='row'>
                                            <div className="col-md-6 d-flex">
                                                <h4>{tipe ? i18n.t('laporan5tepatwaktusiswa'):i18n.t('laporan5tepatwaktu')}</h4>
                                            </div>
                                            <div className='col-md-2'>
                                                <label style={{fontSize: '13px', alignContent: 'flex-end'}}>{i18n.t("filtertahun")} :</label>
                                                <select name="year" className="form-control descending"
                                                        value={this.state.selectedYear}
                                                        onChange={this.handleTahunTepatWaktu}>
                                                    <option key={2024} value={2024}>2024</option>
                                                    <option key={2023} value={2023}>2023</option>
                                                    <option key={2022} value={2022}>2022</option>
                                                </select>
                                            </div>
                                            <div className='col-md-2'>
                                                <label style={{fontSize: '13px', alignContent: 'flex-end'}}>{i18n.t("filterbulan")} :</label>
                                                <select name="month" className="form-control descending"
                                                        value={this.state.selectedBulanTahun}
                                                        onChange={this.handleBulanTepatWaktu}>
                                                    {this.generateOptions()}
                                                </select>
                                            </div>
                                            <div className='col-md-2 pengumuman-col-filter'>
                                                <label style={{fontSize: '13px', alignContent: 'flex-end'}}>{i18n.t("resetfilter")} :</label>
                                                <div className='d-flex flex-row flex-nowrap'>
                                                    <button className='pengumuman-button-refresh'
                                                            onClick={this.resetFilterTepatWaktu}>
                                                        <img alt='' src={RefreshIcon}/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="market-chart">
                                                <Bar data={dataAbsenTepatWaktu}
                                                     options={lineOptions}
                                                     width={"100%"}
                                                     height={500}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-3">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <div className="order-graph sm-order-space">
                                            <div className="row"
                                                 style={
                                                     {
                                                         justifyContent: 'space-between'
                                                     }
                                                 }>
                                                <h6> {tipe ? i18n.t('laporanlimitsiswa') : i18n.t('laporanlimitkaryawan')} </h6> <
                                                h6> {
                                                !this.state.loading && this.state.perusahaan.karyawan?.total_karyawan
                                            }
                                                / {!this.state.loading && this.state.perusahaan.karyawan?.total_karyawan + this.state.perusahaan.karyawan?.sisa_karyawan}</h6>
                                            </div>
                                            {
                                                this.state.loading ?
                                                    i18n.t("sedangmemuatdata")+'...' :
                                                    <>
                                                        <div className="peity-chart-dashboard text-center"
                                                             style={
                                                                 {
                                                                     alignItems: 'center'
                                                                 }
                                                             }>
                                                            <Chart
                                                                chartType="PieChart"
                                                                data={
                                                                    [
                                                                        [i18n.t('laporan'), tipe ? i18n.t('limitsiswa') :  i18n.t('limitkaryawan')],
                                                                        [tipe ? i18n.t('sisaslotsiswa') : i18n.t('sisaslotkaryawan'), this.state.perusahaan.karyawan?.sisa_karyawan],
                                                                        [i18n.t('karyawan'), this.state.perusahaan.karyawan?.total_karyawan],
                                                                    ]
                                                                }
                                                                options={
                                                                    limitOption
                                                                }
                                                                graph_id="LimitChart"
                                                                width={
                                                                    "100%"
                                                                }
                                                                height={
                                                                    "180px"
                                                                }
                                                                legend_toggle/>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <div className="order-graph sm-order-space">
                                            <h6> {i18n.t("perbandingangender")} </h6> {
                                            this.state.loading ?
                                                i18n.t("sedangmemuatdata")+'...' :
                                                <>
                                                    <div className="peity-chart-dashboard text-center">
                                                        <Chart
                                                            chartType="PieChart"
                                                            data={
                                                                [
                                                                    ['Perbandingan', 'Gender'],
                                                                    [i18n.t("lakilaki"), this.state.perusahaan.karyawan?.karyawan_laki_laki],
                                                                    [i18n.t("perempuan"), this.state.perusahaan.karyawan?.karyawan_perempuan],
                                                                ]
                                                            }
                                                            options={
                                                                genderOption
                                                            }
                                                            graph_id="GenderChart"
                                                            width={
                                                                "100%"
                                                            }
                                                            height={
                                                                "180px"
                                                            }
                                                            legend_toggle/>
                                                    </div>
                                                    <div className="order-graph-bottom sales-location">
                                                        <div className="media">
                                                            <div className="media-body">
                                                                <h6 className="mb-0 mr-0"> {i18n.t("lakilaki")} < span
                                                                    className="pull-right"> {
                                                                    perbandinganlaki.toFixed(1)
                                                                } % </span></h6></div>
                                                        </div>
                                                        <div className="media">
                                                            <div className="media-body">
                                                                <h6 className="mb-0 mr-0"> {i18n.t("perempuan")} <span
                                                                    className="pull-right"> {
                                                                    perbandinganperempuan.toFixed(1)
                                                                } % </span></h6></div>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}


const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Monitor);
