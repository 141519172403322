import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import { APIKEY, IMG_HOST } from "../../helper/host";
import { Helmet } from "react-helmet";
import { Spin } from "antd";
import { Card, CardBody, Col, Row } from 'reactstrap'
import ABSENSI from './absensi-detail-karyawan'
import { GoogleApiWrapper } from 'google-maps-react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import GpsIcon from "../../assets/icons/gps.svg";

import "../karyawan/profil/avatar.css"

export class GuideKaryawanDetail extends Component {
    constructor(props) {
        super(props);
        let tabIndex = 0;
        const pathArr = props.location.pathname.split('/')
        if (pathArr.length === 6) {
            tabIndex = parseInt(pathArr[pathArr.length - 1])
        }

        this.state = {
            loading: false,
            data_karyawan: [],
            data_lokasi: [],
            divisi: [],
            jabatan: [],
            tabIndex: tabIndex
        }
        this.setTabIndex = this.setTabIndex.bind(this)
    }

    setTabIndex(index) {
        this.setState({
            tabIndex: index
        })
    }

    loadDataKaryawan() {
        this.setState({ loading: true })
        let form = {
            id_karyawan: this.props.match.params.id,
        }
        this.props.request("karyawan/get_karyawan_by_id", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_karyawan: response.data,
                        data_lokasi: response.lokasi,
                        divisi: response.data.divisi,
                        jabatan: response.data.jabatan
                    })
                }
                this.setState({ loading: false })
            });
    }



    componentDidMount() {
        this.loadDataKaryawan()
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Detail Karyawan - iPresens</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <Breadcrumb title="Detail Karyawan" parent="Karyawan" parentLink='/guide/karyawan' />
                <Col lg="12">
                    <Card className="main-card mb-3">
                        <CardBody>
                            {this.state.loading ?
                                <Spin size="large" tip="Sedang memuat data..." style={{
                                    width: '100%',
                                    margin: 'auto',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }} />
                                :
                                <div>
                                    {/*profil Karyawan*/}
                                    <div className="profile-responsive card-border">
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner">
                                                <div className="row">
                                                    <div>
                                                        {this.state.data_karyawan.karyawan_foto !== null ? (
                                                            <img
                                                                alt=''
                                                                id="avatar-square"
                                                                src={IMG_HOST + 'mitra/frontapp/karyawan/' + this.state.data_karyawan.karyawan_foto}
                                                                className="img-fluid blur-up lazyloaded" />) : (
                                                            <img
                                                                alt=''
                                                                id="avatar-square"
                                                                src={IMG_HOST + 'user-profile.png'}
                                                                className="img-fluid blur-up lazyloaded" />
                                                        )}
                                                    </div>
                                                    <div className="col">
                                                        <div className="row">
                                                            <h1 className="menu-header-title" style={{ color: "#143B5E" }}>{this.state.data_karyawan.karyawan_nama}</h1>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>Nomor Induk: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color:"#143B5E" }}>{this.state.data_karyawan && this.state.data_karyawan.karyawan_no_induk ? this.state.data_karyawan.karyawan_no_induk : '-'}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>Jabatan: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color: "#143B5E" }}>{this.state.data_karyawan.jabatan && this.state.data_karyawan.jabatan.jabatan_nama ? this.state.data_karyawan.jabatan.jabatan_nama : '-'}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>Divisi: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color: "#143B5E" }}>{this.state.data_karyawan.divisi && this.state.data_karyawan.divisi.divisi_name ? this.state.data_karyawan.divisi.divisi_name : '-'}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>Email: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color: "#143B5E" }}>{this.state.data_karyawan.karyawan_email}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>Tanggal Lahir: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color: "#143B5E" }}>{this.state.data_karyawan.karyawan_ttl}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>Tgl Karyawan Bergabung: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color:"#143B5E" }}>{this.state.data_karyawan.karyawan_join_date ? this.state.data_karyawan.karyawan_join_date : '-'}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>No. Handphone: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color: "#143B5E" }}>{this.state.data_karyawan.no_hp}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>Lokasi Presensi: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color: "#143B5E" }}>{this.state.data_lokasi ?
                                                                            this.state.data_lokasi.map((item, index) => {
                                                                                return (
                                                                                    <span key={item.lokasi_id}>{index !== 0 && ', '}{item.lokasi_nama}</span>
                                                                                )
                                                                            })
                                                                            : 'Semua Lokasi Perusahaan'}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>Alamat: </p>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <h6 style={{ fontSize: 12, color: "#143B5E" }}>{this.state.data_karyawan.karyawan_alamat}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>Foto Selfie: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        {this.state.data_karyawan.karyawan_selfie ? (
                                                                            <h6 style={{ fontSize: 12, color: "#2f145e" }}><a href={IMG_HOST + 'mitra/frontapp/karyawan/' + this.state.data_karyawan.karyawan_selfie}>{this.state.data_karyawan.karyawan_selfie}</a></h6>) : (
                                                                            <h6 style={{ fontSize: 12, color: "#2f145e" }}>-</h6>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>Foto KTP: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        {this.state.data_karyawan.karyawan_ktp ? (
                                                                            <h6 style={{ fontSize: 12, color: "#143B5E" }}><a href={IMG_HOST + 'mitra/frontapp/karyawan/' + this.state.data_karyawan.karyawan_ktp}>{this.state.data_karyawan.karyawan_ktp}</a></h6>) : (
                                                                            <h6 style={{ fontSize: 12, color: "#143B5E" }}>-</h6>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </CardBody>
                    </Card>
                </Col>
                <Col lg="12">
                    <Card className="main-card mb-3">
                        <CardBody>
                            {this.state.loading ?
                                <Spin size="large" tip="Sedang memuat data..." style={{
                                    width: '100%',
                                    margin: 'auto',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }} />
                                :
                                <div>
                                    {/*profil Karyawan*/}
                                    <div>
                                        <Row>
                                            <Col sm="12" md="8" lg="12">
                                                <Tabs selectedIndex={this.state.tabIndex} onSelect={this.setTabIndex}>
                                                    <TabList className="nav nav-tabs tab-coupon">
                                                        <Tab className="nav-link"><img alt='' src={GpsIcon} /> Presensi</Tab>
                                                        {/* : ""} */}
                                                    </TabList>
                                                    <TabPanel>
                                                        <ABSENSI
                                                            request={this.props.request}
                                                            match={this.props.match}
                                                            history={this.props.history}
                                                        />
                                                    </TabPanel>
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            }

                        </CardBody>
                    </Card>
                </Col>
            </Fragment>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: APIKEY
})(GuideKaryawanDetail)
