import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import i18n from "i18next";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import {Empty, Spin, Tooltip} from "antd";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import Paging from "../paging";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import SaveIcon from "../../assets/icons/save.svg";
import Swal from "sweetalert2";
import {BACKEND_API_HOST, DEFAULT_IMG} from "../../helper/host";
import moment from "moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import MoreIcon from "../../assets/icons/more.svg";
import {pageNumber} from "../../functions/functionGeneral";

export class List_Mapel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataHistory: [],
            currentPage: 0,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleClear = this.handleClear.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handleClear() {
        this.setState({
            modaladd            : false,
            modaledit           : false,
            modalHistory        : false,
            modalimport         : false,
            importFile          : null,
            mapel_nama          : "",
            mapel_kkm           : "",
            mapel_nilai_start   : "",
            mapel_nilai_end     : "",
            prosesSimpan        : false,
        });
    };

    handleAdd = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            mapel_nama          : this.state.mapel_nama,
            mapel_kkm           : this.state.mapel_kkm,
            mapel_nilai_start   : this.state.mapel_nilai_start,
            mapel_nilai_end     : this.state.mapel_nilai_end,
        }

        this.props.request("mapel/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.handleClear()
                    this.loadData()
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.setState({prosesSimpan: false})
                }
            })
    }

    handleEdit = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            mapel_id            : this.state.mapel_id,
            mapel_nama          : this.state.mapel_nama,
            mapel_kkm           : this.state.mapel_kkm,
            mapel_nilai_start   : this.state.mapel_nilai_start,
            mapel_nilai_end     : this.state.mapel_nilai_end,
        }

        this.props.request("mapel/edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.handleClear()
                    this.loadData()
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                    this.setState({prosesSimpan: false})
                }
            })
    }

    loadData() {
        this.setState({loading: true})
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("mapel/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        loading: false,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
            });
    }

    handleDelete() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('andayakinhapus'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        mapel_id    : this.state.mapel_id
                    }
                    this.setState({
                        data    : [],
                        loading : true
                    })
                    this.props.request("mapel/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    handlePageClick(data) {
        let selected = data.selected
        this.setState({ currentPage: selected }, () => {
            this.loadData()
        });
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        },()=>{
            let form = {
                id: id ? id : this.state.mapel_id,
                paging : 2,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("mapel/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                    else {
                        this.setState({
                            modalHistory: false,
                            mapel_id: ''
                        });
                    }
                });
        })
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected;
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    exportData = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'mapel/export?token=' + AuthKey, "_blank")
    }

    exportTemplate = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'mapel/export-template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'mapel/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.loadData()
                }
                else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        showConfirmButton: true,
                    })
                }
                this.setState({ importLoading: false })
                this.handleClear()
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Mata Pelajaran") >= 0) {
            moment.locale(i18n.language)
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("daftarmapel")} - iPresens</title>
                        <meta name="description" content={i18n.t("daftarmapel")} />
                    </Helmet>
                    <Breadcrumb parent={i18n.t("manajemenmapel")} title={i18n.t("daftarmapel")} setGuide={() => { this.setState({ guide: true }) }} />
                    <Modal style={{maxWidth: 1000}} zIndex={999} isOpen={this.state.modaladd} toggle={this.handleClear}>
                        <ModalHeader toggle={this.handleClear}>
                            <div className='pengumuman-modal-header-font'>{i18n.t("tambahmapel")}</div>
                        </ModalHeader>
                        <ModalBody className="pengumuman-modal-body">
                        <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("namamapel")}</label>
                                        <input className="form-control pengumuman-input" type="text" name="mapel_nama"
                                               value={this.state.mapel_nama} placeholder={i18n.t('ketikkannamamapel')}
                                               onChange={this.handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("mapelkkm")}</label>
                                        <input className="form-control pengumuman-input" type="number" name="mapel_kkm"
                                               value={this.state.mapel_kkm} placeholder={i18n.t('masukkannilaikkm')}
                                               onChange={this.handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("nilaiawal")}</label>
                                        <input className="form-control pengumuman-input" type="number" name="mapel_nilai_start"
                                               value={this.state.mapel_nilai_start} placeholder={i18n.t('masukkannilaiawal')}
                                               onChange={this.handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("nilaiakhir")}</label>
                                        <input className="form-control pengumuman-input" type="number" name="mapel_nilai_end"
                                               value={this.state.mapel_nilai_end} placeholder={i18n.t('masukkannilaiakhir')}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button onClick={this.handleClear} className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' disabled={this.state.prosesSimpan} onClick={this.handleAdd}><img alt='' src={SaveIcon}/> {this.state.prosesSimpan ? i18n.t("menyimpan") : i18n.t("simpan")}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal style={{maxWidth: 1000}} zIndex={999} isOpen={this.state.modaledit} toggle={this.handleClear}>
                        <ModalHeader toggle={this.handleClear}>
                            <div className='pengumuman-modal-header-font'>{i18n.t("editmapel")}</div>
                        </ModalHeader>
                        <ModalBody className="pengumuman-modal-body">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("namamapel")}</label>
                                        <input className="form-control pengumuman-input" type="text" name="mapel_nama"
                                               value={this.state.mapel_nama} placeholder={i18n.t('ketikkannamamapel')}
                                               onChange={this.handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("mapelkkm")}</label>
                                        <input className="form-control pengumuman-input" type="number" name="mapel_kkm"
                                               value={this.state.mapel_kkm} placeholder={i18n.t('masukkannilaikkm')}
                                               onChange={this.handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("nilaiawal")}</label>
                                        <input className="form-control pengumuman-input" type="number" name="mapel_nilai_start"
                                               value={this.state.mapel_nilai_start} placeholder={i18n.t('masukkannilaiawal')}
                                               onChange={this.handleChange}/>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("nilaiakhir")}</label>
                                        <input className="form-control pengumuman-input" type="number" name="mapel_nilai_end"
                                               value={this.state.mapel_nilai_end} placeholder={i18n.t('masukkannilaiakhir')}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button onClick={this.handleClear} className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' disabled={this.state.prosesSimpan} onClick={this.handleEdit}><img alt='' src={SaveIcon}/> {this.state.prosesSimpan ? "Menyimpan.." : i18n.t("simpan")}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal style={{maxWidth: 1000}} zIndex={999} isOpen={this.state.modalHistory} toggle={this.handleClear}>
                        <ModalHeader toggle={this.handleClear}>
                            <div className='modal-header-reactstrap'>{i18n.t('riwayatperubahan')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                {this.state.loadingHistory && (
                                    <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                        <Spin size="large" tip="Loading..." style={{ width: '100%', margin: 'auto' }} />
                                    </div>
                                )}
                                {this.state.dataHistory.length > 0 && (
                                    <div>
                                        <div className="row">
                                            <div className="col ml-4">
                                                <h4>{i18n.t('user')}</h4>
                                            </div>
                                            <div className="col mr-4">
                                                <h4>{i18n.t('aktivitas')}</h4>
                                            </div>
                                        </div>
                                        {this.state.dataHistory.map((item, index) => {

                                            const {p_user_name, level_name, created_at, history_status, parent_history, mapel_nama, mapel_kkm, mapel_nilai_start, mapel_nilai_end,} = item;
                                            const renderChange = (label, oldValue, newValue) => {
                                                if (oldValue === newValue) return null
                                                return (
                                                    <h6 className="mb-3 font-16">
                                                        {i18n.t(label + 'dari')} {oldValue}{' '}
                                                        <img src={ArrowRightIcon} alt="" style={{ width: '20px', marginBottom: '3px', height: '20px' }}
                                                        />{' '}
                                                        {i18n.t('jadi')} {newValue}
                                                    </h6>
                                                )
                                            }

                                            return (
                                                <div key={index} className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <div className="d-flex align-items-center p-3">
                                                            <img
                                                                src={DEFAULT_IMG}
                                                                alt=""
                                                                style={{
                                                                    width: '48px',
                                                                    height: '48px',
                                                                    marginRight: '16px',
                                                                    marginBottom: '25px',
                                                                }}
                                                            />
                                                            <div>
                                                                <h6 className="mb-0 font-16">
                                                                    {p_user_name} | {level_name}
                                                                </h6>
                                                                <p className="m-0 font-12">
                                                                <span className="col-green font-weight-bold">
                                                                    {moment(created_at).format('lll')}
                                                                </span>
                                                                </p>
                                                                <div style={{ marginTop: 12 }}>
                                                                    <div className={`border w-50 ${
                                                                        history_status === 'created' ?
                                                                            'border-primary text-primary' :
                                                                            'border-danger text-danger'
                                                                        }`}
                                                                         style={{ borderRadius: '25px' }}
                                                                    >
                                                                        <div className="d-flex justify-content-center py-2">
                                                                            {i18n.t(history_status === 'created' ? 'dibuat' : 'diubah')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {history_status !== 'created' && (
                                                        <div className="col-md-6 p-3 mb-3">
                                                            {parent_history && (
                                                                <>
                                                                    {renderChange('namamapel', parent_history.mapel_nama, mapel_nama)}
                                                                    {renderChange('mapelkkm', parent_history.mapel_kkm, mapel_kkm)}
                                                                    {renderChange('nilaiawal', parent_history.mapel_nilai_start, mapel_nilai_start)}
                                                                    {renderChange('nilaiakhir', parent_history.mapel_nilai_end, mapel_nilai_end)}
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{
                                            padding: "auto",
                                            margin: "auto",
                                            fontWeight: 500,
                                            lineHeight: "24px"
                                        }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} data
                                        </div>
                                        <div className='col'>
                                            <Paging
                                                handlePageClick={this.handlePageHistory}
                                                pageCount={this.state.pageCountHistory}
                                                currentPage={this.state.currentPageHistory}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalimport} toggle={this.handleClear}>
                        <ModalHeader toggle={this.handleClear}>
                            <div>{i18n.t('importjadwal')}</div>
                        </ModalHeader>
                        <ModalBody style={{ padding: "20px" }}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t('importcutitext')}</a><br/>
                                    <a onClick={() => this.exportTemplate()} style={{color: "#057CE4"}}>{i18n.t('importcutitemplates')}</a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({
                                                       importFile: event.target.files[0],
                                                   })
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i>{i18n.t('sedangmenguploaddata')}</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={() => this.importExcel()}>Import Data</button>
                            ]}
                        </ModalFooter>
                    </Modal>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-5 d-flex justify-content-end flex-wrap">
                                        <div className="flex-grow-1 mr-2">
                                            <div className='input-group btn-srch-kbeban'>
                                                    <span className='input-group-text izin-search-karyawan-img'>
                                                        <img alt='' src={SearchIcon}/>
                                                    </span>
                                                <input type='text' onChange={this.handleChange}
                                                       name='filter_nama' value={this.state.filter_nama}
                                                       className='form-control izin-search-karyawan-form'
                                                       style={{padding: "12px 10px",}}
                                                       placeholder={i18n.t("namamapel") + "..."}
                                                       onKeyDown={(e) => {
                                                           if (e.key === "Enter") {
                                                               this.loadData()
                                                           }
                                                       }}>
                                                </input>
                                            </div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <button className='btn border px-2'>
                                                <img alt="" src={RefreshIcon} onClick={() => {
                                                    this.setState({
                                                        filter_nama: ""
                                                    }, () => this.loadData())
                                                }}/></button>
                                        </div>
                                    </div>
                                    <div className="col-md-7 d-flex justify-content-end flex-wrap">
                                        <div className="flex-grow-1 mr-2">
                                            <button
                                                className="btn ipresens-btn-color text-capitalize width-filter pull-right"
                                                onClick={() => {
                                                    this.setState({modaladd: true});
                                                }}
                                            >
                                                <img src={PlusIcon} alt=""/> {i18n.t('tambahmapel')}
                                            </button>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Tooltip zIndex={1} trigger={'click'} placement="bottomLeft" color={'#FFF'}
                                                     title={
                                                         <div className={'p-2'}>
                                                             <a onClick={() => {
                                                                 this.setState({modalimport: true})
                                                             }}
                                                                style={{marginRight: "50px"}}
                                                                className={'text-dark'}>Import Excel</a><br/>
                                                             <a onClick={this.exportData}
                                                                style={{marginRight: "50px"}}
                                                                className={'text-dark'}>{i18n.t('eksporexcel')}</a><br/>
                                                         </div>
                                                     }>
                                                <a>
                                                    <button disabled={this.state.kelas_id === ''}
                                                            className='btn border border-dark border-1 px-2'
                                                    ><img alt="More" src={MoreIcon}/></button>
                                                </a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            {!this.state.loading && this.state.data.length === 0 ?
                                                <Empty description="Data Kosong"
                                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                :
                                                <div>
                                                    <Table className="table mb-5 table-striped table-borderless">
                                                        <Thead>
                                                            <Tr>
                                                                <Th className='lembur-th-style text-center' width="50">#</Th>
                                                                <Th className='lembur-th-style'>{i18n.t("namamapel")}</Th>
                                                                <Th className='lembur-th-style'>{i18n.t("mapelkkm")}</Th>
                                                                <Th className='lembur-th-style'>{i18n.t("nilaiawal")}</Th>
                                                                <Th className='lembur-th-style'>{i18n.t("nilaiakhir")}</Th>
                                                                <Th className='lembur-th-style text-center'>{i18n.t("aksi")}</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {this.state.loading ?
                                                                <Tr>
                                                                    <Td colSpan="9" style={{
                                                                        textAlign: "center",
                                                                        backgroundColor: "#FFFFFF"
                                                                    }}>
                                                                        <Spin size="large"
                                                                              tip={i18n.t("sedangmemuatdata") + ' ...'}
                                                                              style={{
                                                                                  width: '100%',
                                                                                  margin: 'auto',
                                                                                  marginBottom: '10px',
                                                                                  marginTop: '10px'
                                                                              }}/>
                                                                    </Td>
                                                                </Tr>
                                                                :
                                                                this.state.data.map((item, index) => {
                                                                    no += 1
                                                                    return (
                                                                        <Tr key={index}>
                                                                            <Td className="lembur-td-style text-center">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                                            <Td className="lembur-td-style fw-900">{item.mapel_nama}</Td>
                                                                            <Td className="lembur-td-style">{item.mapel_kkm}</Td>
                                                                            <Td className="lembur-td-style">{item.mapel_nilai_start}</Td>
                                                                            <Td className="lembur-td-style">{item.mapel_nilai_end}</Td>
                                                                            <Td className="lembur-td-style text-center">
                                                                                <Tooltip trigger={'click'} zIndex={1}
                                                                                         placement="bottom"
                                                                                         color={'#fff'} title={
                                                                                    <div className={'text-dark'}>
                                                                                        <a onClick={() => {
                                                                                            this.setState({
                                                                                                modalHistory: true,
                                                                                                mapel_id: item.mapel_id,
                                                                                                currentPageHistory: 0
                                                                                            }, () => {
                                                                                                this.loadHistory(item.mapel_id);
                                                                                            })
                                                                                        }}
                                                                                           className={'text-dark'}>{i18n.t("riwayat")}</a><br/>
                                                                                        <a onClick={() => {
                                                                                            this.setState({
                                                                                                mapel_id: item.mapel_id,
                                                                                                mapel_nama: item.mapel_nama,
                                                                                                mapel_kkm: item.mapel_kkm,
                                                                                                mapel_nilai_start: item.mapel_nilai_start,
                                                                                                mapel_nilai_end: item.mapel_nilai_end,
                                                                                                modaledit: true
                                                                                            })
                                                                                        }}>{i18n.t("edit")}</a><br/>
                                                                                        <a onClick={() => {
                                                                                            this.setState({
                                                                                                mapel_id: item.mapel_id
                                                                                            })
                                                                                            this.handleDelete()
                                                                                        }}
                                                                                           className={'text-danger'}>{i18n.t("hapus")}</a>
                                                                                    </div>
                                                                                }>
                                                                                    <a><i
                                                                                        className="fa fa-ellipsis-h"
                                                                                        style={{
                                                                                            width: 35,
                                                                                            fontSize: 18,
                                                                                            padding: 11,
                                                                                            color: 'rgb(51 51 51)'
                                                                                        }}
                                                                                    ></i></a>
                                                                                </Tooltip>
                                                                            </Td>
                                                                        </Tr>
                                                                    )
                                                                })}
                                                        </Tbody>
                                                    </Table>
                                                </div>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className='col font-14' style={{
                                                padding: "auto",
                                                margin: "auto",
                                                fontWeight: 500,
                                                lineHeight: "24px"
                                            }}>
                                                {i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}.
                                            </div>
                                            <div className="col">
                                                <Paging
                                                    className="float-right"
                                                    handlePageClick={this.handlePageClick}
                                                    pageCount={this.state.pageCount}
                                                    currentPage={this.state.currentPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_Mapel);
