import React, {Component, Fragment} from "react";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import i18n from "i18next";
import Breadcrumb from "../common/breadcrumb";
import {Empty, Spin, Tooltip} from "antd";
import RefreshIcon from "../../assets/icons/refresh.svg";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import {pageNumber} from "../../functions/functionGeneral";
import SearchIcon from "../../assets/icons/search.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import Paging from "../paging";
import AddPerusahaanTunjangan from "./add";
import './tunjangan.css'
import EditPerusahaanTunjangan from "./edit";
import Swal from "sweetalert2";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import moment from "moment/moment";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ReactPaginate from 'react-paginate';
import {DEFAULT_IMG} from "../../helper/host";

export class PerusahaanTunjangan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter_nama: '',
            paging: 10,
            loading: false,
            history_loading: false,
            filter_divisi: '',
            divisi_id: 0,
            tunjangan_id: 0,
            divisi: [],
            data: [],
            history: [],
            perPage: 0,
            currentPage: 0,
            perPageHistory: 0,
            currentPageHistory: 0,
            add_visible: false,
            edit_visible: false,
            modal_history: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handlePageClickHistory = this.handlePageClickHistory.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handlePageClick(data) {
        let selected = data.selected;
        this.setState({ currentPage: selected, loading: true, data: [] }, () => {
            this.loadData();
        });
    }

    handlePageClickHistory(history) {
        let selected = history.selected;
        this.setState({ currentPageHistory: selected, history_loading: true, history: [] }, () => {
            this.loadHistory();
        });
    }

    loadHistory(id) {
        this.setState({ loading_history: true, history: [] })
        let form = {
            tunjangan_id: this.state.tunjangan_id || id,
            divisi_id: this.state.divisi_id,
            paging: 2,
            page: this.state.currentPageHistory + 1,
        }
        this.props.request('tunjangan/history', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        history: response.data.data,
                        loading_history: false,
                        totalDataHistory: response.data.total,
                        pageCountHistory: response.data.last_page,
                        perPageHistory: response.data.per_page,
                    })
                }
            })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    resetFilter() {
        this.setState({
            filter_nama: '',
            data: [],
            loading: true,
            filter_divisi: '',
        }, () => this.loadData())
    }

    loadData() {
        this.setState({ loading: true , data: [] })
        let form = {
            filter_nama: this.state.filter_nama,
            paging: this.state.paging,
            filter_divisi: this.state.filter_divisi,
            page: this.state.currentPage + 1,
        }
        this.props.request('tunjangan/load-data', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false,
                    })
                }
            })
    }

    loadDivisi() {
        this.props.request('tunjangan/load-divisi', 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        divisi: response.divisi,
                    })
                }
            })
    }

    wantDelete = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakininginmenghapustunjangan'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        tunjangan_id: this.state.tunjangan_id
                    }
                    this.setState({
                        data: [],
                        loading: true
                    })
                    this.props.request("tunjangan/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('berhasilmenghapustunjangan'),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    componentDidMount() {
        this.loadDivisi()
        this.loadData()
    }

    render() {
        let no = 0;

        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{i18n.t('tunjanganperusahaan')} -
                        iPresens</title>
                    <meta name="description" content="Tunjangan Perusahaan"/>
                </Helmet>

                <Breadcrumb title={i18n.t('tunjanganperusahaan')}/>

                <AddPerusahaanTunjangan add_visible={this.state.add_visible}
                                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                                        changeState={(state, callback) => {
                                            this.setState(state, () => {
                                                if (typeof callback != 'undefined')
                                                    callback()
                                            })
                                        }}
                                        loadData={() => this.loadData()}
                />

                <EditPerusahaanTunjangan edit_visible={this.state.edit_visible}
                                         id={this.state.tunjangan_id}
                                         request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                                         changeState={(state, callback) => {
                                             this.setState(state, () => {
                                                 if (typeof callback != 'undefined')
                                                     callback()
                                             })
                                         }}
                                         loadData={() => this.loadData()}
                />

                <Modal zIndex="999" size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modal_history} toggle={() => this.setState({ modal_history: false })}>
                    <ModalHeader toggle={() => this.setState({ modal_history: false })} className="pengumuman-modal-header">
                        <div className='pengumuman-modal-header-font'>{i18n.t("riwayattunjanganperusahaan")}</div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="list-group">
                            <div className="">
                                {this.state.loading_history && (
                                    <div style={{textAlign: "center", margin: '10px auto'}}>
                                        <Spin size="large" tip={i18n.t('loading')}
                                              style={{width: "100%", margin: "auto"}}/>
                                    </div>
                                )}
                                {this.state.history.length !== 0 && (
                                    <div>
                                        <Row>
                                            <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                <h4>{i18n.t('user')}</h4>
                                            </Col>
                                            <Col xs={0} md={0} className="text-left"style={{ marginRight: '20px' }}>
                                                <h4>{i18n.t('aktivitas')}</h4>
                                            </Col>
                                        </Row>

                                        {this.state.history.map((item, index) => {
                                            return (
                                                <div key={index} style={{ marginBottom : "20px" }}>
                                                    <Row>
                                                        <Col xs={12} md={6} className="text-left">
                                                            <div style={{ display: 'flex', alignItems: 'center', padding: '16px 12px' }}>
                                                                <div style={{marginRight: '16px'}}>
                                                                    <img
                                                                        src={DEFAULT_IMG}
                                                                        alt=""
                                                                        style={{ width: '48px' , marginBottom:'25px', height:'48px'}}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <h6 className="mb-0 font-13" style={{
                                                                        fontWeight: 500,
                                                                        fontSize: '16px',
                                                                        lineHeight: '24px'
                                                                    }}>{item.p_user_name} | {item.level_name}</h6>
                                                                    <p className="m-0 font-12">
                                                                            <span
                                                                                className="col-green font-weight-bold">
                                                                              {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                    </p>
                                                                    <div style={{ marginTop: 8 }}>
                                                                        {item.history_status === "created" ? (
                                                                            <div
                                                                                className="m-0"
                                                                                style={{
                                                                                    border: '1px solid #000',
                                                                                    borderRadius: '25px',
                                                                                    width: '50%',
                                                                                    color: '#057CE4',
                                                                                    borderColor: '#057CE4',
                                                                                }}
                                                                            >
                                                                                <div className="d-flex justify-content-center" style={{
                                                                                    paddingTop: '6px',
                                                                                    paddingBottom: '6px'
                                                                                }}>
                                                                                    {i18n.t('dibuat')}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                className="m-0"
                                                                                style={{
                                                                                    border: '1px solid #000',
                                                                                    borderRadius: '25px',
                                                                                    width: '50%',
                                                                                    color: '#E6462E',
                                                                                    borderColor: '#E6462E',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className="d-flex justify-content-center"
                                                                                    style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                    {i18n.t('diubah')}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={12} md={6} className="text-left" style={{
                                                            padding: '28px 12px 16px',
                                                        }}>
                                                            {(item.history_status !== 'created' && item.history) && (
                                                                <div>
                                                                    {item.history?.tunjangan_name !== item.tunjangan_name && (
                                                                        <div>
                                                                            <h6
                                                                                className="mb-3 font-13 text-left"
                                                                                style={{
                                                                                    fontWeight: 100,
                                                                                    fontSize: '16px',
                                                                                    lineHeight: '24px',
                                                                                }}
                                                                            >
                                                                                {i18n.t('namatunjangan')} : {i18n.t('dari')}{' '}
                                                                                {item.history?.tunjangan_name}
                                                                                <img
                                                                                    src={ArrowRight}
                                                                                    alt=""
                                                                                    style={{
                                                                                        width: '20x',
                                                                                        marginBottom: '1px',
                                                                                        height: '18px'
                                                                                    }}
                                                                                /> {i18n.t('jadi')}
                                                                                : {item?.tunjangan_name}
                                                                            </h6>
                                                                        </div>
                                                                    )}
                                                                    {item.history.tunjangan_desc !== item.tunjangan_desc && (
                                                                        <div>
                                                                            <h6
                                                                                className="mb-3 font-13 text-left"
                                                                                style={{
                                                                                    fontWeight: 100,
                                                                                    fontSize: '16px',
                                                                                    lineHeight: '24px',
                                                                                }}
                                                                            >
                                                                                {i18n.t('deskripsitunjangan')} : {i18n.t('dari')}{' '}
                                                                                {item.history?.tunjangan_desc}
                                                                                <img
                                                                                    src={ArrowRight}
                                                                                    alt=""
                                                                                    style={{
                                                                                        width: '20x',
                                                                                        marginBottom: '1px',
                                                                                        height: '18px'
                                                                                    }}
                                                                                /> {i18n.t('jadi')}
                                                                                : {item?.tunjangan_desc}
                                                                            </h6>
                                                                        </div>
                                                                    )}
                                                                    {item.history.divisi.divisi_name !== item.divisi.divisi_name && (
                                                                        <div>
                                                                            <h6
                                                                                className="mb-3 font-13 text-left"
                                                                                style={{
                                                                                    fontWeight: 100,
                                                                                    fontSize: '16px',
                                                                                    lineHeight: '24px',
                                                                                }}
                                                                            >
                                                                                {i18n.t('divisi')} : {i18n.t('dari')}{' '}
                                                                                {item.history?.divisi?.divisi_name}
                                                                                <img
                                                                                    src={ArrowRight}
                                                                                    alt=""
                                                                                    style={{
                                                                                        width: '20x',
                                                                                        marginBottom: '1px',
                                                                                        height: '18px'
                                                                                    }}
                                                                                /> {i18n.t('jadi')}
                                                                                : {item?.divisi?.divisi_name}
                                                                            </h6>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {this.state.history.length !== 0 &&
                            <>
                                <div className='col font-14' style={{
                                    padding: "auto",
                                    margin: "auto",
                                    fontWeight: 500,
                                    lineHeight: "24px"
                                }}>{i18n.t('menampilkan')} {this.state.history.length === 0 || this.state.history.length === null ? "0" : this.state.history.length} {i18n.t('dari')} {this.state.totalDataHistory} {i18n.t('data')}
                                </div>
                                <div className='col'>
                                    <ReactPaginate
                                        previousLabel={<img src={ArrowLeft} alt=''/>}
                                        nextLabel={<img src={ArrowRight} alt=''/>}
                                        pageCount={this.state.pageCountHistory}
                                        forcePage={this.state.currentPageHistory}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={this.handlePageClickHistory}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link custom-page-link'}
                                        containerClassName={'pagination my-2'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link custom-page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link custom-prev-next-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link custom-prev-next-link'}
                                        activeClassName={'active custom-active'}
                                    />
                                </div>
                            </>
                        }
                    </ModalFooter>
                </Modal>

                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header pb-0">
                            <div className="row">
                                <div className="col-md-4 pr-lg-1 pr-md-1">
                                    <div className='input-group btn-srch-kbeban'>
                                        <span className='input-group-text izin-search-karyawan-img'>
                                            <img alt='' src={SearchIcon}/>
                                        </span>
                                        <input type='text' onChange={this.handleChange}
                                               name='filter_nama' value={this.state.filter_nama}
                                               className='form-control izin-search-karyawan-form filter_nama'
                                               placeholder={i18n.t("cari") + ("...")}
                                               onKeyDown={(e) => {
                                                   if (e.key === "Enter") {
                                                       this.loadData()
                                                   }
                                               }}>
                                        </input>
                                    </div>
                                </div>
                                <div className="col-md-3 px-lg-1 px-md-1 d-flex flex-row flex-nowrap mb-3">
                                    <select name="filter_divisi"
                                            className="form-control karyawan-filter-jabatan mr-2 btn-slc-krywn"
                                            style={{borderRadius: "4px"}}
                                            onChange={this.handleFilter}>
                                        <option value="" selected={this.state.filter_divisi === ''} >{i18n.t("pilihdivisi")}</option>
                                        {this.state.divisi?.map((item) => (
                                            <option value={item.divisi_id}>{item.divisi_name}</option>
                                        ))}
                                    </select>
                                    <div>
                                        <button className='btn ipresens-btn-reset border px-2'>
                                            <img alt="" src={RefreshIcon}
                                                 onClick={this.resetFilter}/>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="d-flex justify-content-end align-items-center mx-1">
                                        <button
                                            className="btn ipresens-btn-color px-3 text-capitalize width-filter tombol-export"
                                            onClick={() => {
                                                this.setState({add_visible: true});
                                            }}>
                                            <img src={PlusIcon} alt="Tambah Istilah"/> {i18n.t('tambah')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-2">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        {!this.state.loading && this.state.data.length === 0 ?
                                            <Empty description={i18n.t("datakosong")}
                                                   image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                            :
                                            <Table className="table table-striped table-borderless mb-5">
                                                <Thead>
                                                    <Tr>
                                                        <Th className="lembur-th-style" style={{maxWidth: "30px"}}>#</Th>
                                                        <Th className="text-left lembur-th-style" style={{ minWidth: "180px" }}>{i18n.t("namadivisi")}</Th>
                                                        <Th className="text-left lembur-th-style" style={{ minWidth: "180px" }}>{i18n.t("namatunjangan")}</Th>
                                                        <Th className="text-left lembur-th-style">{i18n.t("deskripsitunjangan")}</Th>
                                                        <Th className="text-right lembur-th-style">{i18n.t("aksi")}</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {this.state.loading && (
                                                        <Tr>
                                                            <Td colSpan={5}>
                                                                <Spin size="large" tip="Loading..." style={{
                                                                    width: '100%',
                                                                    margin: 'auto',
                                                                    marginBottom: '10px',
                                                                    marginTop: '10px'
                                                                }} />
                                                            </Td>
                                                        </Tr>
                                                    )}
                                                    {this.state.data.map((item) => {
                                                        no += 1
                                                        return (
                                                            <Tr>
                                                                <Td className="lembur-td-style" style={{maxWidth: "30px"}}>{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                                <Td className="text-left lembur-td-style" style={{ minWidth: "180px" }}>{item.divisi?.divisi_name}</Td>
                                                                <Td className="text-left lembur-td-style" style={{ minWidth: "180px" }}>{item.tunjangan_name}</Td>
                                                                <Td className="text-left lembur-td-style">{item.tunjangan_desc}</Td>
                                                                <Td className="text-right lembur-td-style">
                                                                    <Tooltip trigger={'click'} placement={'bottomRight'} color={'#fff'} title={
                                                                        <div className="p-2 d-flex flex-column">
                                                                            <a className="text-dark"
                                                                               onClick={() => this.setState({
                                                                                   modal_history: true,
                                                                                   tunjangan_id: item.tunjangan_id,
                                                                                   divisi_id: item.divisi_id
                                                                               }, () => this.loadHistory(item.tunjangan_id))}>{i18n.t('riwayat')}</a>

                                                                            <a className="text-dark"
                                                                               onClick={() => this.setState({
                                                                                   edit_visible: true,
                                                                                   tunjangan_id: item.tunjangan_id
                                                                               })}>{i18n.t('edit')}</a>

                                                                            <a className="text-danger"
                                                                               onClick={() => this.setState({
                                                                                   tunjangan_id: item.tunjangan_id
                                                                               }, () => this.wantDelete())}>{i18n.t('hapus')}</a>
                                                                        </div>
                                                                    }>
                                                                        <a><i className="fa fa-ellipsis-h" style={{
                                                                            width: 35,
                                                                            fontSize: 18,
                                                                            padding: 5,
                                                                            color: 'rgb(51 51 51)'
                                                                        }}/></a>
                                                                    </Tooltip>
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    })}
                                                </Tbody>
                                            </Table>
                                        }
                                        <Paging
                                            className="float-right"
                                            handlePageClick={this.handlePageClick}
                                            pageCount={this.state.pageCount}
                                            currentPage={this.state.currentPage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PerusahaanTunjangan);