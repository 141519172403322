import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import i18n from "i18next";
import { connect } from "react-redux";
import ImportIcon from "../../assets/icons/import-icon.svg";
import ExportIcon from "../../assets/icons/download-white.svg";
import IconPlusWhite from "../../assets/icons/IconPlusWhite.svg";
import {Empty, Form, Spin, Tooltip} from "antd";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import Add from './payment/add'
import moment from "moment";
import Paging from "../paging";
import {
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    Modal as ModalRs,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Row
} from "reactstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import Breadcrumb from "../common/breadcrumb";
import Tour from "reactour";
import {pageNumber} from "../../functions/functionGeneral";
import format from "format-number";
import {BACKEND_API_HOST, DEFAULT_IMG} from "../../helper/host";
import WarningIcon from "../../assets/icons/warning-icon.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {DateRange} from "react-date-range";
import ReactPaginate from "react-paginate";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import {getCurrency} from "../../helper/money";

export class PembayaranSiswa extends Component {
    constructor(props) {
        super(props);
        let currentDate = new Date();
        let currentYear = currentDate. getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        this.state = {
            selectedBulan: currentMonth.toString().padStart(2, '0') // Format dua digit
        };
        this.state = {
            selectedBulan: moment().format('MM'),
            selectedTahun: currentYear,
            data: [],
            payment: [],
            kelas: [],
            tagihan: [],
            tagihan_id: 0,
            loading: false,
            filter: '',
            add_visible: false,
            currentPage: 0,
            modal_edit: false,
            siswa_selected: null,
            jumlah_pembayaran: '', selectedTerms: [],
            siswa: [],
            istilah: [],
            siswa_loading: false,
            siswa_name: '',
            siswa_id: 0,
            istilah_combined:[],
            id_detail: 0,
            term_loading: false,
            term_selected: null,
            term_id: 0,
            id_delete: 0,
            filter_kelas: '',
            importFile: null,
            start_date: new Date(),
            end_date: new Date(),
            modalexport: false,
            exportStartDate: "",
            exportEndDate: "",
            reactDateRange: false,
            valueDate: "",
            dataHistory: [],
            pagination: 2,
            currentPageHistory: 0,
            modalHistory: false,
            loadingHistory: false,
            payment_date: '',
        }
        this.resetFilter = this.resetFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleBulanTahunChange = this.handleBulanTahunChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.exportPembayaran = this.exportPembayaran.bind(this);
        this.importPembayaran = this.importPembayaran.bind(this);
        this.handlePageHistory = this.handlePageHistory.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handlePageClick(data) {
        let selected = data.selected;
        this.setState({ currentPage: selected, loading: true, data: [] }, () => {
            this.loadData();
        });
    }

    handleYearChange = (event) => {
        this.setState({ selectedTahun: event.target.value, data: [], loading: true, }, () => {
            this.loadData();
        });
    };

    handleBulanTahunChange = (event) => {
        this.setState({ selectedBulan: event.target.value, data: [], loading: true, }, () => {
            this.loadData();
        });
    };

    resetFilter(event) {
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        this.setState({
            filter: "",
            selectedBulan: currentMonth,
            selectedTahun: currentYear,
            data: [],
            loading: true,
            filter_kelas: '',
        }, () => this.loadData())
    }

    searchKaryawan(){
        let form = {
            karyawan_name : this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response)=> {
                if (response.success){
                    this.setState({
                        siswa: response.data,
                        siswa_loading: false,
                    })
                }
            })
    }

    searchTerm() {
        let form = {}
        this.props.request('pembayaran/payment_term_list', form , 'POST')
            .then((response)=> {
                if (response.success){
                    const payterm = response.data.data;
                    const combined = payterm.map(item => `${item.pb_term_name}_${item.pb_term_id}`);
                    this.setState({
                        istilah: payterm,
                        istilah_combined: combined,
                    });
                }
                this.setState({
                    term_loading: false,
                })
            })
    }

    exportPembayaran() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'pembayaran/export?token=' + AuthKey + '&pembayaranStartDate=' + (this.state.exportStartDate === true ? this.state.exportStartDate : moment(this.state.start_date).format("YYYY-MM-DD")) + '&pembayaranEndDate=' + (this.state.exportEndDate === true ? this.state.exportEndDate : moment(this.state.end_date).format("YYYY-MM-DD")), "_blank")
    }

    exportTemplateKosong() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'pembayaran/export-template?token=' + AuthKey, "_blank")
    }

    importPembayaran() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'pembayaran/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('importpembayaranberhasil'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    handleCancel = e => {
        this.setState({
            modalexport: false,
            modalimport: false,
            valueDate: "",
            exportStartDate: "",
            exportEndDate: "",
            start_date: new Date(),
            end_date: new Date()
        });
    };

    loadDetail() {
        let form = {
            pembayaran_id: this.state.id_detail,
        }
        this.props.request('pembayaran/payment_detail', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        payment: response.data,
                        siswa_id: response.data.karyawan_id,
                        siswa_selected: {
                            value: response.data.siswa.karyawan_id,
                            label: response.data.siswa.karyawan_nama,
                        },
                        term_selected: {
                            value: response.data.pembayaran_term.pb_term_id,
                            label: response.data.pembayaran_term.pb_term_name,
                        },
                        jumlah_pembayaran: response.data.pembayaran_jumlah,
                        payment_date: response.data.pembayaran_date,
                        tagihan_id: response.data.tagihan_id
                    }, () => this.searchTerm())
                }
                this.setState({
                    loading: false,
                } , () => this.searchTerm())
            })
    }

    loadData() {
        this.setState({
            loading: true,
        })
        let form = {
            filter: this.state.filter,
            paginate: 10,
            filter_month: this.state.selectedBulan,
            filter_year: this.state.selectedTahun,
            page: this.state.currentPage + 1,
            filter_kelas: this.state.filter_kelas,
        }
        this.props.request('pembayaran/payment_list', form, 'POST')
        .then(response => {
            if (response.success) {
                this.setState({
                    data: response.data.data,
                    totalData: response.data.total,
                    pageCount: response.data.last_page,
                    perPage: response.data.per_page,
                })
            }
            this.setState({ loading: false });
        })
    }

    loadDataKelas() {
        let form = {}
        this.props.request('divisi/list-divisi', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        kelas: response.data,
                    })
                }
            })
    }

    handleSubmitEdit = () => {
        let form = {
            id_detail: this.state.id_detail,
            jumlahPembayaran: this.state.jumlah_pembayaran,
            term_id: this.state.term_id ? this.state.term_id  : this.state.term_selected.value,
            siswa_id: this.state.siswa_id,
            payment_date: this.state.payment_date,
            tagihan_id: this.state.tagihan_id
        }
        this.props.request('pembayaran/edit_payment', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        modal_edit: false,
                        jumlah_pembayaran: '', term_id: 0, siswa_id: 0
                    }, () => this.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: response.message,
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    deleteThis = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('confirmhapuspembayaransiswa'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.wantDetele()
                }
            })
    }

    wantDetele() {
        this.setState({ loading: true, data: [] })
        let form = {
            pembayaran_id: this.state.id_delete,
        }
        this.props.request('pembayaran/delete_payment', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        confirmDelete: false
                    })
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildihapus'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.loadData()
            })
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected;
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.pembayaran_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("pembayaran/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            pembayaran_id: ''
                        });
                    }
                });
        })
    }

    searchTagihan() {
        let form = {}
        this.props.request('tagihan_siswa/search-tagihan', form, "POST")
            .then((response) => {
                if (response.success){
                    this.setState({
                        tagihan: response.data,
                    })
                }
            })
    }

    componentDidMount() {
        this.loadData();
        this.loadDataKelas()
        this.searchTagihan()
    }

    render() {
        let no = 0;
        const selectionRange = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            key: 'selection',
        }
        const steps = [
            {
                selector: '.pembayaran-siswa',
                content: 'Anda dapat membuat Pembayaran Siswa disini',
            },
            {
                selector: '.daftar-istilah-pembayaran',
                content: 'Daftar Istilah Pembayaran anda dapat dilihat disini',
            },
        ];
        const currency = this.props.user.perusahaan.perusahaan_currency;
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Pembayaran Siswa") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t('pembayaransiswa')} - Presensi</title>
                        <meta name="description" content="Detail Daftar Payroll" />
                    </Helmet>

                    <Add
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />

                    <Breadcrumb title={i18n.t("pembayaransiswa")}  parent={i18n.t("pembayaran")}  setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <div className="row justify-content-between row-btn-payroll mx-0">
                                    <h5 className="">{i18n.t('daftarpembayaransiswa')}</h5>
                                    <div className="row">
                                        <div className="col-auto px-0 mr-2">
                                            <button className="ipresens-import-button btn-import-krywn"
                                                    onClick={() => {
                                                        this.setState({
                                                            modalimport: true,
                                                        })
                                                    }}
                                            >
                                                <img src={ImportIcon} alt="" className="mr-2 mb-1"/> Import
                                            </button>
                                        </div>
                                        <div className='col-auto px-0' style={{textAlign: "right"}}>
                                            <button
                                                onClick={() => this.setState({
                                                    modalexport: true
                                                })}
                                                className='btn ipresens-btn-color'
                                                style={{ textTransform: "none"}}
                                            >
                                                <img alt='' src={ExportIcon}/> {i18n.t('exportexcell')}
                                            </button>
                                        </div>
                                        <div className="col-auto px-0 ml-2 mr-2">
                                            <button className="btn ipresens-btn-color"
                                                    style={{ lineHeight: "24px" }}
                                                    onClick={() => {
                                                        this.setState({
                                                            add_visible: true,
                                                        })
                                                    }}
                                            >
                                                <img src={IconPlusWhite} alt="" className="mr-2 mb-1"/> {i18n.t('tambahpembayaran')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row filter-karyawan row-btn-payroll-list" style={{marginLeft: '15px'}}>
                                <div className="col-md-3 px-1 mb-1">
                                    <div className="input-group btn-srch-payroll-list">
                                        <input name="filter"
                                               className={this.state.filter !== "" ? "form-control" : "form-control bg-image-search"}
                                               placeholder={i18n.t('cari')}
                                               type="text"
                                               required=""
                                               style={{paddingLeft: this.state.filter === "" ? "35px" : "10px"}}
                                               onKeyUp={(event) => {
                                                   if (event.which === 13) {
                                                       event.preventDefault();
                                                       this.setState({
                                                           loading: true,
                                                           data: [],
                                                       }, () => {
                                                           this.loadData()
                                                       })
                                                   }
                                               }} onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2 px-1 mb-1">
                                    <select name="month" className="form-control" onChange={this.handleBulanTahunChange}
                                            value={this.state.selectedBulan}>
                                        <option value="01">Januari</option>
                                        <option value="02">Februari</option>
                                        <option value="03">Maret</option>
                                        <option value="04">April</option>
                                        <option value="05">Mei</option>
                                        <option value="06">Juni</option>
                                        <option value="07">Juli</option>
                                        <option value="08">Agustus</option>
                                        <option value="09">September</option>
                                        <option value="10">Oktober</option>
                                        <option value="11">November</option>
                                        <option value="12">Desember</option>
                                    </select>
                                </div>
                                <div className="col-md-2 px-1 mb-1">
                                    <select name="year" className="form-control" onChange={this.handleYearChange}
                                            value={this.state.selectedTahun}>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                        <option value="2026">2026</option>
                                        <option value="2027">2027</option>
                                        <option value="2028">2028</option>
                                        <option value="2029">2029</option>
                                        <option value="2030">2030</option>
                                    </select>
                                </div>
                                <div className="col-md-3 px-1 mb-1">
                                    <div className="input-group btn-slct-status-payroll">
                                        <select className="form-control mr-1" name="filter_kelas"
                                                onChange={this.handleFilter}>
                                            <option value="" selected={this.state.filter_kelas === ''}>{i18n.t('pilihkelas')}</option>
                                            {this.state.kelas.map((item) => (
                                                <option value={item.divisi_name}>{item.divisi_name}</option>
                                            ))}
                                        </select>
                                        <button className="btn ipresens-btn-reset" onClick={this.resetFilter}><span
                                            className="fa fa-refresh"></span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body daftar-karyawan">
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    <Table className="table table-striped table-borderless">
                                        <Thead>
                                            <Tr>
                                                <Th className="mb-5">#</Th>
                                                <Th className="text-center mb-5">{i18n.t('tagihan')}</Th>
                                                <Th className="text-center mb-5">{i18n.t('nama')}</Th>
                                                <Th className="text-center mb-5">{i18n.t('jenispembayaran')}</Th>
                                                <Th className="text-center mb-5">{i18n.t('jumlahpembayaran')}</Th>
                                                <Th className="text-center mb-5">{i18n.t('tanggalpembayaran')}</Th>
                                                <Th className="text-center mb-5">{i18n.t('aksi')}</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading &&
                                                <Tr>
                                                    <Td colSpan={11}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </Td>
                                                </Tr>
                                            }
                                            {this.state.data.map((item, index) => {
                                                no += 1;
                                                return (
                                                    <Tr>
                                                        <Td>{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                        <Td className="text-center">{item.tagihan ? item.tagihan.tagihan_nama : '-'}</Td>
                                                        <Td className="text-center">{item.siswa.karyawan_nama}</Td>
                                                        <Td className="text-center">{item.pembayaran_term ? item.pembayaran_term.pb_term_name : '-'}</Td>
                                                        <Td className="text-center">{format({ prefix: getCurrency(currency), integerSeparator: "." })(item.pembayaran_jumlah ? item.pembayaran_jumlah : '-')}</Td>
                                                        <Td className="text-center">{moment(item.pembayaram_date).format('LL')}</Td>
                                                        <Td className="text-center">
                                                            <Tooltip trigger={'click'} placement={'bottomRight'} color={'#fff'} title={
                                                                <div className="p-2">
                                                                    <a className="text-dark"
                                                                       onClick={() => {
                                                                           this.setState({
                                                                               modalHistory: true,
                                                                               pembayaran_id: item.pembayaran_id
                                                                           }, () => {
                                                                               this.loadHistory(item.pembayaran_id);
                                                                           })
                                                                       }}>{i18n.t("riwayat")}</a><br/>
                                                                    <a className="text-dark"
                                                                       onClick={() => this.setState({
                                                                           modal_edit: true,
                                                                           id_detail: item.pembayaran_id
                                                                       }, () => this.loadDetail())}>{i18n.t('edit')}</a><br/>
                                                                    <a className="text-danger"
                                                                       onClick={() => this.setState({
                                                                           id_delete: item.pembayaran_id
                                                                       }, () => this.deleteThis())}>{i18n.t('hapus')}</a>
                                                                </div>
                                                            }>
                                                                <a><i className="fa fa-ellipsis-h" style={{
                                                                    width: 35,
                                                                    fontSize: 18,
                                                                    padding: 11,
                                                                    color: 'rgb(51 51 51)'
                                                                }}/></a>
                                                            </Tooltip>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>

                    <Modal size="lg" zIndex={999} isOpen={this.state.modal_edit} toggle={() => this.setState({
                        siswa_selected: null, jumlah_pembayaran: "", selectedTerms: [], modal_edit: false,
                    })} style={{ maxWidth: "800px" }}>
                        <ModalHeader>
                            <div className='text-dark' style={{
                                fontWeight: 'bold', fontSize: "18px"
                            }}>{i18n.t("editpembayaransiswa")}</div>
                        </ModalHeader>
                        <ModalBody>
                            <Form layout="vertical" hideRequiredMark style={{width: "100%"}}>
                                <div className="col mb-2">
                                    <label htmlFor="">{i18n.t('tanggalpembayaran')}</label>
                                    <input type="date" name="payment_date" className="form-control"
                                           value={this.state.payment_date}
                                           onChange={this.handleChange}/>
                                </div>
                                <div className="col mb-2">
                                    <label htmlFor="">{i18n.t('tagihan')}</label>
                                    <select name="tagihan_id" onChange={this.handleChange} className="form-control">
                                        <option value="">{i18n.t('pilihtagihan')}</option>
                                        {this.state.tagihan?.map((item, index) => {
                                            return (
                                                <option value={item.tagihan_id} key={index} selected={this.state.tagihan_id === item.tagihan_id}>{item.tagihan_nama}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="col">
                                    <label htmlFor="">{i18n.t('siswa')}</label>
                                    <Select
                                        placeholder={i18n.t('nama')}
                                        backspaceRemoves={true}
                                        deleteRemoves={true}
                                        styles={{paddingLeft: "0px"}}
                                        components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                        value={this.state.siswa_selected}
                                        isLoading={this.state.siswa_loading}
                                        onFocus={() => this.setState({siswa_loading: true}, () => this.searchKaryawan())}
                                        onBlur={() => this.setState({siswa_loading: false})}
                                        onInputChange={(val) => this.setState({siswa_name: val}, () => this.setState({siswa_loading: true}, () => this.searchKaryawan()))}
                                        options={
                                            this.state.siswa != null ? (
                                                    this.state.siswa.map((item, index) =>
                                                        (
                                                            {
                                                                key: index,
                                                                value: item.karyawan_id,
                                                                label: item.karyawan_nama,
                                                            }
                                                        )
                                                    ))
                                                : ("")
                                        }
                                        onChange={
                                            (siswa_selected) => {
                                                if (!siswa_selected) {
                                                    this.setState({
                                                        siswa_selected: '',
                                                        karyawan_id: 0,
                                                    })
                                                } else {
                                                    this.setState({
                                                        siswa_selected,
                                                        siswa_id: siswa_selected.value,
                                                        siswa: []
                                                    })
                                                }
                                            }
                                        }
                                        isClearable={() => {
                                            this.setState({
                                                isClearable: true,
                                            })
                                        }}
                                    />
                                </div>
                                <div className="row-cols-2 row"
                                     style={{paddingLeft: "13px", paddingRight: "13px", marginTop: "8px"}}>
                                    <div className="col">
                                        <label className="">{i18n.t('tipe')}</label>
                                        <Select
                                            placeholder={i18n.t('nama')}
                                            backspaceRemoves={true}
                                            deleteRemoves={true}
                                            styles={{paddingLeft: "0px"}}
                                            components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                            value={this.state.term_selected}
                                            isLoading={this.state.term_loading}
                                            onFocus={() => this.setState({term_loading: true}, () => this.searchTerm())}
                                            onBlur={() => this.setState({term_loading: false})}
                                            onInputChange={(val) => this.setState({term_name: val}, () => this.setState({term_loading: true}, () => this.searchTerm()))}
                                            options={
                                                this.state.istilah != null ? (
                                                        this.state.istilah.map((item, index) =>
                                                            (
                                                                {
                                                                    key: index,
                                                                    value: item.pb_term_id,
                                                                    label: item.pb_term_name,
                                                                }
                                                            )
                                                        ))
                                                    : ("")
                                            }
                                            onChange={
                                                (term_selected) => {
                                                    if (!term_selected) {
                                                        this.setState({
                                                            term_selected: '',
                                                            term_id: 0,
                                                        })
                                                    } else {
                                                        this.setState({
                                                            term_selected,
                                                            term_id: term_selected.value,
                                                            istilah: []
                                                        })
                                                    }
                                                }
                                            }
                                            isClearable={() => {
                                                this.setState({
                                                    isClearable: true,
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-5">
                                        <label htmlFor="">{i18n.t('jumlahpembayaran')}</label>
                                        <div className="d-flex align-items-center" style={{position: "relative"}}>
                                            <span className="pl-2"
                                                  style={{position: "absolute", fontSize: "16px"}}>Rp.</span>
                                            <input type="number" className="form-control" onChange={this.handleChange}
                                                   name="jumlah_pembayaran" value={this.state.jumlah_pembayaran}
                                                   style={{paddingLeft: "30px"}}/>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <button type="button" className="btn ipresens-btn-cancel" onClick={() => this.setState({
                                    modal_edit: false,
                                })}
                                        style={{marginRight: 8}}>
                                    {i18n.t("batal")}
                                </button>
                                <button type="button" className="btn ipresens-btn-color"
                                        disabled={this.state.loading} onClick={this.handleSubmitEdit} disabled={this.state.loading}>
                                    {this.state.loading ?
                                        <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                        :
                                        i18n.t("simpan")
                                    }
                                </button>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalimport}>
                        <ModalHeader onClick={this.handleCancel} toggle={() => this.setState({importFile: null})}>
                            <div>{i18n.t('importpembayaran')}</div>
                        </ModalHeader>
                        <ModalBody style={{ padding: "20px" }}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t('importtemplate')}</a><br/>
                                    <a onClick={this.exportTemplateKosong} style={{color: "#057CE4"}}>{i18n.t('unduhtemplate')}</a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({
                                                       importFile: event.target.files[0],
                                                   })
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i> {i18n.t('prosesupload')}</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importPembayaran}>{i18n.t('importdata')}</button>
                            ]}
                        </ModalFooter>
                    </Modal>
                    <ModalRs
                        size='lg'
                        style={{ maxWidth: "394px" }}
                        isOpen={this.state.modalexport}
                        toggle={() => this.setState({
                            reactDateRange: false
                        }, () => this.handleCancel())}
                    >
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={this.handleCancel}>{i18n.t('exportexcel')}</ModalHeader>
                        <ModalBody>
                            <div className='row'>
                                <div className='col' style={{ lineHeight: "12px" }}>
                                    <div className='d-flex flex-row warning-info px-2 mb-2'>
                                        <div className='px-0 mr-1'><img src={WarningIcon} alt="" /></div>
                                        <div className='px-0 mt-1' style={{ fontSize: "14px" }}>
                                            {i18n.t('filtertanggal')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2' style={{ width:"780px" }}>
                                <div className='col-md-6'>
                                    <InputGroup className="form-tgl-pembayaran mb-0">
                                        <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                            </div>
                                        </InputGroupAddon>
                                        <Input bsSize="sm" className='font-edit-value' style={{ paddingTop: "8px", paddingBottom: "8px" }} onFocus={() => this.setState({ reactDateRange: true })} placeholder={i18n.t('filtertanggal')} value={this.state.valueDate} />
                                    </InputGroup>
                                </div>
                            </div>
                            {this.state.reactDateRange === true &&
                                <div className='shadow table-responsive d-flex flex-column mb-2' style={{ maxWidth: "360px" }}>
                                    <div className='d-flex justify-content-center mt-3'>
                                        <DateRange
                                            onBlur={() => this.setState({ reactDateRange: false })}
                                            ranges={[selectionRange]}
                                            onChange={(e) => {
                                                this.setState({
                                                    start_date: e.selection.startDate,
                                                    end_date: e.selection.endDate,
                                                    exportStartDate: moment(e.selection.startDate).format("YYYY-MM-DD"),
                                                    exportEndDate: moment(e.selection.endDate).format("YYYY-MM-DD")
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-end pr-2'>
                                            <button className='btn ipresens-btn-cancel text-capitalize mb-2' style={{ fontSize: "14px", fontWeight: 500 }} onClick={() => this.setState({ reactDateRange: false })}>{i18n.t('batal')}</button>
                                            <button className='btn ipresens-btn-color mb-2 ml-2 text-capitalize' style={{ fontSize: "14px", fontWeight: 500, lineHeight: "24px" }} onClick={
                                                () => this.setState({
                                                    valueDate: this.state.exportStartDate === true ? this.state.exportStartDate + " - " + this.state.exportEndDate : moment(this.state.start_date).format("YYYY-MM-DD") + " - " + moment(this.state.end_date).format("YYYY-MM-DD"),
                                                    reactDateRange: false
                                                })
                                            }>{i18n.t('terapkan')}</button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.valueDate !== "" && this.state.valueDate !== " - " &&
                                <>
                                    <div className='d-flex justify-content-center'>
                                        <div className='col-md-8 px-2'>
                                            <button
                                                className={'shadow btn btn-block ipresens-btn-color px-3 py-2 text-capitalize mt-2'}
                                                style={{fontSize: "14px", borderRadius: "11px"}}
                                                onClick={this.exportPembayaran}
                                            >
                                                {i18n.t('exportdatapembayaran')}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            }
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                            <button
                                className="btn ipresens-btn-cancel text-capitalize"
                                onClick={() => this.setState({
                                    reactDateRange: false
                                }, () => this.handleCancel())}
                            >
                                {i18n.t('batalkan')}
                            </button>
                        </ModalFooter>
                    </ModalRs>

                    <ModalRs size="lg" style={{maxWidth: "1000px"}} isOpen={this.state.modalHistory}
                             toggle={() => this.setState({modalHistory: false, currentPageHistory: 0})}>
                        <ModalHeader style={{backgroundColor: "#FAFDFF"}}
                                     toggle={() => this.setState({modalHistory: false, currentPageHistory: 0})}>
                            <div className='modal-header-reactstrap'>{i18n.t("riwayatperubahan")}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{textAlign: 'center', margin: '10px auto'}}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{marginLeft: '20px'}}>
                                                    <h4>{i18n.t("user")}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{marginRight: '20px'}}>
                                                    <h4>{i18n.t("aktivitas")}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                console.log(item)
                                                return (
                                                    <div key={index} style={{marginBottom: '20px'}}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    paddingTop: '16px',
                                                                    paddingBottom: '16',
                                                                    paddingRight: '12px',
                                                                    paddingLeft: '12px'
                                                                }}>
                                                                    <div style={{marginRight: '16px'}}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span
                                                                                className="col-green font-weight-bold">
                                                                                {moment(item.history_create_date).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{marginTop: 12}}>
                                                                            {item.history_status === "created" ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="d-flex justify-content-center"
                                                                                        style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                        {i18n.t("dibuat")}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="d-flex justify-content-center"
                                                                                        style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                        {i18n.t("diubah")}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{
                                                                paddingTop: '16px',
                                                                paddingBottom: '16px',
                                                                paddingRight: '12px',
                                                                paddingLeft: '12px'
                                                            }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.karyawan_id != item.karyawan_id && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('siswa')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.siswa.karyawan_nama
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.siswa ? item.siswa.karyawan_nama : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.pb_term_id != item.pb_term_id && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('jenispembayaran')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.pembayaran_term.pb_term_name
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.pembayaran_term ? item.pembayaran_term.pb_term_name : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.pembayaran_jumlah != item.pembayaran_jumlah && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('jumlahpembayaran')}: {i18n.t('dari')}{' '} Rp.
                                                                                        <b>
                                                                                            {format({
                                                                                                prefix: '',
                                                                                                integerSeparator: "."
                                                                                            })(item.parent_history ? item.parent_history.pembayaran_jumlah : 'N/A')}
                                                                                        </b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: Rp.
                                                                                        <b>
                                                                                            {format({
                                                                                                prefix: '',
                                                                                                integerSeparator: "."
                                                                                            })
                                                                                            (item.pembayaran_jumlah ? item.pembayaran_jumlah : '-')}
                                                                                        </b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.tagihan_id !== item.tagihan_id && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('tagihan')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.tagihan.tagihan_nama
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.tagihan ? item.tagihan.tagihan_nama : item.tagihan}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            )
                                                                            : null}
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{backgroundColor: "#F5F5F5"}}>
                            <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{
                                            padding: "auto",
                                            margin: "auto",
                                            fontWeight: 500,
                                            lineHeight: "24px"
                                        }}>{i18n.t('menampilkan')} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t('dari')} {this.state.totalDataHistory} {i18n.t('data')}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt=''/>}
                                                nextLabel={<img src={ArrowRight} alt=''/>}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

export default connect(mapStateToProps)(PembayaranSiswa);
