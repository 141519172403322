import React, {Component} from 'react'
import {Button, Drawer, Form} from 'antd';
import Swal from "sweetalert2";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";

class EditUser extends Component {

    handleEdit = () => {
        let form = {
            p_user_id: this.props.data.p_user_id,
            p_user_name: this.props.data.p_user_name,
            p_user_email: this.props.data.p_user_email,
            p_user_wa: this.props.data.p_user_wa,
            p_user_level: this.props.data.p_user_level,
        }
        this.props.request("edit_user", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        p_user_name : ''
                    },() =>this.props.changeState({
                        visable: false,
                        p_user_name : '',
                        data: [],
                        isLoading: true,
                    },() => {
                        this.props.loadData()
                    }))
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }


    render() {
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.data.visable} toggle={() => this.props.changeState({ visable: false, p_user_name: '' })} style={{ maxWidth: "800px" }}>
                <ModalHeader>
                    <div className='text-dark' style={{
                        fontWeight: 'bold', fontSize: "18px"
                    }}>{i18n.t("edituser")}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="px-2">
                        <Form layout="vertical" hideRequiredMark>
                            <div className="form-group">
                                <label>Nama User</label>
                                <input name="p_user_name" className="form-control col-xl-12 col-md-12" placeholder="Nama User..."
                                       type="text" required="" value={this.props.data.p_user_name}
                                       onChange={(event) => {
                                           const target = event.target;
                                           const value  = target.value;
                                           const name   = target.name;
                                           this.props.changeState({
                                               [name]: value
                                           });
                                       }}/>
                            </div>
                        </Form>
                        <Form layout="vertical" hideRequiredMark>
                            <div className="form-group">
                                <label>Nama Email</label>
                                <input name="p_user_email" className="form-control col-xl-12 col-md-12" placeholder="Email User..."
                                       type="text" required="" value={this.props.data.p_user_email}
                                       onChange={(event) => {
                                           const target = event.target;
                                           const value  = target.value;
                                           const name   = target.name;
                                           this.props.changeState({
                                               [name]: value
                                           });
                                       }}/>
                            </div>
                        </Form>
                        <Form layout="vertical" hideRequiredMark>
                            <div className="form-group">
                                <label>No. WA User (Opsional)</label>
                                <input name="p_user_wa" className="form-control col-xl-12 col-md-12" placeholder="No. WA User..."
                                       type="text" required="" value={this.props.data.p_user_wa}
                                       onChange={(event) => {
                                           const target = event.target;
                                           const value  = target.value;
                                           const name   = target.name;
                                           this.props.changeState({
                                               [name]: value
                                           });
                                       }}/>
                            </div>
                        </Form>
                        <Form layout="vertical" hideRequiredMark>
                            <div className="form-group">
                                <label>Level User</label>
                                <select
                                    name="p_user_level"
                                    className="form-control col-xl-12 col-md-12"
                                    value={this.props.data.p_user_level}
                                    onChange={(event) => {
                                        const target = event.target;
                                        const value  = target.value;
                                        const name   = target.name;
                                        this.props.changeState({
                                            [name]: value
                                        });
                                    }}>
                                    <option value="">Pilih Level User</option>
                                    {this.props.data.dataLevel && this.props.data.dataLevel.map((item, index) => {
                                        return(
                                            <option value={item.level_id}>{item.level_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div style={{
                            textAlign: 'right',
                        }}>
                        <button type="button" className="btn ipresens-btn-cancel" onChange={() => {
                            this.props.changeState({visable: false, p_user_name:''});
                        }}
                                style={{ marginRight: 8 }}>
                            Batal
                        </button>
                        <button type="button" className="btn ipresens-btn-color" onClick={this.handleEdit}>
                            Simpan
                        </button>
                    </div>
                </ModalFooter>
            </Modal>

        )
    }
}

export default EditUser
