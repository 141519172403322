import React, {Component, Fragment} from "react";
import AddInventaris from "./inventaris/add";
import i18n from "i18next";
import {Empty, Spin, Tooltip} from "antd";
import EmptyDataImage from "../../../assets/images/data-kosong.png";
import {tr} from "date-fns/locale";
import {pageNumber} from "../../../functions/functionGeneral";
import moment from "moment/moment";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import SaveIcon from "../../../assets/icons/save.svg";
import Swal from "sweetalert2";
import Paging from "../../paging";

export class InventarisKaryawan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            add_visible: false,
            modalReturn: false,
            prosesReturn: false,

            data: [],
            tanggalPinjam: [],
            tanggalKembali: '',
            idKembali: 0,

            pageCount: 0,
            perPage: 0,
            currentPage: 0,
            paginate: 10,
            totalData: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.selesaiPeminjaman = this.selesaiPeminjaman.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    handlePageClick(data) {
        let selected = data.selected;
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadDataInventarisKaryawan();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    selesaiPeminjaman() {
        let form = {
            id_inventaris: this.state.idKembali,
            tanggal: this.state.tanggalKembali,
            tanggal_pinjam: this.state.tanggalPinjam
        }
        this.props.request("inventaris/return-inventaris", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.setState({
                    modalReturn: false,
                    prosesReturn: false , tanggalPinjam: "", tanggalKembali: ''
                }, () => this.loadDataInventarisKaryawan())
            })
    }

    loadDataInventarisKaryawan() {
        this.setState({ loading: true })
        let form = {
            karyawan_id: this.props.match.params.id,
            paginate: 5,
            page: this.state.currentPage + 1,
        }
        this.props.request('inventaris/list_v2', form, 'POST')
        .then(response => {
            if (response.success) {
                this.setState({
                    data: response.data.data,
                    totalData: response.data.total,
                    pageCount: response.data.last_page,
                    perPage: response.data.per_page,
                })
            }
            this.setState({
                loading: false
            })
        })
    }

    componentDidMount() {
        this.loadDataInventarisKaryawan()
    }

    render() {
        let no = 0
        return (
            <Fragment>
                <Modal size='lg' zIndex={999} isOpen={this.state.modalReturn} toggle={() => this.setState({
                    modalReturn: false, tanggalKembali: ''
                })} style={{ maxWidth: "800px" }}>
                    <ModalHeader toggle={() => this.setState({
                        modalReturn: false, tanggalKembali: ''
                    })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("selesaikanpeminjaman")}</div></ModalHeader>
                    <ModalBody className='pengumuman-modal-body'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="form-group">
                                    <label className="mb-2 pengumuman-label-input">{i18n.t("tanggalpengembalian")}</label>
                                    <div>
                                        <input name="tanggalKembali" className="form-control pengumuman-input"
                                               type="date" required="" onChange={this.handleChange}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className='pengumuman-modal-footer'>
                        <button onClick={() => this.setState({
                            modalReturn: false, tanggakKembali: ''
                        })} className='ipresens-cancel-button' >{i18n.t("batal")}</button>
                        <button className='ipresens-save-button' disabled={this.state.prosesReturn} onClick={this.selesaiPeminjaman}><img alt='' src={SaveIcon} /> {this.state.prosesReturn ? "Menyimpan.." : i18n.t("simpan")}</button>
                    </ModalFooter>
                </Modal>
                <AddInventaris
                    add_visible={this.state.add_visible}
                   request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                   changeState={(state, callback) => {
                       this.setState(state, () => {
                           if (typeof callback != 'undefined')
                               callback()
                       })
                   }}
                   id={this.props.match.params.id}
                   loadData={() => this.loadDataInventarisKaryawan()}
                />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <div className="d-flex justify-content-between">
                                <h5>{i18n.t('daftarinventarisyangdipinjam')}</h5>
                                <div>
                                    <button onClick={() => {
                                        this.setState({add_visible: true})
                                    }} className={"btn ipresens-btn-color"}>{i18n.t('pinjaminventaris')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body daftar-absen">
                            {!this.state.loading && this.state.data.length === 0 ?
                                <Empty description={i18n.t('datakosong')}
                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                :
                                <table className="table mb-5">
                                    <thead>
                                    <tr>
                                        <th style={{color: "#143B5E", fontSize: "14px"}} className="text-center">#</th>
                                        <th style={{color: "#143B5E", fontSize: "14px"}}
                                            className="text-center">{i18n.t('namainventaris')}</th>
                                        <th style={{color: "#143B5E", fontSize: "14px"}}
                                            className="text-center">{i18n.t('tanggalpeminjaman')}
                                        </th>
                                        <th style={{
                                            color: "#143B5E",
                                            fontSize: "14px"
                                        }} className="text-center">{i18n.t('tanggalpengembalian')}</th>
                                        <th style={{color: "#143B5E", fontSize: "14px"}} className="text-center">{i18n.t('status')}</th>
                                        <th style={{color: "#143B5E", fontSize: "14px"}} className="text-center"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.loading &&
                                        <tr>
                                            <td colSpan={8}>
                                                <Spin size="large" tip="Loading..." style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                    marginBottom: '10px',
                                                    marginTop: '10px'
                                                }}/>
                                            </td>
                                        </tr>
                                    }
                                    {this.state.data.map((item, index) => {
                                        no += 1
                                        return (
                                            <tr>
                                                <td className="text-center">
                                                    <small>
                                                        <th style={{border: "none"}}
                                                            scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</th>
                                                    </small>
                                                </td>
                                                <td className="text-center">{item.inventaris.inventaris_name}</td>
                                                <td className="text-center">{moment(item.borrow_date).format('ll')}</td>
                                                <td className="text-center">{item.returned_date ? moment(item.returned_date).format('ll') : "__:___:____"}</td>
                                                <td className="text-center">{item.returned_date ? i18n.t('selesai') : i18n.t('sedangdipinjam')}</td>
                                                <td className="text-center">
                                                    {!item.returned_date &&
                                                        <Tooltip trigger={'click'} placement="bottom" color={'#fff'}
                                                                 zIndex={900} title={
                                                            // !item.returned_date &&
                                                            <div className="p-2">
                                                                <a onClick={() => {
                                                                    this.setState({
                                                                        idKembali: item.inventaris_id,
                                                                        modalReturn: true,
                                                                    })
                                                                }} className={'text-dark'}>{i18n.t("selesaikanpeminjaman")}</a>
                                                            </div>
                                                        }>
                                                            <a><i
                                                                className="fa fa-ellipsis-v" style={{
                                                                width: 35,
                                                                fontSize: 18,
                                                                padding: 11,
                                                                color: 'rgb(51 51 51)'
                                                            }}
                                                            ></i></a>
                                                        </Tooltip>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            }
                            <Paging
                                className="float-right"
                                handlePageClick={this.handlePageClick}
                                pageCount={this.state.pageCount}
                                currentPage={this.state.currentPage}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default InventarisKaryawan;