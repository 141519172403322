import React, { useState } from 'react';
import GoogleIcon from "../../../assets/icons/google-icon.svg"
import { useGoogleLogin } from '@react-oauth/google';
import i18n from 'i18next';

export default function GoogleAuthButton(props) {
    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
          props.action(tokenResponse.access_token)
        },
        onError: errorResponse => {},
    });
  return (
    <button
        className="btn ipresens-btn-color loginv2-button-login"
        onClick={() => googleLogin()}
    >
      <img alt="" src={GoogleIcon} style={{ width: "16px", height: "16px", marginRight: 8 }} />
      <span className='fs-10'>{i18n.t('lanjutdgngoogle')}</span>
    </button>
  );
}
