import React, { Component } from 'react'
import { set_auth } from "../../reducers/Auth";
import { Redirect } from 'react-router-dom';
import '../home/landing.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css"
import { connect } from "react-redux";
import FooterRegister from './register_components/footer';
import HeaderRegister from './register_components/header';
import PilihPaket from './register_components/pilihPaket';
import DaftarAkun from './register_components/daftarAkun';
import SuccesPage from './register_components/successPage';
import PilihTipe from "./register_components/pilihTipe";

export class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepHeader: 0,
            hideStep: false,
            auth: this.props.auth,
            paket_id: 0,
            paketMonth: 0,
            paket_selected: {},
            company_name: "",
            email: "",
            password: "",
            confirm_password: "",
            no_whatsapp: "",
            otp_code: "",
            selected_new_paket: false,
            otpwaForm: false,
            message: "",
            time: {},
            seconds: 30,
            disableSendOTP: false,
            timer: 0,
            secondsSuccess: 10,
            tipe_selected: null,
            tipe: '',
            paket_type: [],
        }
        this.timer = 0
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    countDown() {
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
            disableSendOTP: true,
        });

        if (seconds === 0) {
            clearInterval(this.timer);
            this.setState({
                seconds: 30,
                disableSendOTP: false,
            })
        }
    }

    startTimer() {
        if (this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    componentDidMount() {
        this.setState({ auth: this.props.auth })
        // this.loadPaketV2()
    }

    componentWillReceiveProps(newProps) {
        if (newProps.auth !== this.state.auth) {
            this.setState({ auth: newProps.auth })
        }
    }

    render() {
        let contentPage;
        if (this.state.stepHeader === 0) {
            contentPage = <PilihTipe
                setState={(val, callback) => this.setState(val, callback)}
                request={this.props.request}
                history={this.props.history}
                tipe={this.state.tipe}
                tipe_selected={this.state.tipe_selected}
                paket_type={this.state.paket_type}
            />
        } else if (this.state.stepHeader === 1) {
            contentPage = <PilihPaket
                setState={(val, callback) => this.setState(val, callback)}
                request={this.props.request}
                history={this.props.history}
                paket_selected={this.state.paket_selected}
                selected_new_paket={this.state.selected_new_paket}
                tipe_selected={this.state.tipe_selected}
            />
        } else if (this.state.stepHeader === 2) {
            contentPage = <DaftarAkun
                paket_id={this.state.paket_id}
                paketMonth={this.state.paketMonth}
                setState={(val, callback) => this.setState(val, callback)}
                request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                history={this.props.history}
                company_name={this.state.company_name}
                email={this.state.email}
                password={this.state.password}
                confirm_password={this.state.confirm_password}
                no_whatsapp={this.state.no_whatsapp}
                otp_code={this.state.otp_code}
                otpwaForm={this.state.otpwaForm}
                time={this.state.time}
                seconds={this.state.seconds}
                disableSendOTP={this.state.disableSendOTP}
                startTimer={this.startTimer}
                tipe_selected={this.state.tipe_selected}
            />
            console.info('paket selected ', this.state.paket_id)
        } else if (this.state.stepHeader === 3) {
            contentPage = <SuccesPage secondsSuccess={this.state.secondsSuccess} setState={(state, val) => this.setState(state, val)} history={this.props.history} message={this.state.message} />
        } else {
            contentPage = null
        }

        if (this.state.auth) {
            var { location } = this.props
            let redirect

            if (typeof location.state !== 'undefined' && location.state.from.pathname !== '/register')
                redirect = location.state.from.pathname
            else
                redirect = '/dashboard'

            return <Redirect to={redirect} />
        }
        return (
            <>
                <HeaderRegister paket_selected={this.state.paket_selected} paket_id={this.state.paket_id} step={this.state.stepHeader} hide={this.state.hideStep} setState={(val, callback) => this.setState(val, callback)} />
                {contentPage}
                <FooterRegister />
            </>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
