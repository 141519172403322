import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import './index.scss';
import Main from "./components/main";
import {Provider} from "react-redux";
import configureStore from "./config/configureStore";
import {HeadProvider} from "react-head";
import {HTML5Backend} from "react-dnd-html5-backend";
import { GoogleOAuthProvider } from '@react-oauth/google';
import {DndProvider} from "react-dnd";
import { GOOGLECLIENTID } from './helper/host';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
const store = configureStore();
class Root extends Component {
    render() {
        return (
            <GoogleOAuthProvider clientId={GOOGLECLIENTID}>
                <HeadProvider>
                    <Provider store={store}>
                        <DndProvider backend={HTML5Backend}>
                            <Main />
                        </DndProvider>
                    </Provider>
                </HeadProvider>
            </GoogleOAuthProvider>
        )
    }
}

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Root />
    </I18nextProvider>,
    document.getElementById('root')
);


