import React, {Component, Fragment} from 'react'
import {APIKEY} from "../../../../helper/host";
import {Spin} from "antd";
import {Button, Card, CardBody, Col, Label, Row} from 'reactstrap'

import {GoogleApiWrapper} from 'google-maps-react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Kompensasi from './kompensasi'
import Potongan from './potongan'
import format from "format-number";
import {toast} from "react-toastify";
import Swal from "sweetalert2";


export class Payroll_edit_modal extends Component {
    constructor(props) {
        super(props);
        this.state      = {
            loading            : false,
            data_payroll       : [],
            karyawan           : [],
            divisi             : [],
            kompensasi         : [],
            potongan           : [],
            count              : null,
            saveAll              : props.saveAll,
            save_potongan       : false,
            updateGaji          : false,
            modalSlip           : false,
            modalEdit           : false,
            indexReactTabs      : 0,
            selectedIndex      : 0,
            id          : ''
        }
        this.loadDataPayroll             = this.loadDataPayroll.bind(this);
        this.loadUpdateGaji         = this.loadUpdateGaji.bind(this);
    }

    refreshPayroll(){
        this.setState({
            loading: true
        })
        this.loadUpdateGaji()
        this.loadUpdatePotongan()
        this.loadUpdateKompensasi()
    }

    loadUpdateGaji() {
        let form = {
            payroll_id  : this.props.payroll_id
        }
        this.props.request("payroll/updateGaji", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_payroll   : response.data,
                        karyawan       : response.data.karyawan,
                        divisi         : response.data.karyawan.divisi,
                        count          : response.count,
                        loading: false,
                        updateGaji      : true,
                        indexReactTabs  : 0
                    })
                }
            });
    }

    loadUpdatePotongan() {
        let form = {
            payroll_id: this.props.payroll_id,
        }
        this.props.request("payroll/update_potongan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        term: response.term,
                        potongan: response.potongan,
                        loading: false
                    })
                }
            });
    }

    loadUpdateKompensasi() {
        let form = {
            payroll_id: this.props.payroll_id,
        }
        this.props.request('payroll/updateKompensasi', form, 'POST')
            .then(response => {
                if (response.success) {
                    toast['success'](response.message)
                    this.setState({
                        kompensasi: response.data,
                        term: response.term,
                        loading: false
                    })
                }
            });
    }

    loadDataPayroll() {
        this.setState({
            loading: true
        })
        let form = {
            id        : this.props.payroll_id
        }
        this.props.request("payroll/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_payroll   : response.data,
                        karyawan       : response.data.karyawan,
                        divisi         : response.data.karyawan.divisi,
                        count          : response.count
                    })
                }
                this.setState({loading: false})
            });
    }

    finishAll = () =>{
        this.setState({
            saveAll : true
        })
    }

    finishPotongan = () =>{
        this.setState({
            save_potongan : true
        })
    }

    handleSubmit(event) {
        this.setState({
            isLoading   : true,
        })

        let formData = {
            payroll_id        : this.props.payroll_id
        }
        this.props.request('payroll/ubah_status', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    this.props.updateState({
                        id: this.props.payroll_id,
                        modalEdit : false,
                    })
                    this.props.loadSlip()
                } else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                }
                this.setState({loading: false})
            })
    }

    componentDidMount() {
        this.loadDataPayroll()
    }

    render() {
        return (
            <Fragment>
                <Col lg="12">
                    <Card className="main-card mb-3">
                        <CardBody>
                            {this.state.loading ?
                                <Spin size="large" tip="Sedang memuat Data Payroll..." style={{
                                    width: '100%',
                                    margin: 'auto',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}/>
                                :
                                <div>
                                    <div>
                                        <Row>
                                            <Col sm="12" md="12" lg="12">
                                                <div style={{
                                                    float: "right",
                                                }}>
                                                    <button className="btn btn-primary mr-2"
                                                            disable={this.state.loading}
                                                            onClick={()=>{this.refreshPayroll()}}
                                                    >
                                                        {this.state.loading ?
                                                            <span className="fa fa-refresh fa-spin"/>
                                                            :
                                                            <span className="fa fa-refresh"/>
                                                        }
                                                    </button>
                                                    <Button
                                                        disabled={this.state.saveAll === false || this.state.save_potongan === false}
                                                        onClick={this.handleSubmit.bind(this)}
                                                        color="warning"
                                                    >
                                                        Finish
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12" md="8" lg="12">
                                                <Tabs selectedIndex={this.state.selectedIndex} onSelect={(index) => this.setState({selectedIndex: index})} defaultIndex={this.state.indexReactTabs}>
                                                    <TabList className="nav nav-tabs tab-coupon">
                                                        <Tab className="nav-link">Gaji</Tab>
                                                        <Tab className="nav-link">Kompensasi</Tab>
                                                        <Tab className="nav-link">Potongan</Tab>
                                                    </TabList>

                                                    <TabPanel>
                                                        <div className="card-header text-center mb-4" style={{border: "none"}}>
                                                            <h4>Detail Gaji</h4>
                                                        </div>
                                                        <hr/>
                                                        <div>
                                                            <Row className="mb-2">
                                                                <Col className="text-center">
                                                                    <Label>Type Gaji</Label>
                                                                </Col>
                                                                <Col className="text-center">
                                                                    <Label>Nominal Gaji</Label>
                                                                </Col>
                                                                <Col className="text-center">
                                                                    <Label>Jumlah Gaji</Label>
                                                                </Col>
                                                                <Col className="text-center">
                                                                    <Label>Total Gaji</Label>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="text-center">
                                                                    {this.state.data_payroll.payroll_type}
                                                                </Col>
                                                                <Col className="text-center">
                                                                    Rp {format({prefix: '', integerSeparator: "."})(this.state.data_payroll.payroll_amount)}
                                                                </Col>
                                                                <Col className="text-center">
                                                                    {this.state.data_payroll.payroll_waktu_kerja}
                                                                </Col>
                                                                <Col className="text-center">
                                                                    Rp {format({prefix: '', integerSeparator: "."})(this.state.data_payroll.payroll_total)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <Kompensasi
                                                            kompensasi={this.state.kompensasi}
                                                            payroll_id={this.props.payroll_id}
                                                            request={this.props.request}
                                                            match={this.props.match}
                                                            handleFinish={this.finishAll}
                                                            history={this.props.history}
                                                            loadDetail={this.loadDataPayroll}
                                                            updateState={(event) => {
                                                                this.setState(event)
                                                            }}
                                                        />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <Potongan
                                                            potongan={this.state.potongan}
                                                            karyawan_id={this.props.karyawan_id}
                                                            payroll_id={this.props.payroll_id}
                                                            request={this.props.request}
                                                            match={this.props.match}
                                                            handleFinish={this.finishPotongan}
                                                            loadDataTermPotongan={this.props.loadDataTermPotongan}
                                                            history={this.props.history}
                                                            loadDetail={this.loadDataPayroll}
                                                            setState={(event) => {
                                                                this.setState(event)
                                                            }}
                                                            updateState={(event) => {
                                                                this.props.updateState(event)
                                                            }}
                                                        />
                                                    </TabPanel>

                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Fragment>
        )
    }
}

export default  GoogleApiWrapper({
    apiKey: APIKEY
}) (Payroll_edit_modal)
