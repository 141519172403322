import React, {Component} from "react"
import {Fragment} from "react";
import RefreshIcon from "../../../assets/icons/refresh.svg";
import {Empty, Spin} from "antd";
import EmptyDataImage from "../../../assets/images/data-kosong.png";
import i18n from "i18next";
import ArrowUpIcon from "../../../assets/icons/arrow-up.svg";
import ArrowDownIcon from "../../../assets/icons/arrow-down.svg";
import {pageNumber} from "../../../functions/functionGeneral";
import moment from "moment/moment";


export class LemburList extends Component {
    constructor(props) {
        super(props);
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        this.state = {
            loading: false,
            data: [],
            year: tahun,
            month: namabulan[bulan],
            pageCount: 0,
            perPage: 0,
            currentPage: 0,
        }
        this.resetFilter = this.resetFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
    }

    resetFilter(event) {
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        this.setState({
            data: [],
            year: tahun,
            month: namabulan[bulan],
            loading: true
        }, () => this.loadData())
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    sortabledate = (val) => {

        const copyArray = this.state.data

        copyArray.sort((a, b) => {
            return val === 0 ? new Date(a.lembur_tanggal) - new Date(b.lembur_tanggal) : new Date(b.lembur_tanggal) - new Date(a.lembur_tanggal);
        });

        this.setState({
            data: copyArray
        })
    }

    compareBy(key) {
        return function (a, b) {
            if (a[key] < (b[key])) return -1;
            if (a[key] > (b[key])) return 1;
            return 0;
        };
    }

    sortablestatus = (val) => {

        const copyArray = this.state.data

        if (val === 0) {
            copyArray.sort(this.compareBy("lembur_status"));
        } else {
            copyArray.reverse();
        }

        this.setState({
            data: copyArray
        })
    }

    loadData() {
        this.setState({ loading: true })
        let form = {
            karyawan_slug: this.props.match.params.id,
            paginate: 5,
        }
        this.props.request("lembur/lembur_list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        visible: true
                    })
                }
                this.setState({ loading: false })
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        return (
            <Fragment>
                <div>
                    <div className="row">
                        <div className='col-md-12'>
                            <div style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }}>Daftar Lembur
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2 mx-0"
                         style={{display: "flex", alignItems: "center", justifyItems: "center"}}>
                        <div className='col-md-3 pl-0'>
                            <label style={{fontSize: '10px'}}>Filter Bulan:</label>
                            <select name="month" className="form-control" onChange={this.handleFilter} value={this.state.month}>
                                <option value="">Pilih Bulan</option>
                                <option value="1">Januari</option>
                                <option value="2">Februari</option>
                                <option value="3">Maret</option>
                                <option value="4">April</option>
                                <option value="5">Mei</option>
                                <option value="6">Juni</option>
                                <option value="7">Juli</option>
                                <option value="8">Agustus</option>
                                <option value="9">September</option>
                                <option value="10">Oktober</option>
                                <option value="11">November</option>
                                <option value="12">Desember</option>
                            </select>
                        </div>
                        <div className='col-md-2'>
                            <label style={{fontSize: '10px'}}>Filter Tahun:</label>
                            <select name="year" className="form-control" onChange={this.handleFilter} value={this.state.year}>
                                <option value="2020">2020</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                            </select>
                        </div>
                        <div className='col-md-2'>
                            <label htmlFor="" style={{fontSize: '10px'}}>Reset Filter</label>
                            <button className="btn secondary px-2" style={{height: "38px"}}><img src={RefreshIcon} onClick={this.resetFilter}/></button>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="table-responsive">
                            <div className="mt-3">
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <Empty description="Data Kosong!"
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th className="lembur-th-style">#</th>
                                                <th className="lembur-th-style">
                                                    <div className='d-flex'>
                                                        <div className="d-flex">
                                                            <div className='izin-th-style'>Tanggal Lembur</div>
                                                            <div className='ml-1 d-flex flex-column pt-1'>
                                                                <img alt='' onClick={() => {
                                                                    this.sortabledate(1)
                                                                }} style={{width: "8px", height: "8px"}}
                                                                     className='izin-btn' src={ArrowUpIcon}/>
                                                                <img alt='' onClick={() => {
                                                                    this.sortabledate(0)
                                                                }} style={{width: "8px", height: "8px"}}
                                                                     className='izin-btn' src={ArrowDownIcon}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </th>
                                                <th className="lembur-th-style">Jam Masuk-Keluar</th>
                                                <th className="lembur-th-style">Durasi Lembur</th>
                                                <th className="lembur-th-style">Deskripsi Lembur</th>
                                                <th className="lembur-th-style">
                                                    <div className='d-flex'>
                                                        <div className='izin-th-style'>Status </div>
                                                        <div className='ml-1 d-flex flex-column pt-1'>
                                                            <img alt='' style={{width: "8px", height: "8px"}}
                                                                 className='izin-btn' src={ArrowUpIcon} onClick={() => {
                                                                this.sortablestatus(1)
                                                            }}/>
                                                            <img alt='' style={{width: "8px", height: "8px"}}
                                                                 className='izin-btn' src={ArrowDownIcon}
                                                                 onClick={() => {
                                                                     this.sortablestatus(0)
                                                                 }}/>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.loading ?
                                            <tr>
                                                <td colSpan={10}>
                                                    <Spin size="large" tip="Loading..." style={{
                                                        width: '100%',
                                                        margin: 'auto',
                                                        marginBottom: '10px',
                                                        marginTop: '10px'
                                                    }}/>
                                                </td>
                                            </tr>
                                            :
                                            this.state.data.length === 0 ?
                                                <tr>
                                                    <td colSpan={9}>
                                                        <Empty description="Data Kosong"
                                                               image={EmptyDataImage}
                                                               className="ipresens-image-empty mb-5"/>
                                                    </td>
                                                </tr>
                                                :
                                                this.state.data.map((item, index) => {
                                                    no += 1
                                                    return (
                                                        <tr>
                                                            <td className='lembur-td-style'>{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                            <td className='lembur-td-style'>
                                                                {moment(item.lembur_tanggal).format('ll')}
                                                            </td>
                                                            <td className='lembur-td-style'>
                                                                {item.absensi ? item.absensi.jam_masuk_absen !== null ? item.absensi.jam_masuk_absen : "__:__:__" : "__:__:__"} -
                                                                {item.absensi ? item.absensi.jam_keluar_absen !== null ? item.absensi.jam_keluar_absen : "__:__:__": "__:__:__"}
                                                            </td>
                                                            <td className='lembur-td-style'>
                                                                {parseInt(item.lembur_jumlah / 60)} Jam {item.lembur_jumlah - (parseInt(item.lembur_jumlah / 60) * 60)} Menit
                                                            </td>
                                                            <td className='lembur-td-style'>
                                                                {item.lembur_desc}
                                                            </td>
                                                            <td className='lembur-td-style'
                                                                style={{color: item.lembur_status === "pending" || item.lembur_status === "draft" ? "#FF9C00" : item.lembur_status === "reject" ? "#E03616" : item.lembur_status === "approved" ? "#1E7E0E" : ""}}>
                                                                {item.lembur_status === "pending" || item.lembur_status === "draft" ? "Menunggu" : item.lembur_status === "reject" ? "Ditolak" : item.lembur_status === "approved" ? "Disetujui" : ""}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                        }

                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
        )
    }
}

export default LemburList