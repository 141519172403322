import React, {Component, Fragment} from "react";
import {Helmet} from "react-helmet";
import i18n from "i18next";
import refreshIcon from "../../assets/icons/refresh.svg";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import {Empty, Spin, Tooltip} from "antd";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {pageNumber} from "../../functions/functionGeneral";
import Swal from "sweetalert2";
import {Col, Modal as ModalRs, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import Tour from "reactour";
import "./style.css";
import ReactPaginate from 'react-paginate';
import IconPlusWhite from "../../assets/icons/IconPlusWhite.svg";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import {DEFAULT_IMG} from "../../helper/host";
import moment from "moment/moment";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";

export class KategoriNilai extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter_nama: '',
            page: 0,
            paging: 5,
            loading: false,
            currentPage: 0,
            data: [],
            totalData: 0,
            pageCount: 0,
            perPage: 0,
            id_delete: 0,
            modal_add: false,
            nl_term_name: "",
            data_detail: [],
            dataHistory: [],
            pagination: 2,
            currentPageHistory: 0,
            importFile: null,
            modalimport: false,
            modalHistory: false,
            loadingHistory: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.handlePageHistory = this.handlePageHistory.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter() {
        this.setState({
            filter_nama: "",
            data: [],
            loading: true,
        }, () => this.loadData())
    }

    handlePageClick(data) {
        let selected = data.selected;
        this.setState({ currentPage: selected, loading: true, data: [] }, () => {
            this.loadData()
        });
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected;
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    loadData() {
        this.setState({loading: true})
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request('nilai/list-nilai-term', form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
                this.setState({
                    loading: false
                })
            })
    }

    wantdelete(nl_term_id) {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('andayakininginmenghapuskategorinilai'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal')
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({ data: [], loading: true })
                    let form = {
                        nl_term_id: nl_term_id ? nl_term_id : this.state.id_delete,
                    }
                    this.props.request("nilai/delete-nilai-term", form, "POST")
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('databerhasildihapus'),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        })
                }
            })
    }

    handleSubmit = () => {
        this.setState({ loading: true })
        let form = {
            nl_term_name : this.state.nl_term_name,
        }
        this.props.request('nilai/add-nilai-term', form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        nl_term_name: '',
                        data: [],
                        loading: false,
                        modal_add: false,
                    }, () => this.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasilditambahkan'),
                        icon: "success"
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ loading: false })
            })
    }

    loadDetail () {
        let form = {
            nl_term_id: this.state.nl_term_id,
        }
        this.props.request('nilai/detail-nilai-term', form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_detail: response.data,
                        nl_term_name: response.data.nl_term_name,
                    })
                }
                this.setState({
                    loading: false,
                })
            })
    }

    handleEdit = () => {
        this.setState({ loading: true })
        let form = {
            nl_term_name : this.state.nl_term_name,
            nl_term_id: this.state.nl_term_id,
        }
        this.props.request('nilai/edit-nilai-term', form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        nl_term_name: '',
                        data: [],
                        loading: false,
                        modal_edit: false,
                    }, () => this.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: "success"
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('gagalmengubahdata'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ loading: false })
            })
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.nl_term_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("nilai/history-nilai-term", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            nl_term_id: ''
                        });
                    }
                });
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        let borderBottom = "1px solid #EFEFEF";
        const steps = [
            {
                selector: '.kategori-nilai-siswa',
                content: i18n.t('caramembuatkategorinilai'),
            },
            {
                selector: '.daftar-kategori-nilai',
                content: i18n.t('daftarkategorinilaidisini'),
            },
        ];
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Nilai") >= 0){
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{i18n.t('daftarkategori')} - Presensi</title>
                        <meta name="description" content="Detail Daftar Kategori Nilai"/>
                    </Helmet>

                    <Breadcrumb title={i18n.t("kategorinilailist")}  parent={i18n.t("nilai")}  setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid">
                        <div className='row'>
                        </div>
                        <div className="col-md-12">
                            <div className='card' style={{border: "1px solid #DDDDDD"}}>
                                <div className="card-header">
                                    <div className="form-group mb-0">
                                        <div className="row filter-karyawan">
                                            <div className="col-md-3 pr-0 input-column-search">
                                                <div className="button-search-istilah">
                                                    <input name="filter_nama"
                                                           className={this.state.filter_nama === "" ? "form-control bg-image-input filter-name input-select-style" : "form-control filter-name input-select-style"}
                                                           placeholder={i18n.t('carinamakategori')}
                                                           type="text"
                                                           style={{
                                                               backgroundColor: "#F9F9F9",
                                                               fontSize: "12px",
                                                               fontWeight: 400,
                                                               paddingTop: "9px",
                                                               paddingBottom: "9px",
                                                               paddingLeft: "35px",
                                                               borderRadius: "8px",
                                                               marginBottom: "0px"
                                                           }}
                                                           required=""
                                                           value={this.state.filter_nama}
                                                           onChange={this.handleChange}
                                                           onKeyUp={(event) => {
                                                               event.preventDefault()
                                                               if (event.which == 13) {

                                                                   this.setState({
                                                                       page: 0,
                                                                       paging: this.state.paging,
                                                                       loading: true
                                                                   }, () => {
                                                                       this.loadData()
                                                                   });
                                                               }
                                                           }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 column-select column-select-istilah">
                                                <div className="button-select-istilah">
                                                    {/* Select Type */}
                                                    <button className="input-select-style button-reset"
                                                            onClick={this.resetFilter}><img src={refreshIcon} alt=""/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col"></div>
                                            <div className="col-auto col-button column-add-term">
                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                    <a className="btn ipresens-btn-color tambah-term button-add-istilah mt-0 kategori-nilai-siswa"
                                                       style={{lineHeight: "24px"}}
                                                       onClick={() => {
                                                           this.setState({
                                                               modal_add: true,
                                                               nl_term_name: ""
                                                           })
                                                       }}>
                                                        <img src={IconPlusWhite} alt="" className="mr-2 mb-1"/> {i18n.t('tambah')}
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.loading ?
                                    <div className="card-body">
                                        <Spin size="large" tip={i18n.t('sedangmemuatdata')} style={{
                                            width: '100%',
                                            margin: 'auto',
                                            marginBottom: '10px',
                                            marginTop: '10px'
                                        }}/>
                                    </div>
                                    :
                                    <div className="card-body">
                                        {!this.state.loading && this.state.data.length === 0 ?
                                            <Empty description={i18n.t("datakosong")}
                                                   image={EmptyDataImage} className="ipresens-image-empty "/>
                                            :
                                            <div className="row">
                                                <div className="col-md-12 daftar-kategori-nilai">
                                                    <div className="table-responsive">
                                                        <table className="table table-striped table-borderless mb-5">
                                                            <thead>
                                                            <tr>
                                                                <th width="30" className="nilai-th-style">#</th>
                                                                <th width="100" className="nilai-th-style">{i18n.t("nama")}</th>
                                                                <th width="100" className="nilai-th-style text-center">{i18n.t("aksi")}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="">
                                                            {this.state.loading &&
                                                                <tr>
                                                                    <td colSpan={9}>
                                                                        <Spin size="large" tip="Loading..." style={{
                                                                            width: '100%',
                                                                            margin: 'auto',
                                                                            marginBottom: '10px',
                                                                            marginTop: '10px'
                                                                        }}/>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {this.state.data.map((item, index) => {
                                                                no += 1
                                                                return (
                                                                    <tr style={{ borderBottom: no === this.state.data.length ? "" : borderBottom }}>
                                                                        <td className="nilai-td-style" scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                        <td className="nilai-td-style" style={{minWidth: "350px"}}>
                                                                            {item.nl_term_name}
                                                                        </td>
                                                                        <td align={"center"}>
                                                                            <Tooltip trigger={'click'} zIndex={1}
                                                                                     placement="bottom"
                                                                                     color={'#fff'} title={
                                                                                <div className={'text-dark'}>
                                                                                    <a onClick={() => {
                                                                                        this.setState({
                                                                                            modalHistory: true,
                                                                                            nl_term_id: item.nl_term_id
                                                                                        }, () => {
                                                                                            this.loadHistory(item.nl_term_id);
                                                                                        })
                                                                                    }}>{i18n.t("riwayat")}</a><br/>
                                                                                    <a className={'text-dark'}
                                                                                       onClick={() => {
                                                                                           this.setState({
                                                                                               nl_term_id: item.nl_term_id,
                                                                                               nl_term_name: item.nl_term_name,
                                                                                               modal_edit: true,
                                                                                           })
                                                                                       }}>{i18n.t("edit")}</a><br/>
                                                                                    <a className={'text-danger'}
                                                                                       onClick={() => {
                                                                                           this.setState({
                                                                                               id_delete: item.nl_term_id
                                                                                           })
                                                                                           this.wantdelete(item.nl_term_id)
                                                                                       }}>{i18n.t("hapus")}</a>
                                                                                </div>
                                                                            }>
                                                                                <a><i
                                                                                    className="fa fa-ellipsis-h"
                                                                                    style={{
                                                                                        width: 35,
                                                                                        fontSize: 18,
                                                                                        padding: 11,
                                                                                        color: 'rgb(51 51 51)'
                                                                                    }}
                                                                                ></i></a>
                                                                            </Tooltip>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                                <div className='card-footer p-0 border-0'>
                                    <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                        {this.state.data.length !== 0 &&
                                            <>
                                                <div className='col font-14' style={{
                                                    padding: "auto",
                                                    margin: "auto",
                                                    fontWeight: 500,
                                                    lineHeight: "24px"
                                                }}>{i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} data
                                                </div>
                                                <div className='col'>
                                                    <ReactPaginate
                                                        previousLabel={<img src={ArrowLeftIcon} alt=''/>}
                                                        nextLabel={<img src={ArrowRightIcon} alt=''/>}
                                                        pageCount={this.state.pageCount}
                                                        forcePage={this.state.currentPage}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={this.handlePageClick}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link custom-page-link'}
                                                        containerClassName={'pagination my-2'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link custom-page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link lembur-prev-next-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link lembur-prev-next-link'}
                                                        activeClassName={'active lembur-active'}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal size="lg" style={{maxWidth: "600px"}} isOpen={this.state.modal_add}
                           toggle={() => this.setState({modal_add: false})}>
                        <ModalHeader>
                            <div className="">{i18n.t('tambahkategorinilai')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="">
                                <label htmlFor="">{i18n.t('namakategorinilai')}</label>
                                <input type="text" className="form-control" placeholder={i18n.t('nama')} name="nl_term_name"
                                       onChange={this.handleChange} value={this.state.nl_term_name}/>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                                    this.setState({modal_add: false, nl_term_name: ""})
                                }}
                                        style={{marginRight: 8}}>
                                    {i18n.t('batal')}
                                </button>
                                <button type="button" className="btn ipresens-btn-color" onClick={this.handleSubmit}
                                        disabled={this.state.loading}>
                                    {this.state.loading ?
                                        <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t('loading')}</>
                                        :
                                        i18n.t('simpan')
                                    }
                                </button>
                            </div>
                        </ModalFooter>
                    </Modal>

                    <Modal size="lg" style={{maxWidth: "600px"}} isOpen={this.state.modal_edit}
                           toggle={() => this.setState({modal_edit: false})}>
                        <ModalHeader>
                            <div className="">{i18n.t('editkategorinilai')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="">
                                <label htmlFor="">{i18n.t('namakategorinilai')}</label>
                                <input type="text" className="form-control" placeholder={i18n.t('nama')} name="nl_term_name"
                                       onChange={this.handleChange} value={this.state.nl_term_name}/>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                                    this.setState({ modal_edit:false })
                                }}
                                        style={{marginRight: 8}}>
                                    {i18n.t('batal')}
                                </button>
                                <button type="button" className="btn ipresens-btn-color" onClick={this.handleEdit}
                                        disabled={this.state.loading}>
                                    {this.state.loading ?
                                        <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t('loading')}</>
                                        :
                                        i18n.t('simpan')
                                    }
                                </button>
                            </div>
                        </ModalFooter>
                    </Modal>

                    <ModalRs size="lg" style={{maxWidth: "1000px"}} isOpen={this.state.modalHistory}
                             toggle={() => this.setState({modalHistory: false, currentPageHistory: 0})}>
                        <ModalHeader style={{backgroundColor: "#FAFDFF"}}
                                     toggle={() => this.setState({modalHistory: false, currentPageHistory: 0})}>
                            <div className='modal-header-reactstrap'>{i18n.t("riwayatperubahan")}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{textAlign: 'center', margin: '10px auto'}}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{marginLeft: '20px'}}>
                                                    <h4>{i18n.t("user")}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{marginRight: '20px'}}>
                                                    <h4>{i18n.t("aktivitas")}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return (
                                                    <div key={index} style={{marginBottom: '20px'}}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    paddingTop: '16px',
                                                                    paddingBottom: '16',
                                                                    paddingRight: '12px',
                                                                    paddingLeft: '12px'
                                                                }}>
                                                                    <div style={{marginRight: '16px'}}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                        <span
                                                                            className="col-green font-weight-bold">
                                                                            {moment(item.created_at).format('lll')}
                                                                        </span>
                                                                        </p>
                                                                        <div style={{marginTop: 12}}>
                                                                            {item.history_status === "created" ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="d-flex justify-content-center"
                                                                                        style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                        {i18n.t("dibuat")}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="d-flex justify-content-center"
                                                                                        style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                        {i18n.t("diubah")}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{
                                                                paddingTop: '16px',
                                                                paddingBottom: '16px',
                                                                paddingRight: '12px',
                                                                paddingLeft: '12px'
                                                            }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.nl_term_name != item.nl_term_name && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('namakategorinilai')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.nl_term_name
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.nl_term_name ? item.nl_term_name : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter
                            style={{
                                backgroundColor: "#F5F5F5",
                                borderBottomRightRadius: "8px",
                                borderBottomLeftRadius: "8px"
                            }}
                        >
                            <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{
                                            padding: "auto",
                                            margin: "auto",
                                            fontWeight: 500,
                                            lineHeight: "24px"
                                        }}>{i18n.t('menampilkan')} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t('dari')} {this.state.totalDataHistory} {i18n.t('data')}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt=''/>}
                                                nextLabel={<img src={ArrowRight} alt=''/>}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(KategoriNilai);
