import React, { Component, Fragment } from 'react'
import { Empty, Spin } from "antd";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { pageNumber } from "../../functions/functionGeneral";
import Paging from "../../components/paging";
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Col } from "reactstrap";
import { BACKEND_API_HOST } from "../../helper/host";
import ReactPaginate from "react-paginate";
import ArrowRight from '../../assets/icons/arrow-right.svg'
import ArrowLeft from '../../assets/icons/arrow-left.svg'
import SearchIcon from "../../assets/icons/search.svg";
import CalendarIcon from "../../assets/icons/calendar-dark-blue.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import ExportIcon from "../../assets/icons/download-white.svg";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import i18n from 'i18next';

export class laporan_harian extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            data: [],
            filter_nama: "",
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            selectedDate: new Date(),
            dateValue: "Filter Tanggal",
            short: '1',

        }
        this.handleNama = this.handleNama.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleChange = (a) => {
        this.setState({ selectedDate: a, dateValue: moment(a).format("L") }, () => {
            this.loadData();
        });
        this.setState({ loading: true })
    };

    handleNama(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    exportLaporanKunjungan() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'outlet/export?token=' + AuthKey + '&kunjunganStartDate=' + moment(this.state.selectedDate).format('YYYY-MM-DD') + '&kunjunganEndDate=' + moment(this.state.selectedDate).format('YYYY-MM-DD'), "_blank")
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            date: moment(this.state.selectedDate).format('YYYY-MM-DD'),
            data: [],
            short: '1',
            loading: true,
            dateValue: ""
        }, () => this.loadData())
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    loadData() {
        this.setState({ loading: true })
        let form = {
            paginate: 10,
            page: this.state.currentPage + 1,
            type: 'hari',
            filter_nama: this.state.filter_nama,
            short: this.state.short,
            date: moment(this.state.selectedDate).format('YYYY-MM-DD'),
        }
        this.props.request("outlet/laporan_kunjungan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        totalData: response.data.total
                    })
                }
                this.setState({ loading: false })
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        console.log(this.state.data.length);
        let no = 0;
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className='row mb-4'>
                        <div className='col-md-3 lk-col-1'>
                            <div className="input-group btn-srch-knjgnhari lk-filter-nama">
                                <span className="input-group-text lk-filter-nama-span" id="basic-addon1">
                                    <img alt='' src={SearchIcon} />
                                </span>
                                <input type="text" value={this.state.filter_nama} className="form-control lk-filter-nama-input" placeholder={i18n.t('carinamakaryawan')} aria-label="Username" aria-describedby="basic-addon1" onChange={this.handleNama} onKeyUp={(event) => {
                                    event.preventDefault()
                                    if (event.which === 13) {

                                        this.setState({
                                            page: 0,
                                            paging: this.state.paging,
                                            loading: true
                                        }, () => {
                                            this.loadData()
                                        });
                                    }
                                }} name='filter_nama' />
                            </div>
                        </div>
                        <div className='col-md-2 lk-col-2'>
                            <DatePicker
                                selected={this.state.selectedDate}
                                onChange={this.handleChange}
                                customInput={
                                    <div className="input-group btn-tgl-knjgnhari lk-filter-tgl">
                                        <input type="text" className="form-control lk-filter-tgl-input" placeholder={i18n.t('filtertanggal')} aria-label="Username" aria-describedby="basic-addon1" value={this.state.dateValue} />
                                        <span className="input-group-text lk-filter-tgl-span" id="basic-addon1">
                                            <img alt='' src={CalendarIcon} />
                                        </span>
                                    </div>
                                }
                            />
                        </div>
                        <div className='col-md-4 lk-col-3'>
                            <div className='d-flex flex-nowrap lk-filter-sort-container' style={{ height: "100%" }}>
                                <div style={{ marginRight: "8px" }} className='flex-fill'>
                                    <select className='form-control lk-filter-sort' name="short" onChange={this.handleFilter} value={this.state.short}>
                                        <option className='lk-filter-sort-option' value="1">A - Z</option>
                                        <option className='lk-filter-sort-option' value="2">Z - A</option>
                                        <option className='lk-filter-sort-option' value="3">{i18n.t('kunjunganterbanyak')}</option>
                                        <option className='lk-filter-sort-option' value="4">{i18n.t('kunjungantersedikit')}</option>
                                    </select>
                                </div>
                                <div>
                                    <button className='ipresens-btn-reset-filter' onClick={this.resetFilter} >
                                        <img alt='' src={RefreshIcon} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 lk-col-4' style={{ textAlign: "right" }}>
                            <button className='ipresens-export-btn btn-exp-knjgnhari' disabled={this.state.data.length === 0} onClick={() => { this.exportLaporanKunjungan() }}><img alt='' src={ExportIcon} /> {i18n.t('exportexcell')}</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 px-0">
                            {!this.state.loading && this.state.data.length === 0 ?
                                <Empty description="Data Kosong"
                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                :
                                <div className='table-responsive'>
                                    <div className='lk-border-table'>
                                        <table className="table table-borderless my-0">
                                            <thead>
                                                <tr>
                                                    <th className='lk-th-style'>#</th>
                                                    <th className="lk-th-style">{i18n.t('nama')}</th>
                                                    <th className="lk-th-style">{i18n.t('divisi')}</th>
                                                    <th className="lk-th-style">{i18n.t('jabatan')}</th>
                                                    <th className="lk-th-style" style={{ minWidth: "160px" }}>{i18n.t('jumlahkunjungan')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.loading &&
                                                    <tr>
                                                        <td colSpan={9}>
                                                            <Spin size="large" tip="Loading..." style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                                marginBottom: '10px',
                                                                marginTop: '10px'
                                                            }} />
                                                        </td>
                                                    </tr>
                                                }
                                                {this.state.data.map((item, index) => {
                                                    no += 1
                                                    return (
                                                        <tr style={{
                                                            backgroundColor: no % 2 === 0 ? "#FFFFFF" : "#EFEFEF"
                                                        }}>
                                                            <td className='lk-td-style' scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                            <td className="lk-td-style">
                                                                <a href={"/karyawan/profil/detail/" + item.karyawan_slug} style={{color : "#8c2bee"}}>
                                                                    {item.karyawan_nama}
                                                                </a>
                                                            </td>
                                                            <td className="lk-td-style">
                                                                {item.divisi.divisi_name}
                                                            </td>
                                                            <td className="lk-td-style">
                                                                {item.jabatan.jabatan_nama}
                                                            </td>
                                                            <td className="lk-td-style">
                                                                {item.jumlah_kunjungan} {i18n.t('kunjungan')}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr>
                                                    <td colSpan={5} style={{ padding: "0px" }}>
                                                        <div className='row mx-0' style={{ backgroundColor: "#FAFDFF", width: "100%", borderTop: "1px solid #DDDDDD" }}>
                                                            {this.state.data.length !== 0 &&
                                                                <>
                                                                    <div className='col font-14' style={{ margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} dari {this.state.totalData} data</div>
                                                                    <div className='col'>
                                                                        <ReactPaginate
                                                                            previousLabel={<img src={ArrowLeft} alt='' />}
                                                                            nextLabel={<img src={ArrowRight} alt='' />}
                                                                            pageCount={this.state.pageCount}
                                                                            forcePage={this.state.currentPage}
                                                                            marginPagesDisplayed={2}
                                                                            pageRangeDisplayed={3}
                                                                            onPageChange={this.handlePageClick}
                                                                            breakClassName={'page-item'}
                                                                            breakLinkClassName={'page-link custom-page-link'}
                                                                            containerClassName={'pagination my-2'}
                                                                            pageClassName={'page-item'}
                                                                            pageLinkClassName={'page-link custom-page-link'}
                                                                            previousClassName={'page-item'}
                                                                            previousLinkClassName={'page-link custom-prev-next-link'}
                                                                            nextClassName={'page-item'}
                                                                            nextLinkClassName={'page-link custom-prev-next-link'}
                                                                            activeClassName={'active custom-active'}
                                                                        />
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default (laporan_harian)
