// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import IDLanguage from './assets/json/id.json';
import ENLanguage from './assets/json/en.json';

const resources = {
    id: {
        translation: IDLanguage
    },
    en: {
        translation: ENLanguage
    },
};

const savedLanguage = localStorage.getItem('lang');

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: savedLanguage || 'id',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
