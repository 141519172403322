import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Swal from "sweetalert2";
import { set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Switch from "react-switch";

export class RestSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            prosesSimpan: false,
            isLoading: true,
            payroll_note: '',
            perusahaan: {},
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    showModal = () => {
        this.setState({
            modal: true,
        });
    };

    handleOk = e => {
        this.setState({
            modal: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modal: false,
        });
    };

    simpanPayrollSetting = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            payroll_note: this.state.payroll_note,
        }

        this.props.request("payroll/set-note", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.loadData()
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }


    loadData() {
        this.setState({
            loading: true
        })
        let form = {}
        this.props.request("get_company", form, 'POST')
            .then(response => {
                if (response) {
                    this.setState({
                        payroll_note: response.data.perusahaan_payroll_note,
                        perusahaan: response.data,
                    })
                }
            })
        this.setState({
            loading: false
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Pengaturan Payroll") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Pengaturan Istirahat - iPresens</title>
                        <meta name="description" content="Detail Presensi" />
                    </Helmet>
                    <Breadcrumb title="Pengaturan Istirahat" />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form className="needs-validation user-add" noValidate="">
                                            <h4>Pengaturan Payroll Perusahaan</h4>
                                            <div className="row">
                                                <label className='form-label label-input-basic col-md-4 col-sm-6'>Tampilkan Menu Istirahat</label>
                                                <Switch
                                                    id={'show_istirahat'}
                                                    name="show_istirahat"
                                                    checked={this.state.show_istirahat}
                                                    onChange={(val) => {
                                                        this.setState({
                                                            show_istirahat: val
                                                        })
                                                    }}
                                                    onColor="#8c2bee"
                                                    offColor="#eb4034"
                                                    handleDiameter={18}
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                    height={24}
                                                    width={48}
                                                    className="mr-1"
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4">Catatan Payroll</label>
                                                <input name="payroll_note" className="form-control col-xl-8 col-md-7" placeholder="Catatan Payroll..."
                                                       type="text" required="" value={this.state.payroll_note} onChange={this.handleChange} />
                                            </div>

                                        </form>
                                        <div className="pull-right">
                                            {this.state.prosesSimpan === false ?
                                                <button type="button" className="btn ipresens-btn-color"
                                                        onClick={this.simpanPayrollSetting}>Simpan</button>
                                                :
                                                <button type="button" className="btn ipresens-btn-color"><i className="fa fa-refresh fa-spin" /> Loading</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    user: state.Auth.user,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RestSetting)
