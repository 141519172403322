import React, {Component, Fragment} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import {Form} from "antd";
import Swal from "sweetalert2";
import Select from "react-select";
import IconPlusWhite from "../../../assets/icons/IconPlusWhite.svg";
import TrashIcon from "../../../assets/icons/trash.svg";

export class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            siswa: [],
            istilah: [],
            kelas: [],
            tagihan: [],
            loading: false,
            siswa_loading: false,
            siswa_name: '',
            siswa_id: 0,
            istilah_combined:[],
            term_loading: false,
            term_id: [],
            rows_length: 1,
            term_selected: [],
            jumlah_pembayaran: {},
            siswa_selected: [],
            term: [],
            selectedTerms: [],
            istilah_selected: [],
            istilah_loading: false,
            pb_term_id: 0,
            payment_date: '',
            tagihan_id: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangePb = this.handleChangePb.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleChangePb = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            jumlah_pembayaran: {
                ...prevState.jumlah_pembayaran,
                [name]: value
            }
        }));
    }

    searchTerm() {
        let form = {}
        this.props.request('pembayaran/payment_term_list', form , 'POST')
            .then((response)=> {
                if (response.success){
                    const payterm = response.data.data;
                    const combined = payterm.map(item => `${item.pb_term_name}_${item.pb_term_id}`);
                    this.setState({
                        istilah: payterm,
                        istilah_combined: combined,
                    });
                }
                this.setState({
                    term_loading: false,
                })
            })
    }

    searchKaryawan(){
        let form = {
            karyawan_name : this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response)=> {
                if (response.success){
                    this.setState({
                        siswa: response.data,
                        siswa_loading: false,
                    })
                }
            })
    }

    searchTagihan() {
        let form = {}
        this.props.request('tagihan_siswa/search-tagihan', form, "POST")
            .then((response) => {
                if (response.success){
                    this.setState({
                        tagihan: response.data,
                    })
                }
            })
    }

    componentDidMount() {
        this.searchTerm()
        this.searchTagihan()
    }

    handleSubmit = () => {
        this.setState({
            loading: true,
        })
        let data_pembayaran = this.state.jumlah_pembayaran;
        let pembayaran = [];

        for (let key in data_pembayaran) {
            pembayaran.push(data_pembayaran[key]);
        }
        let  form = {
            siswa_id: this.state.siswa_id,
            term_id: this.state.term_id,
            jumlah_pembayaran: pembayaran,
            payment_date: this.state.payment_date,
            tagihan_id: this.state.tagihan_id
        }
        this.props.request("pembayaran/add_payments", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.changeState({
                        add_visible: false,
                        data: [],
                        loading: true,
                    }, () => this.props.loadData(), () => this.setState({
                        jumlah_pembayaran: [], istilah_selected: [], siswa_id: 0, siswa_selected: null, rows_length: 1, term: []
                    }))
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasilditambahkan'),
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('datagagalditambahkan'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false,
                })
            })
    }

    render() {
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.add_visible} toggle={() => this.props.changeState({ add_visible: false }, () => this.setState({
                loading: false, jumlah_pembayaran: [], istilah_selected: [], siswa_id: 0, siswa_selected: null, rows_length: 1, term: [],
            }))} style={{ maxWidth: "800px" }}>
                <ModalHeader>
                    <div className='text-dark' style={{
                        fontWeight: 'bold', fontSize: "18px"
                    }}>{i18n.t("tambahpembayaransiswa")}</div>
                </ModalHeader>
                <ModalBody>
                    <Form layout="vertical" hideRequiredMark style={{width: "100%"}}>
                        <div className="col mb-2">
                            <label htmlFor="">{i18n.t('tanggalpembayaran')}</label>
                            <input type="date" name="payment_date" className="form-control"
                                   onChange={this.handleChange}/>
                        </div>
                        <div className="col mb-2">
                            <label htmlFor="">{i18n.t('tagihan')}</label>
                            <select name="tagihan_id" onChange={this.handleChange} className="form-control">
                                <option value="">{i18n.t('pilihtagihan')}</option>
                                {this.state.tagihan?.map((item, index) => {
                                    return (
                                        <option value={item.tagihan_id} key={index}>{item.tagihan_nama}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="col">
                            <label htmlFor="">{i18n.t('siswa')}</label>
                            <Select
                                placeholder={i18n.t('nama')}
                                backspaceRemoves={true}
                                deleteRemoves={true}
                                styles={{paddingLeft: "0px"}}
                                components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                value={this.state.siswa_selected}
                                isLoading={this.state.siswa_loading}
                                onFocus={() => this.setState({siswa_loading: true}, () => this.searchKaryawan())}
                                onBlur={() => this.setState({siswa_loading: false})}
                                onInputChange={(val) => this.setState({siswa_name: val}, () => this.setState({siswa_loading: true}, () => this.searchKaryawan()))}
                                options={
                                    this.state.siswa != null ? (
                                            this.state.siswa.map((item, index) =>
                                                (
                                                    {
                                                        key: index,
                                                        value: item.karyawan_id,
                                                        label: item.karyawan_nama,
                                                    }
                                                )
                                            ))
                                        : ("")
                                }
                                onChange={
                                    (siswa_selected) => {
                                        if (!siswa_selected) {
                                            this.setState({
                                                siswa_selected: '',
                                                karyawan_id: 0,
                                            })
                                        } else {
                                            this.setState({
                                                siswa_selected,
                                                siswa_id: siswa_selected.value,
                                                siswa: []
                                            })
                                        }
                                    }
                                }
                                isClearable={() => {
                                    this.setState({
                                        isClearable: true,
                                    })
                                }}
                            />
                        </div>
                        {Array.from({length: this.state.rows_length}, (item, rowIndex) => {
                            return (
                                <div className="row-cols-2 row"
                                     style={{paddingLeft: "13px", paddingRight: "13px", marginTop: "8px"}}>
                                    <div className="col">
                                        <label className="">{i18n.t('tipe')}</label>
                                        <Select
                                            placeholder={i18n.t('tipe')}
                                            backspaceRemoves={true}
                                            deleteRemoves={true}
                                            styles={{paddingLeft: "0px"}}
                                            components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                            value={this.state.istilah_selected[rowIndex] || null}
                                            onFocus={() => this.setState({istilah_loading: true}, () => this.searchTerm())}
                                            onBlur={() => this.setState({istilah_loading: false})}
                                            onInputChange={(val) => this.setState({pb_term_name: val}, () => this.setState({istilah_loading: true}, () => this.searchTerm()))}
                                            options={
                                                this.state.istilah != null ? (
                                                    this.state.istilah.map((item, index) => (
                                                        {
                                                            key: index,
                                                            value: item.pb_term_id,
                                                            label: item.pb_term_name,
                                                        }
                                                    ))
                                                ) : ("")
                                            }
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    this.setState(prevState => {
                                                        const updatedIstilahSelected = [...prevState.istilah_selected];
                                                        updatedIstilahSelected[rowIndex] = selectedOption;

                                                        const updatedTermIds = [...prevState.term_id];
                                                        updatedTermIds[rowIndex] = selectedOption.value;

                                                        return {
                                                            istilah_selected: updatedIstilahSelected,
                                                            term_id: updatedTermIds,
                                                            istilah: [] // Menghapus istilah yang lama
                                                        };
                                                    });
                                                } else {
                                                    // Jika tidak ada pilihan, hapus nilai
                                                    this.setState(prevState => {
                                                        const updatedIstilahSelected = [...prevState.istilah_selected];
                                                        updatedIstilahSelected[rowIndex] = null;

                                                        const updatedTermIds = [...prevState.term_id];
                                                        updatedTermIds[rowIndex] = null;

                                                        return {
                                                            istilah_selected: updatedIstilahSelected,
                                                            term_id: updatedTermIds,
                                                        };
                                                    });
                                                }
                                            }}
                                            isClearable={() => {
                                                this.setState({
                                                    isClearable: true,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-5">
                                        <label htmlFor="">{i18n.t('jumlahpembayaran')}</label>
                                        <div className="d-flex align-items-center" style={{position: "relative"}}>
                                            <span className="pl-2"
                                                  style={{position: "absolute", fontSize: "16px"}}>Rp.</span>
                                            <input type="number" className="form-control" onChange={this.handleChangePb}
                                                   name={`jumlah_pembayaran_${rowIndex}`}
                                                   style={{paddingLeft: "30px"}}/>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        {/* Tambah Row */}
                        <div className="col" style={{marginTop: "10px", display: "flex", gap: "10px"}}>
                            {this.state.rows_length <= 4 && (
                                <button className="btn ipresens-btn-color mt-0 text-capitalize pull-left button-w-full"
                                        onClick={() => this.setState({rows_length: this.state.rows_length + 1})}>
                                    <img src={IconPlusWhite} alt="" className="mr-2 mb-1"/> {i18n.t('tambahtipe')}
                                </button>
                            )}
                            {this.state.rows_length > 1 && (
                                <button className="btn ipresens-delete-button"
                                        onClick={() => this.setState({rows_length: this.state.rows_length - 1})}>
                                    <img src={TrashIcon} alt="" className="mr-2 mb-1"/>
                                </button>
                            )}
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({add_visible: false,}, () => this.setState({
                                loading: false,
                                jumlah_pembayaran: [],
                                istilah_selected: [],
                                siswa_id: 0,
                                siswa_selected: null,
                                rows_length: 1,
                                term: [],
                            }));
                        }}
                                style={{marginRight: 8}}>
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color"
                                disabled={this.state.loading} onClick={this.handleSubmit}>
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

export default Add;
