import React from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./style.css";
import Swal from "sweetalert2";
import { Redirect } from 'react-router-dom';
import { set_auth } from "../../reducers/Auth";
import { set_filter } from "../../reducers/Filter"
import { connect } from "react-redux";
import GoogleAuthButton from './components/googleauthbutton'
import LoginV2Img from "../../assets/images/login-v2.png"
import logo from "../../assets/images/dashboard/icon_ipresens.png"
import InfoIcon from "../../assets/icons/info-icon-green.svg"
import EyeGreyIcon from "../../assets/icons/eye-grey.svg"
import EyeSlashIcon from "../../assets/icons/eye-slash-grey.svg"
import CrossMarkIcon from "../../assets/icons/cross-mark.svg"
import withLanguageSwitch from "../../helper/language";
import LanguageSwitcher from "../common/lang_switcher";
import GoogleAuthButtonRemember from "./components/googleButtonRemember";
import i18n from 'i18next';

class LoginV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            messageEmail: "",
            messagePassword: "",
            messageOtp: "",
            messageSuccess: {
                header: i18n.t('berhasil'),
                value: ""
            },
            messageFailed: {
                header: i18n.t('gagal'),
                value: ""
            },
            otp: "",
            invalidEmail: false,
            invalidPassword: false,
            invalidOtp: false,
            disabledButton: false,
            hidePassword: true,
            confirmOtp: false,
            remember_me: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.submitAuth = this.submitAuth.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);
    }

    login = () => {
    }

    handleChange(event) {
        const name = event.target.name
        const value = event.target.value

        this.setState({
            [name]: value
        })
    }

    handleCheckBox = () => {
        this.setState((prevState) => ({
            remember_me: !prevState.remember_me
        }));
    }

    submitAuth = (event) => {
        event.preventDefault()
        this.setState({
            disabledButton: true,
            messageFailed: {
                header: "",
                value: ""
            },
            messageSuccess: {
                header: "",
                value: ""
            }
        })

        let form = {
            perusahaan_email: this.state.email,
            perusahaan_password: this.state.password,
            perusahaan_otp: this.state.otp,
            remember_me: this.state.remember_me
        }

        this.props.request("login", form)
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text:  i18n.t('loginberhasil'),
                        showConfirmButton: false,
                        icon: 'success',
                    })
                    this.props.set_filter(response.all_data)
                    this.props.set_auth(response.api_token, response.data, response.remember_me);
                    window.location.reload(false);
                }

                if (response.failed) {
                    this.setState({
                        messageFailed: {
                            header: i18n.t('gagal'),
                            value: i18n.t('cekemail'),
                        }
                    })
                }
                this.setState({
                    disabledButton: false
                })
            });
    }

    submitGoogleLogin(access_token) {
        let form = {
            token: access_token,
            remember_me: this.state.remember_me
        }
        this.props.request("login-google", form)
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text:  i18n.t('loginberhasil'),
                        showConfirmButton: false,
                        icon: 'success',
                    });
                    this.props.set_auth(response.api_token, response.data);
                    this.props.set_filter(response.all_data)
                    window.location.reload(false);
                } else if (response.failed) {
                    this.setState({
                        messageFailed: {
                            header: i18n.t('gagal'),
                            value: i18n.t('logingagal'),
                        }
                    })
                }
            })
    }

    submitLoginRemember = (event) => {
        Swal.fire({
            title: i18n.t("peringatan"),
            text: i18n.t("andayakiningatsaya"),
            confirmButtonText: i18n.t("lanjutkan"),
            showCancelButton: true,
            cancelButtonText: i18n.t("batal"),
            icon: 'warning',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        disabledButton: true,
                        messageFailed: {
                            header: "",
                            value: ""
                        },
                        messageSuccess: {
                            header: "",
                            value: ""
                        }
                    })
                    if (this.state.email === "") {
                        this.setState({
                            invalidEmail: true,
                            messageEmail: i18n.t("cekemail")
                        })
                    } else if (this.state.password === "") {
                        this.setState({
                            invalidPassword: true,
                            messagePassword: i18n.t("cekpassword")
                        })
                    }
                    let form = {
                        perusahaan_email: this.state.email,
                        perusahaan_password: this.state.password,
                        remember_me: this.state.remember_me
                    }
                    this.props.request("req_code", form)
                    .then(response => {
                        if (response.success) {
                            this.setState({
                                confirmOtp: true,
                                messageSuccess: {
                                    header: "OTP Telah dikirim ke Email anda",
                                    value: "Silahkan cek kembali email anda dan masukkan OTP tersebut ke form dibawah...."
                                }
                            })
                        } else if (response.skip_otp) {
                            this.submitAuth(event)
                        } else if (response.failed) {
                            this.setState({
                                messageFailed: {
                                    header: "Gagal",
                                    value: response.message
                                }
                            })
                        }
                        this.setState({
                            disabledButton: false
                        })
                    })
                }
            })
    }

    submitLogin = (event) => {
        this.setState({
            disabledButton: true,
            messageFailed: {
                header: "",
                value: ""
            },
            messageSuccess: {
                header: "",
                value: ""
            }
        })

        if (this.state.email === "") {
            this.setState({
                invalidEmail: true,
                messageEmail: i18n.t('cekemail'),
            })
        } else if (this.state.password === "") {
            this.setState({
                invalidPassword: true,
                messagePassword: i18n.t('cekpassword'),
            })
        }

        let form = {
            perusahaan_email: this.state.email,
            perusahaan_password: this.state.password,
        }

        this.props.request("req_code", form)
            .then(response => {
                if (response.success) {
                    this.setState({
                        confirmOtp: true,
                        messageSuccess: {
                            header: i18n.t('kirimotplogin'),
                            value: i18n.t('cekemaillogin'),
                        }
                    })
                } else if (response.skip_otp) {
                    this.submitAuth(event)
                } else if (response.failed) {
                    this.setState({
                        messageFailed: {
                            header: i18n.t('gagal'),
                            value: i18n.t('cekverifylogin'),
                        }
                    })
                }
                this.setState({
                    disabledButton: false
                })
            });
    }

    checkToken() {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const getparam = urlSearchParams.get('token')

        if(getparam) {
            let formData = {
                token: getparam
            }
            this.props.request('check_token', formData, 'POST')
                .then(response => {
                    if (response.success) {
                        this.props.set_auth(response.api_token, response.data);
                        this.props.set_filter(response.all_data)
                        window.location.reload(false);
                    }
                    this.setState({loading: false})
                })
        }
    }

    componentDidMount() {
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.success) {
                this.setState({
                    messageSuccess: {
                        header: i18n.t('berhasil'),
                        value: this.props.location.state.message
                    }
                }, () => this.props.history.push({ state: undefined }))
            }
        }
        this.checkToken()
    }

    render() {
        const { t,changeLanguage, i18n } = this.props;
        let heightPage = window.screen.height
        if (this.props.auth) {
            var { location } = this.props

            if (typeof location.state !== 'undefined' && location.state.from.pathname !== '/login')
                var redirect = location.state.from.pathname
            else
                var redirect = '/dashboard'

            return <Redirect to={redirect} />
            // return <Redirect to={"/dashboard"} />
        }
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Login - iPresens</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <div className="container-fluid">
                    <div className="row flex-row-reverse">
                        <div className="col-md-8 p-0">
                            <div className="loginv2-div-img">
                                <img alt="" src={LoginV2Img} className="loginv2-img" />
                            </div>
                        </div>
                        <div className="col-md-4 loginv2-col-form">
                            <LanguageSwitcher
                                currentLanguage={i18n.language}
                                onChange={() => changeLanguage(i18n.language == "id" ? 'en' : "id")}
                            />
                            <div className="row loginv2-row-logo">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                    <img alt="" src={logo} />
                                </div>
                            </div>
                            {this.state.messageFailed.value !== "" &&
                                <div className="row">
                                    <div className="col-md-12 loginv2-row-form">
                                        <div className="d-flex flex-row loginv2-failed">
                                            <div>
                                                <img alt="" src={CrossMarkIcon} style={{ width: "24px", height: "24px" }} />
                                            </div>
                                            <div className="pl-2">
                                                <div style={{
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    lineHeight: "18px"
                                                }}>{this.state.messageFailed.header}</div>
                                                <div>{this.state.messageFailed.value}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.messageSuccess.value !== "" &&
                                <div className="row">
                                    <div className="col-md-12 loginv2-row-form">
                                        <div className="d-flex flex-row loginv2-success">
                                            <div>
                                                <img alt="" src={InfoIcon} style={{ width: "24px", height: "24px" }} />
                                            </div>
                                            <div className="pl-2">
                                                <div style={{
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    lineHeight: "18px"
                                                }}>{this.state.messageSuccess.header}</div>
                                                <div>{this.state.messageSuccess.value}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-md-12 text-center loginv2-masuk-akun">
                                    {t("masukakun")}
                                </div>
                            </div>
                            {this.state.confirmOtp ?
                                <>
                                    <div className="row loginv2-row-form mb-4">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="loginv2-label">Otp Login</label>
                                                <input className={this.state.invalidOtp ? "form-control is-invalid loginv2-input" : "form-control loginv2-input"} placeholder="Masukkan OTP Login" name="otp" onChange={this.handleChange} type="text" value={this.state.otp} onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        this.submitAuth(e)
                                                    }
                                                }} />
                                                {this.state.invalidOtp &&
                                                    <div className="invalid-feedback loginv2-invalid">
                                                        {this.state.messageOtp}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row loginv2-row-form mb-4">
                                        <div className="col-md-12">
                                            <button className="btn btn-primary loginv2-button-login" disabled={this.state.email === ""} onClick={this.submitAuth}>{t('konfirmasiotp')}</button>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="row loginv2-row-form">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="loginv2-label">Email</label>
                                                <input className={this.state.invalidEmail ? "form-control is-invalid loginv2-input" : "form-control loginv2-input"} placeholder="emailanda@gmail.com" name="email" onChange={this.handleChange} type="text" value={this.state.email} />
                                                {this.state.invalidEmail &&
                                                    <div className="invalid-feedback loginv2-invalid">
                                                        {this.state.messageEmail}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row loginv2-row-form mb-4">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="loginv2-label">Password</label>
                                                <div className="input-group insert-pw">
                                                    <input className={this.state.invalidPassword ? "form-control is-invalid loginv2-input" : "form-control loginv2-input"} placeholder={t("passworddesc")} style={{ borderRight: "none" }} name="password" onChange={this.handleChange} type={this.state.hidePassword ? "password" : "text"} value={this.state.password} onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            this.submitLogin(e)
                                                        }
                                                    }}/>
                                                    {this.state.invalidPassword &&
                                                        <div className="invalid-feedback loginv2-invalid">
                                                            {this.state.messagePassword}
                                                        </div>
                                                    }
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <img className="loginv2-input-icon" style={{
                                                                width: "16px",
                                                                height: "16px",
                                                                backgroundColor: "#E9ECEF"
                                                            }} alt=""
                                                                 src={this.state.hidePassword ? EyeGreyIcon : EyeSlashIcon}
                                                                 onClick={() => this.setState({
                                                                     hidePassword: !this.state.hidePassword
                                                                 })}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between align-content-center">
                                                    <label htmlFor="remember_me"
                                                           className="d-inline-flex flex-row align-items-center pt-1"
                                                           style={{
                                                               display: "flex",
                                                               alignItems: "center",
                                                               gap: "3px",
                                                               width: "fit-content",
                                                               fontSize: "12px"
                                                           }}>
                                                        <input id="remember_me" type="checkbox" name="remember_me"
                                                               onChange={this.handleCheckBox}
                                                               checked={this.state.remember_me}/>
                                                        {t('ingatsaya')}</label>
                                                    <a href="/lupa-password"
                                                       className="form-text float-right loginv2-lupa-password">{t('lupapassword')}?</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row loginv2-row-form mb-2">
                                        <div className="col-md-12">
                                            <button
                                                className="btn btn-block ipresens-btn-color loginv2-button-login"
                                                disabled={this.state.email === "" || this.state.password === "" || this.state.disabledButton}
                                                onClick={this.state.remember_me ? (e) => this.submitLoginRemember(e) :
                                                    (e) => this.submitLogin(e)
                                                }>{t('masuksekarang')}</button>
                                        </div>
                                    </div>
                                    <div className="row loginv2-row-form mb-2">
                                        <div className="col-md-12">
                                            {this.state.remember_me ?
                                                <GoogleAuthButtonRemember action={(access_token) => {
                                                    this.submitGoogleLogin(access_token)
                                                }}/> :
                                                <GoogleAuthButton action={(access_token) => {
                                                    this.submitGoogleLogin(access_token)
                                                }}/>
                                            }
                                        </div>
                                    </div>
                                    <div className="row loginv2-row-form mb-4">
                                        <div className="col-md-12">
                                            <div className="loginv2-info">{t('belumpunyakun')}? <a href="/register"
                                                                                                   style={{color: "#8c2bee"}}>{t('daftarsinni')}</a>
                                            </div>
                                            <div className="loginv2-info">{t('jikainginmenghapuskaryawan')}! <a href="/hapus-karyawan" style={{ color: "#ff0000" }}>{t('hapusdisini')}</a></div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token,
    all_data: state.Filter.all_data
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data)),
    set_filter: (all_data) => dispatch(set_filter(all_data))
})

export default connect(mapStateToProps, mapDispatchToProps)(withLanguageSwitch(LoginV2));
