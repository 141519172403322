import React, {Component, Fragment} from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Swal from "sweetalert2";
import {requestData} from "../../helper/request";
import {Helmet} from "react-helmet";
import loginV2Img from "../../assets/images/login-v2.png";
import logo from "../../assets/images/full-logo.png";
import EyeGreyIcon from "../../assets/icons/eye-grey.svg"
import EyeSlashIcon from "../../assets/icons/eye-slash-grey.svg"
import "./reset-style.css";


export class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabledButton: false,
            success: false,
            password: "",
            ulangipassword: "",
            hidePassword: true,
            hideConfirmPassword: true
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    submitReset = () => {
        this.setState({
            disabledButton: true
        })

        let form = {
            token: this.props.match.params.token,
            password: this.state.password,
            ulangipassword: this.state.ulangipassword,
        }

        requestData("karyawan/reset_password", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        success: true
                    })
                }else{
                    Swal.fire({
                        title: response.failed,
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    disabledButton: false
                })
            });
    }

    componentDidMount() {
    }

    componentWillReceiveProps(newProps) {
    }

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false
        };

        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Reset Password - iPresens</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <div className="container-fluid">
                    <div className="row flex-row-reverse">
                        <div className="col-md-8 p-0">
                            <div className="loginv2-div-img">
                                <img src={loginV2Img} alt="" className="loginv2-img" />
                            </div>
                        </div>
                        <div className="col-md-4 loginv2-col-form">
                            <div className="row loginv2-row-logo">
                                <div className="col-md-12" style={{ textAlign:"center" }}>
                                    <img src={logo} alt=""/>
                                </div>
                            </div>
                                {this.props.match.params.token ?
                                <div className="row">
                                    <div className="col-md-12 text-center loginv2-masuk-akun">
                                        <h5>Masukkan Password Baru Anda</h5>
                                    </div>
                                    {!this.state.success ?
                                    <>
                                        <div className="row loginv2-row-form mb-4">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="loginv2-label">Password baru:</label>
                                                    <div className="input-group" id="input-password">
                                                        <input required="" name="password"
                                                               onChange={this.handleChange} type={this.state.hidePassword ? "password" : "text"}
                                                               className="form-control loginv2-input"
                                                               placeholder="Password Baru"/>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text">
                                                                <img className="loginv2-input-icon" style={{ width: "16px", height: "16px", backgroundColor: "rgba(255,255,255,0)" }} alt="" src={this.state.hidePassword ? EyeGreyIcon : EyeSlashIcon} onClick={() => this.setState({
                                                                    hidePassword: !this.state.hidePassword
                                                                })} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row loginv2-row-form mb-4">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className='login-v2-label'>Ulangi Password:</label>
                                                    <div className="input-group" id="input-password">
                                                        <input required="" name="ulangipassword"
                                                               onChange={this.handleChange} type={this.state.hideConfirmPassword ? "password" : "text"}
                                                               className="form-control loginv2-input"
                                                               placeholder="Ulangi Password"/>
                                                        <div className="input-group-append">
                                                            <div className="input-group-text">
                                                                <img className="loginv2-input-icon" style={{ width: "16px", height: "16px", backgroundColor: "#E9ECEF" }} alt="" src={this.state.hidePassword ? EyeGreyIcon : EyeSlashIcon} onClick={() => this.setState({
                                                                    hidePassword: !this.state.hidePassword
                                                                })} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row loginv2-row-form">
                                            <div className="col-md-12">
                                                {this.state.disabledButton === false ? (
                                                    <a className="btn btn-primary loginv2-button-login" type="submit"
                                                       style={{
                                                           backgroundColor: 'gray !important',
                                                           color: "white"
                                                       }}
                                                       onClick={this.submitReset}>Reset Password</a>
                                                ) : (
                                                    <a className="btn btn-primary loginv2-button-login" type="submit"
                                                       style={{color: "white"}} disabled><i
                                                        className="fa fa-refresh fa-spin"></i> Loading...</a>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                        :
                                        <form className="form-horizontal auth-form">
                                            <b>Anda berhasil mereset Password Akun anda!</b>
                                            <label>Silahkan masuk ke aplikasi iPresens dan Masuk dengan
                                                Password baru anda...</label>
                                        </form>
                                        }
                                </div>
                                :
                                    <div>
                                        <div className="card-header">
                                            <h5>Akun tidak Ditemukan!</h5>
                                        </div>
                                    </div>
                                }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ResetPassword
