import React, {Component} from 'react'
import Swal from "sweetalert2";
import MapPicker from 'react-google-map-picker'
import {Modal, Select} from "antd";
import {APIKEY} from "../../helper/host";
import i18n from "i18next";
import {Modal as ModalRs, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

export class Edit_outlet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan: false,
            isLoading: true,
            pickKaryawan: false,
            data: [],
            nama_outlet: '',
            email_outlet: '',
            no_hp_outlet: '',
            radius: '',
            location: {
                lat: '',
                lng: ''
            },
            karyawan: [],
            lokasi_karyawan: []

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange = (event) => {
        const { name, value } = event.target

        this.props.changeState({
            [name]: value,
        })
    }


    lokasiKaryawanHandler = () => {
        if (this.props.data.lokasi_karyawan === 'all') {
            this.props.changeState({
                lokasi_karyawan: []
            })
        } else {
            this.props.changeState({
                lokasi_karyawan: 'all'
            })
        }
    }

    loadKaryawan(){
        let form = {}
        this.props.request("karyawan/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        loading         : false
                    })
                }
            });
    }

    simpanOutlet = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id              : this.props.data.outlet_id,
            nama            : this.props.data.nama_outlet,
            nama_pemilik    : this.props.data.nama_pemilik,
            email           : this.props.data.email_outlet,
            no              : this.props.data.no_hp_outlet,
            alamat          : this.props.data.alamat,
            latitude        : this.props.data.location.lat,
            longtitude      : this.props.data.location.lng,
            radius          : this.props.data.radius,
            karyawan        : this.props.data.lokasi_karyawan,
        }

        this.props.request("outlet/edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.changeState({
                        prosesSimpan    : false,
                        nama_outlet     : '',
                        email_outlet    : '',
                        no_hp_outlet    : '',
                        alamat          : '',
                        nama_pemilik    : '',
                        radius          : '',
                        location        : {
                            lat         : -7.865242,
                            lng         : 110.156070
                        },
                        karyawan        : [],
                        lokasi_karyawan : [],
                        visible         : false,
                        data            : [],
                        isLoading       : true,
                    }, () => {
                        this.props.loadData()
                        this.loadKaryawan()
                    })
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }else{
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    componentDidMount() {
        this.loadKaryawan()
    }

    render() {
        if(this.props.data.lokasi_karyawan.length > 0 && this.props.data.lokasi_karyawan.length == this.state.karyawan.length){
            this.props.changeState({
                lokasi_karyawan: 'all'
            })
        }
        const { Option } = Select;
        return (
            <ModalRs size="lg" zIndex={999} isOpen={this.props.data.visible} toggle={() => this.props.changeState({ visible: false })} style={{ maxWidth: "1000px" }}>
                <ModalHeader toggle={() => this.props.changeState({ visible: false })}>
                    <div className='text-dark' style={{
                        fontWeight: 'bold', fontSize: "18px"
                    }}>{i18n.t("editlokasikunjungan")}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="card-body">
                        <form className="needs-validation user-add" noValidate="">
                            <div>
                                <div className="form-group">
                                    <label style={{fontSize: "12px", color: "#143B5E"}}
                                           className="mb-2 font-weight-bold">
                                        {i18n.t("namalokasikunjungan")}
                                    </label>
                                    <div>
                                        <input type="text" name="nama_outlet" className="form-control"
                                               placeholder={i18n.t("namalokasikunjungan") + ("...")}
                                               defaultValue={this.props.data.nama_outlet}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label style={{fontSize: "12px", color: "#143B5E"}}
                                           className="mb-2 font-weight-bold">
                                        {i18n.t("namapemilik")}
                                    </label>
                                    <div>
                                        <input type="text" name="nama_pemilik"
                                               className="form-control"
                                               placeholder={i18n.t("namapemilik") + ("...")}
                                               defaultValue={this.props.data.nama_pemilik}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label style={{fontSize: "12px", color: "#143B5E"}}
                                           className="mb-2 font-weight-bold">
                                        {i18n.t("emaillokasikunjungan")}
                                    </label>
                                    <div>
                                        <input type="text" name="email_outlet"
                                               className="form-control"
                                               placeholder={i18n.t("emaillokasikunjungan") + ("...")}
                                               defaultValue={this.props.data.email_outlet}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label style={{fontSize: "12px", color: "#143B5E"}}
                                           className="mb-2 font-weight-bold">
                                        {i18n.t("nolokasikunjungan")}
                                    </label>
                                    <div>
                                        <input type="text" name="no_hp_outlet"
                                               className="form-control"
                                               placeholder={i18n.t("nolokasikunjungan") + ("...")}
                                               defaultValue={this.props.data.no_hp_outlet}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label style={{fontSize: "12px", color: "#143B5E"}}
                                           className="mb-2 font-weight-bold">
                                        {i18n.t("alamat")}
                                    </label>
                                    <div>
                                        <textarea name="alamat" className="form-control"
                                                  placeholder={i18n.t("alamat") + ("...")}
                                                  defaultValue={this.props.data.alamat}
                                                  onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <div className="form-group">
                                            <label className="mb-2 font-weight-bold"
                                                   style={{fontSize: "12px", color: "#143B5E"}}>
                                                {i18n.t("radiuslokasikunjungan")}
                                            </label>
                                            <div className="input-group flex-nowrap w-100">
                                                <input className="form-control" type="text" onChange={this.handleChange}
                                                       defaultValue={this.props.data.radius}
                                                       name="radius" placeholder="100"/>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">{i18n.t("meter")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.location &&
                                        <>
                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label className="mb-2 font-weight-bold"
                                                           style={{fontSize: "12px", color: "#143B5E"}}>
                                                        Latitude
                                                    </label>
                                                    <div className="input-group w-100">
                                                        <input name="latitude" className="form-control col-xl-12"
                                                               placeholder="Latitude..." type="text" required=""
                                                               value={this.props.data.location.lat}
                                                               onChange={(event) =>
                                                                   this.props.changeState({
                                                                       location: {
                                                                           lat: event.target.value,
                                                                           lng: this.state.location.lng
                                                                       }
                                                                   })}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <label className="mb-2 font-weight-bold"
                                                           style={{fontSize: "12px", color: "#143B5E"}}>
                                                        Longitude
                                                    </label>
                                                    <div className="input-group w-100">
                                                        <input name="longitude" className="form-control col-xl-12"
                                                               placeholder="Longitude..." type="text" required=""
                                                               value={this.props.data.location.lng}
                                                               onChange={(event) =>
                                                                   this.props.changeState({
                                                                       location: {
                                                                           lat: this.state.location.lat,
                                                                           lng: event.target.value
                                                                       }
                                                                   })}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3">
                                                <div className="form-group">
                                                    <button className="btn ipresens-btn-color btn-pin-otl w-100"
                                                            type="button" style={{color: "#fff", marginTop: "28px", fontSize: "12px"}}
                                                            onClick={() => {
                                                                this.setState({modalLokasi: true});
                                                            }}
                                                    >
                                                        {i18n.t("pinlokasikunjungan")}
                                                    </button>
                                                </div>
                                            </div>
                                            <Modal
                                                title="Pin Lokasi Absen"
                                                visible={this.state.modalLokasi}
                                                onOk={() => {
                                                    this.setState({modalLokasi: false})
                                                }}
                                                onCancel={() => {
                                                    this.setState({modalLokasi: false})
                                                }}
                                            >
                                                <MapPicker
                                                    style={{position: 'relative', width: '100%', height: '250px'}}
                                                    name='location'
                                                    defaultLocation={this.props.data.location}
                                                    zoom={10}
                                                    onChangeLocation={(lat, lng) => {
                                                        this.props.changeState({
                                                            location: {
                                                                lat: lat,
                                                                lng: lng
                                                            }
                                                        })
                                                    }}
                                                    apiKey={APIKEY}/>
                                            </Modal>
                                        </>
                                    }
                                </div>
                                <div className="form-group">
                                    <label style={{fontSize: "12px"}}>
                                        <input
                                            style={{fontSize: "12px"}}
                                            type="checkbox"
                                            checked={this.props.data.lokasi_karyawan === 'all'}
                                            onChange={() => this.lokasiKaryawanHandler()}
                                        />
                                        &nbsp;{i18n.t("semuakaryawan")}
                                    </label>
                                    {this.props.data.lokasi_karyawan !== 'all' ? (
                                        <Select
                                            name="select_karyawan"
                                            style={{width: '100%'}}
                                            mode="multiple"
                                            placeholder={i18n.t("pilihkaryawan")}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().includes(input.toLowerCase()) ||
                                                option.value.toLowerCase().includes(input.toLowerCase())
                                            }
                                            value={this.props.data.lokasi_karyawan}
                                            onChange={(selectedValues) => {
                                                this.props.changeState({
                                                    lokasi_karyawan: selectedValues,
                                                });
                                            }}
                                        >
                                            {this.state.karyawan.map((item) => (
                                                <Option value={item.karyawan_id} key={item.karyawan_id}>
                                                    {item.karyawan_nama}
                                                </Option>
                                            ))}
                                        </Select>
                                    ) : null}
                                </div>
                            </div>
                        </form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({visible: false, desc: ''});
                        }}
                                style={{marginRight: 8}}>
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color"
                                disabled={this.state.loading} onClick={this.simpanOutlet}>
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </ModalRs>
        )
    }
}

export default Edit_outlet
