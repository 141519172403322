export const hitungBiayaAdmin = (type, value) =>  {
    switch (type){
        case "credit_card":
            return (0.029 * value) + 2000;
        case "echannel":
        case "bank_transfer":
            return 4000;
        case "shopeepay":
            return 0.02 * value;
        case "indomaret":
            return 6600;
        case "alfamart":
            return 5000;
        case "akulaku":
            return 0.017 * value;
        case "qris":
        case "other_qris":
            return 0.007 * value;
        default:
            return 0;
    }
}

export const getCurrency = (currencySymbol) =>  {
    const currencySymbols = {
        'IDR': 'Rp ',    // Rupiah
        'USD': '$ ',     // Dolar AS
        'VND': '₫ ',     // Dong Vietnam
        'THB': '฿ ',     // Baht Thailand
        'SGD': 'S$ ',     // Dolar Singapura
        'PHP': '₱ ',      // Peso Filipina
        'MYR': 'RM ',      // Malaysian Ringgit
        'AUD': 'A$ ',      // Australian Dollar
    };

    let currency = currencySymbols[currencySymbol] || currencySymbols['IDR'];
    return currency;
}

export const getKaryawanCustomPrice = (karyawanCount, companyType) => {
    const companyPrice = [9000, 8750, 8500, 8000, 7500, 7000, 6500];
    const schoolPrice = [4500, 4200, 4000, 3750, 3500, 3250, 3000];

    let price = 0;

    const priceArray = companyType === 'sekolah' ? schoolPrice : companyPrice;

    if (karyawanCount < 50) {
        price = priceArray[0];
    } else if (karyawanCount < 100) {
        price = priceArray[1];
    } else if (karyawanCount < 200) {
        price = priceArray[2];
    } else if (karyawanCount < 300) {
        price = priceArray[3];
    } else if (karyawanCount < 500) {
        price = priceArray[4];
    } else if (karyawanCount < 750) {
        price = priceArray[5];
    } else {
        price = priceArray[6];
    }

    return price;
};

