import React, { Fragment, useRef, useState } from "react";
import { useDrag, useDrop } from 'react-dnd';
import myImage from "../../../assets/images/Mask_group.png";
import i18n from "i18next";

const TaskItem = ({ item, index, moveItem, status }) => {
    const ref = useRef(null)
    const [, drop ] = useDrop({
        accept: 'ITEM',
        hover({ item, monitor }) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = item.hover;

            if (dragIndex === hoverIndex) {
                return;
            }
            const hovererdRect = ref.current.getBoundClientRect();
            const hoverMiddleY = (hovererdRect.bottom - hovererdRect.top)/2;
            const mousePostion = monitor.getClientOffest();
            const hoverClientY = mousePostion.y - hovererdRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            moveItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
        }
    })
    const [{ isDragging }, drag] = useDrag({
        type: "ITEM",
        item: { type: 'ITEM', item, index },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    function truncateString(str, maxWords) {
        if (!str) return ''
        const words = str.split(' ');
        var truncated = words.slice(0, maxWords).join(' ');
        if(words.length > maxWords){
            truncated = truncated + " ..."
        }
        return truncated;
    }

    drag(drop(ref))

    return (
        <Fragment >
            <div
                ref={ref}
                style={{ opacity: isDragging ? 0 : 1 }}
                className={'item'}
            >
                <div><a>
                    <div className="drop-card o-hidden">
                        <div className="p-3">
                            <div className="media static-top-widget" style={{ marginBottom: '10px' }}>
                                <div className="media-body">
                                    <div className="mt-3 mb-3">
                                        <img src={myImage} className="responsive-img"/>
                                        {item.task_daily === true && (
                                            <>
                                                <span className="badge badge-warning mb-2">{i18n.t('tugasharian')}</span>
                                            </>
                                        )}
                                    </div>
                                    <span className="item-title" style={{fontWeight: 'bold'}}>{truncateString(item.task_title, 10)}</span>
                                    <hr/>
                                    <div className='d-flex justify-content-between'>
                                        {item.karyawan && (
                                            <span className="item-title fs-10 text-muted text-break">{i18n.t('untuk')}&nbsp;
                                                {item.karyawan.length > 0 ?
                                                    item.karyawan.slice(0, 5).map((item2, index) => {
                                                        return (index !== 0 ? ", " : "") + item2.karyawan_nama + (item.karyawan.length > 5 && index === 4 ? " dll" : '');
                                                    }) :
                                                    'Semua Karyawan'
                                                }</span>
                                        )}

                                        {item.task_deadline && (
                                            <span className="item-title fs-10 text-muted">{i18n.t('deadline')} {item.task_deadline}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a></div>
                <div className={'color-bar'} style={{ backgroundColor: status.color }}
                ></div>
            </div>

        </Fragment>
    )
}
export default TaskItem
