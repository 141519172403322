import React, {Component, Fragment} from 'react';
import queryString from 'query-string';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Helmet} from "react-helmet";
import {Result, Spin} from 'antd';
import validation from '../../helper/validation';
import logo from "../../assets/images/dashboard/icon_ipresens.png";
import i18n from "i18next";

export class VerifyEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            loading: true,
            success: false,
            invalidToken: false,
            invalidEmail: false,
            message: '',
            tick: 5,
            reqLink: false
        }
        this.verify = this.verify.bind(this);
        this.reqLink = this.reqLink.bind(this);
    }

    verify = ({ email, token }) => {
        const form = {
            email,
            token
        }

        this.props.request("verify", form)
            .then(response => {
                if (response.success) {
                    this.setState({
                        loading: false,
                        success: true,
                        message: response.message
                    })
                    setInterval(() => {
                        this.setState(state => {
                            return {
                                tick: state.tick -1
                            }
                        })
                    }, 1000)
                    setTimeout(() => {
                        this.props.history.push('/login')
                    }, this.state.tick * 1000)
                } else {
                    this.setState({
                        loading: false,
                        success: false,
                        message: response.message
                    })
                }
            });
    }

    reqLink() {
        const form = {
            email: this.state.email
        }

        this.setState({ loading: true })
        this.props.request("req-resubmit-verify", form)
        .then(response => {
            if (response.success) {
                this.setState({
                    loading: false,
                    success: true,
                    message: response.message
                })
            } else {
                this.setState({
                    loading: false,
                    success: false,
                    message: response.message
                })
            }
        });
    }

    componentDidMount() {
        const queries = queryString.parse(this.props.location.search);
        const emailCheck = validation(queries.email);


        if (queries.email == undefined || queries.token == undefined) {
            this.setState({
                loading: false,
                success: false,
                message: i18n.t("linkveriftidakvalid")
            });
        } else {
            let linkValid = true;
            if (emailCheck === true) {
                this.setState({ email: queries.email });
            } else {
                linkValid = false;
                this.setState({
                    invalidEmail: true,
                    message: emailCheck
                });
            }

            if(queries.token.trim().length !== 40) {
                linkValid = false;
                this.setState({
                    invalidToken: true,
                    message: i18n.t("tokentidakvalid")
                });
            }
            if (linkValid) {
                this.verify(queries);
            } else {
                this.setState({
                    loading: false,
                    success: false,
                });
            }
        }
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{i18n.t("verifikasiemail")} - iPresens </title>
                    <meta name="description" content="Verifikasi Email Aplikasi Presensi" />
                </Helmet>
                <div className="page-wrapper">
                    <div className="authentication-box">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 card-right">
                                    <div className="card tab2-card">
                                        <div className="card-body">
                                            <div >
                                                <div style={{ textAlign: 'center' }} className="card-header">
                                                    <div className="container">
                                                        <div className="logo-wrapper mb-3">
                                                            <img className="blur-up lazyloaded" src={logo} alt="" />
                                                        </div>
                                                        <div>
                                                            <h4>{i18n.t("verifikasiemail")}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="dmt-4 d-flex-column">
                                                    <p style={{ color: '#000000' }}>{i18n.t("memverifikasiemail")} {this.state.email}</p>
                                                    {this.state.loading
                                                    ? (<Spin size="large" tip="Verify..." style={{
                                                        width: '100%',
                                                        margin: 'auto',
                                                        marginBottom: '10px',
                                                        marginTop: '10px'
                                                    }}/>)
                                                    : this.state.message === 'Silahkan cek email anda untuk mengaktifkan akun perusahaan anda.'
                                                        ? (<Result
                                                            status="info"
                                                            title={i18n.t("terkirim")}
                                                            subTitle={i18n.t("linktelahdikirim") + this.state.message}
                                                        />)
                                                        : this.state.message === 'Email anda sudah berhasil diverifikasi!'
                                                            ? (<Result
                                                                status="success"
                                                                title={i18n.t("sukses")}
                                                                subTitle={this.state.message + i18n.t("mengarahkelogin")` ${this.state.tick} ` + i18n.t("detik")}
                                                            />)
                                                            : this.state.message === 'Email sudah terverifikasi!'
                                                                ? (<Result
                                                                    status="info"
                                                                    title={i18n.t("terverifikasi")}
                                                                    subTitle={i18n.t(this.state.message)}
                                                                    />)
                                                                : (<Result
                                                                    status="error"
                                                                    title={i18n.t("gagal")}
                                                                    subTitle={this.state.message}
                                                                />)}
                                                </div>
                                                {this.state.message === 'Link telah expired!'
                                                    && <div className="card-footer">
                                                        <a href="#" onClick={this.reqLink}>{i18n.t("kirimulanglinkverif")}</a>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default VerifyEmail;

