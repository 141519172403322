import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import MapPicker from 'react-google-map-picker'
import {Button, Modal} from "antd";
import {set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {APIKEY} from "../../helper/host";

export class Add_lokasi_absensi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            prosesSimpan: false,
            isLoading: true,
            nama_lokasi: '',
            tipe_lokasi: '',
            alamat_lokasi: '',
            max_jarak: '',
            location: {
                lat: -7.865242,
                lng: 110.156070
            },

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    showModal = () => {
        this.setState({
            modal: true,
        });
    };

    handleOk = e => {
        this.setState({
            modal: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modal: false,
        });
    };

    simpanLokasi = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            nama: this.state.nama_lokasi,
            perusahaan_title: this.state.tipe_lokasi === '' ? 'pusat' : this.state.tipe_lokasi,
            perusahaan_alamat: this.state.alamat_lokasi,
            latitude: this.state.location.lat,
            longitude: this.state.location.lng,
            max_jarak: this.state.max_jarak
        }

        this.props.request("lokasi/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.props.set_auth(this.props.api_token, response.perusahaan);
                    this.props.history.push('/presensi-location')
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    getPosition = () => {
        navigator.geolocation.getCurrentPosition(
            position => this.setState({
                location: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
            }),
            err => console.log(err)
        );
    }

    componentDidMount() {
        this.getPosition()
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Tambah Lokasi Absen - iPresens</title>
                    <meta name="description" content="Tambah Lokasi Absen" />
                </Helmet>
                <Breadcrumb title="Tambah Lokasi Presensi" parent="Lokasi Presensi" parentLink="/presensi-location" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <form className="needs-validation user-add" noValidate="">
                                        <h4>Detail Lokasi Presensi</h4>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Nama Lokasi</label>
                                            <input name="nama_lokasi" className="form-control col-xl-8 col-md-7" placeholder="Nama lokasi..."
                                                type="text" required="" onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Tipe Lokasi</label>
                                            <select name="tipe_lokasi" className="form-control col-xl-8 col-md-7" onChange={this.handleChange}>
                                                <option value="pusat">Pusat</option>
                                                <option value="cabang">Cabang</option>
                                            </select>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Alamat Lokasi</label>
                                            <textarea name="alamat_lokasi" className="form-control col-xl-8 col-md-7" placeholder="Alamat Lokasi.." onChange={this.handleChange} />
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Lokasi Absen</label>
                                            <div className={"row"}>
                                                <div className={"col-md-4"}>
                                                    <label style={{ fontSize: '10px' }}>Pin Lokasi:</label>
                                                    <button type="button" className="btn ipresens-btn-color" onClick={this.showModal}>Pin Lokasi Absen</button>
                                                </div>
                                                <div className={"col-md-4"}>
                                                    <label style={{ fontSize: '10px' }}>Latitude:</label>
                                                    <input name="latitude" className="form-control col-xl-12" placeholder="Latitude..." type="text" required="" value={this.state.location.lat} onChange={(event) =>
                                                        this.setState({
                                                            location: { lat: event.target.value, lng: this.state.location.lng }
                                                        })} />
                                                </div>
                                                <div className={"col-md-4"}>
                                                    <label style={{ fontSize: '10px' }}>Longitude:</label>
                                                    <input name="longitude" className="form-control col-xl-12" placeholder="Longitude..." type="text" required="" value={this.state.location.lng} onChange={(event) =>
                                                        this.setState({
                                                            location: { lat: this.state.location.lat, lng: event.target.value }
                                                        })} />
                                                </div>
                                            </div>
                                            <Modal
                                                title="Pin Lokasi Absen"
                                                visible={this.state.modal}
                                                onOk={this.handleOk}
                                                onCancel={this.handleCancel}
                                            >
                                                <MapPicker
                                                    style={{ position: 'relative', width: '100%', height: '250px' }}
                                                    name='location'
                                                    defaultLocation={this.state.location}
                                                    zoom={10}
                                                    onChangeLocation={(lat, lng) => {
                                                        this.setState({
                                                            location: {
                                                                lat: lat,
                                                                lng: lng
                                                            }
                                                        })
                                                    }}
                                                    apiKey={APIKEY} />
                                            </Modal>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Max Jarak Absen dari Pin Lokasi <b>(*dalam satuan Meter)</b></label>
                                            <input name="max_jarak" defaultValue="100" className="form-control col-xl-8 col-md-7" placeholder="Max Jarak Absen dalam satuan Meter..."
                                                type="number" required="" onChange={this.handleChange} />
                                        </div>
                                    </form>
                                    <div className="pull-right">
                                        {this.state.prosesSimpan === false ?
                                            <button type="button" className="btn ipresens-btn-color"
                                                onClick={this.simpanLokasi}>Simpan</button>
                                            :
                                            <button type="button" className="btn ipresens-btn-color"><i className="fa fa-refresh fa-spin"></i> Loading</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Add_lokasi_absensi)
