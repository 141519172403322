import React, {Component, Fragment} from 'react'
import Breadcrumb from '../../common/breadcrumb';
import {APIKEY, IMG_HOST} from "../../../helper/host";
import {Helmet} from "react-helmet";
import {Spin} from "antd";
import {Card, CardBody, Col, Row} from 'reactstrap'
import KUNJUNGAN from './kunjungan'
import ABSENSI from './absensi'
import OUTLET from './outlet'
import PAYROLLIST from './payroll-list'
import LemburList from "./lembur-list";
import {GoogleApiWrapper} from 'google-maps-react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import KontrakKerja from './kontrak'
import InventarisKaryawan from './inventaris-karyawan';
import 'react-tabs/style/react-tabs.css';

import "./avatar.css"
import {clear_auth, set_auth} from "../../../reducers/Auth";
import {connect} from "react-redux";
import {List_karyawan} from "../list-karyawan";
import i18n from 'i18next';
import Semester from "./semester";

export class Detail extends Component {
    constructor(props) {
        super(props);
        let tabIndex = 0;
        const pathArr = props.location.pathname.split('/')
        if (pathArr.length == 6) {
             tabIndex = parseInt(pathArr[pathArr.length - 1])
        }

        this.state = {
            loading             : false,
            data_karyawan       : [],
            data_lokasi       : [],
            divisi              : [],
            jabatan             : [],
            tabIndex             : tabIndex
        }
        this.setTabIndex = this.setTabIndex.bind(this)
    }

    setTabIndex(index){
        this.setState({
            tabIndex : index
        })
    }

    loadDataKaryawan() {
        this.setState({loading: true})
        let form = {
            slug : this.props.match.params.id,
        }
        this.props.request("karyawan/get_karyawan_by_slug", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_karyawan   : response.data,
                        data_lokasi     : response.lokasi,
                        divisi          : response.data.divisi,
                        jabatan         : response.data.jabatan
                    })
                }
                this.setState({loading: false})
            });
    }



    componentDidMount() {
        this.loadDataKaryawan()
    }
    render() {
        const tipe = this.props.user.perusahaan.perusahaan_type === "sekolah";
        const tipePerusahaan = this.props.user.perusahaan.perusahaan_type === "sekolah" ? i18n.t('sekolah') : i18n.t('perusahaan');
        const karyawanOrSiswa = this.props.user.perusahaan.perusahaan_type === "sekolah" ? i18n.t('siswa') : i18n.t('karyawan');
        const divisiOrKelas =  this.props.user.perusahaan.perusahaan_type === "sekolah" ? i18n.t('kelas') : i18n.t('divisi');
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{tipe ? i18n.t('detailsiswa') : i18n.t('detailkaryawan') } - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <Breadcrumb title={tipe ? i18n.t('detailsiswa') : i18n.t('detailkaryawan') } parent={karyawanOrSiswa} parentLink='/employee/employee-list'/>
                <Col lg="12">
                    <Card className="main-card mb-3">
                        <CardBody>
                            {this.state.loading ?
                                <Spin size="large" tip={i18n.t("sedangmemuatdata") + ' ...'} style={{
                                    width: '100%',
                                    margin: 'auto',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}/>
                                :
                                <div>
                                    {/*profil Karyawan*/}
                                    <div className="profile-responsive card-border">
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner">
                                                <div className="row">
                                                    <div>
                                                        {this.state.data_karyawan.karyawan_foto !== null ? (
                                                            <img
                                                                id="avatar-square"
                                                                src={IMG_HOST + 'mitra/frontapp/karyawan/' + this.state.data_karyawan.karyawan_foto}
                                                                className="img-fluid blur-up lazyloaded"/>) : (
                                                            <img
                                                                id="avatar-square"
                                                                src={IMG_HOST + 'user-profile.png'}
                                                                className="img-fluid blur-up lazyloaded"/>
                                                        )}
                                                    </div>
                                                    <div className="col">
                                                        <div className="row">
                                                            <h1 className="menu-header-title" style={{color:"#143B5E"}}>{this.state.data_karyawan.karyawan_nama}</h1>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>{i18n.t('nomorinduk')}: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color: "#143B5E" }}>{this.state.data_karyawan && this.state.data_karyawan.karyawan_no_induk ? this.state.data_karyawan.karyawan_no_induk : '-'}</h6>
                                                                    </div>
                                                                </div>
                                                                {!tipe && (
                                                                    <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>{i18n.t('jabatan')}: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color:"#143B5E" }}>{this.state.data_karyawan.jabatan && this.state.data_karyawan.jabatan.jabatan_nama ? this.state.data_karyawan.jabatan.jabatan_nama : '-'}</h6>
                                                                    </div>
                                                                </div>
                                                                )}
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>{divisiOrKelas}: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color:"#143B5E" }}>{this.state.data_karyawan.divisi && this.state.data_karyawan.divisi.divisi_name ? this.state.data_karyawan.divisi.divisi_name : '-'}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>{i18n.t('email')}: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color:"#143B5E" }}>{this.state.data_karyawan.karyawan_email}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>{i18n.t('tanggallahir')}: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color:"#143B5E" }}>{this.state.data_karyawan.karyawan_ttl}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>{i18n.t('tanggalbergabung')}: </p>
                                                                    </div>
                                                                    <div className='col-md-8 mt-4'>
                                                                        {this.state.data_karyawan.karyawan_join_date ? (
                                                                            <h6 style={{ fontSize: 12, color:"#143B5E" }}>{this.state.data_karyawan.karyawan_join_date}</h6> ) : (
                                                                            <h6 style={{ fontSize: 12, color:"#143B5E" }}>-</h6>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {!tipe && (
                                                                    <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>{i18n.t('fotoijasah')}: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        {this.state.data_karyawan.karyawan_ijazah ? (
                                                                            <h6 style={{ fontSize: 12, color:"#143B5E" }}><a href={IMG_HOST + 'mitra/frontapp/karyawan/' + this.state.data_karyawan.karyawan_ijazah}>{this.state.data_karyawan.karyawan_ijazah}</a></h6> ) : (
                                                                            <h6 style={{ fontSize: 12, color:"#143B5E" }}>-</h6>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>{i18n.t('nomorhandphone')}: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color:"#143B5E" }}>{this.state.data_karyawan.no_hp}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>{i18n.t('lokasipresensi')}: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{ fontSize: 12, color:"#143B5E" }}>{this.state.data_lokasi ?
                                                                            this.state.data_lokasi.map((item, index) => {
                                                                                return (
                                                                                    <span key={item.lokasi_id}>{index !== 0 && ', '}{item.lokasi_nama}</span>
                                                                                )
                                                                            })
                                                                            : i18n.t('semualokasi') }</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>{i18n.t('alamat')}: </p>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <h6 style={{ fontSize: 12, color: "#143B5E" }}>{this.state.data_karyawan.karyawan_alamat}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>{i18n.t('fotoselfie')}: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        {this.state.data_karyawan.karyawan_selfie ? (
                                                                            <h6 style={{ fontSize: 12, color: "#143B5E" }}><a href={IMG_HOST + 'mitra/frontapp/karyawan/' + this.state.data_karyawan.karyawan_selfie}>{this.state.data_karyawan.karyawan_selfie}</a></h6>) : (
                                                                            <h6 style={{ fontSize: 12, color: "#143B5E" }}>-</h6>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {!tipe && (
                                                                    <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>{i18n.t('fotoktp')}: </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        {this.state.data_karyawan.karyawan_ktp ? (
                                                                            <h6 style={{ fontSize: 12, color: "#143B5E" }}><a href={IMG_HOST + 'mitra/frontapp/karyawan/' + this.state.data_karyawan.karyawan_ktp}>{this.state.data_karyawan.karyawan_ktp}</a></h6>) : (
                                                                            <h6 style={{ fontSize: 12, color: "#143B5E" }}>-</h6>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </CardBody>
                    </Card>
                </Col>
                <Col lg="12">
                    <Card className="main-card mb-3">
                        <CardBody>
                            {this.state.loading ?
                                <Spin size="large" tip={i18n.t("sedangmemuatdata") + ' ...'} style={{
                                    width: '100%',
                                    margin: 'auto',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}/>
                            :
                                <div>
                                    {/*profil Karyawan*/}
                                    <div>
                                        <Row>
                                            <Col sm="12" md="8" lg="12">
                                                <Tabs selectedIndex={this.state.tabIndex} onSelect={this.setTabIndex}>
                                                    <TabList className="nav nav-tabs tab-coupon">
                                                        <Tab className="nav-link">{i18n.t('presensi')}</Tab>
                                                        {!tipe && <Tab className="nav-link">{i18n.t('lokasikunjungan')}</Tab>}
                                                        {!tipe &&  <Tab className="nav-link">{i18n.t('absenkunjungan')}</Tab>}
                                                        {!tipe &&  <Tab className="nav-link">{i18n.t('daftarpayroll')}</Tab>}
                                                        {!tipe &&  <Tab className="nav-link">{i18n.t('kontrakkerja')}</Tab>}
                                                        <Tab className="nav-link">{tipe ? i18n.t('inventarissiswa') : i18n.t('inventariskaryawan')}</Tab>
                                                        {!tipe &&  <Tab className="nav-link">{i18n.t('lemburlistkaryawan')}</Tab>}
                                                        {tipe && <Tab className="nav-link">{i18n.t('nilaisiswa')}</Tab>}

                                                    </TabList>
                                                    <TabPanel>
                                                        <ABSENSI
                                                            request={this.props.request}
                                                            match={this.props.match}
                                                            history={this.props.history}
                                                        />
                                                    </TabPanel>
                                                    {!tipe && (
                                                        <TabPanel>
                                                            <OUTLET
                                                                request={this.props.request}
                                                                match={this.props.match}
                                                                history={this.props.history}
                                                            />
                                                        </TabPanel>
                                                    )}
                                                    {!tipe && (
                                                        <TabPanel>
                                                            <KUNJUNGAN
                                                                request={this.props.request}
                                                                match={this.props.match}
                                                                history={this.props.history} />
                                                        </TabPanel>
                                                    )}
                                                    {!tipe && (
                                                        <TabPanel>
                                                            <PAYROLLIST
                                                                request={this.props.request}
                                                                match={this.props.match}
                                                                history={this.props.history} />
                                                        </TabPanel>
                                                    )}
                                                    {!tipe && (
                                                        <TabPanel>
                                                            <KontrakKerja
                                                                request={this.props.request}
                                                                match={this.props.match}
                                                                history={this.props.history} />
                                                        </TabPanel>
                                                    )}
                                                    <TabPanel>
                                                        <InventarisKaryawan
                                                            request={this.props.request}
                                                            match={this.props.match}
                                                            history={this.props.history} />
                                                    </TabPanel>
                                                    {!tipe && (
                                                        <TabPanel>
                                                            <LemburList
                                                                request={this.props.request}
                                                                match={this.props.match}
                                                                history={this.props.history}/>
                                                        </TabPanel>
                                                    )}
                                                    <TabPanel>
                                                        <Semester
                                                            request={this.props.request}
                                                            match={this.props.match}
                                                            history={this.props.history}/>
                                                    </TabPanel>
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            }

                        </CardBody>
                    </Card>
                </Col>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Detail);

// export default  GoogleApiWrapper({
//     apiKey: APIKEY
// }) (Detail)
