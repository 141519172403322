import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import i18n from "i18next";
import { connect } from "react-redux";
import IconPlusWhite from "../../assets/icons/IconPlusWhite.svg";
import {Empty, Form, Spin, Tooltip} from "antd";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import { Td, Tr } from "react-super-responsive-table";
import Add from './nilai/add'
import moment from "moment";
import {
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    Modal as ModalRs,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Row
} from "reactstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import Breadcrumb from "../common/breadcrumb";
import Tour from "reactour";
import {pageNumber} from "../../functions/functionGeneral";
import "./style.css";
import ReactPaginate from 'react-paginate';
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ImportIcon from "../../assets/icons/import-icon.svg";
import ExportIcon from "../../assets/icons/download-white.svg";
import WarningIcon from "../../assets/icons/warning-icon.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {DateRange} from "react-date-range";
import {BACKEND_API_HOST, DEFAULT_IMG} from "../../helper/host";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import MoreIcon from "../../assets/icons/more.svg";
import FilterIcon from "../../assets/icons/filter.svg";

export class NilaiSiswa extends Component {
    constructor(props) {
        super(props);
        let currentDate = new Date();
        let currentYear = currentDate. getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        this.state = {
            selectedBulan: currentMonth.toString().padStart(2, '0') // Format dua digit
        };
        this.state = {
            selectedBulan: moment().format('MM'),
            selectedTahun: currentYear,
            data: [],
            kelas: [],
            mapel: [],
            semester: [],
            loading: false,
            filter: '',
            filter_select: '',
            add_visible: false,
            currentPage: 0,
            modal_edit: false,
            siswa_selected: null,
            nilai: '',
            selectedTerms: [],
            siswa: [],
            kategori: [],
            siswa_loading: false,
            siswa_name: '',
            siswa_id: [],
            kategori_combined:[],
            id_detail: 0,
            term_loading: false,
            term_selected: null,
            mapel_loading: false,
            mapel_selected: null,
            semester_loading: false,
            semester_selected: null,
            term_id: 0,
            mp_id: 0,
            semester_id: 0,
            id_delete: 0,
            filter_kelas: '',
            filter_mapel: '',
            filter_semester: '',
            modalExport: false,
            modalImport: false,
            start_date: new Date(),
            end_date: new Date(),
            exportStartDate: "",
            exportEndDate: "",
            reactDateRange: false,
            valueDate: "",
            pagination: 2,
            importFile: null,
            dataHistory: [],
            modalHistory: false,
            loadingHistory: false,
            currentPageHistory: 0
        }
        this.resetFilter = this.resetFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleBulanTahunChange = this.handleBulanTahunChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.importNilai = this.importNilai.bind(this);
        this.exportNilai = this.exportNilai.bind(this);
        this.handlePageHistory = this.handlePageHistory.bind(this);
        this.handleChangeNl = this.handleChangeNl.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handlePageClick(data) {
        let selected = data.selected;
        this.setState({ currentPage: selected, loading: true, data: [] }, () => {
            this.loadData();
        });
    }

    handleYearChange = (event) => {
        this.setState({ selectedTahun: event.target.value, data: [], loading: true, }, () => {
            this.loadData();
        });
    };

    handleBulanTahunChange = (event) => {
        this.setState({ selectedBulan: event.target.value, data: [], loading: true, }, () => {
            this.loadData();
        });
    };

    handleCancel = e => {
        this.setState({
            modalExport: false,
            modalimport: false,
            valueDate: "",
            exportStartDate: "",
            exportEndDate: "",
            start_date: new Date(),
            end_date: new Date()
        });
    };

    handleChangeNl = (event) => {
        const { name, value } = event.target;

        const selectedMapel = this.state.mapel_selected;
        const min = selectedMapel ? parseFloat(selectedMapel.mapel_start) : 0.00;
        const max = selectedMapel ? parseFloat(selectedMapel.mapel_end) : 10.00;

        if (value === "") {
            this.setState({
                [name]: value,
            });
            return;
        }

        const numericValue = parseFloat(value);
        const newValue = Math.max(min, Math.min(max, numericValue || 0));

        this.setState({
            [name]: newValue,
        });
    };

    resetFilter(event) {
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        this.setState({
            filter: "",
            karyawan_name: "",
            selectedBulan: currentMonth,
            selectedTahun: currentYear,
            data: [],
            loading: true,
            filter_mapel: '',
            filter_semester: '',
            filter_kelas: ''
        }, () => this.loadData())
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected;
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.nilai_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("nilai/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            nilai_id: ''
                        });
                    }
                });
        })
    }

    searchKaryawan(){
        let form = {
            karyawan_name : this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response)=> {
                if (response.success){
                    this.setState({
                        siswa: response.data,
                        siswa_loading: false,
                    })
                }
            })
    }

    searchTerm() {
        let form = {}
        this.props.request('nilai/list-nilai-term', form , 'POST')
            .then((response)=> {
                if (response.success){
                    const payterm = response.data.data;
                    const combined = payterm.map(item => `${item.nl_term_name}_${item.nl_term_id}`);
                    this.setState({
                        kategori: payterm,
                        kategori_combined: combined,
                    });
                }
                this.setState({
                    term_loading: false,
                })
            })
    }

    searchSemester() {
        let form = {}
        this.props.request('semester/load-data', form , 'POST')
            .then((response)=> {
                if (response.success){
                    const payterm = response.data.data;
                    const combined = payterm.map(item => `${item.semester_type}_${item.semester_id}`);
                    this.setState({
                        semester: payterm,
                        semester_combined: combined,
                    });
                }
                this.setState({
                    semester_loading: false,
                })
            })
    }

    searchMapel() {
        let form = {}
        this.props.request('mapel/list', form , 'POST')
            .then((response)=> {
                if (response.success){
                    const payterm = response.data.data;
                    const combined = payterm.map(item => `${item.mapel_nama}_${item.mapel_id}`);
                    this.setState({
                        mapel: payterm,
                        mapel_combined: combined,
                    });
                }
                this.setState({
                    mapel_loading: false,
                })
            })
    }

    loadDetail() {
        let form = {
            nilai_id: this.state.id_detail,
        }
        this.props.request('nilai/detail', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        siswa_id: response.data.karyawan_id,
                        siswa_selected: {
                            value: response.data.siswa.karyawan_id,
                            label: response.data.siswa.karyawan_nama,
                        },
                        term_selected: {
                            value: response.data.nilai_term.nl_term_id,
                            label: response.data.nilai_term.nl_term_name,
                        },
                        semester_selected: {
                            value: response.data.semester.semester_id,
                            label: response.data.semester.semester_type,
                        },
                        mapel_selected: {
                            value       : response.data.mapel.mapel_id,
                            label       : response.data.mapel.mapel_nama,
                            mapel_start : response.data.mapel.mapel_nilai_start,
                            mapel_end   : response.data.mapel.mapel_nilai_end,
                        },
                        nilai: response.data.nilai_value,
                    }, () => {
                        this.searchTerm();
                        this.searchSemester();
                        this.searchMapel();
                    })
                }
                this.setState({
                    loading: false,
                } , () => {
                    this.searchTerm();
                    this.searchSemester();
                    this.searchMapel();
                })
            })
    }

    loadData() {
        this.setState({
            loading: true,
        })
        let form = {
            filter          : this.state.filter,
            paginate        : 10,
            page            : this.state.currentPage + 1,
            filter_month    : this.state.selectedBulan,
            filter_year     : this.state.selectedTahun,
            filter_mapel    : this.state.filter_mapel,
            filter_semester : this.state.filter_semester,
            filter_kelas    : this.state.filter_kelas
        }
        this.props.request('nilai/list', form, 'POST')
        .then(response => {
            if (response.success) {
                this.setState({
                    data: response.data.data,
                    totalData: response.data.total,
                    pageCount: response.data.last_page,
                    perPage: response.data.per_page,
                })
            }
            this.setState({ loading: false });
        })
    }

    loadDataKelas() {
        let form = {}
        this.props.request('divisi/list-divisi', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        kelas: response.data,
                    })
                }
            })
    }

    loadDataMapel() {
        let form = {}
        this.props.request('mapel/list-mapel', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        mapel: response.data,
                    })
                }
            })
    }

    loadDataSemester() {
        let form = {}
        this.props.request('semester/list-semester', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        semester: response.data,
                    })
                }
            })
    }

    exportNilai() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'nilai/export-nilai?token=' + AuthKey + '&nilaiStartDate=' + (this.state.exportStartDate === true ? this.state.exportStartDate : moment(this.state.start_date).format("YYYY-MM-DD")) + '&nilaiEndDate=' + (this.state.exportEndDate === true ? this.state.exportEndDate : moment(this.state.end_date).format("YYYY-MM-DD")), "_blank")
    }

    exportTemplate() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'nilai/export-template?token=' + AuthKey, "_blank")
    }

    importNilai() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'nilai/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('importnilaiberhasil'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        showConfirmButton: true
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    handleSubmitEdit = () => {
        let form = {
            id_detail   : this.state.id_detail,
            nilai       : this.state.nilai,
            term_id     : this.state.term_id ? this.state.term_id  : this.state.term_selected.value,
            mp_id       : this.state.mp_id ? this.state.mp_id  : this.state.mapel_selected.value,
            semester_id : this.state.semester_id ? this.state.semester_id  : this.state.semester_selected.value,
            siswa_id    : this.state.siswa_id,
        }
        this.props.request('nilai/edit', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        modal_edit: false,
                        nilai: '',
                        term_id: 0,
                        semester_id: 0,
                        mp_id: 0,
                        siswa_id: []
                    }, () => this.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('gagalmengubahdata'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    deleteThis = () => {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('confirmhapuspnilaisiswa'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.wantDetele()
                }
            })
    }

    wantDetele() {
        this.setState({ loading: true, data: [] })
        let form = {
            nilai_id: this.state.id_delete,
        }
        this.props.request('nilai/delete', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        confirmDelete: false
                    })
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasildihapus'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.loadData()
            })
    }

    componentDidMount() {
        this.loadData();
        this.loadDataKelas()
        this.loadDataMapel()
        this.loadDataSemester()
    }

    render() {
        let no = 0;
        let borderBottom = "1px solid #EFEFEF";
        const steps = [
            {
                selector: '.tambah-nilai-siswa',
                content: i18n.t('caramembuatnilai'),
            },
            {
                selector: '.daftar-nilai-siswa',
                content: i18n.t('daftarnilaidisini'),
            },
        ];
        const selectionRange = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            key: 'selection',
        };
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Nilai") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t('nilaisiswa')} - Presensi</title>
                        <meta name="description" content="Detail Daftar Nilai Siswa" />
                    </Helmet>

                    <Add
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />

                    <Breadcrumb title={i18n.t("nilaisiswa")}  parent={i18n.t("nilai")}  setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid">
                        <div className='card' style={{ border: "1px solid #DDDDDD" }}>
                            <div className="card-header">
                                <div className="row justify-content-between row-btn-payroll mx-0">
                                    <h5 className="">{i18n.t('daftarnilaisiswa')}</h5>
                                    <div className="row">
                                        <div className="col-auto px-1">
                                            <a className="btn ipresens-btn-color tambah-jabatan btn-add-jbtn tambah-nilai-siswa"
                                               onClick={() => {
                                                   this.setState({
                                                       add_visible: true
                                                   })
                                               }}><i className={"fa fa-plus"}></i> {i18n.t("tambahnilai")}</a>
                                        </div>
                                        <div className="col-auto px-0 mr-3 ml-2" align="center">
                                            <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'} title={
                                                <div className='row px-4 py-2'>
                                                    <a
                                                        onClick={() => this.setState({
                                                            modalimport: true
                                                        })}
                                                        style={{marginRight: "100px"}}
                                                        className={'text-dark'}
                                                    >{i18n.t('importnilai')}</a>
                                                    <a
                                                        className={'text-dark'}
                                                        onClick={() => this.setState({
                                                            modalExport: true
                                                        })}
                                                    >{i18n.t('exportexcell')}</a>
                                                </div>
                                            }>
                                                <a><i
                                                    style={{
                                                        width: "15",
                                                        fontSize: 18,
                                                        color: 'rgb(51 51 51)',
                                                        alignItems: "center",
                                                        height: "38px",
                                                        paddingRight: "30px"
                                                    }}
                                                    className='btn border border-dark border-1 px-2'
                                                ><img src={MoreIcon}/></i></a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row filter-karyawan row-btn-payroll-list" style={{marginLeft: '25px'}}>
                                <div className="col-md-3 px-1 mb-1">
                                    <div className="input-group btn-srch-payroll-list">
                                        <input name="filter"
                                               value={this.state.filter}
                                               className={this.state.filter !== "" ? "form-control" : "form-control bg-image-search"}
                                               placeholder={i18n.t('cari')}
                                               type="text"
                                               required=""
                                               style={{paddingLeft: this.state.filter === "" ? "35px" : "10px"}}
                                               onKeyUp={(event) => {
                                                   if (event.which === 13) {
                                                       event.preventDefault();
                                                       this.setState({
                                                           loading: true,
                                                           data: [],
                                                       }, () => {
                                                           this.loadData()
                                                       })
                                                   }
                                               }} onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2 px-1 mb-1">
                                    <select name="month" className="form-control" onChange={this.handleBulanTahunChange}
                                            value={this.state.selectedBulan}>
                                        <option value="01">Januari</option>
                                        <option value="02">Februari</option>
                                        <option value="03">Maret</option>
                                        <option value="04">April</option>
                                        <option value="05">Mei</option>
                                        <option value="06">Juni</option>
                                        <option value="07">Juli</option>
                                        <option value="08">Agustus</option>
                                        <option value="09">September</option>
                                        <option value="10">Oktober</option>
                                        <option value="11">November</option>
                                        <option value="12">Desember</option>
                                    </select>
                                </div>
                                <div className="col-md-2 px-1 mb-1">
                                    <select name="year" className="form-control" onChange={this.handleYearChange}
                                            value={this.state.selectedTahun}>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                        <option value="2026">2026</option>
                                        <option value="2027">2027</option>
                                        <option value="2028">2028</option>
                                        <option value="2029">2029</option>
                                        <option value="2030">2030</option>
                                    </select>
                                </div>
                                <div className='col-auto px-1 pr-0 col-fltr-absen'>
                                    <button className='btn ipresens-btn-filter px-2 btn-fltr-absen'
                                            style={{width: "94px"}} align="center"
                                            onClick={() => this.setState({filter_select: !this.state.filter_select})}><img
                                        src={FilterIcon}/>Filter
                                    </button>
                                </div>
                                <div className="col-md-3 px-1 mb-1">
                                    <div className="input-group btn-slct-status-payroll">
                                        <button className="btn ipresens-btn-reset" onClick={this.resetFilter}>
                                            <span className="fa fa-refresh"></span>
                                        </button>
                                    </div>
                                </div>
                                {this.state.filter_select &&
                                    <div className="col-xl-10 px-0">
                                        <div className="row mx-0 mb-2">
                                            <div className="col-md-3 px-1 mb-1">
                                                <div className="input-group btn-slct-status-payroll">
                                                    <select
                                                        className="form-control"
                                                        name="filter_kelas"
                                                        style={{
                                                            borderBottomRightRadius: "4px",
                                                            borderTopRightRadius: "4px"
                                                        }}
                                                        onChange={this.handleFilter}
                                                    >
                                                        <option value=""
                                                                selected={this.state.filter_kelas === ''}>{i18n.t('pilihkelas')}</option>
                                                        {this.state.kelas.map((item) => (
                                                            <option value={item.divisi_name}>{item.divisi_name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3 px-1 mb-1">
                                                <div className="input-group btn-slct-status-payroll">
                                                    <select
                                                        className="form-control"
                                                        name="filter_mapel"
                                                        style={{
                                                            borderBottomRightRadius: "4px",
                                                            borderTopRightRadius: "4px"
                                                        }}
                                                        onChange={this.handleFilter}
                                                    >
                                                        <option value=""
                                                                selected={this.state.filter_mapel === ''}>{i18n.t('pilihmapel')}</option>
                                                        {this.state.mapel.map((item) => (
                                                            <option value={item.mapel_nama}>{item.mapel_nama}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-3 px-1 mb-1">
                                                <div className="input-group btn-slct-status-payroll">
                                                    <select
                                                        className="form-control"
                                                        name="filter_semester"
                                                        style={{
                                                            borderBottomRightRadius: "4px",
                                                            borderTopRightRadius: "4px"
                                                        }}
                                                        onChange={this.handleFilter}
                                                    >
                                                        <option value=""
                                                                selected={this.state.filter_semester === ''}>{i18n.t('pilihsemester')}</option>
                                                        {this.state.semester.map((item) => (
                                                            <option
                                                                value={item.semester_type}>{item.semester_type}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="card-body">
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    <div className="row">
                                        <div className="col-md-12 daftar-nilai-siswa">
                                            <div className="table-responsive">
                                                <table className='table table-striped table-borderless'>
                                                    <thead>
                                                        <tr>
                                                            <th className='nilai-th-style'>#</th>
                                                            <th className='nilai-th-style' style={{minWidth: "200px"}}>{i18n.t('siswa')}</th>
                                                            <th className='nilai-th-style'>{i18n.t('semester')}</th>
                                                            <th className='nilai-th-style'>{i18n.t('mapel')}</th>
                                                            <th className='nilai-th-style' style={{width: "200px"}}>{i18n.t('kategorinilai')}</th>
                                                            <th className='nilai-th-style text-center' style={{width: "120px"}}>{i18n.t('nilai')}</th>
                                                            <th className='nilai-th-style text-center' style={{width: "100px"}}>{i18n.t('aksi')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.loading &&
                                                        <Tr>
                                                            <Td colSpan={11}>
                                                                <Spin size="large" tip="Loading..." style={{
                                                                    width: '100%',
                                                                    margin: 'auto',
                                                                    marginBottom: '10px',
                                                                    marginTop: '10px'
                                                                }}/>
                                                            </Td>
                                                        </Tr>
                                                    }
                                                    {this.state.data.map((item, index) => {
                                                        no += 1;
                                                        return (
                                                            <tr style={{ borderBottom: no === this.state.data.length ? "" : borderBottom }}>
                                                                <td className="nilai-td-style">{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                <td className="nilai-td-style">{item.siswa.karyawan_nama}</td>
                                                                <td className="nilai-td-style">{item.semester ? item.semester.semester_type : '-'}</td>
                                                                <td className="nilai-td-style">{item.mapel ? item.mapel.mapel_nama : '-'}</td>
                                                                <td className="nilai-td-style">{item.nilai_term ? item.nilai_term.nl_term_name : '-'}</td>
                                                                <td className="nilai-td-style text-center">
                                                                    {item.nilai_value ? item.nilai_value.toString().replace('.', ',') : '-'}
                                                                </td>
                                                                <td className="nilai-td-style text-center">
                                                                    <Tooltip trigger={'click'} placement={'bottomRight'}
                                                                             color={'#fff'} title={
                                                                        <div className="p-2">
                                                                            <a className="text-dark"
                                                                               onClick={() => this.setState({
                                                                                   modal_edit: true,
                                                                                   id_detail: item.nilai_id
                                                                               }, () => this.loadDetail())}>{i18n.t('edit')}</a><br/>
                                                                            <a className="text-dark"
                                                                               onClick={() => {
                                                                                   this.setState({
                                                                                       modalHistory: true,
                                                                                       nilai_id: item.nilai_id
                                                                                   }, () => {
                                                                                       this.loadHistory(item.nilai_id);
                                                                                   })
                                                                               }}>{i18n.t("riwayat")}</a><br/>
                                                                            <a className="text-danger"
                                                                               onClick={() => this.setState({
                                                                                   id_delete: item.nilai_id
                                                                               }, () => this.deleteThis())}>{i18n.t('hapus')}</a>
                                                                        </div>
                                                                    }>
                                                                        <a><i className="fa fa-ellipsis-h" style={{
                                                                            width: 35,
                                                                            fontSize: 18,
                                                                            padding: 11,
                                                                            color: 'rgb(51 51 51)'
                                                                        }}/></a>
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='card-footer p-0 border-0'>
                                <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                    {this.state.data.length !== 0 &&
                                        <>
                                            <div className='col font-14' style={{
                                                padding: "auto",
                                                margin: "auto",
                                                fontWeight: 500,
                                                lineHeight: "24px"
                                            }}>{i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} data
                                            </div>
                                            <div className='col'>
                                                <ReactPaginate
                                                    previousLabel={<img src={ArrowLeftIcon} alt=''/>}
                                                    nextLabel={<img src={ArrowRightIcon} alt=''/>}
                                                    pageCount={this.state.pageCount}
                                                    forcePage={this.state.currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={this.handlePageClick}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link custom-page-link'}
                                                    containerClassName={'pagination my-2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link custom-page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link lembur-prev-next-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link lembur-prev-next-link'}
                                                    activeClassName={'active lembur-active'}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal size="lg" zIndex={999} isOpen={this.state.modal_edit} toggle={() => this.setState({
                        siswa_selected: null, nilai: "", selectedTerms: [], modal_edit: false,
                    })} style={{maxWidth: "900px"}}>
                        <ModalHeader>
                            <div className='text-dark' style={{
                                fontWeight: 'bold', fontSize: "18px"
                            }}>{i18n.t("editnilaisiswa")}</div>
                        </ModalHeader>
                        <ModalBody>
                            <Form layout="vertical" hideRequiredMark style={{width: "100%"}}>
                                <div className="row-cols-2 row"
                                     style={{paddingLeft: "13px", paddingRight: "13px", marginTop: "8px"}}>
                                    <div className="col-md-8">
                                        <label htmlFor="">{i18n.t('siswa')}</label>
                                        <Select
                                            placeholder={i18n.t('nama')}
                                            backspaceRemoves={true}
                                            deleteRemoves={true}
                                            styles={{paddingLeft: "0px"}}
                                            components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                            value={this.state.siswa_selected}
                                            isLoading={this.state.siswa_loading}
                                            onFocus={() => this.setState({siswa_loading: true}, () => this.searchKaryawan())}
                                            onBlur={() => this.setState({siswa_loading: false})}
                                            onInputChange={(val) => this.setState({siswa_name: val}, () => this.setState({siswa_loading: true}, () => this.searchKaryawan()))}
                                            options={
                                                this.state.siswa != null ? (
                                                        this.state.siswa.map((item, index) =>
                                                            (
                                                                {
                                                                    key: index,
                                                                    value: item.karyawan_id,
                                                                    label: item.karyawan_nama,
                                                                }
                                                            )
                                                        ))
                                                    : ("")
                                            }
                                            onChange={
                                                (siswa_selected) => {
                                                    if (!siswa_selected) {
                                                        this.setState({
                                                            siswa_selected: '',
                                                            karyawan_id: 0,
                                                        })
                                                    } else {
                                                        this.setState({
                                                            siswa_selected,
                                                            siswa_id: siswa_selected.value,
                                                            siswa: []
                                                        })
                                                    }
                                                }
                                            }
                                            isClearable={() => {
                                                this.setState({
                                                    isClearable: true,
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="">{i18n.t('semester')}</label>
                                        <Select
                                            placeholder={i18n.t('semester')}
                                            backspaceRemoves={true}
                                            deleteRemoves={true}
                                            styles={{paddingLeft: "0px"}}
                                            components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                            value={this.state.semester_selected}
                                            isLoading={this.state.semester_loading}
                                            onFocus={() => this.setState({semester_loading: true}, () => this.searchSemester())}
                                            onBlur={() => this.setState({semester_loading: false})}
                                            onInputChange={(val) => this.setState({semester_type: val}, () => this.setState({semester_loading: true}, () => this.searchSemester()))}
                                            options={
                                                this.state.semester != null ? (
                                                        this.state.semester.map((item, index) =>
                                                            (
                                                                {
                                                                    key: index,
                                                                    value: item.semester_id,
                                                                    label: item.semester_type,
                                                                }
                                                            )
                                                        ))
                                                    : ("")
                                            }
                                            onChange={
                                                (semester_selected) => {
                                                    if (!semester_selected) {
                                                        this.setState({
                                                            semester_selected: '',
                                                            semester_id: 0,
                                                        })
                                                    } else {
                                                        this.setState({
                                                            semester_selected,
                                                            semester_id: semester_selected.value,
                                                            semester: []
                                                        })
                                                    }
                                                }
                                            }
                                            isClearable={() => {
                                                this.setState({
                                                    isClearable: true,
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <hr/>
                                <div className="row-cols-2 row"
                                     style={{paddingLeft: "13px", paddingRight: "13px", marginTop: "8px"}}>
                                    <div className="col-md-6">
                                        <label className="">{i18n.t('kategorinilai')}</label>
                                        <Select
                                            placeholder={i18n.t('kategorinilai')}
                                            backspaceRemoves={true}
                                            deleteRemoves={true}
                                            styles={{paddingLeft: "0px"}}
                                            components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                            value={this.state.term_selected}
                                            isLoading={this.state.term_loading}
                                            onFocus={() => this.setState({term_loading: true}, () => this.searchTerm())}
                                            onBlur={() => this.setState({term_loading: false})}
                                            onInputChange={(val) => this.setState({term_name: val}, () => this.setState({term_loading: true}, () => this.searchTerm()))}
                                            options={
                                                this.state.kategori != null ? (
                                                        this.state.kategori.map((item, index) =>
                                                            (
                                                                {
                                                                    key: index,
                                                                    value: item.nl_term_id,
                                                                    label: item.nl_term_name,
                                                                }
                                                            )
                                                        ))
                                                    : ("")
                                            }
                                            onChange={
                                                (term_selected) => {
                                                    if (!term_selected) {
                                                        this.setState({
                                                            term_selected: '',
                                                            term_id: 0,
                                                        })
                                                    } else {
                                                        this.setState({
                                                            term_selected,
                                                            term_id: term_selected.value,
                                                            kategori: []
                                                        })
                                                    }
                                                }
                                            }
                                            isClearable={() => {
                                                this.setState({
                                                    isClearable: true,
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="">{i18n.t('mapel')}</label>
                                        <Select
                                            placeholder={i18n.t('mapel')}
                                            backspaceRemoves={true}
                                            deleteRemoves={true}
                                            styles={{paddingLeft: "0px"}}
                                            components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                            value={this.state.mapel_selected}
                                            isLoading={this.state.mapel_loading}
                                            onFocus={() => this.setState({mapel_loading: true}, () => this.searchMapel())}
                                            onBlur={() => this.setState({mapel_loading: false})}
                                            onInputChange={(val) => this.setState({mapel_nama: val}, () => this.setState({mapel_loading: true}, () => this.searchMapel()))}
                                            options={
                                                this.state.mapel != null ? (
                                                        this.state.mapel.map((item, index) =>
                                                            (
                                                                {
                                                                    key: index,
                                                                    value: item.mapel_id,
                                                                    label: item.mapel_nama,
                                                                    mapel_start: item.mapel_nilai_start,
                                                                    mapel_end: item.mapel_nilai_end,
                                                                }
                                                            )
                                                        ))
                                                    : ("")
                                            }
                                            onChange={
                                                (mapel_selected) => {
                                                    if (!mapel_selected) {
                                                        this.setState({
                                                            mapel_selected: '',
                                                            mp_id: 0,
                                                            nilai: ''
                                                        })
                                                    } else {
                                                        this.setState({
                                                            mapel_selected,
                                                            mp_id: mapel_selected.value,
                                                            mapel: [],
                                                            nilai: ''
                                                        })
                                                    }
                                                }
                                            }
                                            isClearable={() => {
                                                this.setState({
                                                    isClearable: true,
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <label htmlFor="">{i18n.t('nilai')}</label>
                                        <div className="d-flex align-items-center"
                                             style={{width: "115px", position: "relative"}}>
                                            <input
                                                type="number"
                                                step="0.01"
                                                className="form-control"
                                                onChange={this.handleChangeNl}
                                                name="nilai"
                                                value={this.state.nilai}
                                                disabled={!this.state.mapel_selected}
                                                min={this.state.mapel_selected? this.state.mapel_selected.mapel_start : 0.00}
                                                max={this.state.mapel_selected? this.state.mapel_selected.mapel_end : 10.00}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <div
                                style={{
                                    textAlign: 'right',
                                }}
                            >
                                <button
                                    type="button"
                                    className="btn ipresens-btn-cancel"
                                    onClick={() => this.setState({
                                        modal_edit: false,
                                        nilai: '',
                                        term_id: 0,
                                        siswa_id: []
                                    })}
                                    style={{marginRight: 8}}>
                                    {i18n.t("batal")}
                                </button>
                                <button type="button" className="btn ipresens-btn-color"
                                        disabled={this.state.loading} onClick={this.handleSubmitEdit} disabled={this.state.loading}>
                                    {this.state.loading ?
                                        <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                        :
                                        i18n.t("simpan")
                                    }
                                </button>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <ModalRs
                        size='lg'
                        style={{ maxWidth: "394px" }}
                        isOpen={this.state.modalExport}
                        toggle={() => this.setState({
                            reactDateRange: false
                        }, () => this.handleCancel())}
                    >
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ reactDateRange: false }, () => this.handleCancel())}>
                            {i18n.t('exportexcel')}
                        </ModalHeader>
                        <ModalBody>
                            <div className='row'>
                                <div className='col' style={{ lineHeight: "12px" }}>
                                    <div className='d-flex flex-row warning-info px-2 mb-2'>
                                        <div className='px-0 mr-1'><img src={WarningIcon} alt="" /></div>
                                        <div className='px-0 mt-1' style={{ fontSize: "14px" }}>
                                            {i18n.t('filtertanggal')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2' style={{ width:"780px" }}>
                                <div className='col-md-6'>
                                    <InputGroup className="form-tgl-nilai mb-0">
                                        <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                            </div>
                                        </InputGroupAddon>
                                        <Input bsSize="sm" className='font-edit-value' style={{ paddingTop: "8px", paddingBottom: "8px" }} onFocus={() => this.setState({ reactDateRange: true })} placeholder={i18n.t('filtertanggal')} value={this.state.valueDate} />
                                    </InputGroup>
                                </div>
                            </div>
                            {this.state.reactDateRange === true &&
                                <div className='shadow table-responsive d-flex flex-column mb-2' style={{ maxWidth: "360px" }}>
                                    <div className='d-flex justify-content-center mt-3'>
                                        <DateRange
                                            onBlur={() => this.setState({ reactDateRange: false })}
                                            ranges={[selectionRange]}
                                            onChange={(e) => {
                                                this.setState({
                                                    start_date: e.selection.startDate,
                                                    end_date: e.selection.endDate,
                                                    exportStartDate: moment(e.selection.startDate).format("YYYY-MM-DD"),
                                                    exportEndDate: moment(e.selection.endDate).format("YYYY-MM-DD")
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-end pr-2'>
                                            <button className='btn ipresens-btn-cancel text-capitalize mb-3' style={{ fontSize: "14px", fontWeight: 500 }} onClick={() => this.setState({ reactDateRange: false })}>{i18n.t('batal')}</button>
                                            <button className='btn ipresens-btn-color mb-3 mr-2 ml-2 text-capitalize' style={{ fontSize: "14px", fontWeight: 500, lineHeight: "24px" }} onClick={
                                                () => this.setState({
                                                    valueDate: this.state.exportStartDate === true ? this.state.exportStartDate + " - " + this.state.exportEndDate : moment(this.state.start_date).format("YYYY-MM-DD") + " - " + moment(this.state.end_date).format("YYYY-MM-DD"),
                                                    reactDateRange: false
                                                })
                                            }>{i18n.t('terapkan')}</button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.valueDate !== "" && this.state.valueDate !== " - " &&
                                <>
                                    <div className='d-flex justify-content-center'>
                                        <div className='col-md-8 px-2'>
                                            <button
                                                className={'shadow btn btn-block ipresens-btn-color px-3 py-2 text-capitalize mt-2'}
                                                style={{fontSize: "14px", borderRadius: "11px"}}
                                                onClick={this.exportNilai}
                                            >
                                                {i18n.t('exportdatanilai')}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            }
                        </ModalBody>
                        <ModalFooter style={{backgroundColor: "#F5F5F5", borderTop: 0}}>
                            <button
                                className="btn ipresens-btn-cancel text-capitalize"
                                onClick={() => this.setState({
                                    reactDateRange: false
                                }, () => this.handleCancel())}
                            >
                                {i18n.t('batalkan')}
                            </button>
                        </ModalFooter>
                    </ModalRs>
                    <Modal isOpen={this.state.modalimport}>
                        <ModalHeader onClick={this.handleCancel} toggle={() => this.setState({importFile: null})}>
                            <div>{i18n.t('importnilai')}</div>
                        </ModalHeader>
                        <ModalBody style={{padding: "20px"}}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>{i18n.t('importtemplate')}</a><br/>
                                    <a onClick={this.exportTemplate} style={{color: "#057CE4"}}>{i18n.t('unduhtemplate')}
                                    </a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({
                                                       importFile: event.target.files[0],
                                                   })
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i> {i18n.t('prosesupload')}</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importNilai}>{i18n.t('importdata')}</button>
                            ]}
                        </ModalFooter>
                    </Modal>
                    <ModalRs size="lg" style={{maxWidth: "1000px"}} isOpen={this.state.modalHistory}
                             toggle={() => this.setState({modalHistory: false, currentPageHistory: 0})}>
                        <ModalHeader style={{backgroundColor: "#FAFDFF"}}
                                     toggle={() => this.setState({modalHistory: false, currentPageHistory: 0})}>
                            <div className='modal-header-reactstrap'>{i18n.t("riwayatperubahan")}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{textAlign: 'center', margin: '10px auto'}}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{marginLeft: '20px'}}>
                                                    <h4>{i18n.t("user")}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{marginRight: '20px'}}>
                                                    <h4>{i18n.t("aktivitas")}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return (
                                                    <div key={index} style={{marginBottom: '20px'}}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    paddingTop: '16px',
                                                                    paddingBottom: '16',
                                                                    paddingRight: '12px',
                                                                    paddingLeft: '12px'
                                                                }}>
                                                                    <div style={{marginRight: '16px'}}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span
                                                                                className="col-green font-weight-bold">
                                                                                {moment(item.history_create_date).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{marginTop: 12}}>
                                                                            {item.history_status === "created" ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="d-flex justify-content-center"
                                                                                        style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                        {i18n.t("dibuat")}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        className="d-flex justify-content-center"
                                                                                        style={{
                                                                                            paddingTop: '6px',
                                                                                            paddingBottom: '6px'
                                                                                        }}>
                                                                                        {i18n.t("diubah")}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{
                                                                paddingTop: '16px',
                                                                paddingBottom: '16px',
                                                                paddingRight: '12px',
                                                                paddingLeft: '12px'
                                                            }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.karyawan_id != item.karyawan_id && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('siswa')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.siswa.karyawan_nama
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.siswa ? item.siswa.karyawan_nama : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.nl_term_id != item.nl_term_id && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('kategorinilai')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history.nilai_term
                                                                                            ? item.parent_history.nilai_term.nl_term_name
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.nilai_term ? item.nilai_term.nl_term_name : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.nilai_value != item.nilai_value && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('nilai')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.nilai_value
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.nilai_value ? item.nilai_value : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.semester_id != item.semester_id && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('semester')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.semester.semester_type
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.semester ? item.semester.semester_type : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.mapel_id != item.mapel_id && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('mapel')}: {i18n.t('dari')}{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.mapel.mapel_nama
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')}: <b>{item.mapel ? item.mapel.mapel_nama : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{backgroundColor: "#F5F5F5"}}>
                            <div className='row mx-0' style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{
                                            padding: "auto",
                                            margin: "auto",
                                            fontWeight: 500,
                                            lineHeight: "24px"
                                        }}>{i18n.t('menampilkan')} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t('dari')} {this.state.totalDataHistory} {i18n.t('data')}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt=''/>}
                                                nextLabel={<img src={ArrowRight} alt=''/>}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

export default connect(mapStateToProps)(NilaiSiswa);
