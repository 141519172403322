import React, {Component} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import i18n from "i18next";
import Swal from "sweetalert2";
import { Spin} from "antd";
import './payment_type_style.css'

export class AddPaymentType extends Component {
    constructor(props) {
        super(props);   
        this.state = {
            loading: false,
            payment_type: '',
            acc_number: '',
            add_visible: false,
            edit_visible: false,
            data_payment: [],
            type_id: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleSubmit = () => {
        let form = {
            payment_type: this.state.payment_type,
            acc_number: this.state.acc_number,
        }
        this.props.request('payment-type/add', form, "POST")
        .then(response => {
            if (response.success) {
                Swal.fire({
                    title: i18n.t('berhasil'),
                    text: i18n.t('databerhasilditambahkan'),
                    icon: 'success'
                })
                this.setState({ loading: false, payment_type: '', acc_number: '' }, () => this.loadData())
            } else {
                Swal.fire({
                    title: i18n.t('gagal'),
                    text: response.message,
                    icon: 'warning',
                    confirmButtonText: 'OK'
                })
            }
            this.setState({
                add_visible: false
            })
        })
    }

    loadData() {
        this.setState({ loading: true, data_payment: [] })
        let form = {}
        this.props.request('payment-type/list-payment-type', form, "POST")
            .then((response)=> {
                if (response.success) {
                    this.setState({
                        loading: false,
                        data_payment: response.data,
                    })
                }
            })
    }

    loadDetail(id) {
        let form = {
            type_id: id
        }
        this.props.request('payment-type/load-detail', form, "POST")
        .then(response => {
            if (response.success) {
                this.setState({
                    type_id: response.data.payment_type_id,
                    payment_type: response.data.payment_type_name,
                    acc_number: response.data.payment_type_number,
                    edit_visible: true
                })
            }
        })
    }

    hamdleSubmitEdit(id) {
        let form = {
            payment_type: this.state.payment_type,
            acc_number: this.state.acc_number,
            type_id: id,
        }
        this.props.request('payment-type/update', form, "POST")
        .then(response => {
            if (response.success) {
                Swal.fire({
                    title: i18n.t('berhasil'),
                    text: i18n.t('paymenttypeberhasildiubah'),
                    icon: 'success'
                })
            } else {
                Swal.fire({
                    title: i18n.t('gagal'),
                    text: response.message,
                    icon: 'warning',
                    confirmButtonText: 'OK'
                })
            }
            this.setState({
                loading: true, data_payment: [], edit_visible: false
            }, () => this.loadData())
        })
    }

    wantDelete(id) {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinhapuspaymenttype'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        type_id: id,
                    }
                    this.props.request("payment-type/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                this.setState({ data_payment: [], loading:  true })
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('databerhasildihapus'),
                                    icon: "success",
                                    showConfirmButton: false,
                                    timer: 900,
                                })
                                this.loadData()
                            }
                        })
                }
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return (
            <Modal size="lg"  isOpen={this.props.visible} toggle={() => this.props.changeState({ add_payment_type: false })} style={{ width: "900px" }}>
                <ModalHeader toggle={() => this.props.changeState({ add_payment_type: false })}>
                    <div className="">{i18n.t('addsettingpaymenttype')}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="">
                        <div className="mt-2">
                            <div className="w-100 d-flex flex-column align-items-center">
                                {this.state.add_visible && (
                                    <div className="card card-payment-type" style={{
                                        width: "43%",
                                        border: '1px dashed #DDDDDD',
                                        backgroundColor: '#fff'
                                    }}>
                                        <div className="px-2 py-1">
                                            <div className="py-1">
                                                <select name="payment_type" id="payment_type"
                                                        className="form-control"
                                                        onChange={this.handleChange}>
                                                    <option value="">{i18n.t('pilihjenisbank')}</option>
                                                    <option value="BCA">{i18n.t('bank_type.bca')}</option>
                                                    <option value="MANDIRI">{i18n.t('bank_type.mandiri')}</option>
                                                    <option value="BNI">{i18n.t('bank_type.bni')}</option>
                                                    <option value="BRI">{i18n.t('bank_type.bri')}</option>
                                                    <option value="BSI">{i18n.t('bank_type.bsi')}</option>
                                                    <option value="CIMB">{i18n.t('bank_type.cimb')}</option>
                                                    <option value="BTN">{i18n.t('bank_type.btn')}</option>
                                                </select>
                                            </div>
                                            <div className="py-1" style={{ marginBottom: 5}}>
                                                <input type="text" inputMode="numeric" name="acc_number"
                                                       id="acc_number"
                                                       onChange={this.handleChange} className="form-control"
                                                       placeholder={i18n.t('ketikannomorrekening')}/>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end" style={{ gap: 3 }}>
                                                <button className="button-cancel-payment-type" onClick={() => this.setState({ add_visible: false, payment_type: '', acc_number: '', })}>{i18n.t('batal')}</button>
                                                <button className="button-save-payment-type" onClick={() => this.handleSubmit()}>{i18n.t('simpan')}</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!this.state.add_visible && (
                                    <div onClick={() => this.setState({add_visible: true})} className="card p-lg-5 card-payment-type"
                                         style={{
                                             width: "43%",
                                             border: '1px dashed #DDDDDD',
                                             backgroundColor: '#f7f7f7',
                                             cursor: "pointer",
                                         }}>
                                        <div className="p-1 d-flex align-items-center justify-content-center">
                                            {i18n.t('tambah')}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {!this.state.loading && this.state.data_payment.length === 0 ? (
                                <></>
                            ) : (
                                <div className="w-100">
                                    <div className="d-flex align-items-center justify-content-center flex-column">
                                        {this.state.loading && (
                                            <div className="d-flex justify-content-center align-items-center w-100">
                                                <Spin size="large" tip="Loading..." style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                    marginBottom: '10px',
                                                    marginTop: '10px'
                                                }}/>
                                            </div>
                                        )}

                                        {this.state.data_payment?.map((item, index) => {
                                            return (
                                                <div key={index} className="card border card-payment-type" style={{ width: "43%" }}>
                                                    {(this.state.edit_visible && item.payment_type_id === this.state.type_id) ? (
                                                        <div className="px-2 py-1">
                                                            <div className="py-1">
                                                                <select name="payment_type" id="payment_type"
                                                                        className="form-control" value={this.state.payment_type}
                                                                        onChange={(e) => this.handleChange(e, index)}>
                                                                    <option value="">{i18n.t('pilihjenisbank')}</option>
                                                                    <option
                                                                        value="BCA">{i18n.t('bank_type.bca')}</option>
                                                                    <option
                                                                        value="MANDIRI">{i18n.t('bank_type.mandiri')}</option>
                                                                    <option
                                                                        value="BNI">{i18n.t('bank_type.bni')}</option>
                                                                    <option
                                                                        value="BRI">{i18n.t('bank_type.bri')}</option>
                                                                    <option
                                                                        value="BSI">{i18n.t('bank_type.bsi')}</option>
                                                                    <option
                                                                        value="CIMB">{i18n.t('bank_type.cimb')}</option>
                                                                    <option
                                                                        value="BTN">{i18n.t('bank_type.btn')}</option>
                                                                </select>
                                                            </div>
                                                            <div className="py-1" style={{marginBottom: 11}}>
                                                                <input type="text" inputMode="numeric" name="acc_number"
                                                                       id="acc_number" value={this.state.acc_number}
                                                                       onChange={(e) => this.handleChange(e, index)}
                                                                       className="form-control"
                                                                       placeholder={i18n.t('ketikannomorrekening')}/>
                                                            </div>
                                                            <div
                                                                className="d-flex align-items-center justify-content-end pt-0"
                                                                style={{gap: 3}}>
                                                            <button className="button-cancel-payment-type" onClick={() => this.setState({
                                                                    edit_visible: false,
                                                                })}>
                                                                    {i18n.t('batal')}
                                                                </button>
                                                                <button className="button-save-payment-type" onClick={() => this.hamdleSubmitEdit(item.payment_type_id)}>
                                                                    {i18n.t('simpan')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="d-flex align-items-center justify-content-end p-3 font-weight-bold">
                                                                {item.payment_type_name}
                                                            </div>
                                                            <div className="d-flex flex-column align-items-start px-3">
                                                                <label style={{fontSize: 12}}>{i18n.t('nomorrekening')}</label>
                                                                <h5 className="font-weight-bold">{item.payment_type_number}</h5>
                                                            </div>
                                                            {/* Edit and Delete buttons */}
                                                            <div className="d-flex align-items-center justify-content-end pb-1 pr-1" style={{gap: 6}}>
                                                                <button className="button-edit" onClick={() => this.loadDetail(item.payment_type_id)}>
                                                                    {i18n.t('edit')}
                                                                </button>
                                                                <button className="button-delete" onClick={() => this.wantDelete(item.payment_type_id)}>
                                                                    {i18n.t('hapus')}
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        })}

                                    </div>
                                </div>

                            )}
                        </div>

                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default AddPaymentType;

