import React, {Component} from 'react'
import {Button, Drawer, Form} from 'antd';
import Swal from "sweetalert2";

class EditLembur extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tgl_lembur: '',
            jam_lembur: '',
            menit_lembur: '',
            desc_lembur: '',
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.props.changeState({
            [name]: value
        })
    }

    handleEdit = () => {
        let form = {
            id: this.props.data.dataEdit.lembur_id,
            tanggal: this.props.data.tgl_lembur,
            durasi: (this.props.data.jam_lembur * 60) + this.props.data.menit_lembur,
            desc: this.props.data.desc_lembur,
        }
        this.props.request("lembur/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        tgl_lembur: '',
                        jam_lembur: '',
                        menit_lembur: '',
                        desc_lembur: ''
                    },() =>this.props.changeState({
                        edit_visible: false,
                        data: [],
                        dataEdit: [],
                        isLoading: true,
                    },() => {
                        this.props.loadData()
                    }))
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    componentDidMount() {
        this.setState({
            tgl_lembur: this.props.data.dataEdit.lembur_tanggal,
            jam_lembur: (this.props.data.dataEdit.lembur_jumlah / 60).toFixed(0),
            menit_lembur: this.props.data.dataEdit.lembur_jumlah - ((this.props.data.dataEdit.lembur_jumlah / 60).toFixed(0)*60),
            desc_lembur: this.props.data.dataEdit.lembur_desc,
        })
    }

    render() {
        return (
            <Drawer
                title="Edit Lembur"
                width={350}
                onClose={() => {
                    this.props.changeState({
                        edit_visible: false,
                        dataEdit: []
                    });
                }}
                visible={this.props.data.edit_visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onChange={() => {
                            this.props.changeState({
                                edit_visible: false,
                                dataEdit: []
                            });
                        }}
                                style={{ marginRight: 8 }}>
                            Batal
                        </Button>
                        <Button onClick={this.handleEdit} type="primary">
                            Simpan
                        </Button>
                    </div>
                }
            >
                {this.props.data.dataEdit &&
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Karyawan: <b>{this.props.data.dataEdit.karyawan_nama}</b></label><br/>
                    </div>
                    <div className="form-group">
                        <label>Tanggal Lembur:</label><br/>
                        <input name="tgl_lembur" defaultValue={this.props.data.dataEdit.lembur_tanggal} className="form-control" onChange={this.handleChange}
                               type="date" required=""/>
                    </div>
                    <div className="form-group">
                        <label>Durasi Lembur:</label><br/>
                            <div className="row">
                                <div className={'col-10'}>
                                    <input name="jam_lembur" defaultValue={(this.props.data.dataEdit.lembur_jumlah / 60).toFixed(0)} className="form-control" onChange={this.handleChange}
                                           type="number" required=""/>
                                </div>
                                <div className={'col-2'} style={{paddingLeft: 0}}>
                                    <label style={{lineHeight: 2, fontSize: 16}}>Jam</label>
                                </div>
                            </div>
                        <div className="row">
                            <div className={'col-10'}>
                                <input name="menit_lembur" defaultValue={this.props.data.dataEdit.lembur_jumlah - ((this.props.data.dataEdit.lembur_jumlah / 60).toFixed(0)*60)} className="form-control" onChange={this.handleChange}
                                       type="number" required=""/>
                            </div>
                            <div className={'col-2'} style={{paddingLeft: 0}}>
                                <label style={{lineHeight: 2, fontSize: 16}}>Menit</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Deskripsi Lembur:</label><br/>
                        <input name="desc_lembur" defaultValue={this.props.data.dataEdit.lembur_desc} className="form-control" onChange={this.handleChange}
                               type="text" required=""/>
                    </div>
                </Form>
                }
            </Drawer>

        )
    }
}

export default EditLembur
