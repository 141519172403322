import React from 'react';
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'
import ArrowLeft from "../assets/icons/arrow-left.svg";
import ArrowRight from "../assets/icons/arrow-right.svg";

const Paging = (props) => {
    return (
        props.pageCount > 1 &&
        <ReactPaginate
            previousLabel={<img src={ArrowLeft} alt='' />}
            nextLabel={<img src={ArrowRight} alt='' />}
            // breakLabel={<a style={{cursor:"default"}}>...</a>}
            // breakClassName={"break-me"}
            pageCount={props.pageCount}
            forcePage={props.currentPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={props.handlePageClick}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link custom-page-link'}
            containerClassName={'pagination my-2'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link custom-page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link custom-prev-next-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link custom-prev-next-link'}
            activeClassName={'active custom-active'}
        />
    );
};

Paging.propTypes = {
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    handlePageClick: PropTypes.func.isRequired
}

export default Paging;