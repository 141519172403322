import React, {Component, Fragment} from "react";
import i18n from "i18next";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import Breadcrumb from "../common/breadcrumb";
import Tour from "reactour";
import Paging from "../paging";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {Empty, Spin, Tooltip} from "antd";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import {pageNumber} from "../../functions/functionGeneral";
import {IMG_HOST} from "../../helper/host";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import Swal from "sweetalert2";
import moment from "moment/moment";
import HistoryPembayaranTagihan from "./history";

class PembayaranTagihanList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            image_name: "",
            tagihan_name: "",
            data: [],
            loading: false,
            modal_preview: false,
            history_visible: false,
            link_id: 0,
            tagihan_id: 0,
            pageCount: 0,
            currentPage: 0,
            paginate: 10,
            totalData: 0,
            perPage: 0,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    handleChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleRefresh() {
        this.setState({
            loading: true,
            data: [],
            search: "",
            currentPage: 0,
        }, () => this.loadData())
    }

    handlePageClick(data) {
        let selected = data.selected;
        this.setState({ currentPage: selected, loading: true, data: [] }, () => this.loadData());
    }

    loadData() {
        this.setState({ data: [], loading: true })
        let formData = {
            paginate: this.state.paginate,
            search: this.state.search,
            page: this.state.currentPage + 1,
        }
        this.props.request('tagihan_siswa/get-tagihan-link', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false,
                    })
                }
            })
    }

    verifTagihan(id) {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinterimatagihan'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        link_id: id || this.state.link_id,
                        tagihan_id: this.state.tagihan_id
                    }
                    this.props.request('tagihan_siswa/verifikasi-tagihan', form, 'POST')
                        .then((response) => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('tagihansiswaberhasilverif'),
                                    icon: 'success',
                                    timer: 1400,
                                })
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: i18n.t('tagihansiswagagalverif'),
                                    icon: 'warning',
                                    showConfirmButton: false,
                                    timer: 1200,
                                })
                            }
                            this.setState({ link_id: 0, tagihan_id: 0 }, () => this.loadData())
                        })
                }
            })
    }

    rejectTagihan(id) {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinrejecttagihanini'),
            icon: "warning",
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        link_id: id || this.state.link_id,
                        tagihan_id: this.state.tagihan_id,
                    }
                    this.props.request('tagihan_siswa/reject-tagihan', form, 'POST')
                        .then((response) => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('berhasilmembatalkantagihan'),
                                    icon: 'success',
                                    timer: 1400,
                                })
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: i18n.t('gagalmembatalkantagihan'),
                                    icon: 'warning',
                                    showConfirmButton: false,
                                    timer: 1200,
                                })
                            }
                            this.setState({ link_id: 0, tagihan_id: 0 }, () => this.loadData())
                        })
                }
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.daftar-pembayaran-tagihan',
                content: 'Daftar Pembayaran Tagihan anda dapat dilihat disini',
            },
        ];
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Daftar Pembayaran Tagihan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t('pembayarantagihan')} - Presensi</title>
                        <meta name="description" content="List Pembayaran Tagihan" />
                    </Helmet>

                    <HistoryPembayaranTagihan
                        history_visible={this.state.history_visible}
                        id={this.state.link_id}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />

                    <Breadcrumb title={i18n.t("pembayarantagihanlist")}  parent={i18n.t("pembayaran")}  setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />

                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header pb-0">
                                <div className="row">
                                    <div className="col-md-5 d-flex align-items-center">
                                        <div className="input-group btn-search-payroll-list">
                                            <input type="text" name="search"
                                                   className={this.state.search !== "" ? "form-control" : "form-control bg-image-search"}
                                                   placeholder={i18n.t('cari')}
                                                   style={{paddingLeft: this.state.search === "" ? "40px" : "10px"}}
                                                   onChange={this.handleChange}
                                                   onKeyUp={(e) => {
                                                       if (e.which === 13) {
                                                           e.preventDefault();
                                                           this.setState({
                                                               loading: true,
                                                               data: []
                                                           }, () => this.loadData())
                                                       }
                                                   }}/>
                                        </div>
                                        <button className="btn ipresens-btn-reset" style={{ marginBottom: "20px", marginLeft: "10px"}} onClick={this.handleRefresh}>
                                            <span className="fa fa-refresh"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body pt-0">
                                {!this.state.loading && this.state.data.length === 0 ? (
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                ): (
                                    <Table className="table table-borderless table-striped">
                                        <Thead>
                                            <Tr>
                                                <Th className="lembur-th-style" style={{ width: "20px" }}>#</Th>
                                                <Th className="lembur-th-style text-center" style={{ width: "200px" }}>{i18n.t('namasiswa')}</Th>
                                                <Th className="lembur-th-style text-center">{i18n.t('namatagihan')}</Th>
                                                <Th className="lembur-th-style text-center" style={{ width: "160px" }}>{i18n.t('buktipembayaran')}</Th>
                                                <Th className="lembur-th-style text-center" style={{ width: "100px" }}>{i18n.t('tanggal')}</Th>
                                                <Th className="lembur-th-style text-center" style={{ width: "140px" }}>{i18n.t('status')}</Th>
                                                <Th className="lembur-th-style text-center" style={{ width: "30px" }}>{i18n.t('aksi')}</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading && (
                                                <Tr>
                                                    <Td colSpan={11}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </Td>
                                                </Tr>
                                            )}
                                            {this.state.data?.map((item, index) => {
                                                no += 1
                                                return (
                                                    <Tr>
                                                        <Td className="lembur-td-style">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Td>
                                                        <Td className="lembur-td-style text-dark text-center" style={{ width: "200px" }}>{item.siswa.karyawan_nama}</Td>
                                                        <Td className="lembur-td-style text-dark text-center">{item.invoice.tagihan_nama}</Td>
                                                        <Td className="lembur-td-style text-center" style={{ width: "160px" }}>
                                                            <a style={{ color: "#8C2BEE" }} onClick={() => this.setState({
                                                                image_name: item.link_payment_proof, tagihan_name: item.invoice.tagihan_nama, modal_preview: true
                                                            })}>{i18n.t('lihatbukti')}</a>
                                                        </Td>
                                                        <Td className="lembur-td-style text-dark text-center">
                                                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                        </Td>
                                                        <Td
                                                            className={`lembur-td-style text-center ${item.link_status !== "selesai" ? "text-warning" : "text-success"}`}
                                                            style={{ textTransform: "capitalize", maxWidth: "140px"}}>
                                                            {item.link_status !== "selesai" ? item.link_status === 'pending' ? i18n.t('pending') : i18n.t('dalamverifikasi') : i18n.t('selesai')}
                                                        </Td>
                                                        <Td className="text-center lembur-td-style" style={{ width: "30px" }}>
                                                            <Tooltip trigger="click" placement="bottomRight" zIndex={9999} color={'#fff'} title={
                                                                <div className="d-flex flex-column p-1">
                                                                    <a className="text-dark" onClick={() => this.setState({
                                                                        history_visible: true, link_id: item.link_id
                                                                    })}>{i18n.t('riwayat')}</a>
                                                                    <a className="text-dark" onClick={() => this.setState({
                                                                        link_id: item.link_id, tagihan_id: item.tagihan_id
                                                                    }, () => this.verifTagihan(item.link_id))}>{i18n.t('verifikasitagihan')}</a>
                                                                    <a className="text-danger" onClick={() => this.setState({
                                                                        link_id: item.link_id, tagihan_id: item.tagihan_id
                                                                    }, () => this.rejectTagihan(item.link_id))}>{i18n.t('dibatalkan')}</a>
                                                                </div>
                                                            }>
                                                                <a><i className="fa fa-ellipsis-h" style={{
                                                                    width: 35,
                                                                    fontSize: 18,
                                                                    padding: 5,
                                                                    color: 'rgb(51 51 51)'
                                                                }}/></a>
                                                            </Tooltip>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                )}
                                <Paging pageCount={this.state.pageCount} currentPage={this.state.currentPage} handlePageClick={this.handlePageClick} className="float-right" />
                            </div>
                        </div>
                    </div>

                    <Modal size="lg" style={{ maxWidth: "600px" }} zIndex={999} isOpen={this.state.modal_preview} toggle={() => this.setState({ modal_preview: !this.state.modal_preview, tagihan_name: '', image_name: '', })}>
                        <ModalHeader toggle={() => this.setState({ modal_preview: !this.state.modal_preview,  tagihan_name: '', image_name: '', })}>
                            <div>{i18n.t('buktipembayaran')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="" style={{ width: "100%" }}>
                                <img src={IMG_HOST + "mitra/tagihan/" + this.state.image_name} alt={this.state.tagihan_name} style={{
                                    objectFit: "cover", width: "100%", height: "100%"
                                }}/>
                            </div>
                        </ModalBody>
                    </Modal>

                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})
export default connect(mapStateToProps)(PembayaranTagihanList);