import React, { Component } from 'react'
import { Button, Drawer, Form } from 'antd';
import Swal from "sweetalert2";

class AddKontrak extends Component {
    constructor() {
        super();
        this.state = {
            contract_type: '',
            contract_start: '',
            contract_duration: '',
            contract_note: '',
            contract_status: '',
            loading: false
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        let form = {
            karyawan: this.props.id,
            type: this.state.contract_type,
            start: this.state.contract_start,
            duration: this.state.contract_duration,
            note: this.state.contract_note,
            status: this.state.contract_status,
        }

        this.props.request("kontrak/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        contract_type: '',
                        contract_start: '',
                        contract_duration: '',
                        contract_note: '',
                        contract_status: ''
                    })
                    this.props.changeState({
                        add_visible: false,
                        data: [],
                        isLoading: true,
                        loading: true,
                    }, () => {
                        this.props.loadData()
                    })
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false
                })
            });
    }

    render() {
        return (
            <Drawer
                title="Tambah Kontrak Baru"
                width={350}
                onClose={() => {
                    this.props.changeState({ add_visible: false, divisi_name: '' });
                }}
                visible={this.props.add_visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button className="btn ipresens-btn-cancel"
                            onClick={() => {
                            this.props.changeState({ add_visible: false, sift_name: '' });
                        }}
                                style={{ marginRight: 8 }}>
                            Batal
                        </button>
                        <button onClick={this.handleSubmit} disabled={this.state.loading} className="btn ipresens-btn-color">
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> Loading</>
                                :
                                "Simpan"
                            }
                        </button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Tipe Kontrak</label>
                        <select
                            name="contract_type"
                            value={this.state.contract_type}
                            className="form-control col-xl-12 col-md-12"
                            onChange={this.handleChange}>
                            <option value="">Pilih Tipe Kontrak</option>
                            <option value="tetap">Tetap</option>
                            <option value="kontrak">Kontrak</option>
                            <option value="non-kontrak">Non-Kontrak</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Tanggal Mulai Kontrak</label>
                        <input style={{ fontSize: "12px" }} name="contract_start" value={this.state.contract_start} className="form-control" onChange={this.handleChange}
                               type="date" required="" />
                    </div>
                    <div className="form-group">
                        <label>Durasi Kontrak <small>(Durasi dalam Bulan)</small></label>
                        <input name="contract_duration" className="form-control col-xl-12 col-md-12" placeholder="Durasi Kontrak..."
                               value={this.state.contract_duration}
                               type="number" required
                               onChange={this.handleChange}/>
                    </div>
                    <div className="form-group">
                        <label>Catatan Kontrak</label>
                        <input name="contract_note" className="form-control col-xl-12 col-md-12" placeholder="Catatan Kontrak..."
                               value={this.state.contract_note}
                               type="text" required=""
                               onChange={this.handleChange}/>
                    </div>
                    <div className="form-group">
                        <label>Status Kontrak</label>
                        <select
                            name="contract_status"
                            className="form-control col-xl-12 col-md-12"
                            value={this.state.contract_status}
                            onChange={this.handleChange}>
                            <option value="">Pilih Status Kontrak</option>
                            <option value="active">Aktif</option>
                            <option value="ended">Berakhir</option>
                        </select>
                    </div>
                </Form>
            </Drawer>

        )
    }
}

export default AddKontrak
