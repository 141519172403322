import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import { API_HOST, DEFAULT_IMG, IMG_HOST } from "../../../helper/host";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Home, Settings, User } from "react-feather";
import { clear_auth, set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import Swal from 'sweetalert2'
import { Select, Spin, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import 'antd/dist/antd.css';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import eyeIcon from "../../../assets/icons/eye.svg"
import eyeIconSlash from "../../../assets/icons/eye-slash-grey.svg"
// import "../../styling.css"
import "../../iPresens-style.css"
import EyeGreyIcon from "../../../assets/icons/eye-grey.svg";
import EyeSlashIcon from "../../../assets/icons/eye-slash-grey.svg";
import i18n from 'i18next';

export class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan: false,
            loading: true,
            data_user: [],
            data: [],
            data_provinsi: [],
            data_kota: [],
            data_kecamatan: [],
            imageUrl: "",
            karyawan_status: "",
            jumlah_karyawan: '',
            max_karyawan: '',
            primary: '',
            perusahaan_nama: "",
            perusahaan_foto: {},
            perusahaan_email: "",
            perusahaan_bidang: "",
            perusahaan_provinsi: "",
            perusahaan_kota: "",
            perusahaan_kecamatan: "",
            perusahaan_alamat: "",
            perusahaan_telp: "",
            perusahaan_no_wa: "",
            p_user_name: "",
            p_user_email: "",
            password_lama: "",
            password_baru: "",
            konfirmasi_password_baru: "",
            edit_profile: false,
            edit_user: false,
            simpan_uaer: false,
            fileList: [],
            guide: false,
            isVisible1: false,
            isVisible2: false,
            isVisible3: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleEditProfile = this.handleEditProfile.bind(this);
        this.handleEditUser = this.handleEditUser.bind(this);
        this.handleSimpanProfile = this.handleSimpanProfile.bind(this);
        this.handleSimpanUser = this.handleSimpanUser.bind(this);
        this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            passwordInput: event.target.value
        })
    }

    handleEditProfile() {
        this.setState({
            edit_profile: true,
        })
    }

    handleEditUser() {
        this.setState({
            edit_user: true,
        })
    }

    handleSimpanUser() {
        this.setState({
            simpan_uaer: true,
        })
        let form = {
            p_user_name: this.state.p_user_name,
            p_user_email: this.state.p_user_email,
            timezone: this.state.timezone,
        }
        this.props.request("edit_user_login", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('pengaturansukses'),
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('pengaturangagal'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    simpan_uaer: false,
                    edit_user: false,
                })
                this.loadData()
                this.loadDataUser()
            });
    }

    handleSimpanProfile() {
        this.setState({
            prosesSimpan: true,
        })
        let form = {
            perusahaan_nama: this.state.perusahaan_nama,
            perusahaan_foto: this.state.perusahaan_foto,
            perusahaan_email: this.state.perusahaan_email,
            perusahaan_user: this.state.perusahaan_user,
            perusahaan_password: this.state.perusahaan_password,
            perusahaan_bidang: this.state.perusahaan_bidang,
            perusahaan_provinsi: this.state.perusahaan_provinsi,
            perusahaan_kota: this.state.perusahaan_kota,
            perusahaan_kecamatan: this.state.perusahaan_kecamatan,
            perusahaan_alamat: this.state.perusahaan_alamat,
            perusahaan_telp: this.state.perusahaan_telp,
            perusahaan_no_wa: this.state.perusahaan_no_wa,
        }
        this.props.request("update_company", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('pengaturansukses'),
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('pengaturangagal'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                    edit_profile: false,
                })
                this.loadDataUser()
            });
    }

    wantResetData() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinhapusprofil'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loading: true,
                    })
                    this.props.request("reset-data", {}, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('berhasilhapusprofil'),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    loading: false,
                                })
                            }
                        });
                }
            })
    }

    handleUpdatePassword() {
        this.setState({
            prosesSimpan: true,
        })
        let form = {
            password_lama: this.state.password_lama,
            password_baru: this.state.password_baru,
            konfirmasi_password_baru: this.state.konfirmasi_password_baru,
        }
        this.props.request("update_password", form)
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('pemberitahuan'),
                        text: i18n.t('berhasilubahpassword'),
                        icon: 'warning',
                        confirmButtonText: i18n.t('loginulang'),
                    })
                        .then((result) => {
                            if (result.isConfirmed) {
                                this.props.clear_auth();
                                this.props.history.push('/login')
                            }
                        })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('gagalubahpassword'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    loadDataUser() {
        this.setState({
            loading: true
        })
        let form = {}
        this.props.request("get_company", form, 'POST')
            .then(response => {
                if (response) {
                    this.setState({
                        data_user: response.data,
                        karyawan_status: response.karyawan,
                        jumlah_karyawan: response.jumlah_karyawan,
                        max_karyawan: response.maks_karyawan,
                        perusahaan_nama: response.data.perusahaan_nama,
                        perusahaan_foto: response.data.perusahaan_foto,
                        perusahaan_email: response.data.perusahaan_email,
                        perusahaan_user: response.data.perusahaan_user,
                        perusahaan_password: response.data.perusahaan_password,
                        perusahaan_bidang: response.data.perusahaan_bidang,
                        perusahaan_alamat: response.data.perusahaan_alamat,
                        perusahaan_telp: response.data.perusahaan_telp,
                        perusahaan_no_wa: response.data.perusahaan_no_wa,
                        data: response.data.user,
                        primary: response.primary,
                        fileList: [
                            response.data.perusahaan_foto ?
                            {
                                uid: "-1",
                                name: response.data.perusahaan_foto,
                                status: "done",
                                url: IMG_HOST + 'mitra/' + response.data.perusahaan_foto
                            }
                            : {}
                        ],
                    })

                }
            })
        this.setState({
            loading: false
        })
    }

    loadData() {
        this.setState({
            loading: true
        })
        let form = {}
        this.props.request("detail_user", form, 'POST')
            .then(response => {
                if (response) {
                    this.setState({
                        data: response.data,
                        p_user_name: response.data.p_user_name,
                        p_user_email: response.data.p_user_email,
                    })
                }
            })

        this.setState({
            loading: false
        })
    }

    loadProvisi() {
        this.setState({
            loading: true
        })
        let form = {}
        this.props.request("get_province", form, 'POST')
            .then(response => {
                if (response) {
                    this.setState({
                        data_provinsi: response.data,
                    })
                }
            })

        this.setState({
            loading: false
        })
    }

    loadKota() {
        this.setState({
            loading: true
        })
        let form = {
            city_province: this.state.perusahaan_provinsi,
        }
        this.props.request("get_city", form, 'POST')
            .then(response => {
                if (response) {
                    this.setState({
                        data_kota: response.data,
                    })
                }
            })

        this.setState({
            loading: false
        })
    }

    loadKecamatan() {
        this.setState({
            loading: true
        })
        let form = {
            city_province: this.state.perusahaan_provinsi,
            city_name: this.state.perusahaan_kota,
        }
        this.props.request("get_subdistrict", form, 'POST')
            .then(response => {
                if (response) {
                    this.setState({
                        data_kecamatan: response.data,
                    })
                }
            })

        this.setState({
            loading: false
        })
    }

    componentDidMount() {
        this.loadDataUser()
        this.loadData()
        this.loadProvisi()
    }

    render() {

        const steps = [
            {
                selector: '.profil-akun',
                content: i18n.t('stepdetailakun'),
            },
            {
                selector: '.status-akun',
                content: i18n.t('stepstatusakun'),
            },
            {
                selector: '.edit-profile',
                content: i18n.t('stepubahakun'),
            },
            {
                selector: '.ganti-password',
                content: i18n.t('stepubahpassword'),
            },
        ];
        const { Option } = Select;
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Pengaturan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t('profile')} - Presensi</title>
                        <meta name="description" content="Detail Absensi" />
                    </Helmet>
                    <Breadcrumb title="Profile" parent="Settings" setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="card">
                                    <div className="card-body">
                                        {this.state.loading ?
                                            <Spin size="large" tip="Sedang memuat data..." style={{
                                                width: '100%',
                                                margin: 'auto',
                                                marginBottom: '10px',
                                                marginTop: '10px'
                                            }} />
                                            :
                                            <>
                                                <div className="profile-details text-center">
                                                    <img
                                                        src={this.state.data_user.perusahaan_foto !== null ? IMG_HOST + 'mitra/' + this.state.data_user.perusahaan_foto : DEFAULT_IMG}
                                                        style={{ width: "90px", height: "90px", objectFit: "cover" }}
                                                        className="img-fluid rounded-circle blur-up lazyloaded" />
                                                    <h5 className="f-w-600 f-16 mb-0">{this.state.data_user.perusahaan_nama}</h5>
                                                    <span>{this.state.data_user.perusahaan_email}</span>
                                                    <div className="social">
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="project-status status-akun">
                                                    <h5 className="f-w-600 f-16">{i18n.t('statusperusahaan')}</h5>
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <h6>{i18n.t('karyawan')} <span
                                                                className="pull-right">{this.state.jumlah_karyawan} / {this.state.max_karyawan} ({this.state.karyawan_status}%)</span>
                                                            </h6>
                                                            <div className="progress sm-progress-bar">
                                                                <div className="progress-bar bg-primary" role="progressbar"
                                                                    style={{ width: this.state.karyawan_status + '%' }}
                                                                    aria-valuenow="25" aria-valuemin="0"
                                                                    aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button"
                                                        className="btn btn-danger btn-sm btn-block mt-2 px-0"
                                                        onClick={() => { this.wantResetData() }}>
                                                        <i className="fa fa-undo"></i>&nbsp;
                                                        {i18n.t('resetdataperusahaan')}</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="card profile-card">
                                    <div className="card-body">
                                        <div>
                                            <Tabs>
                                                <TabList className="nav nav-tabs tab-coupon">
                                                    <Tab className="nav-link">
                                                        <User /> {i18n.t('profileuser')}
                                                    </Tab>
                                                    <Tab className="nav-link">
                                                        <Home /> {i18n.t('profileperusahaan')}
                                                    </Tab>
                                                    <Tab className="nav-link">
                                                        <Settings /> {i18n.t('resetpassword')}
                                                    </Tab>
                                                </TabList>

                                                <TabPanel>
                                                    <form action="">
                                                        <div className="tab-pane fade show active">
                                                            <div className="row">
                                                                <div className="col-4">
                                                                </div>
                                                                <div className="col-8 edit-profile">
                                                                    {this.state.edit_user === false ? (
                                                                        <button type="button"
                                                                            className="btn btn-warning pull-right"
                                                                            onClick={this.handleEditUser}>
                                                                            <i className="fa fa-pencil"></i> {i18n.t('editprofile')}</button>)
                                                                        : (
                                                                            <div>
                                                                                {this.state.simpan_uaer === false ?
                                                                                    <button type="button"
                                                                                        className="btn ipresens-btn-color pull-right"
                                                                                        onClick={this.handleSimpanUser}>
                                                                                        <i className="fa fa-pencil"></i> {i18n.t('simpanprofile')}
                                                                                    </button>
                                                                                    :
                                                                                    <button type="button"
                                                                                        className="btn ipresens-btn-color pull-right">
                                                                                        <i className="fa fa-refresh fa-spin"></i> Loading
                                                                                    </button>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="table-responsive profile-table detail-akun">
                                                                <table className="table table-responsive">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <b>{i18n.t('nama')} </b>
                                                                            </td>
                                                                            {this.state.edit_user === false ? (
                                                                                <td>{this.state.data && this.state.data.p_user_name}</td>)
                                                                                : (
                                                                                    <td><input type="text"
                                                                                        className="form-control"
                                                                                        name="p_user_name"
                                                                                        onChange={this.handleChange}
                                                                                        defaultValue={this.state.p_user_name} />
                                                                                    </td>)
                                                                            }
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <b>{i18n.t('email')} </b>
                                                                            </td>
                                                                            {this.state.edit_user === false ? (
                                                                                <td>{this.state.data && this.state.data.p_user_email}</td>)
                                                                                : (
                                                                                    <td><input name="p_user_email"
                                                                                        onChange={this.handleChange}
                                                                                        type="email"
                                                                                        className="form-control"
                                                                                        defaultValue={this.state.p_user_email} />
                                                                                    </td>)
                                                                            }
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <b>{i18n.t('zonawaktu')} </b>
                                                                            </td>
                                                                            {this.state.edit_user === false ?
                                                                                (
                                                                                    <td>
                                                                                        {
                                                                                            this.state.data_user &&
                                                                                            this.state.data_user.perusahaan_timezone &&
                                                                                            String(this.state.data_user.perusahaan_timezone).toUpperCase()
                                                                                        }
                                                                                    </td>
                                                                                ) : (
                                                                                    <td>
                                                                                        <select name="timezone"
                                                                                                className="form-control"
                                                                                                onChange={this.handleChange}>
                                                                                            {[
                                                                                                {
                                                                                                    value: "gmt-12",
                                                                                                    label: "GMT-12"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt-11",
                                                                                                    label: "GMT-11"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt-10",
                                                                                                    label: "GMT-10"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt-9",
                                                                                                    label: "GMT-9"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt-8",
                                                                                                    label: "GMT-8"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt-7",
                                                                                                    label: "GMT-7"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt-6",
                                                                                                    label: "GMT-6"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt-5",
                                                                                                    label: "GMT-5"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt-4",
                                                                                                    label: "GMT-4"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt-3",
                                                                                                    label: "GMT-3"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt-2",
                                                                                                    label: "GMT-2"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt-1",
                                                                                                    label: "GMT-1"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt+0",
                                                                                                    label: "GMT+0"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt+1",
                                                                                                    label: "GMT+1"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt+2",
                                                                                                    label: "GMT+2"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt+3",
                                                                                                    label: "GMT+3"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt+4",
                                                                                                    label: "GMT+4"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt+5",
                                                                                                    label: "GMT+5"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt+6",
                                                                                                    label: "GMT+6"
                                                                                                },
                                                                                                {
                                                                                                    value: "wib",
                                                                                                    label: "GMT+7 (WIB)"
                                                                                                },
                                                                                                {
                                                                                                    value: "wita",
                                                                                                    label: "GMT+8 (WITA)"
                                                                                                },
                                                                                                {
                                                                                                    value: "wit",
                                                                                                    label: "GMT+9 (WIT)"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt+10",
                                                                                                    label: "GMT+10"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt+11",
                                                                                                    label: "GMT+11"
                                                                                                },
                                                                                                {
                                                                                                    value: "gmt+12",
                                                                                                    label: "GMT+12"
                                                                                                },
                                                                                            ].map((item) => (
                                                                                                <option
                                                                                                    key={item.value}
                                                                                                    value={item.value}
                                                                                                    selected={this.state.data_user.perusahaan_timezone === item.value}
                                                                                                >
                                                                                                    {item.label}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </td>
                                                                                )
                                                                            }
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </TabPanel>
                                                <TabPanel>
                                                    <form action="">
                                                        <div className="tab-pane fade show active">
                                                            <div className="row">
                                                                <div className="col-4">
                                                                </div>
                                                                <div className="col-8 edit-profile">{
                                                                    this.state.data && this.state.data.p_user_primary == '0' ? '' :
                                                                        <div>
                                                                        {this.state.edit_profile === false ? (
                                                                                    <button type="button"
                                                                                            className="btn btn-warning pull-right"
                                                                                            onClick={this.handleEditProfile}>
                                                                                        <i className="fa fa-pencil"></i>
                                                                                        {i18n.t('editprofile')}
                                                                                    </button>)
                                                                                : (
                                                                                    <div>
                                                                                        {this.state.prosesSimpan === false ?
                                                                                            <button type="button"
                                                                                                className="btn ipresens-btn-color pull-right"
                                                                                                onClick={this.handleSimpanProfile}>
                                                                                                <i className="fa fa-pencil"></i>
                                                                                                {i18n.t('simpanprofile')}
                                                                                            </button>
                                                                                            :
                                                                                            <button type="button" className="btn ipresens-btn-color pull-right">
                                                                                                <i className="fa fa-refresh fa-spin"></i> Loading
                                                                                            </button>
                                                                                        }
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="table-responsive profile-table detail-akun">
                                                                <table className="table table-responsive">
                                                                    <tbody>
                                                                        {this.state.edit_profile === true ?
                                                                            (
                                                                                <tr>
                                                                                    <td>
                                                                                        <b>{i18n.t('fotoprofileperusahaan')}</b>
                                                                                    </td>
                                                                                    <td>
                                                                                        <ImgCrop
                                                                                            width={100}
                                                                                            height={100}>
                                                                                            <Upload
                                                                                                action={API_HOST}
                                                                                                listType="picture-card"
                                                                                                fileList={this.state.fileList}
                                                                                                onChange={(file) => {
                                                                                                    this.setState({ fileList: file.fileList })
                                                                                                    if (file.fileList.length > 0) {
                                                                                                        let files = file.file.originFileObj
                                                                                                        let reader = new FileReader()

                                                                                                        reader.readAsDataURL(files)
                                                                                                        reader.onloadend = () => {
                                                                                                            this.setState({
                                                                                                                perusahaan_foto: {
                                                                                                                    name: file.file.name,
                                                                                                                    file: reader.result
                                                                                                                }
                                                                                                            })
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        this.setState({ perusahaan_foto: [] })
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                {this.state.fileList.length >= 1 ? null : i18n.t('tambahimage')}
                                                                                            </Upload>
                                                                                        </ImgCrop>
                                                                                    </td>
                                                                                </tr>
                                                                            ) : null}
                                                                        <tr>
                                                                            <td>
                                                                                <b>{i18n.t('namaperusahaan')}</b>
                                                                            </td>
                                                                            {this.state.edit_profile === false ? (
                                                                                <td>{this.state.data_user.perusahaan_nama}</td>)
                                                                                : (
                                                                                    <td><input type="text"
                                                                                        className="form-control"
                                                                                        name="perusahaan_nama"
                                                                                        onChange={this.handleChange}
                                                                                        defaultValue={this.state.data_user.perusahaan_nama} />
                                                                                    </td>)
                                                                            }
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <b>{i18n.t('emailperusahaan')}</b>
                                                                            </td>
                                                                            {this.state.edit_profile === false ? (
                                                                                <td>{this.state.data_user.perusahaan_email}</td>)
                                                                                : (
                                                                                    <td><input name="perusahaan_email"
                                                                                        onChange={this.handleChange}
                                                                                        type="email"
                                                                                        className="form-control"
                                                                                        defaultValue={this.state.data_user.perusahaan_email} />
                                                                                    </td>)
                                                                            }
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <b>{i18n.t('bidangperusahaan')}</b>
                                                                            </td>
                                                                            {this.state.edit_profile === false ? (
                                                                                <td>{i18n.t(this.state.data_user.perusahaan_bidang)}</td>)
                                                                                : (
                                                                                    <td><input type="text"
                                                                                        className="form-control"
                                                                                        name="perusahaan_bidang"
                                                                                        onChange={this.handleChange}
                                                                                        defaultValue={this.state.data_user.perusahaan_bidang} />
                                                                                    </td>)
                                                                            }
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <b>{i18n.t('provinsiperusahaan')}</b>
                                                                            </td>
                                                                            {this.state.edit_profile === false ? (
                                                                                <td>{this.state.data_user.perusahaan_provinsi}</td>)
                                                                                : (
                                                                                    <td> <Select showSearch
                                                                                        optionFilterProp="children"
                                                                                        filterOption={(input, option) =>
                                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                        }

                                                                                        placeholder={i18n.t('pilihprovinsi')}
                                                                                        name="perusahaan_provinsi"
                                                                                        className="form-control col-xl-12 col-md-12"
                                                                                        onChange={(value) => {
                                                                                            this.setState({ perusahaan_provinsi: value }, () => {
                                                                                                this.loadKota()
                                                                                            })
                                                                                        }}
                                                                                        defaultValue={this.state.data_user.perusahaan_provinsi}
                                                                                    >
                                                                                        {this.state.data_provinsi.map((item, index) => {
                                                                                            return (
                                                                                                <Option value={item.city_province}>{item.city_province}</Option>
                                                                                            )
                                                                                        })}
                                                                                    </Select>
                                                                                    </td>)
                                                                            }
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <b>{i18n.t('kotaperusahaan')}</b>
                                                                            </td>
                                                                            {this.state.edit_profile === false ? (
                                                                                <td>{this.state.data_user.perusahaan_kota}</td>)
                                                                                : (
                                                                                    <td>
                                                                                        <Select showSearch
                                                                                            optionFilterProp="children"
                                                                                            filterOption={(input, option) =>
                                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                            }

                                                                                            placeholder={i18n.t('pilihkota')}
                                                                                            name="perusahaan_kota"
                                                                                            className="form-control col-xl-12 col-md-12"
                                                                                            onChange={(value) => {
                                                                                                this.setState({ perusahaan_kota: value }, () => {
                                                                                                    this.loadKecamatan()
                                                                                                })
                                                                                            }}
                                                                                            defaultValue={this.state.data_user.perusahaan_kota}
                                                                                        >
                                                                                            {this.state.data_kota.map((item, index) => {
                                                                                                return (
                                                                                                    <Option value={item.city_name}>{item.city_name}</Option>
                                                                                                )
                                                                                            })}
                                                                                        </Select>
                                                                                    </td>)
                                                                            }
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <b>{i18n.t('kecamatanperusahaan')}</b>
                                                                            </td>
                                                                            {this.state.edit_profile === false ? (
                                                                                <td>{this.state.data_user.perusahaan_kecamatan}</td>)
                                                                                : (
                                                                                    <td>
                                                                                        <Select showSearch
                                                                                            optionFilterProp="children"
                                                                                            filterOption={(input, option) =>
                                                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                            }

                                                                                            placeholder={i18n.t('pilihkecamatan')}
                                                                                            name="perusahaan_kecamatan"
                                                                                            className="form-control col-xl-12 col-md-12"
                                                                                            onChange={(value) => {
                                                                                                this.setState({ perusahaan_kecamatan: value }, () => {
                                                                                                    this.loadKecamatan()
                                                                                                })
                                                                                            }}
                                                                                            defaultValue={this.state.data_user.perusahaan_kecamatan}
                                                                                        >
                                                                                            {this.state.data_kecamatan.map((item, index) => {
                                                                                                return (
                                                                                                    <Option value={item.city_subdistrict}>{item.city_subdistrict}</Option>
                                                                                                )
                                                                                            })}
                                                                                        </Select>
                                                                                    </td>)
                                                                            }
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <b>{i18n.t('alamatperusahaan')}</b>
                                                                            </td>
                                                                            {this.state.edit_profile === false ? (
                                                                                <td>{this.state.data_user.perusahaan_alamat}</td>)
                                                                                : (
                                                                                    <td><textarea className="form-control" cols="20" rows="8"
                                                                                        name="perusahaan_alamat"
                                                                                        onChange={this.handleChange}
                                                                                        defaultValue={this.state.data_user.perusahaan_alamat}
                                                                                    ></textarea>
                                                                                    </td>)
                                                                            }
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <b>{i18n.t('telpperusahaan')}</b>
                                                                            </td>
                                                                            {this.state.edit_profile === false ? (
                                                                                <td>{this.state.data_user.perusahaan_telp}</td>)
                                                                                : (
                                                                                    <td><input type="number"
                                                                                        className="form-control"
                                                                                        name="perusahaan_telp"
                                                                                        onChange={this.handleChange}
                                                                                        defaultValue={this.state.data_user.perusahaan_telp} />
                                                                                    </td>)
                                                                            }
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                <b>{i18n.t('waperusahaan')}</b>
                                                                            </td>
                                                                            {this.state.edit_profile === false ? (
                                                                                <td>{this.state.data_user.perusahaan_no_wa}</td>)
                                                                                : (
                                                                                    <td><input type="number"
                                                                                        className="form-control"
                                                                                        name="perusahaan_no_wa"
                                                                                        onChange={this.handleChange}
                                                                                        defaultValue={this.state.data_user.perusahaan_no_wa} />
                                                                                    </td>)
                                                                            }
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </TabPanel>
                                                <TabPanel>
                                                    <div className="account-setting deactivate-account">
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="input-group btn-password-profile mt-5">
                                                                    <label>{i18n.t('passlama')}</label>
                                                                    <input type={this.state.isVisible1 ? "text" : "password"} name="password_lama"
                                                                        className="form-control input-password" onChange={this.handleChange}
                                                                        placeholder={i18n.t('passlama')} /><br />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <img className="show-hide-button" style={{ width: "16px", height: "16px", backgroundColor: "#E9ECEF" }} src={this.state.isVisible1 ? eyeIcon : eyeIconSlash} onClick={() => this.setState({
                                                                                isVisible1: !this.state.isVisible1
                                                                            })}  />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="input-group btn-password-profile mt-5">
                                                                    <label>{i18n.t('passbaru')}</label>
                                                                    <input type={this.state.isVisible2 ? "text" : "password"} name="password_baru"
                                                                        className="form-control input-password" onChange={this.handleChange}
                                                                        placeholder={i18n.t('passbaru')} /><br />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <img className="show-hide-button" style={{ width: "16px", height: "16px", backgroundColor: "#E9ECEF" }} src={this.state.isVisible2 ? eyeIcon : eyeIconSlash} onClick={() => this.setState({
                                                                                isVisible2: !this.state.isVisible2
                                                                            })}  />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="input-group btn-password-profile mt-5">
                                                                    <label>{i18n.t('konfirmpassbaru')}</label>
                                                                    <input type={this.state.isVisible3 ? "text" : "password"} name="konfirmasi_password_baru"
                                                                        className="form-control input-password" onChange={this.handleChange}
                                                                        placeholder={i18n.t('konfirmpassbaru')} />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <img className="show-hide-button" style={{ width: "16px", height: "16px", backgroundColor: "#E9ECEF" }} src={this.state.isVisible3 ? eyeIcon : eyeIconSlash} onClick={() => this.setState({
                                                                                isVisible3: !this.state.isVisible3
                                                                            })}  />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.prosesSimpan === false ?
                                                            <button type="button" className="btn ipresens-btn-color pull-right"
                                                                onClick={this.handleUpdatePassword}>{i18n.t('gantipass')}
                                                            </button>
                                                            :
                                                            <button type="button" className="btn ipresens-btn-color pull-right"><i className="fa fa-refresh fa-spin"></i> Loading
                                                            </button>
                                                        }
                                                    </div>
                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    };
}

const mapStateToProps = state => ({
    api_token: state.Auth.api_token,
    user: state.Auth.user
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, auth_key, data) => dispatch(set_auth(api_token, auth_key, data)),
    clear_auth: () => dispatch(clear_auth())
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
