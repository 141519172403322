import React, {Component} from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import logo from "../../assets/images/home/logo.svg";

export class FrontHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggle: false,
        }
    }

    toggleMenu = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    }

    render() {
        return(
            <header id="sticky" className="sticky">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col p-0">
                            <div className="top-header padding-body">
                                <div className="logo pl-2">
                                    <a className="nav-link navbar-brand" href="/"><img src={logo} alt="logo" /></a>
                                </div>
                                <div className="main-menu mx-auto" id="nav">
                                    <nav id="navbar-example2" className="navbar navbar-expand-lg navbar-light">
                                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                                data-target="#scroll-spy"
                                                aria-controls="scroll-spy" aria-expanded="false"
                                                aria-label="Toggle navigation"
                                                onClick={() => this.toggleMenu()}>
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <div
                                            className={`collapse navbar-collapse ${this.state.toggle ? 'show' : ''}`}
                                            id="scroll-spy">
                                            <ul className="navbar-nav mx-auto nav">
                                                <li className="nav-item">
                                                    <a className="nav-link fw-400"
                                                       href='#'>Home</a>
                                                </li>
                                                <li className="nav-item">
                                                    <AnchorLink className="nav-link fw-400" href='#fitur'>Fitur</AnchorLink>
                                                </li>
                                                <li className="nav-item">
                                                    <AnchorLink className="nav-link fw-400"
                                                                href='#harga'>Harga</AnchorLink>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link fw-400" href='/blog'>Blog</a>
                                                </li>
                                                <li className="nav-item">
                                                    <AnchorLink className="nav-link fw-400"
                                                                href='#kontak'>Kontak</AnchorLink>
                                                </li>
                                                <li className="nav-item">
                                                    <span><a className="nav-link fw-400" href="/login">Masuk</a></span>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <div className="p-2">
                                    <form target="_blank" className="form-inline my-lg-0"
                                          action="/register">
                                        <button className="btn my-sm-0 purchase-btn hoverable-btn">Demo</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default FrontHeader
