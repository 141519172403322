import React, {Component} from 'react'
import {Button, Drawer, Form} from 'antd';
import Swal from "sweetalert2";
import i18n from 'i18next';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class Edit extends Component {
    constructor() {
        super();
        this.state = {
            jabatan_nama: '',
            loading: false
        }
    }

    simpanJabatan = () => {
        let form = {
            id: this.props.data.jabatan_id,
            name: this.props.data.jabatan_nama,
        }
        this.props.request("jabatan/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    const filter_data = JSON.parse(localStorage.getItem('Presensi-Company-Data'))
                    if (filter_data && filter_data._jabatan) {
                        const updateJabatan = filter_data._jabatan.map(jabatan =>
                            jabatan.jabatan_id === this.props.data.jabatan_id ? {
                                ...jabatan,
                                jabatan_nama: this.props.data.jabatan_nama,
                            } : jabatan)
                        const updateFilter = {...filter_data, _jabatan: updateJabatan}
                        localStorage.setItem('Presensi-Company-Data', JSON.stringify(updateFilter))
                    }
                    this.setState({
                        jabatan_nama : ''
                    },() =>this.props.changeState({
                        visable: false,
                        jabatan_nama : '',
                        data: [],
                        isLoading: true,
                    },() => {
                        this.props.loadData()
                    }))
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }


    render() {
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.data.visable} toggle={() => this.props.changeState({ visable: false })} style={{ maxWidth: "1000px" }}>
                <ModalHeader toggle={() => this.props.changeState({ visable: false })}>
                    <div className='text-dark' style={{
                        fontWeight: 'bold', fontSize: "18px"
                    }}>{i18n.t("editjabatan")}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="">
                        <Form layout="vertical" hideRequiredMark>
                            <div className="form-group">
                                <label>{i18n.t("namajabatan")}</label>
                                <input name="jabatan_nama" className="form-control col-xl-12 col-md-12"
                                       placeholder={i18n.t("placeholdernamajabatan")}
                                       type="text" required="" value={this.props.data.jabatan_nama}
                                       onChange={(event) => {
                                           const target = event.target;
                                           const value = target.value;
                                           const name = target.name;
                                           this.props.changeState({
                                               [name]: value
                                           });
                                       }}/>
                            </div>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({visable: false, desc: ''});
                        }}
                                style={{marginRight: 8}}>
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color"
                                disabled={this.state.loading} onClick={this.simpanJabatan}>
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

export default Edit
