import React, { Component, Fragment } from 'react';
import { Empty, Spin, Tooltip } from 'antd';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Modal as ModalRs,
    InputGroup,
    InputGroupAddon, Input
} from "reactstrap";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { Helmet } from "react-helmet";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { pageNumber } from "../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux"
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import SaveIcon from "../../assets/icons/save.svg";
import i18n from 'i18next';
import {BACKEND_API_HOST, DEFAULT_IMG} from "../../helper/host";

//css ipresens
import "../iPresens-style.css";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import TimeInput from "../../helper/time_input";
import ImportIcon from "../../assets/icons/import-icon.svg";
import moment from "moment";
import swal from "sweetalert2";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ArrowUpIcon from "../../assets/icons/arrow-up.svg";
import ArrowUpIconCustom1 from "../../assets/icons/arrow-up-custom-1.svg";
import ArrowUpIconCustom2 from "../../assets/icons/arrow-up-custom-2.svg";
import ArrowDownIcon from "../../assets/icons/arrow-down.svg";
import ArrowDownIconCustom1 from "../../assets/icons/arrow-down-custom-1.svg";
import ArrowDownIconCustom2 from "../../assets/icons/arrow-down-custom-2.svg";
import MoreIcon from "../../assets/icons/more.svg";
import PlusWhiteIcon from "../../assets/icons/plus-white.svg";
import WarningIcon from "../../assets/icons/warning-icon.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {DateRange} from "react-date-range";

export class ListAgenda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            prosesSimpan: false,
            agenda: [],
            agendaDetail: [],
            agendaData: [],
            agendaAdmin: [],
            agendaAll: [],
            data_import: [],
            item_import: [],
            dataHistory: [],
            agendaModal: false,
            pagination: 2,
            perPage: 0,
            currentPage: 0,
            currentPageHistory: 0,
            modalDetail: false,
            idDetail: "",
            idDel: "",
            modalAdd: false,
            modalEdit: false,
            modalImport: false,
            agenda_title    : '',
            agenda_note     : '',
            agenda_type     : '',
            agenda_date     : '',
            agenda_start_time: '',
            agenda_end_time: '',
            loading_imp: false,
            checked_all: false,
            checked: [],
            totalData: '',
            sort_tanggal: "",
            isClicked: "",
            modalExport: false,
            exportStartDate: "",
            exportEndDate: "",
            start_date: new Date(),
            end_date: new Date(),
            reactDateRange: false,
            valueDate: ""
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.exportAgenda = this.exportAgenda.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handlePageImport(data_import) {
        let selected = data_import.selected;
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value,
            sort_tanggal: "",
            isClicked: ""
        }, () => this.loadData())
    }

    handleSort = (value) => {
        this.setState({
            sort_tanggal: value
        }, () => this.loadData())
    }

    handleIconClick = (type) => {
        this.setState((prevState) => ({
            isClicked: prevState.isClicked === type ? type : type
        }));
    };

    handleCheckBox() {

    }

    resetFilter(event) {
        this.setState({
            filter_judul: '',
            sort_tanggal: '',
            isClicked: '',
            agendaData: [],
            agendaAdmin: [],
            agendaAll: [],
            loading: true
        }, () => this.loadData())
    }

    handleCancel = e => {
        this.setState({
            modalExport: false,
            valueDate: "",
            exportStartDate: "",
            exportEndDate: "",
            start_date: new Date(),
            end_date: new Date()
        });
    };

    simpanAgenda = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            title          : this.state.agenda_title,
            note           : this.state.agenda_note,
            date           : this.state.agenda_date,
            type           : this.state.agenda_type,
            start          : this.state.agenda_start_time,
            end            : this.state.agenda_end_time
        }

        this.props.request("agenda/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title               : i18n.t('berhasil'),
                        text                : i18n.t('databerhasilditambahkan'),
                        icon                : 'success',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                    this.props.history.push('/agenda/list-agenda')
                }else{
                    Swal.fire({
                        title               : i18n.t('gagal'),
                        text                : i18n.t('datagagalditambahkan'),
                        icon                : 'warning',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                }
                this.setState({
                    prosesSimpan: false,
                    modalAdd: false,
                    agenda_title: '',
                    agenda_note: '',
                    agenda_type: '',
                    agenda_date: '',
                    agenda_start_time: '',
                    agenda_end_time: '',
                }, () => this.loadData())
            });
    }

    simpanUbahAgenda = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id             : this.state.idDetail,
            title          : this.state.agenda_title,
            note           : this.state.agenda_note,
            date           : this.state.agenda_date,
            type           : this.state.agenda_type,
            start          : this.state.agenda_start_time,
            end            : this.state.agenda_end_time
        }

        this.props.request("agenda/edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title               : i18n.t('berhasil'),
                        text                : i18n.t('berhasilmengubahdata'),
                        icon                : 'success',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                    this.props.history.push('/agenda/list-agenda')
                }else{
                    Swal.fire({
                        title               : i18n.t('gagal'),
                        text                : i18n.t('gagalmengubahdata'),
                        icon                : 'warning',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                }
                this.setState({
                    prosesSimpan: false,
                    modalEdit: false,
                }, () => this.loadData())
            });
    }

    wantDelete() {
        Swal.fire({
            title: i18n.t('pemberitahuan'),
            text: i18n.t('yakinmenghapusagenda'),
            icon: 'warning',
            confirmButtonText: i18n.t('ya'),
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        data: [],
                        loading: true
                    })
                    this.props.request("agenda/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t('berhasil'),
                                    text: i18n.t('databerhasildihapus'),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            } else {
                                Swal.fire({
                                    title: i18n.t('gagal'),
                                    text: i18n.t('datagagaldihapus'),
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            filter_judul : this.state.filter_judul,
            paginate     : 10,
            page         : this.state.currentPage + 1,
            sort_tanggal : this.state.sort_tanggal === "t_asc" || this.state.sort_tanggal === "t_desc" ? this.state.sort_tanggal : "",
        }
        this.props.request("agenda/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        agendaAdmin: response.admin,
                        agendaData: response.data,
                        agendaAll: response.all,
                        loading: false
                    })
                }
            });
    }

    loadDetail() {
        let form = {
            id : this.state.idDetail
        }
        this.props.request("agenda/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        agendaDetail: response.data,
                        agenda_title: response.data.agenda_title,
                        agenda_type: response.data.agenda_type,
                        agenda_date: response.data.agenda_date,
                        agenda_note: response.data.agenda_note,
                        agenda_start_time: response.data.agenda_start_time,
                        agenda_end_time: response.data.agenda_end_time,
                        loading: false,
                        modalDetail: true,
                    })
                }
            });
    }

    loadEditDetail() {
        let form = {
            id : this.state.idDetail
        }
        this.props.request("agenda/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        agenda: response.data,
                        agenda_title: response.data.agenda_title,
                        agenda_type: response.data.agenda_type,
                        agenda_date: response.data.agenda_date,
                        agenda_note: response.data.agenda_note,
                        agenda_start_time: response.data.agenda_start_time,
                        agenda_end_time: response.data.agenda_end_time,
                        modalEdit: true
                    })
                }
            });
    }

    loadKaryawan(){
        let form = {}
        this.props.request("karyawan/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        loading         : false
                    })
                }
            });
    }

    exportAgenda() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'agenda/export?token=' + AuthKey + '&agendaStartDate=' + (this.state.exportStartDate === true ? this.state.exportStartDate : moment(this.state.start_date).format("YYYY-MM-DD")) + '&agendaEndDate=' + (this.state.exportEndDate === true ? this.state.exportEndDate : moment(this.state.end_date).format("YYYY-MM-DD")), "_blank")
    }

    importAgenda = () => {
        this.setState({ loading_imp: true })
        let form = {
            paginate: 5,
            page: this.state.currentPage + 1,
        }
        this.props.request("agenda/import", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_import: response.data.data,
                        totalData: response.data.total,
                        loading_imp: false,
                        modalImport: true
                    })
                }
            })
    }

    submitImportAgenda = () => {
        let form = {
            agenda_ids: this.state.checked
        }
        this.props.request('agenda/import-to-list', form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        modalImport: false,
                        data: [], loading: true,
                    })
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('importagendaalert'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.setState({
                    checked_all: false,
                    checked: [], loading: false,
                }, () => this.loadData())
            })
    }

    handleCheckAll(e) {
        let value = e.target.checked
        if (value) {
            this.state.data_import.map((item) => {
                if (!this.state.checked.includes(item.agenda_id)) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            checked: [...prevState.checked, item.agenda_id]
                        }
                    }, () => {
                        this.setState({
                            checked_all: true,
                        })
                    })
                }
            })
        } else {
            this.setState({
                checked_all: false,
                checked: []
            })
        }
    }

    handleCheckBoxChange(id) {
        this.state.checked.includes(id) ?
            this.setState(prevState => ({
                checked: prevState.checked.filter(currId => currId !== id),
            }), () => {
                if (this.state.checked.length === this.state.totalData) {
                    this.setState({
                        checked_all: true,
                    })
                } else {
                    this.setState({
                        checked_all: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checked: [...prevState.checked, id],
            }), () => {
                if (this.state.checked.length === this.state.totalData) {
                    this.setState({
                        checked_all: true
                    })
                } else {
                    this.setState({
                        checked_all: false
                    })
                }
            })
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        },()=>{
            let form = {
                id: id ? id : this.state.agenda_id,
                paging : this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("agenda/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                    else {
                        this.setState({
                            modalHistory: false,
                            agenda_id: ''
                        });
                    }
                });
        })
    }

    componentDidMount() {
        this.loadData()
        this.loadKaryawan()
    }

    render() {
        let no = 0;
        const selectionRange = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            key: 'selection',
        }
        const tipe = this.props.user.perusahaan.perusahaan_type === "sekolah";
        const steps = [
            {
                selector: '.tambah-agenda',
                content: 'Anda dapat membuat Agenda disini',
            },
            {
                selector: '.daftar-agenda',
                content: 'Daftar Agenda anda dapat dilihat disini',
            },
        ];
        moment.locale(i18n.language);
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Agenda") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Agenda - Presensi</title>
                        <meta name="description" content="Daftar Agenda" />
                    </Helmet>

                    <Modal size='lg' zIndex={999} isOpen={this.state.modalDetail} toggle={() => this.setState({
                        modalDetail: false
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalDetail: false
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("detailagenda")}</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row mb-3'>
                                <div className='col-md-12' style={{
                                    fontSize: "20px",
                                    fontWeight: 700,
                                    lineHeight: "32px",
                                    color: "#143B5E"
                                }}>{this.state.judulDetail}</div>
                            </div>
                            <div className='row'>
                                <div className='card-body px-3'>
                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                        <div className='col-md-5 font-detail-label pr-0'>{i18n.t("judulagenda")}</div>
                                        <div className='col-md-1 px-0' style={{maxWidth: "20px"}}>:</div>
                                        <div
                                            className='col-md-6 px-0 font-detail-value'>{this.state.agendaDetail ? this.state.agendaDetail.agenda_title : "-"}</div>
                                    </div>
                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                        <div className='col-md-5 font-detail-label pr-0'>{i18n.t("tipeagenda")}</div>
                                        <div className='col-md-1 px-0' style={{maxWidth: "20px"}}>:</div>
                                        <div className='col-md-6 px-0 font-detail-value'>
                                            {this.state.agendaDetail.agenda_type === "liburnasional" ? i18n.t("liburnasional") : this.state.agendaDetail.agenda_type === "liburperusahaan" ? i18n.t("liburperusahaan") : this.state.agendaDetail.agenda_type === "acara" ? i18n.t("acara") : ""}
                                        </div>
                                    </div>
                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                        <div className='col-md-5 font-detail-label pr-0'>{i18n.t("tanggalagenda")}</div>
                                        <div className='col-md-1 px-0' style={{maxWidth: "20px"}}>:</div>
                                        <div className='col-md-6 px-0 font-detail-value'>
                                            {this.state.agendaDetail.agenda_date}
                                        </div>
                                    </div>
                                    <div className='row d-flex justify-content-start flex-nowrap'>
                                        <div className='col-md-5 font-detail-label pr-0'>{i18n.t("catatanagenda")}</div>
                                        <div className='col-md-1 px-0' style={{maxWidth: "20px"}}>:</div>
                                        <div
                                            className='col-md-6 px-0 font-detail-value'>{this.state.agendaDetail ? this.state.agendaDetail.agenda_note : "-"}</div>
                                    </div>
                                    {this.state.agenda_type === 'acara' && (
                                        <div className='row d-flex justify-content-start flex-nowrap'>
                                            <div
                                                className='col-md-5 font-detail-label pr-0'>{i18n.t("waktuagenda")}</div>
                                            <div className='col-md-1 px-0' style={{maxWidth: "20px"}}>:</div>
                                            <div
                                                className='col-md-6 px-0 font-detail-value'>{this.state.agenda_start_time ? this.state.agenda_start_time : ""} - {this.state.agenda_end_time ? this.state.agenda_end_time : ""}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Modal size='lg' zIndex={999} isOpen={this.state.modalAdd} toggle={() => this.setState({
                        modalAdd: false, agenda_title: '',
                        agenda_note: '',
                        agenda_type: '',
                        agenda_date: '',
                    })} style={{maxWidth: "1000px"}}>
                        <ModalHeader toggle={() => this.setState({
                            modalAdd: false, agenda_title: '',
                            agenda_note: '',
                            agenda_type: '',
                            agenda_date: '',
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("tambahagenda")}</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <form className="needs-validation user-add" noValidate="">
                                        <div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("judulagenda")}</label>
                                                <div>
                                                    <input type="text" name="agenda_title"
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder={i18n.t("judulagenda")}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("catatanagenda")}</label>
                                                <div>
                                                    <input type="text" name="agenda_note"
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder={i18n.t("catatanagenda")}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("tipeagenda")}</label>
                                                <div>
                                                    <select id="agenda_type" name="agenda_type"
                                                            className="form-control col-xl-8 col-md-7"
                                                            value={this.state.agenda_type}
                                                            onChange={this.handleChange}>
                                                        <option value="">{i18n.t("pilihtipe")}</option>
                                                        <option value="liburnasional">{i18n.t("liburnasional")}</option>
                                                        <option
                                                            value="liburperusahaan">{i18n.t("liburperusahaan")}</option>
                                                        <option value="acara">{i18n.t("acara")}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("tanggalagenda")}</label>
                                                <input type="date" name="agenda_date"
                                                       className="form-control col-xl-8 col-md-7"
                                                       defaultValue={this.state.agenda_date}
                                                       onChange={this.handleChange}/>
                                            </div>
                                            {/*Input Waktu*/}
                                            {this.state.agenda_type === 'acara' && (
                                                <div className="" style={{display: "flex", gap: "50px"}}>
                                                    <div className="form-group">
                                                        <label htmlFor="" style={{fontSize: "12px", color: "#143B5E"}}
                                                               className="mb-2 font-weight-bold">{i18n.t('jammulaiagenda')}</label>
                                                        <TimeInput
                                                            placeholder={i18n.t('jammulaiagenda')}
                                                            style={{fontSize: "12px"}}
                                                            initTime={this.state.agenda_start_time}
                                                            className='s-input -time form-control px-3 py-2'
                                                            onTimeChange={(event) => {
                                                                const value = event;
                                                                this.setState({
                                                                    agenda_start_time: value
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="" style={{fontSize: "12px", color: "#143B5E"}}
                                                               className="mb-2 font-weight-bold">{i18n.t('jamselesaiagenda')}</label>
                                                        <TimeInput
                                                            placeholder={i18n.t('jamselesaiagenda')}
                                                            style={{fontSize: "12px"}}
                                                            initTime={this.state.agenda_end_time}
                                                            className='s-input -time form-control px-3 py-2'
                                                            onTimeChange={(event) => {
                                                                const value = event;
                                                                this.setState({
                                                                    agenda_end_time: value
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            <button onClick={() => this.setState({
                                modalAdd: false, agenda_title: '',
                                agenda_note: '',
                                agenda_type: '',
                                agenda_date: '',
                            })} className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' disabled={this.state.prosesSimpan}
                                    onClick={this.simpanAgenda}><img alt=''
                                                                     src={SaveIcon}/> {this.state.prosesSimpan ? "Menyimpan.." : i18n.t("simpan")}
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal size='lg' zIndex={999} isOpen={this.state.modalEdit} toggle={() => this.setState({
                        modalEdit: false,
                    })} style={{maxWidth: "1000px"}}>
                        <ModalHeader toggle={() => this.setState({
                            modalEdit: false,
                        })} className='pengumuman-modal-header'>
                            <div className='pengumuman-modal-header-font'>{i18n.t("editagenda")}</div>
                        </ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <form className="needs-validation user-add" noValidate="">
                                        <div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("judulagenda")}</label>
                                                <div>
                                                    <input type="text" name="agenda_title"
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder={i18n.t("judulagenda")}
                                                           defaultValue={this.state.agenda_title}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("catatanagenda")}</label>
                                                <div>
                                                    <input type="text" name="agenda_note"
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder={i18n.t("catatanagenda")}
                                                           defaultValue={this.state.agenda_note}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("tipeagenda")}</label>
                                                <div>
                                                    <select id="agenda_type" name="agenda_type"
                                                            className="form-control col-xl-8 col-md-7"
                                                            value={this.state.agenda_type}
                                                            onChange={this.handleChange}>
                                                        <option value="">{i18n.t("pilihtipe")}</option>
                                                        <option value="liburnasional">{i18n.t("liburnasional")}</option>
                                                        <option
                                                            value="liburperusahaan">{i18n.t("liburperusahaan")}</option>
                                                        <option value="acara">{i18n.t("acara")}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{fontSize: "12px", color: "#143B5E"}}
                                                       className="mb-2 font-weight-bold">{i18n.t("tanggalagenda")}</label>
                                                <input name="agenda_date"
                                                       defaultValue={this.state.agenda_date}
                                                       className="form-control col-xl-8 col-md-7"
                                                       onChange={this.handleChange}
                                                       type="date" required=""/>
                                            </div>
                                            {/*Input Waktu*/}
                                            {this.state.agenda_type === 'acara' && (
                                                <div className="" style={{display: "flex", gap: "50px"}}>
                                                    <div className="form-group">
                                                        <label htmlFor="" style={{fontSize: "12px", color: "#143B5E"}}
                                                               className="mb-2 font-weight-bold">{i18n.t('jammulaiagenda')}</label>
                                                        <TimeInput
                                                            placeholder={i18n.t('jammulaiagenda')}
                                                            style={{fontSize: "12px"}}
                                                            initTime={this.state.agenda_start_time}
                                                            className='s-input -time form-control px-3 py-2'
                                                            onTimeChange={(event) => {
                                                                const value = event;
                                                                this.setState({
                                                                    agenda_start_time: value
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="" style={{fontSize: "12px", color: "#143B5E"}}
                                                               className="mb-2 font-weight-bold">{i18n.t('jamselesaiagenda')}</label>
                                                        <TimeInput
                                                            placeholder={i18n.t('jamselesaiagenda')}
                                                            style={{fontSize: "12px"}}
                                                            initTime={this.state.agenda_end_time}
                                                            className='s-input -time form-control px-3 py-2'
                                                            onTimeChange={(event) => {
                                                                const value = event;
                                                                this.setState({
                                                                    agenda_end_time: value
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            <button onClick={() => this.setState({
                                modalEdit: false,
                            })} className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' onClick={this.simpanUbahAgenda}><img alt=''
                                                                                                          src={SaveIcon}/> {i18n.t("simpan")}
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal size='lg' zIndex={999} isOpen={this.state.modalImport} toggle={() => this.setState({
                        modalImport: false
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalImport: false
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("importagenda")}</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className="">
                                <Table className="table table-borderless">
                                    <Thead>
                                        <Tr>
                                            <Th className="pengumuman-th-table">
                                                <input className='form-control' type='checkbox'
                                                        name="checked_all" checked={this.state.checked_all} onChange={(e) => this.handleCheckAll(e)}/>
                                            </Th>
                                            <Th className="pengumuman-th-table">{i18n.t('titleagenda')}</Th>
                                            <Th className="pengumuman-th-table">{i18n.t("catatan")}</Th>
                                            <Th className="pengumuman-th-table">{i18n.t("tipe")}</Th>
                                            <Th className="pengumuman-th-table">{i18n.t("tanggal")}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {this.state.loading_imp &&
                                            <Tr>
                                                <Td colSpan={8}>
                                                    <Spin size="large" tip="Loading..." style={{
                                                        width: '100%',
                                                        margin: 'auto',
                                                        marginBottom: '10px',
                                                        marginTop: '10px'
                                                    }}/>
                                                </Td>
                                            </Tr>
                                        }
                                        {this.state.data_import.map((item, index) => {
                                            return (
                                                <Tr key={index}>
                                                    <Td className="pengumuman-td-table">
                                                        <input className='form-control' type='checkbox'
                                                               name={"row-" + item.agenda_id} checked={this.state.checked.includes(item.agenda_id)} onChange={() => this.handleCheckBoxChange(item.agenda_id)}/>
                                                    </Td>
                                                    <Td className="pengumuman-td-table">{item.agenda_title}</Td>
                                                    <Td className="pengumuman-td-table">{item.agenda_note}</Td>
                                                    <Td className="pengumuman-td-table">{item.agenda_type === 'acara' ? i18n.t('acara') : item.agenda_type === 'liburnasional' ? i18n.t('liburnasional') : item.agenda_type === 'liburperusahaan' ? i18n.t('liburperusahaan') : '' }</Td>
                                                    <Td className="pengumuman-td-table">{moment(item.agenda_date).format('LL')}</Td>
                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageImport}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            <button onClick={() => this.setState({
                                modalImport: false, checked_all: false, checked: [], data_import: [],
                            })} className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' onClick={() => this.submitImportAgenda()}>{i18n.t("importagenda")}
                            </button>
                        </ModalFooter>
                    </Modal>

                    <ModalRs size='lg' style={{ maxWidth: "394px" }} isOpen={this.state.modalExport}
                             toggle={() => this.setState({
                                 reactDateRange: false
                             }, () => this.handleCancel())}>
                        <ModalHeader style={{backgroundColor: "#FAFDFF"}}
                                     toggle={() => this.setState({ reactDateRange: false }, () => this.handleCancel())}>
                            Export Excel
                        </ModalHeader>
                        <ModalBody>
                            <div className='row'>
                                <div className='col' style={{ lineHeight: "12px" }}>
                                    <div className='d-flex flex-row warning-info px-2 mb-2'>
                                        <div className='px-0 mr-1'><img src={WarningIcon} alt=""/></div>
                                        <div className='px-0 mt-1' style={{fontSize: "14px"}}>
                                            {i18n.t("filterberdasarkantanggal")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2' style={{width: "780px"}}>
                                <div className='col-md-6'>
                                    <InputGroup className="btn-tgl-exp-payroll mb-0">
                                        <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                            </div>
                                        </InputGroupAddon>

                                        <Input bsSize="sm" className='font-edit-value'
                                               style={{paddingTop: "8px", paddingBottom: "8px"}}
                                               onFocus={() => this.setState({reactDateRange: true})}
                                               placeholder={i18n.t("filtertanggal")} value={this.state.valueDate}/>
                                    </InputGroup>
                                </div>
                            </div>
                            {this.state.reactDateRange === true &&
                                <div className='shadow table-responsive d-flex flex-column mb-2' style={{ maxWidth: "360px" }}>
                                    <div className='d-flex justify-content-center mt-3'>
                                        <DateRange
                                            onBlur={() => this.setState({reactDateRange: false})}
                                            ranges={[selectionRange]}
                                            onChange={(e) => {
                                                this.setState({
                                                    start_date: e.selection.startDate,
                                                    end_date: e.selection.endDate,
                                                    exportStartDate: moment(e.selection.startDate).format("YYYY-MM-DD"),
                                                    exportEndDate: moment(e.selection.endDate).format("YYYY-MM-DD")
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-end pr-2'>
                                            <button className='btn ipresens-btn-cancel text-capitalize mb-2'
                                                    style={{fontSize: "14px", fontWeight: 500}}
                                                    onClick={() => this.setState({reactDateRange: false})}>{i18n.t("batal")}
                                            </button>
                                            <button
                                                className='btn ipresens-btn-color mb-2 ml-2 text-capitalize'
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: 500,
                                                    lineHeight: "24px"
                                                }} onClick={
                                                () => this.setState({
                                                    valueDate: this.state.exportStartDate === true ? this.state.exportStartDate + " - " + this.state.exportEndDate : moment(this.state.start_date).format("YYYY-MM-DD") + " - " + moment(this.state.end_date).format("YYYY-MM-DD"),
                                                    reactDateRange: false
                                                })
                                            }>{i18n.t("terapkan")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.valueDate !== "" && this.state.valueDate !== " - " &&
                                <>
                                    <div>
                                        <div className='d-flex justify-content-center'>
                                            <div className='col-md-8 px-2'>
                                                <button
                                                    className={'shadow btn btn-block btn-primary px-3 py-2 text-capitalize mt-2'}
                                                    style={{fontSize: "14px", borderRadius: "11px"}}
                                                    onClick={this.exportAgenda}>Export Data Agenda
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </ModalBody>
                        <ModalFooter style={{backgroundColor: "#F5F5F5", borderTop: 0}}>
                            <button className="btn ipresens-btn-cancel text-capitalize"
                                    onClick={() => this.setState({
                                        reactDateRange: false
                                    }, () => this.handleCancel())}>
                                {i18n.t("batalkan")}
                            </button>
                        </ModalFooter>
                    </ModalRs>

                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false })}>
                            <div className='modal-header-reactstrap'>{i18n.t('riwayatperubahan')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>{i18n.t('user')}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left"style={{ marginRight: '20px' }}>
                                                    <h4>{i18n.t('aktivitas')}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return (
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{ width: '48px' , marginBottom:'25px', height:'48px'}}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                                    <span className="col-green font-weight-bold">
                                                                                      {moment(item.created_at).format('lll')}
                                                                                    </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === 'created' ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        {i18n.t('dibuat')}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        {i18n.t('diubah')}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.history_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.agenda_title != item.agenda_title && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('judulagendadari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.agenda_title
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.agenda_title ? item.agenda_title : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.agenda_date != item.agenda_date && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('tanngalagendadari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.agenda_date
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.agenda_date ? item.agenda_date : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.agenda_note != item.agenda_note && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('catatanagendadari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.agenda_note
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.agenda_note ? item.agenda_note : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.agenda_type != item.agenda_type && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('tipeagendadari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.agenda_type
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.agenda_type ? item.agenda_type : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.agenda_start_time != item.agenda_start_time && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('waktumulaiagendadari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.agenda_start_time
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.agenda_start_time ? item.agenda_start_time : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.agenda_end_time != item.agenda_end_time && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('waktuslesaiagendadari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.agenda_end_time
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.agenda_end_time ? item.agenda_end_time : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} data</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>

                        </ModalFooter>
                    </ModalRs>

                    <div className="container-fluid pt-4">
                        <div className='row'>
                            <div className='col-md-12' style={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#5D6F80"
                            }}>Presensi Admin Panel
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-md-12' style={{
                                fontWeight: 700,
                                fontSize: "24px",
                                lineHeight: "36px",
                                color: "#143B5E"
                            }}>{i18n.t("daftaragenda")}
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header border-0 p-4">
                                <div className='row'>
                                    <div className='col-md-6 pengumuman-col-filter'>
                                        <div className='flex-row flex-nowrap'>
                                            <div className='input-group btn-srch-pengumuman mr-1'>
                                                <span className='input-group-text pengumuman-filter-nama-input-text'>
                                                    <img alt='' src={SearchIcon}/>
                                                </span>
                                                <input className='form-control mr-2 pengumuman-filter-nama-input'
                                                       style={{
                                                           borderTopRightRadius: "8px",
                                                           borderBottomRightRadius: "8px"
                                                       }}
                                                       type='text'
                                                       placeholder={i18n.t("carijudulagenda")} onKeyUp={(event) => {
                                                    if (event.which === 13) {
                                                        event.preventDefault()
                                                        this.setState({
                                                            page: 0,
                                                            paging: this.state.paging,
                                                            loading: true,
                                                            agendaData: [],
                                                            agendaAdmin: [],
                                                            agendaAll: [],
                                                        }, () => {
                                                            this.loadData()
                                                        });
                                                    }
                                                }} onChange={this.handleChange} name="filter_judul"
                                                       value={this.state.filter_judul}/>
                                                <button className='btn ipresens-btn-reset-filter'
                                                        onClick={this.resetFilter}>
                                                    <img alt='' src={RefreshIcon}/>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-md-2"></div>
                                    <div className='col-md-4 d-flex justify-content-end'>
                                        {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                            <>
                                                <div className="row">
                                                    <div className='col-auto px-0 ml-2'>
                                                        {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                            <button
                                                                className='btn ipresens-btn-color float-right ml-2'
                                                                style={{
                                                                    lineHeight: "30px",
                                                                    padding: "6px 18px",
                                                                    fontSize: '14px',
                                                                }}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        modalAdd: true
                                                                    })
                                                                }}><img src={PlusWhiteIcon} alt=''/>
                                                                {i18n.t("tambahagenda")}
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className="col-auto px-0 mr-3 ml-2" align="center">
                                                        <Tooltip rigger={'click'} placement="bottomLeft" color={'#FFF'}
                                                                 title={
                                                                     <div className='row px-4 py-2'>
                                                                         <a
                                                                             onClick={() => this.importAgenda()}
                                                                             style={{marginRight: "100px"}}
                                                                             className={'text-dark'}
                                                                         >Import Agenda</a>
                                                                         <a
                                                                             className={'text-dark'}
                                                                             onClick={() => this.setState({modalExport: true})}
                                                                         >Export Agenda</a>
                                                                     </div>
                                                                 }>
                                                            <a>
                                                                <i
                                                                    style={{
                                                                        width: "15",
                                                                        fontSize: 18,
                                                                        color: 'rgb(51 51 51)',
                                                                        alignItems: "center",
                                                                        paddingRight: "30px"
                                                                    }}
                                                                    className='btn border border-dark border-1 px-2'
                                                                >
                                                                    <img src={MoreIcon}/>
                                                                </i>
                                                            </a>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body daftar-pengumuman pt-3">
                                {!this.state.loading && this.state.agendaData.length === 0 ?
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    (this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("R") >= 0) && (
                                        <Table className="table table-striped table-borderless mb-5">
                                            <Thead>
                                                <Tr>
                                                    <Th className="pengumuman-th-table">#</Th>
                                                    <Th className="pengumuman-th-table">{i18n.t("judul")}</Th>
                                                    <Th className="pengumuman-th-table">{tipe ? i18n.t("sekolah") : i18n.t("perusahaan")}</Th>
                                                    <Th className="pengumuman-th-table">
                                                        <div className='d-flex justify-content-center'>
                                                        <div align="center">{i18n.t("tanggal")} </div>
                                                            <div className='ml-2 d-flex flex-column'>
                                                                <img
                                                                    src={
                                                                        this.state.isClicked === ""
                                                                            ? ArrowUpIcon
                                                                            : this.state.isClicked === "t_asc"
                                                                                ? ArrowUpIconCustom1
                                                                                : this.state.isClicked === "t_desc"
                                                                                    ? ArrowUpIconCustom2
                                                                                    : ArrowUpIcon
                                                                    }
                                                                    alt=""
                                                                    onChange={this.handleChange}
                                                                    onClick={() => {
                                                                        this.handleIconClick("t_asc");
                                                                        this.handleSort("t_asc");
                                                                    }}
                                                                    style={{
                                                                        width: '9px',
                                                                        height: '9px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    className="izin-btn"
                                                                />
                                                                <img
                                                                    src={
                                                                        this.state.isClicked === ""
                                                                            ? ArrowDownIcon
                                                                            : this.state.isClicked === "t_desc"
                                                                                ? ArrowDownIconCustom1
                                                                                : this.state.isClicked === "t_asc"
                                                                                    ? ArrowDownIconCustom2
                                                                                    : ArrowDownIcon
                                                                    }
                                                                    alt=""
                                                                    onChange={this.handleChange}
                                                                    onClick={() => {
                                                                        this.handleIconClick("t_desc");
                                                                        this.handleSort("t_desc");
                                                                    }}
                                                                    style={{
                                                                        width: '9px',
                                                                        height: '9px',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    className="izin-btn"
                                                                />
                                                            </div>
                                                        </div>
                                                    </Th>
                                                    <Th className="pengumuman-th-table">{i18n.t("catatan")}</Th>
                                                    <Th className="pengumuman-th-table"></Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {this.state.loading &&
                                                    <Tr>
                                                        <Td colSpan={8}>
                                                            <Spin size="large" tip="Loading..." style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                                marginBottom: '10px',
                                                                marginTop: '10px'
                                                            }}/>
                                                        </Td>
                                                    </Tr>
                                                }
                                                {this.state.agendaAll.map((item, index) => {
                                                    no += 1
                                                    return (
                                                        <Tr>
                                                            <Td className="pengumuman-td-table">
                                                                {pageNumber(no, this.state.perPage, this.state.currentPage)}
                                                            </Td>
                                                            <Td className="pengumuman-td-table">{item.title}</Td>
                                                            <Td className="pengumuman-td-table">{item.perusahaan_id === "0" ? "Agenda yang dibuat oleh Admin" : item.perusahaan.perusahaan_nama}</Td>
                                                            <Td className="pengumuman-td-table text-center">{moment(item.tanggal).format('LL')}</Td>
                                                            <Td className="pengumuman-td-table">{item.note}</Td>
                                                            <Td className="pengumuman-td-table">
                                                                <Tooltip trigger={'click'} placement="bottom" color={'#fff'} zIndex={900}
                                                                         title={
                                                                             <div className={'p-2'}>
                                                                                 {item.perusahaan_id !== "0" &&
                                                                                     <>
                                                                                         <a onClick={() => {
                                                                                             this.setState({
                                                                                                 modalHistory: true,
                                                                                                 agenda_id: item.agenda_id,
                                                                                                 currentPageHistory: 0
                                                                                             }, () => {
                                                                                                 this.loadHistory(item.agenda_id);
                                                                                             })
                                                                                         }}
                                                                                            className={'text-dark br-left-4'}>{i18n.t("riwayat")}</a><br/>
                                                                                     </>
                                                                                 }
                                                                                 <a onClick={() => this.setState({
                                                                                     idDetail: item.agenda_id
                                                                                 }, () => this.loadDetail())}
                                                                                    className={'text-dark'}>Detail</a><br/>
                                                                                 {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                                     <>
                                                                                         <a onClick={() => this.setState({
                                                                                             idDetail: item.agenda_id
                                                                                         }, () => this.loadEditDetail())}
                                                                                            className={'text-dark'}>Edit</a><br/>
                                                                                     </>
                                                                                 )}
                                                                                 {item.perusahaan_id !== "0" &&
                                                                                     (this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                                         <>
                                                                                             <a onClick={() => {
                                                                                                 this.setState({
                                                                                                     idDel: item.agenda_id
                                                                                                 })
                                                                                                 this.wantDelete()
                                                                                             }}
                                                                                                className={'text-danger'}>{i18n.t("hapus")}</a>
                                                                                         </>
                                                                                     )
                                                                                 }
                                                                             </div>
                                                                         }>
                                                                    <a><i
                                                                        className="fa fa-ellipsis-v" style={{
                                                                        width: 35,
                                                                        fontSize: 18,
                                                                        padding: 11,
                                                                        color: 'rgb(51 51 51)'
                                                                    }}
                                                                    ></i></a>
                                                                </Tooltip>

                                                            </Td>
                                                        </Tr>
                                                    )
                                                })}
                                            </Tbody>
                                        </Table>

                                    )
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListAgenda);