import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Swal from "sweetalert2";
import { set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import Switch from "react-switch";
import { Helmet } from "react-helmet";
import InfoIcon from "../../../assets/icons/info-icon-blue.svg";
import {Modal, ModalBody, ModalHeader, ModalFooter} from "reactstrap";
import "../../styling.css"
import PencilIcon from "../../../assets/icons/pencil.svg"
import Tour from "reactour";
import i18n from 'i18next';

export class PresensiSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: true,
            data: {},
            greet: '',
            quote: '',
            show_alpa: true,
            info_alpa: false,
            setting_r_status: '',
            setting_r_treshold: '',
            istirahat: 0,
            modal_edit: false,
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    saveSettingIstirahat = () => {
        this.setState({
            loading: true,
        })
        let form = {
            istirahat: this.state.istirahat,
        }
        this.props.request("setting-istirahat/istirahat", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text:  i18n.t('pengaturansukses'),
                        icon: 'success'
                    })
                    this.setState({
                        modal_edit: false
                    })
                    this.loadDataPresence()
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text:  i18n.t('pengaturangagal'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    saveSettings = () => {
        this.setState({
            loading: true,
        });

        let formAlpha = {
            show_alpa: this.state.show_alpa,
        };

        let formRecognition = {
            setting_r_treshold: this.state.setting_r_treshold,
            setting_r_status: this.state.setting_r_status
        };


        Promise.all([
            this.props.request("setting-alpa/update", formAlpha, 'POST'),
            this.props.request("setting-recognition/update", formRecognition, 'POST')
        ])
            .then(([responseAlpha, responseRecognition]) => {

                if (responseAlpha.success && responseRecognition.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('pengaturansukses'),
                        icon: 'success'
                    });
                }
                else {

                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('pengaturangagal'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    });
                }

                this.loadDataPresence();
            })
            .catch(error => {
                Swal.fire({
                    title: i18n.t('gagal'),
                    text: i18n.t('errorserver'),
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    loadDataPresence = () => {
        this.setState({
            loaded: false,
            loading: true
        });

        let form = {};


        Promise.all([
            this.props.request("setting-alpa/list", form, 'POST'),
            this.props.request("setting-recognition/list", form, 'POST'),
            this.props.request("setting-istirahat/list", form, 'POST')
        ])
            .then(([responseAlpha, responseRecognition, responseIstirahat]) => {

                if (responseAlpha.success && responseRecognition.success && responseIstirahat.success) {
                    this.setState({
                        loaded: true,
                        show_alpa: responseAlpha.data.setting_absen_alpa === "true",
                        istirahat: responseIstirahat.data.istirahat,
                        setting_r_status: responseRecognition.data.setting_r_status,
                        setting_r_treshold: responseRecognition.data.setting_r_treshold
                    });
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    componentDidMount() {
        this.loadDataPresence()
    }

    render() {
        const steps = [
            {
                selector: '.setting-presensi',
                content: i18n.t('steppengaturanpresensi'),
            },
            {
                selector: '.setting-alpha',
                content: i18n.t('steppengaturanalpha'),
            },
            {
                selector: '.setting-istirahat',
                content: i18n.t('steppengaturanistirahat'),
            },
            {
                selector: '.setting-recognition',
                content: i18n.t('steppengaturanrecognition'),
            },
        ];

        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Pengaturan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t('pengaturanpresensi')}</title>
                        <meta name="description" content={i18n.t('detailpresensi')} />
                    </Helmet>

                    <Breadcrumb title={i18n.t('pengaturanpresensi')} setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        {this.state.loaded ?
                                            <Fragment>
                                                <div className="mt-4 setting-alpha">
                                                    <label
                                                        className="header-input-basic">{i18n.t('pengaturanalpha')}</label>
                                                    <label
                                                        className='form-label label-input-basic col-md-6 col-sm-6'>{i18n.t('otomatisasialpha')}
                                                        <img className="icon-info"
                                                             onClick={() => this.setState({info_alpa: true})}
                                                             src={InfoIcon} alt=""/> </label>

                                                    <Switch
                                                        id={'show_alpa'}
                                                        name="show_alpa"
                                                        checked={this.state.show_alpa}
                                                        onChange={(val) => {
                                                            this.setState({
                                                                show_alpa: val
                                                            })
                                                        }}
                                                        onColor="#8c2bee"
                                                        offColor="#eb4034"
                                                        handleDiameter={18}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={24}
                                                        width={48}
                                                        className="mr-1 btn-switch-setting-presensi"
                                                    />
                                                </div>

                                                <div className="mt-4 setting-istirahat">
                                                    <label
                                                        className="header-input-basic">{i18n.t('pengaturanmaxistirahat')}</label>
                                                    <div className="input-group form-istirahat-max pl-3">
                                                        <input type="number" name="istirahat" id="istirahat"
                                                               className="form-control col-md-6 col-sm-6"
                                                               placeholder="pengaturan max istirahat"
                                                               disabled={this.state.istirahat !== null ? true : false}
                                                               value={this.state.istirahat}
                                                               onChange={this.handleChange}/>
                                                        <div className='input-group-append'>
                                                                <span
                                                                    className='input-group-text'>{i18n.t('menit')}</span>
                                                        </div>
                                                        <a className="flex-wrap my-auto"
                                                           onClick={() => this.setState({modal_edit: true})}><img
                                                            src={PencilIcon} alt=""/></a>
                                                    </div>
                                                </div>

                                                <div className="mt-4 setting-recognition">
                                                    <label
                                                        className="header-input-basic">{i18n.t('pengaturanfacerecognition')}</label>
                                                    <label
                                                        className='form-label label-input-basic col-md-6 col-sm-6'>{i18n.t('statusfitur')}</label>
                                                    <Switch
                                                        id={'setting_r_status'}
                                                        name="setting_r_status"
                                                        checked={this.state.setting_r_status}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                setting_r_status: value === false ? 0 : 1
                                                            })
                                                        }}
                                                        onColor="#8c2bee"
                                                        offColor="#eb4034"
                                                        handleDiameter={18}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={24}
                                                        width={48}
                                                        className="btn-switch-setting-recognition"
                                                    />
                                                    <label
                                                        className='form-label label-input-recognition col-md-6 col-sm-6'>{i18n.t('bataspencocokan')}</label>
                                                    <span
                                                        className="input-group-recognition">{this.state.setting_r_treshold} %</span>
                                                    <input
                                                        className='input-recognition'
                                                        type='number'
                                                        name='setting_r_treshold'
                                                        onChange={(e) => this.handleChange(e)}
                                                        value={this.state.setting_r_treshold}
                                                        min="20" max="100" maxLength="3"
                                                    />
                                                </div>
                                            </Fragment>

                                            : null
                                        }
                                        <div className="pull-right mt-4">
                                            {this.state.loading == false ?
                                                <button type="button" className="btn ipresens-btn-color"
                                                        disabled={!this.state.loaded} onClick={() => {
                                                    this.saveSettings()
                                                }}>{i18n.t('simpan')}</button>
                                                :
                                                <button type="button" className="btn ipresens-btn-color"><i
                                                    className="fa fa-refresh fa-spin"/> Loading</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.info_alpa === true ?
                        <Modal className="modaling-style" size={"sm"} isOpen={this.state.info_alpa}
                               toggle={() => this.setState({info_alpa: false})}>
                            <ModalBody>
                                <p>{i18n.t('fitur')} <strong>{i18n.t('otomatisasialpha')}</strong> {i18n.t('descalpha')}
                                </p>
                            </ModalBody>
                        </Modal> : ""
                    }
                    {this.state.modal_edit === true ?
                        <Modal className="modaling-style" isOpen={this.state.modal_edit}
                               toggle={() => this.setState({modal_edit: false})}>
                            <ModalHeader toggle={() => this.setState({modal_edit: false})}>
                                <h3>{i18n.t('maxistirahatperusahaan')}</h3></ModalHeader>
                            <ModalBody>
                                <div className="row">
                                    <div className="col-md-12 justify-content-center">
                                        <div className="input-group form-istirahat-max">
                                        <input type="number" name="istirahat" id="istirahat"
                                                   className="form-control"
                                                   placeholder="pengaturan max istirahat"
                                                   value={this.state.istirahat}
                                                   onChange={this.handleChange}/>
                                            <div className='input-group-append'>
                                                <span className='input-group-text'>{i18n.t('menit')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter style={{
                                backgroundColor: "#F9F9F9",
                                borderBottomLeftRadius: "8px",
                                borderBottomRightRadius: "8px"
                            }} className='px-4 py-3'>
                                <button className='btn ipresens-btn-cancel text-capitalize' onClick={() => this.setState({ modal_edit: false })}>{i18n.t('batalkan')}</button>
                                <button className='btn ipresens-btn-color text-capitalize' disabled={this.state.loading} onClick={this.saveSettingIstirahat} style={{ padding: "8px 14px", fontWeight: 500, fontSize: "14px", lineHeight: "24px" }}>{i18n.t('simpan')}</button>
                            </ModalFooter>
                        </Modal>
                        : ""}
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    user: state.Auth.user,
    api_token: state.Auth.api_token,
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PresensiSetting)
