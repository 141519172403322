import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import Breadcrumb from '../common/breadcrumb';
import {Empty, Spin, Tooltip} from 'antd';
import ReactPaginate from 'react-paginate';
// import Progres from './progres';
import RefreshIcon from '../../assets/icons/refresh.svg';
import PlusWhiteIcon from '../../assets/icons/plus-white.svg';
import ArrowRight from '../../assets/icons/arrow-right.svg';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import PencilIcon from '../../assets/icons/pencil.svg';
import TrashIcon from '../../assets/icons/trash.svg';
import EmptyDataImage from '../../assets/images/data-kosong.png';
import {Col, Modal as ModalRs, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import './style.css';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import JamKerjaDivisi from './jam-kerja-divisi.js';
import JabatanDivisi from './jabatan-divisi';
import Swal from 'sweetalert2';
import Tour from "reactour";
import ImportIcon from "../../assets/icons/import-icon.svg";
import {BACKEND_API_HOST, DEFAULT_IMG} from "../../helper/host";
import i18n from "i18next";
import {set_filter} from "../../reducers/Filter"
import {Td, Tr} from "react-super-responsive-table";
import EyeIcon from '../../assets/icons/eye.svg';
import ViewDivisiStr from "./struktur_divisi/view";
import moment from "moment/moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import Paging from "../paging";

class List_divisi_karyawan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter_nama: "",
            paginate: 20,
            totalData: 0,
            totalPerpage: 0,
            pageCount: 0,
            perPage: 0,
            divisi_id: 0,
            data: [],
            progresModal: false,
            modalAdd: false,
            modalEdit: false,
            activeTab: 0,
            dataJamkerja: [],
            dataJabatan: [],
            id_jamkerja: [],
            nama_jamkerja: [],
            id_jabatan: [],
            shift_nama: "",
            checked_all: false,
            shift: [],
            lokasi: [],
            divisi: [],
            karyawan: [],
            divisi_name: "",
            divisi_payroll_type: "",
            guide: false,
            disable_add: false,
            disable_edit: false,
            loadingList: false,
            loadingJamkerja: false,
            loadingJabatan: false,
            confirm_modal_default: false,
            importFile: null,
            modalimport: false,
            multiabsen: false,
            view_chart: false,
            nama_divisi: '',
            dataHistory: [],
            currentPageHistory: 0
        };
        this.handlePageClick = this.handlePageClick.bind(this);
        this.importExcel = this.importExcel.bind(this);
        this.handlePageHistory = this.handlePageHistory.bind(this)
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        })
    }

    handleOk = e => {
        this.setState({
            modalimport: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false
        });
    };


    handleMultiAbsen = (e)=> {
        const name = e.target.name;
        const value = e.target.checked;
        this.setState({
            [name]: value
        })
    }

    loadData() {
        this.setState({
            loadingList: true
        })
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 20,
            page: this.state.currentPage + 1,
        }
        this.props.request("divisi/list-divisi-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        loadingList: false,
                        data: response.data.data,
                        totalData: response.data.total,
                        totalPerpage: response.data.to,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            })
    }
    loadJamkerja() {
        this.setState({
            loadingJamkerja: true
        })
        let form = {
            perusahaan_id: this.props.user.perusahaan_id,
            shift_nama: this.state.shift_nama,
        }
        this.props.request("jamKerja/list-jamkerja-guide-v2", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        dataJamkerja: response.data,
                        loadingJamkerja: false,
                        // modalAdd: true
                    })
                }
            })
    }
    loadJabatan() {
        this.setState({
            loadingJabatan: true
        })
        let form = {}
        this.props.request("divisi/list-divisi-jabatan-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        dataJabatan: response.data,
                        loadingJabatan: false
                    })
                }
            })
    }
    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift_check: response.shift,
                        lokasi_check: response.lokasi,
                        divisi_check: response.divisi,
                        jam_kerja_check: response.jam_kerja,
                        karyawan_check: response.karyawan
                    }, () => {
                        if (this.state.shift_check.length === 0 || this.state.lokasi_check.length === 0 || this.state.divisi_check.length === 0 || this.state.karyawan_check.length === 0 || this.state.jam_kerja_check.length === 0) {
                            this.setState({
                                confirm_modal_default: true
                            })
                        }
                    })
                }
            })
    }
    addDivisi() {
        this.setState({
            disable_add: true
        })
        let form = {
            divisi_name: this.state.divisi_name,
            divisi_payroll_type: this.state.divisi_payroll_type,
            shift: this.state.id_jamkerja,
            position: this.state.id_jabatan,
            is_multi: this.state.multiabsen,
            tipe_instansi: this.props.user.perusahaan.perusahaan_type
        }
        this.props.request("divisi/add-divisi-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    const filter_data = JSON.parse(localStorage.getItem('Presensi-Company-Data'))
                    if (filter_data && filter_data._divisi) {
                        filter_data._divisi.push({
                            divisi_id: response.data.divisi_id,
                            perusahaan_id: response.data.perusahaan_id,
                            divisi_name: response.data.divisi_name,
                            divisi_payroll_type: response.data.divisi_payroll_type,
                            divisi_create_date: response.data.divisi_create_date
                        });
                        localStorage.setItem('Presensi-Company-Data', JSON.stringify(filter_data));
                    }
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                id_jabatan: [],
                                id_jamkerja: [],
                                divisi_payroll_type: "",
                                divisi_name: "",
                                modalAdd: false,
                                activeTab: 0,
                                disable_add: false
                            }, () => {
                                this.loadCheck()
                                this.setState({
                                    progresModal: true
                                })
                            })
                            this.loadData()
                            this.props.set_auth(this.props.api_token, response.perusahaan);
                        } else {
                            this.setState({
                                id_jabatan: [],
                                id_jamkerja: [],
                                divisi_payroll_type: "",
                                divisi_name: "",
                                modalAdd: false,
                                activeTab: 0
                            }, () => {
                                this.loadCheck()
                                this.setState({
                                    progresModal: true
                                })
                            })
                            this.loadData()
                            this.props.set_auth(this.props.api_token, response.perusahaan);
                        }
                    })
                } else {
                    this.setState({
                        disable_add: false
                    })
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }
    deleteDivisi() {
        let form = {
            divisi_id: this.state.divisi_id
        }
        this.props.request("divisi/delete-divisi-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.props.set_auth(this.props.api_token, response.perusahaan);
                    const filter_data = JSON.parse(localStorage.getItem('Presensi-Company-Data'));
                    filter_data._divisi = filter_data._divisi.filter(divisi => divisi.divisi_id !== this.state.divisi_id);
                    localStorage.setItem('Presensi-Company-Data', JSON.stringify(filter_data));
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.loadData()
                            this.loadCheck()
                        } else {
                            this.loadData()
                            this.loadCheck()
                        }
                    })
                } else {
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }
    byIdDivisi() {
        let form = {
            divisi_id: this.state.divisi_id
        }
        this.props.request("divisi/by-id-divisi-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        modalEdit: true,
                        divisi_name: response.data.divisi_name,
                        divisi_payroll_type: response.data.divisi_payroll_type,
                        id_jamkerja: response.id_shift,
                        id_jabatan: response.id_jabatan,
                        multiabsen: response.data.divisi_multi_absen
                    })
                }
            })
    }
    editDivisi() {
        this.setState({
            disable_edit: true
        })
        let form = {
            divisi_id: this.state.divisi_id,
            divisi_name: this.state.divisi_name,
            divisi_payroll_type: this.state.divisi_payroll_type,
            shift_id: this.state.id_jamkerja,
            jabatan_id: this.state.id_jabatan,
            is_multi: this.state.multiabsen == true ? 1: 0,
            tipe_instansi: this.props.user.perusahaan.perusahaan_type
        }
        this.props.request("divisi/edit-divisi-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    const filter_data = JSON.parse(localStorage.getItem('Presensi-Company-Data'))
                    if (filter_data && filter_data._divisi) {
                        const updateDivisi = filter_data._divisi.map(divisi =>
                            divisi.divisi_id === this.state.divisi_id ? {
                                ...divisi,
                                divisi_name: this.state.divisi_name,
                                divisi_payroll_type: this.state.divisi_payroll_type,
                            } : divisi)
                        const updateFilter = {...filter_data, _divisi: updateDivisi}
                        localStorage.setItem('Presensi-Company-Data', JSON.stringify(updateFilter))
                    }
                    Swal.fire({
                        title: i18n.t("berhasil"),
                        text: i18n.t(response.message),
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                modalEdit: false,
                                id_jabatan: [],
                                id_jamkerja: [],
                                divisi_payroll_type: "",
                                divisi_name: "",
                                modalAdd: false,
                                activeTab: 0,
                                disable_edit: false
                            })
                            this.loadData()
                        } else {
                            this.setState({
                                modalEdit: false,
                                id_jabatan: [],
                                id_jamkerja: [],
                                divisi_payroll_type: "",
                                divisi_name: "",
                                modalAdd: false,
                                activeTab: 0
                            })
                            this.loadData()
                        }
                    })
                } else {
                    Swal.fire({
                        title: i18n.t("gagal"),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    exportDivisi = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'divisi/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'divisi/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {

                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.loadData()
                    const filter_data = JSON.parse(localStorage.getItem('Presensi-Company-Data'))
                    if (filter_data) {
                        filter_data._divisi = response.data
                        localStorage.setItem('Presensi-Company-Data', JSON.stringify(filter_data))
                    }
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    })
                }
                else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        },()=>{
            let form = {
                id: id ? id : this.state.divisi_id,
                paging : 2 ,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("divisi/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    }
                    else {
                        this.setState({
                            modalHistory: false,
                            divisi_id: ''
                        });
                    }
                });
        })
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    componentDidMount() {
        this.loadData()
        this.loadCheck()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.tambah-divisi',
                content: 'Anda dapat membuat Divisi disini',
            },
            {
                selector: '.daftar-divisi',
                content: 'Daftar Divisi anda dapat dilihat disini',
            },
        ];

        const style = {
            card_header: {
                backgroundColor: "#FAFDFF",
                color: "#143B5E",
                padding: "12px 24px",
                fontSize: "24px",
                lineHeight: "36px",
                fontWeight: 700
            },
            button_refresh: {
                padding: "8px",
                border: "1px solid #DDDDDD",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF"
            },
            filter_nama: {
                backgroundColor: "#F9F9F9",
                padding: "12px 10px",
                color: "#5D6F80",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px"
            },
            th_style: {
                borderBottom: "0px",
                padding: "16px 12px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            },
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        const style_add = {
            modal_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px",
            },
            title_header: {
                color: "#143B5E",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px"
            },
            label_input: {
                color: "#143B5E",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            },
            input_group_text: {
                backgroundColor: "#EFEFEF",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                padding: "12px"
            },
            input: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            select: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                minHeight: "44px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            card_header: {
                backgroundColor: "#FAFDFF",
                padding: "10px 16px",
                color: "#143B5E",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px"
            },
            card_body: {
                backgroundColor: "#F9F9F9",
                padding: "12px 16px"
            },
            column_card_body: {
                backgroundColor: "#F9F9F9",
                textAlign: "center"
            }
        }
        moment.locale(i18n.language);
        const karyawanOrSiswa = this.props.user.perusahaan.perusahaan_type === "sekolah" ? i18n.t('siswa') : i18n.t('karyawan');
        const tipe = this.props.user.perusahaan.perusahaan_type === "sekolah";
        const levelPageLimited = this.props.user.user_level.level_page_limited;

        if (!levelPageLimited ||
            (tipe
                ? levelPageLimited.search("Kelas") >= 0
                : levelPageLimited.search("Divisi") >= 0)) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{tipe ? i18n.t("daftarkelas"):  i18n.t("daftardivisi")} - iPresens</title>
                        <meta name="description" content="Detail Absensi" />
                    </Helmet>
                    <Breadcrumb parent={tipe ? i18n.t("manajemenkelas") : i18n.t("manajemendivisi")} title={tipe ? i18n.t("daftarkelas") : i18n.t("daftardivisi")} parentLink="/employee/division-employee" setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />

                    <ViewDivisiStr view_chart={this.state.view_chart}
                                   divisi_id={this.state.divisi_id}
                                   divisi_name={this.state.nama_divisi}
                                   isSekolah={tipe}
                                   request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                                   changeState={(state, callback) => {
                                       this.setState(state, () => {
                                           if (typeof callback != 'undefined')
                                               callback()
                                       })
                                   }}
                                   loadData={() => this.loadData()}
                    />

                    <Modal size='lg' style={{ maxWidth: "1000px" }} isOpen={this.state.modalAdd} toggle={() => this.setState({
                        modalAdd: false, id_jamkerja: [],
                        activeTab: 0,
                        id_jabatan: [],
                        divisi_name: "",
                        divisi_payroll_type: "",
                    })}>
                        <ModalHeader style={style_add.modal_header} toggle={() => this.setState({
                            modalAdd: false,
                            id_jamkerja: [],
                            activeTab: 0,
                            id_jabatan: [],
                            divisi_name: "",
                            divisi_payroll_type: "",
                        })}>
                            <div style={style_add.title_header}>{tipe ? i18n.t("tambahkelas") : i18n.t("tambahdivisi")}</div>
                        </ModalHeader>
                        <ModalBody className='p-0' style={{ backgroundColor: "#F9F9F9" }}>
                            <div className='row pt-4 px-4 pb-3 mx-0 mb-2' style={{ backgroundColor: "#FFFFFF" }}>
                                <div className='col-md-4 pl-0'>
                                    <label className='form-label' style={style_add.label_input}>{tipe ? i18n.t("namakelas") : i18n.t("namadivisi")}</label>
                                    <input className='form-control' onChange={(e) => this.handleChange(e)} value={this.state.divisi_name} placeholder={tipe ? i18n.t("ketiknama") : i18n.t("ketiknama")} name="divisi_name" style={style_add.input} />
                                </div>
                                {!tipe && (
                                    <div className='col-md-4 pl-0'>
                                        <label className='form-label' style={style_add.label_input}>{i18n.t("metodehitunggaji")}</label>
                                        <select className='form-control' onChange={(e) => this.handleChange(e)}
                                                name="divisi_payroll_type" value={this.state.divisi_payroll_type}
                                                style={style_add.select}>
                                            <option value="">{i18n.t("pilihmetode")}</option>
                                            <option value="jam">{i18n.t("jam")}</option>
                                            <option value="hari">{i18n.t("hari")}</option>
                                            <option value="bulan">{i18n.t("bulan")}</option>
                                        </select>
                                    </div>
                                )}
                                <div className='col-md-4 pr-0'>
                                    <label className='form-label'
                                           style={style_add.label_input}>{i18n.t('multiabsen')}</label>
                                    <div className="d-flex align-items-center justify-content-between" style={{ paddingTop: "13px"}}>
                                        <label htmlFor="multiabsen" className="d-flex align-items-center" style={{ gap: "5px"}}>
                                            <input type="checkbox" name="multiabsen" id="multiabsen" value={this.state.multiabsen} checked={this.state.multiabsen === true }  onChange={this.handleMultiAbsen}/>
                                            {i18n.t('multiabsen')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row pt-3 px-4 pb-4 mx-0' style={{backgroundColor: "#FFFFFF"}}>
                                <div className='col-md-12 px-0'>
                                    <Tabs selectedIndex={this.state.activeTab}
                                          onSelect={(e) => this.setState({activeTab: e})}>
                                        <TabList className="nav nav-tabs tabs-coupon">
                                            <Tab className="nav-link">{tipe? i18n.t("jambelajar") : i18n.t("jamkerja")}</Tab>
                                            {!tipe && <Tab className="nav-link">{i18n.t("jabatan")}</Tab>}
                                        </TabList>
                                        <TabPanel>
                                            <JamKerjaDivisi
                                                request={this.props.request}
                                                match={this.props.match}
                                                history={this.props.history}
                                                user={this.props.user}
                                                modalAdd={this.state.modalAdd}
                                                dataJamkerja={this.state.dataJamkerja}
                                                setState={(state, value) => this.setState(state, value)}
                                                checked_all={this.state.checked_all}
                                                id_jamkerja={this.state.id_jamkerja}
                                                loadingJamkerja={this.state.loadingJamkerja}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                            <JabatanDivisi
                                                request={this.props.request}
                                                match={this.props.match}
                                                history={this.props.history}
                                                dataJabatan={this.state.dataJabatan}
                                                user={this.props.user}
                                                id_jabatan={this.state.id_jabatan}
                                                setState={(state, value) => this.setState(state, value)}
                                                loadingJabatan={this.state.loadingJabatan}
                                            />
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            backgroundColor: "#F9F9F9",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px"
                        }} className='px-4 py-3'>
                            <button className='btn text-capitalize batalkan-add-jamker' onClick={() => {
                                this.setState({
                                    modalAdd: false,
                                    id_jamkerja: [],
                                    activeTab: 0,
                                    id_jabatan: [],
                                    divisi_name: "",
                                    divisi_payroll_type: "",
                                    checked_all: false
                                })
                            }}>{i18n.t("batalkan")}</button>
                            <button className='btn btn-primary text-capitalize' disabled={this.state.disable_add} onClick={() => this.addDivisi()}>{i18n.t("simpan")}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal size='lg' style={{ maxWidth: "1000px" }} isOpen={this.state.modalEdit} toggle={() => this.setState({
                        modalEdit: false, id_jamkerja: [],
                        activeTab: 0,
                        id_jabatan: [],
                        divisi_name: "",
                        divisi_payroll_type: "",
                        checked_all: false
                    })}>
                        <ModalHeader style={style_add.modal_header} toggle={() => this.setState({
                            modalEdit: false,
                            id_jamkerja: [],
                            activeTab: 0,
                            id_jabatan: [],
                            divisi_name: "",
                            divisi_payroll_type: "",
                            checked_all: false
                        })}>
                            <div style={style_add.title_header}>{tipe?i18n.t("editkelas"):i18n.t("editdivisi")}</div>
                        </ModalHeader>
                        <ModalBody className='p-0' style={{ backgroundColor: "#F9F9F9" }}>
                            <div className='row pt-4 px-4 pb-3 mx-0 mb-2' style={{ backgroundColor: "#FFFFFF" }}>
                                <div className='col-md-4 pl-0'>
                                    <label className='form-label' style={style_add.label_input}>{tipe?i18n.t("namakelas"):i18n.t("namadivisi")}</label>
                                    <input className='form-control' onChange={(e) => this.handleChange(e)} value={this.state.divisi_name} placeholder={tipe?i18n.t("ketiknama"):i18n.t("ketiknama")} name="divisi_name" style={style_add.input} />
                                </div>
                                {!tipe && (
                                    <div className='col-md-4 pl-0'>
                                        <label className='form-label' style={style_add.label_input}>{i18n.t("metodehitunggaji")}</label>
                                        <select className='form-control' onChange={(e) => this.handleChange(e)}
                                                name="divisi_payroll_type" value={this.state.divisi_payroll_type}
                                                style={style_add.select}>
                                            <option value="">{i18n.t("pilihmetode")}</option>
                                            <option value="jam">{i18n.t("jam")}</option>
                                            <option value="hari">{i18n.t("hari")}</option>
                                            <option value="bulan">{i18n.t("bulan")}</option>
                                        </select>
                                    </div>
                                )}
                                <div className='col-md-4 pr-0'>
                                    <label className='form-label'
                                           style={style_add.label_input}>{i18n.t('multiabsen')}</label>
                                    <div className="d-flex align-items-center justify-content-between"
                                         style={{paddingTop: "13px"}}>
                                        <label htmlFor="multiabsen" className="d-flex align-items-center"
                                               style={{gap: "5px"}}>
                                            <input type="checkbox" name="multiabsen" id="multiabsen" onChange={this.handleMultiAbsen} checked={this.state.multiabsen}/>
                                            {i18n.t('multiabsen')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row pt-3 px-4 pb-4 mx-0' style={{backgroundColor: "#FFFFFF"}}>
                                <div className='col-md-12 px-0'>
                                    <Tabs selectedIndex={this.state.activeTab}
                                          onSelect={(e) => this.setState({activeTab: e})}>
                                        <TabList className="nav nav-tabs tabs-coupon">
                                            <Tab className="nav-link">{tipe? i18n.t("jambelajar") : i18n.t("jamkerja")}</Tab>
                                            {!tipe && <Tab className="nav-link">{i18n.t("jabatan")}</Tab>}
                                        </TabList>
                                        <TabPanel>
                                            <JamKerjaDivisi
                                                request={this.props.request}
                                                match={this.props.match}
                                                history={this.props.history}
                                                user={this.props.user}
                                                dataJamkerja={this.state.dataJamkerja}
                                                modalAdd={this.state.modalAdd}
                                                setState={(state, value) => this.setState(state, value)}
                                                checked_all={this.state.checked_all}
                                                id_jamkerja={this.state.id_jamkerja}
                                                loadingJamkerja={this.state.loadingJamkerja}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                            <JabatanDivisi
                                                request={this.props.request}
                                                match={this.props.match}
                                                history={this.props.history}
                                                dataJabatan={this.state.dataJabatan}
                                                user={this.props.user}
                                                id_jabatan={this.state.id_jabatan}
                                                setState={(state, value) => this.setState(state, value)}
                                                loadingJabatan={this.state.loadingJabatan}
                                            />
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            backgroundColor: "#F9F9F9",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px"
                        }} className='px-4 py-3'>
                            <button className='btn text-capitalize batalkan-add-jamker' onClick={() => {
                                this.setState({
                                    modalEdit: false,
                                    id_jamkerja: [],
                                    activeTab: 0,
                                    id_jabatan: [],
                                    divisi_name: "",
                                    divisi_payroll_type: "",
                                    checked_all: false
                                })
                            }}>{i18n.t("batalkan")}</button>
                            <button className='btn btn-primary text-capitalize' disabled={this.state.disable_edit} onClick={() => this.editDivisi()}>{i18n.t('simpan')}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalimport}>
                        <ModalHeader onClick={this.handleCancel} toggle={() => this.setState({importFile: null})}>
                            <div>{tipe ? i18n.t('importkelas') : i18n.t('importdivisi')}</div>
                        </ModalHeader>
                        <ModalBody style={{ padding: "20px" }}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>
                                        {i18n.t('importalert')}
                                    </a>
                                    <br/>
                                    <a onClick={this.exportDivisi} style={{color: "#057CE4"}}>{i18n.t('unduhtemplate')}</a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({importFile: event.target.files[0],})
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i> {i18n.t("sedangmenguploaddata")}</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                            ]}
                        </ModalFooter>
                    </Modal>
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false })}>
                            <div className='modal-header-reactstrap'>{i18n.t('riwayatperubahan')}</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>{i18n.t('user')}</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left"style={{ marginRight: '20px' }}>
                                                    <h4>{i18n.t('aktivitas')}</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return (
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src={DEFAULT_IMG}
                                                                            alt=""
                                                                            style={{ width: '48px' , marginBottom:'25px', height:'48px'}}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                                    <span className="col-green font-weight-bold">
                                                                                      {moment(item.created_at).format('lll')}
                                                                                    </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === 'created' ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        {i18n.t('dibuat')}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        {i18n.t('diubah')}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.history_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.divisi_name != item.divisi_name && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {tipe? i18n.t('namakelasdari') : i18n.t("namadivisidari")}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.divisi_name
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.divisi_name ? item.divisi_name : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.divisi_payroll_type != item.divisi_payroll_type && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t('metodehitunggajidari')}{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.divisi_payroll_type
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t('jadi')} {item.divisi_payroll_type ? item.divisi_payroll_type : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.divisi_multi_absen != item.divisi_multi_absen && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        {i18n.t("multiabsendari")}{' '}
                                                                                        <b>{item.parent_history.divisi_multi_absen === 1 ? i18n.t("aktif") : i18n.t("nonaktif")}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> {i18n.t("jadi")} : <b>{item.divisi_multi_absen === 1 ? i18n.t("aktif") : i18n.t("nonaktif")}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} data</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </ModalFooter>
                    </ModalRs>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='card border'>
                                    <div className='card-body p-4'>
                                        <div className='row mb-3'>
                                            <div className="col-md-5 mb-2">
                                                <div className='d-flex flex-row'>
                                                    <div className='col-md-6 pr-1'>
                                                        <div className='input-group btn-search-dvs'>
                                                            <input
                                                                type='text'
                                                                className={this.state.filter_nama !== " " ? "form-control" : "form-control bg-image-search input-group"}
                                                                onChange={(e) => this.handleChange(e)}
                                                                name='filter_nama'
                                                                placeholder={'  '+ tipe ? i18n.t("carinamadivisi") : i18n.t("carinamakelas") +' ...'}
                                                                style={{ borderRadius: '8px' }}
                                                                value={this.state.filter_nama}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        this.loadData()
                                                                    }
                                                                }}></input>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <button className='hover-pointer' style={style.button_refresh}><img
                                                            alt="" src={RefreshIcon} onClick={() => {
                                                            this.setState({
                                                                filter_nama: ""
                                                            }, () => this.loadData())
                                                        }}/></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col"></div>
                                            <div className='col-auto px-0 mb-4' style={{textAlign: "right"}}>
                                                <button className="ipresens-import-button btn-import-krywn"
                                                        onClick={() => {
                                                            this.setState({modalimport: true})
                                                        }}><img src={ImportIcon} alt=''/> Import
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button onClick={() => {
                                                    this.loadJamkerja()
                                                    this.loadJabatan()
                                                    this.setState({
                                                        modalAdd: true
                                                    })
                                                }} className='btn ipresens-btn-color text-capitalize float-right ml-2 izin-btn-w100'><img className='mr-2' alt='' src={PlusWhiteIcon} />{tipe ? i18n.t("tambahkelas") : i18n.t("tambahdivisi")}</button>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='table-responsive'>
                                                    <table className='table table-striped table-borderless'>
                                                        <thead>
                                                        <tr>
                                                            <th style={{
                                                                borderBottom: "0px",
                                                                padding: "16px 12px",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                lineHeight: "24px",
                                                                color: "#252B2B",
                                                                minWidth: "30px"
                                                            }}>#</th>
                                                            <th style={{
                                                                borderBottom: "0px",
                                                                padding: "16px 12px",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                lineHeight: "24px",
                                                                color: "#252B2B",
                                                                minWidth: "50px",
                                                                maxWidth: "447px"
                                                            }}>{i18n.t("nama")}</th>
                                                            <th style={{
                                                                borderBottom: "0px",
                                                                padding: "16px 12px",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                lineHeight: "24px",
                                                                color: "#252B2B",
                                                                minWidth: "180px"
                                                            }}>{tipe ? i18n.t("jumlahsiswa") :i18n.t("jumlahkaryawan")}</th>
                                                            {!tipe && (
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B",
                                                                    minWidth: "250px"
                                                                }}>{i18n.t("metodehitunggaji")}</th>
                                                            )}
                                                            <th style={{
                                                                borderBottom: "0px",
                                                                padding: "16px 12px",
                                                                fontWeight: 500,
                                                                fontSize: "14px",
                                                                lineHeight: "24px",
                                                                color: "#252B2B",
                                                                minWidth: "100px"
                                                            }}></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.loadingList ?
                                                            <Tr>
                                                                <Td colSpan={9}>
                                                                    <Spin size="large" tip={i18n.t('loading...')} style={{
                                                                        width: '100%',
                                                                        margin: 'auto',
                                                                        marginBottom: '10px',
                                                                        marginTop: '10px'
                                                                    }} />
                                                                </Td>
                                                            </Tr>
                                                            :
                                                            this.state.data.length === 0 ?
                                                                <tr>
                                                                    <td colSpan={5} style={{
                                                                        textAlign: "center",
                                                                        backgroundColor: "#FFFFFF"
                                                                    }}>
                                                                        <Empty description={i18n.t("datakosong")} image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                this.state.data.map((item, index) => {
                                                                    no += 1;
                                                                    return (
                                                                        <tr>
                                                                            <td style={style.td_style}>{no}</td>
                                                                            <td style={style.td_style}>
                                                                                <div className='row m-0' style={{
                                                                                    fontWeight: 700,
                                                                                    fontSize: "14px",
                                                                                    color: "#252B2B",
                                                                                    lineHeight: "24px"
                                                                                }}>{item.divisi_name}</div>
                                                                                <div className='row m-0'>
                                                                                    <div style={{
                                                                                        fontWeight: 400,
                                                                                        fontSize: "12px",
                                                                                        color: "#252B2B",
                                                                                        lineHeight: "16px"
                                                                                    }}>{i18n.t("jamkerjaberlaku")}:
                                                                                    </div>
                                                                                    &nbsp;<p style={{
                                                                                    fontWeight: 400,
                                                                                    fontSize: "12px",
                                                                                    color: "#5D6F80",
                                                                                    lineHeight: "16px"
                                                                                }}>{item.data_shift_name.toString().replaceAll(",", ", ")}</p>
                                                                                </div>
                                                                            </td>
                                                                            <td style={style.td_style}>{item.karyawan_many.length} {karyawanOrSiswa}</td>
                                                                            {!tipe && <td style={style.td_style}
                                                                                          className='text-capitalize'>{i18n.t(item.divisi_payroll_type)}</td>}
                                                                            <td style={style.td_style}>
                                                                                <Tooltip trigger={'click'} placement="bottom" color={'#fff'}
                                                                                         zIndex={1}
                                                                                         title={
                                                                                             <div className={'p-2'}>
                                                                                                 <a onClick={() => {
                                                                                                     this.setState({
                                                                                                         modalHistory: true,
                                                                                                         divisi_id: item.divisi_id,
                                                                                                         currentPageHistory: 0
                                                                                                     }, () => {
                                                                                                         this.loadHistory(item.divisi_id);
                                                                                                     })
                                                                                                 }}
                                                                                                    className={'text-dark'}>{i18n.t("riwayat")}</a><br/>
                                                                                                 {!tipe && (
                                                                                                     <>
                                                                                                         <a onClick={() => this.setState({
                                                                                                             view_chart: true,
                                                                                                             divisi_id: item.divisi_id,
                                                                                                             nama_divisi: item.divisi_name
                                                                                                         })}
                                                                                                            className={'text-dark'}>{tipe ? i18n.t("stucturekelas") : i18n.t("stucturedivisi")}</a><br/>
                                                                                                     </>
                                                                                                 )}
                                                                                                 {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                                                     <>
                                                                                                         <a onClick={() => {
                                                                                                             this.setState({
                                                                                                                 divisi_id: item.divisi_id
                                                                                                             }, () => {
                                                                                                                 this.byIdDivisi()
                                                                                                                 this.loadJabatan()
                                                                                                                 this.loadJamkerja()
                                                                                                             })
                                                                                                         }}
                                                                                                            className={'text-dark'}>Edit</a><br/>
                                                                                                     </>
                                                                                                 )}
                                                                                                 {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                                                     <>
                                                                                                         <a onClick={() => {
                                                                                                             Swal.fire({
                                                                                                                 title: i18n.t("yakinhapus"),
                                                                                                                 text: i18n.t("pesanandayakin"),
                                                                                                                 icon: 'warning',
                                                                                                                 showCancelButton: true,
                                                                                                                 confirmButtonColor: '#3085d6',
                                                                                                                 cancelButtonColor: '#d33',
                                                                                                                 confirmButtonText: i18n.t("hapus"),
                                                                                                                 cancelButtonText: i18n.t("batal")
                                                                                                             }).then((result) => {
                                                                                                                 if (result.isConfirmed) {
                                                                                                                     this.setState({
                                                                                                                         divisi_id: item.divisi_id
                                                                                                                     }, () => this.deleteDivisi())
                                                                                                                 }
                                                                                                             })
                                                                                                         }}
                                                                                                            className={'text-danger'}>{i18n.t("hapus")}</a>
                                                                                                     </>
                                                                                                 )}
                                                                                             </div>
                                                                                         }>
                                                                                    <a><i
                                                                                        className="fa fa-ellipsis-h"
                                                                                        style={{
                                                                                            width: 35,
                                                                                            fontSize: 18,
                                                                                            padding: 11,
                                                                                            color: 'rgb(51 51 51)'
                                                                                        }}
                                                                                    ></i></a>
                                                                                </Tooltip>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='card-footer p-0'>
                                        <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                            {/* {this.state.data.length !== 0 && */}
                                            <>
                                                <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} data</div>
                                                <div className='col'>
                                                    <ReactPaginate
                                                        previousLabel={<img src={ArrowLeft} alt='' />}
                                                        nextLabel={<img src={ArrowRight} alt='' />}
                                                        pageCount={this.state.pageCount}
                                                        forcePage={this.state.currentPage}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={3}
                                                        onPageChange={this.handlePageClick}
                                                        breakClassName={'page-item'}
                                                        breakLinkClassName={'page-link custom-page-link'}
                                                        containerClassName={'pagination my-2'}
                                                        pageClassName={'page-item'}
                                                        pageLinkClassName={'page-link custom-page-link'}
                                                        previousClassName={'page-item'}
                                                        previousLinkClassName={'page-link custom-prev-next-link'}
                                                        nextClassName={'page-item'}
                                                        nextLinkClassName={'page-link custom-prev-next-link'}
                                                        activeClassName={'active custom-active'}
                                                    />
                                                    {/* catatan.
                                                custom classname pagination dipanggil dari file css di folder absensi harian
                                                ga hanya pagination, semua classname dari css absensi harian bisa dipanggil file ini */}
                                                </div>
                                            </>
                                            {/* // } */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}
const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data)),
    set_filter: (all_data) => dispatch(set_filter(all_data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_divisi_karyawan);
