import React, {Component, Fragment} from "react";
import i18n from "i18next";
import CollapsibleScore from "./semester/collapsible";
import RefreshIcon from "../../../assets/icons/refresh.svg";
import EmptyDataImage from "../../../assets/images/data-kosong.png";
import {Empty, Spin} from "antd";
import moment from "moment";

export class Semester extends Component {
    constructor(props) {
        super(props);
        this.state = {
            semester_active: '',
            data: [],
            semester: [],
            semester_id: 0,
            loading: false,
            karyawan_slug: '',
            active: ''
        }
        this.handleSemester = this.handleSemester.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
    }

    handleSemester(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        }, () => this.loadData())
    }

    resetFilter(event) {
        this.setState({
            data: [],
            loading: true,
        },  () => this.searchSemester(), () => this.loadData())
    }

    searchSemester() {
        this.setState({ active: '', loading: true })
        let form = {}
        this.props.request('semester/load-semester', form, 'POST')
            .then(response => {
                if (response.success) {
                    const activeSemester = response.data.find(semester => semester.semester_status === 'active');
                    const activeSemesterId = parseInt(activeSemester ? activeSemester.semester_id : null);
                    this.setState({
                        semester: response.data,
                        semester_id: activeSemesterId,
                        loading: false,
                    }, () => this.loadData(activeSemesterId));
                }
            })
    }

    loadData(id) {
        this.setState({ loading: true, data: [] })
        let form = {
            semester_id: id ? id : this.state.semester_id,
            karyawan_slug: this.props.match.params.id,
        }
        this.props.request("semester/nilai-semester", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
                this.setState({ loading: false })
            })
    }

    componentDidMount() {
        this.searchSemester()
    }

    render() {
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="card px-0">
                        <div className="card-header px-0 pt-0 pb-2">
                            <div className="row mb-2 mx-0">
                                <div className='col-md-3 pl-0'>
                                    <label htmlFor="" style={{ fontSize: '10px' }}>{i18n.t('filtersemester')}</label>
                                    <select name="semester_id" className="form-control" onChange={this.handleSemester} style={{ textTransform: "capitalize"}}>
                                        {this.state.semester.map((item) => (
                                            <option key={item.semester_id} value={item.semester_id} selected={this.state.semester_id === item.semester_id}>
                                                {'semester ' + item.semester_type + ' ' + moment(item.semester_start_date).format('YYYY')}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="">
                                    <label htmlFor="" style={{fontSize: '10px', marginLeft: '5px'}}>{i18n.t('reset')}</label>
                                    <button className="btn ipresens-custom-btn-group px-2 ml-2" style={{height: "38px"}}
                                            onClick={this.resetFilter}><img src={RefreshIcon}/></button>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            {!this.state.loading && this.state.data.length <= 0 ? (
                                <Empty description={i18n.t('datakosong')}
                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                            ) : (
                                <div>
                                    {this.state.loading && (
                                        <div className="w-100 d-flex justify-content-center align-items-center">
                                            <Spin size="large" tip="Loading..." style={{
                                                width: '100%',
                                                margin: 'auto',
                                                marginBottom: '10px',
                                                marginTop: '10px'
                                            }}/>
                                        </div>
                                    )}
                                    {this.state.data.map((item) => {
                                        return (
                                            <CollapsibleScore title={item.mapel_nama} averageScore={item.rata_rata}>
                                                <table className="w-100 py-1 table-striped">
                                                    <thead>
                                                    <tr style={{borderBottom: "1px solid #ccc"}}>
                                                        <th className="text-left py-2" style={{ fontSize: "14px", maxWidth: '100px'}}>{i18n.t('tipenilai')}</th>
                                                        <th className="text-center py-2" style={{ fontSize: "14px"}}>{i18n.t('kkm')}</th>
                                                        <th className="text-right py-2" style={{ fontSize: "14px"}}>{i18n.t('nilai')}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {item?.nilai?.map((nilai) => (
                                                        <tr>
                                                            <td className="text-left pl-3"
                                                                style={{fontSize: "13px" , paddingTop: 8, paddingBottom: 8, maxWidth: '100px'}}>{nilai.nl_term_name}</td>
                                                            <td className="text-center"
                                                                style={{fontSize: "13px" , paddingTop: 8, paddingBottom: 8}}>{item.mapel_kkm}</td>
                                                            <td className="text-right pr-3"
                                                                style={{fontSize: "13px" , paddingTop: 8, paddingBottom: 8}}>{nilai?.nilai_value}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </CollapsibleScore>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Semester;