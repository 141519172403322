import React, {Component, Fragment} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";

export default class Survey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            survey: '',
        };
    }

    render() {
        if (!this.props.showModal) return null;
        return (
            <Fragment>
                <Modal isOpen={this.props.showModal} className="modal-style" style={{
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    height: '80vh',
                    maxWidth: '851px',
                    flexWrap: "wrap",
                }}>
                    <ModalHeader toggle={!this.props.dontshow ? this.props.toggle : this.props.cancel}><h4><strong>{i18n.t('kritiksaran')}</strong></h4></ModalHeader>
                    <ModalBody className="modal-body-style">
                        <form>
                            <div className="form-group media-body-info">
                                <label htmlFor="field1" style={{ fontSize:"15px"}}>{i18n.t('kritiksarandesc')}</label><br/>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="survey"
                                    placeholder={i18n.t('kritiksaranplaceholder')}
                                    onChange={(e) => {this.props.setState({survey: e.target.value})}}
                                />
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <label >
                        <input
                            type="checkbox"
                            className="justify-content-start border rounded-4"
                            onChange={this.props.handlerCheckBoxChanges}
                        />  {i18n.t('jangantampilkanlagi')}</label>
                        <button className="btn btn-light border border-black px-2 text-capitalize" onClick={!this.props.dontshow ? this.props.toggle : this.props.cancel}>
                            {i18n.t('batal')}
                        </button>
                        <button className="btn btn-primary border border-black px-2 text-capitalize" onClick={this.props.saveHandler}>
                            {i18n.t('kirim')}
                        </button>
                    </ModalFooter>
                </Modal>
            </Fragment>

        )
    }
}
