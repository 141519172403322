import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../../components/paging";
import {Empty, Spin, Tooltip} from 'antd';
import AddUser from "./add";
import Edit from "./edit";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../../functions/functionGeneral";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { clear_auth, set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import EmptyDataImage from "../../../assets/images/data-kosong.png";
import {Modal as ModalRs, ModalBody, ModalFooter, ModalHeader, Row, Col} from 'reactstrap'
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../../assets/icons/arrow-left.svg";
import ArrowRight from "../../../assets/icons/arrow-right.svg";
import i18n from "i18next";
import {DEFAULT_IMG} from "../../../helper/host";
import Detail from "./detail";
const imgMyimageexample = require('../../../assets/images/bg3.png');
const divStyle = {
    width: '100%',
    height: '50%',
};

export class UserPerusahaan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataPrimary: '',
            idDel: '',
            isLoading: true,
            pageCount: 0,
            currentPage: 0,
            currentPageDetail: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            p_user_name: '',
            p_user_id: '',
            p_user_email: '',
            p_user_wa: '',
            filter_nama: '',
            add_visible: false,
            visable: false,
            guide: false,
            dataHistory: [],
            loadingHistory: false,
            pagination: 2,
            currentPageHistory: 0,
            detail_visible: false,
            dataUser: [],
            dataActivity: []
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected }, () => {
            this.loadData();
        });
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            data: [],
            isLoading: true
        }, () => this.loadData())
    }


    wantDelete() {
        Swal.fire({
            title: i18n.t("pemberitahuan"),
            text: i18n.t("andayakinhapus"),
            icon: 'warning',
            confirmButtonText: i18n.t("ya"),
            showCancelButton: true,
            cancelButtonText: i18n.t("batal"),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        isLoading: true,
                        data: []
                    })
                    let form = {
                        id: this.state.idDel
                    }
                    this.props.request("delete_user", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: i18n.t("berhasil"),
                                    text: i18n.t(response.message),
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                    data: []
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("user_list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        dataPrimary: response.primary,
                        isLoading: false,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
            });
    }

    loadDataLevel() {
        let form = {
            paginate: 9999,
            page: this.state.currentPage + 1,
        }
        this.props.request("userlevel/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataLevel: response.data.data,
                    })
                }
            });
    }

    loadHistory(id){
        this.setState({
            dataHistory: [],
            loadingHistory: true
        },()=>{
            let form = {
                id : id ? id    : this.state.p_user_id,
                pagination      : this.state.pagination,
                page            : this.state.currentPageHistory + 1,
            };
            this.props.request("history_perusahaan_user",form,'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.success){
                        this.setState({
                            dataHistory         : response.data.data,
                            pageCountHistory    : response.data.last_page,
                            totalDataHistory    : response.data.total,
                            totalPerpageHIstory : response.data.to,
                            perPageHistory      : response.data.per_page
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            p_user_id: ''
                        })
                    }
                })
            }
        )
    }

    loadDetailUser(id){
        this.setState({loading : true})
        let form = {
            id: id ? id : this.state.p_user_id,
            paginate    : 5,
            page        : this.state.currentPageDetail + 1
        }
        this.props.request("detail_perusahaan_user", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataUser : response.data,
                        dataActivity : response.activity.data,
                        perPageDetail : response.activity.per_page,
                        totalDataDetail : response.activity.total,
                        pageCountDetail : response.activity.last_page,
                        loading : false
                    })
                }
            })
    }

    handlePageHistory(dataHistory){
        let selected = dataHistory.selected;
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true},()=>{
            this.loadHistory()
        })
    }

    componentDidMount() {
        this.loadData()
        this.loadDataLevel()
    }

    render() {
        let no = 0;
        if (!this.props.user.user_level.level_page_limited || this.props.user.user_level.level_page_limited.search("Pengaturan") >= 0) {
            return (
                <div>
                    <Fragment>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>{i18n.t("daftaruser")} - iPresens</title>
                            <meta name="description" content="Detail User" />
                        </Helmet>

                        <Edit
                            data={this.state}
                            request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                            changeState={(state, callback) => {
                                this.setState(state, () => {
                                    if (typeof callback != 'undefined')
                                        callback()
                                })
                            }}
                            loadData={() => this.loadData()}
                        />

                        <AddUser
                            data={this.state}
                            add_visible={this.state.add_visible}
                            request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                            changeState={(state, callback) => {
                                this.setState(state, () => {
                                    if (typeof callback != 'undefined')
                                        callback()
                                })
                            }}
                            loadData={() => this.loadData()}
                        />

                        <Detail
                            dataUser={this.state.dataUser}
                            changeState={(state, callback) => {
                                this.setState(state, () => {
                                    if (typeof callback != 'undefined')
                                        callback()
                                })
                            }}
                            detail_visible={this.state.detail_visible}
                            dataActivity={this.state.dataActivity}
                            perPageDetail={this.state.perPageDetail}
                            totalDataDetail={this.state.totalDataDetail}
                            pageCountDetail={this.state.pageCountDetail}
                            currentPageDetail={this.state.currentPageDetail}
                            loadDetailUser={() => this.loadDetailUser()}
                            loading={this.state.loading}
                        />

                        {/*<Breadcrumb parent="Manajemen User" title="Daftar User" setGuide={() => { this.setState({ guide: true }) }} />*/}
                        <ModalRs size='lg' style={{maxWidth: '1000px'}} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false })}>
                            <ModalHeader toggle={() => this.setState({modalHistory: false})}>
                                <div className='modal-header-reactstrap'>{i18n.t("riwayatperubahan")} </div>
                            </ModalHeader>
                            <ModalBody>
                                <div className='list-group'>
                                    <div>
                                        {this.state.loadingHistory && (
                                            <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                                <Spin
                                                    size="large"
                                                    tip="Loading..."
                                                    style={{
                                                        width: '100%',
                                                        margin: 'auto',
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {this.state.dataHistory.length !== 0 && (
                                            <div>
                                                <Row>
                                                    <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                        <h4>User</h4>
                                                    </Col>
                                                    <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                        <h4>{i18n.t("aktivitas")} </h4>
                                                    </Col>
                                                </Row>
                                                {this.state.dataHistory.map((item, index) => {
                                                    return(
                                                        <div>
                                                            <div key={index} style={{marginBottom: '20px'}}>
                                                                <Row>
                                                                    <Col xs={12} md={6} className='text-left'>
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            paddingTop: '16px',
                                                                            paddingBottom: '16',
                                                                            paddingRight: '12px',
                                                                            paddingLeft: '12px'
                                                                        }}>
                                                                            <div style={{marginRight: '16px'}}>
                                                                                <img
                                                                                    src={DEFAULT_IMG}
                                                                                    alt=""
                                                                                    style={{
                                                                                        width: '48x',
                                                                                        marginBottom: '25px',
                                                                                        height: '48px'
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div>
                                                                                <h6
                                                                                    className="mb-0 font-13"
                                                                                    style={{
                                                                                        fontWeight: 500,
                                                                                        fontSize: '16px',
                                                                                        lineHeight: '24px',
                                                                                    }}
                                                                                >
                                                                                    {item.p_user_name} | {item.level_name}
                                                                                </h6>
                                                                                <p className="m-0 font-12">
                                                                                    <span className="col-green font-weight-bold">
                                                                                        {moment(item.created_at).format('lll')}
                                                                                    </span>
                                                                                </p>
                                                                                <div style={{marginTop: 12}}>
                                                                                    {item.history_status === "created" ? (
                                                                                        <div
                                                                                            className="m-0"
                                                                                            style={{
                                                                                                border: '1px solid #000',
                                                                                                borderRadius: '25px',
                                                                                                width: '50%',
                                                                                                color: '#057CE4',
                                                                                                borderColor: '#057CE4',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="d-flex justify-content-center"
                                                                                                style={{
                                                                                                    paddingTop: '6px',
                                                                                                    paddingBottom: '6px'
                                                                                                }}>
                                                                                                {i18n.t("dibuat")}
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div
                                                                                            className="m-0"
                                                                                            style={{
                                                                                                border: '1px solid #000',
                                                                                                borderRadius: '25px',
                                                                                                width: '50%',
                                                                                                color: '#E6462E',
                                                                                                borderColor: '#E6462E',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                className="d-flex justify-content-center"
                                                                                                style={{
                                                                                                    paddingTop: '6px',
                                                                                                    paddingBottom: '6px'
                                                                                                }}>
                                                                                                {i18n.t("diubah")}
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                        {item.history_status !== 'created' && (
                                                                            <div>
                                                                                {item.parent_history ?
                                                                                    item.parent_history.p_user_name != item.p_user_name && (
                                                                                        <div>
                                                                                            <h6 className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}>
                                                                                                Perusahaan User Name : Dari{' '}
                                                                                                {item.parent_history ? item.parent_history.p_user_name
                                                                                                    : 'N/A'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                /> {i18n.t("jadi")} : {item.p_user_name ? item.p_user_name : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.p_user_email != item.p_user_email && (
                                                                                        <div>
                                                                                            <h6 className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}>
                                                                                                Perusahaan User Email : Dari{' '}
                                                                                                {item.parent_history ? item.parent_history.p_user_email
                                                                                                    : 'N/A'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                /> {i18n.t("jadi")} : {item.p_user_email ? item.p_user_email : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.p_user_password != item.p_user_password && (
                                                                                        <div>
                                                                                            <h6 className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}>
                                                                                                Perusahaan User Password : Dari{' '}
                                                                                                {item.parent_history ? item.parent_history.p_user_password
                                                                                                    : 'N/A'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                /> {i18n.t("jadi")} : {item.p_user_password ? item.p_user_password : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.p_user_level != item.p_user_level && (
                                                                                        <div>
                                                                                            <h6 className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}>
                                                                                                Perusahaan User Level : Dari{' '}
                                                                                                {item.parent_history ? item.parent_history.user_level.level_name
                                                                                                    : 'N/A'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                /> {i18n.t("jadi")} : {item.user_level.level_name ? item.user_level.level_name : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.p_user_wa != item.p_user_wa && (
                                                                                        <div>
                                                                                            <h6 className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}>
                                                                                                Perusahaan User WA : Dari{' '}
                                                                                                {item.parent_history ? item.parent_history.p_user_wa
                                                                                                    : 'N/A'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                /> {i18n.t("jadi")} : {item.p_user_wa ? item.p_user_wa : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.p_user_status != item.p_user_status && (
                                                                                        <div>
                                                                                            <h6 className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}>
                                                                                                Perusahaan User Status : Dari{' '}
                                                                                                {item.parent_history ? item.parent_history.p_user_status
                                                                                                    : 'N/A'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                /> {i18n.t("jadi")} : {item.p_user_status ? item.p_user_status : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                                {item.parent_history ?
                                                                                    item.parent_history.p_user_otp_status != item.p_user_otp_status && (
                                                                                        <div>
                                                                                            <h6 className="mb-3 font-13 text-left"
                                                                                                style={{
                                                                                                    fontWeight: 500,
                                                                                                    fontSize: '16px',
                                                                                                    lineHeight: '24px',
                                                                                                }}>
                                                                                                Perusahaan User Otp Status : Dari{' '}
                                                                                                {item.parent_history ? item.parent_history.p_user_otp_status
                                                                                                    : 'N/A'}{' '}
                                                                                                <img
                                                                                                    src={ArrowRight}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: '20x',
                                                                                                        marginBottom: '3px',
                                                                                                        height: '20px'
                                                                                                    }}
                                                                                                /> {i18n.t("jadi")} : {item.p_user_otp_status ? item.p_user_otp_status : ''}
                                                                                            </h6>
                                                                                        </div>
                                                                                    ):null
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter  style={{ backgroundColor: "#F5F5F5" }}>
                                <div className="row mx-0" style={{backgroundColor: "#F5F5F5", width: "100%"}}>
                                    {this.state.dataHistory.length !== 0 &&
                                        <>
                                            <div className="col font-14" style={{padding: "auto", margin:"auto", fontWeight: 500, lineHeight: "24px"}}>
                                                {i18n.t("menampilkan")}  {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length}{i18n.t("dari")}  {this.state.totalDataHistory}
                                            </div>
                                            <div className="col">
                                                <ReactPaginate
                                                    previousLabel={<img src={ArrowLeft} alt='' />}
                                                    nextLabel={<img src={ArrowRight} alt='' />}
                                                    pageCount={this.state.pageCountHistory}
                                                    forcePage={this.state.currentPageHistory}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={this.handlePageHistory}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link custom-page-link'}
                                                    containerClassName={'pagination my-2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link custom-page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link custom-prev-next-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link custom-prev-next-link'}
                                                    activeClassName={'active custom-active'}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </ModalFooter>
                        </ModalRs>
                        {/*<Tour isOpen={this.state.guide === true} onAfterOpen={this.disableBody}*/}
                        {/*      onBeforeClose={this.enableBody} onRequestClose={() => this.setState({guide: false})}/>*/}
                        <div className="container-fluid pt-4">
                            <div className="row">
                                <div className="col-md-12" style={{
                                    fontWeight: 400, fontSize: '12px', lineHeight: "16px", color: "#5d6f80"
                                }}>iPresens Admin Panel
                                </div>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-12" style={{
                                    fontWeight: 700,
                                    fontSize: "24px",
                                    lineHeight: "36px",
                                    color: "#143B5E"
                                }}>{i18n.t('daftaruser')}
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header border-0 p-4">
                                    <div className="row">
                                        <div className="col-md-6 pengumuman-col-filter">
                                            <div className="flex-row flex-nowrap">
                                                <div className="input-group btn-srch-user-lvl-setting">
                                                    <label style={{fontSize: '10px'}}>
                                                        {i18n.t('filternama')}:
                                                    </label><br/>
                                                    <input name="filter_nama"
                                                           className="form-control"
                                                           placeholder={i18n.t('namauser')}
                                                           type="text"
                                                           value={this.state.filter_nama}
                                                           onChange={this.handleChange}
                                                           onKeyUp={(event) => {
                                                               event.preventDefault()
                                                               if (event.which == 13) {

                                                                   this.setState({
                                                                       page: 0,
                                                                       paging: this.state.paging,
                                                                       isLoading: true
                                                                   }, () => {
                                                                       this.loadData()
                                                                   });
                                                               }
                                                           }}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 d-flex justify-content-between align-items-center">
                                            <div className="input-group btn-refresh-user-lvl-setting">
                                                <label
                                                    style={{fontSize: '10px'}}> {i18n.t('aturilangfilter')}:</label><br/>
                                                <button className="btn btn-danger"
                                                        onClick={this.resetFilter}>
                                                    <span className="fa fa-refresh"></span> {i18n.t('aturulang')}
                                                </button>
                                            </div>
                                            <div className="input-group d-flex justify-content-end" style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                                paddingTop: "20px "
                                            }}>
                                                <a className="btn ipresens-btn-color tambah-user "
                                                   style={{float: "right", color: "white"}} onClick={() => {
                                                    this.setState({
                                                        add_visible: true
                                                    })
                                                }}><i className={"fa fa-plus"}></i> {i18n.t('tambahuser')}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body daftar-shift pt-3">
                                    {!this.state.isLoading && this.state.data.length === 0 ?
                                        <Empty description={i18n.t('datakosong')}
                                               image={EmptyDataImage}
                                               className="ipresens-image-empty mb-5"/>
                                        :
                                        <div>
                                            <Table striped className="mb-5 table-striped table table-borderless">
                                                <Thead>
                                                    <Tr>
                                                        <Th width="30">#</Th>
                                                        <Th>{i18n.t('namauser')}</Th>
                                                        <Th>{i18n.t('emailuser')}</Th>
                                                        <Th className={"text-center"}>{i18n.t('wauser')}</Th>
                                                        <Th className={"text-center"}>{i18n.t('userlevel')}</Th>
                                                        <Th className={"text-center"}>{i18n.t('status')}</Th>
                                                        <Th className={"text-center"}>{i18n.t('otplogin')}</Th>
                                                        <Th className={"text-center"} width="150">{i18n.t('aksi')}</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {this.state.data.map((item, index) => {
                                                        no += 1
                                                        return (
                                                            <Tr>
                                                                <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                                <Td>
                                                                    {item.p_user_name}
                                                                </Td>
                                                                <Td>
                                                                    {item.p_user_email}
                                                                </Td>
                                                                <Td align={"center"}>
                                                                    {item.p_user_wa ? item.p_user_wa : '-'}
                                                                </Td>
                                                                <Td align={"center"}>
                                                                    {item.user_level ? item.user_level.level_name : "-"}
                                                                </Td>
                                                                <Td align={"center"}>
                                                                    <Switch
                                                                        id={`detail-${item.p_user_id}`}
                                                                        name="p_user_status"
                                                                        checked={
                                                                            item.p_user_status === '1' ? true : false
                                                                        }
                                                                        onChange={(val) => {
                                                                            this.setState({
                                                                                loading: true,
                                                                            })

                                                                            let formData = {
                                                                                id: item.p_user_id,
                                                                            }

                                                                            this.props.request('ubah_status', formData, 'POST')
                                                                                .then((response) => {
                                                                                    if (response.success) {
                                                                                        Swal.fire({
                                                                                            title: 'Berhasil!',
                                                                                            text: response.message,
                                                                                            icon: 'success',
                                                                                            showConfirmButton: false,
                                                                                            timer: 1500,
                                                                                        })
                                                                                    } else {
                                                                                        if (Array.isArray(response.message)) {
                                                                                            response.message.map(item => {
                                                                                                toast['error'](item)
                                                                                                return true
                                                                                            })
                                                                                        } else {
                                                                                            toast['error'](response.message)
                                                                                        }
                                                                                    }
                                                                                    this.loadData()
                                                                                    this.setState({loading: false})
                                                                                })

                                                                        }}
                                                                        onColor="#8c2bee"
                                                                        offColor="#eb4034"
                                                                        handleDiameter={16}
                                                                        uncheckedIcon={
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    height: "100%",
                                                                                    fontSize: 3,
                                                                                    color: "white",
                                                                                    paddingRight: -5
                                                                                }}
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={faTimes}
                                                                                    style={{fontSize: 15}}/>
                                                                            </div>
                                                                        }
                                                                        checkedIcon={
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    height: "100%",
                                                                                    fontSize: 4,
                                                                                    color: "white",
                                                                                    paddingLeft: 3
                                                                                }}
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={faCheck}
                                                                                    style={{fontSize: 15}}/>
                                                                            </div>
                                                                        }
                                                                        boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                        height={18}
                                                                        width={40}
                                                                        className="mr-1"
                                                                    />
                                                                </Td>
                                                                <Td align={"center"}>
                                                                    <Switch
                                                                        id={`detail-${item.p_user_id}`}
                                                                        name="p_user_status"
                                                                        checked={
                                                                            item.p_user_otp_status === '1' ? true : false
                                                                        }
                                                                        onChange={(val) => {
                                                                            this.setState({
                                                                                loading: true,
                                                                            })

                                                                            let formData = {
                                                                                id: item.p_user_id,
                                                                            }

                                                                            this.props.request('ubah_status_otp', formData, 'POST')
                                                                                .then((response) => {
                                                                                    if (response.success) {
                                                                                        Swal.fire({
                                                                                            title: 'Berhasil!',
                                                                                            text: response.message,
                                                                                            icon: 'success',
                                                                                            showConfirmButton: false,
                                                                                            timer: 1500,
                                                                                        })
                                                                                    } else {
                                                                                        if (Array.isArray(response.message)) {
                                                                                            response.message.map(item => {
                                                                                                toast['error'](item)
                                                                                                return true
                                                                                            })
                                                                                        } else {
                                                                                            toast['error'](response.message)
                                                                                        }
                                                                                    }
                                                                                    this.loadData()
                                                                                    this.setState({loading: false})
                                                                                })

                                                                        }}
                                                                        onColor="#8c2bee"
                                                                        offColor="#eb4034"
                                                                        handleDiameter={16}
                                                                        uncheckedIcon={
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    height: "100%",
                                                                                    fontSize: 3,
                                                                                    color: "white",
                                                                                    paddingRight: -5
                                                                                }}
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={faTimes}
                                                                                    style={{fontSize: 15}}/>
                                                                            </div>
                                                                        }
                                                                        checkedIcon={
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    alignItems: "center",
                                                                                    height: "100%",
                                                                                    fontSize: 4,
                                                                                    color: "white",
                                                                                    paddingLeft: 3
                                                                                }}
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={faCheck}
                                                                                    style={{fontSize: 15}}/>
                                                                            </div>
                                                                        }
                                                                        boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                        height={18}
                                                                        width={40}
                                                                        className="mr-1"
                                                                    />
                                                                </Td>
                                                                <Td align={"center"}>
                                                                    <Tooltip trigger={'click'} zIndex={1}
                                                                             placement="bottom"
                                                                             color={'#fff'} title={
                                                                        <div className="text-dark">
                                                                            <a className={'text-dark'}
                                                                               onClick={() => {
                                                                                   this.setState({
                                                                                       p_user_id: item.p_user_id,
                                                                                       detail_visible: true,
                                                                                       currentPageDetail: 0
                                                                                   }, ()=> this.loadDetailUser(item.p_user_id))
                                                                               }}>Detail
                                                                            </a><br/>
                                                                            <a onClick={() => this.setState({
                                                                                modalHistory: true,
                                                                                p_user_id: item.p_user_id,
                                                                            }, () => {
                                                                                this.loadHistory(item.p_user_id)
                                                                            })}>{i18n.t('riwayat')}</a><br/>
                                                                            <a onClick={() => {
                                                                                this.setState({
                                                                                    p_user_id: item.p_user_id,
                                                                                    p_user_name: item.p_user_name,
                                                                                    p_user_email: item.p_user_email,
                                                                                    p_user_wa: item.p_user_wa ? item.p_user_wa : "",
                                                                                    p_user_level: item.user_level ? item.user_level.level_id : "",
                                                                                    visable: true
                                                                                })
                                                                            }}>
                                                                                Edit
                                                                            </a><br/>
                                                                            <a onClick={() => {
                                                                                this.setState({
                                                                                    idDel: item.p_user_id
                                                                                })
                                                                                this.wantDelete()
                                                                            }}>{i18n.t('hapus')}</a>
                                                                        </div>
                                                                    }>
                                                                        <a><i
                                                                            className="fa fa-ellipsis-h"
                                                                            style={{
                                                                                width: 35,
                                                                                fontSize: 18,
                                                                                padding: 11,
                                                                                color: 'rgb(51 51 51)'
                                                                            }}
                                                                        ></i></a>
                                                                    </Tooltip>
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    })}
                                                    {this.state.isLoading &&
                                                        <Tr>
                                                            <Td colSpan="8">
                                                                <Spin style={{width: '100%', paddingTop: "50px"}}
                                                                      size="large" tip={i18n.t('mohontunggu')}/>
                                                            </Td>
                                                        </Tr>
                                                    }
                                                </Tbody>
                                            </Table>
                                            <Paging
                                                className="float-right"
                                                handlePageClick={this.handlePageClick}
                                                pageCount={this.state.pageCount}
                                                currentPage={this.state.currentPage}
                                            />
                                        </div>

                                    }
                                </div>
                            </div>
                        </div>
                    </Fragment>
                </div>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t("tidakadaakseshalaman")}</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    api_token: state.Auth.api_token,
    user: state.Auth.user
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, auth_key, data) => dispatch(set_auth(api_token, auth_key, data)),
    clear_auth: () => dispatch(clear_auth())
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPerusahaan);
