import React, { Component } from 'react'
import { Button, Drawer, Form, Select } from 'antd';
import Swal from "sweetalert2";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";

class UserLevelAdd extends Component {
    constructor() {
        super();
        this.state = {
            level_name: '',
            level_page_limited: null,
            level_divisi_limited: null,
            loading: false
        }
    }

    levelHandler = () => {
        if (this.state.level_page_limited == null) {
            this.setState({
                level_page_limited: ''
            })
        } else {
            this.setState({
                level_page_limited: null
            })
        }
    }

    divisiHandler = () => {
        if (this.state.level_divisi_limited == null) {
            this.setState({
                level_divisi_limited: ''
            })
        } else {
            this.setState({
                level_divisi_limited: null
            })
        }
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        let form = {
            level_name: this.state.level_name,
            level_page_limited: this.state.level_page_limited,
            level_divisi_limited: this.state.level_divisi_limited,
        }

        this.props.request("userlevel/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({ level_name: '', level_page_limited: '' })
                    this.props.changeState({
                        level_name: '',
                        level_page_limited: '',
                        level_divisi_limited: '',
                        add_visible: false,
                        data: [],
                        isLoading: true,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t(response.message),
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t(response.message),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false
                })
            });
    }

    render() {
        const { Option } = Select;
        const tipe = this.props.perusahaan_level.perusahaan_type === "sekolah";
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.add_visible} toggle={() => this.props.changeState({ add_visible: false })} style={{ maxWidth: "700px" }}>
                <ModalHeader>
                    <div className='text-dark' style={{
                        fontWeight: 'bold', fontSize: "18px"
                    }}>{i18n.t("tambahuserlevel")}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="pl-3 pr-3">
                        <Form layout="vertical" hideRequiredMark>
                            <div className="form-group">
                                <label>{i18n.t('namauserlevel')}</label>
                                <input name="level_name" className="form-control col-xl-12 col-md-12" placeholder={i18n.t('namauserlevel')}
                                       type="text" required="" value={this.state.level_name}
                                       onChange={(event) => {
                                           const target = event.target;
                                           const value = target.value;
                                           const name = target.name;
                                           this.setState({
                                               [name]: value
                                           });
                                       }} />
                            </div>
                            <div className="form-group">
                                <label>{i18n.t('akseshalamanuser')}</label><br />
                                <label>
                                    <input type="checkbox"
                                           checked={this.state.level_page_limited === null}
                                           onChange={() => this.levelHandler()} />
                                    &nbsp;{i18n.t('seluruhakseshalaman')}
                                </label>
                                {this.state.level_page_limited !== null &&
                                    <Select name="level_page_limited"
                                            style={{ width: '100%' }}
                                            mode={"multiple"}
                                            placeholder={i18n.t('pilihakaseshalaman')}
                                            onChange={(event) => {
                                                this.setState({
                                                    level_page_limited: event
                                                })
                                            }}
                                            options={tipe ?
                                                [
                                                    { label: i18n.t('pengumuman'), value: 'Pengumuman' },
                                                    {
                                                        label: i18n.t('aplikasi'),
                                                        options: [
                                                            { label: i18n.t('banneraplikasi'), value: 'Banner Aplikasi' }
                                                        ],
                                                    },
                                                    {
                                                        label: i18n.t('masterdata'),
                                                        options: [
                                                            { label: i18n.t('kelas'), value: 'Kelas' },
                                                            { label: i18n.t('mapel'), value: 'Mata Pelajaran' },
                                                            { label: i18n.t('jambelajar'), value: 'Jam Belajar' },
                                                            { label: i18n.t('jadwal'), value: 'Jadwal' },
                                                            { label: i18n.t('lokasipresensi'), value: 'Lokasi Presensi' },
                                                            { label: i18n.t('semester'), value: 'Semester' }
                                                        ],
                                                    },
                                                    {
                                                        label: i18n.t('pengajuansiswa'),
                                                        options: [
                                                            { label: i18n.t('pengajuanizin'), value: 'Pengajuan Izin/Cuti' }
                                                        ],
                                                    },
                                                    { label: i18n.t('siswa'), value: 'Siswa' },
                                                    { label: i18n.t('guru'), value: 'Guru' },
                                                    { label: i18n.t('presensi'), value: 'Presensi' },
                                                    { label: i18n.t('agenda'), value: 'Agenda' },
                                                    { label: i18n.t('tugas'), value: 'Tugas' },
                                                    { label: i18n.t('nilai'), value: 'Nilai' },
                                                    { label: i18n.t('pembayaran'), value: 'Pembayaran' },
                                                    { label: i18n.t('langganan'), value: 'Langganan' },
                                                    { label: i18n.t('pengaturan'), value: 'Pengaturan' },
                                                ]
                                                :
                                                [
                                                    { label: i18n.t('pengumuman'), value: 'Pengumuman' },
                                                    {
                                                        label: i18n.t('aplikasi'),
                                                        options: [
                                                            { label: i18n.t('banneraplikasi'), value: 'Banner Aplikasi' }
                                                        ],
                                                    },
                                                    {
                                                        label: i18n.t('masterdata'),
                                                        options: [
                                                            { label: i18n.t('shift'), value: 'Shift' },
                                                            { label: i18n.t('jamkerja'), value: 'Jam Kerja' },
                                                            { label: i18n.t('divisi'), value: 'Divisi' },
                                                            { label: i18n.t('jabatan'), value: 'Jabatan' },
                                                            { label: i18n.t('lokasikunjungan'), value: 'Lokasi Kunjungan' },
                                                            { label: i18n.t('lokasikerja'), value: 'Lokasi Kerja' },
                                                            { label: i18n.t('daftaristillah'), value: 'Daftar Istillah' },
                                                        ],
                                                    },
                                                    { label: i18n.t('inventaris'), value: 'Inventaris' },
                                                    {
                                                        label: i18n.t('pengajuankaryawan'),
                                                        options: [
                                                            { label: i18n.t('pengajuanizin'), value: 'Pengajuan Izin' },
                                                            { label: i18n.t('pengajuanlembur'), value: 'Pengajuan Lembur' },
                                                            { label: i18n.t('pengajuankasbon'), value: 'Pengajuan Kasbon' },
                                                            { label: i18n.t('pengajuanklaimbeban'), value: 'Pengajuan Klaim beban' },
                                                        ],
                                                    },
                                                    { label: i18n.t('karyawan'), value: 'Karyawan' },
                                                    { label: i18n.t('presensi'), value: 'Presensi' },
                                                    { label: i18n.t('agenda'), value: 'Agenda' },
                                                    { label: i18n.t('kunjungan'), value: 'Kunjungan' },
                                                    { label: i18n.t('tugas'), value: 'Tugas' },
                                                    { label: 'BPJS', value: 'Pengaturan BPJS' },
                                                    {
                                                        label: i18n.t('payroll'),
                                                        options: [
                                                            { label: i18n.t('daftargajikaryawan'), value: 'Daftar Gaji Karyawan' },
                                                            { label: i18n.t('daftaristilah'), value: 'Daftar Istilah' },
                                                            { label: i18n.t('daftarpayroll'), value: 'Daftar Payroll' },
                                                            { label: i18n.t('pengaturanpayroll'), value: 'Pengaturan Payroll' },
                                                        ],
                                                    },
                                                    { label: i18n.t('pembayarankasbon'), value: 'Pembayaran Kasbon' },
                                                    { label: i18n.t('langganan'), value: 'Langganan' },
                                                    { label: i18n.t('pengaturan'), value: 'Pengaturan' },
                                                ]
                                    }
                                    >
                                    </Select>
                                }
                            </div>
                            <div className="form-group">
                                <label>{i18n.t('aksesdivisikaryawan')}</label><br />
                                <label>
                                    <input type="checkbox"
                                           checked={this.state.level_divisi_limited === null}
                                           onChange={() => this.divisiHandler()} />
                                    &nbsp;{i18n.t('seluruhaksesdivisi')}
                                </label>
                                {this.state.level_divisi_limited !== null &&
                                    <Select name="level_divisi_limited"
                                            style={{ width: '100%' }}
                                            mode={"multiple"}
                                            placeholder={i18n.t('pilihaksesdivisi')}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            onChange={(event) => {
                                                this.setState({
                                                    level_divisi_limited: event
                                                })
                                            }}
                                    >
                                        {this.props.divisi.map((item, index) => {
                                            return (
                                                <Option value={item.divisi_id}>{item.divisi_name}</Option>
                                            )
                                        })}
                                    </Select>
                                }
                            </div>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({ add_visible: false, p_user_name: '' });
                        }}
                            style={{ marginRight: 8 }}>
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color" onClick={this.handleSubmit} disabled={this.state.loading}>
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>

        )
    }
}

export default UserLevelAdd
