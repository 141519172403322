import React, { Component } from 'react'
import {Empty, Spin} from "antd";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import 'react-tabs/style/react-tabs.css';
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import {pageNumber} from "../../../functions/functionGeneral";
import EmptyDataImage from "../../../assets/images/data-kosong.png"
import i18n from "i18next";
import Paging from "../../paging";

export class DetailPerusahaanUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
        this.handlePageClick = this.handlePageClick.bind(this)
    }

    handlePageClick(data){
        let selected = data.selected;
        this.props.changeState({currentPageDetail : selected, loading: true}, () => {
            this.props.loadDetailUser()
        })
    }

    render() {
        let no = 0;
        return (
            <Modal style={{maxWidth: '1000px'}} isOpen={this.props.detail_visible} toggle={() => this.props.changeState({ detail_visible: false })}>
                <ModalHeader toggle={() => this.props.changeState({detail_visible: false})}>
                    <h3 className="fw-900">{i18n.t("detailperusahaanuser")}</h3>
                </ModalHeader>
                <ModalBody style={{minHeight: "530px"}}>
                    <div className="card-header">
                        <div className="row">
                            <h1 className="menu-header-title"
                                style={{color: "#143B5E"}}>{this.props.dataUser.p_user_name}</h1>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row flex-nowrap">
                                    <div className='col-3'>
                                        <p className="mb-0">Email</p>
                                    </div>
                                    <div className='col-9'>
                                        <h6 className="mb-0" style={{
                                            fontSize: 12,
                                            color: "#143B5E"
                                        }}>: {this.props.dataUser && this.props.dataUser.p_user_email ? this.props.dataUser.p_user_email : '-'}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-3'>
                                        <p className="mb-0">Wa</p>
                                    </div>
                                    <div className='col-9'>
                                        <h6 className="mb-0" style={{
                                            fontSize: 12,
                                            color: "#143B5E"
                                        }}>: {this.props.dataUser.p_user_wa && this.props.dataUser.p_user_wa ? this.props.dataUser.p_user_wa : '-'}</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='col-3'>
                                        <p className="mb-0">Level</p>
                                    </div>
                                    <div className='col-9'>
                                        <h6 className="mb-0" style={{
                                            fontSize: 12,
                                            color: "#143B5E"
                                        }}>: {this.props.dataUser.user_level?.level_name}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="col">
                            <h4 className="fw-900">{i18n.t("daftaraktivitasuser")}</h4>
                        </div>
                        <Table className="table table-striped table-borderless">
                            <Thead>
                                <Tr>
                                    <Th width="30">#</Th>
                                    <Th>User</Th>
                                    <Th>{i18n.t("tipe")}</Th>
                                    <Th width="200">{i18n.t("tanggal")}</Th>
                                </Tr>
                            </Thead>
                            {this.props.loading ?
                                <Tr>
                                    <Td colSpan={5} style={{textAlign: "center", backgroundColor: "#FFFFFF"}}>
                                        <Spin size="large" tip={i18n.t("sedangmemuatdata")+("...")} style={{
                                            width: '100%',
                                            margin: 'auto',
                                            marginBottom: '10px',
                                            marginTop: '10px'
                                        }}/>
                                    </Td>
                                </Tr>
                                :
                                this.props.dataActivity.length === 0 ?
                                    <Tr>
                                        <Td colSpan={5} style={{textAlign: "center", backgroundColor: "#FFFFFF"}}>
                                            <Empty description={i18n.t("datakosong")}
                                                   image={EmptyDataImage} className="ipresens-image-empty"/>
                                        </Td>
                                    </Tr>
                                    :
                                    <Tbody>
                                        {this.props.dataActivity.map((item, index) => {
                                            no += 1
                                            return (
                                                <Tr>
                                                    <small>
                                                        <Th scope="row">{pageNumber(no, this.props.perPageDetail, this.props.currentPageDetail)}</Th>
                                                    </small>
                                                    <Td>{this.props.dataUser.p_user_name}</Td>
                                                    <Td>{i18n.t(item.type)}</Td>
                                                    <Td>{item.created_at}</Td>
                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                            }
                        </Table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="row w-100 mr-1 align-items-center">
                        <div className="col-12 col-md-6 text-start order-1 order-md-1 font-14"
                             style={{fontWeight: 500, lineHeight: "24px"}}>
                            {i18n.t("menampilkan")} {this.props.dataActivity.length === 0 || this.props.dataActivity.length === null ? "0" : this.props.dataActivity.length} {i18n.t("dari")} {this.props.totalDataDetail} {i18n.t("data")}.
                        </div>
                        <div
                            className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end order-2 order-md-2">
                            <Paging
                                handlePageClick={this.handlePageClick}
                                pageCount={this.props.pageCountDetail}
                                currentPage={this.props.currentPageDetail}
                            />
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

export default DetailPerusahaanUser;