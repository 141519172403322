import React, {Component} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import {Form, Upload} from "antd";
import ImgCrop from "antd-img-crop";
import {API_HOST, IMG_HOST} from "../../helper/host";
import ImportIcon from "../../assets/icons/import-icon-blue-light.svg";
import Swal from "sweetalert2";

export class EditFacility extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            gambar: '',
            desc: '',
            nama: '',
            edit_loading: false,
            facility_id: 0,
            edit_visible: false,
            detail: [],
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleSubmit = () => {
        this.setState({
            edit_loading: true,
        })
        let  form = {
            facility_name: this.state.nama,
            facility_desc: this.state.desc,
            facility_img: this.state.gambar,
            facility_id: this.state.facility_id
        }
        this.props.request("fasilitas/edit-facility", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.changeState({
                        edit_visible: false,
                        data: [],
                        loading: true,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('fasilitasberhasildiubah'),
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('fasilitasgagaldiubah'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ edit_loading: false, fileList: [], gambar: "" })
            })
    }

    loadData() {
        let form = {
            facility_id: this.state.facility_id,
        }
        this.props.request('fasilitas/load-detail', form, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        nama: response.data.facility_name,
                        desc: response.data.facility_desc,
                    })
                    if (response.data.facility_image !== null) {
                        this.setState({
                            fileList: [
                                {
                                    uid: "-1",
                                    name: response.data.facility_image,
                                    status: "done",
                                    url: IMG_HOST + 'mitra/facility/' + response.data.facility_image
                                }
                            ]
                        })
                    }
                }
            })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.id !== prevProps.id) {
            this.setState({
                facility_id: this.props.id,
            }, () => {
                this.loadData();
            });
        }
    }

    render() {
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.edit_visible} toggle={() => this.props.changeState({ edit_visible: false })} style={{ maxWidth: "800px" }}>
                <ModalHeader toggle={() => this.props.changeState({ edit_visible: false })}>
                    <div className='text-dark' style={{
                        fontWeight: 'bold', fontSize: "18px"
                    }}>{i18n.t("editfasilitas")}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="px-2">
                        <Form layout="vertical" hideRequiredMark>
                            <div className="form-group">
                                <label>{i18n.t('foto')}</label>
                                <ImgCrop
                                    width={1920}
                                    height={700}>
                                    <Upload action={API_HOST} listType="picture-card"
                                            style={{width: '400px !important'}}
                                            fileList={this.state.fileList}
                                            onChange={(file) => {
                                                this.setState({fileList: file.fileList})

                                                if (file.fileList.length > 0) {
                                                    let files = file.file.originFileObj
                                                    let reader = new FileReader()

                                                    reader.readAsDataURL(files)
                                                    reader.onloadend = () => {
                                                        this.setState({
                                                            gambar: {
                                                                name: file.file.name,
                                                                file: reader.result
                                                            }
                                                        })
                                                    }
                                                } else {
                                                    this.setState({gambar: []})
                                                }
                                            }}>
                                        {this.state.fileList.length >= 1 ? null :
                                            <div className="p-2">
                                                <img alt='' src={ImportIcon}
                                                     style={{width: "32px", height: "32px"}}/><br/>
                                                <div style={{
                                                    fontSize: "12px",
                                                    lineHeight: "16px",
                                                    color: "#5D6F80",
                                                    marginTop: "8px"
                                                }}>{i18n.t('uploadgambar')}</div>
                                                <br/>
                                                <a className="btn btn-outline-custom tambah-karyawan">{i18n.t('carigambar')}</a>
                                            </div>
                                        }
                                    </Upload>
                                </ImgCrop>
                            </div>
                            <div className="form-group">
                                <label htmlFor="nama">{i18n.t('nama')}</label>
                                <input type="text" name="nama" id="nama" placeholder={i18n.t('namafasilitas')}
                                       className="form-control" onChange={this.handleChange} value={this.state.nama}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="desc">{i18n.t('deskripsi')}</label>
                                <input type="text" name="desc" id="desc" placeholder={i18n.t('deskripsifasilitas')}
                                       className="form-control" onChange={this.handleChange} value={this.state.desc}/>
                            </div>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({edit_visible: false, desc: ''});
                        }}
                                style={{marginRight: 8}}>
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color"
                                disabled={this.state.add_loading} onClick={this.handleSubmit} disabled={this.state.edit_loading}>
                            {this.state.edit_loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}

export default EditFacility;