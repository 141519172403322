import React, {Component} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import i18n from "i18next";
import Select from "react-select";
import Swal from "sweetalert2";

export class AddPerusahaanTunjangan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            divisi: [],
            divisi_loading: false,
            divisi_id: 0,
            divisi_selected: null,
            add_loading: false,
            nama_tunjangan: '',
            deskripsi_tunjangan: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    loadDivisi() {
        this.props.request('tunjangan/load-divisi', 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        divisi: response.divisi,
                        divisi_loading: false,
                    })
                }
            })
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleSubmit = () => {
        this.setState({ add_loading: true })
        let form = {
            divisi_id: this.state.divisi_id,
            nama_tunjangan: this.state.nama_tunjangan,
            deskripsi_tunjangan: this.state.deskripsi_tunjangan,
        }
        this.props.request("tunjangan/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.changeState({
                        add_visible: false,
                        loading: true,
                        data: [],
                    }, this.props.loadData())
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('databerhasilditambahkan'),
                        icon: 'success'
                    })
                    this.setState({
                        divisi_id: 0, divisi_selected: null, nama_tunjangan: '', deskripsi_tunjangan: '',
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('datagagalditambahkan'),
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({ add_loading: false })
            })
    }

    render() {
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.add_visible} toggle={() => this.props.changeState({ add_visible: false })} style={{ maxWidth: "900px" }}>
                <ModalHeader toggle={() => this.props.changeState({ add_visible: false })}>
                    <div className='text-dark' style={{
                        fontWeight: 'bold', fontSize: "18px"
                    }}>{i18n.t("tambahtunjanganperusahaan")}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex flex-column">
                        <div className="form_control">
                            <label className="">{i18n.t('namadivisi')}</label>
                            <Select
                                className="font-edit-value"
                                placeholder={i18n.t("namadivisi")}
                                backspaceRemoves={true}
                                deleteRemoves={true}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null
                                }}
                                value={this.state.divisi_selected}
                                isLoading={this.state.divisi_loading}
                                onFocus={() => this.setState({divisi_loading: true}, () => this.loadDivisi())}
                                onBlur={() => this.setState({divisi_loading: false})}
                                onInputChange={(val) => this.setState({divisi_name: val}, () => this.setState({divisi_loading: true}, () => this.loadDivisi()))}
                                options={
                                    this.state.divisi != null ? (
                                            this.state.divisi.map((item, index) =>
                                                (
                                                    {
                                                        key: index,
                                                        value: item.divisi_id,
                                                        label: item.divisi_name,
                                                    }
                                                )
                                            ))
                                        : ("")
                                }
                                onChange={
                                    (divisi_selected) => {
                                        if (!divisi_selected) {
                                            this.setState({
                                                divisi_selected: null,
                                                divisi_id: 0,
                                            })
                                        } else {
                                            this.setState({
                                                divisi_selected,
                                                divisi_id: divisi_selected.value,
                                                divisi: []
                                            })
                                        }
                                    }
                                }
                                isClearable={() => {
                                    this.setState({
                                        isClearable: true,
                                    })
                                }}
                            />
                        </div>
                        <div className="form_control">
                            <label htmlFor="">{i18n.t('namatunjangan')}</label>
                            <input type="text" name="nama_tunjangan" className="form-control" placeholder={i18n.t('namatunjangan')} onChange={this.handleChange}/>
                        </div>
                        <div className="form_control">
                            <label htmlFor="">{i18n.t('deskripsi')}</label>
                            <textarea name="deskripsi_tunjangan" className="form-control" placeholder={i18n.t('deskripsitunjangan')} onChange={this.handleChange}></textarea>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({add_visible: false});
                        }}
                                style={{marginRight: 8}}>
                            {i18n.t("batal")}
                        </button>
                        <button type="button" className="btn ipresens-btn-color"
                                disabled={this.state.add_loading} onClick={this.handleSubmit} disabled={this.state.add_loading}>
                            {this.state.add_loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> {i18n.t("loading")}</>
                                :
                                i18n.t("simpan")
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}
export default AddPerusahaanTunjangan;