import React, { Component, Fragment } from 'react'
import moment from 'moment';
import { APIKEY, IMG_HOST } from "../../../helper/host";
import { Empty, Spin } from "antd";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Paging from "../../paging";
import FormDateRangePicker from "../../karyawan/form/components/DatePicker/Examples/DateRangeFilter";
import { pageNumber } from "../../../functions/functionGeneral";
import { GoogleApiWrapper, InfoWindow, Map, Marker, Polyline } from 'google-maps-react';
import EmptyDataImage from "../../../assets/images/data-kosong.png";

export class kunjungan extends Component {
    constructor(props) {
        super(props);
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        this.state = {
            loading: false,
            jabatan: [],
            lokasi: [],
            data_karyawan: [],
            absen: [],
            laporan: [],
            jabatan_karyawan: [],
            data_kunjungan: [],
            divisi_karyawan: [],
            lokasi_karyawan: [],
            kunjungan: [],
            outlet: [],
            outlet_karyawan: null,
            year: tahun,
            month: namabulan[bulan],
            add_visible: false,
            modalmaps: false,
            pageCount: 0,
            pagCount: 0,
            pageCountKunjungan: 0,
            currentPage: 0,
            currentPageKunjungan: 0,
            totalData: 0,
            totalDataKunjungan: 0,
            perPage: 0,
            pePage: 0,
            perPageKunjungan: 0,
            kunjunganStartDate: '',
            kunjunganEndDate: '',
            location: {
                lat: -7.865242,
                lng: 110.156070
            },

            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: [],
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.maps = this.maps.bind(this);
        this.list = this.list.bind(this);
    }

    resetFilter(event) {
        this.setState({

            kunjunganStartDate: '',
            kunjunganEndDate: '',
            data_kunjungan: [],
            loading: true
        }, () => this.loadDataKaryawan())
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadDataKaryawan())
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    maps() {
        this.setState({ modalmaps: true, visible: false });
    }
    list() {
        this.setState({ modalmaps: false, visible: true });
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, absen: [] }, () => {
            this.loadDataKaryawan();
        });
    }

    handlePageClickOutlet(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, data_kunjungan: [] }, () => {
            this.loadDataKaryawan();
        });
    }

    handlePageClickKunjungan(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPageKunjungan: selected, loading: true, kunjungan: [] }, () => {
            this.loadKunjungan();
        });
    }

    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;

        this.setState({
            data: [],
            loading: true,
            kunjunganStartDate: start,
            kunjunganEndDate: end
        }, () => {
            this.loadDataKaryawan()
        });
    }

    loadDataKaryawan() {
        this.setState({ loading: true })
        let form = {
            kunjunganStartDate: this.state.kunjunganStartDate,
            kunjunganEndDate: this.state.kunjunganEndDate,
            id_karyawan: this.props.match.params.id,
            month: this.state.month,
            page: this.state.currentPage + 1,
            year: this.state.year,
        }
        this.props.request("karyawan/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_karyawan: response.data,
                        laporan: response.laporan,
                        absen: response.absen.data,
                        data_kunjungan: response.kunjungan.data,
                        jabatan_karyawan: response.data.jabatan,
                        divisi_karyawan: response.data.divisi,
                        lokasi_karyawan: response.data.lokasi,
                        totalData: response.absen.total,
                        pageCount: response.absen.last_page,
                        perPage: response.absen.per_page,
                        pagCount: response.kunjungan.last_page,
                        pePage: response.kunjungan.per_page,
                        visible: true
                    })
                }
                this.setState({ loading: false })
            });
    }

    loadKunjungan() {
        this.setState({ loading: true })
        let form = {
            karyawan_id: this.props.match.params.id,
            page: this.state.currentPageKunjungan + 1,
        }
        this.props.request("karyawan/outlet_by_karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        kunjungan: response.data.data,
                        totalDataKunjungan: response.data.total,
                        pageCountKunjungan: response.data.last_page,
                        perPageKunjungan: response.data.per_page,
                    })
                }
                this.setState({ loading: false })
            });
    }

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

    componentDidMount() {
        this.loadDataKaryawan()
        this.loadKunjungan()
    }

    render() {
        let mo = 0;

        var triangleCoords = []
        this.state.data_kunjungan.map((item) => {
            triangleCoords.push({ lat: item.latitude_kunjungan, lng: item.longitude_kunjungan })
        })

        const stylefoto = {
            maxHeight: '50px',
            maxWidth: '50px',
        }
        let formList;
        let formMap;
        if (this.state.visible) {
            formList =
                <div className="card-body">

                    <button type="button"
                        className="btn btn-info"
                        color="info"
                        onClick={this.maps}
                    >
                        Maps
                    </button>
                    <Table striped className="mb-5">
                        <Thead>
                            <Tr>
                                <Th>#</Th>
                                <Th className="text-center">Lokasi Kunjungant</Th>
                                <Th className="text-center">Jam Kunjung</Th>
                                <Th className="text-center">Tanggal Kunjung</Th>
                                <Th className="text-center">Keterangan</Th>
                                <Th className="text-center">Lampiran 1</Th>
                                <Th className="text-center">Lampiran 2</Th>
                                <Th className="text-center">Lampiran 3</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {this.state.loading &&
                                <Tr>
                                    <Td colSpan={6}>
                                        <Spin size="large" tip="Loading..." style={{
                                            width: '100%',
                                            margin: 'auto',
                                            marginBottom: '10px',
                                            marginTop: '10px'
                                        }} />
                                    </Td>
                                </Tr>
                            }
                            {this.state.data_kunjungan.map((item, index) => {
                                mo += 1
                                return (
                                    <Tr>
                                        <small>
                                            <Th scope="row">{pageNumber(mo, this.state.pePage, this.state.currentPage)}</Th>
                                        </small>
                                        <Td align="center">{item.outlet.outlet_nama}</Td>
                                        <Td align="center">{item.jam_kunjungan_absen}</Td>
                                        <Td align="center">{moment(item.tanggal_kunjungan).format('ll')}</Td>
                                        <Td align="center">{item.keterangan_kunjungan}</Td>
                                        <Td align="center">{item.lampiran_kunjungan_1 !== null ? (
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + item.lampiran_kunjungan_1} target={"_blank"}>
                                                <img style={stylefoto}
                                                    src={IMG_HOST + 'frontapp/kunjungan/' + item.lampiran_kunjungan_1} />
                                            </a>) : (
                                            '-')}
                                        </Td>
                                        <Td align="center">{item.lampiran_kunjungan_2 !== null ? (
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + item.lampiran_kunjungan_2} target={"_blank"}>
                                                <img style={stylefoto}
                                                    src={IMG_HOST + 'frontapp/kunjungan/' + item.lampiran_kunjungan_2} />
                                            </a>) : (
                                            '-')}
                                        </Td>
                                        <Td align="center">{item.lampiran_kunjungan_3 !== null ? (
                                            <a href={"https://services.presensi.co.id/media/frontapp/kunjungan/" + item.lampiran_kunjungan_3} target={"_blank"}>
                                                <img style={stylefoto}
                                                    src={IMG_HOST + 'frontapp/kunjungan/' + item.lampiran_kunjungan_3} />
                                            </a>) : (
                                            '-')}
                                        </Td>

                                    </Tr>
                                )
                            })}
                        </Tbody>

                    </Table>
                    <Paging
                        className="float-right"
                        handlePageClick={this.handlePageClickOutlet}
                        pageCount={this.state.pagCount}
                        currentPage={this.state.currentPage}
                    />
                </div>

        } else if (this.state.modalmaps == true) {
            formMap =
                <div className="card-body">
                    <button type="button"
                        className="btn btn-success"
                        color="info"
                        onClick={this.list}>
                        List
                    </button>
                    <div style={{
                        position: 'relative',
                        width: '100%',
                        height: '250px'
                    }}>
                        <Map google={this.props.google}
                            className={'map'}
                            onClick={this.onMapClicked}
                            initialCenter={this.state.location}

                            zoom={14}>
                            {this.state.data_kunjungan.map((item) => {
                                return (
                                    <Marker
                                        onClick={this.onMarkerClick}
                                        title={'The marker`s title will appear as a tooltip.'}
                                        name={item.outlet.outlet_nama}
                                        jam={item.jam_kunjungan_absen}
                                        position={{ lat: item.latitude_kunjungan, lng: item.longitude_kunjungan }} />
                                )


                            })
                            }
                            <Polyline
                                path={triangleCoords}
                                strokeColor="#FF0000"
                                strokeOpacity={1.8}
                                strokeWeight={2} />
                            <InfoWindow
                                marker={this.state.activeMarker}
                                visible={this.state.showingInfoWindow}>
                                <div>
                                    <h5>{this.state.selectedPlace.name}</h5>
                                    {this.state.selectedPlace.jam}
                                </div>
                            </InfoWindow>
                        </Map>
                    </div>
                </div>



        }
        else {

            formList = ''
        }
        return (
            <Fragment>


                <div className="container-fluid">

                    {/*List Kunjungan*/}
                    {this.state.loading ?
                        <Spin size="large" tip="Sedang memuat data..." style={{
                            width: '100%',
                            margin: 'auto',
                            marginBottom: '10px',
                            marginTop: '10px'
                        }} />
                        :
                        <div className="card">
                            <div className="card-header">
                                <h5>Data Kunjungan Karyawan</h5>


                                <div className="row filter" style={{ marginLeft: '5px' }}>
                                    <div className="col-md-3">
                                        <label style={{ fontSize: '10px' }}>Filter Tanggal Kunjungan:</label>
                                        <FormDateRangePicker
                                            onSelect={(event) => {
                                                this.handleDate(event)
                                            }} />
                                    </div>
                                    <div className="col-md-2">
                                        <label style={{ fontSize: '10px' }}>Atur Ulang Filter:</label><br />
                                        <button className="btn btn-danger" onClick={this.resetFilter}><span
                                            className="fa fa-refresh"></span> Atur Ulang
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {!this.state.loading && this.state.data_kunjungan.length === 0 ?
                                    <Empty description="Data Kosong"
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    <div>
                                        {formList}
                                        {formMap}
                                    </div>

                                }
                            </div>
                        </div>
                    }
                </div>
            </Fragment>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: APIKEY
})(kunjungan)
