import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import moment from 'moment';
import 'moment/locale/id'
import { APIKEY, DEFAULT_IMG, IMG_HOST } from "../../helper/host";
import { Spin } from 'antd';
import { Helmet } from "react-helmet";
import format from "format-number";
import "./style.css"
import "./content.css"
import html2canvas from "html2canvas";
import "./css/slipGaji.css"
import pdfMake from "pdfmake";
import presensiHeaderModalBG from "../../assets/images/modal_header_bg.png";
import presensiWhiteLogo from "../../assets/images/presensi_white_logo.png";

export class Detail_payroll extends Component {
    constructor(props) {
        super(props);
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var date = new Date();
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        var bulan = lastDay.getMonth();
        var tahun = lastDay.getFullYear();
        this.state = {
            data: [],
            loading: true,
            kompensasi: [],
            karyawan: [],
            gaji: [],
            penghasilan: [],
            perusahaan: [],
            year: tahun,
            month: namabulan[bulan],
        }
    }

    loadData() {
        this.setState({
            loading: true,
        })
        let form = {
            id: this.props.match.params.id
        }
        this.props.request("payroll/slip_gaji", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        kompensasi: response.data.kompensasi,
                        potongan: response.data.potongan,
                        karyawan: response.data.karyawan,
                        gaji: response.gaji,
                        penghasilan: response.penghasilan,
                        perusahaan: response.data.perusahaan
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    printDocumentFour = () => {
        var name = this.state.karyawan.karyawan_nama;
        var periode = this.state.data.payroll_date;
        var id = this.state.karyawan.karyawan_id;
        html2canvas(document.getElementById("content"), { useCORS: true, }).then(canvas => {
            var data = canvas.toDataURL();
            var pdfExportSetting = {
                content: [
                    {
                        image: data,
                        width: 500
                    }
                ]
            };
            pdfMake.createPdf(pdfExportSetting).download(name + '-' + periode + '-' + id + '.pdf');
        });
    };

    componentDidMount() {
        this.loadData()
    }

    render() {
        moment.locale('id');

        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Detail Payroll - Presensi</title>
                    <meta name="description" content="Detail Payroll" />
                </Helmet>
                <Breadcrumb title="Detail Payroll" parent="Payroll" parentLink='/Daftar Gaji' />
                <div className="container-fluid">
                    <div className="col-xl-12">
                        <div className="card">
                            {this.state.loading ?
                                <div className="card-body">
                                    <Spin size="large" tip="Sedang memuat data..." style={{
                                        width: '100%',
                                        margin: 'auto',
                                        marginBottom: '10px',
                                        marginTop: '10px'
                                    }} />
                                </div>
                                :
                                <div className="card-body">
                                    <div>
                                        <button className={'btn btn-info'} style={{ float: "right" }} onClick={this.printDocumentFour}>Export pdf</button>
                                    </div>
                                    <div className="page">
                                        <div id="content" className="subpage">
                                            <div className="">
                                                <div>
                                                    <div className="row bg-blue-dark p-3 text-white border-bottom-blue" style={{ backgroundImage: `url(${presensiHeaderModalBG})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                                                        <div className="col-4 d-flex align-items-center">
                                                            <img src={presensiWhiteLogo} alt="Logo" style={{ height: '26px' }} />
                                                        </div>
                                                        <div className="col-8 text-right">
                                                            <b>{moment(this.state.data.payroll_year + '-' + this.state.data.payroll_month + '-01').format('MMMM YYYY')}</b><br />
                                                            <span>{this.state.perusahaan.perusahaan_nama}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-3 border-btm-gray">
                                                    <div className="col">
                                                        <div className="text-muted font-weight-bold mb-2">DARI</div>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <img className="align-self-center pull-right rounded-circle blur-up lazyloaded" src={this.state.perusahaan.perusahaan_foto !== null ? IMG_HOST + 'mitra/' + this.state.perusahaan.perusahaan_foto : DEFAULT_IMG} alt="header-user" style={{ width: "40px", height: "40px", objectFit: "cover" }} />
                                                            </div>
                                                            <div>
                                                                <div className="text-blue-dark font-weight-bold fs-14">{this.state.perusahaan.perusahaan_nama}</div>
                                                                <div className="text-muted">{this.state.perusahaan.perusahaan_alamat}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="text-muted font-weight-bold mb-2">UNTUK</div>
                                                        <div>
                                                            <div className="text-blue-dark font-weight-bold fs-14">{this.state.karyawan.karyawan_nama}</div>
                                                            <div className="text-muted">{this.state.karyawan.divisi.divisi_name} - {this.state.karyawan.jabatan.jabatan_nama}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-6">
                                                        <div className="text-muted font-weight-bold mb-2">PENDAPATAN</div>
                                                        <div className="pl-3 pr-1">
                                                            <div className="row align-items-center justify-content-between pb-1">
                                                                <div>
                                                                    Gaji Pokok
                                                                </div>
                                                                <div className="text-blue-dark">
                                                                    Rp {format({
                                                                        prefix: '',
                                                                        integerSeparator: "."
                                                                    })(this.state.gaji)}
                                                                </div>
                                                            </div>
                                                            {this.state.kompensasi.map((item, index) => {
                                                                return (
                                                                    <div className="row align-items-center justify-content-between pb-1">
                                                                        <div>
                                                                            {item.payroll_term.p_term_name}
                                                                        </div>
                                                                        <div className="text-blue-dark">
                                                                            Rp {format({ prefix: '', integerSeparator: "." })(item.p_kompensasi_total)}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div className="row align-items-center justify-content-between pt-2" style={{ borderTop: "1px dotted #DDDDDD" }}>
                                                                <div>
                                                                    <strong>Total</strong>
                                                                </div>
                                                                <div>
                                                                    <strong>Rp {format({
                                                                        prefix: '',
                                                                        integerSeparator: "."
                                                                    })(this.state.penghasilan)}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="text-muted font-weight-bold mb-2">POTONGAN</div>
                                                        <div className="pr-3 pl-3">
                                                            {this.state.potongan.map((item, index) => {
                                                                return (
                                                                    <div className="row align-items-center justify-content-between pb-1">
                                                                        <div>
                                                                            {item.payroll_term.p_term_name}
                                                                        </div>
                                                                        <div className="text-blue-dark">
                                                                            Rp {format({ prefix: '', integerSeparator: "." })(item.p_potongan_total)}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div className="row align-items-center justify-content-between pt-2" style={{ borderTop: "1px dotted #DDDDDD" }}>
                                                                <div>
                                                                    <strong>Total</strong>
                                                                </div>
                                                                <div>
                                                                    <strong>Rp {format({
                                                                        prefix: '',
                                                                        integerSeparator: "."
                                                                    })(this.state.data.payroll_potongan)}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bg-light mt-2 px-4 py-2">
                                                    <div className="row align-items-center justify-content-between fs-14 font-weight-bold text-blue-dark">
                                                        <div>
                                                            <strong>TAKE HOME PAY</strong>
                                                        </div>
                                                        <div>
                                                            <strong>Rp {format({ prefix: '', integerSeparator: "." })(this.state.data.payroll_total)}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="text-center text-secondary mt-2">
                                                        <span>Presensi.co.id @2020</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Detail_payroll;
