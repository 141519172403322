import moment from "moment";
import i18n from 'i18next';

export const salam = () => {
    var now = moment().format('HH')

    if(now>=0 && now<=11) {
        return  i18n.t("selamat.pagi") + ', '
    }
    else if(now >=12 && now<=14) {
        return i18n.t("selamat.siang") + ', '
    }
    else if(now >=15 && now<=18) {
        return i18n.t("selamat.sore") + ', '
    }
    else {
        return i18n.t("selamat.malam") + ', '
    }
}
