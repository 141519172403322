import React, {Component, Fragment} from "react";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import i18n from "i18next";
import {Helmet} from "react-helmet";
import Breadcrumb from "../common/breadcrumb";
import {OrgDiagram} from 'basicprimitivesreact';
import {PageFitMode, Enabled, Size } from 'basicprimitives';
import {Spin} from "antd";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import ZoomDiagram from "./zoom-diagram";

export class CompanyStructure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            modal_add: false,
            karyawan_id: 0,
            karyawan_selected: null,
            karyawan: [],
            karyawan_loading: false,
            add: false,
            parent_id: 0
        }
    }

    searchKaryawan() {
        let form = {
            karyawan_name: this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        karyawan_loading: false
                    })
                }
            })
    }

    loadData() {
        this.setState({ loading: true })
        let form = {}
        this.props.request("struktur/load-data", form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                    })
                }
                this.setState({
                    loading: false,
                })
            })
    }

    simpanChart() {
        let form = {
            karyawan_id: this.state.karyawan_id
        }
        this.props.request("struktur/save-first-chart", form, "POST")
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('berhasilmenambahkanstruktur'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('gagalmenambahkanstruktur'),
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                }
                this.setState({
                    modal_add: false, karyawan_loading: false, data: [], karyawan_id: 0, data_karyawan: [], karyawan_selected: null,
                }, () => this.loadData())
            })
    }

    addKaryawan() {
        let form = {
            parent_id: this.state.parent_id,
            karyawan_id: this.state.karyawan_id,
        }
        this.props.request("struktur/add-karyawan", form, "POST")
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('berhasilmenambahkanstruktur'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                } else {
                    Swal.fire({
                        title: i18n.t('gagal'),
                        text: i18n.t('gagalmenambahkanstruktur'),
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                }
                this.setState({
                    modal_add: false, karyawan_loading: false, karyawan_id: 0, data_karyawan: [], karyawan_selected: null, data: [],
                }, () => this.loadData())
            })
    }

    wantDelete(id) {
        Swal.fire({
            title: i18n.t('peringatan'),
            text: i18n.t('andayakininginmenghapusstrukturperusahaan'),
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: i18n.t('batal'),
            confirmButtonText: i18n.t('hapus'),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loading: true
                    }, () => this.confirmDelete(id))
                }
            })
    }

    confirmDelete(id) {
        let form = {
            id: id,
        }
        this.props.request("struktur/delete", form, 'POST')
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: i18n.t('berhasil'),
                        text: i18n.t('berhasilmenghapusdata'),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.setState({
                        data: [], loading: false
                    }, () => this.loadData())
                }
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        const isSekolah = this.props.user.perusahaan.perusahaan_type === 'sekolah';

        const config = {
            pageFitMode: PageFitMode.FitToPage,
            cousinsIntervalMultiplier: 1,
            defaultTemplateName: "lg",
            normalLevelShift: 25,
            normalItemsInterval: 30,
            autoSizeMinimum: new Size(5000, 5000),
            hasSelectorCheckbox: Enabled.False,
            cursorItem: 0,
            templates: [{
                name: "lg",
                itemSize: {width: 200, height: 65},
                onItemRender: ({context: itemConfig}) => {
                    return <>
                        <div className="" style={{cursor: "pointer", borderRadius: '4px', border: '2px solid #8C2BEE' }}>
                            <div className=" " style={{
                                padding: "2px 0 2px 0",
                                backgroundColor: '#8C2BEE',
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: '4px'
                            }}>
                                <div className="text-center px-2"
                                     style={{color: "#fff"}}>{itemConfig.jabatan_nama}</div>
                            </div>
                            <div className="px-2 d-flex justify-content-center h-100">
                                <h1 className="lgTitle text-capitalize"
                                    style={{fontSize: 12, marginTop: '10px'}}>{itemConfig.karyawan_nama}</h1>
                            </div>
                        </div>
                        <div className="buttonParent">
                            <button key="1" className="styleButton" onClick={() => this.setState({
                                parent_id: itemConfig.id,
                                modal_add: true
                            })}>
                                +
                            </button>
                            <button key="1" className="styleButton" onClick={() => this.wantDelete(itemConfig.id)}>
                                -
                            </button>
                        </div>
                    </>
                }
            }],
            items: this.state.data
        };

        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{isSekolah ? i18n.t('struktursekolah') : i18n.t('structureperusahaan')} - iPresens</title>
                    <meta name="description" content="Daftar Inventaris"/>
                </Helmet>

                <Breadcrumb title={isSekolah ? i18n.t('struktursekolah') : i18n.t('structureperusahaan')}/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card" style={{ overflow: "hidden"}}>
                                {this.state.loading ? (
                                    <div className="py-5">
                                        <Spin size="large" tip={i18n.t('loading...')} style={{
                                            width: '100%',
                                            margin: 'auto',
                                            marginBottom: '10px',
                                            marginTop: '10px'
                                        }}/>
                                    </div>
                                ) : (
                                    <ZoomDiagram height={'500px'} showZoomControls={true} footer={
                                        (this.state.data.length === 0 && !this.state.loading) && (
                                            <div className="d-flex align-items-center justify-content-end pb-2 px-3">
                                                <button className="btn ipresens-btn-color" onClick={() => this.setState({
                                                    modal_add: true,
                                                })}>Tambah</button>
                                            </div>
                                        )
                                    }>
                                        <OrgDiagram centerOnCursor={true} config={config}/>
                                    </ZoomDiagram>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <Modal size='lg' style={{maxWidth: this.state.data.length !== 0 ? "400px" : "300px", marginRight: this.state.data.length !== 0 ? null : 20, marginTop: this.state.data.length !== 0 ? 100 : null }} isOpen={this.state.modal_add}
                       toggle={() => this.setState({
                           modal_add: false, karyawan_selected: null, karyawan_id: 0, karyawan: [],
                       })}>
                    <ModalHeader toggle={() => this.setState({
                        modal_add: false, karyawan_selected: null, karyawan_id: 0, karyawan: [],
                    })}>{i18n.t('tambahkaryawan')}
                    </ModalHeader>
                    <ModalBody className='p-0' style={{height: this.state.data.length !== 0 ? '100px' : '515px' }}>
                        <div className="px-2">
                            <Select
                                className="font-edit-value"
                                placeholder={i18n.t('namakaryawan')}
                                backspaceRemoves={true}
                                deleteRemoves={true}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null
                                }}
                                value={this.state.karyawan_selected}
                                isLoading={this.state.karyawan_loading}
                                onFocus={() => this.setState({karyawan_loading: true}, () => this.searchKaryawan())}
                                onBlur={() => this.setState({karyawan_loading: false})}
                                onInputChange={(val) => this.setState({karyawan_name: val}, () => this.setState({karyawan_loading: true}, () => this.searchKaryawan()))}
                                options={
                                    this.state.karyawan != null ? (
                                            this.state.karyawan.map((item, index) =>
                                                (
                                                    {
                                                        key: index,
                                                        value: item.karyawan_id,
                                                        label: item.karyawan_nama,
                                                    }
                                                )
                                            ))
                                        : ("")
                                }
                                onChange={
                                    (karyawan_selected) => {
                                        if (!karyawan_selected) {
                                            this.setState({
                                                karyawan_selected: '',
                                                karyawan_id: 0,
                                            })
                                        } else {
                                            this.setState({
                                                karyawan_selected,
                                                karyawan_id: karyawan_selected.value,
                                                data_karyawan: []
                                            })
                                        }
                                    }
                                }
                                isClearable={() => {
                                    this.setState({
                                        isClearable: true,
                                    })
                                }}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter style={{
                        backgroundColor: "#F9F9F9",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px"
                    }} className='px-4 py-3'>
                        <button className='btn text-capitalize batalkan-add-jamker' onClick={() => {
                            this.setState({
                                modal_add: false,
                            })
                        }}>{i18n.t("batalkan")}</button>
                        <button className='btn btn-primary text-capitalize' onClick={this.state.data.length !== 0 ? () => this.addKaryawan() : () => this.simpanChart()}>{i18n.t("simpan")}</button>
                    </ModalFooter>
                </Modal>

            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyStructure);
