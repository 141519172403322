 import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import moment from 'moment';
import {APIKEY, IMG_HOST} from "../../helper/host";
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import {Else, ElseIf, If, Then} from "react-if-elseif-else-render";
import {Button, Modal, Spin} from 'antd';
import {Helmet} from "react-helmet";

export class Detail_absensi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalmasuk: false,
            modalpulang: false,
            lokasi: [],
            data: [],
            lembur: [],
            karyawan: [],
            divisi: [],
            loading: true,
            absenMasuk: {
                lat: -7.865662,
                lng: 110.154877
            },
            absenPulang: {
                lat: -7.865662,
                lng: 110.154877
            }
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    showModalMasuk = () => {
        this.setState({
            modalmasuk: true,
        });
    };

    showModalPulang = () => {
        this.setState({
            modalpulang: true,
        });
    };

    handleOk = e => {
        this.setState({
            modalmasuk: false,
            modalpulang: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modalmasuk: false,
            modalpulang: false,
        });
    };

    loadData() {
        let form = {
            id: this.props.match.params.id
        }
        this.setState({
            loading: true,
        })
        this.props.request("presensi/profil", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        lembur: response.lembur,
                        karyawan: response.data.karyawan,
                        divisi: response.data.divisi,
                        absenMasuk: {
                            lat: response.data.latitude_in,
                            lng: response.data.longitude_in,
                        },
                        absenPulang: {
                            lat: response.data.latitude_out,
                            lng: response.data.longitude_out,
                        }
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Detail Presensi - Presensi</title>
                    <meta name="description" content="Detail Presensi" />
                </Helmet>
                <Breadcrumb title="Detail Presensi" parent="Presensi" parentLink='/presence'/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="profile-details text-center">
                                        <div className="row">
                                            {this.state.loading ?
                                                <Spin size="large" tip="Sedang memuat data..." style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                    marginBottom: '10px',
                                                    marginTop: '10px'
                                                }}/>
                                                :
                                                <>
                                                <div className="col-md-6">
                                                    <p className="f-w-600 mb-0" style={{color: 'black'}}><b>Presensi
                                                        Masuk</b></p><br/>
                                                    {this.state.data.foto_in !== null ? (
                                                        <img
                                                            src={IMG_HOST + 'mitra/frontapp/absensi/' + this.state.data.foto_in}
                                                            style={{width: "80%", height: "auto"}}
                                                            className="img-fluid blur-up lazyloaded"/>) : (
                                                        <img src={IMG_HOST + 'user-profile.png'}
                                                             style={{maxWidth: "100%", maxHeight: "250px"}}
                                                             className="img-fluid blur-up lazyloaded"/>
                                                    )}
                                                    <br/><b>{this.state.data.jam_masuk_absen}</b><br/>
                                                    <If condition={this.state.data.status_in == 'Masuk Kerja'}>
                                                        <Then>
                                                            <span
                                                                className="badge badge-success">{this.state.data.status_in}</span><br/>
                                                        </Then>
                                                        <ElseIf condition={this.state.data.status_in == 'Terlambat'}>
                                                            <span
                                                                className="badge badge-danger">{this.state.data.status_in}</span><br/>
                                                        </ElseIf>
                                                        <Else>
                                                            <span
                                                                className="badge badge-warning">{this.state.data.status_in}</span><br/>
                                                        </Else>
                                                    </If><br/>
                                                    <Button type="primary" onClick={this.showModalMasuk}>Lokasi
                                                        Presensi</Button>
                                                    <Modal
                                                        title="Lokasi Presensi Masuk"
                                                        visible={this.state.modalmasuk}
                                                        onOk={this.handleOk}
                                                        onCancel={this.handleCancel}
                                                    >
                                                        <div style={{
                                                            position: 'relative',
                                                            width: '100%',
                                                            height: '250px'
                                                        }}>
                                                            <Map google={this.props.google}
                                                                 initialCenter={this.state.absenMasuk}
                                                                 zoom={15}>
                                                                <Marker/>
                                                            </Map>
                                                        </div>
                                                    </Modal>
                                                </div>
                                                <div className="col-md-6">
                                                <p className="f-w-600 mb-0" style={{color: 'black'}}><b>Presensi Pulang</b></p><br/>
                                                {this.state.data.jam_keluar_absen !== null ?
                                                    <div>
                                                        {this.state.data.foto_out !== null ? (
                                                            <img
                                                                src={IMG_HOST + 'mitra/frontapp/absensi/' + this.state.data.foto_out}
                                                                style={{width: "80%", height: "auto"}}
                                                                className="img-fluid blur-up lazyloaded"/>) : (
                                                            <img src={IMG_HOST + 'user-profile.png'}
                                                                 style={{maxWidth: "100%", maxHeight: "250px"}}
                                                                 className="img-fluid blur-up lazyloaded"/>
                                                        )}
                                                        <br/><b>{this.state.data.jam_keluar_absen}</b><br/>
                                                        <If condition={this.state.data.status_out == 'Pulang Kerja'}>
                                                            <Then>
                                                                <span
                                                                    className="badge badge-success">{this.state.data.status_out}</span><br/>
                                                            </Then>
                                                            <Else>
                                                                <span
                                                                    className="badge badge-warning">{this.state.data.status_out}</span><br/>
                                                            </Else>
                                                        </If><br/>
                                                        <Button type="primary" onClick={this.showModalPulang}>Lokasi
                                                            Presensi</Button>
                                                        <Modal
                                                            title="Lokasi Presensi Pulang"
                                                            visible={this.state.modalpulang}
                                                            onOk={this.handleOk}
                                                            onCancel={this.handleCancel}
                                                        >
                                                            <div style={{
                                                                position: 'relative',
                                                                width: '100%',
                                                                height: '250px'
                                                            }}>
                                                                <Map google={this.props.google}
                                                                     initialCenter={this.state.absenPulang}
                                                                     zoom={15}>
                                                                    <Marker/>
                                                                </Map>
                                                            </div>
                                                        </Modal>
                                                    </div>
                                                    : <div>-</div>}
                                                </div>
                                                    {this.state.lembur &&
                                                        <div className={'col-md-12 mt-2 p-2'} style={{backgroundColor: '#84ff47', borderRadius: 5}}><b>Lembur:</b> {(this.state.lembur.lembur_jumlah / 60).toFixed(2)} Jam</div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className="card">
                                {this.state.loading ?
                                    <div className="card-body">
                                        <Spin size="large" tip="Sedang memuat data..." style={{
                                        width: '100%',
                                        margin: 'auto',
                                        marginBottom: '10px',
                                        marginTop: '10px'
                                    }}/>
                                    </div>
                                    :
                                    <div className="card-body">
                                        <label
                                            className="f-20"><b>Presensi {this.state.data.absen_hari} / {moment(this.state.data.tanggal).format('ll')}</b>
                                        </label>
                                        <tbody>
                                        <tr>
                                            <td width="200">
                                                <b>Nama Karyawan</b>
                                            </td>
                                            <td width="20">
                                                :
                                            </td>
                                            <td>
                                                {this.state.karyawan.karyawan_nama}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Divisi Karyawan</b>
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td>
                                                {this.state.divisi.divisi_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Shift Karyawan</b>
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td>
                                                {this.state.data.sif_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Keterangan Presensi Masuk</b>
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td>
                                                {this.state.data.keterangan_in !== 'null' && this.state.data.keterangan_in !== '' && this.state.data.keterangan_in !== null ? this.state.data.keterangan_in : 'Keterangan Kosong'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Keterangan Presensi Pulang</b>
                                            </td>
                                            <td>
                                                :
                                            </td>
                                            <td>
                                                {this.state.data.keterangan_out !== 'null' && this.state.data.keterangan_out !== '' && this.state.data.keterangan_out !== null ? this.state.data.keterangan_in : 'Keterangan Kosong'}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: APIKEY
})(Detail_absensi)
