import React, {Component} from 'react'
import {Button, Drawer, Form} from 'antd';
import Swal from "sweetalert2";
import eyeIcon from "../../../assets/icons/eye.svg";
import eyeIconSlash from "../../../assets/icons/eye-slash-grey.svg";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import "../../styling.css"
import i18n from "i18next";

class UserAdd extends Component {
    constructor() {
        super();
        this.state = {
            p_user_name     : '',
            p_user_email    : '',
            p_user_wa    : '',
            p_user_password : '',
            p_user_level : '',
            loading         : false,
            hidePassword    : false
        }
    }
    handleSubmit = () => {
        this.setState({
            loading: true
        })
        let form = {
            p_user_name     : this.state.p_user_name,
            p_user_email    : this.state.p_user_email,
            p_user_wa    : this.state.p_user_wa,
            p_user_password : this.state.p_user_password,
            p_user_level : this.state.p_user_level,
        }

        this.props.request("add_user", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({p_user_name: ''})
                    this.props.changeState({
                        p_user_name: '',
                        add_visible: false,
                        data: [],
                        isLoading: true,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false
                })
            });
    }

    render() {
        return (
            <Modal size="lg" zIndex={999} isOpen={this.props.add_visible} toggle={() => this.props.changeState({ add_visible: false })} style={{ maxWidth: "800px" }}>
                <ModalHeader><div className='text-dark' style={{
                    fontWeight: 'bold', fontSize: "18px"
                }}>{i18n.t("tambahuser")}</div></ModalHeader>
                <ModalBody>
                    <div className="">
                        <Form layout="vertical" hideRequiredMark>
                            <div className="form-group mb-3">
                                <label>Nama User</label>
                                <input name="p_user_name" className="form-control col-xl-12 col-md-12"
                                       placeholder="Nama User..."
                                       type="text" required="" value={this.state.p_user_name}
                                       onChange={(event) => {
                                           const target = event.target;
                                           const value = target.value;
                                           const name = target.name;
                                           this.setState({
                                               [name]: value
                                           });
                                       }}/>
                            </div>
                            <div className="form-group mb-3">
                                <label>Email User</label>
                                <input name="p_user_email" className="form-control col-xl-12 col-md-12"
                                       placeholder="Email User..."
                                       type="text" required="" value={this.state.p_user_email}
                                       onChange={(event) => {
                                           const target = event.target;
                                           const value = target.value;
                                           const name = target.name;
                                           this.setState({
                                               [name]: value
                                           });
                                       }}/>
                            </div>
                            <div className="form-group mb-3">
                                <label>No. WA User (Opsional)</label>
                                <input name="p_user_wa" className="form-control col-xl-12 col-md-12"
                                       placeholder="No Wa User..."
                                       type="number" required="" value={this.state.p_user_wa}
                                       onChange={(event) => {
                                           const target = event.target;
                                           const value = target.value;
                                           const name = target.name;
                                           this.setState({
                                               [name]: value
                                           });
                                       }}/>
                            </div>
                            <div className="form-group mb-3">
                                <label>{i18n.t('passworduser')}</label>
                                <div className="input-group">
                                    <input name="p_user_password" className="form-control col-xl-12 col-md-12"
                                           placeholder="Password User..."
                                           type={this.state.hidePassword ? "text" : "password"} required=""
                                           value={this.state.p_user_password}
                                           onChange={(event) => {
                                               const target = event.target;
                                               const value = target.value;
                                               const name = target.name;
                                               this.setState({
                                                   [name]: value
                                               });
                                           }}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text"
                                              onClick={() => this.setState({hidePassword: !this.state.hidePassword})}>
                                            <img
                                                className="show-hide-button"
                                                style={{width: "16px", height: "16px", cursor: "pointer"}}
                                                src={this.state.hidePassword ? eyeIcon : eyeIconSlash}
                                                alt="Toggle Password Visibility"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label>Level User</label>
                                <select
                                    name="p_user_level"
                                    className="form-control col-xl-12 col-md-12"
                                    value={this.state.p_user_level}
                                    onChange={(event) => {
                                        const target = event.target;
                                        const value = target.value;
                                        const name = target.name;
                                        this.setState({
                                            [name]: value
                                        });
                                    }}>
                                    <option value="">Pilih Level User</option>
                                    {this.props.data.dataLevel && this.props.data.dataLevel.map((item, index) => {
                                        return (
                                            <option value={item.level_id}>{item.level_name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({add_visible: false, p_user_name: ''});
                        }}
                                style={{marginRight: 8}}>
                            Batal
                        </button>
                        <button type="button" className="btn ipresens-btn-color" onClick={this.handleSubmit}
                                disabled={this.state.loading}>
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> Loading</>
                                :
                                "Simpan"
                            }
                        </button>
                    </div>
                </ModalFooter>
            </Modal>

        )
    }
}

export default UserAdd
