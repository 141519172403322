import React, {Component} from 'react'
import {APIKEY} from "../../helper/host";
import {
    Button,
    ButtonGroup,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import CurrencyInput from 'react-currency-input';
import {GoogleApiWrapper} from 'google-maps-react';

export class ModalCutiSetting extends Component {
    constructor(props) {
        super(props);

        this.state      = {
            loading : false,
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.visiblePot} toggle={this.props.potonganModal}
                       style={{maxWidth: '900px', width: '100%'}}>
                    <ModalHeader>
                        Ubah Pengaturan Gaji Karyawan
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            {this.props.visiblePot &&
                                <Col lg="12">
                                    <FormGroup>
                                        <InputGroup>
                                            <Label>Upah Gaji</Label>
                                            <CurrencyInput
                                                name="karyawan_cuti_amount"
                                                className="form-control border-radius upah-upah"
                                                disabled={!(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0)}
                                                value={this.props.karyawan.karyawan_cuti_amount}
                                                id="karyawan_cuti_amount"
                                                onKeyPress={(event) => {
                                                    if (event.which == 13) {
                                                        event.preventDefault()
                                                        this.props.handleSubmitAmount(event, this.props.karyawan.karyawan_id)
                                                    }
                                                }}
                                                onValueChange={(value, name) => {
                                                    let newData = this.props.karyawan
                                                    newData["karyawan_cuti_amount"] = value
                                                    this.props.changeState({ karyawan: newData});
                                                }}
                                            />
                                            <InputGroupAddon addonType="prepend">
                                                <div className="input-group-text input-group-text-radius mobile-hide upah-upah"
                                                     style={{ backgroundColor: "#eef1f3" }}>
                                                    <Label
                                                        style={{ marginTop: '5px' }}>/Hari
                                                    </Label>
                                                </div>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <div className="mobile-show">
                                            <InputGroupAddon addonType="prepend">
                                                <div className="input-group-text input-group-text-radius upah-upah"
                                                     style={{ backgroundColor: "#eef1f3" }}>
                                                    <Label>/Hari</Label>
                                                </div>
                                            </InputGroupAddon>
                                        </div>
                                    </FormGroup>
                                    <br/>
                                    <FormGroup>
                                        <InputGroup>
                                            <Label>Jatah Cuti Tahunan</Label>
                                            <CurrencyInput
                                                precision="0"
                                                name="karyawan_max_cuti"
                                                className="form-control border-radius upah-upah"
                                                disabled={!(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0)}
                                                value={this.props.karyawan.karyawan_max_cuti}
                                                id="karyawan_max_cuti"
                                                onKeyPress={(event) => {
                                                    if (event.which == 13) {
                                                        event.preventDefault()
                                                        this.props.handleSubmitAmount(event, this.props.karyawan.karyawan_id)
                                                    }
                                                }}
                                                onValueChange={(value, name) => {
                                                    let newData = this.props.karyawan
                                                    newData["karyawan_max_cuti"] = value
                                                    this.props.changeState({ karyawan: newData});
                                                }}
                                            />
                                            <InputGroupAddon addonType="prepend">
                                                <div className="input-group-text input-group-text-radius mobile-hide upah-upah"
                                                     style={{ backgroundColor: "#eef1f3" }}>
                                                    <Label
                                                        style={{ marginTop: '5px' }}>Hari
                                                    </Label>
                                                </div>
                                            </InputGroupAddon>
                                        </InputGroup>
                                        <div className="mobile-show">
                                            <InputGroupAddon addonType="prepend">
                                                <div className="input-group-text input-group-text-radius upah-upah"
                                                     style={{ backgroundColor: "#eef1f3" }}>
                                                    <Label>Hari</Label>
                                                </div>
                                            </InputGroupAddon>
                                        </div>
                                    </FormGroup>
                                    <br/>
                                </Col>
                            }
                        </div>
                    </ModalBody>
                </Modal>
            </div>

        )
    }
}

export default ModalCutiSetting;
